import {
    CorptaxHomeLogoLink,
    HeaderEndContainer,
    NotificationBell,
    SignalRConnectionInfo,
    SiteHeader,
    useCometStyles,
    useCorptaxTheme,
    useNewNotificationListener,
    useNotificationLinks,
    useNotificationsErrorStatus,
    UserMenu,
    useUpdatedNotificationListener,
} from '@corptax/react-components-common';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetNotifications, useGetSignalRConnectionInformation, useUpdateNotification } from '../../api/notifications/notifications';
import { useGetClaimsForCurrentUser } from '../../api/user-claims/user-claims';
import { useGetClaims } from '../../apiExtensions/account/account';
import { useServiceDiscovery } from '../../hooks/useServiceDiscovery';
import { useTaxReturnCalculationState } from '../../hooks/useTaxReturnCalculationState';
import { IAppTheme } from '../../theme/IAppTheme';
import { replaceLocation } from '../../utils/LocationUtils';
import { getLogoutUrl } from '../../utils/Urls.constant';
import { getInitialsOfUserName } from '../../utils/UserUtils';
import { StyledHtmlViewer } from '../html-viewer/StyledHtmlViewer';

export const Topbar: FC = () => {
    const { t } = useTranslation();
    const claims = useGetClaims();
    const sessionId = claims.data?.find((c) => c.type === 'sid')?.value || '';
    const userClaims = useGetClaimsForCurrentUser();
    const userFullName = userClaims?.data?.userFullName || '';
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();
    const { homeUrl, calculationReviewUrl } = useServiceDiscovery();
    const { clearAllCompletedReports } = useTaxReturnCalculationState();
    const queryClient = useQueryClient();

    const corptaxCalculationReviewHeaderStyle = {
        color: palette.white,
        marginLeft: '.5rem',
        fontWeight: '600',
        fontSize: '1.25rem',
        lineHeight: '1rem',
        paddingBottom: '5px',
    };

    const {
        data: notificationsResponse,
        isFetching: isGetAllNotificationsFetching,
        isError: isGetAllNotificationsFetchingError,
        refetch: refetchGetAllNotifications,
        queryKey: getNotificationsQueryKey,
    } = useGetNotifications();
    const { mutateAsync: updateNotifications } = useUpdateNotification({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(getNotificationsQueryKey);
            },
        },
    });
    const { populateNotificationLinks } = useNotificationLinks({ reviewAppUrl: calculationReviewUrl ?? '/' });
    populateNotificationLinks(notificationsResponse?.notifications as any);
    const {
        data: signalRConnectionInformation,
        isError: isSignalRConnectionInformationErrored,
        refetch: refetchSignalRConnectionInformation,
    } = useGetSignalRConnectionInformation();
    const signalRObject: SignalRConnectionInfo | undefined =
        signalRConnectionInformation !== undefined &&
        signalRConnectionInformation.connectionInformation?.accessToken &&
        signalRConnectionInformation.connectionInformation?.url
            ? {
                  accessToken: signalRConnectionInformation?.connectionInformation?.accessToken || '',
                  url: signalRConnectionInformation?.connectionInformation?.url || '',
                  HubConnectionBuilder: HubConnectionBuilder,
                  LogLevel: LogLevel.Error,
              }
            : undefined;

    useUpdatedNotificationListener(signalRObject, getNotificationsQueryKey as string[]);
    const { pendingNotificationCount } = useNewNotificationListener(signalRObject, notificationsResponse?.notifications as any);
    const errorStatus = useNotificationsErrorStatus(isGetAllNotificationsFetchingError);

    const cometPalette = {
        darkPrimary: customPalette.themeDark,
        darkPrimaryAlt: customPalette.themeDarkAlt,
        lightPrimary: palette.white,
        lightPrimaryAlt: palette.neutralLighter,
    };

    const styleHelper = useCometStyles(cometPalette);
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

    const toggleNotificationRead = async (notificationId: string, isRead: boolean) => {
        try {
            await updateNotifications({ data: { id: notificationId, isRead } });
        } catch (error) {
            console.error('Error updating notification read status:', error);
            errorStatus.handleModifyNotificationError();
        }
    };

    const handleOpenChange = (_: any, data: { open: boolean }) => {
        if (data.open) {
            if (!isGetAllNotificationsFetching) {
                if (pendingNotificationCount > 0 || isGetAllNotificationsFetchingError) {
                    refetchGetAllNotifications();
                }
            }

            //always reset the error message data when the popover is opened
            errorStatus.reset();
        }

        //whenever the user interacts with the bell, let's verify that the signalr connection info was fetched successfully
        if (isSignalRConnectionInformationErrored) {
            refetchSignalRConnectionInformation();
        }

        setIsNotificationPanelOpen(!!data.open);
    };

    const handleRefetchData: () => void = () => {
        refetchGetAllNotifications();
        errorStatus.reset();
    };

    return (
        <SiteHeader data-testid='topbar' styleHelper={styleHelper}>
            <CorptaxHomeLogoLink logoHref={homeUrl ?? '/'} />
            <h3 style={corptaxCalculationReviewHeaderStyle}>{t('review')}</h3>
            <HeaderEndContainer>
                <NotificationBell
                    isLoading={isGetAllNotificationsFetching}
                    errorStatus={errorStatus}
                    pendingNotificationCount={pendingNotificationCount}
                    notifications={notificationsResponse?.notifications as any}
                    onToggleNotificationRead={toggleNotificationRead}
                    isPopoverOpen={isNotificationPanelOpen}
                    onHandleOpenChange={handleOpenChange}
                    onHandleRefetchData={handleRefetchData}
                    onRenderNotificationCardComment={(notification) => {
                        return <StyledHtmlViewer htmlString={notification.data.comment} />;
                    }}
                />
                <UserMenu
                    styleHelper={styleHelper}
                    userActionMenuItems={[
                        {
                            ariaLabel: t('logout').toString(),
                            key: 'logOut',
                            onClick: () => {
                                clearAllCompletedReports();
                                replaceLocation(getLogoutUrl(sessionId));
                            },
                            text: t('logout').toString(),
                        },
                    ]}
                    userInfo={{
                        displayInitials: getInitialsOfUserName(userFullName),
                        displayName: userFullName,
                        secondaryText: userFullName,
                    }}
                />
            </HeaderEndContainer>
        </SiteHeader>
    );
};
