import { OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';
import { IEnterAmountsDialogComboboxHookConfig } from '../hooks/useEnterAmountsDialogComboboxData';
import { IAdjustment, ICalendarPeriod, ICase, IEntity, IJurisdiction } from '../model';

export type EnterAmountsDialogComboboxReducer<T> = (
    state: IEnterAmountsDialogComboboxState<T>,
    action: EnterAmountsDialogComboboxReducerAction<T>
) => IEnterAmountsDialogComboboxState<T>;

export type EnterAmountsDialogComboboxReducerAction<T> =
    | { type: EnterAmountsComboboxAction.OptionSelected; allOptions: T[]; event: SelectionEvents; data: OptionOnSelectData }
    | {
          type: EnterAmountsComboboxAction.InputBlur;
          allOptions: T[];
          preSelectedOption: T | undefined;
          inputValue: string;
      }
    | { type: EnterAmountsComboboxAction.OnChange; newInputValue: string; allOptions: T[] };

export enum EnterAmountsComboboxAction {
    OptionSelected,
    InputBlur,
    OnChange,
}

export interface IEnterAmountsDialogComboboxState<T> {
    inputValue: string;
    selectedOptions: string[];
    matchingOptions: T[];
    searchText: string | undefined;
}

export type EnterAmountsDialogComboboxReducerFactory = <T extends ICalendarPeriod | IEntity | ICase | IJurisdiction | IAdjustment>(
    utils: IEnterAmountsDialogComboboxHookConfig<T> & { clearable?: boolean }
) => EnterAmountsDialogComboboxReducer<T>;

export const getEnterAmountsDialogComboboxReducer: EnterAmountsDialogComboboxReducerFactory = <
    T extends ICalendarPeriod | IEntity | ICase | IJurisdiction | IAdjustment
>(
    utils: IEnterAmountsDialogComboboxHookConfig<T> & { clearable?: boolean }
) => {
    const comboboxReducer: EnterAmountsDialogComboboxReducer<T> = (
        state: IEnterAmountsDialogComboboxState<T>,
        action: EnterAmountsDialogComboboxReducerAction<T>
    ) => {
        switch (action.type) {
            case EnterAmountsComboboxAction.OptionSelected:
                const matchingOption: T | undefined = utils.findOptionByKey(action.data.optionValue, action.allOptions);
                if (matchingOption) {
                    return {
                        ...state,
                        selectedOptions: [utils.optionToSelectedOption(matchingOption)],
                        inputValue: utils.optionToInputValue(matchingOption),
                        matchingOptions: action.allOptions,
                        searchText: undefined,
                    };
                } else {
                    const eventTarget = action.event.target as HTMLInputElement;
                    if (eventTarget.value) {
                        const matches3: T[] = utils.findOptionsByDisplayValue(eventTarget.value, action.allOptions);
                        return { ...state, searchText: matches3.length < 1 ? eventTarget.value : undefined, matchingOptions: matches3 };
                    }
                    return {
                        ...state,
                        searchText: undefined,
                        selectedOptions: utils.clearable ? [] : state.selectedOptions,
                        inputValue: utils.clearable ? '' : state.inputValue,
                    };
                }

            case EnterAmountsComboboxAction.InputBlur:
                const selectedOptionObject: T | undefined = utils.findOptionByKey(state.selectedOptions[0], action.allOptions);
                return {
                    ...state,
                    inputValue: selectedOptionObject ? utils.optionToInputValue(selectedOptionObject) : '',
                    searchText: undefined,
                    matchingOptions: action.allOptions,
                };
            case EnterAmountsComboboxAction.OnChange:
                const matches2: T[] = utils.findOptionsByDisplayValue(action.newInputValue, action.allOptions);
                const newSearchText = matches2.length < 1 ? action.newInputValue : undefined;
                return {
                    ...state,
                    inputValue: action.newInputValue,
                    matchingOptions: matches2,
                    searchText: newSearchText,
                };
            default:
                throw new Error('Invalid action type in CalendarPeriodComboboxReducer');
        }
    };
    return comboboxReducer;
};
