import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { FocusTrapZone, IButton, IButtonStyles, IconButton, mergeStyleSets, MessageBarType, Stack } from '@fluentui/react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import React, { KeyboardEvent, KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useSaveTaxReturns } from '../../../api/report/report';
import { useFeedbackMessages } from '../../../hooks/useFeedbackMessages';
import { TaxReturnSaveRecordsResponse } from '../../../model';
import { IAppTheme } from '../../../theme/IAppTheme';
import CustomIconButton from '../../common/IconButton';

interface IAddReturnPanelProps {
    isPanelOpen: boolean;
    handleClose: () => void;
    onSuccess?: () => void;
}

const AddReturnPanel: React.FC<IAddReturnPanelProps> = ({ isPanelOpen, handleClose, onSuccess }) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { mutateAsync: saveTaxReturns } = useSaveTaxReturns();
    const { displayFeedbackMessage } = useFeedbackMessages();
    const closeButton = React.useRef<IButton>(null);

    const onSave = () => {
        saveTaxReturns({ data: {} }).then((response: TaxReturnSaveRecordsResponse) => {
            if (!response.hasErrors) {
                displayFeedbackMessage({ type: MessageBarType.success, message: t('calcAdded') });
                onSuccess && onSuccess();
            }
        });
    };

    const getReportsTableStyles = () =>
        mergeStyleSets({
            sidebarContainerStyles: {
                height: '-webkit-fill-available',
                backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1,
                padding: '20px',
            },
            sidebarCloseButtonStyles: {
                border: 'none',
                color: customPalette.greenDark,
                cursor: 'pointer',
                selectors: {
                    ':hover': {
                        color: '#000',
                    },
                    ':focus': {
                        outline: '-webkit-focus-ring-color auto 1px !important',
                    },
                },
            },
            sidebarHeaderContentStyles: {
                display: 'flex',
                justifyContent: 'end',
                flexDirection: 'row',
            },
            sidebarTitleStyles: {
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '28px',
                margin: '0 0 8px 0',
            },
            sidebarsubTitleStyles: {
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '20px',
                margin: '0 0 8px 0',
            },
            sidebarFooterContainerStyles: {
                position: 'absolute',
                bottom: 0,
                display: 'flex',
                flexDirection: 'row',
                padding: '20px 0',
            },
            sidebarFormItemStyles: {
                margin: '30px 0',
            },
        });

    const {
        sidebarCloseButtonStyles,
        sidebarHeaderContentStyles,
        sidebarTitleStyles,
        sidebarContainerStyles,
        sidebarFooterContainerStyles,
        sidebarsubTitleStyles,
        sidebarFormItemStyles,
    } = getReportsTableStyles();

    const sidebarFooterButtonsStyles: IButtonStyles = {
        root: {
            padding: '10px',
            margin: '0 10px 0 0',
            ':focus': {
                outline: '-webkit-focus-ring-color auto 1px !important',
            },
        },
    };

    const formOrCalculationData = [{ Id: 1, Name: 'FE24 2024 FEDERAL FORM 1120 TAX RETURN' }];

    const initialFormsSelectedItems = [1];

    const entityOrEntityGroupData = [{ Id: 1, Name: 'FD7000' }];

    const initialEntitiesSelectedItems = [1];

    const destroyFocusTrapZoneOnEscape: KeyboardEventHandler<HTMLDivElement> = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };

    const fields = { text: 'Name', value: 'Id' };
    return (
        <SidebarComponent
            id='addNewReturnDockSidebar'
            data-testid='addNewReturnDockSidebar'
            close={handleClose}
            isOpen={isPanelOpen}
            position='Right'
            closeOnDocumentClick={true}
            width={507}
            type='Over'
        >
            <FocusTrapZone disabled={!isPanelOpen} isClickableOutsideFocusTrap onKeyDownCapture={destroyFocusTrapZoneOnEscape}>
                <Stack className={sidebarContainerStyles}>
                    <Stack className={sidebarHeaderContentStyles}>
                        <IconButton
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel={t('closePanel').toString()}
                            onClick={handleClose}
                            className={sidebarCloseButtonStyles}
                            componentRef={closeButton}
                        />
                    </Stack>
                    <Stack className={sidebarTitleStyles}>{t('addNewCalculation')}</Stack>
                    <Stack className={sidebarFormItemStyles}>
                        <Stack className={sidebarsubTitleStyles}>{t('formOrCalculation')}</Stack>
                        <MultiSelectComponent
                            id='readonly-multiselect'
                            dataSource={formOrCalculationData}
                            fields={fields}
                            value={initialFormsSelectedItems}
                            mode='Box'
                            readonly={true}
                        />
                    </Stack>
                    <Stack className={sidebarFormItemStyles}>
                        <Stack className={sidebarsubTitleStyles}>{t('entityOrEntityGroupSingular')}</Stack>
                        <MultiSelectComponent
                            id='readonly-multiselect'
                            dataSource={entityOrEntityGroupData}
                            fields={fields}
                            value={initialEntitiesSelectedItems}
                            mode='Box'
                            readonly={true}
                        />
                    </Stack>

                    <Stack className={sidebarFooterContainerStyles}>
                        <CustomIconButton
                            ariaLabel={t('add').toString()}
                            styles={sidebarFooterButtonsStyles}
                            label={t('add').toString()}
                            onButtonClick={onSave}
                        />
                        <CustomIconButton
                            ariaLabel={t('close').toString()}
                            label={t('cancel').toString()}
                            styles={sidebarFooterButtonsStyles}
                            onButtonClick={handleClose}
                        />
                    </Stack>
                </Stack>
            </FocusTrapZone>
        </SidebarComponent>
    );
};

export default AddReturnPanel;
