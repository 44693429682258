import { useCorptaxTheme } from '@corptax/react-components-common';
import {
    Announced,
    FocusZone,
    FocusZoneDirection,
    FocusZoneTabbableElements,
    IContextualMenuItem,
    IContextualMenuItemProps,
    IContextualMenuItemRenderProps,
    IContextualMenuItemStyles,
    IContextualMenuListProps,
    IRenderFunction,
    mergeStyles,
} from '@fluentui/react';
import React, { ReactEventHandler } from 'react';
import { IFilterItem } from '../../data-types/filterItem';
import { IAppTheme } from '../../theme/IAppTheme';
import FilterActionBar from '../filters/filter-actions/FilterActionBar';
import FilterSearchBar from '../filters/filter-search-bar/FilterSearchBar';
import TabList from '../tab-list/TabList';
import ContextualMenuClearSelectionItem from './ContextualMenuClearSelectionItem';

export interface IContextualMenuWithSearchBoxProps {
    menuListProps: IContextualMenuListProps;
    defaultRender: IRenderFunction<IContextualMenuListProps>;
    onSearchBoxChange: (ev: React.ChangeEvent<HTMLInputElement> | undefined, newValue: string | undefined) => void;
    onAbort: ReactEventHandler<HTMLInputElement>;
    onViewAllClick: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>, item?: IContextualMenuItem) => void;
    showViewAll: boolean;
    announcementMessage?: string;
    searchBoxPlaceholderText: string;
    viewAllText: string;
    tabsLabels?: string[];
    selectedTabIndex?: number;
    onTabChange?: (tabIndex: number) => void;
    isMultiSelect?: boolean;
    handleApply?: (ev?: any, dismissAll?: boolean) => void;
    handleCancel?: (ev?: any, dismissAll?: boolean) => void;
    selectedItems?: IFilterItem[];
    setSelectedItems?: React.Dispatch<React.SetStateAction<IFilterItem[]>>;
    filterMenuAriaLabel: string;
    menuListRole?: string;
    getExtendedMenuItems?: (menuListProps: IContextualMenuListProps) => React.ReactNode[];
}

const filterItemsScrollableContainerStyles = mergeStyles({
    overflowX: 'hidden',
    overflowY: 'auto',
});

const contentContainerStyles = mergeStyles({
    maxHeight: `25vmax`,
    display: 'flex',
    flexDirection: 'column',
});

const ContextualMenuWithSearchBox: React.FC<IContextualMenuWithSearchBoxProps> = ({
    menuListProps,
    defaultRender,
    onSearchBoxChange,
    onAbort,
    onViewAllClick,
    showViewAll,
    announcementMessage,
    searchBoxPlaceholderText,
    viewAllText,
    tabsLabels,
    selectedTabIndex,
    onTabChange,
    isMultiSelect,
    handleApply,
    handleCancel,
    filterMenuAriaLabel,
    menuListRole,
    getExtendedMenuItems,
}) => {
    const isMultitab = tabsLabels && tabsLabels.length > 0;
    const { brandPalette } = useCorptaxTheme<IAppTheme>();
    const applyButtonId = 'applyButton';

    const menuHeaderRowStyle = mergeStyles({
        position: 'sticky',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '4px 10px',
        gap: '10px',
        width: '530px',
        background: brandPalette.white,
        flex: 'none',
        order: 0,
        flexGrow: 0,
        zIndex: 1,
    });

    const renderLinkContainerAsMenuItem = (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
        return (
            <ContextualMenuClearSelectionItem
                item={item}
                dismissMenu={dismissMenu}
                onViewAllClick={onViewAllClick}
            ></ContextualMenuClearSelectionItem>
        );
    };

    const renderFilterActionsAsMenuItem = (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
        return (
            <FilterActionBar
                primaryHandler={() => (handleApply ? handleApply(dismissMenu) : () => {})}
                primaryButtonLabel='apply'
                primaryButtonId={applyButtonId}
                secondaryButtonLabel='cancel'
                secondaryHandler={() => (handleCancel ? handleCancel(dismissMenu) : dismissMenu())}
            />
        );
    };
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            const button = document.getElementById(applyButtonId);
            if (button) {
                button.click();
            }
        }
    };

    return (
        <div
            id='filterOptionsContainer'
            data-testid='filter-options-container'
            className={contentContainerStyles}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            role='menu'
            aria-label={filterMenuAriaLabel}
        >
            {isMultitab && (
                <div role='menuitem'>
                    <TabList
                        tabs={tabsLabels}
                        activeTabIndex={selectedTabIndex ?? 0}
                        setActiveTabIndex={onTabChange!}
                        label='Filter tabs'
                    ></TabList>
                </div>
            )}
            <div className={menuHeaderRowStyle} role='menuitem' aria-label='Menu Header Container' tabIndex={0}>
                {announcementMessage && announcementMessage.trim() !== '' ? (
                    <Announced
                        message={announcementMessage}
                        styles={{
                            root: {
                                top: 0,
                                left: -2,
                                width: 1,
                                height: 1,
                                position: 'absolute',
                                overflow: 'hidden',
                            },
                        }}
                    />
                ) : null}
                <div tabIndex={0}>
                    <FilterSearchBar
                        searchBoxPlaceholderText={searchBoxPlaceholderText}
                        onSearchBoxChange={onSearchBoxChange}
                        onAbort={onAbort}
                    />
                </div>
                {showViewAll
                    ? menuListProps.defaultMenuItemRenderer({
                          key: 'viewAll',
                          text: viewAllText,

                          onRender: renderLinkContainerAsMenuItem,
                          /*
                            menuListProps.defaultMenuItemRenderer will render menu items as <li>.
                            So we need to make sure there's no bullet being added
                            */
                          itemProps: {
                              tabIndex: 0,

                              styles: {
                                  item: {
                                      'list-style-type': 'none',
                                  },
                              } as IContextualMenuItemStyles,
                          } as IContextualMenuItemProps,
                      } as IContextualMenuItemRenderProps)
                    : null}
            </div>

            <FocusZone
                className={filterItemsScrollableContainerStyles}
                role={menuListRole}
                aria-label='Filter Items Container'
                handleTabKey={FocusZoneTabbableElements.none}
                direction={FocusZoneDirection.bidirectional}
            >
                {defaultRender(menuListProps)}
            </FocusZone>

            {isMultiSelect
                ? menuListProps.defaultMenuItemRenderer({
                      key: 'filterActionsBar',
                      onRender: renderFilterActionsAsMenuItem,
                      itemProps: {
                          tabIndex: 0,
                          role: 'menuitem',
                          styles: {
                              item: {
                                  'list-style-type': 'none',
                              },
                          } as IContextualMenuItemStyles,
                      } as IContextualMenuItemProps,
                  } as IContextualMenuItemRenderProps)
                : null}
            {getExtendedMenuItems && getExtendedMenuItems(menuListProps)}
        </div>
    );
};

export default ContextualMenuWithSearchBox;
