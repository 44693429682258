/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { FunctionalAccessDefinition } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetFunctionalAccessHook = () => {
    const getFunctionalAccess = useCustomInstance<FunctionalAccessDefinition>();

    return (signal?: AbortSignal) => {
        return getFunctionalAccess({ url: `/FunctionalAccess/GetFunctionalAccess`, method: 'get', signal });
    };
};

export const getGetFunctionalAccessQueryKey = () => [`/FunctionalAccess/GetFunctionalAccess`] as const;

export const useGetFunctionalAccessQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFunctionalAccessHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFunctionalAccessHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFunctionalAccessHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetFunctionalAccessQueryKey();

    const getFunctionalAccess = useGetFunctionalAccessHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFunctionalAccessHook>>>> = ({ signal }) =>
        getFunctionalAccess(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetFunctionalAccessQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFunctionalAccessHook>>>>;
export type GetFunctionalAccessQueryError = ErrorType<unknown>;

export const useGetFunctionalAccess = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFunctionalAccessHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFunctionalAccessHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetFunctionalAccessQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
