import {
    hasOverflow,
    IColumn,
    IDetailsColumnFieldProps,
    IRefObject,
    ITooltipHost,
    TooltipDelay,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import { Key, KeyboardEvent, ReactNode, RefObject, useRef, useState } from 'react';
import {
    formatNumberForDisplay,
    formatNumberForEdit,
    isValidIntermediateNumberInput,
    parseNumericValue,
    tryParseNumericValue,
} from '../../utils/NumberUtils';
import { cellContainerClass, msDetailRowCellClass } from './CardGridListConstants';
import {
    ICardGridCellTooltip,
    ICardGridListColumn,
    ICardGridListCustomRow,
    ICardGridListEventHandling,
    ICardGridListRowData,
    IColumnExtended,
    IGroupExtended,
} from './CardGridListInterfaces';
import { cardGridListRenderUtils } from './CardGridListRender';
import { CellDataType, RenderDataCellTextCallback } from './CardGridListTypes';

export interface IDataCellProps {
    dataType?: CellDataType;
    editable?: boolean | ((dataItem: any, column: ICardGridListColumn) => boolean);
    onRenderDataCellText?: RenderDataCellTextCallback;
    onChange?: (event: any, value: any) => void;
}

export interface IDataCellRenderProps {
    detailsColumnFieldProps: IDetailsColumnFieldProps;
    key: Key;
    gridColumns: IColumn[];
    gridDataSource: any[];
    handle: ICardGridListEventHandling;
    render: (detailsColumnFieldProps: IDetailsColumnFieldProps) => JSX.Element | null;
    defaultExpandCollapseColumnKey?: string;
    customRows?: ICardGridListCustomRow[];
}

function cancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
}

function getElementTextContent(element: HTMLElement): string {
    return element.textContent?.trim() ?? '';
}

function gethandleBlurNumberCallBack(
    currentCellText: string,
    setCellText: (value: string) => void,
    onChange?: (event: any, value: any) => void,
    onchangeParameter?: any
): (event: Event) => void {
    return (event: Event) => {
        const divElement: HTMLDivElement = event.currentTarget as HTMLDivElement;
        const selection: Selection = window.getSelection() as Selection;

        // Remove event listeners for checking input before it is rendered and updating the aria label.
        divElement.removeEventListener('input', handleInputNumber);
        divElement.removeEventListener('beforeinput', handleBeforeInputNumber);

        // Clear any text selection within the number text.
        selection.removeAllRanges();

        const currentElementTextContent = getElementTextContent(divElement);
        const isZeroEquivalent: boolean = currentElementTextContent === '' || currentElementTextContent === '-';
        const newText: string = isZeroEquivalent ? '0' : reformatNumericText(currentElementTextContent, formatNumberForDisplay);

        divElement.removeAttribute('aria-label');
        divElement.textContent = newText;

        if (currentCellText !== newText) {
            setCellText(newText);

            if (onChange) {
                onChange(event, onchangeParameter);
            }
        }
    };
}

function getTextSelectionRange(divElement: HTMLDivElement): Range | null {
    let returnRange: Range | null = null;
    const selection: Selection | null = window.getSelection();

    if (selection) {
        for (let index: number = 0; index < selection.rangeCount; index++) {
            const range: Range = selection.getRangeAt(index);

            if (range.commonAncestorContainer === divElement) {
                if (divElement.childNodes.length > 0) {
                    returnRange = new Range();
                    returnRange.setStart(divElement.childNodes[0], 0);
                    returnRange.setEnd(divElement.childNodes[0], getElementTextContent(divElement).length);
                } else {
                    returnRange = range;
                }
            } else if (range.commonAncestorContainer.parentNode === divElement) {
                returnRange = range;
            }

            if (returnRange) {
                break;
            }
        }
    }

    return returnRange;
}

function handleBeforeInputNumber(event: InputEvent) {
    const divElement: HTMLDivElement = event.target as HTMLDivElement;
    const selectionRange: Range | null = getTextSelectionRange(divElement);

    if (selectionRange === null) {
        console.error('Failed to retrieve selection range for data cell being edited.');
        cancelEvent(event);
        return;
    }

    // Construct the new text based upon the user input.
    let newText: string | null = null;
    const originalText: string = getElementTextContent(divElement);
    const beforeSelectedText: string = originalText.substring(0, selectionRange.startOffset);
    const afterSelectedText: string =
        selectionRange.endOffset < originalText.length ? originalText.substring(selectionRange.endOffset) : '';
    const addedText: string = (event.data || event.dataTransfer?.getData('text') || '').trim();
    let beforeLength: number = beforeSelectedText.length;
    let afterStartIndex: number = 0;

    switch (event.inputType) {
        case 'deleteContentBackward':
            if (selectionRange.collapsed && beforeSelectedText.length > 0) {
                beforeLength -= 1;
            }

            break;

        case 'deleteContentForward':
            if (selectionRange.collapsed && afterSelectedText.length > 0) {
                afterStartIndex = 1;
            }

            break;
    }

    newText = `${beforeSelectedText.substring(0, beforeLength)}${addedText}${afterSelectedText.substring(afterStartIndex)}`;

    // Ensure the new text adheres to a valid integer.
    // Note: Leading zeros in the number are allowed while editing and are removed when cell focus is lost.
    if (!isValidIntermediateNumberInput(newText)) {
        event.preventDefault();

        // If the input was a paste operation, we will allow the resulting text if it is a correctly formatted integer or floating point number.
        if (event.inputType === 'insertFromPaste' && tryParseNumericValue(newText, (result) => (newText = formatNumberForEdit(result)))) {
            divElement.textContent = newText;

            // Ensure the caret position is set back to the correct position after programattically changing the text.
            const caretPosition: number = newText.length - afterSelectedText.substring(afterStartIndex).length;

            setTextSelectionRange(divElement, caretPosition, caretPosition);
        }
    }

    event.stopPropagation();
}

function handleFocusNumber(event: Event) {
    const divElement: HTMLDivElement = event.currentTarget as HTMLDivElement;
    const selection: Selection = window.getSelection() as Selection;
    const range: Range = document.createRange();

    // Remove all formatting from the number text.
    divElement.ariaLabel = divElement.textContent;
    divElement.textContent = reformatNumericText(getElementTextContent(divElement), formatNumberForEdit);

    // Select the number text
    range.selectNode(divElement.childNodes[0]);
    selection.removeAllRanges();
    selection.addRange(range);

    // Add event listeners for checking input before it is rendered and updating the cell aria label.
    divElement.addEventListener('beforeinput', handleBeforeInputNumber);
    divElement.addEventListener('input', handleInputNumber);
}

function handleInputNumber(event: Event) {
    const divElement: HTMLDivElement = event.target as HTMLDivElement;

    divElement.ariaLabel = reformatNumericText(getElementTextContent(divElement), formatNumberForDisplay);
}

function handleKeyDownKeyUp(event: KeyboardEvent) {
    // Ensure text editing navigation does not get overridden by standard grid navigation.
    switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'End':
        case 'Home':
            event.stopPropagation();
            break;
    }
}

function handleMouseDownMouseUp(event: Event) {
    const divElement: HTMLDivElement = event.currentTarget as HTMLDivElement;

    if (document.activeElement !== divElement) {
        event.preventDefault();
        divElement.focus();
    }
}

function initializeCellText(value: string): string {
    return value === '' ? '' : reformatNumericText(value, formatNumberForDisplay);
}

function reformatNumericText(value: string, numberFormatter: (value: number) => string): string {
    return numberFormatter(parseNumericValue(value, true));
}

function renderText(props: IDetailsColumnFieldProps): ReactNode {
    const columnExtended = props.column as IColumnExtended;
    const renderDataCellTextCallback: RenderDataCellTextCallback | undefined =
        columnExtended.cardGridListColumn.dataCellProps?.onRenderDataCellText;
    const defaultOnRender =
        columnExtended.cardGridListColumn.dataCellProps?.dataType === 'number'
            ? () => initializeCellText(props.onRender(props.item, props.itemIndex, columnExtended) as string)
            : props.onRender.bind(null, props.item, props.itemIndex, columnExtended);

    if (renderDataCellTextCallback) {
        return renderDataCellTextCallback(props.item, (props.item as ICardGridListRowData).gridGroup, columnExtended, defaultOnRender);
    } else {
        return defaultOnRender();
    }
}

function removeTextSelectionRange(divElement: HTMLDivElement) {
    const selection: Selection | null = window.getSelection();

    if (selection) {
        let rangeToRemove: Range | null = null;

        for (let index: number = 0; index < selection.rangeCount; index++) {
            const range: Range = selection.getRangeAt(index);

            if (range.commonAncestorContainer === divElement || range.commonAncestorContainer.parentNode === divElement) {
                rangeToRemove = range;
                break;
            }
        }

        if (rangeToRemove) {
            selection.removeRange(rangeToRemove);
        }
    }
}

function renderTextWithTooltip(props: IDetailsColumnFieldProps, tooltipHostRef: IRefObject<ITooltipHost>): ReactNode {
    const extendedColumn = props.column as IColumnExtended;

    return (
        <TooltipHost
            {...extendedColumn.cardGridListColumn.tooltipProps?.tooltipHostProps}
            content={extendedColumn.cardGridListColumn.tooltipProps?.getTooltipContent(props.item)}
            componentRef={tooltipHostRef}
        >
            {renderText(props)}
        </TooltipHost>
    );
}

function renderCellContent(props: IDataCellRenderProps, tooltipHostRef: IRefObject<ITooltipHost>): ReactNode {
    const detailsColumnFieldProps: IDetailsColumnFieldProps = props.detailsColumnFieldProps;
    const extendedColumn = detailsColumnFieldProps.column as IColumnExtended;
    const gridGroup: IGroupExtended = (detailsColumnFieldProps.item as ICardGridListRowData).gridGroup;
    let requiresNestingIndent: boolean = false;
    let requiresNoExpandCollapseIndent: boolean = false;
    let requiresExpandCollapse: boolean = false;
    const isRenderingExpandCollapseColumn = cardGridListRenderUtils.cellIsRowExpander(
        extendedColumn,
        gridGroup,
        props.gridColumns,
        props.defaultExpandCollapseColumnKey,
        props.customRows
    );

    if (isRenderingExpandCollapseColumn) {
        requiresNestingIndent = cardGridListRenderUtils.gridGroupRequiresNestingIndent(gridGroup);
        requiresNoExpandCollapseIndent = cardGridListRenderUtils.gridGroupRequiresNoExpandCollapseIndent(gridGroup);
        requiresExpandCollapse = cardGridListRenderUtils.gridGroupRequiresExpandCollapse(gridGroup);
    }

    const requiresTooltip: boolean = !!extendedColumn.cardGridListColumn.tooltipProps;
    const isCustomizedRender: boolean =
        requiresNestingIndent || requiresNoExpandCollapseIndent || requiresExpandCollapse || requiresTooltip;

    if (isCustomizedRender) {
        return (
            <div className={cellContainerClass}>
                {requiresNestingIndent ? cardGridListRenderUtils.renderNestingIndent(gridGroup) : null}
                {requiresNoExpandCollapseIndent ? cardGridListRenderUtils.renderNoExpandCollapseIndent() : null}
                {requiresExpandCollapse
                    ? cardGridListRenderUtils.renderExpandCollapse(
                          props.gridDataSource.indexOf(detailsColumnFieldProps.item),
                          props.handle.expanderClick
                      )
                    : null}
                {renderColumnText(props.detailsColumnFieldProps, requiresTooltip ? tooltipHostRef : null)}
            </div>
        );
    }

    return renderText(props.detailsColumnFieldProps);
}

function renderColumnText(props: IDetailsColumnFieldProps, tooltipHostRef: IRefObject<ITooltipHost> | null): ReactNode {
    return tooltipHostRef ? renderTextWithTooltip(props, tooltipHostRef) : <span>{renderText(props)}</span>;
}

function setTextSelectionRange(divElement: HTMLDivElement, start: number, end: number) {
    removeTextSelectionRange(divElement);

    const selection: Selection | null = window.getSelection();

    if (selection) {
        const newRange: Range = document.createRange();

        if (divElement.textContent) {
            const textNode: Node = divElement.childNodes[0];

            newRange.setStart(textNode, start);
            newRange.setEnd(textNode, end);
        } else {
            newRange.selectNode(divElement);
        }

        selection.addRange(newRange);
    }
}

const DataCell: React.FC<IDataCellRenderProps> = (props: IDataCellRenderProps) => {
    const detailsColumnFieldProps: IDetailsColumnFieldProps = props.detailsColumnFieldProps;
    const column = detailsColumnFieldProps.column as IColumnExtended;
    const rowData = detailsColumnFieldProps.item as ICardGridListRowData;
    const [cellText, setCellText] = useState<string>(initializeCellText((rowData as any)[column.fieldName!]));
    const tooltipHostRef: RefObject<ITooltipHost> = useRef<ITooltipHost>(null);

    detailsColumnFieldProps.className = msDetailRowCellClass;

    const detailsColumnFieldPropsCopy: IDetailsColumnFieldProps = { ...detailsColumnFieldProps };

    detailsColumnFieldPropsCopy.onRender = (): ReactNode => renderCellContent(props, tooltipHostRef);
    let dataCell: JSX.Element | null = props.render!(detailsColumnFieldPropsCopy);

    if (!dataCell) {
        return null;
    }

    const addTooltipHostKeyboardNavigationEventHandlers: (dataCell: JSX.Element) => JSX.Element = (dataCell: JSX.Element) => {
        const finalDataCell = { ...dataCell };
        finalDataCell.props = {
            ...dataCell.props,
            onFocus: (event: Event) => {
                const extendedColumn: IColumnExtended = props.detailsColumnFieldProps.column as IColumnExtended;
                const tooltipProps: ICardGridCellTooltip | undefined = extendedColumn.cardGridListColumn.tooltipProps;
                const tooltipHostOverflowMode: TooltipOverflowMode | undefined = tooltipProps?.tooltipHostProps.overflowMode;

                if (tooltipHostRef?.current && tooltipHostOverflowMode !== null && hasOverflow(event.currentTarget as HTMLElement)) {
                    const tooltipDelay = tooltipProps?.tooltipHostProps.delay;

                    if (tooltipDelay !== undefined && tooltipDelay !== TooltipDelay.zero) {
                        setTimeout(
                            () => {
                                tooltipHostRef?.current?.show();
                            },
                            // This was taken straight from the TooltipHost source code.
                            // https://github.com/microsoft/fluentui/blob/master/packages/react/src/components/Tooltip/TooltipHost.base.tsx#L289
                            // If I could reference their function directly, I would.
                            tooltipDelay === TooltipDelay.medium ? 300 : 500
                        );
                    } else {
                        tooltipHostRef?.current?.show();
                    }
                }
            },
            onBlur: (event: Event) => {
                if (tooltipHostRef?.current) {
                    tooltipHostRef.current.dismiss();
                }
            },
        };

        return finalDataCell;
    };

    dataCell = addTooltipHostKeyboardNavigationEventHandlers(dataCell);

    const dataCellProps: IDataCellProps | undefined = column.cardGridListColumn.dataCellProps;

    if (dataCellProps) {
        const isEditable: boolean =
            dataCellProps.editable === true ||
            (dataCellProps.editable instanceof Function && dataCellProps.editable(rowData, column.cardGridListColumn));

        if (isEditable) {
            let eventProps: any = {
                onDrag: cancelEvent,
                onDragEnd: cancelEvent,
                onDragOver: cancelEvent,
                onDragStart: cancelEvent,
                onDrop: cancelEvent,
                onMouseDown: handleMouseDownMouseUp,
                onMouseUp: handleMouseDownMouseUp,
                onKeyDown: handleKeyDownKeyUp,
                onKeyUp: handleKeyDownKeyUp,
            };

            if (dataCellProps.dataType === 'number') {
                eventProps = {
                    ...eventProps,
                    onFocus: handleFocusNumber,
                    onBlur: gethandleBlurNumberCallBack(cellText, setCellText, dataCellProps.onChange, rowData),
                };
            }

            dataCell = { ...dataCell };
            dataCell.props = {
                suppressContentEditableWarning: true,
                ...dataCell.props,
                contentEditable: 'plaintext-only',
                ...eventProps,
            };
        }
    }

    return dataCell;
};

export default DataCell;
