import { mergeStyles, Stack } from '@fluentui/react';
import { Combobox, Option, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';
import { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IWhitePaperColumn } from '../../../model';
import DefaultSeparator from '../../common/DefaultSeparator';
import { PdfFormNavigation } from '../../pdf/pdfFormNavigation';
import { PdfPageNavigation } from '../../pdf/pdfPageNavigation';

export interface IPdfViewerToolbarProps {
    returnKey: number | undefined;
    reportColumns: IWhitePaperColumn[];
    pdfViewer?: React.RefObject<PdfViewerComponent>;
}

const PdfViewerToolbar: React.FC<IPdfViewerToolbarProps> = ({ returnKey, reportColumns, pdfViewer }) => {
    const { t } = useTranslation();
    const { loadPdfPage, tocData, currentPage, currentFolder } = useFormPdfView();

    const selectedEntityId = currentPage?.entity?.id || reportColumns?.[0]?.columnId;
    const defaultColumn: IWhitePaperColumn | undefined = reportColumns?.find((column) => column.columnId === selectedEntityId);
    const defaultComboboxSelectedOptions = defaultColumn?.columnId ? [defaultColumn.columnId.toString()] : [];
    const defaultComboboxValue = defaultColumn?.description ?? '';

    const pdfToolbarClass = mergeStyles({
        width: '100%',
        height: '56px',
        padding: '12px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid lightgray',
        marginBottom: 1,
        gap: '0.8rem',
        '& > label': {
            fontWeight: 600,
        },
    });

    const selectEntityForForm = (_: SelectionEvents, data: OptionOnSelectData) => {
        const value = data.optionValue;
        if (value) {
            const selectedEntity = reportColumns?.find((column) => column.columnId === parseInt(value));
            const pages = tocData?.folders?.find((folder) => folder.id === currentPage.parentId)?.pages || [];

            if (returnKey && selectedEntity?.columnId !== undefined && selectedEntity.columnIndex !== undefined) {
                const page =
                    currentPage.pageId && currentPage.pageName
                        ? { id: currentPage.pageId, name: currentPage.pageName, uniqueId: `${currentPage.pageId}${currentPage.pageName}` }
                        : tocData?.defaultPage;
                if (page) {
                    loadPdfPage(pages, currentPage.parentId || '', `${currentPage.pageId}${currentPage.pageName}`, {
                        id: selectedEntity.columnId,
                        index: selectedEntity.columnIndex,
                    });
                }
            }
        }
    };

    const currentFolderIndex = tocData?.folders?.findIndex((folder) => folder.id === currentFolder?.id) ?? 0;
    const isFirstForm: boolean = currentFolderIndex === 0;
    let isLastForm: boolean = true;

    if (tocData && tocData.folders) {
        isLastForm = currentFolderIndex === tocData.folders.length - 1;
    }

    const navigateToFolder = (folderIndex: number) => {
        if (tocData && tocData.folders) {
            const targetFolder = tocData.folders[folderIndex];

            if (targetFolder && targetFolder.pages && targetFolder.pages.length > 0) {
                const firstPageUniqueId = targetFolder.pages[0].uniqueId;
                loadPdfPage(targetFolder.pages, targetFolder.id ?? '', firstPageUniqueId ?? '');
            }
        }
    };

    const previousForm = () => {
        const previousFolderIndex = currentFolderIndex - 1;

        if (previousFolderIndex >= 0) {
            navigateToFolder(previousFolderIndex);
        }
    };

    const nextForm = () => {
        const nextFolderIndex = currentFolderIndex + 1;

        if (nextFolderIndex > 0 && nextFolderIndex < (tocData?.folders?.length ?? 0)) {
            navigateToFolder(nextFolderIndex);
        }
    };

    return (
        <div className={pdfToolbarClass}>
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 5 }}>
                {reportColumns?.length > 1 && (
                    <>
                        <label>{t('entity')}</label>
                        <Combobox
                            onOptionSelect={selectEntityForForm}
                            selectedOptions={defaultComboboxSelectedOptions}
                            value={defaultComboboxValue}
                            listbox={{
                                style: { maxHeight: 300, overflowY: 'auto' },
                            }}
                        >
                            {reportColumns.map((column) => (
                                <Option
                                    key={`${column.columnId}${column.columnIndex}`}
                                    value={column.columnId?.toString()}
                                    text={column.description ?? ''}
                                >
                                    {column.description}
                                </Option>
                            ))}
                        </Combobox>
                    </>
                )}
            </Stack>
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 5 }}>
                <PdfPageNavigation
                    pdfViewer={pdfViewer?.current ?? undefined}
                    isFirstForm={isFirstForm}
                    isLastForm={isLastForm}
                    onNavigateToPreviousForm={previousForm}
                    onNavigateToNextForm={nextForm}
                ></PdfPageNavigation>
                <DefaultSeparator height='1.25rem' />
                <PdfFormNavigation
                    isFirstForm={isFirstForm}
                    isLastForm={isLastForm}
                    onPreviousFormClick={previousForm}
                    onNextFormClick={nextForm}
                ></PdfFormNavigation>
            </Stack>
        </div>
    );
};

export default PdfViewerToolbar;
