import { MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-grids';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAddReturnsToExistingFolder } from '../../api/report/report';
import { useFeedbackMessages } from '../../hooks/useFeedbackMessages';
import { useFunctionalAccess } from '../../hooks/useFunctionalAccess';
import { ReturnFolderDto } from '../../model/returnFolderDto';
import FunctionalAccessDisableWrapper from '../common/FunctionalAccessDisableWrapper';
import CustomIconButton from '../common/IconButton';
import Modal, { IModalButtonProps, IModalMessageBarProps } from '../common/Modal';
import DefaultGrid from '../grid/DefaultGrid';
import AddToNewFolderModal from './AddToNewFolderModal/AddToNewFolderModal';

export interface AddToFolderModalProps {
    isOpen: boolean;
    folders: ReturnFolderDto[] | undefined;
    selectedReturns: number[];
    getAllFoldersQuery: QueryKey;
    isLoadingGetAllFolders: boolean;
    onClose: () => void;
}

const StyledDefaultGrid = styled(DefaultGrid)`
    .e-headercontent {
        border-right: hidden;
    }
`;

const AddToFolderModal: React.FC<AddToFolderModalProps> = ({
    isOpen,
    folders,
    selectedReturns,
    getAllFoldersQuery,
    isLoadingGetAllFolders,
    onClose,
}) => {
    const { t } = useTranslation();
    const [selectedFolder, setSelectedFolder] = React.useState<ReturnFolderDto | undefined>(undefined);
    const [isAddToNewFolderModalOpen, { setFalse: closeAddToNewFolderModal, setTrue: openAddToNewFolderModal }] = useBoolean(false);
    const [messageBarProps, setMessageBarProps] = React.useState<IModalMessageBarProps | undefined>(undefined);
    const queryClient = useQueryClient();
    const { displayFeedbackMessage } = useFeedbackMessages();
    const { functionalAccess } = useFunctionalAccess();

    const { mutateAsync: addReturnsToExistingFolder, isLoading: isSubmitting } = useAddReturnsToExistingFolder({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(getAllFoldersQuery);
                closeAddToNewFolderModal();
            },
        },
    });

    const headerContent = <>{`${t('addToFolder')} (${selectedReturns.length} ${t('items')})`}</>;

    const bodyContent = (
        <StyledDefaultGrid
            dataSource={folders}
            allowResizing={false}
            height='40vh'
            width='40vw'
            enableVirtualMaskRow={false}
            rowHeight={37}
            aria-busy={isLoadingGetAllFolders}
            rowSelected={(row: { data: ReturnFolderDto }) => setSelectedFolder(row.data)}
        >
            <ColumnsDirective>
                <ColumnDirective field={'folderName'} headerText={t('folderName').toString()} headerTextAlign='Left' isPrimaryKey={true} />
            </ColumnsDirective>
        </StyledDefaultGrid>
    );

    const submitAndClearState = async () => {
        if (selectedFolder) {
            await addReturnsToExistingFolder({
                data: {
                    folderKey: selectedFolder.folderKey,
                    taxReturnItemKeys: selectedReturns,
                },
            });

            displayFeedbackMessage({
                type: MessageBarType.success,
                message: (
                    <span>
                        {t('calculationsAddedToFolder')}
                        {selectedFolder.folderName}
                    </span>
                ),
            });
            clearStateAndClose();
        }
    };

    const clearStateAndClose = () => {
        setSelectedFolder(undefined);
        setMessageBarProps(undefined);
        onClose();
    };

    const AddToNewFolderButton = (
        <FunctionalAccessDisableWrapper hasFunctionalAccess={functionalAccess?.createFolderButtonEnabled}>
            <CustomIconButton
                label={t('addToNewFolder').toString()}
                id='add-to-new-folder-button'
                iconName='FolderArrowRight20Regular'
                styles={{ root: { height: '36px' } }}
                onButtonClick={openAddToNewFolderModal}
            ></CustomIconButton>
        </FunctionalAccessDisableWrapper>
    );

    const buttonProps: IModalButtonProps[] = [
        {
            text: t('add'),
            primary: true,
            isProcessing: isSubmitting,
            disabled: selectedFolder === undefined || isSubmitting,
            isSmall: true,
            onClick: submitAndClearState,
        },
        {
            text: t('cancel'),
            primary: false,
            isProcessing: false,
            disabled: false,
            isSmall: true,
            onClick: clearStateAndClose,
        },
    ];

    const onSubmitNewFolder = () => {
        queryClient.invalidateQueries(getAllFoldersQuery);
        onClose();
    };

    return (
        <>
            <Modal
                leftFooterElement={AddToNewFolderButton}
                headerContent={headerContent}
                bodyContent={bodyContent}
                buttons={buttonProps}
                isOpen={isOpen}
                onClose={clearStateAndClose}
                messageBarProps={messageBarProps}
            />
            <AddToNewFolderModal
                isOpen={isAddToNewFolderModalOpen}
                onClose={closeAddToNewFolderModal}
                selectedReturns={selectedReturns}
                onSubmit={onSubmitNewFolder}
                setMessageBarProps={setMessageBarProps}
            />
        </>
    );
};

export default AddToFolderModal;
