export const isUndefinedOrEmpty = (value: string | undefined | null): boolean => {
    return value === undefined || value === null || value.toString().trim() === '';
};

export const convertStringToNumber = (value: string | undefined): number => {
    if (value !== undefined && value.length > 0) {
        return Number(value.replaceAll(',', '') ?? '');
    }

    return NaN;
};
