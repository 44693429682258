export const setConditionalTimeout = (callback: () => void, condition: boolean, timeoutInMilliseconds: number) => {
    if (condition) {
        const interval = setInterval(() => {
            callback();
        }, timeoutInMilliseconds);

        return () => clearInterval(interval);
    }

    return () => {};
};
