import { useContext } from 'react';
import { FormPdfViewContext } from './formPdfViewContext';

export function useFormPdfView() {
    const formPdfViewContext = useContext(FormPdfViewContext);
    if (!formPdfViewContext) {
        throw new Error('useFormPdfView must be wrapped in a FormPdfViewProvider');
    }
    return formPdfViewContext;
}
