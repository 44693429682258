/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { MutationFunction, UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { IEmbedAnalyticsReportRequest, IEmbedAnalyticsReportResponse, ProblemDetails } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useEmbedAnalyticsReportHook = () => {
    const embedAnalyticsReport = useCustomInstance<IEmbedAnalyticsReportResponse>();

    return (iEmbedAnalyticsReportRequest: IEmbedAnalyticsReportRequest) => {
        return embedAnalyticsReport({
            url: `/Analytics`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: iEmbedAnalyticsReportRequest,
        });
    };
};

export const useEmbedAnalyticsReportMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useEmbedAnalyticsReportHook>>>,
        TError,
        { data: IEmbedAnalyticsReportRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEmbedAnalyticsReportHook>>>,
    TError,
    { data: IEmbedAnalyticsReportRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const embedAnalyticsReport = useEmbedAnalyticsReportHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useEmbedAnalyticsReportHook>>>,
        { data: IEmbedAnalyticsReportRequest }
    > = (props) => {
        const { data } = props ?? {};

        return embedAnalyticsReport(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type EmbedAnalyticsReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEmbedAnalyticsReportHook>>>>;
export type EmbedAnalyticsReportMutationBody = IEmbedAnalyticsReportRequest;
export type EmbedAnalyticsReportMutationError = ErrorType<ProblemDetails>;

export const useEmbedAnalyticsReport = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useEmbedAnalyticsReportHook>>>,
        TError,
        { data: IEmbedAnalyticsReportRequest },
        TContext
    >;
}) => {
    const mutationOptions = useEmbedAnalyticsReportMutationOptions(options);

    return useMutation(mutationOptions);
};
