import { SanitizeHtmlHelper } from '@syncfusion/ej2-base';

export interface IHtmlViewerProps {
    htmlString?: string | null;
    /* className to support wrapping with Styled or passing in a class name from elsewhere */
    className?: string;
}

export const HtmlViewer = (props: IHtmlViewerProps) => {
    if (props.htmlString) {
        const sanitizedHtml = SanitizeHtmlHelper.sanitize(props.htmlString);
        return <div className={props.className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    }

    return null;
};
