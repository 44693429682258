import { useContext } from 'react';
import { FeedbackMessagesContext, IFeedbackMessageContextValues } from '../contexts/feedbackMessagesContext';

export const useFeedbackMessages = (): IFeedbackMessageContextValues => {
    const context = useContext(FeedbackMessagesContext);

    if (!context) {
        throw new Error('useFeedbackMessages must be used within a FeedbackMessagesContextProvider');
    }

    return context;
};
