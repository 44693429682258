import FileSaver from 'file-saver';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExportReportToExcel, useExportReportToPDF, useExportTaxReturnListToExcel } from '../api/report/report';
import { Report } from '../model';
import { getExportFileNameBasedOnSelectedReports } from '../utils/ReportUtils';

export const useExportReports = () => {
    const [exportedReports, setExportedReports] = useState<Report[]>();
    const { t } = useTranslation();

    const saveFileAndClearState = (data: Blob, fileExtension: string, isTaxReturnList?: boolean) => {
        FileSaver.saveAs(data, getExportFileNameBasedOnSelectedReports(exportedReports, fileExtension, t, isTaxReturnList));

        setExportedReports([]);
    };

    const { mutateAsync: exportToExcelMutation, isLoading: isExcelExportProcessing } = useExportReportToExcel({
        mutation: {
            onSuccess: (data: any) => {
                saveFileAndClearState(data as Blob, '.xlsx');
            },
        },
    });
    const { mutateAsync: exportTaxReturnListToExcelMutation, isLoading: isTaxRetunExcelExportProcessing } = useExportTaxReturnListToExcel({
        mutation: {
            onSuccess: (data: any) => {
                saveFileAndClearState(data as Blob, '.xlsx', true);
            },
        },
    });
    const { mutateAsync: exportToPdfMutation, isLoading: isPdfExportProcessing } = useExportReportToPDF({
        mutation: {
            onSuccess: (data: any) => {
                saveFileAndClearState(data as Blob, '.pdf');
            },
        },
    });
    const isProcessing = isExcelExportProcessing || isPdfExportProcessing || isTaxRetunExcelExportProcessing;

    return { exportToExcelMutation, exportToPdfMutation, setExportedReports, exportTaxReturnListToExcelMutation, isProcessing };
};
