import { MessageBarType } from '@fluentui/react';
import { ReturnFolderDto, StackErrorMessage } from '../model';
import { validNameRuleRegExp } from './RegExpUtils';

//We use it like this because we cant modify cast right now 15-07-2024
const getErrorMessage = (errorList: StackErrorMessage[]): string => {
    if (errorList.length === 0) {
        return '';
    }

    const errorMessage = errorList[0].message || '';
    if (errorMessage === '') {
        return '';
    }

    const lastParagraphs = errorMessage.split('\r\n').filter((message: string) => message.trim() !== '');
    return lastParagraphs.length > 0 ? lastParagraphs[lastParagraphs.length - 1].trim() : '';
};
export const handleFolderCreation = (
    response: ReturnFolderDto,
    folderNameValue: string,
    message: string,
    displayFeedbackMessage: any,
    setIsErrorInput: any,
    menuItemContainerRef: any,
    finishEditing: any,
    clearIsErrorInput: any,
    onSaveFolder: any
) => {
    const errorMessage = getErrorMessage(response.errors || []);
    if (errorMessage !== '') {
        displayFeedbackMessage({ type: MessageBarType.error, message: errorMessage });
        setIsErrorInput();
        (menuItemContainerRef.current as HTMLElement).querySelector('input')?.focus();
        return;
    }
    finishEditing(folderNameValue, true);
    clearIsErrorInput();

    displayFeedbackMessage({ type: MessageBarType.success, message });

    if (onSaveFolder) onSaveFolder();
};

export const isInvalidFolderName = (folderNameValue: string): boolean | undefined => {
    return folderNameValue.trim() === '' || !validNameRuleRegExp.test(folderNameValue);
};
