import { IWhitePaperRow } from '../model';

export interface SelectedWhitePaperCell {
    displayRowNumber?: string | null;
    description?: string | null;
    definition?: string | null;
    valueEx?: string | null;
    columnFieldName?: string | null;
}

export const createSelectedWhitePaperCell = (whitePaperRow: IWhitePaperRow, columnFieldName: string): SelectedWhitePaperCell => {
    let value: string = '';

    if (whitePaperRow.values) {
        value = whitePaperRow.values![columnFieldName].current!;
    }

    return {
        displayRowNumber: whitePaperRow.displayRowNumber,
        description: whitePaperRow.description,
        definition: whitePaperRow.definition,
        valueEx: value,
        columnFieldName: columnFieldName,
    };
};
