import { MenuEventArgs } from '@syncfusion/ej2-react-navigations';
import { useTranslation } from 'react-i18next';
import { ICurrentCellEventArgs } from '../data-types/ICurrentCellEventArgs';
import { IContextMenuItemOptions } from './IContextMenuItemOptions';
import { ContextMenuItemModelExtended, ContextMenuVisibilityAccessor } from './useCommonIndividualReportTableContextMenu';

export const useDrillDownContextMenuItem = <T>(
    onMenuItemClick?: (args?: ICurrentCellEventArgs<T>) => void,
    getVisibilityState?: ContextMenuVisibilityAccessor
): IContextMenuItemOptions<T> => {
    const { t } = useTranslation();
    const drillDownMenuActionId = 'viewReportDrillDown';

    const contextMenuItem: ContextMenuItemModelExtended = {
        text: t('reportDrillDown').toString(),
        target: '.e-content',
        id: drillDownMenuActionId,
        iconCss: 'icon--fluent icon--fluent--data-line-20-regular',
        visibilityAccessor: getVisibilityState,
    };

    const handleContextMenuItemClick = (args: MenuEventArgs, currentCellArgs?: ICurrentCellEventArgs<T> | null | undefined) => {
        if (args.item.id === drillDownMenuActionId && onMenuItemClick) {
            onMenuItemClick(currentCellArgs!);
            return true;
        }
        return false;
    };

    return { contextMenuItem, handleContextMenuItemClick };
};
