import { useCorptaxTheme } from '@corptax/react-components-common';
import { useMemo } from 'react';
import { CommentsUIStyles } from '../data-types/CommentsUIStyles';

export const useCommentsUIStyles = (generateClassNames: boolean = false) => {
    const theme = useCorptaxTheme();
    const commentsStyles = useMemo(() => {
        const styles = new CommentsUIStyles(theme);
        if (generateClassNames) {
            styles.generateClassNames();
        }
        return styles;
    }, [generateClassNames, theme]);

    return commentsStyles;
};
