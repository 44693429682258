import { DefaultButton, Stack, StackItem } from '@fluentui/react';
import {
    ChangeEventArgs,
    Count,
    HtmlEditor,
    Inject,
    PasteCleanup,
    QuickToolbar,
    RichTextEditorComponent,
    Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import { useState } from 'react';
import { useCommentsUI } from '../../../hooks/useCommentsUI';
import { useCommentsUIStyles } from '../../../hooks/useCommentsUIStyles';
import { SpinnerButton } from '../../common/SpinnerButton';
import { CommentMentionComponent } from './CommentMentionComponent';
import { mentionParser } from './mentionParser';

export interface ICommentRichTextEditorValue {
    commentText: string;
    commentHtml: string;
    userMentions: Array<string>;
}
export interface ICommentRichTextEditorProps {
    commentHtml?: string;
    commentInputAriaLabel: string;
    commentInputPlaceholder: string;
    saveButtonAriaLabel: string;
    saveButtonText: string;
    onSaveButtonClick: (saveValue: ICommentRichTextEditorValue) => void;
    cancelButtonAriaLabel: string;
    cancelButtonText: string;
    onCancelButtonClick: () => void;
    processingText: string;
    onChange?: (saveValue: ICommentRichTextEditorValue) => void;
}

export const CommentRichTextEditor = (props: ICommentRichTextEditorProps) => {
    let editorRef: any;
    const styles = useCommentsUIStyles(true);
    const { state: uiState } = useCommentsUI();
    const [sendDisabled, setSendDisabled] = useState(true);

    // there should only be one of CommentRichTextEditor open at a time, but if we have issues,
    // we can useId to distinguish multiples. not doing that yet because not disposing of them grows the DOM.
    const rteIdentifier = `ctx-comment-rte`;
    const mentionTargetIdentifier = `#${rteIdentifier}_rte-edit-view`;

    function getCommentRichTextEditorSaveValue() {
        const mentions = mentionParser(editorRef.value);
        return {
            commentText: editorRef.getText(),
            commentHtml: editorRef.value,
            userMentions: mentions,
        };
    }

    function handleSaveButtonClick() {
        props.onSaveButtonClick(getCommentRichTextEditorSaveValue());
    }

    function actionBegin(args: any) {
        // disables drag and drop so users cannot upload files or images yet
        if (args.type === 'drop' || args.type === 'dragstart') {
            args.cancel = true;
        }
        // if the user hits enter while in the mention component, this stops it from creating a newline
        // we might need a check for if the popup is open, but for now this seems to work without it and the user
        // can still hit enter while in the editor and see a newline. don't ask me, it's syncfusion.
        if (args.requestType === 'EnterAction') {
            args.cancel = true;
        }
    }

    function handleChange(changeEventArgs: ChangeEventArgs) {
        const newValue = editorRef.getText();
        // an empty value is null, not an empty string
        // the value is html so if the user types only spaces you end up with a value of <p>&nbsp;</p>
        // so sadly we need to check the text property of the control to see if it's all whitespace
        if (newValue.trim() !== '' && changeEventArgs.value !== props.commentHtml) {
            setSendDisabled(false);
        } else {
            setSendDisabled(true);
        }

        if (props.onChange) {
            props.onChange(getCommentRichTextEditorSaveValue());
        }
    }

    return (
        <>
            <Stack>
                <StackItem>
                    {/* There is no keydown event to consume or any other way to know the user has made changes
                    other than relying on the change event, which doesn't fire very fast. little hack to set
                    autosave with a very short interval. Change won't be invoked if there are no changes from the last time it
                    checked, per their docs.
                    Since this is replacing a textfield that was invoking onChange on every keypress, it SHOULD be ok.
                    Also, we want showCharCount, but setting it to true bombs ever jest render; there is an open ticket with SF 
                    Also, readonly doesn't seem to work. (there is a disabled property but it's not documented and also doesn't work) */}

                    <RichTextEditorComponent
                        quickToolbarSettings={{ enable: false }}
                        autoSaveOnIdle={true}
                        saveInterval={200}
                        change={handleChange}
                        htmlAttributes={{ 'aria-label': props.commentInputAriaLabel }}
                        showCharCount={false}
                        toolbarSettings={{ items: ['Bold', 'Italic', 'Underline', '|', 'Undo', 'Redo'] }}
                        pasteCleanupSettings={{
                            keepFormat: false,
                            plainText: true,
                            prompt: false,
                        }}
                        maxLength={1000}
                        ref={(g) => (editorRef = g)}
                        id={rteIdentifier}
                        placeholder={props.commentInputPlaceholder}
                        actionBegin={actionBegin}
                        value={props.commentHtml}
                        readOnly={uiState.isSubmitting}
                    >
                        <Inject services={[HtmlEditor, Toolbar, PasteCleanup, Count, QuickToolbar]} />
                    </RichTextEditorComponent>
                    <CommentMentionComponent targetIdentifier={mentionTargetIdentifier} />
                </StackItem>
                <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 10, padding: 5 }} horizontalAlign={'end'}>
                        <SpinnerButton
                            ariaLabel={props.saveButtonAriaLabel}
                            text={props.saveButtonText}
                            onClick={handleSaveButtonClick}
                            disabled={sendDisabled}
                            processingText={props.processingText}
                            styles={styles.fluentV9ButtonWithChildrenAdjustment}
                            isProcessing={uiState.isSubmitting}
                        />
                        <DefaultButton
                            styles={styles.fluentV9ButtonAdjustment}
                            ariaLabel={props.cancelButtonAriaLabel}
                            onClick={props.onCancelButtonClick}
                        >
                            {props.cancelButtonText}
                        </DefaultButton>
                    </Stack>
                </StackItem>
            </Stack>
        </>
    );
};
