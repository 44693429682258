import { useCorptaxTheme } from '@corptax/react-components-common';
import { Link, mergeStyleSets } from '@fluentui/react';
import { IAppTheme } from '../../theme/IAppTheme';

export interface IErrorDisplayProps {
    errorMessage?: string;
    errorLinkText?: string;
    errorLinkAction?: () => void;
}

export const ErrorDisplay = (props: IErrorDisplayProps) => {
    const { palette } = useCorptaxTheme<IAppTheme>();
    const classNames = mergeStyleSets({
        errorMessage: {
            display: 'block',
            marginBottom: '5px',
            marginTop: '5px',
            color: palette.redDark,
            fontSize: '12px',
        },
        errorMessageContent: {
            display: 'inline-block',
            paddingRight: '10px',
        },
    });
    if (props.errorMessage) {
        return (
            <div role='alert'>
                <p className={classNames.errorMessage}>
                    <span className={classNames.errorMessageContent} data-automation-id='error-message'>
                        {props.errorMessage}
                    </span>
                    {props.errorLinkText && props.errorLinkAction && (
                        <Link aria-label={props.errorLinkText} onClick={props.errorLinkAction}>
                            {props.errorLinkText}
                        </Link>
                    )}
                </p>
            </div>
        );
    }
    return null;
};
