import { getValue, isNullOrUndefined } from '@syncfusion/ej2-base';
import { CellEditArgs, CellSaveArgs, GridComponent, RowDataBoundEventArgs, RowInfo } from '@syncfusion/ej2-react-grids';
import { Dispatch, RefObject, useCallback } from 'react';
import { accountAmountRowPropertyNames } from '../components/enter-amounts-dialog/AccountAmountRowPropertyNames';
import { getUpdatedAccountAmountRow, IAccountAmountRow } from '../components/sidebar/AdjustmentAccountAndAmountAssociationDialog';
import {
    AdjustmentAccountAmountAction,
    AdjustmentAccountAmountDialogReducerActionParams,
} from '../reducers/adjustmentAccountAmountReducer';

export interface IDebitCreditColumnEditingGridComponentHook {
    onCellSave: (args: CellSaveArgs) => void;
    onGridCreated: () => void;
    onCellEdit: (args: CellEditArgs) => void;
    onCellSaved: (args: CellSaveArgs) => void;
    onRowDataBound: (args: RowDataBoundEventArgs) => void;
}

export const useDebitCreditColumnEditingGridComponent: (
    gridRef: RefObject<GridComponent>,
    updateState: Dispatch<AdjustmentAccountAmountDialogReducerActionParams>,
    saveIsDisabled: boolean
) => IDebitCreditColumnEditingGridComponentHook = (
    gridRef: RefObject<GridComponent>,
    updateState: Dispatch<AdjustmentAccountAmountDialogReducerActionParams>,
    saveIsDisabled: boolean
) => {
    const onCellSave = useCallback(
        (args: CellSaveArgs) => {
            if (!args) {
                return;
            }

            if (!args.value && args.previousValue) {
                // Even though the Typescript type definition of CellSaveArgs defines the value property as a string,
                // numbers are allowed at runtime and are legitimate.
                // When you set this property to the string '0', an exception is thrown from the syncfusion number formatter
                // because it is trying to call toFixed() on args.value, but that function only exists for numbers.
                // So we just need to bypass the compiler's type checking here.
                args.value = 0 as unknown as string;
            } else if (args.value === (0 as unknown as string) && !args.previousValue) {
                args.value = null as unknown as string;
            }
            const valueIsPresent: boolean = !isNullOrUndefined(args.value!);
            if (!valueIsPresent) {
                return;
            }
            if (args.value !== args.previousValue && saveIsDisabled) {
                updateState({ type: AdjustmentAccountAmountAction.CellEdited });
            }
            const argColumnName: string = args.columnName!;
            const otherColumnFieldName: string =
                argColumnName === accountAmountRowPropertyNames.debitTotal
                    ? accountAmountRowPropertyNames.creditTotal
                    : accountAmountRowPropertyNames.debitTotal;
            const rowInfo: RowInfo = gridRef.current!.getRowInfo(args.cell!);
            const batchChanges: { changedRecords: IAccountAmountRow[] } = gridRef.current!.getBatchChanges() as {
                changedRecords: IAccountAmountRow[];
            };

            const numericValue: number = parseInt(args.value!);
            // this object represents the state of this row when the component was initially rendered
            const originalRowData: IAccountAmountRow = rowInfo.rowData as IAccountAmountRow;
            // in order to make sure our logic is working on the most current values, we need to find the version of the row being stored in batch changes
            const currentRowData: IAccountAmountRow =
                getUpdatedAccountAmountRow(originalRowData, batchChanges) ?? (rowInfo.rowData as IAccountAmountRow);
            const otherColumnHasValue: boolean = !isNullOrUndefined(currentRowData[otherColumnFieldName as keyof IAccountAmountRow]!);
            if (numericValue < 0) {
                gridRef.current!.updateCell(rowInfo.rowIndex!, otherColumnFieldName, Math.abs(numericValue));
            } else if (numericValue === 0) {
                const accountType: string | null | undefined = currentRowData.accountBalanceType;
                const columnToUpdate: string =
                    accountType === 'Debit' ? accountAmountRowPropertyNames.debitTotal : accountAmountRowPropertyNames.creditTotal;
                if (columnToUpdate !== argColumnName) {
                    gridRef.current!.updateCell(rowInfo.rowIndex!, columnToUpdate, numericValue);
                    args.value = null as unknown as string;
                } else if (otherColumnHasValue) {
                    // The Typescript definition for updateCell does not allow null, but the underlying Javascript method allows this.
                    // Additionally, our system treats a null value differently than a value of zero, and both are legitimate.
                    // So we need to bypass the compiler's type checking to set a cell value to null.
                    gridRef.current!.updateCell(rowInfo.rowIndex!, otherColumnFieldName, null as unknown as number);
                }
            } else if (otherColumnHasValue) {
                // The Typescript definition for updateCell does not allow null, but the underlying Javascript method allows this.
                // Additionally, our system treats a null value differently than a value of zero, and both are legitimate.
                // So we need to bypass the compiler's type checking to set a cell value to null.
                gridRef.current!.updateCell(rowInfo.rowIndex!, otherColumnFieldName, null as unknown as number);
            }
        },
        [updateState, gridRef, gridRef.current, parseInt]
    );
    const editCell = (args: HTMLElement) => {
        gridRef?.current!.editModule.editCell(
            parseInt(args!.getAttribute('index')!),
            gridRef?.current!.getColumnByIndex(parseInt(args!.getAttribute('data-colindex')!)).field
        );
    };

    const onGridCreated = () => {
        const accountAmountGrid = gridRef?.current! as GridComponent;
        accountAmountGrid.getContentTable().addEventListener('click', (args: Event) => {
            const targetElement = args.target as HTMLElement;
            if (targetElement.classList?.contains('e-rowcell')) {
                editCell(targetElement);
            }
        });
        accountAmountGrid.element.addEventListener('keydown', (e: KeyboardEvent) => {
            const eventTarget = e.target as HTMLElement;
            var closestTd = eventTarget.closest('td');
            if (e.code === 'Enter') {
                if (eventTarget.tagName !== 'INPUT') {
                    e.preventDefault();
                    editCell(closestTd!);
                }
            }
            if (e.code === 'Escape') {
                if (eventTarget.tagName === 'INPUT') {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        });
    };

    function onCellEdit(args: CellEditArgs) {
        const rowData = args.rowData as IAccountAmountRow;
        if (rowData.disabled === true) {
            args.cancel = true;
        }
    }

    const onCellSaved = useCallback(
        (args: CellSaveArgs) => {
            if (args?.value && parseInt(args.value) < 0) {
                const rowInfo: RowInfo = gridRef.current!.getRowInfo(args.cell!);
                gridRef.current!.updateCell(rowInfo.rowIndex!, args.columnName!, null as unknown as number);
            }
        },
        [gridRef, gridRef.current, parseInt]
    );

    const onRowDataBound = (args: RowDataBoundEventArgs) => {
        if (args.row) {
            if (getValue('disabled', args.data) === true) {
                args.row.classList.add('disabledRow');
            }
        }
    };

    return { onCellSave, onGridCreated, onCellEdit, onCellSaved, onRowDataBound } as IDebitCreditColumnEditingGridComponentHook;
};
