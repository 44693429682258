import { Button, Checkbox, CheckboxOnChangeData, makeStyles } from '@fluentui/react-components';
import { ChangeEvent, FC, MouseEventHandler, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { IAdjustment, ICalendarPeriod, ICase, IEntity, IJurisdiction } from '../../model';
import {
    EnterAmountsDialogFiltersActionType,
    EnterAmountsDialogFiltersReducer,
    enterAmountsDialogFiltersReducer,
    IEnterAmountsDialogFiltersState,
} from '../../reducers/enterAmountsDialogFiltersReducer';
import { AdjustmentCombobox, IEnterAmountsDialogAdjustmentComboboxProps } from './AdjustmentCombobox';
import { CalendarPeriodCombobox, IEnterAmountsDialogCalendarPeriodComboboxProps } from './CalendarPeriodCombobox';
import { CaseCombobox, IEnterAmountsDialogCaseComboboxProps } from './CaseCombobox';
import { EntityCombobox, IEnterAmountsDialogEntityComboboxProps } from './EntityCombobox';
import { IEnterAmountsDialogJurisdictionComboboxProps, JurisdictionCombobox } from './JurisdictionCombobox';

export interface IEnterAmountsDialogFiltersFilterSelections {
    adjustment: IAdjustment | undefined;
    amountsOnly: boolean;
    calendarPeriod: ICalendarPeriod | undefined;
    case: ICase | undefined;
    combineDebitCreditColumns: boolean;
    entity: IEntity | undefined;
    jurisdiction: IJurisdiction | undefined;
}

export interface IEnterAmountsDialogFiltersProps {
    entityFilter: Pick<IEnterAmountsDialogEntityComboboxProps, 'options' | 'selectedOption' | 'clearable'>;
    caseFilter: Pick<IEnterAmountsDialogCaseComboboxProps, 'options' | 'selectedOption' | 'clearable'>;
    jurisdictionFilter: Pick<IEnterAmountsDialogJurisdictionComboboxProps, 'options' | 'selectedOption' | 'clearable'>;
    yearFilter: Pick<IEnterAmountsDialogCalendarPeriodComboboxProps, 'options' | 'selectedOption' | 'clearable'>;
    adjustmentFilter: Pick<IEnterAmountsDialogAdjustmentComboboxProps, 'options' | 'selectedOption' | 'clearable'>;
    onFilterUpdate?: (filterSelections: IEnterAmountsDialogFiltersFilterSelections) => void;
    onFilterReset?: () => void;
    amountsOnly: boolean;
    combineDebitCreditColumns: boolean;
}

export const comboBoxCheckIconStyles = makeStyles({
    hidden: {
        '>.fui-Option__checkIcon': {
            display: 'none',
        },
    },
});

export const findCalendarPeriodDtoByCode = (
    years: ICalendarPeriod[] | null | undefined,
    periodCode: string | undefined
): ICalendarPeriod | undefined => {
    return years?.find((calendarPeriodDto: ICalendarPeriod) => calendarPeriodDto.periodYear?.toString() === periodCode);
};

const EnterAmountsDialogFilters: FC<IEnterAmountsDialogFiltersProps> = (props: IEnterAmountsDialogFiltersProps) => {
    const initialState: IEnterAmountsDialogFiltersState = {
        applyButtonDisabled: true,
        currentFilterSelections: {
            adjustment: props.adjustmentFilter.selectedOption,
            amountsOnly: props.amountsOnly,
            calendarPeriod: props.yearFilter.selectedOption,
            case: props.caseFilter.selectedOption,
            combineDebitCreditColumns: props.combineDebitCreditColumns,
            entity: props.entityFilter.selectedOption,
            jurisdiction: props.jurisdictionFilter.selectedOption,
        },
    };
    const [state, dispatch] = useReducer<EnterAmountsDialogFiltersReducer>(enterAmountsDialogFiltersReducer, initialState);
    const { buttonOverrides } = useEnterAmountsDialogStyles();
    const { t } = useTranslation();

    const applyButtonClickHandler: MouseEventHandler<HTMLButtonElement> = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        dispatch({ type: EnterAmountsDialogFiltersActionType.ApplyButtonClicked });
        props.onFilterUpdate && props.onFilterUpdate(state.currentFilterSelections);
    };
    const resetButtonClickHandler: MouseEventHandler<HTMLButtonElement> = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        dispatch({ type: EnterAmountsDialogFiltersActionType.ResetButtonClicked, initialState });
        props.onFilterReset && props.onFilterReset();
    };
    const onAmountsOnlyChange = (event: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        dispatch({
            type: EnterAmountsDialogFiltersActionType.AmountsOnlyChanged,
            newFilterValue: data.checked ? true : false,
        });
    };

    const onCombineDebitCreditColumnsChange = (event: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        dispatch({
            type: EnterAmountsDialogFiltersActionType.CombineDebitCreditColumnsChanged,
            newFilterValue: data.checked ? true : false,
        });
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                height: 'inherit',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    height: 'inherit',
                }}
            >
                <CalendarPeriodCombobox
                    options={props.yearFilter.options}
                    selectedOption={state.currentFilterSelections.calendarPeriod}
                    dispatch={dispatch}
                    key={state.currentFilterSelections.calendarPeriod?.periodYear?.toString()}
                />
                <CaseCombobox
                    options={props.caseFilter.options}
                    selectedOption={state.currentFilterSelections.case}
                    dispatch={dispatch}
                    key={state.currentFilterSelections.case?.code}
                />
                <EntityCombobox
                    options={props.entityFilter.options}
                    selectedOption={state.currentFilterSelections.entity}
                    dispatch={dispatch}
                    key={state.currentFilterSelections.entity?.code}
                />
                <JurisdictionCombobox
                    options={props.jurisdictionFilter.options}
                    selectedOption={state.currentFilterSelections.jurisdiction}
                    dispatch={dispatch}
                    key={state.currentFilterSelections.jurisdiction?.code}
                />
                <AdjustmentCombobox
                    options={props.adjustmentFilter.options}
                    selectedOption={state.currentFilterSelections.adjustment}
                    dispatch={dispatch}
                    clearable={props.adjustmentFilter.clearable}
                    key={state.currentFilterSelections.adjustment?.adjustmentCode}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Checkbox
                        label={t('amountsOnly').toString()}
                        checked={state.currentFilterSelections.amountsOnly}
                        onChange={onAmountsOnlyChange}
                        key={`amountsOnly-${state.currentFilterSelections.amountsOnly}`}
                    />
                    <Checkbox
                        label={t('combineDebitCreditColumns').toString()}
                        checked={state.currentFilterSelections.combineDebitCreditColumns}
                        onChange={onCombineDebitCreditColumnsChange}
                        key={`combineDebitCreditColumns-${state.currentFilterSelections.combineDebitCreditColumns}`}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                    appearance='primary'
                    className={buttonOverrides}
                    size='small'
                    disabled={state.applyButtonDisabled}
                    onClick={applyButtonClickHandler}
                    aria-label={t('apply').toString()}
                >
                    {t('apply')}
                </Button>
                <Button
                    appearance='secondary'
                    className={buttonOverrides}
                    size='small'
                    onClick={resetButtonClickHandler}
                    aria-label={t('reset').toString()}
                >
                    {t('reset')}
                </Button>
            </div>
        </div>
    );
};

export default EnterAmountsDialogFilters;
