import * as React from 'react';
import { SelectedWhitePaperCell } from '../data-types/selectedWhitePaperCell';

export interface IDrillDownValues {
    traceCalculationData: Array<SelectedWhitePaperCell>;
    setTraceCalculationData: (traceCalculationData: SelectedWhitePaperCell[]) => void;
    selectedTabKey: number;
}

export const DrillDownContext = React.createContext<IDrillDownValues | null>(null);

export function DrillDownContextProvider(props: { value?: IDrillDownValues; children?: React.ReactNode }) {
    const [traceCalculationData, setTraceCalculationData] = React.useState<Array<SelectedWhitePaperCell>>([]);
    const selectedTabKey = props.value?.selectedTabKey ?? 0;
    return (
        <DrillDownContext.Provider value={props.value ?? { traceCalculationData, setTraceCalculationData, selectedTabKey: selectedTabKey }}>
            {props.children}
        </DrillDownContext.Provider>
    );
}
