export function isDevelopment(): boolean {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEVELOPMENT_MODE === '1';
}

export const getBffUrl = (host: string): string => {
    if (host.includes('localhost')) {
        return 'https://localhost:44357';
    }

    const newHostUri = host.replace('calcreview', 'calcreview-api');
    return `https://${newHostUri}`;
};

export const getCartManagerUrl = (serviceUri: string): string => {
    return `${serviceUri}/CorptaxPortal/Automation/Cart`;
};

export const getCalculationReviewUrl = (host: string): string => {
    if (host.includes('localhost')) {
        return 'http://localhost:3000/';
    }

    return `https://${host}/`;
};
