import { corptaxCustomLightTheme } from '@corptax/react-components-common';
import { concatStyleSets, IGroupHeaderStyles, IRawStyle, IStyle, mergeStyles, mergeStyleSets } from '@fluentui/react';
import {
    IDetailsColumnStyles,
    IDetailsHeaderStyles,
    IDetailsListStyles,
    IDetailsRowStyles,
    IGroupedListStyles,
} from '@fluentui/react/lib/DetailsList';
import { ArrayUtils } from '../../utils/ArrayUtils';
import {
    cardHeaderClass,
    cellContainerClass,
    expandCollapseClass,
    gridContainerClass,
    groupHeaderNameClass,
    msDetailHeaderCellClass,
    msDetailHeaderCellNameClass,
    msDetailRowCellClass,
    msGroupedListGroupClass,
    msGroupHeaderClass,
    msGroupHeaderExpandClass,
    msGroupSpacerClass,
    msListCellClass,
    msListClass,
    msListPageClass,
    msListSurfaceClass,
    msTooltipHost,
    noRecordsRowClass,
} from './CardGridListConstants';
import {
    ICardGridListCellStyle,
    ICardGridListColumn,
    ICardGridListCustomRow,
    ICardGridListCustomRowStyles,
    ICardGridListGroup,
    ICardGridListStyling,
    IColumnExtended,
    IGroupExtended,
} from './CardGridListInterfaces';

export function styleUsing(): ICardGridListStyling {
    return new CardGridListStyling();
}

class CardGridListStyling implements ICardGridListStyling {
    constructor() {
        this.detailsColumnStyles = CardGridListStyling.constructDetailsColumnStyleSet;
        this.detailsRowStyles = CardGridListStyling.constructDetailsRowStyleSet;
        this.gridContainerClass = this.constructGridContainerStyle;
        this.groupedListStyles = CardGridListStyling.constructGroupedListStyleSet;
        this.groupHeaderStyles = CardGridListStyling.constructGroupHeaderStyleSet;
    }

    public get cardGridListClassName(): string {
        return CardGridListStyling._cardGridListStyleClassName;
    }

    public get detailsListStyles(): Partial<IDetailsListStyles> {
        return CardGridListStyling._detailsListGroupsStyleSet;
    }

    public get detailsHeaderStyles(): Partial<IDetailsHeaderStyles> {
        return CardGridListStyling._detailsHeaderStyleSet;
    }

    public get detailListNoRecordClassName(): string {
        return CardGridListStyling._detailListNoRecordClassName;
    }

    public readonly detailsColumnStyles: (
        columns: ICardGridListColumn[],
        defaultColumnStyle: ICardGridListCellStyle
    ) => Partial<IDetailsColumnStyles>;

    public readonly detailsRowStyles: (columns: IColumnExtended[]) => IDetailsRowStyles;

    public readonly gridContainerClass: (height?: string) => string;

    public readonly groupedListStyles: (
        groupHeaderCellStyle: ICardGridListCellStyle | undefined,
        rowCellStyle: ICardGridListCellStyle | undefined,
        columns: IColumnExtended[],
        groups: IGroupExtended[],
        customRows?: ICardGridListCustomRow[],
        customRowStyles?: ICardGridListCustomRowStyles
    ) => Partial<IGroupedListStyles>;

    public readonly groupHeaderStyles: (
        customRows: ICardGridListCustomRow | ICardGridListCustomRow[] | undefined
    ) => Partial<IGroupHeaderStyles>;

    private static readonly _detailListNoRecordClassName: string = mergeStyles({
        displayName: 'detail-list-no-record',
        margin: '5px',
        borderRadius: 'var(--listcard-border-radius)',
        padding: '7px 7px 7px 30px',
        fontSize: '10px',
        color: '${(props) => props.customPalette.themePrimary !important',
        backgroundColor: 'rgb(247, 247, 247)',
        cursor: 'default',
    });

    private static readonly _cardGridListStyleClassName: string = mergeStyles({
        displayName: 'card-grid-list',
        '--list-header-list-card-vertical-gap': 'calc(8px - var(--listcard-box-shadow-margin))',
        '--list-header-font-size': '9px',
        '--list-header-font-style': 'normal',
        '--list-header-font-weight': '600',
        '--list-header-height': '22px',
        '--listcard-border-radius': '6px',
        '--listcard-box-shadow': '0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10), 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13)',
        '--listcard-box-shadow-margin': '6px',
        '--listcard-header-font-size': '10px',
        '--listcard-header-font-style': 'normal',
        '--listcard-header-font-weight': '550',
        '--listcard-header-height': '26px',
        '--listcard-row-font-size': '9px',
        '--listcard-row-font-style': 'normal',
        '--listcard-row-font-weight': '550',
        '--listcard-row-height': '26px',
        '--listcard-vertical-gap': '10px',
    });

    private static readonly _detailsListBaseStyleSet: Partial<IDetailsListStyles> = {
        contentWrapper: {
            height: 'calc(100% - var(--list-header-height))',
            margin: 'var(--list-header-list-card-vertical-gap) 0 0',
            overflowY: 'auto',
            scrollbarGutter: 'stable',
            width: 'calc(100% + .656vw)',
        },
        focusZone: undefined,
        headerWrapper: {
            [`.${msDetailHeaderCellClass}`]: {
                borderRadius: '',
                height: '',
                left: '',
                top: '',
                width: '',
            },
            [`.${msDetailHeaderCellNameClass}`]: {
                fontFamily: '',
                fontSize: '',
                fontStyle: '',
                fontWeight: '',
            },
            '> div': {
                border: 'none',
                backgroundColor: corptaxCustomLightTheme.colorBrandBackground2,
                display: 'flex',
                height: 'var(--list-header-height)',
                padding: '0',
            },
            display: 'block',
            height: 'var(--list-header-height)',
            padding: '0 6px',
        },
        root: {
            [`.${msListCellClass}`]: {
                minHeight: '0px',
            },
            left: '0',
            letterSpacing: '-0.128px',
            margin: '0',
            overflowX: 'visible',
            padding: '0',
        },
    };

    private static readonly _detailsListGroupsStyleSet: Partial<IDetailsListStyles> = mergeStyleSets(
        CardGridListStyling._detailsListBaseStyleSet,
        {
            root: {
                [`.${cellContainerClass}`]: {
                    [`.${msGroupSpacerClass}`]: {
                        display: 'inline-block !important',
                    },
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                [`.${groupHeaderNameClass}`]: {
                    display: 'inline-block',
                    lineHeight: 'var(--listcard-header-height)',
                    overflowX: 'visible',
                },
                [`.${msGroupSpacerClass}`]: {
                    display: 'none !important',
                },
            },
        }
    );

    private static readonly _detailsHeaderStyleSet: Partial<IDetailsHeaderStyles> = {
        root: {
            displayName: 'cardGridListHeader',
            [`> div.${msDetailHeaderCellClass}`]: {
                height: 'var(--list-header-height)',
            },
        },
        cellIsCheck: {
            display: 'none !important',
        },
        cellIsGroupExpander: {
            display: 'none !important',
        },
        collapseButton: {
            display: 'none !important',
        },
    };

    private static readonly _detailsColumnBaseStyleSet: Partial<IDetailsColumnStyles> = {
        cellName: {
            color: 'inherit',
            display: 'inherit',
            fontSize: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'inherit',
            letterSpacing: '-0.128px',
            lineHeight: 'var(--list-header-height)',
            minHeight: 'var(--list-header-height)',
            overflowX: 'hidden',
            overflowY: 'visible',
            padding: '',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        },
        cellTitle: {
            ':hover': {
                backgroundColor: 'inherit !important',
                color: 'inherit !important',
            },
            alignItems: '',
            boxSizing: '',
            display: 'inherit',
            flexDirection: '',
            height: '100%',
            justifyContent: '',
            outline: '',
            padding: '',
        },
        root: {
            ':hover': {
                backgroundColor: 'inherit !important',
                color: 'inherit !important',
            },
            '> span': {
                position: 'relative',
            },
            boxSizing: 'border-box',
            display: 'block',
            flexGrow: '1',
            flexShrink: '1',
            margin: '',
            overflowX: 'hidden',
            overflowY: 'visible',
            padding: '0 10px',
        },
    };

    private static readonly _detailsRowBaseStyleSet: IDetailsRowStyles = {
        cell: {
            displayName: 'cardGridListRowCell',
            ':not(:has(> *)):not(:empty)': {
                cursor: 'text',
            },
            '*:not(:has(> *)):not(:empty)': {
                cursor: 'text',
            },
            [`.${msTooltipHost}`]: {
                cursor: 'text',
            },
            boxSizing: 'border-box',
            display: 'block',
            flexGrow: '1',
            flexShrink: '1',
            minHeight: 'inherit',
            overflowX: 'hidden',
            // we changed the height of each row in the card grid list from 36px to 26px
            // the height of the 'quick add modal' button is 22px. even though 22 < 26,
            // the scrollbar is appearing. setting this to hidden should fix the issue
            // without requiring us to deviate from the styles given to us in Figma
            overflowY: 'hidden',
            padding: '0 10px',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            verticalAlign: 'middle',
            width: '0 !important',
        },
        fields: {
            display: 'flex',
            flexFlow: 'row',
            minHeight: 'inherit',
        },
        root: {
            border: '0',
            display: 'block',
            minHeight: 'var(--listcard-row-height)',
        },
        cellAnimation: undefined,
        cellUnpadded: undefined,
        cellPadded: undefined,
        checkCell: undefined,
        isRowHeader: undefined,
        isMultiline: undefined,
        cellMeasurer: undefined,
        check: undefined,
    };

    private static readonly _gridContainerBaseStyle: IRawStyle = {
        displayName: gridContainerClass,
        padding: '0 0 5px',
    };

    private static readonly _groupedListBaseStyleSet: Partial<IGroupedListStyles> = {
        root: {
            [`.${msListCellClass}`]: {
                minHeight: '',
            },
            [`> div.${msListClass} > div.${msListSurfaceClass} > div.${msListPageClass}`]: {
                [`+ div.${msListPageClass}`]: {
                    marginTop: 'var(--listcard-vertical-gap)',
                },
                [`> div.${msListCellClass}`]: {
                    [`> div.${msGroupedListGroupClass}`]: {
                        margin: 'var(--listcard-box-shadow-margin)',
                        borderRadius: 'var(--listcard-border-radius)',
                        // setting overflow to hidden allows us to ensure
                        // the border radius on this element is still visible
                        // without having to selectively set the border radius for bottom/top left/right on any descendant elements
                        overflow: 'hidden',
                        boxShadow: 'var(--listcard-box-shadow)',
                    },
                    minHeight: 'var(--listcard-header-height)',
                },
            },
            display: 'block',
        },
        group: {
            border: '1px solid var(--listcard-border-color, none)',
        },
    };

    private static readonly _groupHeaderBaseStyleSet: Partial<IGroupHeaderStyles> = {
        check: {
            display: 'none !important',
        },
        dropIcon: {
            display: 'none !important',
        },
        expand: {
            display: 'none !important',
        },
        expandIsCollapsed: {
            display: 'none !important',
        },
        groupHeaderContainer: {
            [`> div[role="gridcell"]:has(> button.${msGroupHeaderExpandClass})`]: {
                display: 'none !important',
            },
            alignItems: '',
            backgroundColor: 'inherit',
            color: 'inherit',
            display: 'inherit',
            font: 'inherit',
            height: '',
            minHeight: 'inherit',
        },
        headerCount: {
            display: 'none !important',
        },
        root: {
            [`.${expandCollapseClass}`]: {
                cursor: 'pointer',
                display: 'inline-block',
                height: '20px',
                margin: '0 1px 0 -1px',
                position: 'relative',
                top: '2px',
                width: '20px',
            },
            [`.${msListCellClass}`]: {
                minHeight: '',
            },
            [`:is(.${noRecordsRowClass})`]: {
                lineHeight: 'var(--listcard-row-height)',
            },
            [`div[aria-expanded="true"] .${expandCollapseClass} svg`]: {
                transform: 'rotate(90deg); transition: transform 200ms ease-out 0s;',
            },
            [`div[aria-expanded="false"] .${expandCollapseClass} svg`]: {
                transform: 'rotate(0deg); transition: transform 200ms ease-out 0s;',
            },
            border: 0,
            minWidth: 'revert !important',
            userSelect: '',
        },
        title: {
            backgroundColor: 'inherit',
            boxSizing: 'border-box',
            color: 'inherit',
            cursor: 'text',
            display: 'block',
            font: 'inherit',
            padding: '0, 10px',
            width: '100%',
        },
    };

    private static constructCustomRowCellStyles(
        customRows: ICardGridListCustomRow | ICardGridListCustomRow[] | undefined,
        customRowStyles: ICardGridListCustomRowStyles | undefined,
        groups: IGroupExtended[],
        columns: IColumnExtended[]
    ): Partial<IGroupedListStyles> {
        const styles: Partial<IGroupedListStyles>[] = [];

        for (const customRow of Object.values(ArrayUtils.ensureArray(customRows))) {
            const style: Partial<IGroupedListStyles> = CardGridListStyling.constructRowCellStyles(
                customRowStyles ? customRowStyles[customRow.className] : undefined,
                groups,
                columns,
                customRow.className
            );

            styles.push(style);
        }

        return concatStyleSets(...styles) as Partial<IGroupedListStyles>;
    }

    private static constructDetailsColumnStyleSet(
        columns: ICardGridListColumn[],
        defaultColumnStyle: ICardGridListCellStyle
    ): Partial<IDetailsColumnStyles> {
        let detailsColumnStyles: Partial<IDetailsColumnStyles> = CardGridListStyling._detailsColumnBaseStyleSet;
        let columnStylesDefined: boolean = false;
        let columnStyles: IRawStyle = {};

        for (const column of Object.values<ICardGridListColumn>(columns)) {
            const columnStyle: IRawStyle = {};
            let columnStyleDefined: boolean = (columnStyle.flexBasis = column.columnWidth) !== undefined;

            if (column.headerHidden) {
                columnStyle.display = 'none';
                columnStyleDefined = true;
            }

            if (column.disableColumnFlexSizing) {
                columnStyle.flexGrow = '0';
                columnStyle.flexShrink = '0';
                columnStyleDefined = true;
            }

            if (columnStyleDefined) {
                const key: string = `:is([data-item-key="${column.fieldName}"])`;

                columnStyles[key] = columnStyle;
                columnStylesDefined = true;
            }
        }

        if (defaultColumnStyle && Object.entries(defaultColumnStyle).length > 0) {
            columnStyles = {
                ...columnStyles,
                ...defaultColumnStyle,
            };

            columnStylesDefined = true;
        }

        if (columnStylesDefined) {
            const rootStyles: Partial<IDetailsColumnStyles> = {
                root: columnStyles,
            };

            detailsColumnStyles = concatStyleSets(detailsColumnStyles, rootStyles);
        }

        return detailsColumnStyles;
    }

    private static constructDetailsRowStyleSet(columns: IColumnExtended[]): IDetailsRowStyles {
        let styles: IDetailsRowStyles = CardGridListStyling._detailsRowBaseStyleSet;
        let stylesDefined: boolean = false;
        const columnStyles: IRawStyle = {};

        for (const column of Object.values<IColumnExtended>(columns)) {
            const sourceColumn: ICardGridListColumn = column.cardGridListColumn;
            const columnStyle: IRawStyle = {};
            let columnStyleDefined: boolean = false;

            columnStyleDefined = (columnStyle.flexBasis = sourceColumn.columnWidth) !== undefined || columnStyleDefined;

            if (sourceColumn.disableColumnFlexSizing) {
                columnStyleDefined = (columnStyle.flexGrow = '0') !== undefined || columnStyleDefined;
                columnStyleDefined = (columnStyle.flexShrink = '0') !== undefined || columnStyleDefined;
            }

            if (columnStyleDefined) {
                const key: string = `:is([data-automation-key="${column.fieldName}"])`;

                columnStyles[key] = columnStyle;
                stylesDefined = true;
            }
        }

        if (stylesDefined) {
            const cellStyles: Partial<IDetailsRowStyles> = {
                cell: columnStyles,
            };

            styles = concatStyleSets(styles, cellStyles);
            styles = mergeStyleSets(styles);
        }

        return styles;
    }

    private constructGridContainerStyle(height?: string): string {
        let gridContainerStyle: IStyle = CardGridListStyling._gridContainerBaseStyle;

        if (height) {
            gridContainerStyle = {
                '> div': {
                    '> div': {
                        '> div': {
                            height: 'inherit',
                        },
                        height: 'inherit',
                    },
                    height: 'inherit',
                },
                ...gridContainerStyle,
                height: height,
            };
        }

        return mergeStyles(gridContainerStyle);
    }

    private static constructGroupHeaderCellStyles(
        listStyle: ICardGridListCellStyle | undefined,
        groups: IGroupExtended[],
        columns: IColumnExtended[]
    ): Partial<IGroupedListStyles> {
        const styles: IRawStyle = {};
        let stylesDefined: boolean = CardGridListStyling.isCellStyleDefined(listStyle);
        const groupStyles: IRawStyle = {};

        for (const group of Object.values<IGroupExtended>(groups)) {
            const sourceGroup: ICardGridListGroup = group.cardGridListGroup;

            if (CardGridListStyling.isCellStyleDefined(sourceGroup.headerStyle)) {
                const groupKey: string = `:is(.${group.groupClassName})`;

                groupStyles[groupKey] = { ...sourceGroup.headerStyle };
                stylesDefined = true;
            }
        }

        const columnStyles: IRawStyle = {};

        for (const column of Object.values<IColumnExtended>(columns)) {
            const sourceColumn: ICardGridListColumn = column.cardGridListColumn;

            if (CardGridListStyling.isCellStyleDefined(sourceColumn.groupHeaderStyle)) {
                //tech debt why are we using keys here?
                const columnKey: string = `div.${msDetailRowCellClass}[data-automation-key="${column.key}"]`;

                columnStyles[columnKey] = { ...sourceColumn.groupHeaderStyle };
                stylesDefined = true;
            }
        }

        if (stylesDefined) {
            const anyGroupHeaderKey: string = `div.${msGroupHeaderClass}.${cardHeaderClass}`;

            styles[anyGroupHeaderKey] = {
                ...listStyle,
                ...groupStyles,
                ...columnStyles,
            };
        }

        return {
            root: {
                ...styles,
            },
        };
    }

    private static constructRowCellStyles(
        listStyle: ICardGridListCellStyle | undefined,
        groups: IGroupExtended[],
        columns: IColumnExtended[],
        customRowClassName?: string
    ): Partial<IGroupedListStyles> {
        const styles: IRawStyle = {};
        let stylesDefined: boolean = false;
        const customRowQueryText: string = customRowClassName ? `.${customRowClassName}` : '';
        const listStyles: IRawStyle = {};

        if (CardGridListStyling.isCellStyleDefined(listStyle)) {
            const listKey: string = `+ div.${msListClass} div.${msGroupHeaderClass}${customRowQueryText}`;

            listStyles[listKey] = { ...listStyle };
            stylesDefined = true;
        }

        const groupStyles: IRawStyle = {};

        for (const group of Object.values<IGroupExtended>(groups)) {
            const cardGridListGroup: ICardGridListGroup = group.cardGridListGroup;
            const rowStyle: ICardGridListCellStyle | undefined = customRowClassName
                ? CardGridListStyling.getCustomRowStyle(customRowClassName, cardGridListGroup.customRowStyles)
                : cardGridListGroup.rowStyle;

            if (CardGridListStyling.isCellStyleDefined(rowStyle)) {
                const groupKey: string = `:has(> div.${group.groupClassName}) + div.${msListClass} div.${msGroupHeaderClass}${customRowQueryText}`;

                groupStyles[groupKey] = { ...rowStyle };
                stylesDefined = true;
            }
        }

        const columnStyles: IRawStyle = {};

        for (const column of Object.values<IColumnExtended>(columns)) {
            const cardGridListColumn: ICardGridListColumn = column.cardGridListColumn;
            const rowStyle: ICardGridListCellStyle | undefined = customRowClassName
                ? CardGridListStyling.getCustomRowStyle(customRowClassName, cardGridListColumn.customRowStyles)
                : cardGridListColumn.rowStyle;

            if (CardGridListStyling.isCellStyleDefined(rowStyle)) {
                const columnKey: string = `+ div.${msListClass} div.${msGroupHeaderClass}${customRowQueryText} div.${msDetailRowCellClass}[data-automation-key="${column.key}"]`;
                const columnStyle: IRawStyle = { ...rowStyle };

                columnStyles[columnKey] = columnStyle;
                stylesDefined = true;
            }
        }

        if (stylesDefined) {
            const anyGroupHeaderParentKey: string = `div:has(> div.${msGroupHeaderClass}.${cardHeaderClass})`;

            styles[anyGroupHeaderParentKey] = {
                ...listStyles,
                ...groupStyles,
                ...columnStyles,
            };
        }

        return {
            root: {
                ...styles,
            },
        };
    }

    private static constructGroupedListStyleSet(
        groupHeaderCellStyle: ICardGridListCellStyle | undefined,
        rowCellStyle: ICardGridListCellStyle | undefined,
        columns: IColumnExtended[],
        groups: IGroupExtended[],
        customRows: ICardGridListCustomRow | ICardGridListCustomRow[] | undefined,
        customRowStyles: ICardGridListCustomRowStyles | undefined
    ): Partial<IGroupedListStyles> {
        const groupHeaderCellStyles: Partial<IGroupedListStyles> = CardGridListStyling.constructGroupHeaderCellStyles(
            groupHeaderCellStyle,
            groups,
            columns
        );
        const rowCellStyles: Partial<IGroupedListStyles> = CardGridListStyling.constructRowCellStyles(rowCellStyle, groups, columns);
        const customRowCellStyles: Partial<IGroupedListStyles> = CardGridListStyling.constructCustomRowCellStyles(
            customRows,
            customRowStyles,
            groups,
            columns
        );

        const concatStyles: Partial<IGroupedListStyles> = concatStyleSets(
            CardGridListStyling._groupedListBaseStyleSet,
            groupHeaderCellStyles,
            rowCellStyles,
            customRowCellStyles
        );

        return concatStyles;
    }

    private static constructGroupHeaderStyleSet(
        customRows: ICardGridListCustomRow | ICardGridListCustomRow[] | undefined
    ): Partial<IGroupHeaderStyles> {
        let renderStyles: IRawStyle = {};
        let headerRowStyleKeys: string[] = [`:is(.${cardHeaderClass}) div.${msDetailRowCellClass}`];
        let dataRowStyleKeys: string[] = [`div.${msDetailRowCellClass}`];
        for (const customRow of Object.values(ArrayUtils.ensureArray(customRows))) {
            if (customRow.renderAs) {
                const customRowStyleKey: string = `:is(.${customRow.className}) div.${msDetailRowCellClass}`;

                switch (customRow.renderAs) {
                    case 'datarow':
                        dataRowStyleKeys.push(customRowStyleKey);
                        break;

                    case 'headerrow':
                        headerRowStyleKeys.push(customRowStyleKey);
                        break;

                    default:
                        throw new Error(`Unhandled RowRenderType: ${customRow.renderAs}`);
                }
            }
        }

        renderStyles[headerRowStyleKeys.join()] = {
            lineHeight: 'var(--listcard-header-height)',
        };

        renderStyles[dataRowStyleKeys.join()] = {
            lineHeight: 'var(--listcard-row-height)',
        };

        const rootStyles: Partial<IGroupHeaderStyles> = {
            root: { ...renderStyles },
        };

        return concatStyleSets(CardGridListStyling._groupHeaderBaseStyleSet, rootStyles);
    }

    private static getCustomRowStyle(
        customRowClassName: string,
        customRowStyles?: ICardGridListCustomRowStyles
    ): ICardGridListCellStyle | undefined {
        if (customRowStyles) {
            return customRowStyles[customRowClassName];
        }

        return undefined;
    }

    private static isCellStyleDefined(cellStyle?: ICardGridListCellStyle): boolean {
        return cellStyle && Object.entries(cellStyle).length > 0 ? true : false;
    }
}
