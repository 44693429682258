// columnUtils.ts
import { ColumnModel, GridComponent, IEditCell, ValueType } from '@syncfusion/ej2-react-grids';
import { RefObject } from 'react';
import DefaultTooltipHost from '../components/common/DefaultTooltipHost';
import { IFilterItem } from '../data-types/filterItem';
import { IWhitePaperColumn, IWhitePaperReport, IWhitePaperRow } from '../model';
import { getCellValueByFieldName, getDynamicFieldName, isMultiColumnReport } from './ReportUtils';
import { isUndefinedOrEmpty } from './StringUtils';

export const SUBTOTAL_COLUMN_NAME = 'subtotal';

export const getColumnWidth = (longestValueLength: number | undefined, customHeaderTextLength?: number): string => {
    const charWidth = 10;
    const minimumChars = 11;

    if (customHeaderTextLength) {
        longestValueLength = Math.max(longestValueLength || 0, customHeaderTextLength);
    }

    return `${(longestValueLength || minimumChars) * charWidth}px`;
};

//TODO: move this to compare reports table because this is the only place it's used, and this function name is too generic anyway.
export const generateColumns = (
    currentReport: IWhitePaperReport | undefined,
    t: Function,
    filteredEntities: IFilterItem[],
    currentValueFieldNameSuffix: string,
    compareValueFieldNameSuffix: string,
    varianceValueFieldNameSuffix: string,
    getDateStringAsLocaleString: Function
): ColumnModel[] => {
    const isMultiColumns = isMultiColumnReport(currentReport);
    const numericParams: IEditCell = {
        params: {
            decimals: 0,
            format: 'N',
            validateDecimalOnType: true,
            showSpinButton: false,
            htmlAttributes: { maxlength: '15' },
        },
    };

    const columns: ColumnModel[] = [];
    if (currentReport) {
        currentReport.columns?.forEach((col: IWhitePaperColumn) => {
            const currentValue = getDynamicFieldName(col.fieldName, currentValueFieldNameSuffix);
            const compareValue = getDynamicFieldName(col.fieldName, compareValueFieldNameSuffix);
            const varianceValue = getDynamicFieldName(col.fieldName, varianceValueFieldNameSuffix);
            const isVisible = isColumnVisibleAfterEntityFilter(filteredEntities, col);

            const currentValueHeaderText = col.entityName + ' ' + t('current').toString();
            columns.push({
                maxWidth: undefined,
                width: getColumnWidth(col.longestValueLength, currentValueHeaderText.length),
                field: currentValue,
                headerText: currentValueHeaderText,
                headerTemplate: getColumnHeaderTemplate,
                template: getColumnTemplate(currentValue),
                headerTextAlign: 'Right',
                textAlign: 'Right',
                customAttributes: { class: 'white-paper-report-customizable-cell' },
                freeze: 'None',
                allowResizing: true,
                autoFit: !isMultiColumns,
                visible: isVisible,
                edit: numericParams,
                //TODO: remove this. somehow, this is required in order for variance threshold unit tests to pass because it adds an aria label that those tests are asserting on.
                editType: 'numericedit',
                uid: getColumnUid(col),
            });

            const compareValueHeaderText =
                col.entityName + ' ' + getDateStringAsLocaleString(currentReport.comparedDate ?? '', true).replace(',', ' - ');
            columns.push({
                maxWidth: undefined,
                width: getColumnWidth(col.longestValueLength, compareValueHeaderText.length),
                field: compareValue,
                headerText: compareValueHeaderText,
                headerTemplate: getColumnHeaderTemplate,
                template: getColumnTemplate(compareValue),
                headerTextAlign: 'Right',
                textAlign: 'Right',
                customAttributes: undefined,
                freeze: 'None',
                allowResizing: true,
                autoFit: !isMultiColumns,
                visible: isVisible,
                edit: numericParams,
                //TODO: remove this. somehow, this is required in order for variance threshold unit tests to pass because it adds an aria label that those tests are asserting on.
                editType: 'numericedit',
                uid: getColumnUid(col),
            });

            const varianceValueHeaderText = col.entityName + ' ' + t('variance').toString();
            columns.push({
                maxWidth: undefined,
                width: getColumnWidth(col.longestValueLength, varianceValueHeaderText.length),
                field: varianceValue,
                headerText: varianceValueHeaderText,
                headerTemplate: getColumnHeaderTemplate,
                template: getColumnTemplate(varianceValue),
                headerTextAlign: 'Right',
                textAlign: 'Right',
                customAttributes: { class: 'compare-data-variance-column' },
                freeze: 'None',
                allowResizing: true,
                autoFit: !isMultiColumns,
                visible: isVisible,
                edit: numericParams,
                //TODO: remove this. somehow, this is required in order for variance threshold unit tests to pass because it adds an aria label that those tests are asserting on.
                editType: 'numericedit',
                uid: getColumnUid(col),
            });
        });
    }
    return columns;
};

export const discardTotalSubTotalColumnForReport = (currentReport: any): IWhitePaperColumn[] => {
    return currentReport.columns?.filter((col: { fieldName: any; entityName: string }) => !isTotalOrSubTotalColumn(col));
};

export const isTotalOrSubTotalColumn = (col: IWhitePaperColumn): boolean =>
    col.fieldName?.toLowerCase().includes('total') || col.fieldName?.toLowerCase().includes(SUBTOTAL_COLUMN_NAME) || false;

export const getColumnNames = (columns: IFilterItem[], isInComparePage?: boolean, visibleColumns?: string[]) => {
    const selectedColumnsName = columns.map((column) => column.searchBy[1]);

    if (isInComparePage && visibleColumns) {
        return visibleColumns.filter((col) => selectedColumnsName.some((selectedCol) => col.includes(selectedCol)));
    }

    return columns.map((column) => column.searchBy[1]);
};
export const getColumnIds = (columns: IFilterItem[]) => {
    return columns.map((column) => column.searchBy[0]);
};
export const getColumnsInReport = (gridRef: RefObject<GridComponent>, entities: IFilterItem[]) => {
    const columnsInReport = gridRef.current?.getColumns().map((col) => col.headerText) ?? [];
    const columns = getColumnNames(entities);
    return columnsInReport.filter((col) => columns.some((selectedCol) => col.includes(selectedCol)));
};

const compareDateValues = (reference: string, comparer: string): number => Date.parse(reference) - Date.parse(comparer);

const compareNumericValues = (reference: number, comparer: number): number => reference - comparer;

const compareNullOrUndefined = (reference: ValueType, sortingDirection: string): number => {
    if (isUndefinedOrEmpty(String(reference))) {
        return sortingDirection === 'Ascending' ? 1 : -1;
    }

    return sortingDirection === 'Ascending' ? -1 : 1;
};

export const getTypeofValueType = (reference: ValueType | undefined | null, comparer: ValueType | undefined | null): string => {
    if (isUndefinedOrEmpty(reference?.toString()) && isUndefinedOrEmpty(comparer?.toString())) {
        return 'undefined';
    }
    if (!isNaN(Number(reference)) && !isNaN(Number(comparer))) {
        return 'number';
    }
    if (!isNaN(new Date(reference?.toString() || '').getTime()) && !isNaN(new Date(comparer?.toString() || '').getTime())) {
        return 'date';
    }
    return 'string';
};

export const getSortComparer = (reference: ValueType, comparer: ValueType, sortingDirection: String): number => {
    const typeOfValue = getTypeofValueType(reference, comparer);
    if (!sortingDirection || typeOfValue === 'undefined') {
        return 0;
    }

    if (isUndefinedOrEmpty(reference?.toString()) || isUndefinedOrEmpty(comparer?.toString())) {
        return compareNullOrUndefined(reference?.toString() || '', sortingDirection?.toString() || '');
    }

    if (typeOfValue === 'number') {
        return compareNumericValues(Number(reference), Number(comparer));
    }

    if (typeOfValue === 'Date') {
        return compareDateValues(String(reference), String(comparer));
    }

    return String(comparer) < String(reference) ? 1 : -1;
};

export const getColumnHeaderTemplate = (item: ColumnModel) => {
    return (
        <DefaultTooltipHost tooltipId={item.field} tooltipContent={item.headerText}>
            <label>{item.headerText}</label>
        </DefaultTooltipHost>
    );
};

export const getColumnTemplate = (fieldName: string) => {
    return (item: IWhitePaperRow) => {
        const value = getCellValueByFieldName(item, fieldName);
        return (
            <DefaultTooltipHost tooltipId={item.rowNumber?.toString()} tooltipContent={value}>
                <span tabIndex={-1}>{value}</span>
            </DefaultTooltipHost>
        );
    };
};

export const getColumnUid = (column: IWhitePaperColumn) => {
    return `_${column.entityCode}${column.entityName}`.replaceAll(' ', '');
};

export const getColumnUidForFilterItem = (filterItem: IFilterItem) => {
    return `_${filterItem.searchBy?.[0]}${filterItem.searchBy?.[1]}`.replaceAll(' ', '');
};

export const isColumnVisibleAfterEntityFilter = (filteredEntities: IFilterItem[], column: IWhitePaperColumn) => {
    return filteredEntities.length ? filteredEntities.some((f) => getColumnUid(column) === getColumnUidForFilterItem(f)) : true;
};
