/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
    AddNewConversationRequest,
    AddNewConversationResponse,
    AddNewReplyToConversationRequest,
    DeleteConversationRequest,
    DeleteReplyRequest,
    GetConversationsForReportFieldResponse,
    GetConversationsForReportResponse,
    IActionResult,
    UpdateConversationRequest,
    UpdateReplyRequest,
} from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetConversationsForReportHook = () => {
    const getConversationsForReport = useCustomInstance<GetConversationsForReportResponse>();

    return (reportId: string, signal?: AbortSignal) => {
        return getConversationsForReport({ url: `/Collaboration/report/${reportId}`, method: 'get', signal });
    };
};

export const getGetConversationsForReportQueryKey = (reportId: string) => [`/Collaboration/report/${reportId}`] as const;

export const useGetConversationsForReportQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportHook>>>,
    TError = ErrorType<unknown>
>(
    reportId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetConversationsForReportQueryKey(reportId);

    const getConversationsForReport = useGetConversationsForReportHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportHook>>>> = ({ signal }) =>
        getConversationsForReport(reportId, signal);

    return { queryKey, queryFn, enabled: !!reportId, ...queryOptions };
};

export type GetConversationsForReportQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportHook>>>>;
export type GetConversationsForReportQueryError = ErrorType<unknown>;

export const useGetConversationsForReport = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportHook>>>,
    TError = ErrorType<unknown>
>(
    reportId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetConversationsForReportQueryOptions(reportId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useAddNewConversationToReportHook = () => {
    const addNewConversationToReport = useCustomInstance<AddNewConversationResponse>();

    return (reportId: string, addNewConversationRequest: AddNewConversationRequest) => {
        return addNewConversationToReport({
            url: `/Collaboration/report/${reportId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: addNewConversationRequest,
        });
    };
};

export const useAddNewConversationToReportMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportHook>>>,
        TError,
        { reportId: string; data: AddNewConversationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportHook>>>,
    TError,
    { reportId: string; data: AddNewConversationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const addNewConversationToReport = useAddNewConversationToReportHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportHook>>>,
        { reportId: string; data: AddNewConversationRequest }
    > = (props) => {
        const { reportId, data } = props ?? {};

        return addNewConversationToReport(reportId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddNewConversationToReportMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportHook>>>
>;
export type AddNewConversationToReportMutationBody = AddNewConversationRequest;
export type AddNewConversationToReportMutationError = ErrorType<unknown>;

export const useAddNewConversationToReport = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportHook>>>,
        TError,
        { reportId: string; data: AddNewConversationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useAddNewConversationToReportMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetConversationsForReportFieldHook = () => {
    const getConversationsForReportField = useCustomInstance<GetConversationsForReportFieldResponse>();

    return (reportId: string, reportColumnName: string, reportRowNumber: string, signal?: AbortSignal) => {
        return getConversationsForReportField({
            url: `/Collaboration/report/${reportId}/${reportColumnName}/${reportRowNumber}`,
            method: 'get',
            signal,
        });
    };
};

export const getGetConversationsForReportFieldQueryKey = (reportId: string, reportColumnName: string, reportRowNumber: string) =>
    [`/Collaboration/report/${reportId}/${reportColumnName}/${reportRowNumber}`] as const;

export const useGetConversationsForReportFieldQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportFieldHook>>>,
    TError = ErrorType<unknown>
>(
    reportId: string,
    reportColumnName: string,
    reportRowNumber: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportFieldHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportFieldHook>>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetConversationsForReportFieldQueryKey(reportId, reportColumnName, reportRowNumber);

    const getConversationsForReportField = useGetConversationsForReportFieldHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportFieldHook>>>> = ({ signal }) =>
        getConversationsForReportField(reportId, reportColumnName, reportRowNumber, signal);

    return { queryKey, queryFn, enabled: !!(reportId && reportColumnName && reportRowNumber), ...queryOptions };
};

export type GetConversationsForReportFieldQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportFieldHook>>>
>;
export type GetConversationsForReportFieldQueryError = ErrorType<unknown>;

export const useGetConversationsForReportField = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportFieldHook>>>,
    TError = ErrorType<unknown>
>(
    reportId: string,
    reportColumnName: string,
    reportRowNumber: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConversationsForReportFieldHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetConversationsForReportFieldQueryOptions(reportId, reportColumnName, reportRowNumber, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useAddNewConversationToReportFieldHook = () => {
    const addNewConversationToReportField = useCustomInstance<AddNewConversationResponse>();

    return (reportId: string, reportColumnName: string, reportRowNumber: string, addNewConversationRequest: AddNewConversationRequest) => {
        return addNewConversationToReportField({
            url: `/Collaboration/report/${reportId}/${reportColumnName}/${reportRowNumber}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: addNewConversationRequest,
        });
    };
};

export const useAddNewConversationToReportFieldMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportFieldHook>>>,
        TError,
        { reportId: string; reportColumnName: string; reportRowNumber: string; data: AddNewConversationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportFieldHook>>>,
    TError,
    { reportId: string; reportColumnName: string; reportRowNumber: string; data: AddNewConversationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const addNewConversationToReportField = useAddNewConversationToReportFieldHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportFieldHook>>>,
        { reportId: string; reportColumnName: string; reportRowNumber: string; data: AddNewConversationRequest }
    > = (props) => {
        const { reportId, reportColumnName, reportRowNumber, data } = props ?? {};

        return addNewConversationToReportField(reportId, reportColumnName, reportRowNumber, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddNewConversationToReportFieldMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportFieldHook>>>
>;
export type AddNewConversationToReportFieldMutationBody = AddNewConversationRequest;
export type AddNewConversationToReportFieldMutationError = ErrorType<unknown>;

export const useAddNewConversationToReportField = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddNewConversationToReportFieldHook>>>,
        TError,
        { reportId: string; reportColumnName: string; reportRowNumber: string; data: AddNewConversationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useAddNewConversationToReportFieldMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useUpdateConversationHook = () => {
    const updateConversation = useCustomInstance<IActionResult>();

    return (updateConversationRequest: UpdateConversationRequest) => {
        return updateConversation({
            url: `/Collaboration/update`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: updateConversationRequest,
        });
    };
};

export const useUpdateConversationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateConversationHook>>>,
        TError,
        { data: UpdateConversationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateConversationHook>>>,
    TError,
    { data: UpdateConversationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const updateConversation = useUpdateConversationHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateConversationHook>>>,
        { data: UpdateConversationRequest }
    > = (props) => {
        const { data } = props ?? {};

        return updateConversation(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateConversationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateConversationHook>>>>;
export type UpdateConversationMutationBody = UpdateConversationRequest;
export type UpdateConversationMutationError = ErrorType<unknown>;

export const useUpdateConversation = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateConversationHook>>>,
        TError,
        { data: UpdateConversationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useUpdateConversationMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useDeleteConversationHook = () => {
    const deleteConversation = useCustomInstance<IActionResult>();

    return (deleteConversationRequest: DeleteConversationRequest) => {
        return deleteConversation({
            url: `/Collaboration/delete`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: deleteConversationRequest,
        });
    };
};

export const useDeleteConversationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteConversationHook>>>,
        TError,
        { data: DeleteConversationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteConversationHook>>>,
    TError,
    { data: DeleteConversationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteConversation = useDeleteConversationHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteConversationHook>>>,
        { data: DeleteConversationRequest }
    > = (props) => {
        const { data } = props ?? {};

        return deleteConversation(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteConversationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteConversationHook>>>>;
export type DeleteConversationMutationBody = DeleteConversationRequest;
export type DeleteConversationMutationError = ErrorType<unknown>;

export const useDeleteConversation = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteConversationHook>>>,
        TError,
        { data: DeleteConversationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useDeleteConversationMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useAddNewReplyToConversationHook = () => {
    const addNewReplyToConversation = useCustomInstance<IActionResult>();

    return (conversationId: string, addNewReplyToConversationRequest: AddNewReplyToConversationRequest) => {
        return addNewReplyToConversation({
            url: `/Collaboration/conversation/${conversationId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: addNewReplyToConversationRequest,
        });
    };
};

export const useAddNewReplyToConversationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddNewReplyToConversationHook>>>,
        TError,
        { conversationId: string; data: AddNewReplyToConversationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddNewReplyToConversationHook>>>,
    TError,
    { conversationId: string; data: AddNewReplyToConversationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const addNewReplyToConversation = useAddNewReplyToConversationHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddNewReplyToConversationHook>>>,
        { conversationId: string; data: AddNewReplyToConversationRequest }
    > = (props) => {
        const { conversationId, data } = props ?? {};

        return addNewReplyToConversation(conversationId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddNewReplyToConversationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddNewReplyToConversationHook>>>>;
export type AddNewReplyToConversationMutationBody = AddNewReplyToConversationRequest;
export type AddNewReplyToConversationMutationError = ErrorType<unknown>;

export const useAddNewReplyToConversation = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddNewReplyToConversationHook>>>,
        TError,
        { conversationId: string; data: AddNewReplyToConversationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useAddNewReplyToConversationMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useUpdateReplyHook = () => {
    const updateReply = useCustomInstance<IActionResult>();

    return (updateReplyRequest: UpdateReplyRequest) => {
        return updateReply({
            url: `/Collaboration/reply/update`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: updateReplyRequest,
        });
    };
};

export const useUpdateReplyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReplyHook>>>,
        TError,
        { data: UpdateReplyRequest },
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateReplyHook>>>, TError, { data: UpdateReplyRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const updateReply = useUpdateReplyHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateReplyHook>>>, { data: UpdateReplyRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return updateReply(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateReplyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateReplyHook>>>>;
export type UpdateReplyMutationBody = UpdateReplyRequest;
export type UpdateReplyMutationError = ErrorType<unknown>;

export const useUpdateReply = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReplyHook>>>,
        TError,
        { data: UpdateReplyRequest },
        TContext
    >;
}) => {
    const mutationOptions = useUpdateReplyMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useDeleteReplyHook = () => {
    const deleteReply = useCustomInstance<IActionResult>();

    return (deleteReplyRequest: DeleteReplyRequest) => {
        return deleteReply({
            url: `/Collaboration/reply/delete`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: deleteReplyRequest,
        });
    };
};

export const useDeleteReplyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReplyHook>>>,
        TError,
        { data: DeleteReplyRequest },
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteReplyHook>>>, TError, { data: DeleteReplyRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteReply = useDeleteReplyHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteReplyHook>>>, { data: DeleteReplyRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return deleteReply(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteReplyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteReplyHook>>>>;
export type DeleteReplyMutationBody = DeleteReplyRequest;
export type DeleteReplyMutationError = ErrorType<unknown>;

export const useDeleteReply = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReplyHook>>>,
        TError,
        { data: DeleteReplyRequest },
        TContext
    >;
}) => {
    const mutationOptions = useDeleteReplyMutationOptions(options);

    return useMutation(mutationOptions);
};
