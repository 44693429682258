import { Stack } from '@fluentui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeStylesOnDemand } from '../../utils/StyleUtils';
import CalloutIcon, { ICalloutIconProps } from '../common/CalloutIcon';
import { IAccountAmountWhitePaperRow } from './ReportDrillDownUtils';

export interface ILastModifiedByCalloutIconProps {
    item: IAccountAmountWhitePaperRow;
    calloutIconProps: ICalloutIconProps;
}

const LastModifiedByCalloutIcon: FC<ILastModifiedByCalloutIconProps> = (props: ILastModifiedByCalloutIconProps) => {
    const { t } = useTranslation();
    const getLabelStyle: () => string = mergeStylesOnDemand({ fontWeight: 600, fontSize: '10px' });
    const getTextStyle: () => string = mergeStylesOnDemand({ fontWeight: 400, fontSize: '10px' });
    return (
        <CalloutIcon {...props.calloutIconProps}>
            <Stack tabIndex={0}>
                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <Stack.Item className={getLabelStyle()}>
                            {/* 
                                    the reason we are invoking translation inside a verbatim string 
                                    is to make sure that this doesn't add a newline when this component is rendered as part of unit testing
                                    if it adds a newline, it becomes harder to target this with RTL's "findByText" query
                                */}
                            <label htmlFor='#lastEditedBy'>{`${t('lastModifiedBy')}: `}</label>
                        </Stack.Item>
                        <Stack.Item className={getTextStyle()}>
                            <span id='lastEditedBy'>{props.item.lastModifiedBy}</span>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </CalloutIcon>
    );
};

export default LastModifiedByCalloutIcon;
