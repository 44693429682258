import { corptaxCustomLightTheme, CorptaxThemeProvider } from '@corptax/react-components-common';
import { FluentProvider } from '@fluentui/react-components';
import { PortalCompatProvider } from '@fluentui/react-portal-compat';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Router } from '@remix-run/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './assets/css/sync-fusion/customSyncFusionStyles.css';
import { FunctionalAccessContextProvider } from './contexts/functionaAccessContext';
import ServiceDiscoveryContextProvider from './contexts/serviceDiscoveryContext';
import { TaxReturnCalculationProvider } from './contexts/taxReturnCalculationContext';
import UserSettingsProvider from './contexts/userSettingsContext';
import { initI18n } from './i18n';
import './index.css';
import { registerDefaultSyncfusionLicense } from './registerSyncfusionLicense';
import routesConfig from './routes';
import { themeSettings } from './theme';

registerDefaultSyncfusionLicense();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: process.env.REACT_APP_QUERY_CLIENT_MAX_RETRIES ? parseInt(process.env.REACT_APP_QUERY_CLIENT_MAX_RETRIES) : false,
        },
    },
});

const router: Router = createBrowserRouter(routesConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
    await initI18n();
    initializeIcons();

    root.render(
        <FluentProvider theme={corptaxCustomLightTheme}>
            <PortalCompatProvider>
                <CorptaxThemeProvider theme={themeSettings}>
                    <QueryClientProvider client={queryClient}>
                        <ServiceDiscoveryContextProvider>
                            <FunctionalAccessContextProvider>
                                <UserSettingsProvider>
                                    <TaxReturnCalculationProvider>
                                        <RouterProvider router={router} />
                                    </TaxReturnCalculationProvider>
                                </UserSettingsProvider>
                            </FunctionalAccessContextProvider>
                        </ServiceDiscoveryContextProvider>
                    </QueryClientProvider>
                </CorptaxThemeProvider>
            </PortalCompatProvider>
        </FluentProvider>
    );
})();
