import { ColumnDirective, ColumnModel, ColumnsDirective } from '@syncfusion/ej2-react-grids';
import { IFilterItem } from '../data-types/filterItem';
import { getColumnUidForFilterItem } from '../utils/ColumnUtils';

export const useReportColumnsAsJsx = (columns: ColumnModel[], filteredEntities: IFilterItem[]) => {
    let columnsToSearch: string[] = ['description'];

    const columnsAsJsx = (
        <ColumnsDirective>
            {columns.map((c, index) => {
                const isVisible = filteredEntities.length ? filteredEntities.some((f) => c.uid === getColumnUidForFilterItem(f)) : true;
                if (isVisible && c.field) {
                    columnsToSearch.push(c.field);
                }

                return <ColumnDirective key={c.field} {...c}></ColumnDirective>;
            })}
        </ColumnsDirective>
    );

    return { columnsAsJsx, columnsToSearch };
};
