import { Stack, StackItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useCommentsUIStyles } from '../../hooks/useCommentsUIStyles';
import { CommentRichTextEditor, ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';
import { ErrorDisplay } from './ErrorDisplay';

export interface IAddConversationCardProps {
    title: string;
    onCancel: () => void;
    onAdd: (comment: ICommentRichTextEditorValue) => void;
    errorMessage?: string;
    onRefresh: () => void;
}

export const AddConversationCard = (props: IAddConversationCardProps) => {
    const { t } = useTranslation();
    const styles = useCommentsUIStyles(true);
    const addButtonText = t('sendCommentButtonText').toString();
    const addingButtonText = t('sendingCommentButtonText').toString();
    const cancelButtonText = t('cancel').toString();
    const commentInputAriaLabelText = t('commentInputAriaLabel').toString();
    const commentInputPlaceholderText = t('commentInputPlaceholder').toString();

    const hasError = props.errorMessage;

    return (
        <>
            <Stack className={styles.cardSelectedClassNames}>
                <StackItem>
                    <div className={styles.cardHeaderSelectedClassNames}>{props.title}</div>
                    <StackItem tokens={{ padding: 5 }}>
                        <CommentRichTextEditor
                            commentInputAriaLabel={commentInputAriaLabelText}
                            commentInputPlaceholder={commentInputPlaceholderText}
                            saveButtonAriaLabel={addButtonText}
                            saveButtonText={addButtonText}
                            onSaveButtonClick={props.onAdd}
                            cancelButtonText={cancelButtonText}
                            cancelButtonAriaLabel={cancelButtonText}
                            onCancelButtonClick={props.onCancel}
                            processingText={addingButtonText}
                        />
                    </StackItem>
                </StackItem>
                <StackItem>
                    <ErrorDisplay
                        errorMessage={hasError ? props.errorMessage : undefined}
                        errorLinkText={hasError ? t('refresh').toString() : undefined}
                        errorLinkAction={hasError ? props.onRefresh : undefined}
                    />
                </StackItem>
            </Stack>
        </>
    );
};
