export const adjustmentEntriesChildDataName: string = 'adjustmentEntries';
export const adjustmentSummariesChildDataName: string = 'adjustmentSummaries';
export const bookEntriesChildDataName: string = 'bookEntries';
export const bookSummaryChildDataName: string = 'bookSummary';
export const adjustmentEntryRowClass: string = 'adjustmentEntryRow';
export const adjustmentHeaderRowClass: string = 'adjustment-header-row';
export const adjustmentSummaryRowClass: string = 'adjustment-summary-row';
export const bookSummaryRowClass: string = 'book-summary-row';
export const bookEntryRowClass: string = 'book-entry-row';
export const gridDisplayHeight: string = 'calc(100vh - 320px)';
export const bookSummaryClassWithLastModifiedBy = `${bookSummaryRowClass}-hasAdjustment`;
export const adjustmentSummaryClassWithLastModifiedBy = `${adjustmentSummaryRowClass}-hasAdjustment`;
export const recalcNeededClass: string = 'recalc-needed';
export const disabledClass: string = 'disabled-amount';
