import { useCorptaxTheme } from '@corptax/react-components-common';

import {
    CommandBarButton,
    DirectionalHint,
    ICommandBarItemProps,
    IContextualMenuItem,
    ITooltipHostProps,
    mergeStyles,
    MessageBarType,
    Stack,
    StackItem,
    TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteTaxReturnFolderRecord, useRenameTaxReturnFolderRecord, useSaveTaxReturnFolderRecord } from '../../api/report/report';
import { useFeedbackMessages } from '../../hooks/useFeedbackMessages';
import { useFunctionalAccess } from '../../hooks/useFunctionalAccess';
import { IAppTheme } from '../../theme/IAppTheme';
import { focusContextualMenuSearchBox, scrollToElement, scrollToFolderElementByName } from '../../utils/ContextualMenuUtils';
import { getTooltipHostProps } from '../../utils/CustomStyles';
import { handleFolderCreation, isInvalidFolderName } from '../../utils/FoldersUtils';
import { validNameRuleRegExp } from '../../utils/RegExpUtils';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import DefaultTooltipHost from './DefaultTooltipHost';
import Icon from './Icon';
import CustomTextField from './TextField';

export interface IContextualMenuFolderItemProps {
    item: IContextualMenuItem;
    dismissMenu: (ev?: any, dismissAll?: boolean) => void;
    updateFolderList?: () => Promise<any>;
    onFolderChange?: (filteredItems: any[], filterName: string) => void;
    onCancel?: () => void;
    isNewFolder?: boolean;
    onSaveFolder?: () => void;
    itemIndex: number;
}

const ContextualMenuFolderItem: React.FC<IContextualMenuFolderItemProps> = ({
    item,
    dismissMenu,
    updateFolderList,
    onFolderChange,
    onCancel,
    isNewFolder = false,
    onSaveFolder,
    itemIndex,
}) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { mutateAsync: renameFolderCall, isLoading: renameFolderIsLoading } = useRenameTaxReturnFolderRecord();
    const { displayFeedbackMessage } = useFeedbackMessages();
    const { mutateAsync: saveFolder, isLoading: saveFolderIsLoading } = useSaveTaxReturnFolderRecord();
    const [isEdit, { setTrue: setIsEdit, setFalse: clearIsEdit }] = useBoolean(isNewFolder);
    const [isErrorInput, { setTrue: setIsErrorInput, setFalse: clearIsErrorInput }] = useBoolean(false);
    const trimmedFolderName = item.text?.replace(/\(\d+\)$/, '') ?? '';
    const [folderNameValue, setFolderNameValue] = useState<string>(item?.data?.filterItem?.searchBy?.[0] || '');
    const menuItemContainerRef = useRef<HTMLDivElement>(null);
    const { mutateAsync: deleteFolderCall, isLoading: deleteFolderIsLoading } = useDeleteTaxReturnFolderRecord();
    const [isDelete, { setTrue: setIsDelete, setFalse: clearIsDelete }] = useBoolean(false);
    const isSaving = saveFolderIsLoading || renameFolderIsLoading;
    const { functionalAccess } = useFunctionalAccess();

    const commandBarButtonStyles = {
        root: { padding: '5px', height: '100%' },
        menuIcon: { fontSize: '16px' },
    };

    const folderNameButtonStyles = {
        icon: {
            color: customPalette.yellowDark,
        },
        iconHovered: {
            color: customPalette.yellowDark,
        },
        root: {
            width: '100%',
            height: 44,
        },
        label: { textAlign: 'left', margin: 0 },
    };

    const folderOptionButtonStyles = {
        menuIcon: { fontSize: 17 },
        root: {
            height: 44,
        },
    };

    const canRenameFolder = functionalAccess?.editFolderButtonEnabled;
    const canDeleteFolder = functionalAccess?.deleteFolderButtonEnabled;
    const menuItemsRenderFunction = (props: any, hasFunctionalAccess: boolean | undefined) => {
        const commandBarButton = <CommandBarButton style={{ height: '36px', width: '100%', textAlign: 'left' }} {...props} />;
        if (hasFunctionalAccess) {
            return commandBarButton;
        }

        return (
            <DefaultTooltipHost
                tooltipId='functional-access-disabled-tooltip'
                tooltipContent={t('noFunctionalAccess').toString()}
                alwaysDisplay={true}
            >
                {commandBarButton}
            </DefaultTooltipHost>
        );
    };

    const overflowItems: ICommandBarItemProps[] = [
        {
            key: 'renameFolder',
            text: t('rename').toString(),
            ariaLabel: t('rename').toString(),
            onClick: setIsEdit,
            iconProps: { iconName: 'RenameRegular' },
            disabled: !canRenameFolder,
            onRender: (props: any) => menuItemsRenderFunction(props, canRenameFolder),
        },
        {
            key: 'deleteFolder',
            text: t('Delete').toString(),
            ariaLabel: t('Delete').toString(),
            onClick: setIsDelete,
            iconProps: { iconName: 'DeleteRegular' },
            disabled: !canDeleteFolder,
            onRender: (props: any) => menuItemsRenderFunction(props, canDeleteFolder),
        },
    ];

    const tooltipHostProps: ITooltipHostProps = {
        ...getTooltipHostProps(DirectionalHint.leftCenter, undefined, customPalette),
        content: t('folderOptions').toString(),
    };

    const finishEditing = (folderKey: string | undefined, scrollByName = false) => {
        if (folderKey) {
            updateFolderList &&
                updateFolderList().then(() => {
                    scrollByName ? scrollToFolderElementByName(folderKey) : scrollToElement(folderKey);
                    setFolderNameValue(trimmedFolderName);
                    clearIsEdit();
                });
        }
    };

    const saveChanges = () => {
        if (isInvalidFolderName(folderNameValue)) {
            return;
        }

        if (isNewFolder) {
            saveFolder({ params: { folderName: folderNameValue } }).then((response) => {
                handleFolderCreation(
                    response,
                    folderNameValue,
                    t('newFolderCreated'),
                    displayFeedbackMessage,
                    setIsErrorInput,
                    menuItemContainerRef,
                    finishEditing,
                    clearIsErrorInput,
                    onSaveFolder
                );
            });
        } else {
            renameFolderCall({ params: { FolderKey: Number(item.key), FolderName: folderNameValue } }).then((response) => {
                handleFolderCreation(
                    response,
                    item.key,
                    t('folderRenamedSuccessfully'),
                    displayFeedbackMessage,
                    setIsErrorInput,
                    menuItemContainerRef,
                    finishEditing,
                    clearIsErrorInput,
                    onSaveFolder
                );
            });
        }
    };

    const handleDeleteFolder = () => {
        deleteFolderCall({ data: { folderKey: parseInt(item.key) } }).then(() => {
            clearIsDelete();
            updateFolderList &&
                updateFolderList().then(() => {
                    focusContextualMenuSearchBox();
                    displayFeedbackMessage({ message: t('folderDeleted').toString(), type: MessageBarType.success });
                });
        });
    };

    const handleChangeFolderName = (ev: any) => {
        if (validNameRuleRegExp.test(ev.target.value)) {
            setFolderNameValue(ev.target.value);
        }
    };

    const handleCancel = () => {
        clearIsEdit();
        scrollToElement(item.key);
        clearIsErrorInput();
        clearIsDelete();
    };

    const handleChangeSelectedFolder = () => {
        onFolderChange && onFolderChange([item], item.text || '');
        dismissMenu();
    };

    const cancelRenameIfNecessary = (event: FocusEvent) => {
        if (!menuItemContainerRef.current?.contains(event.relatedTarget as Node)) {
            clearIsEdit();
            clearIsErrorInput();
            if (onCancel) onCancel();
        }
    };

    const inputName = isNewFolder ? t('addFolderTextField') : t('renameFolderTextField');

    return (
        <div ref={menuItemContainerRef}>
            {isEdit ? (
                <Stack
                    horizontal
                    horizontalAlign='space-between'
                    verticalAlign='center'
                    key={item.key}
                    className={mergeStyles({ padding: '0px 4px' })}
                    id={item.key.toString()}
                >
                    <StackItem align='center'>
                        <Icon iconName='Folder20Filled' className={mergeStyles({ color: customPalette.yellowDark })} />
                    </StackItem>
                    <StackItem grow>
                        <CustomTextField
                            id={`renameFolderInput${item.key.toString()}`}
                            onChange={handleChangeFolderName}
                            value={folderNameValue}
                            ariaLabel={inputName}
                            onBlur={cancelRenameIfNecessary}
                            onEnter={saveChanges}
                            isProcessing={isSaving}
                            isErrorInput={isErrorInput}
                            processingLabel={t('processing').toString()}
                        />
                    </StackItem>
                    <StackItem align='center'>
                        <CommandBarButton
                            aria-label={t('confirmRenameFolder').toString()}
                            menuIconProps={{ iconName: 'Accept' }}
                            styles={commandBarButtonStyles}
                            onClick={saveChanges}
                            onBlur={(event) => cancelRenameIfNecessary(event.nativeEvent)}
                            disabled={isSaving || isInvalidFolderName(folderNameValue)}
                        />
                        <CommandBarButton
                            aria-label={t('cancelRenameFolder').toString()}
                            menuIconProps={{ iconName: 'Cancel' }}
                            styles={commandBarButtonStyles}
                            onClick={onCancel ? onCancel : handleCancel}
                            onBlur={(event) => cancelRenameIfNecessary(event.nativeEvent)}
                            disabled={isSaving}
                        />
                    </StackItem>
                </Stack>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between' }} key={item.key} tabIndex={0}>
                    <CommandBarButton
                        id={`item-${itemIndex}`}
                        styles={folderNameButtonStyles}
                        onClick={handleChangeSelectedFolder}
                        aria-label={item.text}
                        iconProps={{ iconName: 'Folder20Filled' }}
                    >
                        {item.text}
                    </CommandBarButton>
                    <TooltipHost {...tooltipHostProps}>
                        <CommandBarButton
                            menuIconProps={{ iconName: 'More' }}
                            aria-label={t('folderOptions').toString()}
                            menuProps={{ items: overflowItems }}
                            styles={folderOptionButtonStyles}
                        />
                    </TooltipHost>
                </div>
            )}
            <ConfirmationDialog
                show={isDelete}
                filterActionBarProps={{
                    primaryButtonLabel: t('delete').toString(),
                    primaryHandler: handleDeleteFolder,
                    primaryButtonProcessing: deleteFolderIsLoading,
                    primaryButtonProcessingLabel: t('deleting').toString(),
                    secondaryHandler: handleCancel,
                    secondaryButtonLabel: t('cancel').toString(),
                    secondaryButtonDisabled: deleteFolderIsLoading,
                }}
                heading={t('deleteFolderTitle')}
                content={t('deleteFolderSubTitle')}
                onDismiss={handleCancel}
            />
        </div>
    );
};

export default ContextualMenuFolderItem;
