import { ExtractionDetailsRequest, IWhitePaperReportChanges, ProblemDetails, Report } from '../../model';
import { ErrorType } from '../../utils/UseCustomInstance';
import { CardGridListExpandCollapseState } from '../card-grid-list/CardGridListTypes';
import { IEnterAmountsDialogProps } from '../enter-amounts-dialog/EnterAmountsDialog';
import { IAccountAmountWhitePaperRow, ITrackedExtractionDetailsAccountSummary } from './ReportDrillDownUtils';

export enum DrillDownActionType {
    ChangeActiveTabIndex = 0,
    StartProcessingExport = 1,
    ExportFailure = 8,
    StopProcessingExport = 2,
    StartProcessingSaveAmounts = 3,
    SaveAmountsSuccess = 4,
    SaveAmountsFailure = 5,
    SaveAmountsComplete = 6,
    AmountEdited = 7,
    RequestCellAuditStart = 9,
    RequestCellAuditSuccess = 10,
    RequestCellAuditFailure = 11,
    RequestCellAuditEnd = 12,
    CardGridListExpandCollapseStateChange = 13,
    ConfirmationDialogOpened = 14,
    ConfirmationDialogClosed = 15,
    AdjustmentModalAmountsSave = 16,
    EnterAmountsDialogOpened = 17,
    EnterAmountsDialogClosed = 18,
}

export interface IEnterAmountsOptionalProps
    extends Omit<
        IEnterAmountsDialogProps,
        | 'taxReturnKey'
        | 'show'
        | 'saveButtonIsDisabled'
        | 'updateState'
        | 'rowDescription'
        | 'drillDownRowNumber'
        | 'columnId'
        | 'changeTrackingData'
    > {}

export interface IAmountEditedDrillDownAction {
    columnId: number;
    event: any;
    item: IAccountAmountWhitePaperRow;
    report: Report;
    rowNumber: number;
    type: DrillDownActionType.AmountEdited;
}

export interface IEnterAmountsDialogClosedDrillDownAction {
    type: DrillDownActionType.EnterAmountsDialogClosed;
    updatedChangeTrackingData: IWhitePaperReportChanges;
}

export interface IEnterAmountsDialogOpenedDrillDownAction {
    enterAmountsDialogProps: IEnterAmountsOptionalProps;
    type: DrillDownActionType.EnterAmountsDialogOpened;
}

// FIXME: We can get much better type checking if we added types for each value of DrillDownActionType and then defined DrillDownAction as a union type of all those types.
//        However, since there are 17 values for DrillDownActionType, this would be a lot of work.
//        So, using Exclude here allows us to avoid having to do all that work up front while still gaining that benefit for any new enum values.
export interface IGenericDrillDownAction {
    error?: ErrorType<ProblemDetails> | null;
    event?: any;
    eventTargetTabIndex?: number;
    extractionDetailsRequests?: ExtractionDetailsRequest[];
    initialAccountAmountSummaries?: ITrackedExtractionDetailsAccountSummary[];
    item?: IAccountAmountWhitePaperRow;
    newActiveTab?: number;
    type: Exclude<DrillDownActionType, DrillDownActionType.AmountEdited> &
        Exclude<DrillDownActionType, DrillDownActionType.EnterAmountsDialogOpened> &
        Exclude<DrillDownActionType, DrillDownActionType.EnterAmountsDialogClosed>;
    updatedChangeTrackingData?: IWhitePaperReportChanges;
    updatedExpandCollapseState?: CardGridListExpandCollapseState;
}

export type DrillDownAction =
    | IAmountEditedDrillDownAction
    | IGenericDrillDownAction
    | IEnterAmountsDialogClosedDrillDownAction
    | IEnterAmountsDialogOpenedDrillDownAction;
