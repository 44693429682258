import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultTooltipHost from './DefaultTooltipHost';

export interface IFunctionalAccessDisableWrapperProps {
    hasFunctionalAccess: boolean | undefined;
}

const FunctionalAccessDisableWrapper: React.FC<PropsWithChildren<IFunctionalAccessDisableWrapperProps>> = ({
    hasFunctionalAccess,
    children,
}) => {
    const { t } = useTranslation();

    if (!hasFunctionalAccess) {
        return (
            <DefaultTooltipHost
                tooltipId={'functional-access-disabled-tooltip'}
                tooltipContent={t('noFunctionalAccess')}
                alwaysDisplay={true}
            >
                {React.cloneElement(children as any, { disabled: !hasFunctionalAccess })}
            </DefaultTooltipHost>
        );
    }

    return <>{children}</>;
};

export default FunctionalAccessDisableWrapper;
