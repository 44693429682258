import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { applicationInsightsReactPlugin } from '../../../ApplicationInsightsService';
import { DrillDownContextProvider } from '../../../contexts/drillDownContext';
import FormPdfViewProvider from '../../../contexts/formPdfViewContext';
import { GridScrollContextProvider } from '../../../contexts/gridScrollContext';
import { searchOnType, SearchTableContextProvider } from '../../../contexts/searchTableContext';
import { VarianceNavigationContextProvider } from '../../../contexts/varianceNavigationContext';
import { usePreventNavigation } from '../../../hooks/usePreventNavigation';
import { COMPARE_REPORT_URL } from '../../../utils/Urls.constant';
import ReportLinesTable from './ReportLinesTable';

const ReportLines: FC = () => {
    const params = useParams();
    const reportId = params.reportId ? parseInt(params.reportId) : 0;
    const pageId = params.pageId;
    const pageName = params.pageName;

    // this is specifically for overrides
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const { blocker } = usePreventNavigation(isDirty);
    const navigate: NavigateFunction = useNavigate();
    const navigateToCompareReport = (versionId: string, selectedEntities: string[]) => {
        navigate(`${COMPARE_REPORT_URL}${reportId}/${versionId}?selectedEntities=${selectedEntities?.join(',')}`);
    };

    return (
        <DrillDownContextProvider>
            <SearchTableContextProvider searchOn={searchOnType.Report}>
                <GridScrollContextProvider>
                    <FormPdfViewProvider returnKey={reportId} pageId={pageId} pageName={pageName}>
                        <VarianceNavigationContextProvider>
                            <ReportLinesTable
                                key={reportId}
                                reportId={reportId}
                                blocker={blocker}
                                setIsDirtyFlag={setIsDirty}
                                navigateToCompareReport={navigateToCompareReport}
                            />
                        </VarianceNavigationContextProvider>
                    </FormPdfViewProvider>
                </GridScrollContextProvider>
            </SearchTableContextProvider>
        </DrillDownContextProvider>
    );
};

export default withAITracking(applicationInsightsReactPlugin, ReportLines, 'Individual return');
