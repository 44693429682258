import { useCorptaxTheme } from '@corptax/react-components-common';
import { CommandBarButton, DirectionalHint, IButtonStyles, IContextualMenuItem, IContextualMenuProps, StackItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { IAppTheme } from '../../theme/IAppTheme';

export interface ICommentContextualMenuButtonProps {
    showMenuButton?: boolean;
    menuItems?: IContextualMenuItem[];
    onMenuOpen: () => void;
    onMenuDismissed: () => void;
    isInTabOrder: boolean;
}

export const CommentContextualMenuButton = (props: ICommentContextualMenuButtonProps) => {
    const { brandPalette, customPalette } = useCorptaxTheme<IAppTheme>();
    const { t } = useTranslation();
    const { state: uiState } = useCommentsUI();

    const menuProps: IContextualMenuProps = {
        directionalHint: DirectionalHint.bottomRightEdge,
        isBeakVisible: false,
        styles: {
            list: {
                '.ms-ContextualMenu-item .ms-ContextualMenu-linkContent > .ms-ContextualMenu-itemText': { padding: 0 },
            },
        },
        items: props.menuItems ?? [],
    };

    const onRenderOverflowButtonStyles: IButtonStyles = {
        root: { padding: 0, minWidth: 24 },
        menuIcon: { fontSize: 24, height: 24, margin: 0 },
        rootHovered: {
            backgroundColor: brandPalette.coolLightGrayTint1,
            color: brandPalette.flatPrimaryButtonHover,
        },
        menuIconHovered: {
            color: brandPalette.flatPrimaryButtonHover,
        },
        rootExpanded: {
            backgroundColor: brandPalette.coolLightGray,
            color: customPalette.themePrimary,
            '.ms-Button-menuIcon': {
                color: customPalette.themePrimary,
            },
        },
    };

    if (props.showMenuButton && menuProps.items.length > 0) {
        return (
            <StackItem>
                <CommandBarButton
                    allowDisabledFocus={true}
                    data-is-focuszone-keypress-skip
                    data-is-focusable={props.isInTabOrder}
                    tabIndex={props.isInTabOrder ? 0 : -1}
                    aria-label={t('moreActions').toString()}
                    styles={onRenderOverflowButtonStyles}
                    menuIconProps={{ iconName: 'FDL2MoreFilled24' }}
                    menuProps={menuProps}
                    onMenuClick={props.onMenuOpen}
                    onAfterMenuDismiss={props.onMenuDismissed}
                    disabled={uiState.hasPendingChanges}
                />
            </StackItem>
        );
    } else {
        return null;
    }
};
