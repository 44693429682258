import { StackItem } from '@fluentui/react';
import { CommentRichTextViewer } from './editor/CommentRichTextViewer';

export interface IViewCommentProps {
    comment?: string | null;
}

export const ViewComment = (props: IViewCommentProps) => {
    return (
        <StackItem style={{ wordBreak: 'break-word' }}>
            <CommentRichTextViewer commentHtml={props.comment} />
        </StackItem>
    );
};
