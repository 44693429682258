import { useCorptaxTheme } from '@corptax/react-components-common';
import {
    CommandBarButton,
    ContextualMenuItemType,
    DirectionalHint,
    IButtonStyles,
    IContextualMenuItem,
    IContextualMenuProps,
    Label,
    mergeStyles,
    StackItem,
    TextField,
    TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useQueryClient } from '@tanstack/react-query';
import { memo, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddItemsToCurrentCart, useCreateNewCart, useGetCurrentCartSelector, useSetCurrentCart } from '../../api/cart/cart';
import { ISelectedTaxReturnsAndFilters } from '../../data-types/exportReports';
import { useFunctionalAccess } from '../../hooks/useFunctionalAccess';
import { useServiceDiscovery } from '../../hooks/useServiceDiscovery';
import { CreateNewCartRequest, RecentCartInfo } from '../../model';
import { IAppTheme } from '../../theme/IAppTheme';
import { getTooltipHostProps } from '../../utils/CustomStyles';
import { validNameRuleRegExp } from '../../utils/RegExpUtils';
import FunctionalAccessDisableWrapper from './FunctionalAccessDisableWrapper';
import CustomIconButton from './IconButton';
import Modal from './Modal';

export interface ICartButtonProps {
    disabled?: boolean;
    getReportsToExport: () => ISelectedTaxReturnsAndFilters;
}

const CartButton: React.FC<ICartButtonProps> = memo(({ getReportsToExport, disabled }) => {
    const { selectedTaxReturns } = getReportsToExport();
    const { t } = useTranslation();
    const [isAddToCartModalOpen, { setFalse: closeAddToCartModalOpen, setTrue: openAddToCartModalOpen }] = useBoolean(false);
    const [cartName, setCartName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const { cartManagerUrl } = useServiceDiscovery();
    const { functionalAccess } = useFunctionalAccess();

    const queryClient = useQueryClient();
    const { data, queryKey: getCurrentCartSelectorQueryKey } = useGetCurrentCartSelector();

    const { mutateAsync: addItemsToCurrentCart, isLoading: isAddItemsToCurrentCartLoading } = useAddItemsToCurrentCart({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(getCurrentCartSelectorQueryKey);
            },
        },
    });

    const { mutateAsync: addNewCart, isLoading: isLoadingCreateCart } = useCreateNewCart({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(getCurrentCartSelectorQueryKey);
            },
        },
    });

    const { mutateAsync: setCurrentCartAsync, isLoading: IsLoadingSetCart } = useSetCurrentCart({
        mutation: {
            onSuccess: (res: any) => {
                if (res.success) queryClient.invalidateQueries(getCurrentCartSelectorQueryKey);
            },
        },
    });

    const currentCart: RecentCartInfo | undefined = data?.currentCart;
    const currentCartNameTooltip = currentCart?.name ? t('currentCart') + currentCart.name : t('noCartSelected');
    const currentCartItemsCount = currentCart ? `(${currentCart.itemsNumber})` : '';

    const isLoading = isLoadingCreateCart || IsLoadingSetCart;

    const clearModalStateAndClose = () => {
        if (!isLoading) {
            setCartName('');
            setErrorMessage(undefined);
        }

        closeAddToCartModalOpen();
    };

    const updateInputTextValue = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
        if (value !== undefined && validNameRuleRegExp.test(value)) {
            setCartName(value);
            setErrorMessage(undefined);
        }
        if (value !== undefined && value.length >= 60) {
            setErrorMessage(t('cartNameLengthExceeded').toString());
        }
    };

    const createNewCart = async () => {
        if (validNameRuleRegExp.test(cartName) && cartName) {
            const request: CreateNewCartRequest = {
                name: cartName.trim(),
            };
            try {
                const response = await addNewCart({ data: request });
                if (response.errors && response.errors.length > 0) {
                    const errorMessageToDisplay = response.errors[0].message ?? t('addToCartGenericErrorMessage').toString();
                    setErrorMessage(errorMessageToDisplay);
                } else {
                    clearModalStateAndClose();
                }
            } catch (error) {
                setErrorMessage(t('addToCartGenericErrorMessage').toString());
            }
        } else {
            setErrorMessage(t('cartNameIsRequired').toString());
        }
    };

    const AddToCartModalButtons = [
        {
            text: t('createNewCart'),
            onClick: createNewCart,
            primary: true,
            isProcessing: isLoading,
            disabled: !validNameRuleRegExp.test(cartName) || !cartName,
        },
        { text: t('cancel'), onClick: clearModalStateAndClose, disabled: isLoading },
    ];

    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const menuItemsStyles: IButtonStyles = {
        root: {
            width: '100%',
            height: 36,
            textAlign: 'left',
        },
        label: {
            fontWeight: 600,
        },
    };

    const recentCartsMenuItems: IContextualMenuItem[] =
        data?.recentCarts?.map((cartInfo) => ({
            key: cartInfo.id || '',
            name: `${cartInfo.name} (${cartInfo.itemsNumber})` || '',
            onClick: () => {
                setCurrentCartAsync({ data: { cartId: cartInfo.id } });
            },
        })) || [];

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'add-to-new-cart',
            name: t('addNewCart').toString(),
            disabled: isLoading || !functionalAccess?.cartDropdownEnabled,
            onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void) => {
                return (
                    <FunctionalAccessDisableWrapper hasFunctionalAccess={functionalAccess?.cartDropdownEnabled}>
                        <CommandBarButton
                            id={`item-add-to-new-cart`}
                            styles={menuItemsStyles}
                            onClick={(e: any) => {
                                e?.preventDefault();
                                openAddToCartModalOpen();
                                dismissMenu();
                            }}
                            disabled={isLoading}
                            aria-label={item.text}
                            iconProps={{ iconName: 'ShoppingCart' }}
                        >
                            {item.name}
                        </CommandBarButton>
                    </FunctionalAccessDisableWrapper>
                );
            },
        },
        {
            key: 'recent-carts',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                topDivider: true,
                title: t('recentCarts').toString(),
                items: recentCartsMenuItems,
            },
        },
    ];

    const cartMenuProps: IContextualMenuProps = {
        items: menuItems,
        className: mergeStyles({ paddingTop: '10px', paddingBottom: '10px' }),
        ariaLabel: t('cart').toString(),
    };

    const addSelectedItemsToCurrentCart = async () => {
        const selectedTaxReturnIds = selectedTaxReturns?.map((report) => report.taxReturnItemId!) ?? [];
        await addItemsToCurrentCart({ data: { cartItemIds: selectedTaxReturnIds } });
    };

    const redirectToPortalCartManager = () => {
        window.open(cartManagerUrl, '_blank');
    };

    const renderContent = (): ReactNode => (
        <StackItem tokens={{ padding: 5 }}>
            <Label>{t('newCartName').toString()}</Label>
            <TextField
                autoFocus
                ariaLabel={t('newCartName').toString()}
                placeholder={t('newCart').toString()}
                value={cartName}
                style={{ minWidth: '400px' }}
                onChange={updateInputTextValue}
                disabled={isLoading}
                errorMessage={errorMessage}
            />
        </StackItem>
    );

    const tooltipHostProps = getTooltipHostProps(DirectionalHint.rightCenter, undefined, customPalette);
    tooltipHostProps.id = 'cart-tooltip';
    tooltipHostProps.directionalHint = DirectionalHint.leftCenter;
    tooltipHostProps.content = currentCartNameTooltip;
    const buttonAriaLabel = `${tooltipHostProps.content}. ${currentCart?.itemsNumber ?? 0} items`;

    const cartIconButon = (
        <CustomIconButton
            label={currentCartItemsCount}
            ariaLabel={buttonAriaLabel}
            iconName='Cart24Filled'
            splitButton
            menuProps={cartMenuProps}
            splitButtonAriaLabel={buttonAriaLabel}
            role='button'
            isProcessing={isLoading}
            dataTestid='add-new-cart-button-split-button'
            onButtonClick={redirectToPortalCartManager}
        />
    );

    const canAccessCartButton = functionalAccess?.cartButtonEnabled;

    return (
        <>
            <FunctionalAccessDisableWrapper
                hasFunctionalAccess={functionalAccess?.cartButtonEnabled && functionalAccess?.addToCartButtonEnabled}
            >
                <CustomIconButton
                    label={t('addToCart').toString()}
                    iconName='Cart24Filled'
                    onButtonClick={addSelectedItemsToCurrentCart}
                    isProcessing={isAddItemsToCurrentCartLoading}
                    disabled={disabled}
                    dataTestid='cart-icon-button'
                ></CustomIconButton>
            </FunctionalAccessDisableWrapper>
            {canAccessCartButton ? (
                <TooltipHost {...tooltipHostProps}>{cartIconButon}</TooltipHost>
            ) : (
                <FunctionalAccessDisableWrapper hasFunctionalAccess={canAccessCartButton}>{cartIconButon}</FunctionalAccessDisableWrapper>
            )}
            <Modal
                isOpen={isAddToCartModalOpen}
                onClose={clearModalStateAndClose}
                headerContent={t('addNewCart')}
                bodyContent={renderContent()}
                buttons={AddToCartModalButtons}
            />
        </>
    );
});

export default CartButton;
