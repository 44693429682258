export const useCommentTitleGeneration = (reportId?: string, reportRowNumber?: number, reportRowDescription?: string) => {
    const defaultTitle = 'Comment';

    let cellLevelTitle = defaultTitle;
    if (reportRowDescription) {
        cellLevelTitle = reportRowDescription!.trim();
    }

    const newConversationTitle = reportRowNumber ? cellLevelTitle : reportId ? 'General' : defaultTitle;

    return { newConversationTitle };
};
