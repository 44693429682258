import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useCommentsUIStyles } from '../../hooks/useCommentsUIStyles';

export interface IDeleteCommentConfirmationDialogProps {
    show: boolean;
    hasReplies: boolean;
    onClose: () => void;
    onDelete: () => void;
}

// (re)used for delete conversation and delete reply
export const DeleteCommentConfirmationDialog = (props: IDeleteCommentConfirmationDialogProps) => {
    const { t } = useTranslation();

    const closeLabel = t('close').toString();
    const cancelLabel = t('cancel');
    const deleteLabel = t('delete');
    const dialogHeading = t('deleteCommentDialogHeading');
    const dialogText = props.hasReplies ? t('deleteAllCommentsDialogText') : t('deleteCommentDialogText');

    const styles = useCommentsUIStyles();

    return (
        <Dialog
            hidden={!props.show}
            onDismiss={props.onClose}
            dialogContentProps={{
                type: DialogType.normal,
                title: dialogHeading,
                closeButtonAriaLabel: closeLabel,
                subText: dialogText,
            }}
            modalProps={{
                isBlocking: true,
                styles: styles.fluentV9DialogAdjustment,
            }}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={props.onDelete}
                    text={deleteLabel}
                    ariaLabel={deleteLabel}
                    styles={styles.fluentV9ButtonAdjustment}
                />
                <DefaultButton
                    onClick={props.onClose}
                    text={cancelLabel}
                    ariaLabel={cancelLabel}
                    styles={styles.fluentV9ButtonAdjustment}
                />
            </DialogFooter>
        </Dialog>
    );
};
