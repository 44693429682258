import * as React from 'react';
import { DrillDownContext } from '../contexts/drillDownContext';

export function useDrillDown() {
    const context = React.useContext(DrillDownContext);
    if (!context) {
        throw new Error('useDrillDown must be wrapped in a DrillDownContextProvider');
    }
    return context;
}
