/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { UserClaims } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetClaimsForCurrentUserHook = () => {
    const getClaimsForCurrentUser = useCustomInstance<UserClaims>();

    return (signal?: AbortSignal) => {
        return getClaimsForCurrentUser({ url: `/UserClaims`, method: 'get', signal });
    };
};

export const getGetClaimsForCurrentUserQueryKey = () => [`/UserClaims`] as const;

export const useGetClaimsForCurrentUserQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetClaimsForCurrentUserHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClaimsForCurrentUserHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClaimsForCurrentUserHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetClaimsForCurrentUserQueryKey();

    const getClaimsForCurrentUser = useGetClaimsForCurrentUserHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClaimsForCurrentUserHook>>>> = ({ signal }) =>
        getClaimsForCurrentUser(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetClaimsForCurrentUserQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClaimsForCurrentUserHook>>>>;
export type GetClaimsForCurrentUserQueryError = ErrorType<unknown>;

export const useGetClaimsForCurrentUser = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetClaimsForCurrentUserHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClaimsForCurrentUserHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetClaimsForCurrentUserQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
