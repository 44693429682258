import { IEnterAmountsDialogFiltersFilterSelections } from '../components/enter-amounts-dialog/EnterAmountsDialogFilters';
import { IAdjustment, ICalendarPeriod, ICase, IEntity, IJurisdiction } from '../model';

export interface IEnterAmountsDialogFiltersState {
    applyButtonDisabled: boolean;
    currentFilterSelections: IEnterAmountsDialogFiltersFilterSelections;
}

export enum EnterAmountsDialogFiltersActionType {
    AdjustmentChanged,
    AmountsOnlyChanged,
    ApplyButtonClicked,
    CalendarPeriodChanged,
    CaseChanged,
    EntityChanged,
    JurisdictionChanged,
    ResetButtonClicked,
    CombineDebitCreditColumnsChanged,
}

export interface IEnterAmountsDialogFiltersActionBase {
    type: EnterAmountsDialogFiltersActionType;
}

export interface IEnterAmountsDialogFilterAdjustmentChangeAction
    extends IEnterAmountsDialogFilterFilterChangeAction<IAdjustment | undefined> {
    type: EnterAmountsDialogFiltersActionType.AdjustmentChanged;
}

export interface IEnterAmountsDialogFilterAmountsOnlyChangeAction extends IEnterAmountsDialogFilterFilterChangeAction<boolean> {
    type: EnterAmountsDialogFiltersActionType.AmountsOnlyChanged;
}

export interface IEnterAmountsDialogFiltersApplyButtonClickAction extends IEnterAmountsDialogFiltersActionBase {
    type: EnterAmountsDialogFiltersActionType.ApplyButtonClicked;
}

export interface IEnterAmountsDi9alogFiltersResetButtonClickAction extends IEnterAmountsDialogFiltersActionBase {
    type: EnterAmountsDialogFiltersActionType.ResetButtonClicked;
    initialState: IEnterAmountsDialogFiltersState;
}

export interface IEnterAmountsDialogFilterCalendarPeriodChangeAction
    extends IEnterAmountsDialogFilterFilterChangeAction<ICalendarPeriod | undefined> {
    type: EnterAmountsDialogFiltersActionType.CalendarPeriodChanged;
}

export interface IEnterAmountsDialogFilterCaseChangeAction extends IEnterAmountsDialogFilterFilterChangeAction<ICase | undefined> {
    type: EnterAmountsDialogFiltersActionType.CaseChanged;
}

export interface IEnterAmountsDialogFilterCombineDebitCreditColumnsChangeAction
    extends IEnterAmountsDialogFilterFilterChangeAction<boolean> {
    type: EnterAmountsDialogFiltersActionType.CombineDebitCreditColumnsChanged;
}

export interface IEnterAmountsDialogFilterFilterChangeAction<T> extends IEnterAmountsDialogFiltersActionBase {
    newFilterValue: T;
}

export interface IEnterAmountsDialogFilterEntityChangeAction extends IEnterAmountsDialogFilterFilterChangeAction<IEntity | undefined> {
    type: EnterAmountsDialogFiltersActionType.EntityChanged;
}

export interface IEnterAmountsDialogFilterJurisdictionChangeAction
    extends IEnterAmountsDialogFilterFilterChangeAction<IJurisdiction | undefined> {
    type: EnterAmountsDialogFiltersActionType.JurisdictionChanged;
}

export declare type EnterAmountsDialogFiltersFilterComboboxChangeActions =
    | IEnterAmountsDialogFilterAdjustmentChangeAction
    | IEnterAmountsDialogFilterCalendarPeriodChangeAction
    | IEnterAmountsDialogFilterCaseChangeAction
    | IEnterAmountsDialogFilterEntityChangeAction
    | IEnterAmountsDialogFilterJurisdictionChangeAction;

export declare type EnterAmountsDialogFiltersAction =
    | IEnterAmountsDialogFilterAdjustmentChangeAction
    | IEnterAmountsDialogFilterAmountsOnlyChangeAction
    | IEnterAmountsDialogFiltersApplyButtonClickAction
    | IEnterAmountsDialogFilterCalendarPeriodChangeAction
    | IEnterAmountsDialogFilterCaseChangeAction
    | IEnterAmountsDialogFilterCombineDebitCreditColumnsChangeAction
    | IEnterAmountsDialogFilterEntityChangeAction
    | IEnterAmountsDialogFilterJurisdictionChangeAction
    | IEnterAmountsDi9alogFiltersResetButtonClickAction;

export interface IEnterAmountsDialogComboboxPropsBase<
    TOption,
    TAction extends IEnterAmountsDialogFilterFilterChangeAction<TOption | undefined> & EnterAmountsDialogFiltersFilterComboboxChangeActions
> {
    options: TOption[] | null | undefined;
    selectedOption: TOption | undefined;
    dispatch?: React.Dispatch<TAction>;
    clearable?: boolean;
}

export type EnterAmountsDialogFiltersReducer = (
    state: IEnterAmountsDialogFiltersState,
    action: EnterAmountsDialogFiltersAction
) => IEnterAmountsDialogFiltersState;

export const enterAmountsDialogFiltersReducer: EnterAmountsDialogFiltersReducer = (
    state: IEnterAmountsDialogFiltersState,
    action: EnterAmountsDialogFiltersAction
) => {
    switch (action.type) {
        case EnterAmountsDialogFiltersActionType.ApplyButtonClicked:
            return { ...state, applyButtonDisabled: true };
        case EnterAmountsDialogFiltersActionType.ResetButtonClicked:
            return { ...action.initialState };
        case EnterAmountsDialogFiltersActionType.AdjustmentChanged:
        case EnterAmountsDialogFiltersActionType.AmountsOnlyChanged:
        case EnterAmountsDialogFiltersActionType.CalendarPeriodChanged:
        case EnterAmountsDialogFiltersActionType.CaseChanged:
        case EnterAmountsDialogFiltersActionType.CombineDebitCreditColumnsChanged:
        case EnterAmountsDialogFiltersActionType.EntityChanged:
        case EnterAmountsDialogFiltersActionType.JurisdictionChanged:
            return {
                ...state,
                currentFilterSelections: {
                    ...state.currentFilterSelections,
                    [getFilterNameForActionType(action.type)]: action.newFilterValue,
                },
                applyButtonDisabled: false,
            };
        default:
            throw new Error('Invalid action type passed to enterAmountsDialogFiltersReducer');
    }
};

export function getFilterNameForActionType(
    actionType: EnterAmountsDialogFiltersActionType
): keyof IEnterAmountsDialogFiltersFilterSelections {
    switch (actionType) {
        case EnterAmountsDialogFiltersActionType.AdjustmentChanged:
            return 'adjustment';

        case EnterAmountsDialogFiltersActionType.AmountsOnlyChanged:
            return 'amountsOnly';

        case EnterAmountsDialogFiltersActionType.CalendarPeriodChanged:
            return 'calendarPeriod';

        case EnterAmountsDialogFiltersActionType.CaseChanged:
            return 'case';

        case EnterAmountsDialogFiltersActionType.CombineDebitCreditColumnsChanged:
            return 'combineDebitCreditColumns';

        case EnterAmountsDialogFiltersActionType.EntityChanged:
            return 'entity';

        case EnterAmountsDialogFiltersActionType.JurisdictionChanged:
            return 'jurisdiction';

        default:
            throw new Error(`Invalid action type passed to getFilterNameForActionType: ${actionType}`);
    }
}
