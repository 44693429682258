import React, { createContext, useEffect, useState } from 'react';
import { useGetClaimsForCurrentUser } from '../api/user-claims/user-claims';
import { getCalculationReviewUrl, getCartManagerUrl } from '../utils/EnvironmentUtils';

export interface IServiceDiscoveryConfig {
    appInsightsConnectionString: string | undefined;
    homeUrl: string | undefined;
    cartManagerUrl: string | undefined;
    calculationReviewUrl?: string | undefined;
}

interface IAppSettings {
    appInsightsConnectionString: string;
    homeUrl: string;
}

export const ServiceDiscoveryContext = createContext<IServiceDiscoveryConfig | null>(null);

export function ServiceDiscoveryContextProvider(props: { value?: IServiceDiscoveryConfig; children?: React.ReactNode }) {
    const [appSettings, setAppSettings] = useState<IAppSettings>({
        appInsightsConnectionString: '',
        homeUrl: '',
    });

    const { data: claims, isSuccess } = useGetClaimsForCurrentUser();
    const cartManagerUrl = getCartManagerUrl(claims?.serviceUri || '');
    const calculationReviewUrl = getCalculationReviewUrl(window.location.host);

    useEffect(() => {
        if (isSuccess && (claims?.appInsightsConnectionString || claims?.homeUrl)) {
            setAppSettings({
                appInsightsConnectionString: claims.appInsightsConnectionString ?? '',
                homeUrl: claims.homeUrl ?? '',
            });
        }
    }, [claims, isSuccess]);

    return (
        <ServiceDiscoveryContext.Provider
            value={
                props.value ?? {
                    ...appSettings,
                    cartManagerUrl,
                    calculationReviewUrl,
                }
            }
        >
            {props.children}
        </ServiceDiscoveryContext.Provider>
    );
}

export default ServiceDiscoveryContextProvider;
