import { useContext } from 'react';
import { ServiceDiscoveryContext } from '../contexts/serviceDiscoveryContext';

export function useServiceDiscovery() {
    const serviceDiscoveryContext = useContext(ServiceDiscoveryContext);
    if (!serviceDiscoveryContext) {
        throw new Error('useServiceDiscovery must be wrapped in a ServiceDiscoveryContextProvider');
    }
    return serviceDiscoveryContext;
}
