import { MessageBarType } from '@fluentui/react';
import { createContext, useState } from 'react';
import { CustomMessageBar } from '../components/messagebar/customMessageBar';

export interface IFeedbackMessageContextValues {
    displayFeedbackMessage: (message: IFeedbackMessage) => void;
    activeMessage: IFeedbackMessage | undefined;
}

export interface IFeedbackMessage {
    message: string | React.ReactNode;
    type: MessageBarType;
}

export const FeedbackMessagesContext = createContext<IFeedbackMessageContextValues | null>(null);

export function FeedbackMessagesContextProvider(props: any) {
    const [activeMessage, setActiveMessage] = useState<IFeedbackMessage | undefined>(undefined);

    return (
        <FeedbackMessagesContext.Provider value={{ displayFeedbackMessage: setActiveMessage, activeMessage }}>
            {activeMessage && (
                <CustomMessageBar message={activeMessage.message} type={activeMessage.type} setActiveMessage={setActiveMessage} />
            )}
            {props.children}
        </FeedbackMessagesContext.Provider>
    );
}
