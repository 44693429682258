import { useCorptaxTheme } from '@corptax/react-components-common';
import { DefaultButton, mergeStyles, mergeStyleSets, Stack } from '@fluentui/react';
import React, { BaseSyntheticEvent, KeyboardEvent } from 'react';
import { IAppTheme } from '../../theme/IAppTheme';

const TAB_KEY_PREFIX = 'corptax-tab-';
const ARROW_RIGHT_KEY_NAME = 'ArrowRight';
const ARROW_LEFT_KEY_NAME = 'ArrowLeft';
const HOME_KEY_NAME = 'Home';
const END_KEY_NAME = 'End';
const SUPPORTED_TAB_KEYS = [ARROW_RIGHT_KEY_NAME, ARROW_LEFT_KEY_NAME, HOME_KEY_NAME, END_KEY_NAME];

export interface TabListProps {
    tabs: string[];
    activeTabIndex: number;
    label: string;
    setActiveTabIndex: (value: number) => void;
}

const handleArrowKeys = (event: BaseSyntheticEvent) => {
    const keyboardEvent = event as KeyboardEvent;
    const targetElement = event.target as HTMLElement;

    if (SUPPORTED_TAB_KEYS.includes(keyboardEvent.key)) {
        event.preventDefault();
    }

    switch (keyboardEvent.key) {
        case ARROW_RIGHT_KEY_NAME:
            focusNextOrFirstTab(targetElement);
            break;
        case ARROW_LEFT_KEY_NAME:
            focusPreviousOrLastTab(targetElement);
            break;
        case HOME_KEY_NAME:
            focusFirstTab(targetElement);
            break;
        case END_KEY_NAME:
            focusLastTab(targetElement);
            break;
    }
};

const focusNextOrFirstTab = (targetElement: HTMLElement) => {
    const nextSibling = targetElement.nextSibling as HTMLElement;
    const firstTab = targetElement.parentElement?.firstChild as HTMLElement;

    if (nextSibling) {
        nextSibling.focus();
    } else {
        firstTab.focus();
    }
};

const focusPreviousOrLastTab = (targetElement: HTMLElement) => {
    const previousSibling = targetElement.previousSibling as HTMLElement;
    const lastTab = targetElement.parentElement?.lastChild as HTMLElement;

    if (previousSibling) {
        previousSibling.focus();
    } else {
        lastTab.focus();
    }
};

const focusFirstTab = (targetElement: HTMLElement) => {
    const firstTab = targetElement.parentElement?.firstChild as HTMLElement;

    if (firstTab) {
        firstTab.focus();
    }
};

const focusLastTab = (targetElement: HTMLElement) => {
    const lastTab = targetElement.parentElement?.lastChild as HTMLElement;

    if (lastTab) {
        lastTab.focus();
    }
};

const TabList: React.FC<TabListProps> = ({ tabs, activeTabIndex, label, setActiveTabIndex }) => {
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { baseTabStyle, tabsWrapperStyles } = mergeStyleSets({
        baseTabStyle: {
            fontSize: '12px',
            fontWeight: 500,
            border: 'none',
            borderRadius: '6px',
            lineHeight: '22px',
        },
        tabsWrapperStyles: {
            paddingTop: '8px',
            paddingLeft: '10px',
        },
    });

    const tabStyles = mergeStyles(
        {
            color: customPalette.blueDark,
        },
        baseTabStyle
    );
    const activeTabStyles = mergeStyles(
        {
            backgroundColor: customPalette.themeLighter,
            color: customPalette.themePrimary,
        },
        baseTabStyle
    );

    const filterTabs = tabs.map((tab, index) => {
        const isActiveTab = index === activeTabIndex;
        return (
            <DefaultButton
                role='tab'
                aria-selected={isActiveTab}
                tabIndex={isActiveTab ? 0 : -1}
                onKeyDown={handleArrowKeys}
                key={TAB_KEY_PREFIX + index}
                className={isActiveTab ? activeTabStyles : tabStyles}
                onClick={() => setActiveTabIndex(index)}
            >
                {tab}
            </DefaultButton>
        );
    });

    return (
        <Stack horizontal role='tablist' aria-label={label} className={tabsWrapperStyles}>
            {filterTabs}
        </Stack>
    );
};

export default TabList;
