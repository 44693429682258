import type { IModalStyleProps, IModalStyles, IStyleFunctionOrObject } from '@fluentui/react';
import { Dialog, DialogFooter, DialogType, mergeStyles } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useCommentsUIStyles } from '../../hooks/useCommentsUIStyles';
import FilterActionBar, { IFilterActionBarProps } from '../filters/filter-actions/FilterActionBar';

export interface IConfirmationDialogProps {
    show: boolean;
    heading: string;
    content: string;
    onDismiss: () => void;
    filterActionBarProps: IFilterActionBarProps;
}

const ConfirmationDialog = (props: IConfirmationDialogProps) => {
    const { t } = useTranslation();

    const closeLabel = t('close').toString();
    const cancelLabel = 'cancel';
    const confirmLabel = 'saveChanges';
    const styles: IStyleFunctionOrObject<IModalStyleProps, IModalStyles> = {
        main: {
            ...useCommentsUIStyles().fluentV9DialogAdjustment.main,
            '& .ms-Dialog-title': {
                padding: '24px 24px 15px 24px',
            },
            '& .ms-Dialog-subText': {
                paddingBottom: '0px',
                marginBottom: '0px',
            },
            '& .ms-Dialog-actions': {
                paddingTop: '0px',
                marginTop: '0px',
            },
        },
    };

    const filterActionBarProps: IFilterActionBarProps = {
        ...props.filterActionBarProps,
        primaryButtonLabel: props.filterActionBarProps.primaryButtonLabel ?? confirmLabel,
        secondaryButtonLabel: props.filterActionBarProps.secondaryButtonLabel ?? cancelLabel,
        wrapperStyles: mergeStyles({
            padding: '16px 20px 0px 20px',
        }),
    };
    return (
        <Dialog
            hidden={!props.show}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.heading,
                closeButtonAriaLabel: closeLabel,
                subText: props.content,
            }}
            modalProps={{
                isBlocking: true,
                styles: styles,
            }}
        >
            <DialogFooter>
                <FilterActionBar {...filterActionBarProps} />
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmationDialog;
