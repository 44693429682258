import { DateTimeDisplay } from '@corptax/react-components-common';
import { IContextualMenuItem, mergeStyles, Stack, StackItem, Text } from '@fluentui/react';
import { Avatar } from '@fluentui/react-components';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsCurrentType } from '../../data-types/ICommentsUIState';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { Conversation, DeleteReplyRequest, Reply } from '../../model';
import { UpdateReplyRequest } from '../../model/updateReplyRequest';
import { CommentsActionType } from '../../reducers/commentsStateReducer';
import { OverlaySpinner } from '../common/OverlaySpinner';
import { CommentContextualMenuButton } from './CommentContextualMenuButton';
import { EditComment } from './EditComment';
import { ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';
import { ViewComment } from './ViewComment';

// todo: ditch dependence on Orval model
export interface IReplyItemProps {
    reply: Reply;
    conversation: Conversation;
    onMenuOpened: () => void;
    onReplyUpdated: (value: ICommentRichTextEditorValue) => void;
    commentToHighlightRef?: RefObject<HTMLDivElement>;
}

export const ReplyItem = (props: IReplyItemProps) => {
    const { t } = useTranslation();
    const showDelete = props.reply.allowDelete;
    const showEdit = props.reply.allowModification;
    const { state: uiState, dispatch: dispatchUIState } = useCommentsUI();
    const updateRequest = uiState.replyUpdateRequest;
    const isHighlighted = uiState.highlightedCommentId && uiState.highlightedCommentId === props.reply.id?.toLowerCase();
    const isEdit = updateRequest != null && uiState.selectedReplyId === props.reply.id;
    const isDeleting = uiState.isSubmitting === true && props.reply?.id === uiState.replyDeleteRequest?.replyId;
    const operationErrorMessage =
        props.conversation?.id === uiState.selectedCommentId && uiState.operationFailedErrorMessage
            ? uiState.operationFailedErrorMessage
            : undefined;
    const submitErrorMessage =
        props.conversation?.id === uiState.selectedCommentId && uiState.submitFailedErrorMessage
            ? uiState.submitFailedErrorMessage
            : undefined;
    const errorMessage = (submitErrorMessage || operationErrorMessage) ?? undefined;
    const iconClass: string = mergeStyles({
        '& svg': {
            verticalAlign: '-0.125em',
            height: '16px',
            width: '16px',
        },
    });

    const handleDeleteCommentClick = () => {
        var parent = props.conversation.parent;
        if (parent && parent.locationId && parent.parentLocationId && parent.id && props.conversation.id && props.reply?.id) {
            var deleteRequest: DeleteReplyRequest = {
                locationId: parent?.locationId!,
                parentLocationId: parent?.parentLocationId!,
                collaborationId: parent?.id!,
                conversationId: props.conversation.id,
                replyId: props.reply.id,
            };
            dispatchUIState({ type: CommentsActionType.DeleteReply, replyDeleteRequest: deleteRequest });
        }
    };

    const handleUpdateCommentClick = () => {
        var parent = props.conversation.parent;
        if (parent && parent.locationId && parent.parentLocationId && parent.id && props.conversation.id && props.reply.id) {
            var updateRequest: UpdateReplyRequest = {
                locationId: parent.locationId,
                parentLocationId: parent.parentLocationId,
                collaborationId: parent.id,
                conversationId: props.conversation.id,
                replyId: props.reply.id,
            };
            dispatchUIState({ type: CommentsActionType.EditReply, replyUpdateRequest: updateRequest });
        }
    };

    const handleCancelUpdate = () => {
        dispatchUIState({ type: CommentsActionType.CancelOperation });
    };

    const handleCommentValueChange = (hasValidChangesCommentValue: boolean) => {
        if (hasValidChangesCommentValue) {
            dispatchUIState({ type: CommentsActionType.MadeChanges });
        } else {
            dispatchUIState({ type: CommentsActionType.UndoMadeChanges });
        }
    };

    const menuItems = new Array<IContextualMenuItem>();
    if (showDelete) {
        const deleteText = t('deleteComment').toString();
        menuItems.push({
            key: 'deleteComment',
            name: deleteText,
            ariaLabel: deleteText,
            onClick: handleDeleteCommentClick,
            iconProps: {
                iconName: 'FDL2DeleteRegular24',
                className: iconClass,
            },
        });
    }
    if (showEdit) {
        const editText = t('editComment').toString();
        menuItems.push({
            key: 'editComment',
            name: editText,
            ariaLabel: editText,
            onClick: handleUpdateCommentClick,
            iconProps: {
                iconName: 'FDL2EditRegular24',
                className: iconClass,
            },
        });
    }

    const isSelected = uiState.selectedCommentId === props.conversation.id;

    const showMenu = menuItems && menuItems.length > 0;
    const isInTabOrder = uiState.currentUiState !== CommentsCurrentType.AddingNewConversation;
    const tabIndex = isSelected && isInTabOrder ? 0 : -1;

    return (
        <div
            ref={isHighlighted ? props.commentToHighlightRef : undefined}
            data-is-focusable={isSelected}
            tabIndex={tabIndex}
            data-is-focuszone-keypress-skip={isSelected}
        >
            <OverlaySpinner label='Deleting...' showOverlay={isDeleting}>
                <Stack horizontal style={{ padding: '10px' }} tokens={{ childrenGap: 10 }}>
                    <StackItem>
                        <span>
                            <Avatar
                                name={props.reply.authorName ?? 'Unknown'}
                                color='colorful'
                                idForColor={props.reply.authorName ?? 'Unknown'}
                                size={24}
                            />
                        </span>
                    </StackItem>
                    <StackItem grow>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign='space-between'>
                                <StackItem grow>
                                    <div>
                                        <Text>{props.reply.authorName}</Text>
                                    </div>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <DateTimeDisplay
                                            timestamp={props.reply.modifiedTimestamp ?? props.reply.timestamp!}
                                            format='LocaleDateTime'
                                        />
                                        {props.reply.modified && <Text>Edited</Text>}
                                    </Stack>
                                </StackItem>
                                <CommentContextualMenuButton
                                    showMenuButton={showMenu}
                                    menuItems={menuItems}
                                    onMenuOpen={props.onMenuOpened}
                                    onMenuDismissed={() => {}}
                                    isInTabOrder={isSelected}
                                />
                            </Stack>
                            {isEdit ? (
                                <EditComment
                                    originalCommentHtml={props.reply.comment}
                                    onCancel={handleCancelUpdate}
                                    onSave={props.onReplyUpdated}
                                    errorMessage={errorMessage}
                                    onChange={handleCommentValueChange}
                                />
                            ) : (
                                <ViewComment comment={props.reply.comment} />
                            )}
                        </Stack>
                    </StackItem>
                </Stack>
            </OverlaySpinner>
        </div>
    );
};
