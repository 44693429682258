import { Stack } from '@fluentui/react';
import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsCurrentType } from '../../data-types/ICommentsUIState';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { useCommentsUIStyles } from '../../hooks/useCommentsUIStyles';
import { AddNewReplyToConversationRequest, Conversation } from '../../model';
import { OverlaySpinner } from '../common/OverlaySpinner';
import { AddReplyItem } from './AddReplyItem';
import { ConversationItem } from './ConversationItem';
import { ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';
import { ErrorDisplay } from './ErrorDisplay';
import { ReplyItem } from './ReplyItem';

// todo: ditch dependence on Orval model
export interface IConversationCardProps {
    title: string;
    conversation: Conversation;
    onSelected: (id?: string | null, cardRef?: RefObject<HTMLDivElement>) => void;
    errorMessage?: string;
    replyErrorMessage?: string;
    onRefresh: () => void;
    onAddReply: (conversationId: string, req: AddNewReplyToConversationRequest) => Promise<boolean>;
    onCancelUpdate: () => void;
    ariaPosinset?: number;
    onConversationUpdated: (value: ICommentRichTextEditorValue) => void;
    onReplyUpdated: (value: ICommentRichTextEditorValue) => void;
    commentToHighlightRef?: RefObject<HTMLDivElement>;
}

// a conversation card displays a single conversation (a post/thread with replies)
export const ConversationCard = (props: IConversationCardProps) => {
    const { t } = useTranslation();
    const cardStyles = useCommentsUIStyles(true);
    const { state: uiState } = useCommentsUI();
    const isSelected = props.conversation.id === uiState.selectedCommentId;
    const cardStyle = isSelected ? cardStyles.cardSelectedClassNames : cardStyles.cardClassNames;
    const cardHeaderStyle = isSelected ? cardStyles.cardHeaderSelectedClassNames : cardStyles.cardHeaderClassNames;
    const cardRef = useRef<HTMLDivElement>(null);
    const isDeleting = uiState.isSubmitting === true && props.conversation?.id === uiState.conversationDeleteRequest?.conversationId;

    const selectCard = () => {
        if (!isSelected) {
            props.onSelected(props.conversation.id, cardRef);
        }
    };

    const handleFocus = () => {
        selectCard();
    };
    const handleClick = () => {
        selectCard();
    };
    const handleMenuOpened = () => {
        selectCard();
    };

    const hasError = props.conversation.id === uiState.selectedCommentId && props.errorMessage;

    return (
        <OverlaySpinner label='Deleting...' showOverlay={isDeleting}>
            <div
                ref={cardRef}
                className={cardStyle}
                key={props.conversation.id}
                data-is-focuszone-keypress-skip
                data-is-focusable={true}
                tabIndex={uiState.currentUiState === CommentsCurrentType.AddingNewConversation ? -1 : 0}
                onFocus={handleFocus}
                onClick={handleClick}
            >
                <div className={cardHeaderStyle}>{props.title}</div>
                <div className={cardStyles.cardItemClassNames}>
                    <ConversationItem
                        conversation={props.conversation}
                        commentToHighlightRef={props.commentToHighlightRef}
                        onConversationUpdated={props.onConversationUpdated}
                        onMenuOpened={handleMenuOpened}
                    />
                    {props.conversation.replies?.map((reply, index) => (
                        <Stack tokens={{ childrenGap: 20 }} key={reply.id}>
                            <ReplyItem
                                reply={reply}
                                commentToHighlightRef={props.commentToHighlightRef}
                                conversation={props.conversation}
                                onMenuOpened={handleMenuOpened}
                                onReplyUpdated={props.onReplyUpdated}
                            />
                        </Stack>
                    ))}
                    <AddReplyItem conversation={props.conversation} onAdd={props.onAddReply} onCancel={props.onCancelUpdate} />
                </div>
            </div>
            <ErrorDisplay
                errorMessage={hasError ? props.errorMessage : undefined}
                errorLinkText={hasError ? t('refresh').toString() : undefined}
                errorLinkAction={hasError ? props.onRefresh : undefined}
            />
        </OverlaySpinner>
    );
};
