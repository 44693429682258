import { Combobox, Label, Option, OptionGroup, Tooltip, useId } from '@fluentui/react-components';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnterAmountsDialogComboboxData } from '../../hooks/useEnterAmountsDialogComboboxData';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { IEntity } from '../../model';
import {
    EnterAmountsDialogFiltersActionType,
    IEnterAmountsDialogComboboxPropsBase,
    IEnterAmountsDialogFilterEntityChangeAction,
} from '../../reducers/enterAmountsDialogFiltersReducer';
import { comboBoxCheckIconStyles } from './EnterAmountsDialogFilters';

export const findEntityDtoByCode = (entities: IEntity[] | null | undefined, entityCode: string | undefined): IEntity | undefined => {
    return entities?.find((entity: IEntity) => entity.code === entityCode);
};

export const findEntitiesByDisplayValue = (displayValue: string, entities: IEntity[]): IEntity[] => {
    return entities.filter((entity: IEntity) => `${entity.code} - ${entity.name}`.toLowerCase().includes(displayValue.toLowerCase()));
};

const OptionGroupLabel: FC = () => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '24px',
                overflow: 'hidden',
            }}
        >
            <div>{t('entityCode')}</div>
            <div>{t('entityName')}</div>
        </div>
    );
};

interface IEntityComboboxOptionProps {
    entity: IEntity;
    optionToSelectedOption: (entity: IEntity) => string;
    optionToInputValue: (entity: IEntity) => string;
}

export interface IEnterAmountsDialogEntityComboboxProps
    extends IEnterAmountsDialogComboboxPropsBase<IEntity, IEnterAmountsDialogFilterEntityChangeAction> {}

const EntityComboboxOption: FC<IEntityComboboxOptionProps> = (props) => {
    const hiddenCheckBoxCssClass = comboBoxCheckIconStyles().hidden;
    const { entity, optionToSelectedOption, optionToInputValue } = props;
    return (
        <Option
            className={hiddenCheckBoxCssClass}
            key={optionToSelectedOption(entity)}
            text={optionToInputValue(entity)}
            value={optionToSelectedOption(entity)}
            aria-label={optionToInputValue(entity)}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '24px',
                    overflow: 'hidden',
                }}
            >
                <Tooltip content={entity!.code!} relationship='inaccessible' appearance='inverted' withArrow={true}>
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: '0 0 60px',
                        }}
                    >
                        {entity.code}
                    </div>
                </Tooltip>
                <Tooltip content={entity!.name!} relationship='inaccessible' appearance='inverted' withArrow={true}>
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: '3 0 0',
                        }}
                    >
                        {entity.name}
                    </div>
                </Tooltip>
            </div>
        </Option>
    );
};

export const EntityCombobox: FC<IEnterAmountsDialogEntityComboboxProps> = (props: IEnterAmountsDialogEntityComboboxProps) => {
    const { t } = useTranslation();
    const optionToInputValue: (option: IEntity) => string = (option: IEntity) => `${option.code} - ${option.name}`;
    const optionToSelectedOption: (option: IEntity) => string = (option: IEntity) => option.code!;
    const findOptionsByDisplayValue: (displayValue: string, options: IEntity[]) => IEntity[] = (displayValue: string, options: IEntity[]) =>
        findEntitiesByDisplayValue(displayValue, options);
    const findOptionByKey: (key: string | undefined, options: IEntity[]) => IEntity | undefined = (
        key: string | undefined,
        options: IEntity[]
    ) => findEntityDtoByCode(options, key);
    const entityLabelId: string = useId('entity');
    const { comboboxStyles } = useEnterAmountsDialogStyles();
    const optionRenderer: (entity: IEntity) => JSX.Element = useCallback(
        (entity: IEntity) => (
            <EntityComboboxOption
                key={optionToSelectedOption(entity)}
                entity={entity}
                optionToSelectedOption={optionToSelectedOption}
                optionToInputValue={optionToInputValue}
            />
        ),
        [optionToSelectedOption, optionToInputValue]
    );
    const { inputValue, selectedOptions, matchingOptions, searchText, onOptionSelect, onBlur, onChange, disabled, onKeyDown, comboboxRef } =
        useEnterAmountsDialogComboboxData<IEntity, IEnterAmountsDialogFilterEntityChangeAction, IEnterAmountsDialogEntityComboboxProps>(
            props,
            {
                optionToInputValue,
                optionToSelectedOption,
                findOptionsByDisplayValue: findOptionsByDisplayValue,
                findOptionByKey,
                optionRenderer: optionRenderer,
            },
            EnterAmountsDialogFiltersActionType.EntityChanged
        );
    const optionGroupLabel: JSX.Element = useMemo(() => <OptionGroupLabel />, []);
    return (
        <div>
            <Label id={entityLabelId} weight='semibold'>
                {t('entity')}
            </Label>
            <Combobox
                className={comboboxStyles}
                aria-labelledby={entityLabelId}
                selectedOptions={selectedOptions}
                value={inputValue}
                onOptionSelect={onOptionSelect}
                disabled={disabled}
                freeform
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                ref={comboboxRef}
                clearable={props.clearable}
            >
                {searchText ? (
                    <Option aria-disabled='true' key='freeform' text={searchText} disabled={true}>
                        {t('noOptionsFound', { searchText: searchText })}
                    </Option>
                ) : (
                    <OptionGroup key='optionGroup' label={optionGroupLabel}>
                        {matchingOptions}
                    </OptionGroup>
                )}
            </Combobox>
        </div>
    );
};
