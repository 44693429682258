import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import {
    concatStyleSets,
    DirectionalHint,
    IButtonStyles,
    IconButton,
    IFocusZoneProps,
    IIconProps,
    IIconStyles,
    INav,
    INavButtonProps,
    INavLink,
    INavLinkGroup,
    INavStyles,
    IProgressIndicatorStyles,
    IRenderFunction,
    IRenderGroupHeaderProps,
    IStyle,
    ITooltipHostProps,
    Link,
    mergeStyles,
    Nav,
    ProgressIndicator,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router';
import { ITaxReturnTab } from '../../contexts/userSettingsContext';
import { useUserSettings } from '../../contexts/useUserSettings';
import { useTaxReturnCalculationState } from '../../hooks/useTaxReturnCalculationState';
import { CalculationExecutionStatusCode } from '../../model';
import { IAppTheme } from '../../theme/IAppTheme';
import { getTooltipHostProps } from '../../utils/CustomStyles';
import { IconName } from '../../utils/Icons.constants';
import { getRecentlyOpenedTabsWithStatus } from '../../utils/ReportUtils';
import { SIDEMENU_CLOSED_SIZE, SIDEMENU_SIZE } from '../../utils/Style.constants';
import { VIEW_REPORT_URL } from '../../utils/Urls.constant';
import CustomIconButton from '../common/IconButton';

const clearLinkButtonKey = 'clear';
const viewAllNavItemKey: string = 'viewAll';
// without this, long report names will not be truncated
const linkTextMaxWidthInPixels: number = 124;
const baseLinkStyles: IButtonStyles = {
    root: { width: '100%', padding: '8px 10px' },
    label: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whitespace: 'nowrap',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '-0.13px',
        whiteSpace: 'nowrap',
        maxWidth: `${linkTextMaxWidthInPixels}px`,
        margin: 0,
    },
};
const baseLinkIconStyles: IIconStyles = {
    root: {
        fontSize: '20px',
        paddingRight: '10px',
        margin: 0,
    },
};
const NavLinkWithLine: React.FC<INavButtonProps> = (props: INavButtonProps) => {
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const clearLinkStyles = mergeStyles({
        textAlign: 'right',
        width: '100%',
        fontSize: '12px',
        marginBottom: 15,
        selectors: {
            '&::before': {
                content: '""',
                display: 'block',
                width: '75%',
                height: '1px',
                background: customPalette.neutralTertiaryAlt,
                left: '0px',
                top: '50%',
                position: 'absolute',
            },
        },
    });
    return (
        <Stack.Item key={props.link?.key} className={clearLinkStyles}>
            <Link onClick={props.link?.onClick} aria-label={props.link?.ariaLabel} role='button'>
                <span>{props.link?.name}</span>
            </Link>
        </Stack.Item>
    );
};

const ViewAllReportsNavButton: React.FC<INavButtonProps> = (props: INavButtonProps) => {
    const { customPalette, palette } = useCorptaxTheme<IAppTheme>();
    const viewAllReturnsLinkStyles: IButtonStyles = concatStyleSets(baseLinkStyles, {
        root: {
            border: '2px solid',
            borderColor: customPalette.darkGreen,
            background: customPalette.lightGreen,
            borderRadius: '6px',
            padding: '9px 13px',
        },
        rootHovered: {
            color: customPalette.darkGreen,
            borderColor: palette.neutralQuaternaryAlt,
            background: customPalette.lightGreen,
        },
        label: {
            color: customPalette.darkGreen,
            textAlign: 'left',
        },
        labelHovered: {
            color: customPalette.darkGreen,
        },
        flexContainer: {
            justifyContent: 'space-between',
            gap: '2px',
            width: '100%',
        },
    });
    return (
        <Stack.Item key={props.link?.key}>
            <CustomIconButton
                url={props.link?.url}
                iconName={(props.link?.icon as IconName) ?? ''}
                iconProps={props.link?.iconProps}
                ariaLabel={props.link?.ariaLabel}
                onButtonClick={props.onClick}
                label={props.link?.name}
                styles={viewAllReturnsLinkStyles}
                role='link'
            />
        </Stack.Item>
    );
};

export const RecentlyViewedReportNavItem: React.FC<INavButtonProps> = (props: INavButtonProps) => {
    const { t } = useTranslation();
    const { removeReturn } = useUserSettings();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const handleRemoveReturnClick = (ev: any, clickHandler?: any, link?: INavLink) => {
        ev?.stopPropagation();
        ev?.preventDefault();
        const tagName = ev.target.tagName.toLowerCase();
        if ((tagName === 'svg' || tagName === 'button') && link?.key !== undefined) {
            removeReturn(link.key);
            return;
        }
        clickHandler(ev);
    };
    const tooltipHostProps: Partial<ITooltipHostProps> = getTooltipHostProps(DirectionalHint.rightCenter, undefined, customPalette);
    tooltipHostProps.content = props.link?.name;
    tooltipHostProps.id = `${props.link?.name} tooltip`;

    const defaultProgressIndicatorStyles: IProgressIndicatorStyles = {
        root: {
            marginBottom: '4px',
            marginLeft: '4px',
        },
        itemDescription: { fontSize: '10px' },
        itemProgress: {
            padding: '0px',
        },
    } as IProgressIndicatorStyles;
    const progressIndicatorCompleteStyles: IProgressIndicatorStyles = concatStyleSets(defaultProgressIndicatorStyles, {
        progressBar: { backgroundColor: customPalette.darkGreen },
    });

    const buttonStyles: IButtonStyles = concatStyleSets(baseLinkStyles, {
        root: {
            minWidth: '144px',
            padding: '9px',
            backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1,
        },
        textContainer: {
            overflow: 'hidden',
        },
        flexContainer: {
            width: '100%',
        },
        label: { paddingTop: '0', overflow: 'hidden', whitespace: 'nowrap', textOverflow: 'ellipsis', minWidth: '77px', display: 'block' },
    });

    return (
        <Stack.Item key={props.link?.key}>
            <TooltipHost {...tooltipHostProps}>
                <CustomIconButton
                    url={props.link?.url}
                    ariaLabel={props.link?.ariaLabel}
                    onButtonClick={(e) => handleRemoveReturnClick(e, props.onClick, props.link)}
                    styles={buttonStyles}
                    role='link'
                    label={props.link?.name}
                >
                    <IconButton
                        id={props.link?.key}
                        aria-label={`Close link recently viewed return: ${props.link?.name}`}
                        data-testid={t('removeReturnButtonLabel').toString()}
                        iconProps={{
                            iconName: 'Dismiss20Regular',
                        }}
                        styles={{
                            root: {
                                minWidth: 20,
                                padding: 0,
                                margin: 0,
                                border: '0px',
                                borderRadius: '4px',
                                height: '16px',
                                width: '16px',
                                backgroundColor: 'transparent',
                            },
                            flexContainer: {
                                width: '100%',
                            },
                            rootHovered: { backgroundColor: customPalette.greyD3D2D1 },
                            icon: {
                                '> svg': {
                                    height: '14px',
                                    width: '14px',
                                    margin: 0,
                                },
                                height: '14px',
                                width: '14px',
                                margin: 0,
                            },
                        }}
                    />
                </CustomIconButton>
                {props.link?.statusCode === CalculationExecutionStatusCode.Executing ? (
                    <Stack>
                        <ProgressIndicator
                            styles={defaultProgressIndicatorStyles}
                            description={t('calculating')}
                            ariaLabel={t('calculating').toString()}
                        />
                    </Stack>
                ) : props.link?.statusCode === CalculationExecutionStatusCode.Completed ? (
                    <Stack>
                        <ProgressIndicator
                            styles={progressIndicatorCompleteStyles}
                            percentComplete={100}
                            description={t('complete')}
                            ariaLabel={t('complete').toString()}
                        />
                    </Stack>
                ) : (
                    <Stack style={{ marginBottom: '0.8px' }}></Stack>
                )}
            </TooltipHost>
        </Stack.Item>
    );
};

const NavItemIconButton: React.FC<INavButtonProps> = (props: INavButtonProps) => {
    const renderClearAll = props.link?.key === clearLinkButtonKey;
    const renderViewAllReports: boolean = props.link?.key === viewAllNavItemKey;

    if (renderViewAllReports) {
        return <ViewAllReportsNavButton {...props} />;
    }

    if (renderClearAll) {
        return <NavLinkWithLine {...props} />;
    }

    return <RecentlyViewedReportNavItem {...props} />;
};

const ReportListPanel: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const navigate: NavigateFunction = useNavigate();
    const { mostRecentlyOpenedTabs, clearReturnTabs } = useUserSettings();
    const { taxReturnCalculationStatusList, clearAllCompletedReports } = useTaxReturnCalculationState();
    const { t } = useTranslation();
    const { brandPalette, customPalette } = useCorptaxTheme<IAppTheme>();
    const navRef = useRef<INav>(null);

    const panelWidthToggleStyleOverrides: IButtonStyles = {
        root: {
            width: 36,
            padding: 0,
            height: '36px',
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: '6px',
        },
        rootHovered: {
            backgroundColor: brandPalette.coolLightGray,
            color: brandPalette.hyperlinkBlue,
            borderRadius: '6px',
        },
        rootPressed: {
            backgroundColor: customPalette.greyD1D3D4,
            color: brandPalette.hyperlinkBlue,
        },
        icon: {
            color: customPalette.neutralSecondary,
            margin: 0,
            height: '1.5rem',
            width: '1.5rem',
        },
        iconHovered: {
            color: brandPalette.hyperlinkBlue,
        },
    };

    const mostRecentlyOpenedTabsWithStatus: ITaxReturnTab[] = getRecentlyOpenedTabsWithStatus(
        taxReturnCalculationStatusList ?? [],
        mostRecentlyOpenedTabs
    );
    const hasMostRecentlyOpenedReturns: boolean = mostRecentlyOpenedTabsWithStatus ? mostRecentlyOpenedTabsWithStatus.length > 0 : false;

    const handleLinkClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => void = (
        ev?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) => {
        if (ev) {
            ev.preventDefault();
        }
        if (!item) {
            return;
        }
        if (item.url !== window.location.pathname || item.url === '/') {
            navigate(item.url);
        }
    };
    const handleClearReturnsClick = () => {
        clearReturnTabs();
        clearAllCompletedReports();
    };
    const renderHeader: IRenderFunction<IRenderGroupHeaderProps> = (
        props?: IRenderGroupHeaderProps,
        defaultRender?: (props?: IRenderGroupHeaderProps) => JSX.Element | null
    ) => {
        const navHeaderWrapperStyles = mergeStyles({
            borderTopWidth: props?.isExpanded ? '0.75px' : undefined,
            borderTopColor: props?.isExpanded ? customPalette.neutralTertiaryAlt : undefined,
            borderTopStyle: props?.isExpanded ? 'solid' : undefined,
            width: '100%',
            alignSelf: 'end',
            maxHeight: 'inherit',
            height: props?.isExpanded ? '48px' : '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyItems: 'space-between',
            alignItems: isExpanded ? 'flex-end' : 'center',
        });
        const togglePanelWidthButtonIconName: IconName = props?.isExpanded ? 'PanelLeftContract28Regular' : 'PanelLeftExpand28Regular';
        return (
            <Stack.Item className={navHeaderWrapperStyles} id='reportListPanelToggleWidthButtonWrapper'>
                <CustomIconButton
                    styles={panelWidthToggleStyleOverrides}
                    ariaLabel={props?.name}
                    iconName={togglePanelWidthButtonIconName}
                    onButtonClick={() => setIsExpanded(!props?.isExpanded)}
                    ariaExpanded={props?.isExpanded}
                />
            </Stack.Item>
        );
    };

    const viewAllReturnsIconProps: IIconProps = {
        styles: concatStyleSets(baseLinkIconStyles, {
            root: {
                paddingLeft: 10,
                fontSize: 16,
                color: customPalette.darkGreen,
            },
        }),
    } as IIconProps;

    const navGroupStyles = {
        group: {
            display: 'flex',
            flexDirection: 'column-reverse',
            height: '100%',
            justifyContent: 'space-between',
            width: '100%',
            selectors: {
                '.ms-Nav-groupContent': {
                    overflow: 'auto',
                },
                div: {
                    marginBottom: '0px',
                },
            },
        } as IStyle,
        root: {
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            display: 'flex',
            height: '100%',
            width: isExpanded ? SIDEMENU_SIZE : SIDEMENU_CLOSED_SIZE,
            maxWidth: '202px',
            padding: isExpanded ? '32px 10px 16px 10px' : '0',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'var(--background-tabs, #F4F5FD)',
            boxShadow:
                '3px 3.200000047683716px 7.199999809265137px 0px rgba(0, 0, 0, 0.02), 1px 0.6000000238418579px 0px 0px rgba(0, 0, 0, 0.02)',
            transition: 'all 500ms cubic-bezier(0, 0.55, 0.45, 1)',
        } as IStyle,
    } as INavStyles;

    const mapTaxReturnTabToNavLink: (tab: ITaxReturnTab) => INavLink = (tab: ITaxReturnTab) => {
        return {
            key: tab.returnKey.toString(),
            name: tab.returnName,
            url: VIEW_REPORT_URL + tab.returnKey,
            ariaLabel: `recently viewed return: ${tab.returnName}`,
            icon: 'DocumentTextExtract20Regular',
            iconProps: {
                className: mergeStyles({ paddingRight: '0px', fontSize: '16px' }),
            } as IIconProps,
            statusCode: tab.statusCode,
        } as INavLink;
    };

    const navGroups = [
        {
            name: t('recentlyReviewedReportsPanelToggle')?.toString(),
            links: [
                {
                    name: t('clearMostRecentReturnsButtonText').toString(),
                    key: clearLinkButtonKey,
                    onClick: handleClearReturnsClick,
                    ariaLabel: t('clearMostRecentReturnsButtonLabel').toString(),
                },
                {
                    key: viewAllNavItemKey,
                    name: t('taxReturns').toString(),
                    url: '/',
                    icon: 'ReceiptSparkles16Regular',
                    iconProps: viewAllReturnsIconProps,
                } as INavLink,
            ].concat(mostRecentlyOpenedTabsWithStatus?.map(mapTaxReturnTabToNavLink) ?? []),
            isExpanded: isExpanded,
        } as INavLinkGroup,
    ];

    return (
        <>
            {hasMostRecentlyOpenedReturns && (
                <Nav
                    componentRef={navRef}
                    ariaLabel={t('recentlyReviewedReports').toString()}
                    groups={navGroups}
                    onRenderGroupHeader={renderHeader}
                    onLinkClick={handleLinkClick}
                    linkAs={NavItemIconButton}
                    styles={navGroupStyles}
                    // focus zones are rendered as <div>s, and unless we force the height of that <div> to be 100%,
                    // the expand/collapse button will not go to the bottom of the screen
                    focusZoneProps={
                        {
                            className: mergeStyles({ height: '100%' }),
                            isCircularNavigation: true,
                        } as IFocusZoneProps
                    }
                />
            )}
        </>
    );
};

export default ReportListPanel;
