import { Dispatch, useCallback, useEffect, useReducer } from 'react';
import { useGetAmountsEntries, useGetAmountsEntryOptions, useSaveAmounts } from '../api/report/report';
import { IEnterAmountsDialogProps } from '../components/enter-amounts-dialog/EnterAmountsDialog';
import { IEnterAmountsDialogFiltersFilterSelections } from '../components/enter-amounts-dialog/EnterAmountsDialogFilters';
import {
    AmountDataEntryOptions,
    GetAmountsEntriesParams,
    IAccountAmountRow,
    IAdjustment,
    ICalendarPeriod,
    ICase,
    IEntity,
    IJurisdiction,
    IWhitePaperReportChanges,
    SaveAmountsRequest,
    SaveAmountsResponse,
} from '../model';
import {
    EnterAmountsDialogReducer,
    EnterAmountsDialogReducerAction,
    enterAmountsDialogStateReducer,
    EnterAmountsModalActionActionType,
    IEnterAmountsDialogState,
} from '../reducers/enterAmountsDialogReducer';

export interface IUseEnterAmountsDataHook {
    accountAmountRows: IAccountAmountRow[] | undefined;
    cases: ICase[] | undefined;
    selectedCase: ICase | undefined;
    entities: IEntity[] | undefined;
    selectedEntity: IEntity | undefined;
    jurisdictions: IJurisdiction[] | undefined;
    selectedJurisdiction: IJurisdiction | undefined;
    years: ICalendarPeriod[] | undefined;
    selectedYear: ICalendarPeriod | undefined;
    adjustments: IAdjustment[] | undefined;
    selectedAdjustment: IAdjustment | undefined;
    saveAmounts: (
        request: { data: SaveAmountsRequest },
        options: {
            onSuccess: (
                data: SaveAmountsResponse | undefined,
                variables: { data: SaveAmountsRequest },
                context: unknown | undefined
            ) => void;
        }
    ) => void;
    updateDialogState: Dispatch<EnterAmountsDialogReducerAction>;
    isLoading: boolean;
    saveButtonIsDisabled: boolean;
    changeTrackingData: IWhitePaperReportChanges;
    onFilterUpdate: (filterSelections: IEnterAmountsDialogFiltersFilterSelections) => void;
    onFilterReset: () => void;
    amountsOnly: boolean;
    combineDebitCreditColumns: boolean;
}
const findIEntityByCode = (entities: IEntity[] | null | undefined, entityCode: string | undefined): IEntity | undefined => {
    return entities?.find((entity: IEntity) => entity.code === entityCode);
};
const findICaseByCode = (cases: ICase[] | null | undefined, caseCode: string | undefined): ICase | undefined => {
    return cases?.find((ICase: ICase) => ICase.code === caseCode);
};
const findIJurisdictionByCode = (
    jurisdictions: IJurisdiction[] | null | undefined,
    jurisdictionCode: string | undefined
): IJurisdiction | undefined => {
    return jurisdictions?.find((jurisdictionDto: IJurisdiction) => jurisdictionDto.code === jurisdictionCode);
};
const findICalendarPeriodByCode = (
    years: ICalendarPeriod[] | null | undefined,
    periodCode: string | undefined
): ICalendarPeriod | undefined => {
    return years?.find((calendarPeriodDto: ICalendarPeriod) => calendarPeriodDto.periodYear?.toString() === periodCode);
};
const findIAdjustmentByCode = (
    adjustments: IAdjustment[] | null | undefined,
    adjustmentCode: string | undefined
): IAdjustment | undefined => {
    return adjustments?.find((adjustmentDto: IAdjustment) => adjustmentDto.adjustmentCode === adjustmentCode);
};

export const useEnterAmountsData: (props: IEnterAmountsDialogProps) => IUseEnterAmountsDataHook = (props: IEnterAmountsDialogProps) => {
    const { data: amountsDataEntryOptions, isFetching: isAmountsDataEntryOptionsFetching } =
        useGetAmountsEntryOptions<AmountDataEntryOptions>({
            ColumnId: props.columnId,
            RowNumber: props.definingRowNumber ?? props.drillDownRowNumber,
            TaxReturnKey: props.taxReturnKey,
        });
    const { cases, entities, jurisdictions, years, adjustments } = { ...amountsDataEntryOptions };

    const initialState: IEnterAmountsDialogState = {
        jurisdiction: findIJurisdictionByCode(jurisdictions, props.jurisdiction),
        period: findICalendarPeriodByCode(years, props.period),
        entity: findIEntityByCode(entities, props.entity),
        adjustment: findIAdjustmentByCode(adjustments, props.adjustmentCode),
        case: findICaseByCode(cases, props.case),
        location: props.location,
        show: false,
        rowNumber: props.drillDownRowNumber,
        columnId: props.columnId,
        changeTrackingData: props.changeTrackingData,
        taxReturnKey: props.taxReturnKey,
        saveButtonIsDisabled: true,
        rowDescription: props.rowDescription,
        amountsOnly: false,
        combineDebitCreditColumns: false,
    };
    const [dialogState, updateDialogState] = useReducer<EnterAmountsDialogReducer>(enterAmountsDialogStateReducer, initialState);
    const amountEntriesRequest: GetAmountsEntriesParams = {
        CaseCode: dialogState.case ? dialogState.case.code! : props.case,
        //location: props.location,
        EntityCode: dialogState.entity ? dialogState.entity.code! : props.entity,
        Period: dialogState.period ? dialogState.period.periodYear!.toString() : props.period,
        AdjustmentCode: dialogState.adjustment ? dialogState.adjustment.adjustmentCode! : props.adjustmentCode,
        Jurisdiction: dialogState.jurisdiction ? dialogState.jurisdiction.code! : props.jurisdiction,
        // TODO: add account codes to this request, get the list of account codes from glenn's API (getAmountsEntryOptions)
        // or maybe this can just be done in BFF?
    };

    const onFilterUpdate = useCallback(
        (filterSelections: IEnterAmountsDialogFiltersFilterSelections): void => {
            updateDialogState({ type: EnterAmountsModalActionActionType.FiltersUpdated, filterSelections });
        },
        [updateDialogState]
    );

    const onFilterReset = useCallback(
        () =>
            onFilterUpdate({
                adjustment: findIAdjustmentByCode(adjustments, props.adjustmentCode),
                amountsOnly: false,
                calendarPeriod: findICalendarPeriodByCode(years, props.period),
                case: findICaseByCode(cases, props.case),
                combineDebitCreditColumns: false,
                entity: findIEntityByCode(entities, props.entity),
                jurisdiction: findIJurisdictionByCode(jurisdictions, props.jurisdiction),
            }),
        [onFilterUpdate]
    );

    useEffect(() => {
        if (!dialogState.adjustment && !dialogState.case && !dialogState.entity && !dialogState.jurisdiction && !dialogState.period) {
            onFilterUpdate({
                jurisdiction: findIJurisdictionByCode(jurisdictions, props.jurisdiction),
                calendarPeriod: findICalendarPeriodByCode(years, props.period),
                entity: findIEntityByCode(entities, props.entity),
                adjustment: findIAdjustmentByCode(adjustments, props.adjustmentCode),
                case: findICaseByCode(cases, props.case),
                amountsOnly: dialogState.amountsOnly,
                combineDebitCreditColumns: dialogState.combineDebitCreditColumns,
            });
        }
    }, [jurisdictions, years, entities, adjustments, cases, updateDialogState]);

    const { data: amountsEntriesResponse, isFetching: isAmountsEntriesFetching } = useGetAmountsEntries(
        amountEntriesRequest /*, queryOptions*/
    );
    const { mutate: saveAmounts, isLoading: isSaveAmountsLoading } = useSaveAmounts();
    return {
        accountAmountRows: amountsEntriesResponse?.accounts,
        cases,
        selectedCase: dialogState.case ?? findICaseByCode(cases, props.case),
        entities,
        selectedEntity: dialogState.entity ?? findIEntityByCode(entities, props.entity),
        jurisdictions,
        selectedJurisdiction: dialogState.jurisdiction ?? findIJurisdictionByCode(jurisdictions, props.jurisdiction),
        years,
        selectedYear: dialogState.period ?? findICalendarPeriodByCode(years, props.period),
        adjustments,
        selectedAdjustment: dialogState.adjustment ?? findIAdjustmentByCode(adjustments, props.adjustmentCode),
        saveAmounts,
        updateDialogState,
        isLoading: isAmountsDataEntryOptionsFetching || isAmountsEntriesFetching || isSaveAmountsLoading,
        saveButtonIsDisabled: dialogState.saveButtonIsDisabled || isSaveAmountsLoading,
        changeTrackingData: dialogState.changeTrackingData,
        onFilterUpdate,
        onFilterReset,
        amountsOnly: dialogState.amountsOnly,
        combineDebitCreditColumns: dialogState.combineDebitCreditColumns,
    } as IUseEnterAmountsDataHook;
};
