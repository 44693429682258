import { useCorptaxTheme } from '@corptax/react-components-common';
import {
    Checkbox,
    ContextualMenuItemType,
    IContextualMenuItem,
    IContextualMenuItemProps,
    IContextualMenuItemStyles,
    IContextualMenuProps,
    IIconProps,
    mergeStyles,
} from '@fluentui/react';
import { useMemo } from 'react';
import { IFilterItem } from '../data-types/filterItem';
import { IAppTheme } from '../theme/IAppTheme';
import { clickHandlerWrapperUtil, isCheckedUtil } from '../utils/ContextualMenuUtils';
import { IFluentContextMenuItemOptions } from './IFluentContextMenuItemOptions';

const getKeyForItems = (contextualMenuItems: IContextualMenuItem[]) => {
    return contextualMenuItems
        .map((i) => {
            return `${i.key} - checked: ${i.isChecked}`;
        })

        .join(',');
};

export const useContextualMenuItems = (
    filterMenuItems: IFilterItem[],
    filterName: string,
    iconProps: IIconProps | null,
    itemWrapperClassName: string,
    tempSelectedItems: IFilterItem[] | undefined,
    setTempSelectedItems: React.Dispatch<React.SetStateAction<IFilterItem[] | undefined>>,
    onMenuItemClick: (filteredItems: IFilterItem[], filterName: string) => void,
    selectedFilterItems?: IFilterItem[] | null,
    isMultiSelect?: boolean
): IFluentContextMenuItemOptions => {
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();

    const itemProps = {
        styles: {
            root: {
                paddingLeft: 24,
            },
            label: {
                color: customPalette.blueDark,
                fontSize: '13px',
                fontWeight: '600',
            },
        } as IContextualMenuItemStyles,
    } as IContextualMenuItemProps;

    const getItemIsCheckedStyles = () =>
        mergeStyles({
            '@media (forced-colors!: active)': {
                span: {
                    color: `${customPalette.themePrimary} !important`,
                },
            },
            '@media (forced-colors: active)': {
                span: {
                    border: '1px solid',
                    color: 'none',
                },
            },
        });

    const getCheckboxStyles = () => {
        return {
            root: mergeStyles({
                minWidth: '494px',
                margin: '10px auto 10px 24px',
                selectors: {
                    '&:hover .ms-Checkbox-text': {
                        color: customPalette.blueDark,
                    },
                    '&:hover .ms-Checkbox-checkbox': {
                        borderColor: customPalette.neutralSecondaryAlt,
                    },
                    '&:hover .ms-Checkbox-checkmark': {
                        backgroundColor: palette.white,
                        color: palette.white,
                    },
                    '': {
                        whiteSpace: 'nowrap',
                    },
                },
            }),

            checkbox: mergeStyles({
                boxSizing: 'border-box',
                left: '0%',
                right: '0%',
                top: '0%',
                bottom: '0%',
                width: '20px',
                height: '20px',
                borderRadius: '6px',
                border: `1px solid ${customPalette.neutralSecondaryAlt}`,
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.2)',
            }),
            text: mergeStyles({
                color: customPalette.blueDark,
                fontWeight: '600',
                fontSize: '13px',
            }),
        };
    };

    const getCheckedboxStyles = () => {
        return {
            root: mergeStyles({
                margin: '10px auto 10px 24px',
                selectors: {
                    '.ms-Checkbox-checkmark': {
                        color: palette.white,
                    },
                    '&:hover .ms-Checkbox-text': {
                        color: customPalette.themePrimary,
                    },
                    '&:hover .ms-Checkbox-checkbox': {
                        borderColor: customPalette.themePrimary,
                        background: customPalette.themePrimary,
                    },
                    '&:hover .ms-Checkbox-checkmark': {
                        backgroundColor: customPalette.themePrimary,
                        color: palette.white,
                    },
                    '': {
                        whiteSpace: 'nowrap',
                    },
                },
            }),
            checkbox: mergeStyles({
                boxSizing: 'border-box',
                left: '0%',
                right: '0%',
                top: '0%',
                bottom: '0%',
                width: '20px',
                height: '20px',
                borderRadius: '6px',
                backgroundColor: customPalette.themePrimary,
                border: `1px solid ${customPalette.neutralSecondaryAlt}`,
            }),
            text: mergeStyles({
                color: customPalette.themePrimary,
                fontWeight: '600',
                fontSize: '13px',
            }),
        };
    };

    const mapFilterItemToContextualMenuItem = (
        item: IFilterItem,
        iconProps: IIconProps | null,
        itemWrapperClassName: string,
        handleContextualItemClick: (
            ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
            item?: IContextualMenuItem
        ) => boolean | void,
        itemIndex: number,
        isChecked: boolean,
        isMultiSelect?: boolean
    ): IContextualMenuItem => {
        let label = item.label ? item.label.trim() : '';
        label = label === '' ? '(Blanks)' : label;
        return {
            role: 'menuitemradio',
            itemType: ContextualMenuItemType.Normal,
            key: item.key,
            iconProps: iconProps,
            onClick: handleContextualItemClick,
            text: label,
            data: { filterItem: item },
            itemProps: itemProps,
            className: isChecked ? mergeStyles(getItemIsCheckedStyles(), itemWrapperClassName) : itemWrapperClassName,
            canCheck: isMultiSelect ?? false,
            isChecked: isChecked,
            id: `item-${itemIndex}`,
            onRender: isMultiSelect
                ? (item) => {
                      return (
                          <Checkbox
                              id={`item-${itemIndex}`}
                              key={item.key}
                              styles={isChecked ? getCheckedboxStyles() : getCheckboxStyles()}
                              label={label}
                              checked={isChecked}
                              onChange={(ev) => handleContextualItemClick(undefined, item)}
                          />
                      );
                  }
                : undefined,
        } as IContextualMenuItem;
    };

    const clickHandlerWrapper = (
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        contextualMenuItem?: IContextualMenuItem
    ): void => {
        clickHandlerWrapperUtil(
            tempSelectedItems,
            setTempSelectedItems,
            onMenuItemClick,
            filterName,
            ev,
            contextualMenuItem,
            isMultiSelect
        );
    };

    const isChecked = (filterMenuItem: { key: string }): boolean => {
        return isCheckedUtil(filterMenuItem, isMultiSelect, tempSelectedItems, selectedFilterItems);
    };

    const contextualMenuItems: IContextualMenuItem[] = useMemo(
        () =>
            filterMenuItems?.map((filterMenuItem, index) => {
                return mapFilterItemToContextualMenuItem(
                    filterMenuItem,
                    iconProps,
                    itemWrapperClassName,
                    clickHandlerWrapper,
                    index,
                    isChecked(filterMenuItem) ?? false,
                    isMultiSelect
                );
            }),
        [selectedFilterItems, tempSelectedItems, filterMenuItems]
    );

    const menuKey = getKeyForItems(contextualMenuItems);
    return { contextualMenuItems, menuKey } as IFluentContextMenuItemOptions;
};

export interface IFluentContextMenuOptions {
    contextualMenuObject: IContextualMenuProps;
}
