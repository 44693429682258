import { useCorptaxTheme } from '@corptax/react-components-common';
import { IDialogContentStyles, IDialogFooterStyles, IModalStyles, IRawStyle } from '@fluentui/react';
import { useMemo } from 'react';
import { IAppTheme } from '../theme/IAppTheme';
import { mergeStylesOnDemand } from '../utils/StyleUtils';

export interface IAdjustmentAccountAndAmountAssociationDialogStyles {
    modalStyles: Partial<IModalStyles>;
    dialogContentStyles: Partial<IDialogContentStyles>;
    dialogFooterStyles: Partial<IDialogFooterStyles>;
    gridComponentCSSClass: string;
    spinnerClassName: string;
    searchBarStyleOverrides: IRawStyle;
    totalRowStyle: IRawStyle;
}

export const useAdjustmentAccountAndAmountAssociationDialogStyles: () => IAdjustmentAccountAndAmountAssociationDialogStyles = () => {
    const { brandPalette, customPalette } = useCorptaxTheme<IAppTheme>();
    const modalStyles: Partial<IModalStyles> = useMemo(() => {
        return {
            main: {
                borderRadius: '8px',
                boxShadow: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
                height: '475px !important',
                maxHeight: '475px !important',
                maxWidth: '579px !important',
                minHeight: '475px !important',
                minWidth: '579px !important',
                width: '579px !important',
            },
            scrollableContent: {
                height: 'inherit',
                maxHeight: 'inherit',
                overflow: 'hidden',
            },
        };
    }, []);

    const dialogContentStyles: Partial<IDialogContentStyles> = useMemo(() => {
        return {
            content: {
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit',
                maxHeight: 'inherit',
                overflow: 'inherit',
            },
            header: {
                border: `1px solid ${brandPalette.coolLightGrayTint1}`,
                display: 'block',
            },
            inner: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'inherit',
                padding: '',
            },
            innerContent: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'inherit',
                padding: '0px calc(16px - 11px) 24px 16px',
                width: '',
            },
        };
    }, [brandPalette.coolLightGrayTint1]);

    const dialogFooterStyles: Partial<IDialogFooterStyles> = useMemo(() => {
        return {
            actions: {
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                lineHeight: '',
                margin: '',
                minHeight: '',
                overflow: 'visible',
            },
            actionsRight: {
                overflow: 'inherit',
            },
            action: {
                overflow: 'inherit',
            },
        } as IDialogFooterStyles;
    }, []);

    const getSpinnerClassName: () => string = mergeStylesOnDemand({
        displayName: 'spinner',
        boxSizing: 'content-box',
        height: '100px',
        margin: '95px 0 0',
        padding: '0 0 5px',
    });
    const searchBarStyleOverrides: IRawStyle = useMemo(() => {
        return {
            width: '100%',
            marginBottom: '5px',
            height: '33px',
        };
    }, []);
    const amountsGridHeaderStyle: IRawStyle = useMemo(() => {
        return {
            '.e-gridheader': {
                '.e-headercontent': {
                    '.e-table': {
                        '.e-columnheader': {
                            '.e-headercell': {
                                '.e-headercelldiv': {
                                    '.e-headertext': {
                                        fontSize: '10px',
                                        fontWeight: 700,
                                        textTransform: 'uppercase',
                                        verticalAlign: 'bottom',
                                    },
                                },
                                backgroundColor: customPalette.themeLighter,
                                border: 'none',
                            },
                            alignItems: 'stretch',
                        },
                    },
                    borderColor: `1px solid ${customPalette.statusCoolLightGrey}`,
                    maxHeight: '36px',
                    overflow: 'hidden !important',
                    height: '36px',
                },
                border: '0 !important',
                borderLeft: `1px solid ${customPalette.statusCoolLightGrey}`,
                borderRight: `1px solid ${customPalette.statusCoolLightGrey}`,
                minHeight: '36px',
                overflow: 'hidden auto !important',
                scrollbarGutter: 'stable',
            },
        };
    }, [customPalette.themeLighter, customPalette.statusCoolLightGrey]);
    const amountsGridCellStyle: IRawStyle = useMemo(() => {
        return {
            '&.e-grid': {
                // override existing theme styles to add more padding to all cells
                '.e-rowcell, .e-headercell': {
                    fontSize: 10,
                    padding: '10px 16px',
                    lineHeight: 'normal',
                    // first and last child have different padding in the existing corptax theme, but we don't want that here
                    ':first-child, :last-child': {
                        padding: '10px 16px',
                        lineHeight: 'normal',
                    },
                },
                // this class is applied to the cell being edited as part of batch edit (despite its name implying that the cell was already edited)
                '.e-editedbatchcell.e-rowcell, .e-editedbatchcell.e-headercell': {
                    ':first-child, :last-child': {
                        padding: 0,
                    },
                    padding: 0,
                },
                // elements in this hierachy appear when you edit a cell.
                '.e-row': {
                    '.e-input-group': {
                        '.e-input.e-field': {
                            // override existing corptax theme which sets this to 14px
                            fontSize: 10,
                        },
                        // if we don't unset the margin like this,
                        // the rows around the cell being edited will be pushed apart ever so slightly
                        margin: 0,
                    },
                },
            },
        };
    }, []);
    const amountsGridStyle: IRawStyle = useMemo(() => {
        return {
            displayName: 'amountsGrid',
            '.e-gridcontent': {
                '.e-content': {
                    '.e-table': {
                        '.e-row, .e-summaryrow': {
                            '.e-rowcell': {
                                ':nth-child(1), :nth-child(2)': {
                                    backgroundColor: customPalette.statusCoolMidGrey,
                                },
                                ':nth-child(3), :nth-child(4), :nth-child(5)': {
                                    backgroundColor: brandPalette.white,
                                    borderBottom: `1px solid ${brandPalette.coolLightGrayTint1}`,
                                },
                                color: customPalette.grey222222,
                                fontWeight: 400,
                            },
                        },
                        '.disabledRow > td': {
                            backgroundColor: `${customPalette.statusCoolMidGrey} !important`,
                            borderBottom: 'none !important',
                        },
                    },
                    borderBottom: `1px solid ${customPalette.statusCoolLightGrey}`,
                    borderLeft: `1px solid ${customPalette.statusCoolLightGrey}`,
                    borderRight: `1px solid ${customPalette.statusCoolLightGrey}`,
                    overflow: 'visible !important',
                },
                flexGrow: 2,
                overflow: 'hidden auto !important',
                scrollbarGutter: 'stable',
            },
            border: '0 !important',
            display: 'flex !important',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'inherit',
        };
    }, [
        brandPalette.white,
        customPalette.statusCoolMidGrey,
        customPalette.grey222222,
        brandPalette.coolLightGrayTint1,
        customPalette.statusCoolLightGrey,
    ]);

    const amountsGridFooterStyle: IRawStyle = useMemo(() => {
        return {
            '&.e-grid': {
                '.e-gridfooter': {
                    backgroundColor: `${brandPalette.white}`,
                },
            },
            '.e-gridfooter': {
                '.e-summarycontent': {
                    '.e-table': {
                        '.e-summaryrow': {
                            /*the row with the 'total' text. quasi-header*/
                            ':first-child': {
                                '.e-summarycell': {
                                    ':first-child': {
                                        paddingLeft: '16px',
                                        letterSpacing: '-0.128px',
                                    },
                                    backgroundColor: customPalette.blue075A92,
                                    padding: 0,
                                },
                                border: `1px solid ${customPalette.statusCoolLightGrey}`,
                                height: '22px',
                            },
                            /*the row with the aggregated values*/
                            ':last-child': {
                                '.e-summarycell': {
                                    ':nth-child(4), :nth-child(5)': {
                                        backgroundColor: brandPalette.white,
                                    },
                                    color: customPalette.grey222222,
                                    fontWeight: 400,
                                    minHeight: '36px',
                                    backgroundColor: customPalette.statusCoolMidGrey,
                                    border: `1px solid ${brandPalette.coolLightGrayTint1}`,
                                    padding: '10px 16px',
                                },
                            },
                            '.e-summarycell': {
                                fontSize: '10px',
                            },
                            borderBottom: `1px solid ${customPalette.statusCoolLightGrey}`,
                            borderLeft: `1px solid ${customPalette.statusCoolLightGrey}`,
                            borderRight: `1px solid ${customPalette.statusCoolLightGrey}`,
                        },
                    },
                    maxHeight: '72px',
                    overflow: 'hidden !important',
                    borderRadius: 'inherit',
                },
                border: '0 !important',
                borderLeft: `1px solid ${customPalette.statusCoolLightGrey}`,
                borderRight: `1px solid ${customPalette.statusCoolLightGrey}`,
                borderBottom: `1px solid ${customPalette.statusCoolLightGrey}`,
                borderRadius: '4px',
                minHeight: '4.2em',
                marginTop: '16px',
                overflow: 'hidden auto !important',
                scrollbarGutter: 'stable',
            },
        };
    }, [
        customPalette.statusCoolLightGrey,
        brandPalette.white,
        customPalette.blue075A92,
        customPalette.grey222222,
        brandPalette.coolLightGrayTint1,
        customPalette.statusCoolMidGrey,
    ]);

    const getAmountsGridClassName: () => string = mergeStylesOnDemand(
        amountsGridHeaderStyle,
        amountsGridCellStyle,
        amountsGridStyle,
        amountsGridFooterStyle
    );
    const totalRowStyle: IRawStyle = useMemo(() => {
        return {
            fontWeight: 700,
            textTransform: 'uppercase',
            verticalAlign: 'top',
            color: brandPalette.white,
            lineHeight: '22px',
        };
    }, [brandPalette.white]);

    return {
        modalStyles,
        dialogContentStyles,
        dialogFooterStyles,
        searchBarStyleOverrides,
        gridComponentCSSClass: getAmountsGridClassName(),
        spinnerClassName: getSpinnerClassName(),
        totalRowStyle,
    } as IAdjustmentAccountAndAmountAssociationDialogStyles;
};
