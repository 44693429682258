import {
    ICalloutProps,
    IContextualMenuItem,
    IContextualMenuListProps,
    IContextualMenuProps,
    IContextualMenuStyles,
    IFocusZoneProps,
    IIconProps,
    IRenderFunction,
    IStyle,
} from '@fluentui/react';
import { ReactEventHandler, useCallback, useEffect, useState } from 'react';
import ContextualMenuWithSearchBox from '../components/common/ContextualMenuWithSearchBox';
import { IFilterItem } from '../data-types/filterItem';

import { getFilteredItemsOrEmptyValue } from '../utils/ContextualMenuUtils';
import { IFluentContextMenuOptions, useContextualMenuItems } from './useContextualMenuItem';
import { useFilterItemContextualMenuSearch } from './useFilterItemContextualMenuSearch';

interface IRenderMenuListOptions {
    filterName: string;
    onSearchBoxChange: (ev: React.ChangeEvent<HTMLInputElement> | undefined, newValue: string | undefined) => void;
    onAbort: ReactEventHandler<HTMLInputElement>;
    onViewAllClick: (filteredItems: IFilterItem[], filterName: string) => void;
    isFiltered: boolean;
    viewAllText: string;
    searchPlaceholderText: string;
    filterMenuAriaLabel: string;
    menuListRole: string;
    announcementMessage?: string | undefined;
    tabsLabels?: string[];
    selectedTabIndex?: number;
    onTabChange?: (tabIndex: number) => void;
    isMultiSelect?: boolean;
    handleApply?: () => void;
    handleCancel?: () => void;
    getExtendedMenuItems?: (menuListProps: IContextualMenuListProps) => React.ReactNode[];
}

const renderMenuList = (options: IRenderMenuListOptions) => {
    const {
        filterName,
        onSearchBoxChange,
        onAbort,
        onViewAllClick,
        isFiltered,
        viewAllText,
        searchPlaceholderText,
        filterMenuAriaLabel,
        menuListRole,
        announcementMessage,
        tabsLabels,
        selectedTabIndex,
        onTabChange,
        isMultiSelect,
        handleApply,
        handleCancel,
        getExtendedMenuItems,
    } = options;

    return (menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) => {
        return (
            <ContextualMenuWithSearchBox
                menuListProps={menuListProps}
                defaultRender={defaultRender}
                onSearchBoxChange={onSearchBoxChange}
                onAbort={onAbort}
                onViewAllClick={() => onViewAllClick([], filterName)}
                showViewAll={isFiltered}
                announcementMessage={announcementMessage}
                searchBoxPlaceholderText={searchPlaceholderText}
                viewAllText={viewAllText}
                tabsLabels={tabsLabels}
                selectedTabIndex={selectedTabIndex}
                onTabChange={onTabChange}
                isMultiSelect={isMultiSelect}
                handleApply={handleApply}
                handleCancel={handleCancel}
                filterMenuAriaLabel={filterMenuAriaLabel}
                menuListRole={menuListRole}
                getExtendedMenuItems={getExtendedMenuItems}
            />
        );
    };
};

export interface IContextualMenuWithSearchBoxOptions {
    filterMenuItems: IFilterItem[];
    filterName: string;
    iconProps: IIconProps | null;
    itemWrapperClassName: string;
    onMenuItemClick: (filteredItems: IFilterItem[], filterName: string) => void;
    searchItemName: string; //ex: folder, filter, action
    isFiltered: boolean;
    noResultsText: string;
    viewAllText: string;
    searchPlaceholderText: string;
    menuItems: IContextualMenuItem[] | undefined;
    setMenuItems: (menuItems: IContextualMenuItem[]) => void;
    filterMenuAriaLabel: string;
    menuListRole: string;
    selectedFilterItems?: IFilterItem[] | null;
    isMultiSelect?: boolean;
    searchValue?: string;
    setSearchValue?: (searchValue: string) => void;
    tabsLabels?: string[];
    selectedTabIndex?: number;
    onTabChange?: (tabIndex: number) => void;
    getExtendedMenuItems?: (menuListProps: IContextualMenuListProps) => React.ReactNode[];
    overrideAnnouncedMessage?: string;
}

export const useContextualMenuWithSearchBox = (options: IContextualMenuWithSearchBoxOptions): IFluentContextMenuOptions => {
    const {
        filterMenuItems,
        filterName,
        iconProps,
        itemWrapperClassName,
        onMenuItemClick,
        searchItemName,
        isFiltered,
        noResultsText,
        viewAllText,
        searchPlaceholderText,
        menuItems,
        setMenuItems,
        filterMenuAriaLabel,
        menuListRole,
        selectedFilterItems,
        isMultiSelect,
        searchValue,
        setSearchValue,
        tabsLabels,
        selectedTabIndex,
        onTabChange,
        getExtendedMenuItems,
        overrideAnnouncedMessage,
    } = options;

    const [tempSelectedItems, setTempSelectedItems] = useState<IFilterItem[] | undefined>(undefined);
    const { contextualMenuItems } = useContextualMenuItems(
        filterMenuItems,
        filterName,
        iconProps,
        itemWrapperClassName,
        tempSelectedItems,
        setTempSelectedItems,
        onMenuItemClick,
        selectedFilterItems,
        isMultiSelect
    );

    useEffect(() => {
        const filteredMenuItems: IContextualMenuItem[] = getFilteredItemsOrEmptyValue(contextualMenuItems, noResultsText, searchValue);
        setMenuItems(filteredMenuItems);
    }, [searchValue, noResultsText, contextualMenuItems, selectedFilterItems]);

    useEffect(() => {
        setTempSelectedItems(selectedFilterItems || []);
    }, [selectedFilterItems]);

    const onAbort = () => setMenuItems(contextualMenuItems);

    const onMenuDismiss = useCallback(() => {
        if (setSearchValue) {
            setSearchValue('');
        }
        setTempSelectedItems(selectedFilterItems || []);
        setMenuItems(contextualMenuItems);
    }, [contextualMenuItems, setSearchValue, setTempSelectedItems]);

    const handleApply = (dismissAll?: () => void) => {
        if (selectedFilterItems && Array.isArray(selectedFilterItems)) {
            selectedFilterItems.splice(0, selectedFilterItems.length, ...(tempSelectedItems || []));
            onMenuItemClick(selectedFilterItems, filterName);
        }

        setMenuItems(contextualMenuItems);
        if (dismissAll) {
            dismissAll();
        }
        onMenuDismiss();
    };

    const handleCancel = (dismissAll?: () => void) => {
        if (dismissAll) {
            dismissAll();
        }
        onMenuDismiss();
    };

    const hasResults = !(menuItems?.length === 1 && menuItems[0].key === 'no_results');

    const announcedComponentMessage = hasResults
        ? `${menuItems?.length} ${searchItemName}${menuItems?.length === 1 ? '' : 's'} found`
        : menuItems[0]?.text;

    const announcementMessage =
        overrideAnnouncedMessage ?? menuItems?.length !== contextualMenuItems.length ? announcedComponentMessage : '';

    const onSearchBoxChange = useFilterItemContextualMenuSearch(contextualMenuItems, noResultsText, setMenuItems, setSearchValue);

    const contextualMenuProps: IContextualMenuProps = {
        items: menuItems,
        directionalHintFixed: true,
        gapSpace: 10,
        calloutProps: {
            calloutWidth: 540,
            hideOverflow: true,
        } as ICalloutProps,
        onRenderMenuList: renderMenuList({
            filterName,
            onSearchBoxChange: (ev: any, newValue?: string) => onSearchBoxChange(newValue),
            onAbort,
            onViewAllClick: onMenuItemClick,
            isFiltered: isFiltered || (tempSelectedItems !== undefined && tempSelectedItems.length > 0),
            viewAllText,
            searchPlaceholderText,
            filterMenuAriaLabel,
            menuListRole,
            announcementMessage,
            tabsLabels,
            selectedTabIndex,
            onTabChange,
            isMultiSelect,
            handleApply,
            handleCancel,
            getExtendedMenuItems,
        }),
        onDismiss: onMenuDismiss,
        shouldFocusOnMount: true,
        focusZoneProps: {
            shouldInputLoseFocusOnArrowKey: () => true,
        } as IFocusZoneProps,
        styles: {
            root: {
                paddingTop: 8,
            } as IStyle,
        } as IContextualMenuStyles,
    } as IContextualMenuProps;

    return { contextualMenuObject: contextualMenuProps } as IFluentContextMenuOptions;
};
