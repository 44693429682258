/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */

export type CalculationExecutionStatusCode = (typeof CalculationExecutionStatusCode)[keyof typeof CalculationExecutionStatusCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalculationExecutionStatusCode = {
    Unknown: 'Unknown',
    Executing: 'Executing',
    Completed: 'Completed',
    Canceled: 'Canceled',
} as const;
