import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import { FC, RefObject, useRef } from 'react';
import { useParams } from 'react-router';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { applicationInsightsReactPlugin } from '../../../ApplicationInsightsService';
import { DrillDownContextProvider } from '../../../contexts/drillDownContext';
import FormPdfViewProvider from '../../../contexts/formPdfViewContext';
import { GridScrollContextProvider } from '../../../contexts/gridScrollContext';
import { GridStateContextProvider } from '../../../contexts/gridStateContext';
import { searchOnType, SearchTableContextProvider } from '../../../contexts/searchTableContext';
import { VarianceNavigationContextProvider } from '../../../contexts/varianceNavigationContext';
import { COMPARE_REPORT_URL, VIEW_REPORT_URL } from '../../../utils/Urls.constant';
import CompareReportsTable from './CompareReportsTable';

const CompareReportLines: FC = () => {
    const { reportId, versionId } = useParams();
    const reportIdAsNumber: number = reportId ? parseInt(reportId) : 0;
    const gridRef: RefObject<GridComponent> | null = useRef<GridComponent>(null);
    const navigate: NavigateFunction = useNavigate();
    const navigateToCompareReport = (versionId: string, selectedEntities: string[]) => {
        navigate(`${COMPARE_REPORT_URL}${reportIdAsNumber}/${versionId}?selectedEntities=${selectedEntities?.join(',')}`);
    };
    const navigateToIndividualReport = () => navigate(`${VIEW_REPORT_URL}${reportIdAsNumber}`);
    return (
        <DrillDownContextProvider>
            <SearchTableContextProvider searchOn={searchOnType.Report}>
                <GridScrollContextProvider>
                    <GridStateContextProvider grid={gridRef}>
                        <FormPdfViewProvider returnKey={reportIdAsNumber}>
                            <VarianceNavigationContextProvider>
                                <CompareReportsTable
                                    key={`${reportId} - ${versionId ?? 'NO_VERSION'}`}
                                    reportId={reportIdAsNumber}
                                    versionId={versionId!}
                                    errorModalOnClose={navigateToIndividualReport}
                                    navigateToCompareReport={navigateToCompareReport}
                                    navigateToReport={navigateToIndividualReport}
                                />
                            </VarianceNavigationContextProvider>
                        </FormPdfViewProvider>
                    </GridStateContextProvider>
                </GridScrollContextProvider>
            </SearchTableContextProvider>
        </DrillDownContextProvider>
    );
};

export default withAITracking(applicationInsightsReactPlugin, CompareReportLines, 'Compare return');
