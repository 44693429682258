import { FormTreeFolder } from '../contexts/formPdfViewContext';
import { FormTOC } from '../model';

export const getFormPagesAndParentId = (data: FormTOC, pageId: string | undefined) => {
    let formPages = [
        {
            formPageId: data?.defaultPage?.id,
            formPageName: data?.defaultPage?.name,
        },
    ];
    const foldersPages = data?.folders?.find((folder) => folder.pages?.some((page) => page.id === pageId)) as FormTreeFolder | undefined;
    if (foldersPages?.pages) {
        formPages = foldersPages?.pages.map((page) => {
            return {
                formPageId: page.id,
                formPageName: page.name,
            };
        });
    }
    const parentId = foldersPages?.id || data?.defaultPage?.id || '';
    return { formPages, parentId };
};
