/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { UserRecord } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetAllUserRecordsHook = () => {
    const getAllUserRecords = useCustomInstance<UserRecord[]>();

    return (signal?: AbortSignal) => {
        return getAllUserRecords({ url: `/Users/GetAllUserRecords`, method: 'get', signal });
    };
};

export const getGetAllUserRecordsQueryKey = () => [`/Users/GetAllUserRecords`] as const;

export const useGetAllUserRecordsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllUserRecordsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllUserRecordsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllUserRecordsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAllUserRecordsQueryKey();

    const getAllUserRecords = useGetAllUserRecordsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllUserRecordsHook>>>> = ({ signal }) =>
        getAllUserRecords(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAllUserRecordsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllUserRecordsHook>>>>;
export type GetAllUserRecordsQueryError = ErrorType<unknown>;

export const useGetAllUserRecords = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllUserRecordsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllUserRecordsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAllUserRecordsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useRefreshTokenHook = () => {
    const refreshToken = useCustomInstance<string>();

    return (signal?: AbortSignal) => {
        return refreshToken({ url: `/Users/RefreshToken`, method: 'get', signal });
    };
};

export const getRefreshTokenQueryKey = () => [`/Users/RefreshToken`] as const;

export const useRefreshTokenQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getRefreshTokenQueryKey();

    const refreshToken = useRefreshTokenHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>> = ({ signal }) => refreshToken(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type RefreshTokenQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>>;
export type RefreshTokenQueryError = ErrorType<unknown>;

export const useRefreshToken = <
    TData = Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useRefreshTokenQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
