import { useCorptaxTheme } from '@corptax/react-components-common';
import { Announced, mergeStyles, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useCommonIndividualReportTableHeaderStyles } from '../../../hooks/useCommonIndividualReportTableHeaderStyles';
import { IAppTheme } from '../../../theme/IAppTheme';
import Icon from '../../common/Icon';

export interface ICommonIndividualReportHeaderProps {
    isRecalculateNeeded: boolean;
    actionBar?: React.ReactNode;
    topContentLeft?: React.ReactNode[];
    topContentRight?: React.ReactNode[];
}

const CommonIndividualReportHeader = (props: ICommonIndividualReportHeaderProps) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const { cardStyles, topSection, buttonBar, buttonBarWrapper } = useCommonIndividualReportTableHeaderStyles();

    return (
        <>
            <Stack className={cardStyles}>
                <Stack className={topSection} horizontal>
                    {props.topContentLeft}
                    {props.topContentRight}
                </Stack>
                <Stack className={buttonBarWrapper} horizontal horizontalAlign='space-between' wrap>
                    <Stack className={buttonBar}>{props.actionBar}</Stack>
                    {props.isRecalculateNeeded && (
                        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 4 }}>
                            <span
                                style={{
                                    background: customPalette.warningBackground,
                                    borderRadius: 32,
                                    display: 'inherit',
                                    padding: '4px',
                                }}
                            >
                                <Icon iconName='Warning16Regular' />
                            </span>
                            <Text
                                className={mergeStyles({
                                    fontSize: '10px',
                                    lineHeight: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                })}
                            >
                                {t('recalculateNeeded')}
                                <Announced message={t('recalculateNeeded').toString()} />
                            </Text>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default CommonIndividualReportHeader;
