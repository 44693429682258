import { IPanelStyleProps, IPanelStyles, IStyleFunctionOrObject, Panel, PanelType } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { ICalculationOptions, IWhitePaperColumn } from '../../../model';
import { HEADER_SIZE } from '../../../utils/Style.constants';
import CalculationOptionsTable from './CalculationOptionsTable';

export interface ICalculationOptionsPanelProps {
    calculationOptions: ICalculationOptions[];
    entityCode: string;
    isOpen: boolean;
    columns?: IWhitePaperColumn[] | null;
    onClose?: () => void;
    onConfirm?: () => void;
}

export const CalculationOptionsPanel = ({ calculationOptions, isOpen, columns, onClose }: ICalculationOptionsPanelProps) => {
    const { t } = useTranslation();

    const panelStyles: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles> = {
        main: {
            transition: 'width 500ms',
            height: `calc(100% - ${HEADER_SIZE})`,
            display: 'flex',
            top: HEADER_SIZE,
            maxWidth: '50rem',
        },
        commands: {
            padding: '20px 20px 20px 24px',
        },
        content: {
            padding: '26px 20px 20px 24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            minHeight: '10em',
        },
        navigation: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            width: '100%',
            height: '38px',
        },
        subComponentStyles: {
            closeButton: {
                root: {
                    margin: 0,
                },
                rootHovered: {
                    background: 'transparent',
                },
            },
        },
        header: {
            display: 'flex',
            gap: '10px',
            padding: 0,
            width: '100%',
            alignItems: 'center',
            height: 'inherit',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '-0.256px',
            flexGrow: 2,
        },
    };

    return (
        <Panel
            isOpen={isOpen}
            onDismiss={onClose}
            customWidth='auto'
            closeButtonAriaLabel={t('close').toString()}
            type={PanelType.custom}
            isFooterAtBottom={true}
            isLightDismiss={true}
            data-testid='calculationOptionsPanelTestId'
            headerText={t('calculationOptions').toString()}
            styles={panelStyles}
        >
            <CalculationOptionsTable calculationOptions={calculationOptions} columns={columns} />
        </Panel>
    );
};
