export const arrowLeftKey: string = 'ArrowLeft';
export const arrowRightKey: string = 'ArrowRight';
export const cardHeaderClass: string = 'card-header';
export const cellContainerClass: string = 'cell-container';
export const columnHeaderRole: string = 'columnheader';
export const elementNodeType: number = 1;
export const enterKey: string = 'Enter';
export const expandCollapseClass: string = 'expand-collapse';
export const gridCellRole: string = 'gridcell';
export const gridContainerClass: string = 'gridcontainer';
export const groupedListIdPrefix: string = 'groupedList';
export const msGroupHeaderClass: string = 'ms-GroupHeader';
export const groupHeaderNameClass: string = 'group-header-name';
export const groupKeyPrefix: string = 'group-key-';
export const msDetailsHeaderClass: string = 'ms-DetailsHeader';
export const msDetailHeaderCellClass: string = 'ms-DetailsHeader-cell';
export const msDetailHeaderCellNameClass: string = 'ms-DetailsHeader-cellName';
export const msDetailRowCellClass: string = 'ms-DetailsRow-cell';
export const msGroupedListGroupClass: string = 'ms-GroupedList-group';
export const msGroupHeaderExpandClass: string = 'ms-GroupHeader-expand';
export const msGroupHeaderTitleClass: string = 'ms-GroupHeader-title';
export const msGroupSpacerClass: string = 'ms-GroupSpacer';
export const msTooltipHost: string = 'ms-TooltipHost';
export const msListClass: string = 'ms-List';
export const msListCellClass: string = 'ms-List-cell';
export const msListPageClass: string = 'ms-List-page';
export const msListSurfaceClass: string = 'ms-List-surface';
export const noRecordsRowClass: string = 'no-records';
export const rowRole: string = 'row';
export const treeGridRole: string = 'treegrid';
export const treeLineClass: string = 'treeline';
export const automationKeyAttribute: string = 'data-automation-key';
