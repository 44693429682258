import { getId } from '@fluentui/react';
import { gridCellRole, groupedListIdPrefix, groupKeyPrefix, rowRole } from './CardGridListConstants';

export function createUniqueGroupClassName(): string {
    return getId(groupKeyPrefix);
}

export function createUniqueGroupedListId(): string {
    return getId(groupedListIdPrefix);
}

export function getGridCellElements(rowElement: HTMLElement): HTMLElement[] {
    const gridCellElements: NodeListOf<HTMLElement> = rowElement.querySelectorAll(
        `:not(div[role="${rowRole}"]) div[role="${gridCellRole}"]`
    );
    const visibileGridCellElements: HTMLElement[] = [];

    for (let index: number = 0; index < gridCellElements.length; index++) {
        const gridCellElement: HTMLElement = gridCellElements.item(index);

        if (getComputedStyle(gridCellElement).display !== 'none') {
            const alreadyDefinedTabIndexElements = gridCellElement.querySelectorAll('[tabindex]');
            if (alreadyDefinedTabIndexElements.length > 0) {
                for (let i = 0; i < alreadyDefinedTabIndexElements.length; i++) {
                    visibileGridCellElements.push(alreadyDefinedTabIndexElements[i] as HTMLElement);
                }
            } else {
                visibileGridCellElements.push(gridCellElement);
            }
        }
    }

    return visibileGridCellElements;
}

export function getParentRowElement(elementOfRow: HTMLElement): HTMLElement | null {
    let rowElement: HTMLElement | null = null;

    while (elementOfRow.parentElement) {
        elementOfRow = elementOfRow.parentElement;

        if (elementOfRow.role === rowRole) {
            rowElement = elementOfRow;
            break;
        }
    }

    return rowElement;
}
