import { ITextStyles, mergeStyles, Text } from '@fluentui/react';
import { VIEW_REPORT_URL } from '../../../utils/Urls.constant';

import { useCorptaxTheme } from '@corptax/react-components-common';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserSettings } from '../../../contexts/useUserSettings';
import { Report } from '../../../model';
import { IAppTheme } from '../../../theme/IAppTheme';

interface ReportLinkProps {
    report: Report;
    tabIndex?: number;
}

const ReportLink: React.FC<ReportLinkProps> = ({ report, tabIndex = 0 }) => {
    const navigate = useNavigate();
    const userSettingsContext = useUserSettings();
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const reportNameTextStyles: ITextStyles = {
        root: {
            color: customPalette.blueDark,
            whiteSpace: 'normal',
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 600,
            textWrap: 'nowrap',
        },
    };

    const getLinkStyles = () =>
        mergeStyles({
            textDecoration: 'none',
            ':hover': {
                textDecoration: 'underline ' + customPalette.themePrimary,
                cursor: 'pointer',
            },
            '& > span:hover': {
                color: customPalette.themePrimary,
            },
        });

    const linkStyles = getLinkStyles();

    return (
        <Link role='link' to={VIEW_REPORT_URL + report.id} onClick={() => openReport()} className={linkStyles} tabIndex={tabIndex}>
            <Text styles={reportNameTextStyles}>{report.name ?? ''}</Text>
        </Link>
    );

    function openReport(): void {
        userSettingsContext.updateReturnTabs(
            {
                returnKey: report.id ?? 0,
                returnName: report.name ?? '',
            },
            true
        );
        return navigate(VIEW_REPORT_URL + report.id);
    }
};

export { ReportLink as default };
