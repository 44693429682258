import { IStyle } from '@fluentui/react';
import styled from 'styled-components';
import DefaultGrid from '../../grid/DefaultGrid';

//these attach to a separate location in the DOM tree so we cannot set them using styled-components
export const contextMenuIconStyles: IStyle = {
    '.icon--fluent ': {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        backgroundColor: 'currentColor',
        '-webkit-mask': 'no-repeat center / 100%',
        mask: 'no-repeat center / 100%',
        '-webkit-mask-image': 'var(--svg)',
        maskImage: 'var(--svg)',
    },
    '.icon--fluent--data-line-20-regular ': {
        ' --svg': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20' height='20'%3E%3Cpath fill='black' d='M15.5 4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3ZM13 5.5a2.5 2.5 0 1 1 1.557 2.316l-1.282 1.923a2.5 2.5 0 1 1-3.83 3.185l-2.496 1.07a2.5 2.5 0 1 1-.394-.919l2.496-1.07a2.5 2.5 0 0 1 3.392-2.822l1.282-1.922A2.492 2.492 0 0 1 13 5.5Zm-3 6a1.5 1.5 0 1 0 3 0a1.5 1.5 0 0 0-3 0Zm-7 3a1.5 1.5 0 1 0 3 0a1.5 1.5 0 0 0-3 0Z'/%3E%3C/svg%3E")`,
    },
    '.e-menu-item .e-menu-icon.icon--fluent.icon--fluent--data-line-20-regular': {
        color: (props: any) => props.theme.customPalette.themePrimary,
        height: '20px',
        width: '20px',
    },
    '.icon--fluent--edit-20-regular': {
        ' --svg': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20' height='20'%3E%3Cpath fill='currentColor' d='M15.1794 1.42622C14.0271 0.214378 12.1027 0.190103 10.9203 1.3725L1.54545 10.7469C1.21763 11.0747 0.990085 11.4892 0.889529 11.9418L0.0119121 15.8917C-0.0251689 16.0586 0.02559 16.2328 0.146489 16.3537C0.267387 16.4746 0.441665 16.5253 0.608549 16.4882L4.53494 15.615C5.00237 15.5111 5.43048 15.276 5.76907 14.9375L13.7497 6.95676L14.0858 7.29287C14.4763 7.68339 14.4763 8.31656 14.0858 8.70708L13.1464 9.64642C12.9512 9.84169 12.9512 10.1583 13.1464 10.3535C13.3417 10.5488 13.6583 10.5488 13.8536 10.3535L14.7929 9.41419C15.5739 8.63314 15.5739 7.36681 14.7929 6.58576L14.4568 6.24965L15.1271 5.57936C16.2681 4.43832 16.2913 2.59564 15.1794 1.42622ZM11.6274 2.07962C12.4123 1.29471 13.6898 1.31083 14.4547 2.11528C15.1928 2.89158 15.1774 4.1148 14.42 4.87226L5.06196 14.2303C4.85781 14.4345 4.59969 14.5762 4.31786 14.6389L1.15855 15.3415L1.86572 12.1587C1.92502 11.8918 2.05921 11.6473 2.25254 11.454L11.6274 2.07962Z'/%3E%3C/svg%3E")`,
    },
    '.e-menu-item .e-menu-icon.icon--fluent.icon--fluent--edit-20-regular': {
        color: (props: any) => props.theme.customPalette.themePrimary,
        height: '20px',
        width: '20px',
    },
    '.icon--fluent--comment-20-regular': {
        ' --svg': `url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="20" height="20" viewBox="0 0 20 20"%3E%3Cpath fill="currentColor" d="M10.48 13.842h4.92c.896 0 1.6-.713 1.6-1.566v-6.71C17 4.713 16.296 4 15.4 4H4.6C3.704 4 3 4.713 3 5.566v6.71c0 .853.704 1.566 1.6 1.566h1.6V17h.003l.002-.001l4.276-3.157ZM6.8 17.803a1.009 1.009 0 0 1-1.4-.199a.978.978 0 0 1-.199-.59v-2.172h-.6c-1.436 0-2.6-1.149-2.6-2.566v-6.71C2 4.149 3.164 3 4.6 3h10.8C16.836 3 18 4.149 18 5.566v6.71c0 1.418-1.164 2.566-2.6 2.566h-4.59l-4.011 2.961Z"%2F%3E%3C%2Fsvg%3E')`,
    },
    '.e-menu-item .e-menu-icon.icon--fluent.icon--fluent--comment-20-regular': {
        color: (props: any) => props.theme.customPalette.themePrimary,
        height: '20px',
        width: '20px',
    },
};

//todo - theme provider
export const StyledIndividualReportGrid = styled(DefaultGrid)`
    &.e-grid {
        background-color: ${(props: any) => props.theme.brandPalette.white};
        font-size: 14px;
        font-weight: 600;
        min-height: 'calc(100vh - 280px)';
        color: ${(props: any) => props.theme.brandPalette.neutralSecondary};
    }

    &.e-grid .e-gridcontent {
        padding-bottom: ${(props: any) => (props.enableColumnVirtualization ? '11px' : '0px')};
    }

    &.e-grid .e-gridcontent .e-movablescrollbar {
        overflow: hidden;
        overflow-x: auto;
    }

    .highlighted-search {
        background-color: ${(props: any) => props.theme.customPalette.yellowDark};
    }

    .e-headercell {
        background-color: ${(props: any) => props.theme.customPalette.themeLighter} !important;
        font-weight: bold;
        font-size: var(--header-font-size);
        border: none;
    }

    .e-headertext {
        font-size: var(--header-font-size);
        color: ${(props: any) => props.theme.customPalette.blueDark};
        font-weight: 600;
        text-align: center;
    }

    .e-gridheader .e-headercell .e-rhandler,
    .e-gridheader .e-headercell .e-rsuppress {
        border-right: 1px solid ${(props: any) => props.theme.customPalette.lineGrey} !important;
    }
    .e-gridcontent .e-rowcell.e-cellselectionbackground,
    .e-gridcontent .e-rowcell.e-focus {
        border-radius: 4px;
    }

    .e-gridheader .e-headercell .e-headercelldiv {
        align-items: start;
    }

    .e-gridheader .e-headercell.e-rightalign .e-headercelldiv {
        align-items: end;
    }

    .e-grid .e-spinner-pane {
        // Hide the Grid spinner.
        display: none;
    }

    .compare-data-variance-column.white-paper-report-highlight,
    .compare-data-variance-column.white-paper-report-highlight:hover,
    .compare-data-variance-column.white-paper-report-highlight.e-selectionbackground.e-active {
        background-color: ${(props: any) => props.theme.customPalette.orangeLight} !important;
        position: relative;
        padding-left: 25px;
    }

    .compare-data-variance-column.white-paper-report-highlight::before {
        content: '\\e88b';
        font-family: 'e-icons' !important;
        float: left;
        color: ${(props: any) => props.theme.customPalette.red};
        font-weight: 600;
        position: absolute;
        left: 7px;
        top: 18px;
    }

    .compare-data-variance-column.white-paper-report-highlight .highlighted-search {
        background-color: ${(props: any) => props.theme.customPalette.themePrimary};
        color: ${(props: any) => props.theme.brandPalette.white};
    }

    &.e-grid.e-control .e-row .e-rowcell {
        background-color: ${(props: any) => props.theme.brandPalette.white};
        color: ${(props: any) => props.theme.customPalette.blueDark};
        font-size: 14px;
        border: none;
        font-weight: 600;
        border-right: 1px solid ${(props: any) => props.theme.customPalette.lineGrey};
        box-sizing: border-box;
        height: 100%;
    }

    td.e-lastrowcell {
        color: ${(props: any) => props.theme.customPalette.blueDark};
    }

    .white-paper-report-customizable-cell {
        padding-left: 30px !important;
    }

    .white-paper-report-customizable-cell .white-paper-cell-blue {
        color: ${(props: any) => props.theme.customPalette.themePrimary} !important;
    }

    .white-paper-report-customizable-cell .white-paper-cell-bold {
        font-weight: 700 !important;
    }

    .recalc-needed {
        background: ${(props: any) => props.theme.customPalette.warningBackground} !important;
    }

    .highlighted-cell {
        background: ${(props: any) => props.theme.customPalette.redLighter} !important;
    }

    .headerTextWrap,
    .compareHeaderTextWrap {
        width: 100%;
        font-size: var(--header-font-size);
        color: ${(props: any) => props.theme.customPalette.blueDark};
        font-weight: 600;
        text-align: right;
        line-height: 1.2;
        padding: 0.25rem 0;
        margin: 0;
    }
    .headerTextWrap > label,
    .compareHeaderTextWrap > label {
        display: block;
        text-align: right;
        word-wrap: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .white-paper-report-clickable-value-cell.e-rowcell.e-templatecell,
    .white-paper-report-clickable-value-cell.e-rowcell.e-templatecell > div {
        height: inherit;
    }

    .compare-data-variance-column:not(:last-child) {
        border-right: 0.2rem solid ${(props: any) => props.theme.customPalette.lineGrey} !important;
    }
`;
