import { EditEventArgs, QueryCellInfoEventArgs, SaveEventArgs } from '@syncfusion/ej2-react-grids';
import { useTranslation } from 'react-i18next';
import { setFocusOnSyncfusionGridCell } from '../utils/TableUtils';

export const BEGIN_EDIT_REQUEST_TYPE = 'beginEdit';
export const SAVE_REQUEST_TYPE = 'save';
export const CANCEL_REQUEST_TYPE = 'cancel';

export interface IEditableTableOptions<T> {
    parseRowValuesToNumber?: (row?: T) => void;
    getCellHighlightingClasses?: (data: T, field: string) => { add: string[]; remove: string[] };
    formatEditableRowValues?: (data: T) => void;
    markModifiedValues?: (data: T, previousData: T) => boolean;
    setGridDirtyFlag?: (isDirty: boolean) => void;
    formElementToFocusOnEditBegin?: (args: EditEventArgs) => HTMLInputElement | null;
}

export function useEditableTable<T>(options: IEditableTableOptions<T> = {}) {
    const {
        parseRowValuesToNumber,
        getCellHighlightingClasses,
        formatEditableRowValues,
        markModifiedValues,
        setGridDirtyFlag,
        formElementToFocusOnEditBegin: onEditGetFormElementToFocus,
    } = options;
    const { t } = useTranslation();

    const focusCellAfterEventCompleted = (args: EditEventArgs) => {
        if (args?.form && args.requestType === BEGIN_EDIT_REQUEST_TYPE && onEditGetFormElementToFocus) {
            const input: HTMLInputElement | null = onEditGetFormElementToFocus(args);
            input?.focus();
            input?.select();
        } else if (args.row && args.requestType === SAVE_REQUEST_TYPE) {
            const firstEditableCell = args.row.querySelector('.white-paper-report-clickable-value-cell') as HTMLElement;
            setFocusOnSyncfusionGridCell(firstEditableCell);
        } else if (args.row && args.requestType === CANCEL_REQUEST_TYPE) {
            const firstRowCell = args.row.querySelector('td') as HTMLElement;
            setFocusOnSyncfusionGridCell(firstRowCell);
        }
    };

    const formatFieldValues = (args: SaveEventArgs) => {
        if (args.requestType === BEGIN_EDIT_REQUEST_TYPE && parseRowValuesToNumber) {
            parseRowValuesToNumber(args.rowData as T);
        } else if (args.requestType === SAVE_REQUEST_TYPE) {
            const parsedData = args.data as T;
            const parsedPreviousData = args.previousData as T;
            formatEditableRowValues && formatEditableRowValues(parsedData!);
            const wasRowModified = markModifiedValues && markModifiedValues(parsedData!, parsedPreviousData!);
            if (setGridDirtyFlag && wasRowModified) {
                setGridDirtyFlag(wasRowModified);
            }
        }
    };

    const highlightCell = (args: QueryCellInfoEventArgs) => {
        if (getCellHighlightingClasses) {
            const cell: Element | undefined = args.cell;
            const data: T = args.data as T;
            const field: string | undefined = args.column?.field;
            const { add: classesToAdd, remove: classesToRemove } = getCellHighlightingClasses!(data!, field!);
            cell?.classList.add(...classesToAdd);
            cell?.classList.remove(...classesToRemove);
            if (classesToAdd.length > 0) {
                cell?.setAttribute('aria-label', addHighlightedSuffixToAriaLabel(cell?.getAttribute('aria-label')!));
            }
        }
    };

    const addHighlightedSuffixToAriaLabel = (currentAriaLabel: string) => {
        return `${currentAriaLabel} ${t('highlighted')}.`;
    };

    return {
        focusCellAfterEventCompleted,
        formatFieldValues,
        highlightCell,
        addHighlightedSuffixToAriaLabel,
    };
}
