/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
    AddReturnsToExistingFolderRequest,
    AdjustmentAccountAndAmountAssociationRequest,
    AdjustmentAccountAndAmountAssociationsResponse,
    AmountDataEntryOptions,
    AmountsEntriesResponse,
    BaseStackResponse,
    CalculationExecutionStatus,
    CalculationExecutionStatuses,
    CancelScheduledCalculationRequest,
    ChangeTaxReturnStatusResponse,
    CreateFolderWithTaxReturnsRequest,
    DeleteFolderRequest,
    DeleteReturnsFromFolderRequest,
    Enterprise,
    ExtractionDetailsAccountSummary,
    FormTOC,
    GetAmountsEntriesParams,
    GetAmountsEntryOptionsParams,
    GetCalculationExecutionStatusRequest,
    GetComparedWhitePaperReportParams,
    GetFormTOCRequest,
    GetWhitePaperReportParams,
    GetWhitePaperReportPreviousVersionsParams,
    IWhitePaperReport,
    ProblemDetails,
    RenameFolderResponse,
    RenameTaxReturnFolderRecordParams,
    Report,
    ReportCellAuditExportRequest,
    ReportCellAuditRequest,
    ReportListRequest,
    ReportListResponse,
    ReportRowTraceExportRequest,
    ReturnExecutionInformation,
    ReturnFolderDto,
    ReviewFormDataRequest,
    SaveAmountsRequest,
    SaveAmountsResponse,
    SaveTaxReturnFolderRecordParams,
    ScheduleTaxReturnCalculationRequest,
    SetTaxReturnStatusParams,
    TaxReturnSaveRecordsRequest,
    TaxReturnSaveRecordsResponse,
    WhitePaperReportExportRequest,
    WhitePaperReportVersion,
} from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetTaxReturnsHook = () => {
    const getTaxReturns = useCustomInstance<ReportListResponse>();

    return (reportListRequest: ReportListRequest) => {
        return getTaxReturns({ url: `/Report`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: reportListRequest });
    };
};

export const useGetTaxReturnsMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTaxReturnsHook>>>,
        TError,
        { data: ReportListRequest },
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnsHook>>>, TError, { data: ReportListRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const getTaxReturns = useGetTaxReturnsHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnsHook>>>, { data: ReportListRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return getTaxReturns(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetTaxReturnsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnsHook>>>>;
export type GetTaxReturnsMutationBody = ReportListRequest;
export type GetTaxReturnsMutationError = ErrorType<unknown>;

export const useGetTaxReturns = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTaxReturnsHook>>>,
        TError,
        { data: ReportListRequest },
        TContext
    >;
}) => {
    const mutationOptions = useGetTaxReturnsMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetTaxReturnByIdHook = () => {
    const getTaxReturnById = useCustomInstance<Report>();

    return (reportId: number, signal?: AbortSignal) => {
        return getTaxReturnById({ url: `/Report/${reportId}`, method: 'get', signal });
    };
};

export const getGetTaxReturnByIdQueryKey = (reportId: number) => [`/Report/${reportId}`] as const;

export const useGetTaxReturnByIdQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTaxReturnByIdHook>>>,
    TError = ErrorType<unknown>
>(
    reportId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnByIdHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnByIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetTaxReturnByIdQueryKey(reportId);

    const getTaxReturnById = useGetTaxReturnByIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnByIdHook>>>> = ({ signal }) =>
        getTaxReturnById(reportId, signal);

    return { queryKey, queryFn, enabled: !!reportId, ...queryOptions };
};

export type GetTaxReturnByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnByIdHook>>>>;
export type GetTaxReturnByIdQueryError = ErrorType<unknown>;

export const useGetTaxReturnById = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaxReturnByIdHook>>>, TError = ErrorType<unknown>>(
    reportId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaxReturnByIdHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTaxReturnByIdQueryOptions(reportId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetLatestExecutionInformationHook = () => {
    const getLatestExecutionInformation = useCustomInstance<ReturnExecutionInformation>();

    return (reportId: number) => {
        return getLatestExecutionInformation({ url: `/Report/LatestExecution/${reportId}`, method: 'post' });
    };
};

export const useGetLatestExecutionInformationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetLatestExecutionInformationHook>>>,
        TError,
        { reportId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLatestExecutionInformationHook>>>,
    TError,
    { reportId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const getLatestExecutionInformation = useGetLatestExecutionInformationHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetLatestExecutionInformationHook>>>,
        { reportId: number }
    > = (props) => {
        const { reportId } = props ?? {};

        return getLatestExecutionInformation(reportId);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetLatestExecutionInformationMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetLatestExecutionInformationHook>>>
>;

export type GetLatestExecutionInformationMutationError = ErrorType<unknown>;

export const useGetLatestExecutionInformation = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetLatestExecutionInformationHook>>>,
        TError,
        { reportId: number },
        TContext
    >;
}) => {
    const mutationOptions = useGetLatestExecutionInformationMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useSetTaxReturnStatusHook = () => {
    const setTaxReturnStatus = useCustomInstance<ChangeTaxReturnStatusResponse>();

    return (reportId: number, params?: SetTaxReturnStatusParams) => {
        return setTaxReturnStatus({ url: `/Report/${reportId}/status`, method: 'post', params });
    };
};

export const useSetTaxReturnStatusMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetTaxReturnStatusHook>>>,
        TError,
        { reportId: number; params?: SetTaxReturnStatusParams },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetTaxReturnStatusHook>>>,
    TError,
    { reportId: number; params?: SetTaxReturnStatusParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const setTaxReturnStatus = useSetTaxReturnStatusHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSetTaxReturnStatusHook>>>,
        { reportId: number; params?: SetTaxReturnStatusParams }
    > = (props) => {
        const { reportId, params } = props ?? {};

        return setTaxReturnStatus(reportId, params);
    };

    return { mutationFn, ...mutationOptions };
};

export type SetTaxReturnStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetTaxReturnStatusHook>>>>;

export type SetTaxReturnStatusMutationError = ErrorType<unknown>;

export const useSetTaxReturnStatus = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetTaxReturnStatusHook>>>,
        TError,
        { reportId: number; params?: SetTaxReturnStatusParams },
        TContext
    >;
}) => {
    const mutationOptions = useSetTaxReturnStatusMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetWhitePaperReportHook = () => {
    const getWhitePaperReport = useCustomInstance<IWhitePaperReport>();

    return (params?: GetWhitePaperReportParams, signal?: AbortSignal) => {
        return getWhitePaperReport({ url: `/Report/view`, method: 'get', params, signal });
    };
};

export const getGetWhitePaperReportQueryKey = (params?: GetWhitePaperReportParams) =>
    [`/Report/view`, ...(params ? [params] : [])] as const;

export const useGetWhitePaperReportQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportHook>>>,
    TError = ErrorType<unknown>
>(
    params?: GetWhitePaperReportParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetWhitePaperReportQueryKey(params);

    const getWhitePaperReport = useGetWhitePaperReportHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportHook>>>> = ({ signal }) =>
        getWhitePaperReport(params, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetWhitePaperReportQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportHook>>>>;
export type GetWhitePaperReportQueryError = ErrorType<unknown>;

export const useGetWhitePaperReport = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportHook>>>,
    TError = ErrorType<unknown>
>(
    params?: GetWhitePaperReportParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetWhitePaperReportQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetComparedWhitePaperReportHook = () => {
    const getComparedWhitePaperReport = useCustomInstance<IWhitePaperReport>();

    return (params?: GetComparedWhitePaperReportParams, signal?: AbortSignal) => {
        return getComparedWhitePaperReport({ url: `/Report/compare`, method: 'get', params, signal });
    };
};

export const getGetComparedWhitePaperReportQueryKey = (params?: GetComparedWhitePaperReportParams) =>
    [`/Report/compare`, ...(params ? [params] : [])] as const;

export const useGetComparedWhitePaperReportQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetComparedWhitePaperReportHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetComparedWhitePaperReportParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetComparedWhitePaperReportHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetComparedWhitePaperReportHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetComparedWhitePaperReportQueryKey(params);

    const getComparedWhitePaperReport = useGetComparedWhitePaperReportHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetComparedWhitePaperReportHook>>>> = ({ signal }) =>
        getComparedWhitePaperReport(params, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetComparedWhitePaperReportQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetComparedWhitePaperReportHook>>>
>;
export type GetComparedWhitePaperReportQueryError = ErrorType<ProblemDetails>;

export const useGetComparedWhitePaperReport = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetComparedWhitePaperReportHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetComparedWhitePaperReportParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetComparedWhitePaperReportHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetComparedWhitePaperReportQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useScheduleTaxReturnCalculationHook = () => {
    const scheduleTaxReturnCalculation = useCustomInstance<void>();

    return (scheduleTaxReturnCalculationRequest: ScheduleTaxReturnCalculationRequest) => {
        return scheduleTaxReturnCalculation({
            url: `/Report/recalculate`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: scheduleTaxReturnCalculationRequest,
        });
    };
};

export const useScheduleTaxReturnCalculationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useScheduleTaxReturnCalculationHook>>>,
        TError,
        { data: ScheduleTaxReturnCalculationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useScheduleTaxReturnCalculationHook>>>,
    TError,
    { data: ScheduleTaxReturnCalculationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const scheduleTaxReturnCalculation = useScheduleTaxReturnCalculationHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useScheduleTaxReturnCalculationHook>>>,
        { data: ScheduleTaxReturnCalculationRequest }
    > = (props) => {
        const { data } = props ?? {};

        return scheduleTaxReturnCalculation(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ScheduleTaxReturnCalculationMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useScheduleTaxReturnCalculationHook>>>
>;
export type ScheduleTaxReturnCalculationMutationBody = ScheduleTaxReturnCalculationRequest;
export type ScheduleTaxReturnCalculationMutationError = ErrorType<unknown>;

export const useScheduleTaxReturnCalculation = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useScheduleTaxReturnCalculationHook>>>,
        TError,
        { data: ScheduleTaxReturnCalculationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useScheduleTaxReturnCalculationMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useCancelScheduledCalculationHook = () => {
    const cancelScheduledCalculation = useCustomInstance<void>();

    return (cancelScheduledCalculationRequest: CancelScheduledCalculationRequest) => {
        return cancelScheduledCalculation({
            url: `/Report/CancelScheduledCalculation`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: cancelScheduledCalculationRequest,
        });
    };
};

export const useCancelScheduledCalculationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelScheduledCalculationHook>>>,
        TError,
        { data: CancelScheduledCalculationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelScheduledCalculationHook>>>,
    TError,
    { data: CancelScheduledCalculationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const cancelScheduledCalculation = useCancelScheduledCalculationHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCancelScheduledCalculationHook>>>,
        { data: CancelScheduledCalculationRequest }
    > = (props) => {
        const { data } = props ?? {};

        return cancelScheduledCalculation(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type CancelScheduledCalculationMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useCancelScheduledCalculationHook>>>
>;
export type CancelScheduledCalculationMutationBody = CancelScheduledCalculationRequest;
export type CancelScheduledCalculationMutationError = ErrorType<unknown>;

export const useCancelScheduledCalculation = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelScheduledCalculationHook>>>,
        TError,
        { data: CancelScheduledCalculationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useCancelScheduledCalculationMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetAllFoldersHook = () => {
    const getAllFolders = useCustomInstance<ReturnFolderDto[]>();

    return (signal?: AbortSignal) => {
        return getAllFolders({ url: `/Report/GetAllFolders`, method: 'get', signal });
    };
};

export const getGetAllFoldersQueryKey = () => [`/Report/GetAllFolders`] as const;

export const useGetAllFoldersQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllFoldersHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFoldersHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFoldersHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAllFoldersQueryKey();

    const getAllFolders = useGetAllFoldersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllFoldersHook>>>> = ({ signal }) => getAllFolders(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAllFoldersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllFoldersHook>>>>;
export type GetAllFoldersQueryError = ErrorType<unknown>;

export const useGetAllFolders = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllFoldersHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFoldersHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAllFoldersQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useSaveTaxReturnFolderRecordHook = () => {
    const saveTaxReturnFolderRecord = useCustomInstance<BaseStackResponse>();

    return (params?: SaveTaxReturnFolderRecordParams) => {
        return saveTaxReturnFolderRecord({ url: `/Report/SaveTaxReturnFolderRecord`, method: 'post', params });
    };
};

export const useSaveTaxReturnFolderRecordMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnFolderRecordHook>>>,
        TError,
        { params?: SaveTaxReturnFolderRecordParams },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnFolderRecordHook>>>,
    TError,
    { params?: SaveTaxReturnFolderRecordParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const saveTaxReturnFolderRecord = useSaveTaxReturnFolderRecordHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnFolderRecordHook>>>,
        { params?: SaveTaxReturnFolderRecordParams }
    > = (props) => {
        const { params } = props ?? {};

        return saveTaxReturnFolderRecord(params);
    };

    return { mutationFn, ...mutationOptions };
};

export type SaveTaxReturnFolderRecordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnFolderRecordHook>>>>;

export type SaveTaxReturnFolderRecordMutationError = ErrorType<unknown>;

export const useSaveTaxReturnFolderRecord = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnFolderRecordHook>>>,
        TError,
        { params?: SaveTaxReturnFolderRecordParams },
        TContext
    >;
}) => {
    const mutationOptions = useSaveTaxReturnFolderRecordMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useDeleteTaxReturnFolderRecordHook = () => {
    const deleteTaxReturnFolderRecord = useCustomInstance<BaseStackResponse>();

    return (deleteFolderRequest: DeleteFolderRequest) => {
        return deleteTaxReturnFolderRecord({
            url: `/Report/DeleteTaxReturnFolderRecord`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: deleteFolderRequest,
        });
    };
};

export const useDeleteTaxReturnFolderRecordMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteTaxReturnFolderRecordHook>>>,
        TError,
        { data: DeleteFolderRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteTaxReturnFolderRecordHook>>>,
    TError,
    { data: DeleteFolderRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteTaxReturnFolderRecord = useDeleteTaxReturnFolderRecordHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteTaxReturnFolderRecordHook>>>,
        { data: DeleteFolderRequest }
    > = (props) => {
        const { data } = props ?? {};

        return deleteTaxReturnFolderRecord(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteTaxReturnFolderRecordMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useDeleteTaxReturnFolderRecordHook>>>
>;
export type DeleteTaxReturnFolderRecordMutationBody = DeleteFolderRequest;
export type DeleteTaxReturnFolderRecordMutationError = ErrorType<unknown>;

export const useDeleteTaxReturnFolderRecord = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteTaxReturnFolderRecordHook>>>,
        TError,
        { data: DeleteFolderRequest },
        TContext
    >;
}) => {
    const mutationOptions = useDeleteTaxReturnFolderRecordMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useRenameTaxReturnFolderRecordHook = () => {
    const renameTaxReturnFolderRecord = useCustomInstance<RenameFolderResponse>();

    return (params: RenameTaxReturnFolderRecordParams) => {
        return renameTaxReturnFolderRecord({ url: `/Report/RenameTaxReturnFolderRecord`, method: 'post', params });
    };
};

export const useRenameTaxReturnFolderRecordMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRenameTaxReturnFolderRecordHook>>>,
        TError,
        { params: RenameTaxReturnFolderRecordParams },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRenameTaxReturnFolderRecordHook>>>,
    TError,
    { params: RenameTaxReturnFolderRecordParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const renameTaxReturnFolderRecord = useRenameTaxReturnFolderRecordHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRenameTaxReturnFolderRecordHook>>>,
        { params: RenameTaxReturnFolderRecordParams }
    > = (props) => {
        const { params } = props ?? {};

        return renameTaxReturnFolderRecord(params);
    };

    return { mutationFn, ...mutationOptions };
};

export type RenameTaxReturnFolderRecordMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useRenameTaxReturnFolderRecordHook>>>
>;

export type RenameTaxReturnFolderRecordMutationError = ErrorType<unknown>;

export const useRenameTaxReturnFolderRecord = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRenameTaxReturnFolderRecordHook>>>,
        TError,
        { params: RenameTaxReturnFolderRecordParams },
        TContext
    >;
}) => {
    const mutationOptions = useRenameTaxReturnFolderRecordMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetReportCellAuditHook = () => {
    const getReportCellAudit = useCustomInstance<ExtractionDetailsAccountSummary[]>();

    return (reportCellAuditRequest: ReportCellAuditRequest) => {
        return getReportCellAudit({
            url: `/Report/GetReportCellAudit`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportCellAuditRequest,
        });
    };
};

export const useGetReportCellAuditMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReportCellAuditHook>>>,
        TError,
        { data: ReportCellAuditRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetReportCellAuditHook>>>,
    TError,
    { data: ReportCellAuditRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const getReportCellAudit = useGetReportCellAuditHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetReportCellAuditHook>>>,
        { data: ReportCellAuditRequest }
    > = (props) => {
        const { data } = props ?? {};

        return getReportCellAudit(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetReportCellAuditMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReportCellAuditHook>>>>;
export type GetReportCellAuditMutationBody = ReportCellAuditRequest;
export type GetReportCellAuditMutationError = ErrorType<ProblemDetails>;

export const useGetReportCellAudit = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReportCellAuditHook>>>,
        TError,
        { data: ReportCellAuditRequest },
        TContext
    >;
}) => {
    const mutationOptions = useGetReportCellAuditMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetWhitePaperReportPreviousVersionsHook = () => {
    const getWhitePaperReportPreviousVersions = useCustomInstance<WhitePaperReportVersion[]>();

    return (params?: GetWhitePaperReportPreviousVersionsParams, signal?: AbortSignal) => {
        return getWhitePaperReportPreviousVersions({ url: `/Report/versions`, method: 'get', params, signal });
    };
};

export const getGetWhitePaperReportPreviousVersionsQueryKey = (params?: GetWhitePaperReportPreviousVersionsParams) =>
    [`/Report/versions`, ...(params ? [params] : [])] as const;

export const useGetWhitePaperReportPreviousVersionsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportPreviousVersionsHook>>>,
    TError = ErrorType<unknown>
>(
    params?: GetWhitePaperReportPreviousVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportPreviousVersionsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportPreviousVersionsHook>>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetWhitePaperReportPreviousVersionsQueryKey(params);

    const getWhitePaperReportPreviousVersions = useGetWhitePaperReportPreviousVersionsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportPreviousVersionsHook>>>> = ({ signal }) =>
        getWhitePaperReportPreviousVersions(params, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetWhitePaperReportPreviousVersionsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportPreviousVersionsHook>>>
>;
export type GetWhitePaperReportPreviousVersionsQueryError = ErrorType<unknown>;

export const useGetWhitePaperReportPreviousVersions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportPreviousVersionsHook>>>,
    TError = ErrorType<unknown>
>(
    params?: GetWhitePaperReportPreviousVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitePaperReportPreviousVersionsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetWhitePaperReportPreviousVersionsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useExportReportToExcelHook = () => {
    const exportReportToExcel = useCustomInstance<Blob>();

    return (whitePaperReportExportRequest: WhitePaperReportExportRequest[]) => {
        return exportReportToExcel({
            url: `/Report/Export`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: whitePaperReportExportRequest,
            responseType: 'blob',
        });
    };
};

export const useExportReportToExcelMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportToExcelHook>>>,
        TError,
        { data: WhitePaperReportExportRequest[] },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportReportToExcelHook>>>,
    TError,
    { data: WhitePaperReportExportRequest[] },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const exportReportToExcel = useExportReportToExcelHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportReportToExcelHook>>>,
        { data: WhitePaperReportExportRequest[] }
    > = (props) => {
        const { data } = props ?? {};

        return exportReportToExcel(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportReportToExcelMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportReportToExcelHook>>>>;
export type ExportReportToExcelMutationBody = WhitePaperReportExportRequest[];
export type ExportReportToExcelMutationError = ErrorType<ProblemDetails>;

export const useExportReportToExcel = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportToExcelHook>>>,
        TError,
        { data: WhitePaperReportExportRequest[] },
        TContext
    >;
}) => {
    const mutationOptions = useExportReportToExcelMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useExportTaxReturnListToExcelHook = () => {
    const exportTaxReturnListToExcel = useCustomInstance<Blob>();

    return (reportListRequest: ReportListRequest) => {
        return exportTaxReturnListToExcel({
            url: `/Report/ExportTaxReturnList`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportListRequest,
            responseType: 'blob',
        });
    };
};

export const useExportTaxReturnListToExcelMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportTaxReturnListToExcelHook>>>,
        TError,
        { data: ReportListRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportTaxReturnListToExcelHook>>>,
    TError,
    { data: ReportListRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const exportTaxReturnListToExcel = useExportTaxReturnListToExcelHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportTaxReturnListToExcelHook>>>,
        { data: ReportListRequest }
    > = (props) => {
        const { data } = props ?? {};

        return exportTaxReturnListToExcel(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportTaxReturnListToExcelMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useExportTaxReturnListToExcelHook>>>
>;
export type ExportTaxReturnListToExcelMutationBody = ReportListRequest;
export type ExportTaxReturnListToExcelMutationError = ErrorType<ProblemDetails>;

export const useExportTaxReturnListToExcel = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportTaxReturnListToExcelHook>>>,
        TError,
        { data: ReportListRequest },
        TContext
    >;
}) => {
    const mutationOptions = useExportTaxReturnListToExcelMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useExportReportToPDFHook = () => {
    const exportReportToPDF = useCustomInstance<Blob>();

    return (whitePaperReportExportRequest: WhitePaperReportExportRequest[]) => {
        return exportReportToPDF({
            url: `/Report/ExportPDF`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: whitePaperReportExportRequest,
            responseType: 'blob',
        });
    };
};

export const useExportReportToPDFMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportToPDFHook>>>,
        TError,
        { data: WhitePaperReportExportRequest[] },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportReportToPDFHook>>>,
    TError,
    { data: WhitePaperReportExportRequest[] },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const exportReportToPDF = useExportReportToPDFHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportReportToPDFHook>>>,
        { data: WhitePaperReportExportRequest[] }
    > = (props) => {
        const { data } = props ?? {};

        return exportReportToPDF(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportReportToPDFMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportReportToPDFHook>>>>;
export type ExportReportToPDFMutationBody = WhitePaperReportExportRequest[];
export type ExportReportToPDFMutationError = ErrorType<ProblemDetails>;

export const useExportReportToPDF = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportToPDFHook>>>,
        TError,
        { data: WhitePaperReportExportRequest[] },
        TContext
    >;
}) => {
    const mutationOptions = useExportReportToPDFMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useViewerFormPDFHook = () => {
    const viewerFormPDF = useCustomInstance<Blob>();

    return (reviewFormDataRequest: ReviewFormDataRequest) => {
        return viewerFormPDF({
            url: `/Report/ViewerFormPDF`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reviewFormDataRequest,
            responseType: 'blob',
        });
    };
};

export const useViewerFormPDFMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useViewerFormPDFHook>>>,
        TError,
        { data: ReviewFormDataRequest },
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useViewerFormPDFHook>>>, TError, { data: ReviewFormDataRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const viewerFormPDF = useViewerFormPDFHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useViewerFormPDFHook>>>, { data: ReviewFormDataRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return viewerFormPDF(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ViewerFormPDFMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useViewerFormPDFHook>>>>;
export type ViewerFormPDFMutationBody = ReviewFormDataRequest;
export type ViewerFormPDFMutationError = ErrorType<ProblemDetails>;

export const useViewerFormPDF = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useViewerFormPDFHook>>>,
        TError,
        { data: ReviewFormDataRequest },
        TContext
    >;
}) => {
    const mutationOptions = useViewerFormPDFMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetFormTOCHook = () => {
    const getFormTOC = useCustomInstance<FormTOC>();

    return (getFormTOCRequest: GetFormTOCRequest) => {
        return getFormTOC({
            url: `/Report/GetFormTOC`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: getFormTOCRequest,
        });
    };
};

export const useGetFormTOCMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetFormTOCHook>>>, TError, { data: GetFormTOCRequest }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetFormTOCHook>>>, TError, { data: GetFormTOCRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const getFormTOC = useGetFormTOCHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetFormTOCHook>>>, { data: GetFormTOCRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return getFormTOC(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetFormTOCMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFormTOCHook>>>>;
export type GetFormTOCMutationBody = GetFormTOCRequest;
export type GetFormTOCMutationError = ErrorType<unknown>;

export const useGetFormTOC = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetFormTOCHook>>>, TError, { data: GetFormTOCRequest }, TContext>;
}) => {
    const mutationOptions = useGetFormTOCMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useSaveAmountsHook = () => {
    const saveAmounts = useCustomInstance<SaveAmountsResponse>();

    return (saveAmountsRequest: SaveAmountsRequest) => {
        return saveAmounts({
            url: `/Report/SaveAmounts`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: saveAmountsRequest,
        });
    };
};

export const useSaveAmountsMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveAmountsHook>>>,
        TError,
        { data: SaveAmountsRequest },
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveAmountsHook>>>, TError, { data: SaveAmountsRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const saveAmounts = useSaveAmountsHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSaveAmountsHook>>>, { data: SaveAmountsRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return saveAmounts(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type SaveAmountsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveAmountsHook>>>>;
export type SaveAmountsMutationBody = SaveAmountsRequest;
export type SaveAmountsMutationError = ErrorType<ProblemDetails>;

export const useSaveAmounts = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveAmountsHook>>>,
        TError,
        { data: SaveAmountsRequest },
        TContext
    >;
}) => {
    const mutationOptions = useSaveAmountsMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetCalculationExecutionStatusHook = () => {
    const getCalculationExecutionStatus = useCustomInstance<CalculationExecutionStatus[]>();

    return (getCalculationExecutionStatusRequest: GetCalculationExecutionStatusRequest) => {
        return getCalculationExecutionStatus({
            url: `/Report/GetCalculationExecutionStatus`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: getCalculationExecutionStatusRequest,
        });
    };
};

export const useGetCalculationExecutionStatusMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusHook>>>,
        TError,
        { data: GetCalculationExecutionStatusRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusHook>>>,
    TError,
    { data: GetCalculationExecutionStatusRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const getCalculationExecutionStatus = useGetCalculationExecutionStatusHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusHook>>>,
        { data: GetCalculationExecutionStatusRequest }
    > = (props) => {
        const { data } = props ?? {};

        return getCalculationExecutionStatus(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetCalculationExecutionStatusMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusHook>>>
>;
export type GetCalculationExecutionStatusMutationBody = GetCalculationExecutionStatusRequest;
export type GetCalculationExecutionStatusMutationError = ErrorType<unknown>;

export const useGetCalculationExecutionStatus = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusHook>>>,
        TError,
        { data: GetCalculationExecutionStatusRequest },
        TContext
    >;
}) => {
    const mutationOptions = useGetCalculationExecutionStatusMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetCalculationExecutionStatusesHook = () => {
    const getCalculationExecutionStatuses = useCustomInstance<CalculationExecutionStatuses>();

    return () => {
        return getCalculationExecutionStatuses({ url: `/Report/GetCalculationExecutionStatuses`, method: 'post' });
    };
};

export const useGetCalculationExecutionStatusesMutationOptions = <
    TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusesHook>>>,
        TError,
        TVariables,
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusesHook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const getCalculationExecutionStatuses = useGetCalculationExecutionStatusesHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusesHook>>>, TVariables> = () => {
        return getCalculationExecutionStatuses();
    };

    return { mutationFn, ...mutationOptions };
};

export type GetCalculationExecutionStatusesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusesHook>>>
>;

export type GetCalculationExecutionStatusesMutationError = ErrorType<unknown>;

export const useGetCalculationExecutionStatuses = <TError = ErrorType<unknown>, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCalculationExecutionStatusesHook>>>,
        TError,
        TVariables,
        TContext
    >;
}) => {
    const mutationOptions = useGetCalculationExecutionStatusesMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useExportReportCellAuditToExcelHook = () => {
    const exportReportCellAuditToExcel = useCustomInstance<Blob>();

    return (reportCellAuditExportRequest: ReportCellAuditExportRequest) => {
        return exportReportCellAuditToExcel({
            url: `/Report/ExportReportCellAuditToExcel`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportCellAuditExportRequest,
            responseType: 'blob',
        });
    };
};

export const useExportReportCellAuditToExcelMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportCellAuditToExcelHook>>>,
        TError,
        { data: ReportCellAuditExportRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportReportCellAuditToExcelHook>>>,
    TError,
    { data: ReportCellAuditExportRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const exportReportCellAuditToExcel = useExportReportCellAuditToExcelHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportReportCellAuditToExcelHook>>>,
        { data: ReportCellAuditExportRequest }
    > = (props) => {
        const { data } = props ?? {};

        return exportReportCellAuditToExcel(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportReportCellAuditToExcelMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useExportReportCellAuditToExcelHook>>>
>;
export type ExportReportCellAuditToExcelMutationBody = ReportCellAuditExportRequest;
export type ExportReportCellAuditToExcelMutationError = ErrorType<ProblemDetails>;

export const useExportReportCellAuditToExcel = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportCellAuditToExcelHook>>>,
        TError,
        { data: ReportCellAuditExportRequest },
        TContext
    >;
}) => {
    const mutationOptions = useExportReportCellAuditToExcelMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useExportReportRowTraceToExcelHook = () => {
    const exportReportRowTraceToExcel = useCustomInstance<Blob>();

    return (reportRowTraceExportRequest: ReportRowTraceExportRequest) => {
        return exportReportRowTraceToExcel({
            url: `/Report/ExportReportRowTraceToExcel`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportRowTraceExportRequest,
            responseType: 'blob',
        });
    };
};

export const useExportReportRowTraceToExcelMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportRowTraceToExcelHook>>>,
        TError,
        { data: ReportRowTraceExportRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportReportRowTraceToExcelHook>>>,
    TError,
    { data: ReportRowTraceExportRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const exportReportRowTraceToExcel = useExportReportRowTraceToExcelHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportReportRowTraceToExcelHook>>>,
        { data: ReportRowTraceExportRequest }
    > = (props) => {
        const { data } = props ?? {};

        return exportReportRowTraceToExcel(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportReportRowTraceToExcelMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useExportReportRowTraceToExcelHook>>>
>;
export type ExportReportRowTraceToExcelMutationBody = ReportRowTraceExportRequest;
export type ExportReportRowTraceToExcelMutationError = ErrorType<ProblemDetails>;

export const useExportReportRowTraceToExcel = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportReportRowTraceToExcelHook>>>,
        TError,
        { data: ReportRowTraceExportRequest },
        TContext
    >;
}) => {
    const mutationOptions = useExportReportRowTraceToExcelMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetAdjustmentAccountAssociationsHook = () => {
    const getAdjustmentAccountAssociations = useCustomInstance<AdjustmentAccountAndAmountAssociationsResponse>();

    return (adjustmentAccountAndAmountAssociationRequest: AdjustmentAccountAndAmountAssociationRequest) => {
        return getAdjustmentAccountAssociations({
            url: `/Report/GetAdjustmentAccountAssociations`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: adjustmentAccountAndAmountAssociationRequest,
        });
    };
};

export const useGetAdjustmentAccountAssociationsMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAdjustmentAccountAssociationsHook>>>,
        TError,
        { data: AdjustmentAccountAndAmountAssociationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAdjustmentAccountAssociationsHook>>>,
    TError,
    { data: AdjustmentAccountAndAmountAssociationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const getAdjustmentAccountAssociations = useGetAdjustmentAccountAssociationsHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetAdjustmentAccountAssociationsHook>>>,
        { data: AdjustmentAccountAndAmountAssociationRequest }
    > = (props) => {
        const { data } = props ?? {};

        return getAdjustmentAccountAssociations(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetAdjustmentAccountAssociationsMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetAdjustmentAccountAssociationsHook>>>
>;
export type GetAdjustmentAccountAssociationsMutationBody = AdjustmentAccountAndAmountAssociationRequest;
export type GetAdjustmentAccountAssociationsMutationError = ErrorType<ProblemDetails>;

export const useGetAdjustmentAccountAssociations = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAdjustmentAccountAssociationsHook>>>,
        TError,
        { data: AdjustmentAccountAndAmountAssociationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useGetAdjustmentAccountAssociationsMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetEnterpriseHook = () => {
    const getEnterprise = useCustomInstance<Enterprise>();

    return (signal?: AbortSignal) => {
        return getEnterprise({ url: `/Report/GetEnterprise`, method: 'get', signal });
    };
};

export const getGetEnterpriseQueryKey = () => [`/Report/GetEnterprise`] as const;

export const useGetEnterpriseQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetEnterpriseHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEnterpriseHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEnterpriseHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetEnterpriseQueryKey();

    const getEnterprise = useGetEnterpriseHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEnterpriseHook>>>> = ({ signal }) => getEnterprise(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetEnterpriseQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEnterpriseHook>>>>;
export type GetEnterpriseQueryError = ErrorType<unknown>;

export const useGetEnterprise = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetEnterpriseHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEnterpriseHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetEnterpriseQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useAddReturnsToExistingFolderHook = () => {
    const addReturnsToExistingFolder = useCustomInstance<unknown>();

    return (addReturnsToExistingFolderRequest: AddReturnsToExistingFolderRequest) => {
        return addReturnsToExistingFolder({
            url: `/Report/AddReturnsToExistingFolder`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: addReturnsToExistingFolderRequest,
        });
    };
};

export const useAddReturnsToExistingFolderMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddReturnsToExistingFolderHook>>>,
        TError,
        { data: AddReturnsToExistingFolderRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddReturnsToExistingFolderHook>>>,
    TError,
    { data: AddReturnsToExistingFolderRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const addReturnsToExistingFolder = useAddReturnsToExistingFolderHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddReturnsToExistingFolderHook>>>,
        { data: AddReturnsToExistingFolderRequest }
    > = (props) => {
        const { data } = props ?? {};

        return addReturnsToExistingFolder(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddReturnsToExistingFolderMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useAddReturnsToExistingFolderHook>>>
>;
export type AddReturnsToExistingFolderMutationBody = AddReturnsToExistingFolderRequest;
export type AddReturnsToExistingFolderMutationError = ErrorType<ProblemDetails>;

export const useAddReturnsToExistingFolder = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddReturnsToExistingFolderHook>>>,
        TError,
        { data: AddReturnsToExistingFolderRequest },
        TContext
    >;
}) => {
    const mutationOptions = useAddReturnsToExistingFolderMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useDeleteReturnsFromFolderHook = () => {
    const deleteReturnsFromFolder = useCustomInstance<unknown>();

    return (deleteReturnsFromFolderRequest: DeleteReturnsFromFolderRequest) => {
        return deleteReturnsFromFolder({
            url: `/Report/DeleteReturnsFromFolder`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: deleteReturnsFromFolderRequest,
        });
    };
};

export const useDeleteReturnsFromFolderMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReturnsFromFolderHook>>>,
        TError,
        { data: DeleteReturnsFromFolderRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteReturnsFromFolderHook>>>,
    TError,
    { data: DeleteReturnsFromFolderRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteReturnsFromFolder = useDeleteReturnsFromFolderHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteReturnsFromFolderHook>>>,
        { data: DeleteReturnsFromFolderRequest }
    > = (props) => {
        const { data } = props ?? {};

        return deleteReturnsFromFolder(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteReturnsFromFolderMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteReturnsFromFolderHook>>>>;
export type DeleteReturnsFromFolderMutationBody = DeleteReturnsFromFolderRequest;
export type DeleteReturnsFromFolderMutationError = ErrorType<ProblemDetails>;

export const useDeleteReturnsFromFolder = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReturnsFromFolderHook>>>,
        TError,
        { data: DeleteReturnsFromFolderRequest },
        TContext
    >;
}) => {
    const mutationOptions = useDeleteReturnsFromFolderMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useCreateFolderWithTaxReturnsHook = () => {
    const createFolderWithTaxReturns = useCustomInstance<unknown>();

    return (createFolderWithTaxReturnsRequest: CreateFolderWithTaxReturnsRequest) => {
        return createFolderWithTaxReturns({
            url: `/Report/CreateFolderWithTaxReturns`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: createFolderWithTaxReturnsRequest,
        });
    };
};

export const useCreateFolderWithTaxReturnsMutationOptions = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateFolderWithTaxReturnsHook>>>,
        TError,
        { data: CreateFolderWithTaxReturnsRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateFolderWithTaxReturnsHook>>>,
    TError,
    { data: CreateFolderWithTaxReturnsRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const createFolderWithTaxReturns = useCreateFolderWithTaxReturnsHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateFolderWithTaxReturnsHook>>>,
        { data: CreateFolderWithTaxReturnsRequest }
    > = (props) => {
        const { data } = props ?? {};

        return createFolderWithTaxReturns(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateFolderWithTaxReturnsMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useCreateFolderWithTaxReturnsHook>>>
>;
export type CreateFolderWithTaxReturnsMutationBody = CreateFolderWithTaxReturnsRequest;
export type CreateFolderWithTaxReturnsMutationError = ErrorType<ProblemDetails>;

export const useCreateFolderWithTaxReturns = <TError = ErrorType<ProblemDetails>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateFolderWithTaxReturnsHook>>>,
        TError,
        { data: CreateFolderWithTaxReturnsRequest },
        TContext
    >;
}) => {
    const mutationOptions = useCreateFolderWithTaxReturnsMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetAmountsEntryOptionsHook = () => {
    const getAmountsEntryOptions = useCustomInstance<AmountDataEntryOptions>();

    return (params?: GetAmountsEntryOptionsParams, signal?: AbortSignal) => {
        return getAmountsEntryOptions({ url: `/Report/GetAmountsEntryOptions`, method: 'get', params, signal });
    };
};

export const getGetAmountsEntryOptionsQueryKey = (params?: GetAmountsEntryOptionsParams) =>
    [`/Report/GetAmountsEntryOptions`, ...(params ? [params] : [])] as const;

export const useGetAmountsEntryOptionsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAmountsEntryOptionsHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetAmountsEntryOptionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntryOptionsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntryOptionsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAmountsEntryOptionsQueryKey(params);

    const getAmountsEntryOptions = useGetAmountsEntryOptionsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntryOptionsHook>>>> = ({ signal }) =>
        getAmountsEntryOptions(params, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAmountsEntryOptionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntryOptionsHook>>>>;
export type GetAmountsEntryOptionsQueryError = ErrorType<ProblemDetails>;

export const useGetAmountsEntryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAmountsEntryOptionsHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetAmountsEntryOptionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntryOptionsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAmountsEntryOptionsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetAmountsEntriesHook = () => {
    const getAmountsEntries = useCustomInstance<AmountsEntriesResponse>();

    return (params?: GetAmountsEntriesParams, signal?: AbortSignal) => {
        return getAmountsEntries({ url: `/Report/GetAmountsEntries`, method: 'get', params, signal });
    };
};

export const getGetAmountsEntriesQueryKey = (params?: GetAmountsEntriesParams) =>
    [`/Report/GetAmountsEntries`, ...(params ? [params] : [])] as const;

export const useGetAmountsEntriesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAmountsEntriesHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetAmountsEntriesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntriesHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntriesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAmountsEntriesQueryKey(params);

    const getAmountsEntries = useGetAmountsEntriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntriesHook>>>> = ({ signal }) =>
        getAmountsEntries(params, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAmountsEntriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntriesHook>>>>;
export type GetAmountsEntriesQueryError = ErrorType<ProblemDetails>;

export const useGetAmountsEntries = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAmountsEntriesHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetAmountsEntriesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAmountsEntriesHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAmountsEntriesQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useSaveTaxReturnsHook = () => {
    const saveTaxReturns = useCustomInstance<TaxReturnSaveRecordsResponse>();

    return (taxReturnSaveRecordsRequest: TaxReturnSaveRecordsRequest) => {
        return saveTaxReturns({
            url: `/Report/SaveTaxReturns`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: taxReturnSaveRecordsRequest,
        });
    };
};

export const useSaveTaxReturnsMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnsHook>>>,
        TError,
        { data: TaxReturnSaveRecordsRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnsHook>>>,
    TError,
    { data: TaxReturnSaveRecordsRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const saveTaxReturns = useSaveTaxReturnsHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnsHook>>>,
        { data: TaxReturnSaveRecordsRequest }
    > = (props) => {
        const { data } = props ?? {};

        return saveTaxReturns(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type SaveTaxReturnsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnsHook>>>>;
export type SaveTaxReturnsMutationBody = TaxReturnSaveRecordsRequest;
export type SaveTaxReturnsMutationError = ErrorType<unknown>;

export const useSaveTaxReturns = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveTaxReturnsHook>>>,
        TError,
        { data: TaxReturnSaveRecordsRequest },
        TContext
    >;
}) => {
    const mutationOptions = useSaveTaxReturnsMutationOptions(options);

    return useMutation(mutationOptions);
};
