import styled from 'styled-components';
import DefaultGrid from '../grid/DefaultGrid';

export const StyledTable = styled(DefaultGrid)`
    .e-headercell {
        background-color: ${(props: any) => props.theme.customPalette.themeLighter} !important;
        font-weight: bold;
        font-size: var(--header-font-size);
        border: none;
    }

    .e-columnheader {
        height: 36px !important;
    }

    .e-headertext {
        font-size: var(--header-font-size);
        color: ${(props: any) => props.theme.customPalette.blueDark};
        font-weight: 600;
        text-align: center;
    }

    th.e-headercell[aria-sort='ascending'] .e-headertext,
    th.e-headercell[aria-sort='descending'] .e-headertext,
    th.e-headercell[aria-sort='ascending'] .e-sortfilterdiv,
    th.e-headercell[aria-sort='descending'] .e-sortfilterdiv {
        color: ${(props: any) => props.theme.customPalette.themePrimary};
    }

    .e-icon-ascending::before,
    .e-icon-descending::before {
        color: ${(props: any) => props.theme.customPalette.themePrimary};
    }

    .e-icon-ascending::before {
        content: '\\e776';
    }

    .e-icon-descending::before {
        content: '\\e729';
    }

    .e-checkbox-wrapper.e-css {
        padding: unset;
    }

    .e-checkbox-wrapper .e-frame.e-icons {
        border-radius: 6px;
        border-color: ${(props: any) => props.theme.customPalette.themePrimary};
    }

    .e-checkbox-wrapper .e-frame.e-icons.e-check {
        background-color: ${(props: any) => props.theme.customPalette.themePrimary};
    }

    .e-checkbox-wrapper .e-frame.e-icons.e-stop {
        color: ${(props: any) => props.theme.customPalette.themePrimary};
    }

    .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
        margin-bottom: -5px;
    }

    &.e-grid.e-control .e-row .e-rowcell {
        background-color: ${(props: any) => props.theme.brandPalette.white};
        color: ${(props: any) => props.theme.customPalette.blueDark};
        font-size: 14px;
        border: none;
        font-weight: 600;
        border-bottom: 1px solid ${(props: any) => props.theme.customPalette.statusCoolLightGrey};
        box-sizing: border-box;
        height: 100%;
    }

    .highlighted-search {
        background-color: ${(props: any) => props.theme.customPalette.yellowDark};
    }
`;
