import { IAccountAmountWhitePaperRow } from '../components/sidebar/ReportDrillDownUtils';
import { IWhitePaperReportChanges } from '../model';

export interface IAdjustmentAccountAmountDialogState {
    jurisdiction: string;
    period: string;
    entity: string;
    adjustmentCode: string;
    case: string;
    location: string;
    show: boolean;
    rowNumber: number;
    columnId: number;
    changeTrackingData: IWhitePaperReportChanges;
    taxReturnKey: number;
    isLoading: boolean;
    saveButtonIsDisabled: boolean;
}
export enum AdjustmentAccountAmountAction {
    ModalOpen,
    ModalClose,
    AssociationsFetchSuccess,
    CellEdited,
}

export type AdjustmentAccountAmountReducer = (
    state: IAdjustmentAccountAmountDialogState,
    action: AdjustmentAccountAmountDialogReducerActionParams
) => IAdjustmentAccountAmountDialogState;

export type AdjustmentAccountAmountDialogReducerActionParams = {
    updateChangeTrackingData?: (changeTrackingData: IWhitePaperReportChanges) => void;
    changeTrackingData?: IWhitePaperReportChanges;
    type: AdjustmentAccountAmountAction;
    row?: IAccountAmountWhitePaperRow;
    displayRowNumber?: string;
};

export function adjustmentAccountAmountDialogStateReducer(
    state: IAdjustmentAccountAmountDialogState,
    action: AdjustmentAccountAmountDialogReducerActionParams
): IAdjustmentAccountAmountDialogState {
    switch (action.type) {
        case AdjustmentAccountAmountAction.ModalOpen:
            return {
                ...state,
                case: action.row?.dataObject.case,
                adjustmentCode: action.row?.dataObject.adjustmentCode! ?? action.row?.parent?.adjustmentCode!,
                entity: action.row?.dataObject.corporation!,
                period: action.row?.dataObject.year!,
                jurisdiction: action.row?.dataObject.jurisdiction!,
                location: action.row?.dataObject.location!,
                rowNumber: Number.parseInt(action.displayRowNumber!),
                isLoading: true,
                saveButtonIsDisabled: true,
                show: true,
            };
        case AdjustmentAccountAmountAction.ModalClose:
            if (action.changeTrackingData && action.updateChangeTrackingData) {
                action.updateChangeTrackingData(action.changeTrackingData);
            }
            return {
                ...state,
                case: '',
                adjustmentCode: '',
                entity: '',
                period: '',
                jurisdiction: '',
                location: '',
                rowNumber: 0,
                show: false,
            };
        case AdjustmentAccountAmountAction.AssociationsFetchSuccess:
            return { ...state, isLoading: false };
        case AdjustmentAccountAmountAction.CellEdited:
            return { ...state, saveButtonIsDisabled: false };
        default:
            throw new Error();
    }
}
