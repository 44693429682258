import { RouteObject } from 'react-router';
import App from './App';
import Analytics from './components/analytics/Analytics';
import CompareReportLines from './components/tables/compare-reports-table/CompareReportLines';
import ReportLines from './components/tables/report-lines-table/ReportLines';
import ReportsTable from './components/tables/reports-table/ReportsTable';

const routesConfig: RouteObject[] = [
    {
        path: '/',
        Component: App,
        children: [
            { path: '/', Component: ReportsTable },
            { path: '/analytics', Component: Analytics },
            { path: '/compare/:reportId/:versionId', Component: CompareReportLines },
            { path: '/view/:reportId', Component: ReportLines },
            { path: '/view/:reportId/forms/:pageId/:pageName', Component: ReportLines },
            { path: '/view/:reportId/forms', Component: ReportLines },
            { path: '/view/:reportId/comments', Component: ReportLines },
            { path: '/view/:reportId/comments/:commentId', Component: ReportLines },
            { path: '/view/:reportId/cell/:columnId/:rowId/comments', Component: ReportLines },
            { path: '/view/:reportId/cell/:columnId/:rowId/comments/:cellCommentId', Component: ReportLines },
        ],
    },
];

export default routesConfig;
