import * as React from 'react';
import { SearchTableContext } from '../contexts/searchTableContext';

export function useSearchTable() {
    const context = React.useContext(SearchTableContext);
    if (!context) {
        throw new Error('useSearchTable must be wrapped in a SearchTableContextProvider');
    }
    return context;
}
