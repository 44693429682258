import { useBoolean } from '@fluentui/react-hooks';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import React, { createContext, useEffect, useState } from 'react';
import useSessionStorageState from 'use-session-storage-state';
import {
    useGetCalculationExecutionStatus,
    useGetCalculationExecutionStatuses,
    useScheduleTaxReturnCalculation,
} from '../api/report/report';
import { useGetClaimsForCurrentUser } from '../api/user-claims/user-claims';
import { IExecutionStatusStorage } from '../data-types/IExecutionStatusStorage';
import { CalculationExecutionStatus, CalculationExecutionStatusCode } from '../model';
import { CALCULATION_REPORT_LIST_NAME } from '../utils/BrowserStorageKeys.constants';
import { shouldInvalidateDependentQueries } from '../utils/TaxReturnUtils';
import { setConditionalTimeout } from '../utils/timerUtils';

export interface ICalculationExecutionStatusContextValues {
    taxReturnCalculationStatusList?: IExecutionStatusStorage[];
    isLoadingRecalculation: boolean;
    isErrorRecalculation: boolean;
    completeReportDependencies?: Record<number, QueryKey[]>;
    insertTaxReturnCalculationReport: (taxReturnCalculation: IExecutionStatusStorage) => void;
    updateTaxReturnCalculationReport: (taxReturnCalculation: IExecutionStatusStorage) => void;
    removeTaxReturnCalculationReport: (taxReturnKey: number) => void;
    isReportExecuting: (returnKey: number) => boolean;
    getReportStatus: (returnKey: number) => IExecutionStatusStorage | undefined;
    updateTaxReturnProcessingStatus: (returnKey: number) => void;
    updateAllObservedTaxReturnProcessingStatus: () => void;
    clearAllCompletedReports: () => void;
    rerunCalculationReport: (returnKey: number, taxReturnItemId: string, handleError?: boolean, onSuccess?: (data: any) => void) => void;
    registerGlobalDependency: (triggerName: string, triggerFunction: (taxReturnItemKey: number) => void) => void;
    registerDependency: (taxReturnKey: number, queryKey: QueryKey) => void;
    getReportStatusByReportId: (reportId: number) => CalculationExecutionStatus | null;
    fetchAllCalculationExecutionStatuses: () => Promise<{ [key: string]: CalculationExecutionStatus } | undefined>;
    clearReportsWithoutJobid: () => void;
}

export const TaxReturnCalculationContext = createContext<ICalculationExecutionStatusContextValues | null>(null);

export function TaxReturnCalculationProvider(props: { value?: ICalculationExecutionStatusContextValues; children?: React.ReactNode }) {
    const userClaims = useGetClaimsForCurrentUser();
    const queryClient = useQueryClient();
    const { mutateAsync } = useGetCalculationExecutionStatus();
    const {
        isLoading: isPostLoading,
        isError: isPostError,
        error,
        mutate: postScheduleReportCalculation,
    } = useScheduleTaxReturnCalculation();
    const { mutateAsync: getAllCalculationExecutionStatuses } = useGetCalculationExecutionStatuses();
    const [dataFromStorageReady, { setTrue: finishedLoadingFromStorage }] = useBoolean(false);

    const environmentId = userClaims?.data?.environmentId || '';
    const userId = userClaims?.data?.userId || '';
    const isProcessingReportStorageName = `${environmentId}-${userId}_${CALCULATION_REPORT_LIST_NAME}`;
    const [isProcessingReportsStorageValue, setIsProcessingReportsStorageValue] = useSessionStorageState(isProcessingReportStorageName, {
        defaultValue: [] as IExecutionStatusStorage[],
    });
    const observedJobIds = isProcessingReportsStorageValue
        .filter((status) => status.jobId && status?.statusCode === CalculationExecutionStatusCode.Executing)
        .map((item) => item.jobId ?? 0);
    const [completeReportDependencies, setCompleteReportDependencies] = useState<Record<number, QueryKey[]>>([]);
    const [globalDependencyTriggers, setGlobalDependencyTriggers] = useState<Record<string, Function>>({});
    const [latestCalculationExecutionStatusMap, setLatestCalculationExecutionStatusMap] = useState<{
        [key: string]: CalculationExecutionStatus;
    } | null>(null);

    const updateTaxReturnProcessingStatus = (reportKey: number) => {
        const jobId = isProcessingReportsStorageValue.find((item) => item.taxReturnItemKey === reportKey)?.jobId;

        if (jobId) {
            mutateAsync({ data: { jobIds: [jobId] } }).then((response) => {
                updateTaxReturnCalculationReport(response[0]);
            });
        }
    };

    const updateAllObservedTaxReturnProcessingStatus = () => {
        mutateAsync({ data: { jobIds: observedJobIds } }).then((response) => {
            updateAllTaxReturnCalculationReports(response);
        });
    };

    if (!dataFromStorageReady && isProcessingReportsStorageValue) {
        // The session storage library does not trigger an event when it finishes loading
        // And it takes a couple of re-renders, in which we try to clear the reports without jobid
        // Since the data did not load yet, nothing happens that's why we're waiting
        setTimeout(() => {
            finishedLoadingFromStorage();
        }, 250);
    }

    useEffect(() => {
        if (dataFromStorageReady) {
            clearReportsWithoutJobid();
        }
    }, [dataFromStorageReady]);

    useEffect(() => {
        const cleanupFunction = setConditionalTimeout(updateAllObservedTaxReturnProcessingStatus, observedJobIds.length > 0, 5000);

        return cleanupFunction;
    }, [observedJobIds]);

    const clearAllCompletedReports = () => {
        const filteredList = isProcessingReportsStorageValue?.filter(
            (item: IExecutionStatusStorage) => item.statusCode === CalculationExecutionStatusCode.Executing
        );
        setIsProcessingReportsStorageValue(filteredList || []);
    };

    const updateAllTaxReturnCalculationReports = (taxReturnCalculations: IExecutionStatusStorage[]) => {
        taxReturnCalculations.forEach((taxReturnCalculation) => {
            updateTaxReturnCalculationReport(taxReturnCalculation);
        });
    };

    const updateTaxReturnCalculationReport = (taxReturnCalculation: IExecutionStatusStorage) => {
        const existingItem = isProcessingReportsStorageValue.find((item) => item && item.jobId === taxReturnCalculation.jobId);

        if (existingItem) {
            if (existingItem.statusCode !== taxReturnCalculation.statusCode) {
                invalidateDependentQueries(taxReturnCalculation);

                setIsProcessingReportsStorageValue((prevList) =>
                    prevList.map((item) =>
                        item.jobId === taxReturnCalculation.jobId
                            ? {
                                  ...item,
                                  statusCode: taxReturnCalculation.statusCode,
                              }
                            : item
                    )
                );
            }
        } else {
            const filteredExecutingJobs = isProcessingReportsStorageValue.filter(
                (item) => item.taxReturnItemKey !== taxReturnCalculation.taxReturnItemKey
            );
            setIsProcessingReportsStorageValue([...filteredExecutingJobs, taxReturnCalculation]);
        }
    };

    const invalidateDependentQueries = (taxReturnCalculation: IExecutionStatusStorage) => {
        const taxReturnItemKey = isProcessingReportsStorageValue.find(
            (item) => item.jobId === taxReturnCalculation.jobId
        )?.taxReturnItemKey;

        if (
            taxReturnItemKey !== undefined &&
            shouldInvalidateDependentQueries(taxReturnItemKey, taxReturnCalculation, isProcessingReportsStorageValue)
        ) {
            completeReportDependencies[taxReturnItemKey]?.forEach((key) => {
                queryClient.invalidateQueries(key);
            });
        }

        if (globalDependencyTriggers) {
            for (const triggerFunction of Object.values(globalDependencyTriggers)) {
                triggerFunction(taxReturnItemKey);
            }
        }
    };

    const insertTaxReturnCalculationReport = (taxReturnCalculation: IExecutionStatusStorage) => {
        if (
            !(isProcessingReportsStorageValue.filter((item) => item.taxReturnItemKey === taxReturnCalculation.taxReturnItemKey).length > 0)
        ) {
            setIsProcessingReportsStorageValue([...isProcessingReportsStorageValue, taxReturnCalculation]);
        }
    };

    const removeTaxReturnCalculationReport = (taxReturnKey: number) => {
        if (isProcessingReportsStorageValue.filter((item) => item.taxReturnItemKey === taxReturnKey).length > 0) {
            setIsProcessingReportsStorageValue([
                ...isProcessingReportsStorageValue.filter((item: IExecutionStatusStorage) => item.taxReturnItemKey !== taxReturnKey),
            ]);
        }
    };

    const isReportExecuting = (returnKey: number): boolean => {
        return isProcessingReportsStorageValue.some(
            (item: IExecutionStatusStorage) =>
                item?.taxReturnItemKey === returnKey && item.statusCode === CalculationExecutionStatusCode.Executing
        );
    };

    const getReportStatus = (returnKey: number): IExecutionStatusStorage | undefined => {
        return isProcessingReportsStorageValue?.find((item: IExecutionStatusStorage) => item?.taxReturnItemKey === returnKey);
    };

    const rerunCalculationReport = async (
        returnKey: number,
        taxReturnItemId: string,
        handleError?: boolean,
        onSuccess?: (data: any) => void
    ) => {
        try {
            const taxReturn: IExecutionStatusStorage = {
                taxReturnItemKey: returnKey,
                jobId: undefined,
                statusCode: CalculationExecutionStatusCode.Executing,
            };

            updateTaxReturnCalculationReport(taxReturn);

            postScheduleReportCalculation(
                {
                    data: { reportId: taxReturnItemId },
                },
                {
                    onError: () => {
                        removeTaxReturnCalculationReport(returnKey);
                        if (handleError && isPostError) {
                            let errorMessage;
                            if (error && error.message) {
                                errorMessage = error?.request?.response;
                            }
                            alert(errorMessage);
                        }
                    },
                    onSuccess: (data: any) => {
                        updateTaxReturnCalculationReport({
                            taxReturnItemKey: returnKey,
                            jobId: data?.jobId,
                            statusCode: CalculationExecutionStatusCode.Executing,
                        });
                        if (onSuccess) {
                            onSuccess(data);
                        }
                    },
                }
            );
        } catch (error: any) {
            if (error) {
                throw new Error(error?.request?.response);
            }
        }
    };

    const registerGlobalDependency = (triggerName: string, triggerFunction: (taxReturnItemKey: number) => void): void => {
        setGlobalDependencyTriggers((prevState) => {
            return {
                ...prevState,
                [triggerName]: triggerFunction,
            };
        });
    };

    const registerDependency = (taxReturnKey: number, queryKey: QueryKey): void => {
        const existingRecordWithKey = completeReportDependencies[taxReturnKey];

        if (!existingRecordWithKey) {
            setCompleteReportDependencies((prevState) => {
                return {
                    ...prevState,
                    [taxReturnKey]: [queryKey],
                };
            });
        } else {
            if (!existingRecordWithKey.some((key) => areQueriesEqual(key, queryKey))) {
                setCompleteReportDependencies((prevState) => {
                    return {
                        ...prevState,
                        [taxReturnKey]: [...prevState[taxReturnKey], queryKey],
                    };
                });
            }
        }
    };

    const areQueriesEqual = (queryKey1: QueryKey, queryKey2: QueryKey): boolean => {
        return JSON.stringify(queryKey1) === JSON.stringify(queryKey2);
    };

    const getReportStatusByReportId = (reportId: number): CalculationExecutionStatus | null => {
        if (latestCalculationExecutionStatusMap) {
            return latestCalculationExecutionStatusMap[reportId] ?? null;
        }

        return null;
    };

    const fetchAllCalculationExecutionStatuses = async () => {
        const latestCalculationStatus = await getAllCalculationExecutionStatuses();

        if (latestCalculationStatus?.statuses) {
            setLatestCalculationExecutionStatusMap(latestCalculationStatus.statuses);
            return latestCalculationStatus.statuses;
        }

        return undefined;
    };

    const clearReportsWithoutJobid = () => {
        const filteredList = isProcessingReportsStorageValue?.filter((item: IExecutionStatusStorage) => item.jobId !== undefined);
        setIsProcessingReportsStorageValue(filteredList || []);
    };

    return (
        <TaxReturnCalculationContext.Provider
            value={
                props.value ?? {
                    taxReturnCalculationStatusList: isProcessingReportsStorageValue,
                    isLoadingRecalculation: isPostLoading,
                    isErrorRecalculation: isPostError,
                    completeReportDependencies,
                    insertTaxReturnCalculationReport,
                    isReportExecuting,
                    getReportStatus,
                    removeTaxReturnCalculationReport,
                    updateTaxReturnCalculationReport,
                    updateTaxReturnProcessingStatus,
                    updateAllObservedTaxReturnProcessingStatus,
                    clearAllCompletedReports,
                    rerunCalculationReport,
                    registerGlobalDependency,
                    registerDependency,
                    getReportStatusByReportId,
                    fetchAllCalculationExecutionStatuses,
                    clearReportsWithoutJobid,
                }
            }
        >
            {props.children}
        </TaxReturnCalculationContext.Provider>
    );
}
