import { IStyle, mergeStyles } from '@fluentui/react';

export function mergeStylesOnDemand(...styles: IStyle[]): () => string {
    let className: string | null = null;

    return (): string => {
        if (className === null) {
            className = mergeStyles(...styles);
        }

        return className;
    };
}
