import { getBffUrl } from './EnvironmentUtils';

const queryParams = new URLSearchParams();
queryParams.append('redirectPath', window.location.pathname + window.location.search);

const REACT_APP_API_BASE_URL = getBffUrl(window.location.host);

export const LOGIN_URL = REACT_APP_API_BASE_URL + `/bff/login?` + encodeURI(queryParams.toString());
export const VIEW_REPORT_URL = '/view/';
export const COMPARE_REPORT_URL = '/compare/';
export const REPORTS_TABLE_URL = '/';
export const AVAILABLE_REPORT_ID_TO_VIEW = 183;
export const getLogoutUrl = (sessionId: string): string => {
    return REACT_APP_API_BASE_URL + '/bff/logout?sid=' + sessionId;
};
