export function wrapObject(objectToWrap: any, makeImmutable: boolean = false): any {
    if (objectToWrap === null || objectToWrap === undefined) {
        return null;
    }

    if (Object(objectToWrap) !== objectToWrap) {
        throw new Error('objectToWrap is not an object');
    }

    let descriptors: PropertyDescriptorMap;
    const wrapper: any = {};

    // Get descriptors owned by the object.
    descriptors = Object.getOwnPropertyDescriptors(objectToWrap);

    for (const key of Object.keys(descriptors)) {
        let descriptor: PropertyDescriptor = descriptors[key];

        Object.defineProperty(wrapper, key, constructWrapperPropertyDescriptor(objectToWrap, key, descriptor, makeImmutable));
    }

    // Get descriptors owned by the object's prototype chain.
    for (
        let prototype = Object.getPrototypeOf(objectToWrap);
        Object.getPrototypeOf(prototype) !== null;
        prototype = Object.getPrototypeOf(prototype)
    ) {
        descriptors = Object.getOwnPropertyDescriptors(prototype);

        for (const key of Object.getOwnPropertyNames(descriptors)) {
            if (!wrapper.hasOwnProperty(key)) {
                let descriptor: PropertyDescriptor = descriptors[key];

                Object.defineProperty(wrapper, key, constructWrapperPropertyDescriptor(objectToWrap, key, descriptor, makeImmutable));
            }
        }
    }

    return wrapper;
}

function getWrappedObjectPropertyValue(wrappedObject: any, propertyKey: string): any {
    return wrappedObject[propertyKey];
}

function setWrappedObjectPropertValue(wrappedObject: any, propertyKey: string, value: any): void {
    wrappedObject[propertyKey] = value;
}

function constructWrapperPropertyDescriptor(obj: any, key: string, descriptor: PropertyDescriptor, makeImmutable: boolean): any {
    const isReadable: boolean = descriptor.get !== undefined || descriptor.set === undefined;
    const isWritable: boolean = !makeImmutable && (descriptor.set !== undefined || descriptor.writable === true);
    const wrapperDescriptor: PropertyDescriptor = {
        configurable: false,
        enumerable: descriptor.enumerable,
        get: isReadable ? getWrappedObjectPropertyValue.bind(null, obj, key) : undefined,
        set: isWritable ? setWrappedObjectPropertValue.bind(null, obj, key) : undefined,
    };

    return wrapperDescriptor;
}
