import { IButtonStyles, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';

export interface ISpinnerButtonProps {
    ariaLabel: string;
    /** the text of the button while it's "at rest" */
    text: string;
    disabled: boolean;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    /** the text of the button while it is in a processing state */
    processingText: string;
    isProcessing: boolean;
    styles?: IButtonStyles | undefined;
    focuszoneKeypressSkip?: boolean;
}

export const SpinnerButton = (props: ISpinnerButtonProps) => {
    return (
        <PrimaryButton
            data-is-focuszone-keypress-skip={props.focuszoneKeypressSkip ?? false}
            ariaLabel={props.ariaLabel}
            onClick={props.onClick}
            disabled={props.disabled || props.isProcessing}
            styles={props.styles}
        >
            {props.isProcessing && (
                <Spinner
                    size={SpinnerSize.xSmall}
                    ariaLabel={props.processingText}
                    styles={{
                        root: {
                            paddingRight: '5px',
                            opacity: 0.5,
                        },
                    }}
                />
            )}
            {props.isProcessing ? props.processingText : props.text}
        </PrimaryButton>
    );
};
