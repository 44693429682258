import { useBoolean } from '@fluentui/react-hooks';
import { GridComponent, IIndex } from '@syncfusion/ej2-react-grids';
import React, { createContext, useRef } from 'react';
import { scrollToRow } from '../utils/TableUtils';

export interface IGridScrollContextValues {
    currentRowIndex: React.MutableRefObject<number | null>;
    currentColumnIndex: React.MutableRefObject<number | null>;
    shouldScrollToRowOnDataBound: boolean;
    shouldScrollToColumnOnDataBound: boolean;
    programmaticallyScrollToRow: (grid: GridComponent, positionIndex: IIndex) => void;
    setShouldScrollOnDataBoundTrue: () => void;
    setShouldScrollOnDataBoundFalse: () => void;
    setShouldScrollToColumnOnDataBoundTrue: () => void;
    setShouldScrollToColumnOnDataBoundFalse: () => void;
}

export const GridScrollContext = createContext<IGridScrollContextValues | null>(null);

export function GridScrollContextProvider(props: { value?: IGridScrollContextValues; children?: React.ReactNode }) {
    const [shouldScrollToRowOnDataBound, { setTrue: setShouldScrollOnDataBoundTrue, setFalse: setShouldScrollOnDataBoundFalse }] =
        useBoolean(false);
    const [
        shouldScrollToColumnOnDataBound,
        { setTrue: setShouldScrollToColumnOnDataBoundTrue, setFalse: setShouldScrollToColumnOnDataBoundFalse },
    ] = useBoolean(false);
    const currentRowIndex = useRef<number | null>(null);
    const currentColumnIndex = useRef<number | null>(null);

    const programmaticallyScrollToRow = (grid: GridComponent, positionIndex: IIndex) => {
        currentRowIndex.current = positionIndex.rowIndex ?? 0;
        currentColumnIndex.current = positionIndex.cellIndex ?? 0;
        const newIndex = scrollToRow(grid, positionIndex.rowIndex ?? 0);

        if (newIndex) {
            setShouldScrollOnDataBoundTrue();
        }
    };

    return (
        <GridScrollContext.Provider
            value={
                props.value ?? {
                    currentRowIndex,
                    currentColumnIndex,
                    shouldScrollToRowOnDataBound,
                    shouldScrollToColumnOnDataBound,
                    programmaticallyScrollToRow,
                    setShouldScrollOnDataBoundTrue,
                    setShouldScrollOnDataBoundFalse,
                    setShouldScrollToColumnOnDataBoundTrue,
                    setShouldScrollToColumnOnDataBoundFalse,
                }
            }
        >
            {props.children}
        </GridScrollContext.Provider>
    );
}
