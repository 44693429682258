import { BaseTheme } from '@corptax/react-components-common';
import { IButtonStyles, IProcessedStyleSet, IStyle, ITextFieldStyles, mergeStyleSets } from '@fluentui/react';

export class CommentsUIStyles {
    private _baseCardClassNames: IProcessedStyleSet<{ baseCardHeaderStyle: IStyle; baseCardStyle: IStyle }> | undefined;
    private _cardClassNames:
        | IProcessedStyleSet<{
              cardStyle: IStyle;
              cardHeaderStyle: IStyle;
              cardStyleSelected: IStyle;
              cardHeaderStyleSelected: IStyle;
              cardItemStyle: IStyle;
          }>
        | undefined;
    private _baseStyles = {
        baseCardStyle: {
            borderRadius: '10px',
            border: '1px solid',
            marginTop: '10px',
            marginBottom: '10px',
            boxShadow: `0px 0px 4px`,
        },
        baseCardHeaderStyle: {
            lineHeight: 20,
            fontWeight: 600,
            padding: '10px',
            borderRadius: '6px 6px 0 0',
            marginBottom: '10px',
        },
    };

    constructor(private theme: BaseTheme) {}

    generateClassNames() {
        this._baseCardClassNames = mergeStyleSets(this._baseStyles);
        this._cardClassNames = mergeStyleSets({
            cardStyle: {
                borderColor: this.theme.brandPalette.coolLightGrayTint1,
                selectors: {
                    '&:hover': {
                        borderColor: this.theme.brandPalette.hyperlinkBlueColor?.transformOpacity(0.5),
                        [`.${this._baseCardClassNames!.baseCardHeaderStyle}`]: {
                            backgroundColor: this.theme.brandPalette.gridHighlightBlue,
                        },
                    },
                    '&:focus': {
                        borderColor: this.theme.brandPalette.black,
                        outline: `1px solid ${this.theme.brandPalette.black}`,
                    },
                },
            },
            cardStyleSelected: {
                borderColor: this.theme.brandPalette.hyperlinkBlue,
                selectors: {
                    '&:focus': {
                        borderColor: this.theme.brandPalette.black,
                        borderWidth: '2px',
                        outline: `1px solid ${this.theme.brandPalette.black}`,
                    },
                },
            },
            cardHeaderStyle: {
                backgroundColor: this.theme.brandPalette.coolLightGrayTint1,
                color: this.theme.brandPalette.black,
            },
            cardHeaderStyleSelected: {
                backgroundColor: this.theme.brandPalette.hyperlinkBlue,
                color: this.theme.palette.white,
            },
            cardItemStyle: {
                margin: '10px',
            },
        });
    }

    get cardClassNames() {
        return `${this._baseCardClassNames?.baseCardStyle} ${this._cardClassNames?.cardStyle}`;
    }
    get cardHeaderClassNames() {
        return `${this._baseCardClassNames?.baseCardHeaderStyle} ${this._cardClassNames?.cardHeaderStyle}`;
    }
    get cardSelectedClassNames() {
        return `${this._baseCardClassNames?.baseCardStyle} ${this._cardClassNames?.cardStyleSelected}`;
    }
    get cardHeaderSelectedClassNames() {
        return `${this._baseCardClassNames?.baseCardHeaderStyle} ${this._cardClassNames?.cardHeaderStyleSelected}`;
    }
    get cardItemClassNames() {
        return `${this._cardClassNames?.cardItemStyle}`;
    }

    get commentTextAreaStyles(): Partial<ITextFieldStyles> {
        return {
            root: {
                '.ms-TextField-fieldGroup::after': { display: 'None' },
            },
            fieldGroup: { border: 'none' },
            field: {
                border: `1px solid ${this.theme.brandPalette.darkGray}`,
                borderRadius: 6,
                ':focus': { border: '1px solid black' },
                ':focus-visible': { outline: '-webkit-focus-ring-color auto 1px' },
            },
        };
    }

    get collapsedCommentTextAreaStyles(): Partial<ITextFieldStyles> {
        return {
            root: {
                '.ms-TextField-fieldGroup::after': { display: 'None' },
            },
            fieldGroup: { border: 'none' },
            field: {
                border: `1px solid ${this.theme.brandPalette.mediumGray}`,
                borderRadius: 6,
                ':focus': { border: '1px solid black' },
                ':focus-visible': { outline: '-webkit-focus-ring-color auto 1px' },
            },
        };
    }

    get fluentV9ButtonAdjustment(): IButtonStyles {
        return {
            root: {
                borderRadius: 6,
                selectors: {
                    ':focus': {
                        borderRadius: 6,
                        borderColor: `${this.theme.brandPalette.blackColor}`,
                        borderWidth: '2px',
                        outline: 'none',
                    },
                    '&.ms-Button--default:focus::after': {
                        display: 'none',
                    },
                    '&.ms-Button--primary:focus::after': {
                        content: '',
                        position: 'absolute',
                        inset: '2px',
                        border: 'none',
                        outline: '1px solid',
                    },
                },
            },
        };
    }

    get fluentV9DialogAdjustment() {
        return { main: { maxWidth: '600px !important', width: '600px !important', borderRadius: 6 } };
    }

    get fluentV9ButtonWithChildrenAdjustment(): IButtonStyles {
        return {
            root: {
                fontWeight: 600,
                padding: '0 12px ',
                selectors: {
                    ':focus': {
                        borderRadius: 6,
                        borderColor: `${this.theme.brandPalette.blackColor}`,
                        borderWidth: '2px',
                        outline: 'none',
                    },
                    '&.ms-Button--default:focus::after': {
                        display: 'none',
                    },
                    '&.ms-Button--primary:focus::after': {
                        content: '',
                        position: 'absolute',
                        inset: '2px',
                        border: 'none',
                        outline: '1px solid',
                    },
                },
            },
        };
    }
}
