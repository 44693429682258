import { useCorptaxTheme } from '@corptax/react-components-common';
import { IContextualMenuItem, IIconProps, mergeStyleSets } from '@fluentui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterItem } from '../../../data-types/filterItem';
import { FilterTabDefinition } from '../../../data-types/filterTabDefinition';
import { useContextualMenuWithSearchBox } from '../../../hooks/useContextualMenuWithSearchBox';
import { IAppTheme } from '../../../theme/IAppTheme';
import { roleMenu, roleMenuItem } from '../../../utils/Filter.constant';
import { getFilterMenuAriaLabel, getUpdatedSelectedFilters, reorderCheckedItems } from '../../../utils/FilterUtils';
import IconButtonWithContextualMenu from '../../common/IconButtonWithContextualMenu';

export interface ITabFilterButtonProps {
    label?: string;
    tabs: FilterTabDefinition[];
    filterName: string;
    searchValue: string;
    setSearchValue: (value: string) => void;
    onButtonClick?: (filterName: string) => void;
    disabled?: boolean;
}

const TabFilterButton: React.FC<ITabFilterButtonProps> = ({
    label,
    tabs,
    filterName,
    searchValue,
    disabled,
    setSearchValue,
    onButtonClick,
}) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const getTabFilterButtonStyles = () =>
        mergeStyleSets({
            contextMenuItemIconStyles: {
                fontSize: '13px',
                margin: '0 10 0 0',
                width: '13px',
            },
            filterItemWrapperStyles: {
                paddingTop: 5,
                paddingBottom: 5,
            },
        });

    const { contextMenuItemIconStyles, filterItemWrapperStyles } = getTabFilterButtonStyles();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const activeTab = tabs[activeTabIndex];
    const isItemSelected = activeTab?.checkedItems.length > 0;
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[] | undefined>(undefined);
    const handleContextualMenuButtonClick = (filteredItems: IFilterItem[], filterName: string) => {
        const newSelectedItems: IFilterItem[] = getUpdatedSelectedFilters(filteredItems, activeTab.checkedItems, activeTab.isSingleSelect);
        activeTab.setCheckedItems(newSelectedItems);
        clearOtherTabsAndSelectItems(newSelectedItems);

        if (onButtonClick) {
            onButtonClick(filterName);
        }
    };

    const tabLabels = useMemo(() => {
        return tabs.map((tab) => tab.label);
    }, [tabs]);

    const orderedItems = useMemo(() => {
        return reorderCheckedItems(activeTab?.checkedItems, activeTab?.items, activeTab.label);
    }, [activeTab.items, activeTab.checkedItems]);

    const contextMenuItemIconProps: IIconProps = {
        iconName: 'Group',
        className: contextMenuItemIconStyles,
        style: { color: customPalette.themePrimary },
    };

    const { contextualMenuObject } = useContextualMenuWithSearchBox({
        filterMenuItems: orderedItems,
        filterName: filterName,
        iconProps: activeTab?.isSingleSelect ? contextMenuItemIconProps : null,
        itemWrapperClassName: filterItemWrapperStyles,
        onMenuItemClick: handleContextualMenuButtonClick,
        searchItemName: 'item',
        isFiltered: isItemSelected,
        noResultsText: activeTab?.noElementsFoundtext,
        viewAllText: t('clearAll'),
        searchPlaceholderText: t('search'),
        menuItems,
        setMenuItems,
        filterMenuAriaLabel: getFilterMenuAriaLabel(label || filterName, t),
        menuListRole: tabs[activeTabIndex]?.isSingleSelect ? roleMenu : roleMenuItem,
        selectedFilterItems: tabs[activeTabIndex]?.checkedItems,
        isMultiSelect: !tabs[activeTabIndex]?.isSingleSelect,
        searchValue,
        setSearchValue,
        tabsLabels: tabLabels,
        selectedTabIndex: activeTabIndex,
        onTabChange: setActiveTabIndex,
    });

    const clearOtherTabsAndSelectItems = (items: IFilterItem[]) => {
        tabs.forEach((tab, index) => {
            if (index !== activeTabIndex) {
                tab.setCheckedItems([]);
            }
        });
        tabs[activeTabIndex].setCheckedItems(items);
    };

    const buttonId: string = `${filterName}-button`;

    return (
        <IconButtonWithContextualMenu
            ariaLabel={t(label || filterName) ?? ''}
            menuProps={contextualMenuObject}
            buttonId={buttonId}
            label={t(label || filterName)}
            isActive={isItemSelected}
            isDisabled={disabled}
        />
    );
};

export default TabFilterButton;
