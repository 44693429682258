import {
    CollapseAllVisibility,
    DetailsRowFields,
    FocusZoneDirection,
    GroupedList,
    GroupSpacer,
    IColumn,
    IDetailsColumnFieldProps,
    IDetailsHeaderProps,
    IDetailsRowProps,
    IFocusZone,
    IGroupedListProps,
    IGroupHeaderProps,
    mergeStyleSets,
    Point,
    SelectAllVisibility,
    SelectionMode,
} from '@fluentui/react';
import { TFunction } from 'i18next';
import { KeyboardEvent as ReactKeyboardEvent, MouseEvent as ReactMouseEvent, RefCallback } from 'react';
import Icon from '../common/Icon';
import { cellContainerClass, columnHeaderRole, expandCollapseClass, groupHeaderNameClass } from './CardGridListConstants';
import { getGridGroupNestingLevel } from './CardGridListData';
import {
    ICardGridListCustomRow,
    ICardGridListEventHandling,
    ICardGridListProps,
    ICardGridListRender,
    ICardGridListRenderUtils,
    ICardGridListRowData,
    ICardGridListStyling,
    IColumnExtended,
    IGroupExtended,
} from './CardGridListInterfaces';
import { styleUsing } from './CardGridListStyling';
import DataCell, { IDataCellProps, IDataCellRenderProps } from './DataCell';

export function renderUsing(
    props: ICardGridListProps,
    groupedListId: string,
    gridColumns: IColumnExtended[],
    gridGroups: IGroupExtended[],
    dataSource: ICardGridListRowData[],
    handle: ICardGridListEventHandling,
    t: TFunction
): ICardGridListRender {
    return new CardGridListRender(props, groupedListId, gridColumns, gridGroups, dataSource, handle, t);
}

class CardGridListRender implements ICardGridListRender {
    private readonly _detailListNoRecordsContainerId: string = 'detailListNoRecordContainer';
    private readonly _handle: ICardGridListEventHandling;
    private readonly _props: ICardGridListProps;
    private readonly _styling: ICardGridListStyling;
    private readonly _t: TFunction;
    private readonly _groupedListId: string;
    private readonly _gridColumns: IColumnExtended[];
    private readonly _gridGroups: IGroupExtended[];
    private readonly _dataSource: ICardGridListRowData[];

    constructor(
        props: ICardGridListProps,
        groupedListId: string,
        gridColumns: IColumnExtended[],
        gridGroups: IGroupExtended[],
        dataSource: ICardGridListRowData[],
        handle: ICardGridListEventHandling,
        t: TFunction
    ) {
        this._props = props;
        this._groupedListId = groupedListId;
        this._gridColumns = gridColumns;
        this._gridGroups = gridGroups;
        this._dataSource = dataSource;
        this._handle = handle;
        this._t = t;
        this._styling = styleUsing();
        this.columnHeaders = this.renderColumnHeaders.bind(this);
        this.groupedList = this.renderGroupedList.bind(this);
        this.groupHeader = this.renderGroupHeader.bind(this);
        this.groupHeaderName = this.renderGroupHeaderName.bind(this);
        this.groupHeaderTitle = this.renderGroupHeaderTitle.bind(this);
        this.row = this.renderRow.bind(this);
    }

    public readonly columnHeaders: (
        props?: IDetailsHeaderProps,
        defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null
    ) => JSX.Element | null;

    public readonly groupedList: (props: IGroupedListProps, context?: any) => JSX.Element | null;

    public readonly groupHeader: (
        props?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null
    ) => JSX.Element | null;

    public readonly groupHeaderName: (
        props?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null
    ) => JSX.Element | null;

    public readonly groupHeaderTitle: (
        props?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null
    ) => JSX.Element | null;

    public readonly row: (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null) => JSX.Element | null;

    public static cellIsRowExpander(
        cellColumn: IColumnExtended,
        cellGridGroup: IGroupExtended,
        columns: IColumn[],
        defaultExpanderColumnKey: string | undefined,
        customRows: ICardGridListCustomRow[] | undefined
    ): boolean {
        const classNames: string[] = cellGridGroup?.className?.split(' ').map((value) => value.trim()) ?? [];
        const expanderCustomRows: ICardGridListCustomRow[] =
            customRows?.filter((customRow) => customRow.expandCollapseColumnKey === cellColumn.key) ?? [];

        for (const customRow of Object.values<ICardGridListCustomRow>(expanderCustomRows)) {
            if (classNames.indexOf(customRow.className) >= 0) {
                return true;
            }
        }

        if (cellColumn.key === defaultExpanderColumnKey || columns[0].key === cellColumn.key) {
            return true;
        }

        return false;
    }

    public static gridGroupRequiresNestingIndentation(gridGroup: IGroupExtended): boolean {
        const nestingLevel: number = getGridGroupNestingLevel(gridGroup);

        return nestingLevel > 0;
    }

    public static gridGroupRequiresExpandCollapse(gridGroup: IGroupExtended): boolean {
        return !gridGroup.noExpandCollapse && gridGroup.hasChildren;
    }

    public static gridGroupRequiresNoExpandCollapseIndent(gridGroup: IGroupExtended): boolean {
        if (!gridGroup.noExpandCollapse) {
            return !gridGroup.hasChildren;
        } else if (gridGroup.parent) {
            const parent: IGroupExtended = gridGroup.parent;

            if (!parent.noExpandCollapse) {
                const nestingLevel: number = getGridGroupNestingLevel(gridGroup);
                const parentNestingLevel: number = getGridGroupNestingLevel(parent);

                return nestingLevel === parentNestingLevel;
            }
        }

        return false;
    }

    public static renderExpandCollapse(
        dataSourceIndex: number,
        clickEventCallback: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
    ): JSX.Element | null {
        return (
            // TODO: Potentially pull this out as a new component.
            // Need to embed svg element instead of making a request as a resource in order to make use of
            // current text color specified within the parent element.
            <div className={expandCollapseClass} data-source-index={dataSourceIndex} onClick={clickEventCallback}>
                <Icon iconName='ChevronRight12Regular' />
            </div>
        );
    }

    public static renderIndent(width: number, count: number): JSX.Element | null {
        return <GroupSpacer count={count ?? 1} indentWidth={width ?? 10} />;
    }

    public static renderNestingIndent(gridGroup: IGroupExtended): JSX.Element | null {
        const nestingLevel: number = getGridGroupNestingLevel(gridGroup);
        return CardGridListRender.renderIndent(15, nestingLevel);
    }

    public static renderNoExpandCollapseIndent(): JSX.Element | null {
        return CardGridListRender.renderIndent(20, 1);
    }

    private maybeSetFocusAlignment(focusZone: IFocusZone | null, hasFocusAlignmentBeenSet: boolean): boolean {
        if (!focusZone || hasFocusAlignmentBeenSet) {
            return hasFocusAlignmentBeenSet;
        }

        const gridRoot: HTMLElement | null = document.getElementById(this._props.id);
        const firstDataColumnHeader: HTMLElement | null =
            gridRoot?.querySelector(`div[role="${columnHeaderRole}"][data-item-key="${this._props.columns[0]?.fieldName}"]`) ?? null;

        if (!firstDataColumnHeader) {
            return false;
        }

        const dataColumnRect: DOMRect = firstDataColumnHeader.getBoundingClientRect();

        const horizontalFocus: Point = {
            left: dataColumnRect.left + dataColumnRect.width / 2,
            top: -1,
        };

        focusZone.setFocusAlignment(horizontalFocus);
        return true;
    }

    private renderColumnHeaders(
        props?: IDetailsHeaderProps,
        defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null
    ): JSX.Element | null {
        if (props) {
            props.collapseAllVisibility = CollapseAllVisibility.hidden;
            props.selectAllVisibility = SelectAllVisibility.hidden;
            props.styles = this._styling.detailsHeaderStyles;
            props.selectionMode = SelectionMode.none;
        }

        return defaultRender ? defaultRender(props) : null;
    }

    private renderField(
        props?: IDetailsColumnFieldProps,
        defaultRender?: (props?: IDetailsColumnFieldProps) => JSX.Element | null
    ): JSX.Element | null {
        if (!props || !defaultRender) {
            return null;
        }

        const column: IColumnExtended = props.column as IColumnExtended;

        const dataCellProps: IDataCellProps = column.cardGridListColumn.dataCellProps ?? {
            dataType: 'text',
        };

        const containingGridIndex: number = this._gridColumns.findIndex(
            (column) => (props.column as IColumnExtended).fieldName === column.fieldName
        );

        const dataCellRenderProps: IDataCellRenderProps = {
            ...dataCellProps,
            detailsColumnFieldProps: props,
            key: `Cell(${props.itemIndex}, ${containingGridIndex})`,
            gridColumns: this._gridColumns,
            gridDataSource: this._dataSource,
            handle: this._handle,
            render: defaultRender,
            defaultExpandCollapseColumnKey: this._props.expandCollapseColumnKey,
            customRows: this._props.customRows,
        };

        return <DataCell {...dataCellRenderProps} />;
    }

    private renderGroupedList(props: IGroupedListProps, context?: any): JSX.Element | null {
        const newProps: IGroupedListProps = { ...props };
        newProps.rootListProps = {
            id: this._groupedListId,
        };

        newProps.styles = this._styling.groupedListStyles(
            this._props.groupHeaderStyle,
            this._props.rowStyle,
            this._gridColumns,
            this._gridGroups,
            this._props.customRows,
            this._props.customRowStyles
        );

        let focusZoneRef: IFocusZone | null = null;
        let hasFocusAlignmentBeenSet: boolean = false;

        newProps.focusZoneProps = {
            componentRef: ((instance: IFocusZone) => (focusZoneRef = instance)) as RefCallback<IFocusZone>,
            direction: FocusZoneDirection.bidirectional,
            onActiveElementChanged: () => (hasFocusAlignmentBeenSet = this.maybeSetFocusAlignment(focusZoneRef, hasFocusAlignmentBeenSet)),
            onKeyDown: (event: ReactKeyboardEvent<HTMLElement>) => this._handle.groupedListKeyDown(this._groupedListId, event),
        };

        return <GroupedList {...newProps} />;
    }

    private renderGroupHeader(
        props?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null
    ): JSX.Element | null {
        const extendedGroup: IGroupExtended | null = props?.group as IGroupExtended;

        if (!(props && extendedGroup)) {
            return null;
        }

        props.className = extendedGroup.groupClassName;

        if (extendedGroup.className) {
            props.className += ` ${extendedGroup.className}`;
        }

        if (!extendedGroup.noExpandCollapse && extendedGroup.hasChildren) {
            return <div onDoubleClick={this._handle.collapsibleRowDoubleClick}>{defaultRender!(props)}</div>;
        } else {
            return <div>{defaultRender!(props)}</div>;
        }
    }

    private renderGroupHeaderName(
        props?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null
    ): JSX.Element | null {
        const gridGroup: IGroupExtended | null = props?.group as IGroupExtended;

        if (!gridGroup) {
            return null;
        }

        const renderName = () => <span className={groupHeaderNameClass}>{gridGroup.name}</span>;
        const requiresNestingIndent: boolean = CardGridListRender.gridGroupRequiresNestingIndentation(gridGroup);
        const requiresNoExpandCollapseIndent: boolean = CardGridListRender.gridGroupRequiresNoExpandCollapseIndent(gridGroup);
        const requiresExpandCollapse: boolean = CardGridListRender.gridGroupRequiresExpandCollapse(gridGroup);
        const requiresCustomRender = requiresNestingIndent || requiresNoExpandCollapseIndent || requiresExpandCollapse;

        if (!requiresCustomRender) {
            return renderName();
        }

        return (
            <div className={cellContainerClass}>
                {requiresNestingIndent ? CardGridListRender.renderNestingIndent(gridGroup) : null}
                {requiresNoExpandCollapseIndent ? CardGridListRender.renderNoExpandCollapseIndent() : null}
                {requiresExpandCollapse
                    ? CardGridListRender.renderExpandCollapse(gridGroup.dataSourceIndex, this._handle.expanderClick)
                    : null}
                {renderName()}
            </div>
        );
    }

    private renderGroupHeaderTitle(
        props?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null
    ): JSX.Element | null {
        const extendedGroup: IGroupExtended | null = props?.group as IGroupExtended;

        if (!extendedGroup) {
            return null;
        }

        const dataSourceIndex: number = extendedGroup.dataSourceIndex;
        const dataItem: ICardGridListRowData = this._dataSource[dataSourceIndex];

        if (extendedGroup.name !== '') {
            return defaultRender ? defaultRender(props) : null;
        } else {
            return (
                <DetailsRowFields
                    columns={this._gridColumns}
                    columnStartIndex={0}
                    item={dataItem}
                    itemIndex={dataSourceIndex}
                    onRenderField={this.renderField.bind(this)}
                    rowClassNames={mergeStyleSets(this._styling.detailsRowStyles(this._gridColumns)) as any}
                />
            );
        }
    }

    private renderRow(props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null): JSX.Element | null {
        if (this._gridGroups.length === 0) {
            return (
                <div id={this._detailListNoRecordsContainerId} className={this._styling.detailListNoRecordClassName}>
                    {this._t('noRecordsToDisplay')}
                </div>
            );
        }

        return defaultRender ? defaultRender(props) : null;
    }
}

export const cardGridListRenderUtils: ICardGridListRenderUtils = {
    cellIsRowExpander: CardGridListRender.cellIsRowExpander,
    gridGroupRequiresExpandCollapse: CardGridListRender.gridGroupRequiresExpandCollapse,
    gridGroupRequiresNoExpandCollapseIndent: CardGridListRender.gridGroupRequiresNoExpandCollapseIndent,
    gridGroupRequiresNestingIndent: CardGridListRender.gridGroupRequiresNestingIndentation,
    renderExpandCollapse: CardGridListRender.renderExpandCollapse,
    renderIndent: CardGridListRender.renderIndent,
    renderNoExpandCollapseIndent: CardGridListRender.renderNoExpandCollapseIndent,
    renderNestingIndent: CardGridListRender.renderNestingIndent,
};
