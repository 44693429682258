import {
    DirectionalHint,
    ICalloutContentStyles,
    ICalloutProps,
    ITooltipHostProps,
    ITooltipProps,
    ITooltipStyles,
    TooltipOverflowMode,
} from '@fluentui/react';
import { ICustomPalette } from '../theme/ICustomPalette';

export const getTooltipHostProps: (
    directionalHint?: DirectionalHint,
    overflowMode?: TooltipOverflowMode,
    palette?: ICustomPalette
) => Partial<ITooltipHostProps> = (directionalHint?: DirectionalHint, overflowMode?: TooltipOverflowMode, palette?: ICustomPalette) => {
    return {
        directionalHint: directionalHint,
        overflowMode: overflowMode,
        tooltipProps: {
            styles: { content: { color: 'white' } } as ITooltipStyles,
            calloutProps: {
                beakWidth: 9,
                styles: {
                    beak: { backgroundColor: palette?.neutralDarker },
                    beakCurtain: { backgroundColor: palette?.neutralDarker, borderRadius: '4px' },
                    calloutMain: { backgroundColor: palette?.neutralDarker },
                } as ICalloutContentStyles,
            } as ICalloutProps,
        } as ITooltipProps,
    } as ITooltipHostProps;
};

export const getCalloutProps: (palette?: ICustomPalette) => ICalloutProps = (palette?: ICustomPalette) => {
    return {
        beakWidth: 9,
        styles: {
            beak: { backgroundColor: palette?.neutralDarker },
            beakCurtain: { backgroundColor: palette?.neutralDarker, borderRadius: '4px' },
            calloutMain: {
                backgroundColor: palette?.neutralDarker,
                color: palette?.themeLight,
                borderRadius: '4px',
                padding: '5px 12px 7px 12px',
                lineHeight: '16px',
            },
        } as ICalloutContentStyles,
        // calloutWidth: 200,
        // calloutMinWidth: 200,
    } as ICalloutProps;
};
