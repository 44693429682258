import { useContext } from 'react';
import { FunctionalAccessContext } from '../contexts/functionaAccessContext';

export function useFunctionalAccess() {
    const functionalAccessContext = useContext(FunctionalAccessContext);
    if (!functionalAccessContext) {
        throw new Error('useFunctionalAccess must be wrapped in a FunctionalAccessContextProvider');
    }
    return functionalAccessContext;
}
