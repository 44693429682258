import { DefaultButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { CommentsCurrentType } from '../../data-types/ICommentsUIState';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { useCommentsUIStyles } from '../../hooks/useCommentsUIStyles';
import { CommentsActionType } from '../../reducers/commentsStateReducer';
import Icon from '../common/Icon';
import { AddConversationCard } from './AddConversationCard';
import { ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';

export interface IAddConversationItemProps {
    newConversationTitle: string;
    onAdd: (value: ICommentRichTextEditorValue) => void;
    onRefresh: () => void;
    canAdd: boolean;
    errorMessage?: string;
}

export const AddConversationItem = (props: IAddConversationItemProps) => {
    const { state: uiState, dispatch: dispatchUIState } = useCommentsUI();
    const styles = useCommentsUIStyles();
    const { t } = useTranslation();
    const addCommentButtonText = t('addCommentButtonText').toString();

    const handleBeginAddConversation = () => {
        dispatchUIState({ type: CommentsActionType.AddNewConversation });
    };

    const handleAddComment = (comment: ICommentRichTextEditorValue) => {
        // there might be a slight delay where the control allows a save when it should not
        // due to eventing, so make sure it's not empty
        if (comment.commentText) {
            props.onAdd(comment);
        }
    };

    const handleCancelAddComment = () => {
        dispatchUIState({ type: CommentsActionType.CancelOperation });
    };

    return (
        <>
            {uiState.currentUiState === CommentsCurrentType.AddingNewConversation ? (
                <div>
                    <AddConversationCard
                        title={props.newConversationTitle}
                        onCancel={handleCancelAddComment}
                        onAdd={handleAddComment}
                        errorMessage={props.errorMessage ?? undefined}
                        onRefresh={props.onRefresh}
                    />
                </div>
            ) : props.canAdd ? (
                <div style={{ width: 'auto' }}>
                    <DefaultButton
                        ariaLabel={addCommentButtonText}
                        onClick={handleBeginAddConversation}
                        disabled={uiState.hasPendingChanges}
                        styles={styles.fluentV9ButtonAdjustment}
                    >
                        <Icon iconName='CommentAdd28Regular' style={{ paddingRight: 10, width: '14px' }} />
                        {addCommentButtonText}
                    </DefaultButton>
                </div>
            ) : null}
        </>
    );
};
