import { useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useState } from 'react';
import { applicationInsightsReactPlugin } from '../ApplicationInsightsService';
import { ExtractionDetailsAccountSummary } from '../model';

export type ReportDrilldownEvent = 'Drilldown Loaded' | 'Drilldown Saved' | 'Drilldown Loading' | 'Drilldown Saving';
export interface IReportDrilldownTrackingInformation {
    reportId: string;
    isMultiColumnReport: boolean;
    reportIsForEntityGroup: boolean;
    success: boolean | 'Not Attempted';
    columnId: string;
    numberOfAccountGroups: number;
    rowNumber: string;
}
export interface IReportDrillDownLoadedTrackingInformation extends IReportDrilldownTrackingInformation {
    numberOfAccountGroupsWithAdjustments: number;
    numberOfUniqueAccounts: number;
    rowMapItemCount: number;
    rowRequestCount: number;
}

export interface IReportDrilldownSavedTrackingInformation extends IReportDrilldownTrackingInformation {
    numberOfAmountsSaved: number;
}

export interface IReportDrillDownPerformanceTrackingHook {
    trackDrilldownLoaded: (trackingInformation: IReportDrillDownLoadedTrackingInformation) => void;
    trackDrilldownSaved: (trackingInformation: IReportDrilldownSavedTrackingInformation) => void;
    trackDrilldownLoading: () => void;
    trackDrilldownSaving: () => void;
}

export const getNumberOfUniqueAccounts: (extractionDetails: ExtractionDetailsAccountSummary[] | undefined) => number = (
    extractionDetails: ExtractionDetailsAccountSummary[] | undefined
) => {
    return (
        new Set(
            extractionDetails
                ?.filter((x: ExtractionDetailsAccountSummary) => x.accountNumber !== null)
                .map((x: ExtractionDetailsAccountSummary) => x.accountNumber)
        ).size ?? 0
    );
};

export const getNumberOfAccountGroupsWithAdjustments: (extractionDetails: ExtractionDetailsAccountSummary[] | undefined) => number = (
    extractionDetails: ExtractionDetailsAccountSummary[] | undefined
) => {
    return extractionDetails?.filter((x: ExtractionDetailsAccountSummary) => (x.adjustmentInfo?.length ?? 0) > 0).length ?? 0;
};
export const usePerformanceMetricsForDrillDown: () => IReportDrillDownPerformanceTrackingHook = () => {
    const eventProtoType = { componentName: 'Drilldown', durationInMilliseconds: 0 };
    const [timeMap] = useState<Map<ReportDrilldownEvent, number>>(new Map());
    const trackDrilldownLoadedEvent = useTrackEvent(applicationInsightsReactPlugin, 'Drilldown Loaded', eventProtoType);
    const trackDrilldownSavedEvent = useTrackEvent(applicationInsightsReactPlugin, 'Drilldown Saved', eventProtoType);
    const trackDrilldownLoading = () => {
        const eventStartTime = Date.now();
        timeMap.set('Drilldown Loading', eventStartTime);
    };
    const trackDrilldownLoaded = (trackingInformation: IReportDrillDownLoadedTrackingInformation) => {
        const eventEndTime = Date.now();
        const eventStartTime = timeMap.get('Drilldown Loading');
        const durationInMilliseconds = eventEndTime - eventStartTime!;
        timeMap.delete('Drilldown Loading');
        const eventDetails = { ...eventProtoType, durationInMilliseconds, ...trackingInformation };
        trackDrilldownLoadedEvent(() => eventDetails);
    };
    const trackDrilldownSaving = () => {
        const eventStartTime = Date.now();
        timeMap.set('Drilldown Saving', eventStartTime);
    };
    const trackDrilldownSaved = (trackingInformation: IReportDrilldownSavedTrackingInformation) => {
        const eventEndTime = Date.now();
        const eventStartTime = timeMap.get('Drilldown Saving');
        const durationInMilliseconds = eventEndTime - eventStartTime!;
        const eventDetails = { ...eventProtoType, durationInMilliseconds, ...trackingInformation };
        trackDrilldownSavedEvent(() => eventDetails);
        timeMap.delete('Drilldown Saving');
    };

    return {
        trackDrilldownLoaded,
        trackDrilldownSaved,
        trackDrilldownLoading,
        trackDrilldownSaving,
    } as IReportDrillDownPerformanceTrackingHook;
};
