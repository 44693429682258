import { useSearchParams } from 'react-router-dom';
import { IFilterItem } from '../data-types/filterItem';

export const useSelectedEntities = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedEntities = searchParams.get('selectedEntities') ? (searchParams.get('selectedEntities') || '').split(',') : [];

    const setSelectedEntitiesSearchParams = (filteredItems: IFilterItem[]) => {
        const filteredItemsKey = filteredItems.map((i) => i.key).join(',');
        setSearchParams({ selectedEntities: filteredItemsKey });
    };

    const getFilteredEntities = (entities: IFilterItem[]): IFilterItem[] =>
        entities.filter((e) => selectedEntities.some((p) => p === e.key));

    return { selectedEntities, setSearchParams, setSelectedEntitiesSearchParams, getFilteredEntities, searchParams };
};
