import { mergeStyles, Stack } from '@fluentui/react';
import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Outlet } from 'react-router-dom';
import { useGetAppVersionCalculationReviewBuildNumber } from './api/app-version/app-version';
import { useGetClaimsForCurrentUser } from './api/user-claims/user-claims';
import { useGetClaims } from './apiExtensions/account/account';
import { initializeAppInsights } from './ApplicationInsightsService';
import { FooterContainer } from './components/footer/FooterContainer';
import { Navbar } from './components/navbar/Navbar';
import ReportListPanel from './components/report-list-panel/ReportListPanel';
import { Topbar } from './components/topbar/Topbar';
import { FeedbackMessagesContextProvider } from './contexts/feedbackMessagesContext';
import { useFunctionalAccess } from './hooks/useFunctionalAccess';
import { useServiceDiscovery } from './hooks/useServiceDiscovery';
import { HEADER_SIZE, NAVBAR_SIZE } from './utils/Style.constants';
import { getLogoutUrl, LOGIN_URL } from './utils/Urls.constant';

function App() {
    const { homeUrl, appInsightsConnectionString } = useServiceDiscovery();
    const { data: sessionClaims, isError, isLoading } = useGetClaims({ query: { retry: false } });
    const { data: claims } = useGetClaimsForCurrentUser();
    const { data: buildNumber } = useGetAppVersionCalculationReviewBuildNumber();
    const { functionalAccess, isFetchingAccess } = useFunctionalAccess();

    const rootClass = mergeStyles({
        displayName: 'appRoot',
        height: '100vh',
        width: '100vw',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        ':focus, :focus-within, :focus-visible': {
            outline: 'solid',
        },
    });

    useEffect(() => {
        if (appInsightsConnectionString && claims?.environmentName && buildNumber) {
            initializeAppInsights(appInsightsConnectionString, claims?.environmentName, buildNumber);
        }
    }, [appInsightsConnectionString, claims?.environmentName, buildNumber]);

    const mainContentWrapperClass = mergeStyles({
        displayName: 'mainContentWrapper',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowX: 'hidden',
        overflowY: 'hidden',
    });

    const isAnalyticsRoute: boolean = window.location.pathname.includes('/analytics');
    const tabsWrapperClass = mergeStyles({
        displayName: 'tabsWrapper',
        display: isAnalyticsRoute ? 'none' : undefined,
        flexShrink: 1,
    });

    const tableWrapperClass = mergeStyles({
        displayName: 'tableWrapper',
        minWidth: '450px',
        flexGrow: 1,
    });

    const routerOutletLayoutClass = mergeStyles({
        padding: '0 0',
        margin: '0px',
        border: 'none',
    });

    const navbarWrapperClass = mergeStyles({
        displayName: 'navbarWrapper',
        flexShrink: 0,
        // Had to override this style in the shared component to avoid the content from overflowing
        '& ul': {
            boxSizing: 'border-box',
        },
        // When the common component does not have multiple items it lacks padding,
        // so I set the width here (matching the common component max-width)
        '& nav': {
            width: NAVBAR_SIZE,
        },
    });

    const topbarWrapperClass = mergeStyles({
        displayName: 'topbarWrapper',
        flexShrink: 0,
        minHeight: HEADER_SIZE,
        maxHeight: HEADER_SIZE,
        '> div': {
            paddingLeft: '10px',
            minHeight: HEADER_SIZE,
            maxHeight: HEADER_SIZE,
        },
    });

    const redirectToLoginPage = () => {
        window.location.replace(LOGIN_URL);
    };

    const checkForReportsAccess = () => {
        if (functionalAccess?.reportsButtonEnabled === false) {
            if (homeUrl !== undefined) {
                window.location.replace(homeUrl);
            } else {
                const sessionId = sessionClaims?.find((c) => c.type === 'sid')?.value || '';
                window.location.replace(getLogoutUrl(sessionId));
            }
        }
    };

    const mainContentClass = mergeStyles({
        displayName: 'mainContent',
        height: `calc(100vh - ${HEADER_SIZE})`,
    });

    if (isError) {
        redirectToLoginPage();
    }

    checkForReportsAccess();

    return (
        <Stack data-testid='RootApp' className={`${rootClass}`}>
            <CookiesProvider defaultSetOptions={{ path: '/' }}>
                <Stack enableScopedSelectors horizontal>
                    {isLoading || isError || isFetchingAccess ? (
                        <Stack data-testid='blank-loading-screen'></Stack>
                    ) : (
                        <>
                            <Stack className={mainContentWrapperClass} grow data-is-scrollable='true'>
                                <main>
                                    <Stack.Item className={topbarWrapperClass}>
                                        <Topbar />
                                    </Stack.Item>
                                    <Stack enableScopedSelectors horizontal className={mainContentClass}>
                                        <Stack.Item className={navbarWrapperClass}>
                                            <Navbar />
                                        </Stack.Item>
                                        <Stack.Item className={tabsWrapperClass}>
                                            <ReportListPanel />
                                        </Stack.Item>
                                        <Stack className={tableWrapperClass}>
                                            <Stack.Item className={routerOutletLayoutClass}>
                                                <FeedbackMessagesContextProvider>
                                                    <Outlet />
                                                </FeedbackMessagesContextProvider>
                                            </Stack.Item>
                                            <FooterContainer />
                                        </Stack>
                                    </Stack>
                                </main>
                            </Stack>
                        </>
                    )}
                </Stack>
            </CookiesProvider>
        </Stack>
    );
}

export default App;
