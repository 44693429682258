import { concatStyleSets, IButtonStyles, IIconProps, IIconStyles } from '@fluentui/react';
import CustomIconButton, { IIconButtonProps } from './IconButton';

export interface INarrowIconButtonProps extends IIconButtonProps {
    styles?: IButtonStyles;
}
export const NarrowIconButton: React.FC<INarrowIconButtonProps> = (props: INarrowIconButtonProps) => {
    const defaultButtonStyles: IButtonStyles = {
        root: {
            padding: '2px 8px',
            height: 'auto',
        },
    };
    const defaultButtonIconStyles: IIconStyles = {
        root: { width: 20, height: 20, lineHeight: 20 },
    };
    const className: IButtonStyles = concatStyleSets(defaultButtonStyles, props.styles);
    const iconProps: IIconProps = {
        ...props.iconProps,
        styles: defaultButtonIconStyles,
    };
    return <CustomIconButton {...props} styles={className} onButtonClick={props.onClick} iconProps={iconProps} />;
};
