import { useCorptaxTheme } from '@corptax/react-components-common';
import {
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    IStackTokens,
    Label,
    mergeStyles,
    mergeStyleSets,
    PrimaryButton,
    Stack,
    Toggle,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IAppTheme } from '../../../theme/IAppTheme';
import { isUndefinedOrEmpty } from '../../../utils/StringUtils';
import { VARIANCE_DISPLAY_FORMAT, VARIANCE_TYPE } from '../../../utils/Variance.constants';
import MaskTextField from '../../common/MaskTextField';

const choiceGroupStyles = mergeStyleSets({
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        selectors: {
            '& > *': {
                marginRight: '8px',
            },
            '& > *:last-child': {
                marginRight: '0',
            },
        },
    },
});

const defaultGapStackTokens: IStackTokens = {
    childrenGap: '10px',
};

const toggleStyles = {
    root: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
    },
    label: {
        margin: 'unset',
    },
    container: {
        marginLeft: '16px',
    },
};

const amountSymbol = '$';
const percentageSymbol = '%';

export interface IVarianceThresholdPanelProps {
    varianceThreshold: any;
    displayVarianceThreshold: any;
    varianceType: any;
    varianceDisplayFormat: any;
    shouldShowOnlyExceedingRows: boolean;
    setVarianceThreshold: (value: any) => void;
    setDisplayVarianceThreshold: (value: any) => void;
    setVarianceType: (value: any) => void;
    setVarianceDisplayFormat: (value: any) => void;
    saveChangesAndCloseDialog: () => void;
    toggleIsDialogVisible: () => void;
    setShouldShowOnlyExceedingRows: (value: boolean) => void;
}

const VarianceThresholdPanel: FC<IVarianceThresholdPanelProps> = ({
    varianceThreshold,
    displayVarianceThreshold,
    varianceType = VARIANCE_TYPE.AMOUNT,
    varianceDisplayFormat = VARIANCE_DISPLAY_FORMAT.AMOUNT,
    shouldShowOnlyExceedingRows = false,
    setVarianceThreshold,
    setDisplayVarianceThreshold,
    setVarianceType,
    setVarianceDisplayFormat,
    saveChangesAndCloseDialog,
    toggleIsDialogVisible,
    setShouldShowOnlyExceedingRows,
}) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const getVarianceThresholdPanelStyles = () =>
        mergeStyleSets({
            modalContentStyles: {
                maxWidth: '100%',
                width: '400px',
                height: 'auto',
                boxSizing: 'border-box',
                backgroundColor: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E7E8E9, #E7E8E9)',
                boxShadow: '0px 25.6px 57.6px 0px rgba(0, 0, 0, 0.22)',
                borderRadius: '12px',
            },
            cardHeaderStyles: {
                borderBottom: '1px solid rgba(231, 232, 233, 1)',
                padding: '16px 24px',
            },
            cardHeaderTextStyles: {
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '28px',
                letterSpacing: '-0.256px',
                textAlign: 'left',
                margin: '0',
            },
            setThresholdSectionStyles: {
                width: '400px',
                height: 'auto',
                gap: '16px',
                boxSizing: 'border-box',
                padding: '20px',
            },
            setTextStyles: {
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '-0.128px',
                textAlign: 'left',
                margin: '0',
            },
            buttonStyles: {
                width: '56px',
                height: '48px',
                borderRadius: '6px',
                border: '1px solid rgba(231, 232, 233, 1)',
                backgroundImage: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E7E8E9, #E7E8E9)',
                boxShadow: '0px 5px 4px -4px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                cursor: 'pointer',
                minWidth: '56px',
                selectors: {
                    '> span': {
                        color: customPalette.blueDark,
                    },
                },
            },
            cardFooterStyles: {
                backgroundColor: 'rgba(251, 251, 252, 1)',
                padding: '16px 20px',
            },
            defaultButton: {
                borderRadius: '6px',
            },
            primaryButton: {
                backgroundColor: customPalette.themePrimary,
                borderRadius: '6px',
                border: 'none',
                selectors: {
                    '&:hover': {
                        border: 'none',
                        backgroundColor: customPalette.themePrimary,
                    },
                },
            },
        });

    const buttonLikeStyles = {
        root: {
            color: customPalette.blueDark,
            display: 'flex',
            alignItems: 'center',
            height: '46px',
            width: '171px',
            padding: '12px',
            margin: '0 8px 8px 0',
            border: '1px solid',
            borderColor: customPalette.neutralSecondaryAlt,
            borderRadius: '8px',
            boxShadow: '0px 5px 4px -4px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E7E8E9, #E7E8E9)',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '16px',
            letterSpacing: '0px',
            textAlign: 'left',
            selectors: {
                ':hover': {
                    color: customPalette.themePrimary,
                    borderColor: customPalette.themePrimary,
                },
            },
        },
        field: {
            selectors: {
                '.ms-ChoiceFieldLabel': {
                    marginLeft: '8px',
                },
            },
        },
    };
    const selectedButtonLikeStyles = {
        root: {
            color: customPalette.themePrimary,
            display: 'flex',
            alignItems: 'center',
            height: '46px',
            width: '171px',
            padding: '12px',
            margin: '0 8px 8px 0',
            border: '1px solid',
            borderColor: customPalette.themePrimary,
            borderRadius: '8px',
            boxShadow: '0px 5px 4px -4px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E7E8E9, #E7E8E9)',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '16px',
            letterSpacing: '0px',
            textAlign: 'left',
            selectors: {
                ':hover': {
                    color: customPalette.themePrimary,
                    borderColor: customPalette.themePrimary,
                },
            },
        },
        field: {
            selectors: {
                '.ms-ChoiceFieldLabel': {
                    marginLeft: '8px',
                },
            },
        },
    };
    const getActiveButtonStyle = (buttonStyles: string) =>
        mergeStyles(buttonStyles, {
            color: customPalette.themePrimary,
            borderColor: customPalette.themePrimary,
            selectors: {
                '> span': {
                    fontSize: '14px',
                    color: customPalette.themePrimary,
                },
            },
        });

    const {
        buttonStyles,
        cardFooterStyles,
        cardHeaderStyles,
        cardHeaderTextStyles,
        defaultButton,
        modalContentStyles,
        primaryButton,
        setTextStyles,
        setThresholdSectionStyles,
    } = getVarianceThresholdPanelStyles();
    const activeButtonStyle = getActiveButtonStyle(buttonStyles);
    const textFieldId = useId('variance-threshold-text-input');

    const onChangeVarianceThresholdHandler = (event: any, value: string | undefined) => {
        const inputValue = value || '';

        setDisplayVarianceThreshold(inputValue);

        if (!isUndefinedOrEmpty(inputValue)) {
            const valueWithoutCommas = inputValue.replace(/,/g, '');
            const parsedValue = parseFloat(valueWithoutCommas);

            if (!isNaN(parsedValue)) {
                setVarianceThreshold(parsedValue);
            } else {
                setVarianceThreshold('');
            }
        } else {
            setVarianceThreshold('');
        }
    };

    const changeVarianceType = (newType: string) => {
        setVarianceType(newType);
        setVarianceThreshold('');
        setDisplayVarianceThreshold('');
    };

    const options: IChoiceGroupOption[] = [
        {
            key: VARIANCE_DISPLAY_FORMAT.AMOUNT,
            text: t('numerical'),
            styles: varianceDisplayFormat === VARIANCE_DISPLAY_FORMAT.AMOUNT ? selectedButtonLikeStyles : buttonLikeStyles,
        },
        {
            key: VARIANCE_DISPLAY_FORMAT.PERCENTAGE,
            text: t('percentage'),
            styles: varianceDisplayFormat === VARIANCE_DISPLAY_FORMAT.PERCENTAGE ? selectedButtonLikeStyles : buttonLikeStyles,
        },
    ];

    const _onChange = (ev: any, option: IChoiceGroupOption | undefined) => {
        setVarianceDisplayFormat(option?.key);
    };

    const updateShouldShowOnlyExceedingRowsValue = (_: any, checked?: boolean | undefined) => {
        setShouldShowOnlyExceedingRows(checked ?? false);
    };

    return (
        <Stack className={modalContentStyles} role='menu'>
            <Stack className={cardHeaderStyles} role='menuitem'>
                <h1 className={cardHeaderTextStyles}>{t('setYourVarianceThreshold')}</h1>
            </Stack>
            <Stack className={setThresholdSectionStyles} role='menu'>
                <Stack.Item>
                    <Label htmlFor={textFieldId} className={setTextStyles}>
                        {t('setThreshold')}:
                    </Label>
                    <Stack horizontal tokens={defaultGapStackTokens} role='menuitem'>
                        <MaskTextField
                            testid={t('varianceThresholdInput').toString()}
                            id={textFieldId}
                            type='text'
                            value={displayVarianceThreshold}
                            prefix={varianceType === VARIANCE_TYPE.AMOUNT ? amountSymbol : percentageSymbol}
                            onChange={onChangeVarianceThresholdHandler}
                        />
                        <DefaultButton
                            className={varianceType === VARIANCE_TYPE.AMOUNT ? activeButtonStyle : buttonStyles}
                            ariaLabel={t('amountThreshold').toString()}
                            onClick={() => changeVarianceType(VARIANCE_TYPE.AMOUNT)}
                        >
                            {amountSymbol}
                        </DefaultButton>
                        <DefaultButton
                            className={varianceType === VARIANCE_TYPE.PERCENTAGE ? activeButtonStyle : buttonStyles}
                            ariaLabel={t('percentageThreshold').toString()}
                            onClick={() => changeVarianceType(VARIANCE_TYPE.PERCENTAGE)}
                        >
                            {percentageSymbol}
                        </DefaultButton>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack.Item tokens={defaultGapStackTokens}>
                        <ChoiceGroup
                            role='menuitem'
                            defaultSelectedKey={varianceDisplayFormat}
                            options={options}
                            onChange={_onChange}
                            aria-label={t('showTableVarianceAs').toString()}
                            label={t('showTableVarianceAs').toString()}
                            styles={{
                                flexContainer: choiceGroupStyles.flexContainer,
                            }}
                        />
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        role='menuitemcheckbox'
                        checked={shouldShowOnlyExceedingRows}
                        styles={toggleStyles}
                        label={t('hideRowsNotExceedingThreshold').toString()}
                        inlineLabel
                        onChange={updateShouldShowOnlyExceedingRowsValue}
                    />
                </Stack.Item>
            </Stack>
            <Stack horizontalAlign='end' tokens={defaultGapStackTokens} horizontal className={cardFooterStyles} role='menuitem'>
                <PrimaryButton ariaLabel={t('setThreshold').toString()} className={primaryButton} onClick={saveChangesAndCloseDialog}>
                    {t('setThreshold')}
                </PrimaryButton>
                <DefaultButton ariaLabel={t('cancel').toString()} className={defaultButton} onClick={toggleIsDialogVisible}>
                    {t('cancel')}
                </DefaultButton>
            </Stack>
        </Stack>
    );
};

export default VarianceThresholdPanel;
