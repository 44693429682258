import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { useCommentsUIStyles } from '../../hooks/useCommentsUIStyles';

export interface ICommentsPanelConfirmCloseDialogProps {
    isOpen: boolean;
    onDiscard: () => void;
    onCancel: () => void;
}

export const CommentsPanelConfirmCloseDialog = (props: ICommentsPanelConfirmCloseDialogProps) => {
    const { t } = useTranslation();
    const { state: uiState } = useCommentsUI();

    const discardLabel = uiState.isSubmitting ? t('yes') : t('discard');
    const cancelLabel = uiState.isSubmitting ? t('no') : t('cancel');
    const closeLabel = t('close');
    const dialogHeading = uiState.isSubmitting
        ? t('commentsPanelConfirmCloseDialogHeadingSending')
        : t('commentsPanelConfirmCloseDialogHeadingUnsaved');
    const dialogText = uiState.isSubmitting
        ? t('commentsPanelConfirmCloseDialogTextSending')
        : t('commentsPanelConfirmCloseDialogTextUnsaved');

    const styles = useCommentsUIStyles();

    return (
        <Dialog
            hidden={!props.isOpen}
            onDismiss={props.onCancel}
            dialogContentProps={{
                type: DialogType.normal,
                title: dialogHeading,
                closeButtonAriaLabel: closeLabel,
                subText: dialogText,
            }}
            modalProps={{
                isBlocking: true,
                styles: styles.fluentV9DialogAdjustment,
            }}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={props.onDiscard}
                    text={discardLabel}
                    ariaLabel={discardLabel}
                    styles={styles.fluentV9ButtonAdjustment}
                />
                <DefaultButton
                    onClick={props.onCancel}
                    text={cancelLabel}
                    ariaLabel={cancelLabel}
                    styles={styles.fluentV9ButtonAdjustment}
                />
            </DialogFooter>
        </Dialog>
    );
};
