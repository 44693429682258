import { corptaxThemeTokens, NavRail, useFilteredNavItems } from '@corptax/react-components-common';
import { INavigationItem } from '@corptax/react-components-common/dist/types/view-models/navigation/INavigationItem';
import { mergeStyles } from '@fluentui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFeatureEnabled } from '../../api/features/features';
import { useGetNavigationItems } from '../../api/navigation/navigation';
import { NAVBAR_SIZE } from '../../utils/Style.constants';

export const Navbar: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { data: isAnalyticsPreviewEnabled, isLoading: isFeatureFlagLoading } = useIsFeatureEnabled({
        featureFlagName: 'AnalyticsPreviewEnabled',
    });
    const { data: unfilteredNavItems, isLoading: isNavItemsLoading } = useGetNavigationItems();
    const navItems = useFilteredNavItems((unfilteredNavItems as INavigationItem[]) ?? [], '/');

    const loadingStyles = mergeStyles({
        width: NAVBAR_SIZE,
        backgroundColor: corptaxThemeTokens.colorBrandBackground,
        color: corptaxThemeTokens.colorNeutralForegroundOnBrand,
        height: '100%',
    });

    if (isNavItemsLoading || isFeatureFlagLoading) return <div className={loadingStyles}>{t('loading')}</div>;

    if (isAnalyticsPreviewEnabled) {
        const analyticsNavItem: INavigationItem = {
            displayName: t('analytics'),

            // TODO: Specify desired icon
            iconName: undefined,

            // ID is a random GUID since this is not part of collateral
            id: 'f5dd7c5e-483b-46a1-b75c-cdb06b0ac4a3',

            routeUrl: '/analytics',
            target: '_self',
        };

        navItems.splice(1, 0, analyticsNavItem);
    }

    return <NavRail items={navItems}></NavRail>;
};
