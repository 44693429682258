/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetAppVersionCalculationReviewVersionHook = () => {
    const getAppVersionCalculationReviewVersion = useCustomInstance<string>();

    return (signal?: AbortSignal) => {
        return getAppVersionCalculationReviewVersion({ url: `/AppVersion/CalculationReviewVersion`, method: 'get', signal });
    };
};

export const getGetAppVersionCalculationReviewVersionQueryKey = () => [`/AppVersion/CalculationReviewVersion`] as const;

export const useGetAppVersionCalculationReviewVersionQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewVersionHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewVersionHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewVersionHook>>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAppVersionCalculationReviewVersionQueryKey();

    const getAppVersionCalculationReviewVersion = useGetAppVersionCalculationReviewVersionHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewVersionHook>>>> = ({ signal }) =>
        getAppVersionCalculationReviewVersion(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAppVersionCalculationReviewVersionQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewVersionHook>>>
>;
export type GetAppVersionCalculationReviewVersionQueryError = ErrorType<unknown>;

export const useGetAppVersionCalculationReviewVersion = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewVersionHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewVersionHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAppVersionCalculationReviewVersionQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetAppVersionCalculationReviewBuildNumberHook = () => {
    const getAppVersionCalculationReviewBuildNumber = useCustomInstance<string>();

    return (signal?: AbortSignal) => {
        return getAppVersionCalculationReviewBuildNumber({ url: `/AppVersion/CalculationReviewBuildNumber`, method: 'get', signal });
    };
};

export const getGetAppVersionCalculationReviewBuildNumberQueryKey = () => [`/AppVersion/CalculationReviewBuildNumber`] as const;

export const useGetAppVersionCalculationReviewBuildNumberQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewBuildNumberHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewBuildNumberHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewBuildNumberHook>>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAppVersionCalculationReviewBuildNumberQueryKey();

    const getAppVersionCalculationReviewBuildNumber = useGetAppVersionCalculationReviewBuildNumberHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewBuildNumberHook>>>> = ({ signal }) =>
        getAppVersionCalculationReviewBuildNumber(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAppVersionCalculationReviewBuildNumberQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewBuildNumberHook>>>
>;
export type GetAppVersionCalculationReviewBuildNumberQueryError = ErrorType<unknown>;

export const useGetAppVersionCalculationReviewBuildNumber = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewBuildNumberHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAppVersionCalculationReviewBuildNumberHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAppVersionCalculationReviewBuildNumberQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
