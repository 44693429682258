import { useContext } from 'react';
import { GridStateContext, IGridStateContextValues } from '../contexts/gridStateContext';

export const useGridState = (): IGridStateContextValues => {
    const context = useContext(GridStateContext);

    if (!context) {
        throw new Error('useGridContext must be used within a GridStateContextProvider');
    }

    return context;
};
