import { IColumn } from '@fluentui/react';
import { getValue } from '@syncfusion/ej2-base';
import { RowDataBoundEventArgs } from '@syncfusion/ej2-grids';
import { ColumnModel, GridComponent, IIndex, QueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids';
import { ICurrentCellEventArgs } from '../data-types/ICurrentCellEventArgs';
import { IWhitePaperColumn, IWhitePaperComparedValue, IWhitePaperRow, WhitePaperRowStyle } from '../model';
import { TOTAL_ROW_NUMBER } from './Table.constants';

export const WHITE_PAPER_CELL_BLUE_CLASS = 'white-paper-cell-blue';
export const WHITE_PAPER_CELL_BOLD_CLASS = 'white-paper-cell-bold';
const GRID_FOCUSED_CELL_CLASS = 'e-focused';
const GRID_FOCUS_CELL_CLASS = 'e-focus';
const GRID_CELL_ROW_INDEX_ATTRIBUTE_NAME = 'index';
const GRID_CELL_COL_INDEX_ATTRIBUTE_NAME = 'data-colindex';
const DISTANCE_TO_ENTITY_VARIANCE_COLUMN = 2;

export function getUpdatedColumnSortingState(
    columns: IColumn[],
    activeColumn: IColumn,
    headerStyles?: (isSorted: boolean) => string
): IColumn[] {
    let newColumns: IColumn[] = columns.slice();
    newColumns = getInactiveColumnsWithClearedSortingState(newColumns, activeColumn.key, headerStyles);

    const currentColumn: IColumn = newColumns.filter((currCol) => activeColumn.key === currCol.key)[0];
    currentColumn.isSortedDescending = getIsSortedDescendingForColumn(currentColumn);
    currentColumn.isSorted = true;
    if (headerStyles !== undefined) {
        currentColumn.headerClassName = headerStyles(true);
    }

    return newColumns;
}

export function getInactiveColumnsWithClearedSortingState(
    columns: IColumn[],
    activeColumnKey: string,
    headerStyles?: (isSorted: boolean) => string
): IColumn[] {
    return columns.map((col: IColumn) => {
        if (col.key !== activeColumnKey) {
            col.isSorted = false;
            col.isSortedDescending = true;
            if (headerStyles !== undefined) {
                col.headerClassName = headerStyles(false);
            }
        }

        return col;
    });
}

export function getIsSortedDescendingForColumn(column: IColumn): boolean {
    return column.isSortedDescending !== undefined ? !column.isSortedDescending : false;
}

export function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => {
        if (a[key] instanceof Date && b[key] instanceof Date) {
            return isSortedDescending ? (b[key] as number) - (a[key] as number) : (a[key] as number) - (b[key] as number);
        }
        return (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1;
    });
}

export const formatAmount = (amount: number | null): string => {
    if (amount === null) {
        return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    if (amount < 0) {
        return '(' + formatter.format(amount).replace('-', '') + ')';
    }

    return formatter.format(amount);
};

export const addStylesToTotalRow = (args: RowDataBoundEventArgs) => {
    if (args.row) {
        if (getValue('displayRowNumber', args.data) === TOTAL_ROW_NUMBER) {
            args.row.classList.add('bg-total');
        }
    }
};

export const getHeaderDateBasedOnColumnId = (compareDate: string): string => {
    const options: any = { month: 'numeric', day: 'numeric', year: '2-digit' };
    const timeOptions: any = { hour: '2-digit', minute: '2-digit' };
    if (compareDate === '') {
        return new Date().toLocaleString('en-us', options) + ' - ' + new Date().toLocaleTimeString('en-us', timeOptions) ?? '';
    }
    return (
        new Date(compareDate).toLocaleString('en-us', options) + ' - ' + new Date(compareDate).toLocaleTimeString('en-us', timeOptions) ??
        ''
    );
};

export const getAmountAsNumberOrDefault = (reportRow: IWhitePaperRow, columnFieldName: string): string | null => {
    return getCellValueForColumnInWhitePaperRow(
        reportRow,
        columnFieldName,
        (columnValue: IWhitePaperComparedValue) => columnValue.current ?? null
    );
};

export const getCellValueForColumnInWhitePaperRow = <T>(
    reportRow: IWhitePaperRow,
    columnName: string,
    cellValueSelector: (columnValue: IWhitePaperComparedValue) => T
): T | null => {
    if (reportRow.values) {
        const columnValue: IWhitePaperComparedValue = reportRow.values[columnName];
        if (columnValue) {
            return cellValueSelector(columnValue);
        }
    }

    return null;
};

export const getClassNameForStyle = (style: WhitePaperRowStyle): string => {
    switch (style) {
        case WhitePaperRowStyle.Blue:
            return WHITE_PAPER_CELL_BLUE_CLASS;
        case WhitePaperRowStyle.Bold:
            return WHITE_PAPER_CELL_BOLD_CLASS;
    }
};

export const setFocusOnSyncfusionGridCell = (cellToSelect: HTMLElement | undefined): void => {
    if (cellToSelect) {
        cellToSelect.classList.add(GRID_FOCUSED_CELL_CLASS, GRID_FOCUS_CELL_CLASS);
        cellToSelect.focus();
    }
};

export const getIndexObjectBasedOnCellElement = (element: HTMLElement): IIndex => {
    return {
        rowIndex: parseInt(element.getAttribute(GRID_CELL_ROW_INDEX_ATTRIBUTE_NAME) ?? '0'),
        cellIndex: parseInt(element.getAttribute(GRID_CELL_COL_INDEX_ATTRIBUTE_NAME) ?? '0'),
    };
};

export const getCellHtmlElementByIndex = (
    grid: GridComponent | null,
    rowIndex: number,
    cellIndex: number,
    isVariance: boolean = false
): HTMLElement | null => {
    const containerElement: Element | Document = grid ? grid.getContent() : document;
    const targetColumn: ColumnModel = grid?.columns[cellIndex] as ColumnModel;
    // With column virtualization, for some reason the col-index property on cells changes and it does not match the column index
    // We are getting the column information to find the DOM element based on that

    if (targetColumn) {
        const matchingEntityColumnHeaders = Array.from(document.querySelectorAll(`div[e-mappinguid="${targetColumn.uid}"]`));
        const targetColumnHeader = matchingEntityColumnHeaders.filter((header) => {
            return (header as HTMLElement).outerText === targetColumn.headerText;
        })[0];

        if (!targetColumnHeader) return null;

        const targetColumnHeaderTD = (targetColumnHeader as HTMLElement)?.parentElement as HTMLElement;
        let targetColumnHeaderIndex = parseInt(targetColumnHeaderTD?.getAttribute(GRID_CELL_COL_INDEX_ATTRIBUTE_NAME) ?? '0');

        if (isVariance) {
            targetColumnHeaderIndex += DISTANCE_TO_ENTITY_VARIANCE_COLUMN;
        }

        return containerElement.querySelector(
            `td[${GRID_CELL_ROW_INDEX_ATTRIBUTE_NAME}="${rowIndex}"][${GRID_CELL_COL_INDEX_ATTRIBUTE_NAME}="${targetColumnHeaderIndex}"]`
        );
    }

    return null;
};

export const getCellByColumnIndex = (columnIndex: number, cells: NodeListOf<Element> | undefined): HTMLElement | null => {
    if (cells) {
        return (Array.from(cells).find((cell) => cell.getAttribute('data-colindex') === columnIndex.toString()) as HTMLElement) || null;
    }

    return null;
};

export const focusOnButtonContainedByCell = (cellIndex: IIndex) => {
    const newCell = getCellHtmlElementByIndex(null, cellIndex.rowIndex ?? 0, cellIndex.cellIndex ?? 0) as HTMLElement;

    if (newCell) {
        const childButton = newCell.querySelector('button') as HTMLElement;
        childButton.focus();
    }
};

export const clickOnVarianceContainedByCell = (grid: GridComponent | null, cellIndex: IIndex) => {
    const varianceCell = getCellHtmlElementByIndex(grid, cellIndex.rowIndex ?? 0, cellIndex.cellIndex ?? 0, true) as HTMLElement;

    if (varianceCell) {
        setFocusOnSyncfusionGridCell(varianceCell);
        varianceCell.click();
    }
};

export const focusOnFirstCellFromRow = (rowIndex: number, grid?: GridComponent) => {
    if (grid) {
        const cellToSelect = getCellHtmlElementByIndex(grid, rowIndex, 0) as HTMLElement;

        setFocusOnSyncfusionGridCell(cellToSelect);
    }
};

export const getFirstFocusableElementInForm = (form: HTMLFormElement): HTMLInputElement => {
    const elements = Array.from(form.elements);

    return elements.find((element) => !(element as HTMLInputElement).disabled) as HTMLInputElement;
};

export const isRowInCache = (gridComponent: GridComponent, rowIndex: number): boolean => {
    const blockIndexes = ((gridComponent.contentModule as any).prevInfo as any).blockIndexes;
    const cache = (gridComponent.contentModule as any).vgenerator.cache;
    let isRowInCache = false;

    for (let i = 0; i < blockIndexes.length; i++) {
        if (isRowInCache) {
            break;
        }

        isRowInCache = rowExistsInCacheBlock(rowIndex, cache[blockIndexes[i]]);
    }

    return isRowInCache;
};

const rowExistsInCacheBlock = (rowIndex: number, cacheBlockRows: any[]): boolean => {
    for (let j = 0; j < cacheBlockRows.length; j++) {
        if (cacheBlockRows[j].index === rowIndex) {
            return true;
        }
    }

    return false;
};

export const getTableKeyWithVisibleFields = (visibleFields: string[], versionId: string): string => {
    const visibleFieldsHash = visibleFields
        ? visibleFields.map((fieldName: string) => fieldName.charAt(fieldName.length / 2)).join('')
        : '';
    return `tax-return-grid-${visibleFieldsHash}${versionId}`;
};

export const getTableKeyWithColumnsFilters = (aliasTable: string, columnsFilters: Record<string, any[]>): string => {
    const columnValueHash = JSON.stringify(columnsFilters);
    return `${aliasTable}${columnValueHash}`;
};

export const scrollToTop = (grid: GridComponent | null) => {
    if (grid && grid.getContent().children[0]) {
        grid.getContent().children[0].scrollTop = 0;
    }
};

export const scrollToRow = (grid: GridComponent | null, rowIndex: number): number | null => {
    if (grid && grid.getContent().children[0]) {
        const rowHeight = grid.rowHeight || 0;
        const isRowRendered = isRowInCache(grid, rowIndex);

        if (isRowRendered) {
            grid.selectRow(rowIndex);
        } else {
            grid.getContent().children[0].scrollTop = rowHeight * rowIndex;
            return rowIndex;
        }
    }

    return null;
};

export const scrollToX = (grid: GridComponent | null, xPosition: number) => {
    if (grid && grid.getContent().children[0]) {
        grid.getContent().children[0].scrollLeft = xPosition;
    }
};

export const highlightSubstringWithClass = (
    cell: Element,
    substring: string,
    className: string,
    shouldSearchDeepestChild: boolean = false
) => {
    if (shouldSearchDeepestChild) {
        const deepestChild = getTemplateCellDeepestChild(cell);

        if (deepestChild) {
            deepestChild.innerHTML = deepestChild.innerHTML.replaceAll(substring, `<span class='${className}'>${substring}</span>`);
        }
    } else {
        cell.innerHTML = cell.innerHTML.replaceAll(substring, `<span class='${className}'>${substring}</span>`);
    }
};

export const getTemplateCellDeepestChild = (element: Element): Element => {
    let deepestChild: Element = element;
    let maxDepth: number = 0;

    const traverse = (node: Element, depth: number): void => {
        if (depth > maxDepth) {
            deepestChild = node;
            maxDepth = depth;
        }
        Array.from(node.children).forEach((child: Element) => {
            traverse(child, depth + 1);
        });
    };

    traverse(element, 0);
    return deepestChild;
};

export const shouldUpdateAriaLabel = (args: QueryCellInfoEventArgs): boolean => {
    return args.column !== undefined && args.column.editType === 'numericedit';
};

export const getWhitePaperReportHeaderTemplate = (column: IWhitePaperColumn): string | undefined => {
    if (column.headingFirstLine && column.headingSecondLine) {
        return `<div class="headerTextWrap">
            <label>${column.headingFirstLine}</label>
            <label>${column.headingSecondLine}</label>
        </div>`;
    }

    return undefined;
};

export const handleSearchAction = (
    gridRef: GridComponent | null,
    setIsDisabledVarianceGoToNavigation: (value: boolean) => void,
    setCurrentVarianceIndex: (value: number) => void
) => {
    const emptySearch: boolean = !gridRef || gridRef.searchSettings.key === '';
    setIsDisabledVarianceGoToNavigation(!emptySearch);
    setCurrentVarianceIndex(0);

    if (gridRef) {
        scrollToTop(gridRef);
    }
};

export const calculateNextVarianceStep = (currentVarianceIndex: number, variancePointsLength: number, isNext: boolean): number => {
    let step = currentVarianceIndex;

    if (isNext) {
        step += 1;
    } else {
        step -= 1;
    }

    if (step <= 0) {
        step = variancePointsLength;
    }

    if (step > variancePointsLength) {
        step = 1;
    }
    return step;
};

export const getColumnNameFromCurrentComparedColumnName = (eventArgs: ICurrentCellEventArgs<IWhitePaperRow>) => {
    return eventArgs?.fieldName?.replace('values.', '').replace('.current', '');
};
