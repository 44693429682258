import { IIndex } from '@syncfusion/ej2-react-grids';
import { MenuEventArgs } from '@syncfusion/ej2-react-navigations';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportStatus } from '../../model/reportStatus';
import { IAppTheme } from '../../theme/IAppTheme';
import { getIndexObjectBasedOnCellElement } from '../../utils/TableUtils';

export interface IReportStatusButtonProps {
    disabled?: boolean;
    status: ReportStatus;
    onChange?: (key: ReportStatus) => void;
    isCell?: boolean;
    setSelectedCellIndex?: (element: IIndex | undefined) => void;
    tabIndex?: number;
}

interface IAppThemeWithStatus {
    $status: ReportStatus;
    theme: IAppTheme;
}

const getButtonStyles = (props: IAppThemeWithStatus) => {
    switch (props.$status) {
        case ReportStatus.InPreparation: {
            return {
                backgroundColor: props.theme.customPalette.statusLightBlue,
                hoverBackgroundColor: props.theme.customPalette.statusMidBlue,
                activeBackgroundColor: props.theme.customPalette.statusDarkBlue,
                border: 'none',
            };
        }
        case ReportStatus.OnHold: {
            return {
                backgroundColor: props.theme.customPalette.statusLightCyan,
                hoverBackgroundColor: props.theme.customPalette.statusMidCyan,
                activeBackgroundColor: props.theme.customPalette.statusDarkCyan,
                border: 'none',
            };
        }
        case ReportStatus.InReview: {
            return {
                backgroundColor: props.theme.customPalette.statusLightOrange,
                hoverBackgroundColor: props.theme.customPalette.statusMidOrange,
                activeBackgroundColor: props.theme.customPalette.statusDarkOrange,
                border: 'none',
            };
        }
        case ReportStatus.Complete: {
            return {
                backgroundColor: props.theme.customPalette.statusLightGreen,
                hoverBackgroundColor: props.theme.customPalette.statusMidGreen,
                activeBackgroundColor: props.theme.customPalette.statusDarkGreen,
                border: 'none',
            };
        }
        default: {
            return {
                backgroundColor: props.theme.palette.white,
                hoverBackgroundColor: props.theme.customPalette.statusCoolMidGrey,
                activeBackgroundColor: props.theme.customPalette.statusCoolDarkGrey,
                border: `1px solid ${props.theme.customPalette.statusCoolLightGrey}`,
            };
        }
    }
};

//had to add this logic in a div because syncfusion has a bug where it clears its styles when status is changing
const StyledStatusDiv = styled.div<{ $status: string }>`
    & button.e-dropdown-btn {
        height: 24px;
        padding: 2px 8px;
        background-color: ${(props) => getButtonStyles(props as IAppThemeWithStatus).backgroundColor};
        border: ${(props) => getButtonStyles(props as IAppThemeWithStatus).border};
        border-radius: 18px;
        font-size: 12px;
        color: ${(props) => props.theme.palette.black};
        font-weight: 400;
        @media (forced-colors: active) {
            color: WindowText; // Adjust text color for high contrast
            border: 1px solid WindowText; // Make border visible and adapt to high contrast settings
        }
    }
    & button.e-dropdown-btn:hover {
        background-color: ${(props) => getButtonStyles(props as IAppThemeWithStatus).hoverBackgroundColor};
    }
    & button.e-dropdown-btn.e-active {
        background-color: ${(props) => getButtonStyles(props as IAppThemeWithStatus).activeBackgroundColor};
    }
`;

export const ReportStatusButton = (props: IReportStatusButtonProps) => {
    const { t } = useTranslation();

    const handleOnChange = (args: MenuEventArgs) => {
        if (props.onChange) {
            props.onChange(args.item.id as ReportStatus);
        }
    };

    const getIdForDropdownButtonAssociatedWithPopup = (): string | undefined => {
        return document?.activeElement?.closest('div')?.id.replace('-popup', '');
    };

    const getParentCellAsElement = (dropDownId: string): Element | null | undefined => {
        return document?.querySelector('#' + dropDownId)?.closest('.e-rowcell');
    };

    const setFocusOnContainingCell = (dropDownId: string): void => {
        const cell = getParentCellAsElement(dropDownId);
        if (cell) {
            (cell as HTMLElement).click();
        }
    };

    const keepFocusOnParentCell = () => {
        const dropDownId = getIdForDropdownButtonAssociatedWithPopup();

        if (dropDownId) {
            if (props.setSelectedCellIndex) {
                const parentElement = getParentCellAsElement(dropDownId) as HTMLElement;
                props.setSelectedCellIndex(getIndexObjectBasedOnCellElement(parentElement));
            }

            setFocusOnContainingCell(dropDownId);
        }
    };

    const popupBeforeClose = () => {
        if (props.isCell) {
            keepFocusOnParentCell();
        }
    };

    const items = Object.values(ReportStatus).map((x) => ({ text: t(x), id: x }));

    return (
        <StyledStatusDiv $status={props.status}>
            <DropDownButtonComponent
                aria-label={props.status}
                disabled={props.disabled || false}
                items={items}
                select={handleOnChange}
                beforeClose={popupBeforeClose}
                tabIndex={props.tabIndex}
            >
                {items.find((x) => x.id === props.status)?.text}
            </DropDownButtonComponent>
        </StyledStatusDiv>
    );
};
