import { ContextMenuItemModel, GridComponent } from '@syncfusion/ej2-react-grids';
import { ICommonIndividualReportTableContextMenuProps } from '../components/tables/common-individual-report-table/CommonIndividualReportTable';
import { IContextMenuArgs } from '../data-types/IContextMenuArgs';
import { IRowInfo } from '../data-types/IRowInfo';
import { IWhitePaperRow } from '../model';
import { IContextMenuItemOptions } from './IContextMenuItemOptions';
import { useCommentsContextMenuItem } from './useCommentsContextMenuItem';
import { useDrillDownContextMenuItem } from './useDrillDownContextMenuItem';
import { useEditCellContextMenuItem } from './useEditCellContextMenuItem';
import { useEnterAmountsContextMenuItem } from './useEnterAmountsContextMenuItem';

export interface ContextMenuItemModelExtended extends ContextMenuItemModel {
    visibilityAccessor?: ContextMenuVisibilityAccessor;
}

export declare type ContextMenuVisibilityAccessor = <T>(rowInfo: IRowInfo<T>) => ContextMenuItemDisplayState;

export enum ContextMenuItemDisplayState {
    Invisible = 'Invisible',
    Disabled = 'Disabled',
    Enabled = 'Enabled',
}

const updateMenuItemsUsability = (args: IContextMenuArgs<IWhitePaperRow>, grid: GridComponent) => {
    let shouldCancelMenuShowEvent: boolean = true;
    if (grid.contextMenuModule.contextMenu.items?.length > 0) {
        for (const menuItem of grid.contextMenuModule.contextMenu.items) {
            const menuItemExtended = menuItem as ContextMenuItemModelExtended;
            const visibilityState = menuItemExtended.visibilityAccessor!(args.rowInfo);
            switch (visibilityState) {
                case ContextMenuItemDisplayState.Disabled:
                    shouldCancelMenuShowEvent = false;
                    grid.contextMenuModule.contextMenu.enableItems([menuItem.id!], false, true);
                    break;
                case ContextMenuItemDisplayState.Enabled:
                    grid.contextMenuModule.contextMenu.enableItems([menuItem.id!], true, true);
                    grid.contextMenuModule.contextMenu.showItems([menuItem.id!], true);
                    shouldCancelMenuShowEvent = false;
                    break;
                case ContextMenuItemDisplayState.Invisible:
                    grid.contextMenuModule.contextMenu.hideItems([menuItem.id!], true);
                    shouldCancelMenuShowEvent = shouldCancelMenuShowEvent && true;
                    break;
            }
        }
    }

    args.cancel = shouldCancelMenuShowEvent;
};

export const useCommonIndividualReportTableContextMenu: (contextMenuProps?: ICommonIndividualReportTableContextMenuProps) => {
    menuItemOptions: IContextMenuItemOptions<IWhitePaperRow>[];
    updateMenuItemsUsability: (args: IContextMenuArgs<IWhitePaperRow>, grid: GridComponent) => void;
} = (contextMenuProps?: ICommonIndividualReportTableContextMenuProps) => {
    return {
        menuItemOptions: [
            useDrillDownContextMenuItem(contextMenuProps?.drillDown?.onMenuItemClick, contextMenuProps?.drillDown?.getVisibilityState),
            useEnterAmountsContextMenuItem(
                contextMenuProps?.enterAmounts?.onMenuItemClick,
                contextMenuProps?.enterAmounts?.getVisibilityState
            ),
            useCommentsContextMenuItem(contextMenuProps?.comments?.onMenuItemClick, contextMenuProps?.comments?.getVisibilityState),
            useEditCellContextMenuItem(contextMenuProps?.editCell?.onMenuItemClick, contextMenuProps?.editCell?.getVisibilityState),
        ] as IContextMenuItemOptions<IWhitePaperRow>[],
        updateMenuItemsUsability: updateMenuItemsUsability,
    };
};
