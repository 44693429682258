/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
    AddItemsToCartReponse,
    AddItemsToCurrentCartRequest,
    CreateNewCartRequest,
    CreateNewCartResponse,
    CurrentCartRequest,
    CurrentCartResponse,
    RecentCartResponse,
} from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useCreateNewCartHook = () => {
    const createNewCart = useCustomInstance<CreateNewCartResponse>();

    return (createNewCartRequest: CreateNewCartRequest) => {
        return createNewCart({
            url: `/Cart/CreateNewCart`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: createNewCartRequest,
        });
    };
};

export const useCreateNewCartMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateNewCartHook>>>,
        TError,
        { data: CreateNewCartRequest },
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNewCartHook>>>, TError, { data: CreateNewCartRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const createNewCart = useCreateNewCartHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateNewCartHook>>>, { data: CreateNewCartRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return createNewCart(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateNewCartMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateNewCartHook>>>>;
export type CreateNewCartMutationBody = CreateNewCartRequest;
export type CreateNewCartMutationError = ErrorType<unknown>;

export const useCreateNewCart = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateNewCartHook>>>,
        TError,
        { data: CreateNewCartRequest },
        TContext
    >;
}) => {
    const mutationOptions = useCreateNewCartMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useGetCurrentCartSelectorHook = () => {
    const getCurrentCartSelector = useCustomInstance<RecentCartResponse>();

    return (signal?: AbortSignal) => {
        return getCurrentCartSelector({ url: `/Cart/GetCurrentCartSelector`, method: 'get', signal });
    };
};

export const getGetCurrentCartSelectorQueryKey = () => [`/Cart/GetCurrentCartSelector`] as const;

export const useGetCurrentCartSelectorQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentCartSelectorHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentCartSelectorHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentCartSelectorHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetCurrentCartSelectorQueryKey();

    const getCurrentCartSelector = useGetCurrentCartSelectorHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCurrentCartSelectorHook>>>> = ({ signal }) =>
        getCurrentCartSelector(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetCurrentCartSelectorQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCurrentCartSelectorHook>>>>;
export type GetCurrentCartSelectorQueryError = ErrorType<unknown>;

export const useGetCurrentCartSelector = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentCartSelectorHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentCartSelectorHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCurrentCartSelectorQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useAddItemsToCurrentCartHook = () => {
    const addItemsToCurrentCart = useCustomInstance<AddItemsToCartReponse>();

    return (addItemsToCurrentCartRequest: AddItemsToCurrentCartRequest) => {
        return addItemsToCurrentCart({
            url: `/Cart/AddItemsToCurrentCart`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: addItemsToCurrentCartRequest,
        });
    };
};

export const useAddItemsToCurrentCartMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddItemsToCurrentCartHook>>>,
        TError,
        { data: AddItemsToCurrentCartRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddItemsToCurrentCartHook>>>,
    TError,
    { data: AddItemsToCurrentCartRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const addItemsToCurrentCart = useAddItemsToCurrentCartHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddItemsToCurrentCartHook>>>,
        { data: AddItemsToCurrentCartRequest }
    > = (props) => {
        const { data } = props ?? {};

        return addItemsToCurrentCart(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddItemsToCurrentCartMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddItemsToCurrentCartHook>>>>;
export type AddItemsToCurrentCartMutationBody = AddItemsToCurrentCartRequest;
export type AddItemsToCurrentCartMutationError = ErrorType<unknown>;

export const useAddItemsToCurrentCart = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddItemsToCurrentCartHook>>>,
        TError,
        { data: AddItemsToCurrentCartRequest },
        TContext
    >;
}) => {
    const mutationOptions = useAddItemsToCurrentCartMutationOptions(options);

    return useMutation(mutationOptions);
};
export const useSetCurrentCartHook = () => {
    const setCurrentCart = useCustomInstance<CurrentCartResponse>();

    return (currentCartRequest: CurrentCartRequest) => {
        return setCurrentCart({
            url: `/Cart/SetCurrentCart`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: currentCartRequest,
        });
    };
};

export const useSetCurrentCartMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetCurrentCartHook>>>,
        TError,
        { data: CurrentCartRequest },
        TContext
    >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetCurrentCartHook>>>, TError, { data: CurrentCartRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {};

    const setCurrentCart = useSetCurrentCartHook();

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetCurrentCartHook>>>, { data: CurrentCartRequest }> = (
        props
    ) => {
        const { data } = props ?? {};

        return setCurrentCart(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type SetCurrentCartMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetCurrentCartHook>>>>;
export type SetCurrentCartMutationBody = CurrentCartRequest;
export type SetCurrentCartMutationError = ErrorType<unknown>;

export const useSetCurrentCart = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetCurrentCartHook>>>,
        TError,
        { data: CurrentCartRequest },
        TContext
    >;
}) => {
    const mutationOptions = useSetCurrentCartMutationOptions(options);

    return useMutation(mutationOptions);
};
