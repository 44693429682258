import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import {
    IButtonStyles,
    IPanelHeaderRenderer,
    IPanelProps,
    IPanelStyleProps,
    IPanelStyles,
    IRenderFunction,
    IStyleFunction,
    mergeStyles,
    Panel,
    PanelType,
} from '@fluentui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cometDarkTheme } from '../../theme';
import { IAppTheme } from '../../theme/IAppTheme';
import { IconName } from '../../utils/Icons.constants';
import FilterActionBar from '../filters/filter-actions/FilterActionBar';
import { NarrowIconButton } from './NarrowIconButton';

export interface IToggleWidthPanelProps {
    primaryButtonDisabled: boolean;
    isOpen: boolean;
    onClose?: () => void;
    onResize?: () => void;
    onConfirm?: () => void;
    children: React.ReactNode;
    headerText: string;
}

export const ToggleWidthPanel = (props: IToggleWidthPanelProps) => {
    const { t } = useTranslation();
    const { palette } = useCorptaxTheme<IAppTheme>();

    const [panelIsExpanded, setPanelIsExpanded] = useState(false);
    const panelSizeType: PanelType = panelIsExpanded ? PanelType.custom : PanelType.medium;
    const panelSizeToggleButtonText = t(panelIsExpanded ? 'reducePanelSize' : 'expandPanelSize').toString();
    const buttonIconName: IconName = panelIsExpanded ? 'ArrowEnter20Regular' : 'ArrowFit20Regular';
    const ariaCheckedState = panelIsExpanded ? true : false;

    const onExpandButtonclick = () => {
        setPanelIsExpanded(!panelIsExpanded);
        if (props.onResize) {
            props.onResize();
        }
    };

    const onRenderHeader: IPanelHeaderRenderer = useCallback(
        (props?: IPanelProps, defaultRender?: (props?: IPanelProps) => JSX.Element | null) => {
            const styles = props?.styles as IStyleFunction<IPanelStyleProps, IPanelStyles>;
            const styleProps: IPanelStyleProps = { ...props } as IPanelStyleProps;
            const computedStyles = styles(styleProps);

            const buttonStyleOverrides: IButtonStyles = {
                label: {
                    color: cometDarkTheme.colorNeutralForeground1,
                    fontWeight: `${cometDarkTheme.fontWeightRegular}`,
                    // the icon button adds left and right margin to the label,
                    // which makes the button longer than it's supposed to be
                    margin: 0,
                },
                labelDisabled: {
                    color: cometDarkTheme.colorNeutralForegroundDisabled,
                },
                labelHovered: {
                    color: cometDarkTheme.colorNeutralForeground1Hover,
                },
                rootPressed: {
                    background: cometDarkTheme.colorTransparentBackground,
                },
                /*since this button is a toggle, we need to define styles for when it is 'checked', I.E. "enabled"*/
                rootChecked: {
                    background: cometDarkTheme.colorTransparentBackground,
                    /*
                        there's no rootCheckedFocused property or similar on IButtonStyles,
                        so we have to manually add the focus styling via selector
                    */
                    '&:focus': {
                        border: `${cometDarkTheme.strokeWidthThick} solid ${cometDarkTheme.colorStrokeFocus1}`,
                    },
                },
                rootCheckedHovered: {
                    background: cometDarkTheme.colorTransparentBackground,
                    borderColor: cometDarkTheme.colorNeutralStroke1Hover,
                },
                icon: {
                    color: cometDarkTheme.colorNeutralForeground1,
                },
                iconHovered: {
                    color: cometDarkTheme.colorNeutralForeground1,
                },
                rootDisabled: {
                    background: cometDarkTheme.colorTransparentBackground,
                    borderColor: cometDarkTheme.colorNeutralStrokeDisabled,
                },
                rootHovered: {
                    background: cometDarkTheme.colorTransparentBackground,
                    borderColor: cometDarkTheme.colorNeutralStroke1Hover,
                },
            };
            return (
                <div className={mergeStyles(computedStyles.header)}>
                    <h2 className={mergeStyles(computedStyles.headerText)}>{props!.headerText}</h2>
                    <div>
                        <NarrowIconButton
                            id='expandButton'
                            onClick={onExpandButtonclick}
                            iconName={buttonIconName}
                            label={panelSizeToggleButtonText}
                            toggle={true}
                            tabIndex={0}
                            checked={ariaCheckedState}
                            styles={buttonStyleOverrides}
                        />
                    </div>
                </div>
            );
        },
        [onExpandButtonclick, palette.white, panelIsExpanded, panelSizeToggleButtonText, ariaCheckedState, buttonIconName]
    );

    const onRenderFooter: IRenderFunction<IPanelProps> = useCallback(
        (panelProps?: IPanelProps) => {
            const styles = panelProps?.styles as IStyleFunction<IPanelStyleProps, IPanelStyles>;
            const styleProps: IPanelStyleProps = { ...panelProps } as IPanelStyleProps;
            const computedStyles = styles(styleProps);
            return (
                <div className={mergeStyles(computedStyles.footer)}>
                    <FilterActionBar
                        primaryButtonLabel='save'
                        secondaryButtonLabel='cancel'
                        primaryHandler={props.onConfirm!}
                        secondaryHandler={props.onClose!}
                        primaryButtonDisabled={props.primaryButtonDisabled}
                    />
                </div>
            );
        },
        [props.onConfirm, props.onClose, props.primaryButtonDisabled]
    );

    const panelVariables: string = mergeStyles({
        displayName: 'panelVariables',
        '--drilldownpanel-component-vertical-gap': '12px',
    });

    return (
        <Panel
            className={panelVariables}
            isOpen={props.isOpen}
            onDismiss={props.onClose}
            customWidth='1186px'
            closeButtonAriaLabel={t('close').toString()}
            type={panelSizeType}
            isFooterAtBottom={true}
            isLightDismiss={true}
            data-testid='drilldownPanelTestId'
            onRenderHeader={onRenderHeader}
            onRenderFooter={onRenderFooter}
            headerText={props.headerText}
            styles={{
                main: {
                    transition: 'width 500ms',
                },
                content: {
                    padding: '12px 14px 0px 14px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    gap: 'var(--drilldownpanel-component-vertical-gap)',
                    minHeight: '10em',
                },
                navigation: {
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    width: '100%',
                    height: '38px',
                },
                subComponentStyles: {
                    closeButton: {
                        root: {
                            color: cometDarkTheme.colorNeutralForeground1,
                            margin: 0,
                            width: 24,
                            height: 24,
                            padding: `${cometDarkTheme.spacingVerticalXXS} ${cometDarkTheme.spacingHorizontalXXS}`,
                        },
                        flexContainer: {
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                        },
                        rootHovered: {
                            background: cometDarkTheme.colorNeutralBackground2Hover,
                            color: cometDarkTheme.colorBrandForeground2Hover,
                        },
                        rootPressed: {
                            backgroundColor: cometDarkTheme.colorNeutralBackground2Pressed,
                        },
                        icon: {
                            width: 20,
                            height: 20,
                            lineHeight: 20,
                        },
                    },
                },
                header: {
                    displayName: 'panelHeader',
                    display: 'flex',
                    gap: '10px',
                    padding: 0,
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: 2,
                    // there's a base style that this to 'flex-start' which makes the header not align with the close button
                    alignSelf: 'auto',
                },
                headerText: {
                    color: corptaxCustomLightTheme.colorNeutralForegroundOnBrand,
                    // top and bottom margin are automatically added by the default styles.
                    // it's easier to just explicitly remove all margin in general and let flexbox handle layout
                    margin: 0,
                    lineHeight: '24px',
                    fontSize: '18px',
                },
                footer: {
                    boxShadow: '0px -5px 5px -5px #00000040',
                    width: '100%',
                },
                commands: {
                    backgroundColor: corptaxCustomLightTheme.colorBrandBackground,
                    padding: '5px 20px 5px 24px',
                },
            }}
        >
            {props.children}
        </Panel>
    );
};
