import { StackItem, TextField } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsCurrentType } from '../../data-types/ICommentsUIState';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { useCommentsUIStyles } from '../../hooks/useCommentsUIStyles';
import { AddNewReplyToConversationRequest, Conversation } from '../../model';
import { CommentsActionType } from '../../reducers/commentsStateReducer';
import { CommentRichTextEditor, ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';
import { ErrorDisplay } from './ErrorDisplay';

// todo: ditch dependence on Orval model
export interface IAddReplyItemProps {
    conversation: Conversation;
    onCancel: () => void;
    onAdd: (conversationId: string, request: AddNewReplyToConversationRequest) => Promise<boolean>;
}

// todo: set comment text as well as html, and mentions, upon add reply
export const AddReplyItem = (props: IAddReplyItemProps) => {
    const { t } = useTranslation();
    const commentStyles = useCommentsUIStyles();
    const { state: uiState, dispatch: dispatchUIState } = useCommentsUI();
    const addButtonText = t('sendCommentButtonText').toString();
    const addingButtonText = t('sendingCommentButtonText').toString();
    const cancelButtonText = t('cancel').toString();
    const commentInputAriaLabelText = t('commentInputAriaLabel').toString();
    const addReplyInputPlaceholderText = t('reply').toString();
    const replyInProgressInputPlaceholderText = t('replyInProgress').toString();

    const operationErrorMessage =
        props.conversation?.id === uiState.selectedCommentId &&
        (uiState.currentUiState === CommentsCurrentType.AddingNewReply || uiState.openCommentId === props.conversation.id) &&
        uiState.operationFailedErrorMessage
            ? uiState.operationFailedErrorMessage
            : uiState.submitFailedErrorMessage
            ? uiState.submitFailedErrorMessage
            : undefined;

    const handleCancel = () => {
        props.onCancel();
    };

    const handleAddReply = async (comment: ICommentRichTextEditorValue) => {
        // there might be a slight delay where the control allows a save when it should not
        // due to eventing, so make sure it's not empty
        if (!comment.commentText) return;

        dispatchUIState({ type: CommentsActionType.Submitting });
        var parent = props.conversation.parent;
        var addReplyRequest: AddNewReplyToConversationRequest = {
            locationId: parent?.locationId,
            parentLocationId: parent?.parentLocationId,
            collaborationId: parent?.id,
            comment: comment.commentHtml,
            commentPlainText: comment.commentText,
            userMentions: comment.userMentions,
        };
        const result = await props.onAdd(props.conversation.id!, addReplyRequest);
        if (!result) {
            dispatchUIState({ type: CommentsActionType.SubmitError, errorMessage: t('commentNotSent').toString() });
        } else {
            dispatchUIState({ type: CommentsActionType.AddNewReplySuccess });
        }
    };

    const handleChange = (saveValue: ICommentRichTextEditorValue) => {
        if (saveValue.commentText) {
            dispatchUIState({ type: CommentsActionType.MadeChanges });
        } else {
            dispatchUIState({ type: CommentsActionType.UndoMadeChanges });
        }
    };

    const handleReplyTextKeyUp = (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!(uiState.openCommentId === props.conversation.id) && evt.key === 'Enter') {
            handleOpenReply();
        }
    };

    const handleOpenReply = () => {
        if ((uiState.openCommentId !== props.conversation.id || CommentsCurrentType.EditingConversation) && !uiState.hasPendingChanges) {
            dispatchUIState({ type: CommentsActionType.AddNewReply, selectedConversationId: props.conversation.id! });
        }
    };

    const isSelected = props.conversation.id === uiState.selectedCommentId;
    const hasAnotherReplyInProgress = uiState.hasPendingChanges && uiState.selectedCommentId !== props.conversation.id;
    const commentInputPlaceholderText = hasAnotherReplyInProgress
        ? replyInProgressInputPlaceholderText
        : `${addReplyInputPlaceholderText}...`;

    if (uiState.openCommentId !== props.conversation.id) {
        // shows the placeholder textfield if we're not in edit mode
        return (
            <StackItem>
                <StackItem tokens={{ padding: 5 }}>
                    <TextField
                        data-is-focusable={isSelected}
                        tabIndex={isSelected ? 0 : -1}
                        ariaLabel={commentInputAriaLabelText}
                        placeholder={commentInputPlaceholderText}
                        readOnly={true}
                        onKeyUp={handleReplyTextKeyUp}
                        styles={commentStyles.collapsedCommentTextAreaStyles}
                        onClick={() => {
                            // todo: how to focus the editor when opened
                            handleOpenReply();
                        }}
                        disabled={uiState.isSubmitting}
                    />
                </StackItem>
            </StackItem>
        );
    }

    return (
        <>
            <StackItem>
                <StackItem tokens={{ padding: 5 }}>
                    <CommentRichTextEditor
                        commentInputAriaLabel={commentInputAriaLabelText}
                        commentInputPlaceholder={`${addReplyInputPlaceholderText}...`}
                        saveButtonAriaLabel={addButtonText}
                        saveButtonText={addButtonText}
                        onSaveButtonClick={handleAddReply}
                        cancelButtonText={cancelButtonText}
                        cancelButtonAriaLabel={cancelButtonText}
                        onCancelButtonClick={handleCancel}
                        processingText={addingButtonText}
                        onChange={handleChange}
                    />
                </StackItem>
                <StackItem>
                    <ErrorDisplay errorMessage={operationErrorMessage} />
                </StackItem>
            </StackItem>
        </>
    );
};
