import { IStackStyles, mergeStyles, Stack } from '@fluentui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const stackStyles: IStackStyles = {
    root: {
        '> div': {
            '> span': {
                ':is(:first-child)': {
                    fontWeight: '600',
                },
                ':is(:last-child)': {
                    fontWeight: '400',
                },
                color: 'rgb(34, 34, 34)',
                fontSize: '10px',
                lineHeight: '18px',
            },
        },
        gap: '10px',
    },
};

export interface IReportColumnContextProps {
    case: string;
    entity: string;
    jurisdiction: string;
    year: string;
}

const ReportColumnContext: FC<IReportColumnContextProps> = (props: IReportColumnContextProps) => {
    const { t } = useTranslation();
    const baseStyles = { gap: '5px' };

    return (
        <Stack id='reportColumnContext' horizontal horizontalAlign='start' styles={stackStyles}>
            <Stack horizontal className={mergeStyles(baseStyles)}>
                <span>{t('entity')}:</span>
                <span>{props.entity}</span>
            </Stack>
            <Stack horizontal className={mergeStyles(baseStyles)}>
                <span>{t('case')}:</span>
                <span>{props.case}</span>
            </Stack>
            <Stack horizontal className={mergeStyles(baseStyles)}>
                <span>{t('jurisdiction')}:</span>
                <span>{props.jurisdiction}</span>
            </Stack>
            <Stack horizontal className={mergeStyles(baseStyles)}>
                <span>{t('year')}:</span>
                <span>{props.year}</span>
            </Stack>
        </Stack>
    );
};

export default ReportColumnContext;
