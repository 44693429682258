import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { BaseButton, Button, DefaultButton, getId, IRawStyle, mergeStyles } from '@fluentui/react';
import { TFunction } from 'i18next';
import { CSSProperties, FC, Key, MouseEventHandler, ReactNode, useCallback, useMemo } from 'react';
import ReactDomServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { IAppTheme } from '../../theme/IAppTheme';
import { getCalloutProps } from '../../utils/CustomStyles';
import { mergeStylesOnDemand } from '../../utils/StyleUtils';
import CardGridList from '../card-grid-list/CardGridList';
import { automationKeyAttribute, groupKeyPrefix } from '../card-grid-list/CardGridListConstants';
import {
    ICardGridListCellStyle,
    ICardGridListCellStyleAdvanced,
    ICardGridListColumn,
    ICardGridListCustomRow,
    ICardGridListCustomRowStyles,
    ICardGridListGroup,
    ICardGridListRowData,
    IColumnExtended,
    IGroupExtended,
} from '../card-grid-list/CardGridListInterfaces';
import { CardGridListChildDataLoadedCallback } from '../card-grid-list/CardGridListTypes';
import { createUniqueGroupClassName } from '../card-grid-list/CardGridListUtilities';
import { ICalloutIconProps } from '../common/CalloutIcon';
import LoadingSpinner from '../common/LoadingSpinner';
import { getAccountAmountGridListColumns } from './AccountAmountTabPanel.columns';
import {
    adjustmentEntriesChildDataName,
    adjustmentEntryRowClass,
    adjustmentHeaderRowClass,
    adjustmentSummariesChildDataName,
    adjustmentSummaryClassWithLastModifiedBy,
    adjustmentSummaryRowClass,
    bookEntriesChildDataName,
    bookEntryRowClass,
    bookSummaryChildDataName,
    bookSummaryClassWithLastModifiedBy,
    bookSummaryRowClass,
    disabledClass,
    recalcNeededClass,
} from './AccountAmountTabPanel.constants';
import { IAccountAmountTabPanelProps } from './AccountAmountTabPanel.props';
import LastModifiedByCalloutIcon, { ILastModifiedByCalloutIconProps } from './LastModifiedByCalloutIcon';
import { DrillDownActionType } from './ReportDrillDownActions';
import {
    getAccountAmountSummaryByEphemeralKey,
    getWhitePaperRowForExtractionDetailsAccountSummary,
    IAccountAmountWhitePaperRow,
    ITrackedExtractionDetailsAccountSummary,
} from './ReportDrillDownUtils';

interface IContextAttributeRenderInfo {
    key?: Key | undefined;
    name: string;
    value: string;
}

function accountAmountGridOnChildDataLoaded(
    childDataName: string,
    childRows: ICardGridListRowData[],
    parentRow: ICardGridListRowData,
    t: TFunction,
    isModifyAmountsEnabled: boolean
): void {
    if (!(childRows?.length > 0)) {
        return;
    }

    if (childDataName === adjustmentEntriesChildDataName) {
        for (let index = 0; index < childRows.length; index++) {
            const childRow: ICardGridListRowData = childRows[index];
            const treeLineClass: string = index === 0 ? getTreeLineFirstChildClassName() : getTreeLineSubsequentChildClassName();
            const gridGroup: IGroupExtended = childRow.gridGroup as IGroupExtended;
            const childData: IAccountAmountWhitePaperRow = childRow as any as IAccountAmountWhitePaperRow;
            const ifRecalcRequiredClass: string = childData.recalcNeeded ? ` ${recalcNeededClass}` : '';
            let ifIsDisabledClass: string = '';
            if (!childData.recalcNeeded) {
                ifIsDisabledClass = childData.isComplexAdjustment || !isModifyAmountsEnabled ? ` ${disabledClass}` : '';
            }

            gridGroup.className = `${adjustmentEntryRowClass} ${treeLineClass}${ifIsDisabledClass}${ifRecalcRequiredClass}`;
            gridGroup.isCollapsed = true;
            gridGroup.level = (parentRow.gridGroup.level ?? 0) + 1;
            gridGroup.noExpandCollapse = false;
        }
    } else if (childDataName === adjustmentSummariesChildDataName) {
        for (let index = 0; index < childRows.length; index++) {
            const childRow: ICardGridListRowData = childRows[index];
            const gridGroup: IGroupExtended = childRow.gridGroup as IGroupExtended;
            const childData: IAccountAmountWhitePaperRow = childRow as any as IAccountAmountWhitePaperRow;
            const ifRecalcRequiredClass: string = childData.recalcNeeded ? ` ${recalcNeededClass}` : '';
            let ifIsDisabledClass: string = '';
            if (!childData.recalcNeeded) {
                ifIsDisabledClass = childData.isComplexAdjustment || !isModifyAmountsEnabled ? ` ${disabledClass}` : '';
            }

            gridGroup.className = gridGroup.hasChildren
                ? adjustmentSummaryRowClass
                : `${adjustmentSummaryRowClass} ${adjustmentSummaryClassWithLastModifiedBy}${ifIsDisabledClass}${ifRecalcRequiredClass}`;
            gridGroup.isCollapsed = false;
            gridGroup.level = parentRow.gridGroup.level;
            gridGroup.noExpandCollapse = true;
        }

        const adjustmentHeaderRow: ICardGridListRowData = {
            gridGroup: {
                cardGridListGroup: parentRow.gridGroup!.cardGridListGroup,
                className: adjustmentHeaderRowClass,
                count: 0,
                dataSourceIndex: -1,
                groupClassName: createUniqueGroupClassName(),
                hasChildren: false,
                key: getId(groupKeyPrefix),
                hasMoreData: false,
                isCollapsed: true,
                isShowingAll: true,
                level: parentRow.gridGroup.level,
                name: t('adjustments'),
                noExpandCollapse: true,
                parent: parentRow.gridGroup,
                startIndex: -1,
            },
        };

        childRows.unshift(adjustmentHeaderRow);
    } else if (childDataName === bookEntriesChildDataName) {
        for (let index = 0; index < childRows.length; index++) {
            const treeLineClass: string = index === 0 ? getTreeLineFirstChildClassName() : getTreeLineSubsequentChildClassName();
            const gridGroup: IGroupExtended = childRows[index].gridGroup as IGroupExtended;

            gridGroup.className = `${bookEntryRowClass} ${treeLineClass} ${disabledClass}`;
            gridGroup.isCollapsed = true;
            gridGroup.level = (parentRow.gridGroup.level ?? 0) + 1;
            gridGroup.noExpandCollapse = false;
        }
    } else if (childDataName === bookSummaryChildDataName) {
        const gridGroup: IGroupExtended = childRows[0].gridGroup as IGroupExtended;

        gridGroup.className = gridGroup.hasChildren
            ? bookSummaryRowClass
            : `${bookSummaryRowClass} ${bookSummaryClassWithLastModifiedBy} ${disabledClass}`;
        gridGroup.isCollapsed = false;
        gridGroup.level = parentRow.gridGroup.level;
        gridGroup.noExpandCollapse = true;
    }
}

function createGridGroup(dataSourceItem: any, keyGenerator?: (dataSourceItem?: any) => string): ICardGridListGroup {
    let group: ICardGridListGroup = {
        dataSource: dataSourceItem,
        noChildNesting: true,
        key: keyGenerator ? keyGenerator(dataSourceItem) : undefined,
    };
    return group;
}

// NOTE: this could be pulled out as a separate component.
const ContextAttribute: FC<IContextAttributeRenderInfo> = (props: IContextAttributeRenderInfo) => {
    const getEntityContextClassName: () => string = mergeStylesOnDemand({
        displayName: 'entityContext',
        display: 'inline',
    });

    const getContextAttributeNameClassName: () => string = mergeStylesOnDemand({
        displayName: 'contextAttributeName',
        display: 'inline',
        fontWeight: '600',
    });

    const getContextAttributeNameValueSpacerClassName: () => string = mergeStylesOnDemand({
        displayName: 'contextAttributeNameValueSpacer',
        display: 'inline-block',
        minWidth: '8px',
    });

    const getContextAttributeValueClassName: () => string = mergeStylesOnDemand({
        displayName: 'contextAttributeValue',
        display: 'inline',
        fontWeight: '400',
    });

    return (
        <div className={getEntityContextClassName()} key={props.key}>
            <div className={getContextAttributeNameClassName()}>{props.name}:</div>
            <div className={getContextAttributeNameValueSpacerClassName()}></div>
            <div className={getContextAttributeValueClassName()}>{props.value}</div>
        </div>
    );
};

const defaultCellDisplayStyle: IRawStyle = {
    [`div[${automationKeyAttribute}="adjustmentCode"]`]: {
        display: 'none',
    },
    [`div[${automationKeyAttribute}="case"]`]: {
        display: 'none',
    },
    [`div[${automationKeyAttribute}="entity"]`]: {
        display: 'none',
    },
    [`div[${automationKeyAttribute}="jurisdiction"]`]: {
        display: 'none',
    },
    [`div[${automationKeyAttribute}="year"]`]: {
        display: 'none',
    },
    [`div[${automationKeyAttribute}="lastModifiedBy"]`]: {
        display: 'none',
    },
};
interface ITreeLineProps {
    height: number;
    width: number;
}

const TreeLineIcon: React.FC<ITreeLineProps> = (props: ITreeLineProps) => {
    const height: number = props.height ?? 31;
    const width: number = props.width ?? 8;
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d={`M 1 0 V ${height} H ${width}`} stroke={corptaxCustomLightTheme.colorBrandStroke1} strokeWidth='0.5' />
        </svg>
    );
};
const treeLineFirstChildDataUri: string = encodeURIComponent(ReactDomServer.renderToString(<TreeLineIcon width={8} height={21} />));
const treeLineSubsequentChildDataUri: string = encodeURIComponent(ReactDomServer.renderToString(<TreeLineIcon width={8} height={27} />));
const getTreeLineFirstChildClassName: () => string = mergeStylesOnDemand({
    displayName: 'treeline-firstchild',
    'span:last-child::before': {
        content: `url('data:image/svg+xml;utf8,${treeLineFirstChildDataUri}')`,
        display: 'inline',
        left: '33px',
        position: 'absolute',
        top: '-7px',
    },
    textAlign: 'left',
    userSelect: '',
});

const getTreeLineSubsequentChildClassName: () => string = mergeStylesOnDemand({
    displayName: 'treeline-subsequentchild',
    'span:last-child::before': {
        content: `url('data:image/svg+xml;utf8,${treeLineSubsequentChildDataUri}')`,
        display: 'inline',
        left: '33px',
        position: 'absolute',
        top: '-13px',
    },
    textAlign: 'left',
    userSelect: '',
});

const getGridVariablesClassName: (theme: IAppTheme) => () => string = (theme: IAppTheme) => {
    const style = {
        displayName: 'gridVariables',
        '--editvaluebutton-column-width': '42px',
        '--reportamount-column-width': '106px',
        '--dynamic-width': 'calc(100% - var(--editvaluebutton-column-width) - var(--reportamount-column-width))',

        // account and adjustment code are meant to have the same width
        // the width of these 2 columns, when added to the widths of type and description,
        // should add up to 1.0
        '--account-column-width': 'calc(var(--dynamic-width) * .31)',
        '--adjustmentCode-column-width': 'calc(var(--dynamic-width) * .31)',
        '--type-column-width': 'calc(var(--dynamic-width) * .12)',
        '--description-column-width': 'calc(var(--dynamic-width) * .57)',

        // these 5 percentages should add up to 1.0
        '--entity-column-width': 'calc(var(--dynamic-width) * .31)',
        '--case-column-width': 'calc(var(--dynamic-width) * .1)',
        '--jurisdiction-column-width': 'calc(var(--dynamic-width) * .3)',
        '--year-column-width': 'calc(var(--dynamic-width) * .20)',
        '--lastmodifiedby-column-width': 'calc(var(--dynamic-width) * .09)',

        '--description-column-width-withAdjustment': 'calc(var(--dynamic-width) * .48)',
        // We need to reference this border color through the palette for now because the UX
        // team has not figured out which alias token to use for this color.
        '--listcard-border-color': theme.customPalette.blueGreyNeutral,
    };
    return mergeStylesOnDemand(style);
};

const getSpinnerClassName: () => string = mergeStylesOnDemand({
    displayName: 'spinner',
    boxSizing: 'content-box',
    height: '100%',
    width: '100%',
});

function onRenderDataCellText(
    t: TFunction,
    item?: any,
    group?: IGroupExtended,
    column?: IColumnExtended,
    defaultRender?: () => ReactNode
): ReactNode {
    if (!(item && group && column && defaultRender)) {
        return null;
    }

    const entryDataObjectTypes = ['AdjustmentEntry', 'BookEntry'];
    const entryContextColumnKeys = ['case', 'entity', 'jurisdiction', 'year'];

    if (entryDataObjectTypes.indexOf(item.dataObjectType) >= 0 && entryContextColumnKeys.indexOf(column.key) >= 0) {
        return <ContextAttribute name={column.name} value={defaultRender() as string} />;
    }

    return defaultRender();
}

function getCardGridListGroupKey(cardGridListData: ICardGridListGroup): string {
    return `${cardGridListData?.key}-summaryRow`;
}

const AccountAmountTabPanel: FC<IAccountAmountTabPanelProps> = (props: IAccountAmountTabPanelProps) => {
    const { t } = useTranslation();
    const appTheme = useCorptaxTheme<IAppTheme>();
    const { customPalette, palette } = appTheme;

    const childDataPropertyNames: string[] = useMemo(
        () => [bookSummaryChildDataName, bookEntriesChildDataName, adjustmentSummariesChildDataName, adjustmentEntriesChildDataName],
        []
    );

    const defaultGroupHeaderStyle = useMemo(() => {
        return {
            ...defaultCellDisplayStyle,
            backgroundColor: customPalette.blueC7DCE5,
            color: corptaxCustomLightTheme.colorNeutralForeground1,
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '600',
            borderRadius: '6px',
        } as ICardGridListCellStyleAdvanced;
    }, [defaultCellDisplayStyle, customPalette.blueC7DCE5, corptaxCustomLightTheme.colorNeutralForeground1]);

    const defaultRowStyle = useMemo(() => {
        return {
            ...defaultCellDisplayStyle,
            color: corptaxCustomLightTheme.colorNeutralForeground1,
            //FIXME: this style causes the bottom border of the entire card to be thicker than the other 3 sides
            borderBottom: `0.5px solid ${corptaxCustomLightTheme.colorNeutralStroke1}`,
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '600',
        } as ICardGridListCellStyleAdvanced;
    }, [defaultCellDisplayStyle, corptaxCustomLightTheme.colorNeutralForeground1, corptaxCustomLightTheme.colorNeutralStroke1]);

    const customRows: ICardGridListCustomRow[] = useMemo(() => {
        return [
            {
                className: adjustmentEntryRowClass,
                expandCollapseColumnKey: 'entity',
            },
            {
                className: adjustmentHeaderRowClass,
                renderAs: 'headerrow',
            },
            {
                className: adjustmentSummaryRowClass,
                expandCollapseColumnKey: 'adjustmentCode',
            },
            {
                className: bookEntryRowClass,
                expandCollapseColumnKey: 'entity',
            },
            {
                className: bookSummaryRowClass,
            },
            {
                className: bookSummaryClassWithLastModifiedBy,
            },
            {
                className: adjustmentSummaryClassWithLastModifiedBy,
                expandCollapseColumnKey: 'adjustmentCode',
            },
            {
                className: recalcNeededClass,
            },
            {
                className: disabledClass,
            },
        ];
    }, [
        adjustmentEntryRowClass,
        adjustmentHeaderRowClass,
        adjustmentSummaryRowClass,
        bookEntryRowClass,
        bookSummaryRowClass,
        bookSummaryClassWithLastModifiedBy,
        adjustmentSummaryClassWithLastModifiedBy,
    ]);

    const defaultCustomRowStyles: ICardGridListCustomRowStyles = useMemo(() => {
        // TODO: once the UX team has figured out how to map these colors to alias tokens,
        // we should update these to use corptaxCustomLightTheme instead of the palette
        return {
            [`${adjustmentEntryRowClass}`]: {
                backgroundColor: customPalette.statusCoolMidGrey,
            },
            [`${adjustmentHeaderRowClass}`]: {
                backgroundColor: customPalette.statusCoolDarkGrey,
                borderBottomColor: customPalette.cementGrey,
            },
            [`${adjustmentSummaryRowClass}`]: {
                backgroundColor: palette.white,
            },
            [`${bookEntryRowClass}`]: {
                backgroundColor: customPalette.blueGreyNeutralTint2,
            },
            [`${bookSummaryRowClass}`]: {
                backgroundColor: palette.white,
            } as ICardGridListCellStyleAdvanced,
        };
    }, [
        adjustmentEntryRowClass,
        adjustmentHeaderRowClass,
        adjustmentSummaryRowClass,
        bookEntryRowClass,
        bookSummaryRowClass,
        customPalette.statusCoolMidGrey,
        customPalette.statusCoolDarkGrey,
        customPalette.blueGreyNeutralTint2,
        customPalette.cementGrey,
        palette.white,
    ]);

    const defaultColumnHeaderStyle: ICardGridListCellStyleAdvanced = useMemo(() => {
        return {
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '600',
        };
    }, []);

    const enterAmountsDivStyles: CSSProperties = {
        marginLeft: '6px',
        marginRight: '4px',
        borderBottom: '1px solid',
        backgroundColor: corptaxCustomLightTheme.colorNeutralBackground5,
        borderColor: corptaxCustomLightTheme.colorBrandForegroundLink,
        borderRadius: '4px 4px 0px 0px',
        overflow: 'none',
    };

    const getGridKey: (dataSourceItem?: any) => string = (dataSourceItem?: any) => {
        return `${dataSourceItem?.summaryEphemeralKey}-${props.selectedCell?.displayRowNumber}-${props.selectedCell?.columnFieldName}-accountAmountGrid-${dataSourceItem?.dataObject?.accountNumber}`;
    };

    const getGridGroups = () => {
        const gridGroups = [] as ICardGridListGroup[];
        props.initialAccountAmountSummaries?.forEach((summary: ITrackedExtractionDetailsAccountSummary) => {
            const dirtySummary = getAccountAmountSummaryByEphemeralKey(
                summary.accountNumber!,
                summary.ephemeralKey!,
                props.currentAccountAmountSummaries
            );
            const summaryForAccount = dirtySummary ? dirtySummary : summary;
            const whitePaperRow = getWhitePaperRowForExtractionDetailsAccountSummary(
                summaryForAccount,
                Number.parseInt(props.selectedCell.displayRowNumber!),
                props.whitePaperReportColumn.originalColumnId!,
                props.report,
                props.changeTrackingData
            );
            const gridGroup = createGridGroup(whitePaperRow, getGridKey);
            if (dirtySummary) {
                gridGroup.initiallyExpanded = true;
            }
            gridGroups.push(gridGroup);
        });
        return gridGroups;
    };

    const gridGroups = useMemo(
        () => getGridGroups(),
        [props.initialAccountAmountSummaries, props.currentAccountAmountSummaries, props.changeTrackingData]
    );

    const reportAmountColumnRowStyle: ICardGridListCellStyle = useMemo(() => {
        return {
            backgroundColor: palette.white,
            textAlign: 'right',
        } as ICardGridListCellStyle;
    }, [palette.white]);

    const renderLastModifiedColumn = useCallback(
        (item?: IAccountAmountWhitePaperRow): JSX.Element | null => {
            if (!item?.lastModifiedBy) return null;
            const key = `${item?.dataObjectType}-${item?.account}-${item?.adjustmentCode}-${item?.entity}-${item?.case}-${item?.jurisdiction}-${item?.year}`;
            const props: ILastModifiedByCalloutIconProps = {
                item: item,
                calloutIconProps: {
                    icon: 'Info12Regular',
                    iconProps: { className: mergeStyles({ color: corptaxCustomLightTheme.colorNeutralForeground3 }) },
                    calloutProps: getCalloutProps(customPalette),
                    key: key,
                    buttonAriaLabel: t('lastModifiedByCalloutButtonLabel'),
                } as ICalloutIconProps,
            } as ILastModifiedByCalloutIconProps;
            return <LastModifiedByCalloutIcon {...props} />;
        },
        [getCalloutProps, customPalette, t, corptaxCustomLightTheme]
    );

    const columns: ICardGridListColumn[] = useMemo(
        () =>
            getAccountAmountGridListColumns(
                onRenderDataCellText,
                t,
                renderLastModifiedColumn,
                props,
                reportAmountColumnRowStyle,
                props.renderAdjustmentAccountAssociationDialogButton,
                customPalette
            ),
        [
            onRenderDataCellText,
            t,
            renderLastModifiedColumn,
            props,
            reportAmountColumnRowStyle,
            props.renderAdjustmentAccountAssociationDialogButton,
            customPalette,
            getAccountAmountGridListColumns,
        ]
    );

    const onChildDataLoaded: CardGridListChildDataLoadedCallback = useCallback(
        (...args: [childDataName: string, childRows: ICardGridListRowData[], parentRow: ICardGridListRowData]): void =>
            accountAmountGridOnChildDataLoaded(...args, t, props.isAmountDataEntryModifyEnabled),
        [t]
    );

    const openEnterAmountsDialog: MouseEventHandler<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button
    > = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button>) => {
        props.updateDrillDownState &&
            props.updateDrillDownState({
                type: DrillDownActionType.EnterAmountsDialogOpened,
                enterAmountsDialogProps: {
                    buttonTrigger: event.target as HTMLElement,
                    case: props.report.case!,
                    jurisdiction: props.report.jurisdiction!,
                    period: props.report.year!.toString(),
                    entity: props.whitePaperReportColumn.entityCode ?? props.report.entityCode!,
                    adjustmentCode: '',
                    location: '',
                },
            });
    };

    const cardGridListWrapperStyle: CSSProperties = useMemo(() => {
        return { display: props.isFetchingOrSavingData ? 'none' : undefined, height: 'inherit' };
    }, [props.isFetchingOrSavingData]);

    const memoizedCardGridList = useMemo(() => {
        return (
            <div key='accountAmountCardGridListWrapper' data-testid='accountAmountGrid' style={cardGridListWrapperStyle}>
                <CardGridList
                    childDataPropertyNames={childDataPropertyNames}
                    className={getGridVariablesClassName(appTheme)()}
                    columnHeaderStyle={defaultColumnHeaderStyle}
                    columns={columns}
                    customRows={customRows}
                    customRowStyles={defaultCustomRowStyles}
                    groupHeaderStyle={defaultGroupHeaderStyle}
                    groupKeyGenerator={getCardGridListGroupKey}
                    groups={gridGroups}
                    height='inherit'
                    id='accountAmountGrid'
                    isUnitTestExecution={props.isUnitTestExecution}
                    key='accountAmountCardGridList'
                    onChildDataLoaded={onChildDataLoaded}
                    onExpandCollapseStateChange={props.onExpandCollapseStateChange}
                    ref={props.cardGridListRef}
                    rowStyle={defaultRowStyle}
                />
            </div>
        );
    }, [
        childDataPropertyNames,
        columns,
        customRows,
        defaultColumnHeaderStyle,
        defaultCustomRowStyles,
        defaultGroupHeaderStyle,
        defaultRowStyle,
        getCardGridListGroupKey,
        gridGroups,
        cardGridListWrapperStyle,
        onChildDataLoaded,
        props.isUnitTestExecution,
        props.onExpandCollapseStateChange,
        props.cardGridListRef,
    ]);
    return (
        <>
            {props.isEnterAmountsFeatureFlagEnabled! && props.isDataLoaded && (
                <div style={enterAmountsDivStyles}>
                    <DefaultButton
                        onClick={openEnterAmountsDialog}
                        text={t('enterAmounts')!}
                        style={{ border: 'none', background: 'transparent' }}
                        iconProps={{
                            iconName: 'textBulletListSquareEdit20Regular',
                            styles: { root: { color: corptaxCustomLightTheme.colorBrandForeground2 } },
                        }}
                    ></DefaultButton>
                </div>
            )}
            {props.isDataLoaded && memoizedCardGridList}
            {props.isFetchingOrSavingData && (
                <LoadingSpinner
                    id='loadingSpinnerAccountsAmount'
                    containerStyle={getSpinnerClassName()}
                    label={t('loadingPleaseWait').toString()}
                />
            )}
        </>
    );
};

export default AccountAmountTabPanel;
