import { useCorptaxTheme } from '@corptax/react-components-common';
import { IContextualMenuItem, IContextualMenuProps, mergeStyles, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Blocker } from '@remix-run/router';
import { ColumnModel, GridComponent, IEditCell, IIndex } from '@syncfusion/ej2-react-grids';
import { useQueryClient } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useIsFeatureEnabled } from '../../../api/features/features';
import { useGetTaxReturnById, useGetWhitePaperReport, useGetWhitePaperReportPreviousVersions } from '../../../api/report/report';
import { CommentsUIStateProvider } from '../../../contexts/commentsUIStateContext';
import { GridStateContextProvider } from '../../../contexts/gridStateContext';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IFilterItem } from '../../../data-types/filterItem';
import { ICurrentCellEventArgs } from '../../../data-types/ICurrentCellEventArgs';
import { IRowInfo } from '../../../data-types/IRowInfo';
import { ISelectableGrid } from '../../../data-types/ISelectableGrid';
import { createSelectedWhitePaperCell } from '../../../data-types/selectedWhitePaperCell';
import { useCellCommentsRouteDetails } from '../../../hooks/useCellCommentsRouteDetails';
import { ContextMenuItemDisplayState } from '../../../hooks/useCommonIndividualReportTableContextMenu';
import { useDrillDown } from '../../../hooks/useDrillDown';
import { useFunctionalAccess } from '../../../hooks/useFunctionalAccess';
import { useGridScroll } from '../../../hooks/useGridScroll';
import { usePerformanceMetricsForTable } from '../../../hooks/usePerformanceMetricsForTable';
import { useSearchTable } from '../../../hooks/useSearchTable';
import { useSelectedEntities } from '../../../hooks/useSelectedEntities';
import { useTaxReturnCalculationState } from '../../../hooks/useTaxReturnCalculationState';
import {
    CalculationExecutionStatus,
    IWhitePaperColumn,
    IWhitePaperMappedRowDictionary,
    IWhitePaperReport,
    IWhitePaperReportChanges,
    IWhitePaperRow,
    Report,
    WhitePaperReportVersion,
} from '../../../model';

import { initialUIState } from '../../../reducers/commentsStateReducer';
import { IAppTheme } from '../../../theme/IAppTheme';
import { isResponseArrayValid } from '../../../utils/ApiClientUtils';
import {
    getColumnHeaderTemplate,
    getColumnTemplate,
    getColumnUid,
    getColumnWidth,
    isColumnVisibleAfterEntityFilter,
} from '../../../utils/ColumnUtils';
import { useDataManager } from '../../../utils/DataManagerUtils';
import { isDevelopment } from '../../../utils/EnvironmentUtils';
import { getEntitiesFilterForColumns, getSectionsFilterItem } from '../../../utils/FilterUtils';
import {
    CLICKABLE_COLUMN_START_INDEX,
    getColumnFieldNameByIndex,
    getDynamicFieldName,
    getDynamicFieldNameParts,
    getReportVersionLabelText,
    getVisibleEntityColumnNames,
    isDrillDownContextMenuAllowed,
    isMenuEnabledForTableColumn,
    isMultiColumnReport,
    shouldExecuteRerun,
    shouldFetch,
    updateSubtotalByEntitiesValue,
} from '../../../utils/ReportUtils';
import { TOTAL_ROW_NUMBER } from '../../../utils/Table.constants';
import { getWhitePaperReportHeaderTemplate } from '../../../utils/TableUtils';
import { shouldFreezeColumnByHeaderName } from '../../../utils/TreegridUtils';
import GridSearchBar from '../../common/GridSearchBar';
import CustomIconButton from '../../common/IconButton';
import ConfirmationDialog from '../../dialog/ConfirmationDialog';
import EnterAmountsDialog, { IEnterAmountsDialogProps } from '../../enter-amounts-dialog/EnterAmountsDialog';
import FilterButton from '../../filters/filter-button/FilterButton';
import { CommentsPanel, CommentsPanelLocationType } from '../../sidebar/CommentsPanel';
import ReportDrillDown from '../../sidebar/ReportDrillDown';
import ActionBarButtons from '../action-buttons/actionBarButtons';
import CalculateButton from '../action-buttons/calculateButton';
import CommonIndividualReportTable, {
    descriptionTemplate,
    ICommonIndividualReportTableContextMenuItemProps,
    ICommonIndividualReportTableContextMenuProps,
} from '../common-individual-report-table/CommonIndividualReportTable';
import ScrollToSectionDropdown from '../common-individual-report-table/ScrollToSectionDropdown';

const getHeaderTextBasedOnColumnId = (column: IWhitePaperColumn): string => {
    return column.description ?? column.headingFirstLine ?? '';
};

const isDrillDownAllowed = (cellIndex?: IIndex) => {
    if (cellIndex && cellIndex.cellIndex) {
        return isMenuEnabledForTableColumn(cellIndex.cellIndex);
    }
    return false;
};

export const getWhitePaperReportColumnModels = (
    report: IWhitePaperReport | undefined,
    frozenColumnHeaders?: string[],
    filteredColumns?: IFilterItem[]
): ColumnModel[] => {
    //TODO: move this to a shared location so reportlines, comparereportlines, and quick add adjustments
    // can all use this model
    const columns: ColumnModel[] = [];
    const numericParams: IEditCell = {
        params: {
            decimals: 0,
            format: 'N',
            validateDecimalOnType: true,
            showSpinButton: false,
            htmlAttributes: { maxlength: '15' },
        },
    };
    const isMultiColumn: boolean = isMultiColumnReport(report);

    report?.columns?.forEach((col) => {
        const fieldName = getDynamicFieldName(col.fieldName, '.current');
        const isVisible = isColumnVisibleAfterEntityFilter(filteredColumns ?? [], col);
        const headerText = getHeaderTextBasedOnColumnId(col);
        const shouldFreeze = isMultiColumn && frozenColumnHeaders && shouldFreezeColumnByHeaderName(headerText, frozenColumnHeaders);
        columns.push({
            width: getColumnWidth(col.longestValueLength),
            field: fieldName,
            headerText: headerText,
            headerTemplate: getWhitePaperReportHeaderTemplate(col), //TODO: see if this is actually needed, or if we can just use reportHeaderTemplate
            template: getColumnTemplate(fieldName),
            headerTextAlign: 'Right',
            textAlign: 'Right',
            customAttributes: { class: 'white-paper-report-clickable-value-cell' },
            isFrozen: shouldFreeze,
            freeze: shouldFreeze ? 'Left' : 'None',
            allowResizing: true,
            autoFit: !isMultiColumn,
            visible: isVisible,
            edit: numericParams,
            editType: 'numericedit',
            uid: getColumnUid(col),
        } as ColumnModel);
    });

    return columns;
};

const frozenColumnHeaders: string[] = ['total'];
export const getReportLinesColumns = (
    report: IWhitePaperReport | undefined,
    t: TFunction<'translation', undefined, 'translation'>,
    frozenColumnHeaders: string[],
    filteredColumns?: IFilterItem[]
) => {
    const isMultiColumns: boolean = isMultiColumnReport(report);
    const rowNumberColumnModel: ColumnModel = {
        field: 'rowNumber',
        width: '100px',
        headerText: 'Row Number',
        isFrozen: isMultiColumns,
        freeze: isMultiColumns ? 'Left' : 'None',
        allowResizing: true,
        autoFit: false,
        allowEditing: false,
        isPrimaryKey: true,
        visible: isDevelopment(),
    };
    const descriptionColumnModel: ColumnModel = {
        field: 'description',
        maxWidth: '3130px',
        width: '450px',
        headerText: t('description').toString(),
        isFrozen: isMultiColumns,
        freeze: isMultiColumns ? 'Left' : 'None',
        headerTemplate: getColumnHeaderTemplate,
        template: descriptionTemplate,
        customAttributes: { class: 'white-paper-report-customizable-cell' },
        allowEditing: false,
        visible: true,
        editTemplate: (props: IWhitePaperRow) => <span style={{ paddingLeft: '23px' }}>{props.description}</span>,
    };
    return [rowNumberColumnModel, descriptionColumnModel, ...getWhitePaperReportColumnModels(report, frozenColumnHeaders, filteredColumns)];
};

export interface IReportLinesTableProps {
    navigateToCompareReport: (versionId: string, selectedEntities: string[]) => void;
    reportId: number;
    blocker?: Blocker;
    setIsDirtyFlag?: (isDirty: boolean) => void;
}

const ReportLinesTable: FC<IReportLinesTableProps> = (props: IReportLinesTableProps) => {
    const { startTrackingScrollEvent, trackRenderFinished, resetStartTime } = usePerformanceMetricsForTable('Individual return');
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { isReportExecuting: isInTaxCalculationReportList, registerDependency } = useTaxReturnCalculationState();
    const {
        data: currentReport,
        isError: isErrorReport,
        isFetching: isFetchingReport,
        queryKey: queryKeyReport,
    } = useGetTaxReturnById<Report>(props.reportId);
    const {
        data: currentWhitePaperReport,
        isError: isErrorFetchingWhitePaperReport,
        isFetching: isFetchingWhitePaperReport,
        queryKey: queryKeyWhitePaperReport,
        refetch: refetchGetWhitePaperReport,
    } = useGetWhitePaperReport<IWhitePaperReport>({ ReportId: props.reportId }, { query: { enabled: false } });
    const { data: isViewFormEnabled } = useIsFeatureEnabled({ featureFlagName: 'ViewFormEnabled' });

    //commenting out for now but can be used to test later
    // const { data: isModernizationPreviewEnabled } = useIsFeatureEnabled({ featureFlagName: 'ModernizationPreviewEnabled' });
    // console.log('isModernizationPreviewEnabled:' + isModernizationPreviewEnabled);

    const [currentWhitePaperColumn, setCurrentWhitePaperColumn] = useState<IWhitePaperColumn | undefined>(undefined);
    const { setTraceCalculationData } = useDrillDown();
    const {
        rerunCalculationReport,
        isReportExecuting,
        isLoadingRecalculation: isFetchingRecalculation,
        isErrorRecalculation,
        taxReturnCalculationStatusList,
    } = useTaxReturnCalculationState();
    const { openForm, navigateToReport, showPdf, tocData, hasForms, isLoadingToc } = useFormPdfView();

    const [isDrillDownVisible, { setTrue: showDrillDown, setFalse: hideDrillDown }] = useBoolean(false);
    const gridComponentRef = useRef<GridComponent | null>(null);
    const selectableGridRef = useRef<ISelectableGrid | null>(null);
    const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false);
    const [commentData, setCommentData] = useState<{ rowData: IWhitePaperRow; columnName: string } | null | undefined>(undefined);
    const [currentCellArgs, setCurrentCellArgs] = useState<ICurrentCellEventArgs<IWhitePaperRow> | null>(null);

    // this is true when someone saves an override (has not been implemented yet), or when someone saves one or more amounts from the Drilldown Panel
    const [isRecalculateNeeded, setIsRecalculateNeeded] = useState<boolean>(false);
    const [changeTrackingData, setChangeTrackingData] = useState<IWhitePaperReportChanges>({});
    const { dataManager, contentLength } = useDataManager(currentWhitePaperReport, props.reportId.toString());
    const isMultiColumns = isMultiColumnReport(currentWhitePaperReport);
    const isExecuting = isReportExecuting(props.reportId);
    const currentReturnStatus =
        taxReturnCalculationStatusList?.find((item: CalculationExecutionStatus) => item.taxReturnItemKey === props.reportId) || {};

    const validateErrorOnLoadingReportData = !isReportExecuting(props.reportId) && !(isFetchingRecalculation && isErrorRecalculation);
    const commentsRouteDetails = useCellCommentsRouteDetails(currentWhitePaperReport);
    const cellCommentId = useParams().cellCommentId;
    const initialCommentsUIState = useMemo(() => {
        const highlightedCommentId = cellCommentId ? cellCommentId.toLowerCase() : null;
        return { ...initialUIState, highlightedCommentId };
    }, [cellCommentId]);
    const { getFilteredEntities, setSelectedEntitiesSearchParams } = useSelectedEntities();
    const entities: IFilterItem[] = getEntitiesFilterForColumns(currentWhitePaperReport?.columns || []);
    const filteredEntities: IFilterItem[] = getFilteredEntities(entities);

    useEffect(() => {
        if (commentsRouteDetails.isCommentsSubroute) {
            setCommentData(commentsRouteDetails.commentData);
            setIsCommentsPanelOpen(true);
        }
    }, [commentsRouteDetails.isCommentsSubroute]);

    useEffect(() => {
        const executeRerun = () => {
            rerunCalculationReport(props.reportId, currentReport?.taxReturnItemId ?? '');
        };

        if (
            currentReport &&
            shouldExecuteRerun(currentReport, isReportExecuting(props.reportId), isFetchingRecalculation, currentReturnStatus)
        ) {
            executeRerun();
        }
    }, [currentReport, props.reportId]);

    useEffect(() => {
        const fetchData = () => {
            resetStartTime();
            refetchGetWhitePaperReport();
        };

        if (currentReport && shouldFetch(currentReport, currentReturnStatus)) fetchData();
    }, [isExecuting, currentReport]);

    const closeDrillDown = (changeTrackingData: { amountsChangedOnServer: boolean; reportChanges: IWhitePaperReportChanges }) => {
        const { amountsChangedOnServer, reportChanges } = changeTrackingData;
        hideDrillDown();
        setIsRecalculateNeeded(isRecalculateNeeded || amountsChangedOnServer);
        setChangeTrackingData(reportChanges);
        if (selectableGridRef.current) {
            selectableGridRef.current.focusCell(undefined as unknown as IIndex);
            if (amountsChangedOnServer) {
                selectableGridRef.current.markAsEdited(currentCellArgs?.cellIndex!);
            }
        }
    };

    const cleanupCache = () => {
        queryClient.resetQueries(queryKeyWhitePaperReport);
        queryClient.resetQueries(queryKeyReport);
        if (props.setIsDirtyFlag) {
            props.setIsDirtyFlag(false);
        }
    };

    useEffect(() => {
        registerDependency(props.reportId, queryKeyWhitePaperReport);
        return cleanupCache();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reportId]);

    const editCell = (eventArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null) => {
        if (eventArgs) {
            if (selectableGridRef.current) {
                selectableGridRef.current.startEdit(eventArgs.cellIndex);
            }
        }
    };

    const openDrillDownIfAllowed = (eventArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null) => {
        if (eventArgs) {
            const columnIndex = eventArgs.cellIndex.cellIndex! - CLICKABLE_COLUMN_START_INDEX;
            const currentCol = currentWhitePaperReport?.columns?.[columnIndex];
            setCurrentWhitePaperColumn(currentCol);
            setTraceCalculationData([
                { displayRowNumber: TOTAL_ROW_NUMBER },
                createSelectedWhitePaperCell(eventArgs.data, getColumnFieldNameByIndex(columnIndex, currentWhitePaperReport)),
            ]);

            showDrillDown();
        }
    };

    const openCommentsIfAllowed = (eventArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null) => {
        if (eventArgs) {
            if (isDrillDownAllowed(eventArgs.cellIndex)) {
                setCommentData({ rowData: eventArgs.data, columnName: getColumnIdFromFieldName(eventArgs.fieldName) });
                setIsCommentsPanelOpen(true);
            }
        }
    };

    const getColumnIdFromFieldName = useCallback((fieldName: string): string => {
        if (!fieldName.includes('.')) {
            return fieldName;
        }
        const { entityCode } = getDynamicFieldNameParts(fieldName);
        return entityCode;
    }, []);

    const columns: ColumnModel[] = useMemo(
        () => getReportLinesColumns(currentWhitePaperReport, t, frozenColumnHeaders, filteredEntities),
        [currentWhitePaperReport, t, filteredEntities]
    );
    useEffect(() => {
        setIsRecalculateNeeded(currentWhitePaperReport?.reCalculationRequired ?? false);
        setChangeTrackingData(currentWhitePaperReport?.changeTrackingData ?? {});
    }, [currentWhitePaperReport]);

    const handleOpenPathToPortal = () => {
        if (isViewFormEnabled) {
            openForm();
        } else {
            currentReport?.pathToPortal && window.open(currentReport?.pathToPortal, '_blank');
        }
    };

    const isFetching = isFetchingReport || isFetchingWhitePaperReport || isFetchingRecalculation || !validateErrorOnLoadingReportData;
    const isDataReadyToDisplay = !isFetching && currentWhitePaperReport !== undefined;
    const currentReportIsCalculating = isInTaxCalculationReportList(props.reportId) || false;

    const { data: availableVersions, queryKey: previousVersionsKey } = useGetWhitePaperReportPreviousVersions({
        ReportId: props.reportId,
    });

    useEffect(() => {
        registerDependency(props.reportId, previousVersionsKey);
    }, [previousVersionsKey]);
    const { selectedEntities } = useSelectedEntities();

    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const getDropdownItems = (): IContextualMenuItem[] => {
        if (!isResponseArrayValid(availableVersions)) {
            return [];
        }

        return (
            availableVersions?.map((version: WhitePaperReportVersion) => {
                return {
                    key: version.versionId ?? '',
                    name: getReportVersionLabelText(version),
                    onClick: () => {
                        props.navigateToCompareReport(version.versionId ?? '', selectedEntities);
                    },
                    itemProps: {
                        styles: {
                            label: {
                                color: customPalette.blueDark,
                                fontSize: '13px',
                                fontWeight: '600',
                                padding: '0px 24px',
                            },
                        },
                    },
                };
            }) ?? []
        );
    };

    const compareHistoryButtonId = `compareHistoryButton`;
    const reportVersionsMenuProps: IContextualMenuProps = {
        items: getDropdownItems(),
        title: t('datesRun').toString(),
        styles: {
            title: {
                fontSize: '10px',
                lineHeight: '22px',
                fontWeight: '600',
                backgroundColor: 'unset',
                padding: '20px 20px 0px',
            },
        },
        ariaLabel: t('compareHistory').toString(),
    };

    const rerunCalculation = async () => {
        rerunCalculationReport(props.reportId, currentReport?.taxReturnItemId ?? '', undefined, (data: any) => {
            setIsRecalculateNeeded(false);
        });
    };

    const getCompareButton: () => JSX.Element = () => {
        if (!availableVersions?.length) {
            return <></>;
        }

        return (
            <CustomIconButton
                id={compareHistoryButtonId}
                label={t('compareHistory').toString()}
                iconName='History20Regular'
                menuProps={reportVersionsMenuProps}
                //TODO: Use the context props to handle this

                disabled={isFetching || currentReportIsCalculating /*|| isViewFormPdfProcessing*/}
                key='compareHistoryButton'
            />
        );
    };

    const compareButton: JSX.Element = getCompareButton();

    const sections = useMemo(
        () => getSectionsFilterItem(currentWhitePaperReport?.rows || [], tocData?.statements || []),
        [currentWhitePaperReport, tocData]
    );
    const { programmaticallyScrollToRow } = useGridScroll();
    const onSectionsScrollToChange = (filteredItems: IFilterItem[]) => {
        if (gridComponentRef && gridComponentRef.current) {
            const rowId = parseInt(filteredItems[0].key, 10);
            const rowIndex = currentWhitePaperReport?.rows?.findIndex((row) => row.rowNumber === rowId);
            programmaticallyScrollToRow(gridComponentRef.current, { rowIndex: rowIndex });
        }
    };
    const { isSearchValueApplied, clearSearch } = useSearchTable();
    const [searchEntityValue, setSearchEntityValue] = useState<string>('');
    const updateVisibleColumns = (filteredItems: IFilterItem[]) => {
        if (gridComponentRef && gridComponentRef.current && currentWhitePaperReport) {
            const columns = getVisibleEntityColumnNames(filteredItems, currentWhitePaperReport?.columns ?? []);
            updateSubtotalByEntitiesValue(currentWhitePaperReport?.rows || [], columns, gridComponentRef);
        }
    };
    const onFilterChange = (filteredItems: IFilterItem[]) => {
        setSelectedEntitiesSearchParams(filteredItems);
        updateVisibleColumns(filteredItems);
    };
    const handlerSetCheckedItems = (filterItems: IFilterItem[]) => {
        onFilterChange(filterItems);
        clearSearch(gridComponentRef);
    };

    const renderFinished = () => {
        trackRenderFinished({
            rowCount: contentLength,
            columnCount: columns.length,
            visibleColumnCount: columns.filter((column) => column.visible).length,
            reportId: props.reportId,
            isMultiColumn: isMultiColumns,
        });
    };

    const filterGroupItems: React.ReactNode[] = [
        <ScrollToSectionDropdown
            sections={sections}
            isFiltered={false}
            filterName={''}
            label='goToSection'
            showSingleSelect={true}
            onSectionChange={onSectionsScrollToChange}
            isDisabled={isSearchValueApplied}
            key='scrollToSectionDropdown'
        />,
        isMultiColumns ? (
            <FilterButton
                data-testid='filterButton'
                filterName='entityName'
                items={entities}
                label='entity'
                checkedItems={filteredEntities}
                setCheckedItems={handlerSetCheckedItems}
                searchValue={searchEntityValue}
                setSearchValue={setSearchEntityValue}
                noElementsFoundtext={t('noEntitiesFound')}
                key='entityFilterButton'
            />
        ) : null,
        <GridSearchBar searchBoxPlaceholderText={t('search')} key='gridSearchBar' />,
        compareButton,
    ];
    const reportLinesWrapperClass = mergeStyles({ displayName: 'reportLinesWrapper' });
    const hasCalculationOptions = currentWhitePaperReport?.options?.length !== undefined && currentWhitePaperReport?.options?.length > 0;

    const actionBarButtons = ActionBarButtons({
        isReportActive: !showPdf,
        handleOpenPathToPortal,
        navigateToReport,
        hasForms,
        isLoading: isLoadingToc || !isDataReadyToDisplay,
    });

    const calculateButton = CalculateButton({
        onClick: rerunCalculation,
        disabled: isFetching || currentReportIsCalculating,
    });

    const [enterAmountsDialogProps, setEnterAmountsDialogProps] = useState<IEnterAmountsDialogProps | null>(null);
    const openEnterAmountsIfAllowed = (eventArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null) => {
        if (eventArgs) {
            const columnIndex = eventArgs.cellIndex.cellIndex! - CLICKABLE_COLUMN_START_INDEX;
            const currentColumn = currentWhitePaperReport?.columns?.[columnIndex];

            setEnterAmountsDialogProps({
                //buttonTrigger: eventArgs as HTMLElement,
                rowDescription: `Row ${eventArgs.data.description}, ${currentColumn?.entityCode} ${currentColumn?.entityName} `,
                columnId: currentColumn?.originalColumnId,
                drillDownRowNumber: Number.parseInt(eventArgs.data.displayRowNumber!),
                onClose: () => setEnterAmountsDialogProps(null),
                entity: currentColumn?.entityCode ?? currentReport!.entityCode!,
            } as unknown as IEnterAmountsDialogProps);
        }
    };

    const { data: isEnterAmountsEnabled } = useIsFeatureEnabled({ featureFlagName: 'EnterAmountsModalEnabled' });
    const onEnterAmountsClosedCallback: (amountsChangedOnServer: boolean, updatedChangeTrackingData: IWhitePaperReportChanges) => void = (
        amountsChangedOnServer: boolean,
        updatedChangeTrackingData: IWhitePaperReportChanges
    ) => {
        setEnterAmountsDialogProps(null);
        setIsRecalculateNeeded(isRecalculateNeeded || amountsChangedOnServer);
        setChangeTrackingData(updatedChangeTrackingData);
        if (selectableGridRef.current) {
            selectableGridRef.current.focusCell(undefined as unknown as IIndex);
            if (changeTrackingData !== updatedChangeTrackingData) {
                selectableGridRef.current.markAsEdited(currentCellArgs?.cellIndex!);
            }
        }
    };
    const { functionalAccess } = useFunctionalAccess();
    return (
        <Stack className={reportLinesWrapperClass}>
            <CommentsUIStateProvider commentsUIState={initialCommentsUIState}>
                <CommentsPanel
                    locationType={CommentsPanelLocationType.ReportField}
                    reportId={props.reportId.toString()}
                    reportName={currentReport?.name ?? undefined}
                    reportRowNumber={commentData?.rowData?.rowNumber}
                    reportRowDescription={commentData?.rowData?.description ?? undefined}
                    reportColumnName={commentData?.columnName ?? undefined}
                    isOpen={isCommentsPanelOpen}
                    onClose={() => setIsCommentsPanelOpen(false)}
                ></CommentsPanel>
            </CommentsUIStateProvider>
            <GridStateContextProvider grid={gridComponentRef}>
                <CommonIndividualReportTable
                    key={props.reportId}
                    columns={columns}
                    currentReport={currentWhitePaperReport}
                    isError={isErrorReport || (isErrorFetchingWhitePaperReport && validateErrorOnLoadingReportData)}
                    isTableDataReadyToDisplay={isDataReadyToDisplay}
                    componentRef={selectableGridRef}
                    dataManager={dataManager}
                    currentCellArgs={currentCellArgs}
                    setCurrentCellArgs={setCurrentCellArgs}
                    setIsDirtyFlag={props.setIsDirtyFlag}
                    onRenderFinished={renderFinished}
                    onScrollingStarted={startTrackingScrollEvent}
                    contextMenuProps={
                        {
                            enterAmounts: {
                                getVisibilityState: (rowData: IRowInfo<IWhitePaperRow>) => {
                                    if (
                                        isDrillDownContextMenuAllowed(
                                            rowData,
                                            isMultiColumns,
                                            currentWhitePaperReport,
                                            isDrillDownAllowed
                                        ) &&
                                        isEnterAmountsEnabled
                                    ) {
                                        if (functionalAccess?.amountDataEntryAccessEnabled) {
                                            return ContextMenuItemDisplayState.Enabled;
                                        }
                                        return ContextMenuItemDisplayState.Disabled;
                                    }
                                    return ContextMenuItemDisplayState.Invisible;
                                },
                                onMenuItemClick: openEnterAmountsIfAllowed,
                            } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                            drillDown: {
                                getVisibilityState: (rowData: IRowInfo<IWhitePaperRow>) => {
                                    if (
                                        isDrillDownContextMenuAllowed(rowData, isMultiColumns, currentWhitePaperReport, isDrillDownAllowed)
                                    ) {
                                        if (functionalAccess?.amountDataEntryAccessEnabled) {
                                            return ContextMenuItemDisplayState.Enabled;
                                        }
                                        return ContextMenuItemDisplayState.Disabled;
                                    }
                                    return ContextMenuItemDisplayState.Invisible;
                                },
                                onMenuItemClick: openDrillDownIfAllowed,
                            } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                            comments: {
                                getVisibilityState: (rowInfo: IRowInfo<IWhitePaperRow>) => {
                                    const index = { ...rowInfo } as IIndex;
                                    if (isDrillDownAllowed(index)) {
                                        return ContextMenuItemDisplayState.Enabled;
                                    }
                                    return ContextMenuItemDisplayState.Invisible;
                                },
                                onMenuItemClick: openCommentsIfAllowed,
                            } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                            editCell: {
                                getVisibilityState: (rowInfo: IRowInfo<IWhitePaperRow>) => {
                                    return ContextMenuItemDisplayState.Invisible;
                                },
                                onMenuItemClick: editCell,
                            } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                        } as ICommonIndividualReportTableContextMenuProps
                    }
                    isRecalculateNeeded={isRecalculateNeeded}
                    setIsRecalculateNeeded={setIsRecalculateNeeded}
                    reportId={props.reportId}
                    calculateButton={calculateButton}
                    actionBar={actionBarButtons}
                    filterGroupItems={filterGroupItems}
                    clearAllFilters={() => handlerSetCheckedItems([])}
                    shouldDisplayCalculationOptions={hasCalculationOptions}
                    loadingIndicator={{ indicatorType: 'Shimmer' }}
                />
            </GridStateContextProvider>
            {isDrillDownVisible && (
                <ReportDrillDown
                    onClose={closeDrillDown}
                    report={currentReport!}
                    showPanel={isDrillDownVisible}
                    whitePaperReportMap={currentWhitePaperReport?.rowsMap as IWhitePaperMappedRowDictionary}
                    whitePaperReportColumn={currentWhitePaperColumn}
                    changeTrackingData={changeTrackingData}
                    isMultiColumnWhitePaperReport={isMultiColumns}
                />
            )}
            {enterAmountsDialogProps && (
                <EnterAmountsDialog
                    {...enterAmountsDialogProps}
                    case={currentReport!.case!}
                    jurisdiction={currentReport!.jurisdiction!}
                    period={currentReport!.year!.toString()}
                    adjustmentCode=''
                    location={currentReport!.location!}
                    changeTrackingData={changeTrackingData}
                    updateChangeTrackingData={setChangeTrackingData}
                    taxReturnKey={currentReport!.id!}
                    onClose={onEnterAmountsClosedCallback}
                />
            )}
            {props.blocker && (
                <ConfirmationDialog
                    show={props.blocker.state === 'blocked'}
                    filterActionBarProps={{
                        primaryButtonLabel: t('saveChanges'),
                        primaryHandler: () => props?.blocker?.proceed?.(),
                        secondaryButtonLabel: t('cancel'),
                        secondaryHandler: () => props?.blocker?.reset?.(),
                    }}
                    heading={t('saveChanges')}
                    content={t('preventNavigationDialogContent')}
                    onDismiss={() => props?.blocker?.reset?.()}
                />
            )}
        </Stack>
    );
};

export { ReportLinesTable as default };
