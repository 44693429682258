import { corptaxThemeTokens, Footer } from '@corptax/react-components-common';
import { mergeStyles, Separator } from '@fluentui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAppVersionCalculationReviewVersion } from '../../api/app-version/app-version';
import { useGetEnterprise } from '../../api/report/report';
import { useUserSettings } from '../../contexts/useUserSettings';
import { FOOTER_SIZE, NAVBAR_SIZE, SIDEMENU_SIZE } from '../../utils/Style.constants';

export const FooterContainer: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { data: enterpriseData } = useGetEnterprise();
    const { data: versionData } = useGetAppVersionCalculationReviewVersion();
    const userSettings = useUserSettings();

    const footerStyles = mergeStyles({
        display: 'flex',
        gap: '19px',
        width: '100%',
        justifyContent: 'center',
        color: corptaxThemeTokens.colorNeutralForeground3,
    });

    const wrapperStyles = mergeStyles({
        display: 'flex',
        flexDirection: 'column',
        height: FOOTER_SIZE,
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        width: `calc(100% - ${SIDEMENU_SIZE} - ${NAVBAR_SIZE})`,
    });

    const version: string = versionData ?? t('loading');
    const build: string = userSettings?.buildVersion ?? t('loading');
    const environment: string = userSettings.environmentName ?? t('loading');
    const enterprise: string = enterpriseData?.enterpriseName ?? t('loading');

    return (
        <div className={wrapperStyles}>
            <Separator styles={{ root: { margin: 0, padding: 0 } }} />
            <Footer version={version} build={build} environment={environment} enterprise={enterprise} className={footerStyles} />
        </div>
    );
};
