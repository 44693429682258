import { DetailsList, IComponentAs, IDetailsGroupRenderProps, IDetailsListProps, IGroup, SelectionMode } from '@fluentui/react';
import { IGroupedListProps, IGroupHeaderProps } from '@fluentui/react/lib/GroupedList';
import { forwardRef, KeyboardEvent, MutableRefObject, RefObject, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllowNull } from '../../data-types/AllowUndefinedAndNull';
import { treeGridRole } from './CardGridListConstants';
import { createGridColumns, createGridDataSource, createGridGroups } from './CardGridListData';
import { doEffects } from './CardGridListEffects';
import { handleEventsUsing } from './CardGridListEventHandling';
import {
    ICardGridList,
    ICardGridListEventHandling,
    ICardGridListNotify,
    ICardGridListProps,
    ICardGridListRender,
    ICardGridListRowData,
    ICardGridListStyling,
    ICardGridListUpdate,
    IColumnExtended,
    IGroupExtended,
} from './CardGridListInterfaces';
import { renderUsing } from './CardGridListRender';
import { styleUsing } from './CardGridListStyling';
import { CardGridListExpandCollapseState } from './CardGridListTypes';
import { createUniqueGroupedListId } from './CardGridListUtilities';

class CardGridListRef implements ICardGridListUpdate {
    private _datasourceRef: RefObject<ICardGridListRowData[]>;
    private _handle: ICardGridListEventHandling | null;
    private _notify: ICardGridListNotify | null;

    constructor(datasourceRef: RefObject<ICardGridListRowData[]>) {
        this._datasourceRef = datasourceRef;
        this._handle = null;
        this._notify = null;
    }

    get expandCollapseState(): CardGridListExpandCollapseState {
        return this._handle?.expandCollapseState ?? CardGridListExpandCollapseState.AllCollapsed;
    }

    set handle(value: ICardGridListEventHandling) {
        this._handle = value;
        this._handle.notify = this._notify;
    }

    set notify(value: ICardGridListNotify | null) {
        this._notify = value;

        if (this._handle) {
            this._handle.notify = this._notify;
        }
    }

    collapseAll(): void {
        this._handle?.collapseAll();
    }

    expandAll(): void {
        this._handle?.expandAll();
    }

    getGridGroups<T>(predicate?: (rowData: ICardGridListRowData & T) => boolean): IGroupExtended[] {
        const allRowData: AllowNull<(ICardGridListRowData & T)[]> = this._datasourceRef.current as unknown as AllowNull<
            (ICardGridListRowData & T)[]
        >;

        if (!allRowData) {
            return [];
        }

        const filteredRowData: (ICardGridListRowData & T)[] = predicate ? allRowData.filter(predicate) : allRowData;
        const gridGroups: IGroupExtended[] = filteredRowData.map((rowData: ICardGridListRowData & T) => rowData.gridGroup);

        return gridGroups;
    }
}

const CardGridList = forwardRef<ICardGridList, ICardGridListProps>(function CardGridList(props: ICardGridListProps, ref: any): any {
    const { t } = useTranslation();
    const styling: ICardGridListStyling = useMemo(() => styleUsing(), []);
    const gridColumns: IColumnExtended[] = useMemo(
        () => createGridColumns(props.columns, styling.detailsColumnStyles(props.columns, props.columnHeaderStyle)),
        [createGridColumns, styling, props.columns, props.columnHeaderStyle]
    );
    const gridGroupsRef: MutableRefObject<IGroupExtended[]> = useRef(null as any);

    if (!gridGroupsRef.current) {
        gridGroupsRef.current = createGridGroups(props.groups, props.groupKeyGenerator);
    }

    const dataSourceRef: MutableRefObject<ICardGridListRowData[]> = useRef(null as any);

    if (!dataSourceRef.current) {
        dataSourceRef.current = createGridDataSource(gridGroupsRef.current, t, props.childDataPropertyNames);
    }

    const groupedListId: string = useMemo(() => createUniqueGroupedListId(), []);
    const isEmptyGridGroups: boolean = gridGroupsRef.current.length === 0;
    const handle: ICardGridListEventHandling = useMemo(
        () => handleEventsUsing(props, groupedListId, gridGroupsRef.current, dataSourceRef.current),
        [groupedListId, gridGroupsRef.current, dataSourceRef.current, props]
    );
    const render: ICardGridListRender = useMemo(
        () => renderUsing(props, groupedListId, gridColumns, gridGroupsRef.current, dataSourceRef.current, handle, t),
        [props, groupedListId, gridColumns, gridGroupsRef.current, dataSourceRef.current, handle, t]
    );

    const groupHeaderProps: IGroupHeaderProps = useMemo(() => {
        return {
            onRenderTitle: render.groupHeaderTitle,
            onRenderName: render.groupHeaderName,
            onToggleCollapse: handle.rowExpandCollapseToggled,
            styles: styling.groupHeaderStyles(props.customRows),
            onGroupHeaderKeyUp: (event: KeyboardEvent<HTMLElement>, group?: IGroup) => handle.groupedListKeyUp(groupedListId, event, group),
        };
    }, [
        render.groupHeaderTitle,
        render.groupHeaderName,
        handle.rowExpandCollapseToggled,
        styling.groupHeaderStyles,
        props.customRows,
        handle.groupedListKeyUp,
        groupedListId,
    ]);

    const detailGroupRenderProps: IDetailsGroupRenderProps = useMemo(() => {
        return {
            groupedListAs: render.groupedList as IComponentAs<IGroupedListProps>,
            headerProps: groupHeaderProps,
            onRenderHeader: render.groupHeader,
            showEmptyGroups: true,
        };
    }, [render.groupedList, groupHeaderProps, render.groupHeader]);

    const detailsListProps: IDetailsListProps = useMemo(() => {
        return {
            className: styling.cardGridListClassName,
            columns: gridColumns,
            items: isEmptyGridGroups ? [{}] : [],
            isHeaderVisible: true,
            onRenderDetailsHeader: render.columnHeaders,
            onShouldVirtualize: () => false, // !props.isUnitTestExecution,
            role: treeGridRole,
            selectionMode: SelectionMode.none,
            styles: styling.detailsListStyles,
            useReducedRowRenderer: true,
            onRenderRow: render.row,
            groupProps: !isEmptyGridGroups ? detailGroupRenderProps : undefined,
            groups: !isEmptyGridGroups ? gridGroupsRef.current : undefined,
        } as IDetailsListProps;
    }, [
        styling.cardGridListClassName,
        gridColumns,
        isEmptyGridGroups,
        render.columnHeaders,
        treeGridRole,
        SelectionMode.none,
        styling.detailsListStyles,
        render.row,
        detailGroupRenderProps,
        gridGroupsRef.current,
    ]);

    useEffect(() => {
        return doEffects(props.id);
    }, [props.id]);

    const [forwardRef] = useState<ICardGridListUpdate>(new CardGridListRef(dataSourceRef));

    useImperativeHandle(ref, () => forwardRef, [forwardRef]);
    if (props.onExpandCollapseStateChange) {
        forwardRef.notify = {
            onExpandCollapseStateChange: props.onExpandCollapseStateChange,
        } as ICardGridListNotify;
    }
    handle.grid = forwardRef;
    forwardRef.handle = handle;

    return (
        <div className={`${styling.gridContainerClass(props.height)} ${props.className}`} id={props.id}>
            <DetailsList {...detailsListProps} />
        </div>
    );
});

export default CardGridList;
