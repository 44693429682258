import { Column, GridComponent, IIndex } from '@syncfusion/ej2-react-grids';
import { createContext, useState } from 'react';
import { useGridScroll } from '../hooks/useGridScroll';
import { ReportVariancePoint } from '../model';
import { scrollToRowAndSetFocusCell } from '../utils/ReportUtils';
import { calculateNextVarianceStep, scrollToX } from '../utils/TableUtils';
export interface IVarianceNavigationValues {
    isVarianceNavigating: boolean;
    setIsVarianceNavigating: (isVarianceNavigating: boolean) => void;
    currentVarianceIndex: number;
    setCurrentVarianceIndex: (current: number) => void;
    isDisabledVarianceGoToNavigation: boolean;
    setIsDisabledVarianceGoToNavigation: (isDisabled: boolean) => void;
    resetAndDisableNavigation: () => void;
    scrollToVariance: (
        variancePoints: ReportVariancePoint[],
        isNext: boolean,
        showOnlyExceedingRowsCookieValue: boolean,
        grid?: GridComponent | null
    ) => void;
    horizontallyScrollToVariance: (grid: GridComponent | null, columnIndex: number | null) => void;
    getCalculatedColumnIndex: (colIndex: number | undefined) => number;
}

export const VarianceNavigationContext = createContext<IVarianceNavigationValues | null>(null);

export function VarianceNavigationContextProvider(props: { value?: IVarianceNavigationValues; children?: React.ReactNode }) {
    const [isVarianceNavigating, setIsVarianceNavigating] = useState<boolean>(false);
    const [currentVarianceIndex, setCurrentVarianceIndex] = useState<number>(0);
    const [isDisabledVarianceGoToNavigation, setIsDisabledVarianceGoToNavigation] = useState(false);
    const { programmaticallyScrollToRow, setShouldScrollToColumnOnDataBoundTrue } = useGridScroll();
    const numberOfColumnsInCompare = 3;
    const numberOfColumnsBeforeFirstVariance = 4;

    const resetAndDisableNavigation = () => {
        setCurrentVarianceIndex(0);
        setIsDisabledVarianceGoToNavigation(true);
    };

    const getCalculatedColumnIndex = (colIndex: number | undefined) => {
        return colIndex !== undefined ? colIndex * numberOfColumnsInCompare + numberOfColumnsBeforeFirstVariance : 0;
    };

    const goToVarianceByStep = (
        step: number,
        variancePoints: ReportVariancePoint[],
        showOnlyExceedingRowsCookieValue: boolean,
        grid?: GridComponent | null
    ) => {
        let varianceIndex = step - 1;
        const rowIndexInTable = getEquivalentRowIndex(variancePoints, varianceIndex, showOnlyExceedingRowsCookieValue);

        const cellIndex: IIndex = {
            rowIndex: rowIndexInTable,
            cellIndex: getCalculatedColumnIndex(variancePoints[varianceIndex].colIndex),
        };

        scrollToRowAndSetFocusCell(
            setIsVarianceNavigating,
            setCurrentVarianceIndex,
            programmaticallyScrollToRow,
            cellIndex,
            step,
            grid || undefined
        );
    };

    const scrollToVariance = (
        variancePoints: ReportVariancePoint[],
        isNext: boolean,
        showOnlyExceedingRowsCookieValue: boolean,
        grid?: GridComponent | null
    ) => {
        const step = calculateNextVarianceStep(currentVarianceIndex, variancePoints.length, isNext);
        goToVarianceByStep(step, variancePoints, showOnlyExceedingRowsCookieValue, grid);
    };

    const horizontallyScrollToVariance = (grid: GridComponent | null, columnIndex: number | null) => {
        if (grid && grid.getContent().children.length > 0 && columnIndex !== null) {
            let columns = grid.columns as Column[];
            columns = columns.filter((c) => !c.isFrozen);

            const xPosition = getXPositionForColumnIndex(columnIndex, columns);

            scrollToX(grid, xPosition);
            setShouldScrollToColumnOnDataBoundTrue();
        }
    };

    const getXPositionForColumnIndex = (columnIndex: number, columns: Column[]): number => {
        let result = 0;
        // With column virtualization, we don't have all the columns in the grid
        // So when we tried to find the "leftOffset" of a cell that has not been rendered, we ended up getting 0 and scrolling to the beginning of the grid
        // To fix this, we're going through the whole list of columns definitions and summing the width of the columns that are before the one we're looking for

        if (columns && columnIndex >= numberOfColumnsBeforeFirstVariance) {
            for (let i = 0; i < columnIndex - numberOfColumnsInCompare; i++) {
                result += parseInt((columns[i].width as string).replace('px', ''));
            }
        }

        return result;
    };

    return (
        <VarianceNavigationContext.Provider
            value={
                props.value ?? {
                    isVarianceNavigating,
                    setIsVarianceNavigating,
                    currentVarianceIndex,
                    setCurrentVarianceIndex,
                    isDisabledVarianceGoToNavigation,
                    setIsDisabledVarianceGoToNavigation,
                    resetAndDisableNavigation,
                    scrollToVariance,
                    horizontallyScrollToVariance,
                    getCalculatedColumnIndex,
                }
            }
        >
            {props.children}
        </VarianceNavigationContext.Provider>
    );
}

export const getEquivalentRowIndex = (
    variancePoints: ReportVariancePoint[],
    varianceIndex: number,
    showOnlyExceedingRowsCookieValue: boolean
) => {
    const uniqueRowIndexValues = Array.from(new Set(variancePoints.map((vp) => vp.rowIndex)));

    return showOnlyExceedingRowsCookieValue
        ? uniqueRowIndexValues.indexOf(variancePoints[varianceIndex].rowIndex || 0)
        : variancePoints[varianceIndex].rowIndex;
};
