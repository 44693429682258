import { useCorptaxTheme } from '@corptax/react-components-common';
import {
    IButtonStyles,
    IContextualMenuItem,
    IContextualMenuItemRenderProps,
    IContextualMenuItemStyles,
    IContextualMenuListProps,
    Label,
    mergeStyles,
    mergeStyleSets,
    Separator,
    Stack,
    StackItem,
} from '@fluentui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterItem } from '../../../data-types/filterItem';
import { useContextualMenuWithSearchBox } from '../../../hooks/useContextualMenuWithSearchBox';
import { useFunctionalAccess } from '../../../hooks/useFunctionalAccess';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getFolderItemRender } from '../../../utils/ContextualMenuUtils';
import { roleMenu } from '../../../utils/Filter.constant';
import { getFilterMenuAriaLabel } from '../../../utils/FilterUtils';
import FunctionalAccessDisableWrapper from '../../common/FunctionalAccessDisableWrapper';
import CustomIconButton from '../../common/IconButton';
import IconButtonWithContextualMenu from '../../common/IconButtonWithContextualMenu';

export interface IFoldersListFilterProps {
    folders: IFilterItem[];
    label?: string;
    filterName: string;
    showSingleSelect?: boolean;
    selectedFolderFilter?: IFilterItem | null;
    isFiltered: boolean;
    onButtonClick?: (filterName: string) => void;
    onFolderChange: (filteredItems: IFilterItem[], filterName: string) => void;
    updateFolderList?: () => Promise<any>;
    disabled?: boolean;
}

const FoldersListFilter: React.FC<IFoldersListFilterProps> = ({
    folders,
    label,
    filterName,
    selectedFolderFilter,
    isFiltered,
    disabled,
    onFolderChange,
    updateFolderList,
}) => {
    const newFolderKey = 'new-folder';
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const getFoldersFilterStyles = () =>
        mergeStyleSets({
            folderFilterItemWrapperClassName: {
                padding: '0',
            },
            titleClass: {
                color: customPalette.blueDark,
                fontSize: '9px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '12px',
                letterSpacing: '2.4px',
                textTransform: 'uppercase',
                padding: '0 0 0 9px',
                height: '12px',
                display: 'block',
            },
        });

    const separatorClass = mergeStyles({
        padding: '0',
        height: '12px',
    });

    const folderItemButton: IButtonStyles = {
        root: {
            width: '100%',
            border: 0,
            background: 'transparent',
            shadow: 'none',
            justifyContent: 'flex-start',
        },
    };
    const { folderFilterItemWrapperClassName, titleClass } = getFoldersFilterStyles();
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[] | undefined>(undefined);
    const [announceMessage, setAnnounceMessage] = useState<string | undefined>(undefined);
    const selectedFolderAsArray = useMemo(() => (selectedFolderFilter ? [selectedFolderFilter] : []), [selectedFolderFilter]);
    const { functionalAccess } = useFunctionalAccess();

    const setMenuItemsAdapter = (menuItems: IContextualMenuItem[] | undefined) => {
        menuItems = getFolderItemRender(menuItems, updateFolderList, onFolderChange);

        setMenuItems(menuItems);
    };

    const onCancelAddFolder = () => {
        const items = menuItems?.filter((item) => item.key !== newFolderKey) || [];
        setMenuItems(items);
        setAnnounceMessage(undefined);
    };

    const onSaveFolderCallback = () => {
        setAnnounceMessage('');
        setMenuItems(menuItems?.filter((item) => item.key !== newFolderKey) || []);
    };

    const handleAddFolder = () => {
        if (!menuItems) {
            return;
        }

        if (menuItems.findIndex((item) => item.key === newFolderKey) !== -1) {
            return;
        }

        const newMenuItem = getFolderItemRender(
            [{ key: newFolderKey }],
            updateFolderList,
            onFolderChange,
            onCancelAddFolder,
            true,
            onSaveFolderCallback
        );

        setMenuItems([newMenuItem[0], ...menuItems]);
        setAnnounceMessage('');
    };

    const getAddNewFolderMenuItem = (menuListProps: IContextualMenuListProps) => {
        return [
            menuListProps.defaultMenuItemRenderer({
                key: 'folderActionsBar',
                onRender: () => {
                    return (
                        <>
                            <Separator className={separatorClass} />
                            <Stack horizontal grow>
                                <StackItem grow align='start'>
                                    <FunctionalAccessDisableWrapper hasFunctionalAccess={functionalAccess?.createFolderButtonEnabled}>
                                        <CustomIconButton
                                            label={t('newFolder').toString()}
                                            iconName='FolderAdd20Regular'
                                            styles={folderItemButton}
                                            onButtonClick={handleAddFolder}
                                        />
                                    </FunctionalAccessDisableWrapper>
                                </StackItem>
                            </Stack>
                        </>
                    );
                },
                itemProps: {
                    tabIndex: 0,
                    role: 'menuitem',
                    styles: {
                        item: {
                            'list-style-type': 'none',
                        },
                    } as IContextualMenuItemStyles,
                },
                onClick: handleAddFolder,
                hasIcons: false,
                hasCheckmarks: false,
                totalItemCount: 1,
                index: 0,
                focusableElementIndex: 0,
            } as IContextualMenuItemRenderProps),
        ];
    };

    const { contextualMenuObject } = useContextualMenuWithSearchBox({
        filterMenuItems: folders,
        filterName,
        itemWrapperClassName: folderFilterItemWrapperClassName,
        onMenuItemClick: onFolderChange,
        searchItemName: 'folder',
        isFiltered,
        noResultsText: t('noFoldersFound'),
        viewAllText: t('viewAll'),
        searchPlaceholderText: t('search'),
        menuItems,
        setMenuItems: setMenuItemsAdapter,
        filterMenuAriaLabel: getFilterMenuAriaLabel('selectFolder', t),
        menuListRole: roleMenu,
        selectedFilterItems: selectedFolderAsArray,
        getExtendedMenuItems: getAddNewFolderMenuItem,
        overrideAnnouncedMessage: announceMessage,
        iconProps: null,
    });

    const ariaLabelForButton: string = useMemo(() => {
        if (isFiltered) {
            return `${t('selectFolder')}. ${t('currentlySelectedFolder')} ${selectedFolderFilter?.label}.`;
        }

        return `${t('selectFolder')}. ${t('currentlySelected')}${t('allCalculations')}.`;
    }, [selectedFolderFilter, isFiltered, t]);

    const buttonId: string = `${filterName}-button`;

    return (
        <Stack horizontal>
            <Stack>
                <Label className={titleClass}>{isFiltered ? t('selectedFolder') : t('selectFolder')}</Label>
                <IconButtonWithContextualMenu
                    ariaLabel={ariaLabelForButton}
                    menuProps={contextualMenuObject}
                    buttonId={buttonId}
                    label={t(selectedFolderFilter?.label || label || filterName)}
                    isDisabled={disabled}
                />
            </Stack>
        </Stack>
    );
};

export default FoldersListFilter;
