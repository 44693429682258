import { DeleteConversationRequest, DeleteReplyRequest, UpdateConversationRequest, UpdateReplyRequest } from '../model';

export enum CommentsCurrentType {
    ViewingComments = 0,
    AddingNewConversation = 1,
    EditingConversation = 2,
    AddingNewReply = 3,
    EditingReply = 4,
    DeletingConversation = 5,
    DeletingReply = 6,
    CancelingOperation = 7,
}

export interface ICommentsUIState {
    selectedCommentId: string | null;
    selectedReplyId: string | null;
    openCommentId: string | null;
    highlightedCommentId: string | null;

    showDeleteDialog: boolean;
    hasReplies: boolean;
    isSubmitting: boolean;

    conversationUpdateRequest: UpdateConversationRequest | null;
    conversationDeleteRequest: DeleteConversationRequest | null;
    replyUpdateRequest: UpdateReplyRequest | null;
    replyDeleteRequest: DeleteReplyRequest | null;

    currentUiState: CommentsCurrentType;
    hasPendingChanges: boolean;
    operationFailedErrorMessage: string | null;
    submitFailedErrorMessage: string | null;
}
