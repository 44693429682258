import { classNamesFunction, IContextualMenuItem, IContextualMenuItemStyleProps, IContextualMenuItemStyles } from '@fluentui/react';
import ContextualMenuFolderItem from '../components/common/ContextualMenuFolderItem';
import { IFilterItem } from '../data-types/filterItem';

export const getFolderItemRender = (
    menuItems: IContextualMenuItem[] | undefined,
    updateFolderList?: () => Promise<any>,
    onFolderChange?: (filteredItems: IFilterItem[], filterName: string) => void,
    onCancel?: () => void,
    isNewFolder?: boolean,
    onSaveFolder?: () => void
): IContextualMenuItem[] => {
    return (
        menuItems?.map((item, index) => {
            if (item.key === 'no_results') return item;

            item.onRender = (item, dismissMenu) => (
                <ContextualMenuFolderItem
                    item={item}
                    itemIndex={index}
                    dismissMenu={dismissMenu}
                    updateFolderList={updateFolderList}
                    onFolderChange={onFolderChange}
                    onCancel={onCancel}
                    isNewFolder={isNewFolder}
                    onSaveFolder={onSaveFolder}
                ></ContextualMenuFolderItem>
            );
            return item;
        }) ?? []
    );
};

export const getFilteredContextualMenuItems = (contextualMenuItems: IContextualMenuItem[], searchValue?: string): IContextualMenuItem[] => {
    return contextualMenuItems.filter((item) => {
        const filterItem: IFilterItem = item?.data?.filterItem as IFilterItem;
        const filterItemSearchBy: string[] = filterItem?.searchBy as string[];
        return filterItemSearchBy?.some((x) => x?.toLowerCase().includes(searchValue?.toLocaleLowerCase() ?? ''));
    });
};

export const getFilteredItemsOrEmptyValue = (contextualMenuItems: IContextualMenuItem[], noResultsText: string, searchValue?: string) => {
    let filteredMenuItems: IContextualMenuItem[] = getFilteredContextualMenuItems(contextualMenuItems, searchValue);

    if (!filteredMenuItems || filteredMenuItems.length === 0) {
        filteredMenuItems.push({
            key: 'no_results',
            onRender: (item: IContextualMenuItem, dismissMenu) => {
                const getClassNames = classNamesFunction<IContextualMenuItemStyleProps, IContextualMenuItemStyles>();
                const classNames = getClassNames(item?.styles);
                return (
                    <div key={item.key} className={classNames?.root}>
                        <span>{item.text}</span>
                    </div>
                );
            },
            styles: {
                root: {
                    padding: '4px',
                },
            },
            text: noResultsText,
        } as IContextualMenuItem);
    }

    return filteredMenuItems;
};

export const clickHandlerWrapperUtil = (
    tempSelectedItems: IFilterItem[] | undefined,
    setTempSelectedItems: React.Dispatch<React.SetStateAction<IFilterItem[] | undefined>>,
    onMenuItemClick: (filteredItems: IFilterItem[], filterName: string) => void,
    filterName: string,
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    contextualMenuItem?: IContextualMenuItem,
    isMultiSelect?: boolean
): void => {
    if (isMultiSelect) {
        ev && ev.preventDefault();
        ev?.stopPropagation();
        const contextFilterItem: IFilterItem = contextualMenuItem?.data['filterItem'] as IFilterItem;
        if (tempSelectedItems?.some((item) => item.key === contextFilterItem.key)) {
            setTempSelectedItems((prev) => prev?.filter((item) => item.key !== contextFilterItem.key));
        } else {
            setTempSelectedItems((prev) => [...(prev || []), contextFilterItem]);
        }
    } else {
        const contextFilterItem: IFilterItem = contextualMenuItem?.data['filterItem'] as IFilterItem;
        if (contextFilterItem) {
            onMenuItemClick([contextFilterItem], filterName);
        }
    }
};

export const isCheckedUtil = (
    filterMenuItem: { key: string },
    isMultiSelect?: boolean,
    tempSelectedItems?: IFilterItem[],
    selectedFilterItems?: IFilterItem[] | null
): boolean => {
    if (isMultiSelect && tempSelectedItems === undefined) {
        return selectedFilterItems ? selectedFilterItems.some((item) => item.key === filterMenuItem.key) : false;
    } else if (isMultiSelect && tempSelectedItems !== undefined) {
        return tempSelectedItems ? tempSelectedItems.some((item) => item.key === filterMenuItem.key) : false;
    }
    return selectedFilterItems ? selectedFilterItems.some((item) => item.key === filterMenuItem.key) : false;
};

export const scrollToElement = (id: string) => {
    const itemEl = document.getElementById(id);
    setTimeout(() => {
        if (itemEl && itemEl.scrollIntoView) {
            itemEl.scrollIntoView();
            itemEl.focus();
        }
    }, 200);
};

/* Necessary until we fix the return from add folder */
export const scrollToFolderElementByName = (text: string) => {
    setTimeout(() => {
        var menu = document.getElementById('filterOptionsContainer');
        if (menu) {
            const itemEl = Array.from(menu.querySelectorAll('span')).find((item) => item.textContent?.startsWith(text));
            if (itemEl && itemEl.scrollIntoView) {
                itemEl.scrollIntoView();
                itemEl.focus();
            }
        }
    }, 200);
};

export const focusContextualMenuSearchBox = () => {
    const itemEl = document.querySelector('.ms-Callout .ms-ContextualMenu.is-open .ms-SearchBox-field') as HTMLElement;

    setTimeout(() => {
        if (itemEl) {
            itemEl.focus();
        }
    }, 200);
};
