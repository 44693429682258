import * as React from 'react';
import { ICommentsUIState } from '../data-types/ICommentsUIState';
import { commentsStateReducer, CommentStateAllowedAction, initialUIState } from '../reducers/commentsStateReducer';

export interface ICommentState {
    state: ICommentsUIState;
    dispatch: React.Dispatch<CommentStateAllowedAction>;
}

export const CommentsUIStateContext = React.createContext<ICommentState | null>(null);

export function CommentsUIStateProvider(props: any) {
    const [state, dispatch] = React.useReducer<React.Reducer<ICommentsUIState, CommentStateAllowedAction>>(
        commentsStateReducer,
        props.commentsUIState ? props.commentsUIState : initialUIState
    );
    return <CommentsUIStateContext.Provider value={{ state, dispatch } as ICommentState}>{props.children}</CommentsUIStateContext.Provider>;
}
