import { quoteRegExpSearchValue } from './RegExpUtils';

// As of now we are only supporting the en-US locale.
// In the future we may want to format numbers based upon browser locale by calling navigator.language or allowing customer/user preference configuration.
const enUslocale: string = 'en-US';
const [groupSeparator, decimalSeparator] = getFormattedNumberSeparators();
const displayFormat: Intl.NumberFormat = createDisplayFormat();
const editFormat: Intl.NumberFormat = createEditFormat();

export function deFormatNumber(value: string) {
    const findAllGroupSeparatorRegExp: RegExp = new RegExp(`\\${quoteRegExpSearchValue(groupSeparator)}`, 'g');
    let formattedValue = value.replace(findAllGroupSeparatorRegExp, '');

    if (isNegativeAccountingFormattedNumber(formattedValue)) {
        formattedValue = `-${formattedValue.slice(1, -1)}`;
    }

    return formattedValue;
}

export function formatNumberForDisplay(number: number): string {
    const parts: Intl.NumberFormatPart[] = displayFormat.formatToParts(number);
    const currencyIndex: number = parts.findIndex((part) => part.type === 'currency');

    parts.splice(currencyIndex, 1);
    return parts.map((part) => part.value).join('');
}

export function formatNumberForEdit(number: number): string {
    const parts: Intl.NumberFormatPart[] = editFormat.formatToParts(number);

    return parts.map((part) => part.value).join('');
}

export function isValidIntermediateNumberInput(value: string): boolean {
    const validTypedInput: RegExp = /^(-?\d*)?$/;

    return validTypedInput.test(value);
}

export function parseNumericValue(value: string, skipFormatCheck: boolean = false): number {
    if (!(skipFormatCheck || isStringProperlyFormattedNumber(value))) {
        return Number.NaN;
    }

    let cleanedValue: string = '';

    try {
        cleanedValue = deFormatNumber(value.toString());
    } catch (error) {
        return Number.NaN;
    }

    return parseFloat(cleanedValue);
}

export function tryParseNumericValue(value: string, setSuccessResult: (result: number) => void): boolean {
    const result: number = parseNumericValue(value);
    const successfulParse: boolean = !Number.isNaN(result);

    if (successfulParse) {
        setSuccessResult(result);
    }

    return successfulParse;
}

function createBaseCustomNumberFormatOptions(): Intl.NumberFormatOptions {
    let numberFormatOptions: Intl.NumberFormatOptions = getDefaultNumberFormatForLocale(enUslocale).resolvedOptions();

    // The actual currency specified here is irrelevant other than it must specify an existing, known currency.
    // Currency specific parts will be removed from formatted numbers.
    numberFormatOptions.currency = 'USD';
    numberFormatOptions.currencyDisplay = 'symbol';
    numberFormatOptions.currencySign = 'accounting';
    numberFormatOptions.maximumFractionDigits = 0;
    numberFormatOptions.minimumFractionDigits = 0;
    numberFormatOptions.minimumIntegerDigits = 1;

    // signDisplay should be changed to 'negative' when we upgrade to Node.js 19 or later
    // so formatting will not produce a value of negative zero (-0).
    // numberFormatOptions.signDisplay = 'negative' as any;
    numberFormatOptions.signDisplay = 'auto';
    return numberFormatOptions;
}

function createDisplayFormat(): Intl.NumberFormat {
    let numberFormatOptions: Intl.NumberFormatOptions = createBaseCustomNumberFormatOptions();

    // The actual currency specified here is irrelevant other than it must specify an existing, known currency.
    // Currency specific parts will be removed from formatted numbers.
    numberFormatOptions.style = 'currency';
    numberFormatOptions.useGrouping = true;
    return new Intl.NumberFormat(enUslocale, numberFormatOptions);
}

function createEditFormat(): Intl.NumberFormat {
    let numberFormatOptions: Intl.NumberFormatOptions = createBaseCustomNumberFormatOptions();

    // The actual currency specified here is irrelevant other than it must specify an existing, known currency.
    // Currency specific parts will be removed from formatted numbers.
    numberFormatOptions.style = 'decimal';
    numberFormatOptions.useGrouping = false;
    return new Intl.NumberFormat(enUslocale, numberFormatOptions);
}

function getDefaultNumberFormatForLocale(locale: string): Intl.NumberFormat {
    return new Intl.NumberFormat(locale);
}

function getFormattedNumberSeparators(): [string, string] {
    const numberParts = getDefaultNumberFormatForLocale(enUslocale).formatToParts(9999999.9999);

    // Fallback values are defined solely for testing due as they do not resolve during unit tests.
    const groupSeparator: string = numberParts.find((part) => part.type === 'group')?.value ?? ',';
    const decimalSeparator: string = numberParts.find((part) => part.type === 'decimal')?.value ?? '.';

    return [groupSeparator, decimalSeparator] as [string, string];
}

function isNegativeAccountingFormattedNumber(value: string) {
    // This only tests that a value is enclosed in parentheses.
    // It does not ensure that the enclosed value is a properly formatted number.
    const negativeNumberRegex: RegExp = /^\(.+\)$/;

    return negativeNumberRegex.test(value);
}

function isStringProperlyFormattedNumber(value: string): boolean {
    const isNumberRegExp: RegExp = new RegExp(
        String.raw`((^(\+|-))(?=\d|${quoteRegExpSearchValue(
            decimalSeparator
        )})|(^\()(?=.+\)$)|^)(\d{1,3}((\d{3})*|(${quoteRegExpSearchValue(groupSeparator)}\d{3})*))?(${quoteRegExpSearchValue(
            decimalSeparator
        )}\d+)?((\)$)(?<=^\(.+)|$)`
    );

    return isNumberRegExp.test(value);
}
