import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { IButtonStyles, IPivotStyles, IStyle } from '@fluentui/react';
import { useMemo } from 'react';
import { IReportDrillDownStyles } from '../data-types/IReportDrillDownStyles';
import { IAppTheme } from '../theme/IAppTheme';

export function useDrillDownStyles() {
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();

    const styles: IReportDrillDownStyles = useMemo(() => {
        return {
            expandAllButton: {
                root: {
                    backgroundColor: palette.white,
                    borderColor: customPalette.neutralSecondaryAlt,
                    color: customPalette.blueDark,
                    fontWeight: '600',
                },
                rootHovered: {
                    color: customPalette.themePrimary,
                    borderColor: customPalette.themePrimary,
                },
                rootDisabled: {
                    backgroundColor: customPalette.neutralLighter,
                    color: customPalette.neutralTertiary,
                },
            } as IButtonStyles,
            exportButton: {
                root: {
                    backgroundColor: palette.white,
                    borderColor: customPalette.neutralSecondaryAlt,
                    color: customPalette.blueDark,
                    fontWeight: '600',
                },
                rootHovered: {
                    color: customPalette.themePrimary,
                    borderColor: customPalette.themePrimary,
                },
                rootDisabled: {
                    backgroundColor: customPalette.neutralLighter,
                    color: customPalette.neutralTertiary,
                },
            } as IButtonStyles,
            pivotWrapper: { minHeight: '10em', flexGrow: 2, display: 'flex', flexDirection: 'column', gap: '8px' } as IStyle,
            pivot: {
                itemContainer: {
                    minHeight: '10vh',
                    // needed in order to center the loading spinner
                    height: '100%',
                },
                link: {
                    ':after': {
                        display: 'none',
                        transition: 'none',
                    },
                    ':hover': {
                        backgroundColor: 'none',
                        color: customPalette.neutralTertiaryAlt,
                        ':before': {
                            backgroundColor: corptaxCustomLightTheme.colorNeutralStroke1Hover,
                        },
                        ':after': {
                            display: 'none',
                            transition: 'none',
                        },
                    },
                    ':active': {
                        backgroundColor: 'none',
                    },
                    ':focus': {
                        outline: '-webkit-focus-ring-color auto 1px !important',
                        ':after': {
                            display: 'none !important',
                        },
                    },
                    ':focus-visible': {
                        outline: '-webkit-focus-ring-color auto 1px !important',
                        ':after': {
                            display: 'none !important',
                        },
                    },
                    borderRadius: '6px',
                    color: customPalette.neutralTertiaryAlt,
                    fontWeight: '400',
                    margin: 0,
                    padding: '6px',
                    height: 'initial',
                    lineHeight: 'initial',
                },
                linkContent: {
                    padding: '0 2px',
                },
                text: {
                    display: 'flex',
                    alignItems: 'center',
                    lineHeight: '20px',
                },
                linkIsSelected: {
                    fontWeight: 600,
                    ':before': {
                        backgroundColor: corptaxCustomLightTheme.colorBrandForegroundLinkSelected,
                        transition: 'none',
                    },
                    ':after': {
                        display: 'none',
                    },
                    ':hover': {
                        backgroundColor: 'none',
                        ':before': {
                            backgroundColor: corptaxCustomLightTheme.colorBrandForegroundLinkSelected,
                            /*The Pivot component is dynamically adding a css style onto the selected tab item which makes the underline longer by setting left and right to 0
                            so we need to use !important to override this style. without !important, these styles will be ignored even though they target the exact same selector*/
                            left: '8px !important',
                            right: '8px !important',
                        },
                        ':after': {
                            display: 'none',
                        },
                    },
                },
                root: {
                    color: customPalette.blue075A92,
                    display: 'flex',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    letterSpacing: '-0.128px',
                    lineHeight: '22px',
                },
            } as Partial<IPivotStyles>,
            header: { displayName: 'drillDownHeader', margin: '0px 6px', gap: '6px' } as IStyle,
            customizedTabList: {
                displayName: 'customizedTabList',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignSelf: 'stretch',
                padding: '0px 6px',
            } as IStyle,
            errorMessage: {
                displayName: 'errormessage',
                boxSizing: 'content-box',
                color: customPalette.red,
                fontSize: '12px',
                margin: '10px 6px 0',
                padding: '0 0 5px',
            } as IStyle,
            adjustmentAccountAssociationDialogButton: {
                root: {
                    backgroundColor: palette.white,
                    borderColor: customPalette.neutralSecondaryAlt,
                    borderRadius: '6px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    boxShadow: '0px 5px 4px -4px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    color: customPalette.blueDark,
                    maxHeight: '',
                    maxWidth: '',
                    minHeight: '',
                    minWidth: '',
                    padding: '0',
                    height: 25,
                    width: 25,
                },
                rootHovered: {
                    color: customPalette.themePrimary,
                    borderColor: customPalette.themePrimary,
                },
                rootDisabled: {
                    backgroundColor: customPalette.neutralLighter,
                    color: customPalette.neutralTertiary,
                },
            } as IButtonStyles,
        };
    }, [customPalette, palette]);

    return { styles };
}
