import { DefaultButton, IButtonStyles, IconButton, IMessageBarProps, mergeStyles, MessageBar, Modal as FUIModal } from '@fluentui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from './SpinnerButton';

export type IModalMessageBarProps = Partial<IMessageBarProps> & { message: string };

export interface IModalButtonProps {
    text: string;
    onClick: () => void;
    primary?: boolean;
    isProcessing?: boolean;
    processingText?: string;
    disabled?: boolean;
    isSmall?: boolean;
}

export interface IModalProps {
    isOpen: boolean;
    onClose: () => void;
    headerContent?: React.ReactNode;
    bodyContent?: React.ReactNode;
    buttons?: IModalButtonProps[];
    leftFooterElement?: React.ReactNode;
    messageBarProps?: IModalMessageBarProps;
    elementToFocusOnDismiss?: HTMLElement | undefined;
}

const headerStyle = mergeStyles({
    padding: '15px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '600',
    fontSize: '16px',
    color: '#323130',
});

const bodyStyle = mergeStyles({
    paddingTop: '5px',
    paddingBottom: '10px',
    paddingLeft: '24px',
    paddingRight: '24px',
    fontSize: '14px',
    color: '#323130',
    lineHeight: '1.42857',
});

const footerStyle = mergeStyles({
    padding: '10px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const actionButtonsStyle = mergeStyles({
    display: 'flex',
    gap: '10px',
});

const closeButtonStyle = mergeStyles({
    border: 'none',
    color: '#605E5C',
    cursor: 'pointer',
    marginRight: '-6px',
    marginTop: '-6px',
    selectors: {
        ':hover': {
            color: '#000',
        },
    },
});
const footerButtonDefaultStyle: IButtonStyles = {
    root: {
        marginLeft: '5px',
        borderRadius: '6px',
    },
};
const footerButtonSmallStyle: IButtonStyles = {
    root: {
        height: '32px',
        borderRadius: '6px',
        padding: '5px 12px',
        minWidth: 'unset',
    },
};

const Modal: React.FC<IModalProps> = React.memo(
    ({ isOpen, onClose, headerContent, bodyContent, buttons, leftFooterElement, messageBarProps, elementToFocusOnDismiss }) => {
        const modalRef = useRef<HTMLDivElement>(null);
        const { t } = useTranslation();

        const renderedButtons = buttons?.map((button, index) =>
            button.primary ? (
                <SpinnerButton
                    key={index}
                    text={button.text}
                    onClick={button.onClick}
                    isProcessing={button.isProcessing ?? false}
                    disabled={button.disabled ?? false}
                    processingText={button.processingText ?? t('working')}
                    ariaLabel={button.text}
                    styles={button.isSmall ? footerButtonSmallStyle : undefined}
                />
            ) : (
                <DefaultButton
                    key={index}
                    text={button.text}
                    onClick={button.onClick}
                    styles={button.isSmall ? footerButtonSmallStyle : footerButtonDefaultStyle}
                    disabled={button.disabled ?? false}
                />
            )
        );

        return (
            <FUIModal
                isOpen={isOpen}
                onDismiss={onClose}
                elementToFocusOnDismiss={elementToFocusOnDismiss}
                isBlocking={false}
                containerClassName='modal-container'
                aria-labelledby='modalTitle'
                aria-describedby='modalDescription'
            >
                {messageBarProps && (
                    <MessageBar {...messageBarProps} isMultiline={false} dismissButtonAriaLabel='Close'>
                        {messageBarProps.message}
                    </MessageBar>
                )}

                <div className={headerStyle} id='modalTitle'>
                    {headerContent}
                    <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel='Close modal' onClick={onClose} className={closeButtonStyle} />
                </div>
                <div className={bodyStyle} id='modalDescription' ref={modalRef} tabIndex={-1}>
                    {bodyContent || <p>This is a simple modal dialog.</p>}
                </div>
                <div className={footerStyle}>
                    <div>{leftFooterElement}</div>
                    {renderedButtons && <div className={actionButtonsStyle}>{renderedButtons}</div>}
                </div>
            </FUIModal>
        );
    }
);

export default Modal;
