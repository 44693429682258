import { differenceInHours, formatDistanceToNowStrict, isValid, parseISO } from 'date-fns';

const ISO_DATE_WITH_NO_TIMEZONE_REGEX = new RegExp('^(d{4})-(d{2})-(d{2})T(d{2}):(d{2}):(d{2}(?:.d*)?)([-+](d{2}):(d{2}))?$');
const ZULU_ZONE_CHAR = 'Z';

export function getDateStringAsLocaleString(dateString: string | null | undefined, includeTime: boolean = false): string {
    if (dateString && isValid(parseISO(dateString))) {
        dateString = setDateStringAsUTCIfNotTimezoneIsDefined(dateString);
        const date = parseISO(dateString);
        return includeTime
            ? date.toLocaleString(undefined, {
                  dateStyle: 'short',
                  timeStyle: 'short',
              })
            : date.toLocaleDateString(undefined, { dateStyle: 'short' });
    }

    return '';
}

export function setDateStringAsUTCIfNotTimezoneIsDefined(dateString: string): string {
    if (ISO_DATE_WITH_NO_TIMEZONE_REGEX.test(dateString)) {
        return dateString;
    }

    return dateString + ZULU_ZONE_CHAR;
}

export function getHourDifferenceToNow(dateString: string | null | undefined): number {
    if (dateString && isValid(parseISO(dateString))) {
        const date = parseISO(dateString);
        return differenceInHours(Date.now(), date);
    }

    return 0;
}

export function getFormattedDistanceToNowStrict(dateString: string | null | undefined): string {
    if (dateString && isValid(parseISO(dateString))) {
        const date = parseISO(dateString);
        return formatDistanceToNowStrict(date, { addSuffix: true });
    }

    return '';
}
