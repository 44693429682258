import { IDetailsColumnStyles } from '@fluentui/react';
import { TFunction } from 'i18next';
import { ArrayUtils } from '../../utils/ArrayUtils';
import { wrapObject } from '../../utils/ObjectUtils';
import { cardHeaderClass, noRecordsRowClass } from './CardGridListConstants';
import { ICardGridListColumn, ICardGridListGroup, ICardGridListRowData, IColumnExtended, IGroupExtended } from './CardGridListInterfaces';
import { CardGridListChildDataLoadedCallback, CardGridListExpandCollapseState } from './CardGridListTypes';
import { createUniqueGroupClassName } from './CardGridListUtilities';

export function createGridColumns(columns: ICardGridListColumn[], columnStyles: Partial<IDetailsColumnStyles>) {
    const gridColumns: IColumnExtended[] = [];

    for (const column of Object.values<ICardGridListColumn>(columns)) {
        const gridColumn: IColumnExtended = {
            className: column.fieldName,
            fieldName: column.fieldName,
            isResizable: false,
            key: column.fieldName,
            maxWidth: 0,
            minWidth: 0,
            name: column.headerText,
            cardGridListColumn: column,
            styles: columnStyles,
        };

        gridColumns.push(gridColumn);
    }

    return gridColumns;
}

export function createGridDataSource(
    groups: IGroupExtended[],
    t: TFunction,
    childDataPropertyNames?: string | string[]
): ICardGridListRowData[] {
    let flattenedData: ICardGridListRowData[] = [];

    for (const group of Object.values<IGroupExtended>(groups)) {
        group.hasChildren = doesSourceDataHaveChildren(group.cardGridListGroup.dataSource, childDataPropertyNames);
        group.childrenLoaded = group.hasChildren ? false : undefined;

        let rowDataSoruce: ICardGridListRowData = wrapSourceData(group.cardGridListGroup.dataSource, group);

        group.dataSourceIndex = flattenedData.push(rowDataSoruce) - 1;

        if (!group.hasChildren) {
            flattenedData = createNoCardRecordsRow(group, flattenedData, t);
        } else if (!group.isCollapsed) {
            flattenedData = loadChildRows(group, flattenedData, childDataPropertyNames!);
        }
    }

    return flattenedData;
}

export function createGridGroups(
    groups: ICardGridListGroup[],
    keyGenerator?: (cardGridListGroup: ICardGridListGroup) => string
): IGroupExtended[] {
    const gridGroups: IGroupExtended[] = [];
    for (const group of Object.values<ICardGridListGroup>(groups)) {
        const groupClassName = createUniqueGroupClassName();
        const gridGroup: IGroupExtended = {
            ariaLabel: group.headerText ?? undefined,
            cardGridListGroup: group,
            className: cardHeaderClass,
            count: -1,
            dataSourceIndex: -1,
            hasChildren: false,
            hasMoreData: false,
            isCollapsed: !group.initiallyExpanded,
            isDropEnabled: false,
            isShowingAll: false,
            key: keyGenerator ? keyGenerator(group) : groupClassName,
            groupClassName: groupClassName,
            name: group.headerText ?? '',
            noExpandCollapse: group.noExpandCollapseDefault,
            startIndex: -1,
            level: 0,
        };

        gridGroups.push(gridGroup);
    }

    return gridGroups;
}

function createNoCardRecordsRow(cardRootGroup: IGroupExtended, dataSource: ICardGridListRowData[], t: TFunction): ICardGridListRowData[] {
    const parentGroupLevel: number = cardRootGroup.level ?? 0;
    const parentRowData: ICardGridListRowData = dataSource[cardRootGroup.dataSourceIndex];
    const noRecordsGroup: IGroupExtended = createChildGridGroup(undefined, parentGroupLevel, parentRowData);
    const rowData: ICardGridListRowData = wrapSourceData(undefined, noRecordsGroup);

    noRecordsGroup.className = noRecordsRowClass;
    noRecordsGroup.dataSourceIndex = dataSource.push(rowData) - 1;
    noRecordsGroup.name = t('noRecordsToDisplay');
    cardRootGroup.children = [noRecordsGroup];
    cardRootGroup.hasChildren = true;
    cardRootGroup.childrenLoaded = true;
    return dataSource;
}

export function doesSourceDataHaveChildren(sourceData: any, childDataPropertyNames?: string | string[]): boolean {
    const childPropertiesDefined: boolean = ArrayUtils.isNonEmptyArray(childDataPropertyNames, true);

    if (childPropertiesDefined && sourceData) {
        for (const childDataPropertyName of Object.values(ArrayUtils.ensureArray(childDataPropertyNames))) {
            if (ArrayUtils.isNonEmptyArray(sourceData[childDataPropertyName], true)) {
                return true;
            }
        }
    }

    return false;
}

export function getGridGroupNestingLevel(gridGroup: IGroupExtended): number {
    let level: number | undefined = gridGroup.level;

    if (level === undefined && gridGroup.parent) {
        level = getGridGroupNestingLevel(gridGroup.parent);
    }

    return level ?? 0;
}

export function getGridGroupsExpandCollapseState(groups: IGroupExtended[] | undefined): CardGridListExpandCollapseState {
    let hasCollapsed: boolean = false;
    let hasExpanded: boolean = false;

    if (groups) {
        for (const group of Object.values<IGroupExtended>(groups)) {
            const groupCanExpandCollapse: boolean = !group.noExpandCollapse;
            const groupHasChildren: boolean = group.hasChildren;
            const groupIsCollapsed: boolean = !groupHasChildren || (group.isCollapsed ?? true);
            let evaluateChildren: boolean;

            if (!groupCanExpandCollapse) {
                // Only evaluate children of groups which cannot be expanded/collapsed if the group is expanded.
                evaluateChildren = !groupIsCollapsed;
            } else {
                if (groupIsCollapsed) {
                    hasCollapsed = true;
                } else {
                    hasExpanded = true;
                }

                evaluateChildren = !(hasCollapsed && hasExpanded);
            }

            if (group.hasChildren && evaluateChildren) {
                switch (getGridGroupsExpandCollapseState(group.children as IGroupExtended[] | undefined)) {
                    case CardGridListExpandCollapseState.AllCollapsed:
                        hasCollapsed = true;
                        break;

                    case CardGridListExpandCollapseState.AllExpanded:
                        hasExpanded = true;
                        break;

                    case CardGridListExpandCollapseState.Mixed:
                        hasCollapsed = true;
                        hasExpanded = true;
                        break;
                }
            }

            if (hasCollapsed && hasExpanded) {
                // Mixed state of both expanded and collapsed.  No need to further evaluate.
                break;
            }
        }
    }

    if (hasCollapsed && hasExpanded) {
        return CardGridListExpandCollapseState.Mixed;
    } else if (hasExpanded) {
        return CardGridListExpandCollapseState.AllExpanded;
    } else {
        return CardGridListExpandCollapseState.AllCollapsed;
    }
}

export function loadChildRows(
    group: IGroupExtended,
    dataSource: ICardGridListRowData[],
    childDataPropertyNames: string | string[],
    onchildDataLoaded?: CardGridListChildDataLoadedCallback
): ICardGridListRowData[] {
    if (!group.childrenLoaded) {
        const rowData: ICardGridListRowData = dataSource[group.dataSourceIndex];
        const parentGroupLevel: number = getGridGroupNestingLevel(group);
        const nestingLevel: number = group.cardGridListGroup.noChildNesting ? parentGroupLevel : parentGroupLevel + 1;

        group.children = [];

        for (const childDataPropertyName of Object.values<string>(ArrayUtils.ensureArray(childDataPropertyNames))) {
            const currentChildRowsData: ICardGridListRowData[] = [];
            let childData: any = (rowData as any)[childDataPropertyName] ?? [];

            for (const childDataItem of Object.values<any>(ArrayUtils.ensureArray(childData))) {
                const childGroup: IGroupExtended = createChildGridGroup(childDataItem, nestingLevel, rowData, childDataPropertyNames);
                const childRowData: ICardGridListRowData = wrapSourceData(childDataItem, childGroup);

                currentChildRowsData.push(childRowData);
            }

            if (onchildDataLoaded) {
                onchildDataLoaded(childDataPropertyName, currentChildRowsData, rowData);
            }

            for (const childRowData of Object.values<ICardGridListRowData>(currentChildRowsData)) {
                childRowData.gridGroup.dataSourceIndex = dataSource.push(childRowData) - 1;
                group.children.push(childRowData.gridGroup);
            }
        }

        group.childrenLoaded = true;

        for (const childGroup of Object.values<IGroupExtended>(group.children as IGroupExtended[])) {
            if (!childGroup.isCollapsed && childGroup.hasChildren) {
                dataSource = loadChildRows(childGroup, dataSource, childDataPropertyNames, onchildDataLoaded);
            }
        }
    }

    return dataSource;
}

export function createChildGridGroup(
    sourceData: any,
    nestingLevel: number,
    parent: ICardGridListRowData,
    childDataPropertyNames?: string | string[]
): IGroupExtended {
    const hasChildData: boolean = doesSourceDataHaveChildren(sourceData, childDataPropertyNames);
    const cardListGroup: ICardGridListGroup = parent.gridGroup.cardGridListGroup;

    const uniqueGroupKey = createUniqueGroupClassName();
    let childGroup: IGroupExtended = {
        cardGridListGroup: cardListGroup,
        childrenLoaded: hasChildData ? false : undefined,
        count: 0,
        dataSourceIndex: -1,
        hasChildren: hasChildData,
        hasMoreData: false,
        isCollapsed: true,
        isDropEnabled: false,
        isShowingAll: true,
        groupClassName: uniqueGroupKey,
        key: `${cardListGroup.key}-${childDataPropertyNames}-${sourceData?.dataSource?.corporation}${
            sourceData?.dataSource?.adjustmentCode ? `-${sourceData?.dataSource?.adjustmentCode}` : ''
        }`,
        level: nestingLevel,
        name: '',
        noExpandCollapse: parent.gridGroup.cardGridListGroup.noExpandCollapseDefault,
        parent: parent.gridGroup,
        startIndex: -1,
    };

    return childGroup;
}

export function wrapSourceData(sourceData: any, gridGroup: IGroupExtended): ICardGridListRowData {
    if (!sourceData) {
        sourceData = {};
    }

    const wrapper: ICardGridListRowData = wrapObject(sourceData, true);

    wrapper.gridGroup = gridGroup;
    return wrapper;
}
