import { useCorptaxTheme } from '@corptax/react-components-common';
import { IMessageBarStyles, MessageBar, MessageBarType } from '@fluentui/react';
import { IFeedbackMessage } from '../../contexts/feedbackMessagesContext';
import { IAppTheme } from '../../theme/IAppTheme';

export interface ICustomMessageBarProps {
    message: string | React.ReactNode;
    type: MessageBarType;
    setActiveMessage: (message: IFeedbackMessage | undefined) => void;
}

export function CustomMessageBar(props: ICustomMessageBarProps) {
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const messageBarStyles: IMessageBarStyles = {
        root: {
            backgroundColor: props.type === MessageBarType.error ? customPalette.errorBackground : undefined,
        },
    };

    return (
        <MessageBar
            messageBarType={props.type}
            isMultiline={false}
            onDismiss={() => props.setActiveMessage(undefined)}
            dismissButtonAriaLabel='Close'
            styles={messageBarStyles}
        >
            {props.message}
        </MessageBar>
    );
}
