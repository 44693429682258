import { IContextualMenuItem } from '@fluentui/react';
import LineContainerLink from '../line-container/LineContainerLink';

export interface IContextualMenuClearSelectionItemProps {
    item: any;
    dismissMenu: (ev?: any, dismissAll?: boolean) => void;
    onViewAllClick: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>, item?: IContextualMenuItem) => void;
}

const ContextualMenuClearSelectionItem: React.FC<IContextualMenuClearSelectionItemProps> = ({ item, dismissMenu, onViewAllClick }) => {
    const handleViewAll = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>, item?: IContextualMenuItem) => {
        onViewAllClick(event, item);
        dismissMenu();
    };

    const contextualMenuItem: IContextualMenuItem = item as IContextualMenuItem;
    return <LineContainerLink handleLinkAction={handleViewAll} linkTitle={contextualMenuItem?.text ?? ''} />;
};

export default ContextualMenuClearSelectionItem;
