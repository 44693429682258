import { Combobox, Label, Option, OptionGroup, useId } from '@fluentui/react-components';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnterAmountsDialogComboboxData } from '../../hooks/useEnterAmountsDialogComboboxData';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { ICase } from '../../model';
import {
    EnterAmountsDialogFiltersActionType,
    IEnterAmountsDialogComboboxPropsBase,
    IEnterAmountsDialogFilterCaseChangeAction,
} from '../../reducers/enterAmountsDialogFiltersReducer';
import { comboBoxCheckIconStyles } from './EnterAmountsDialogFilters';

const findCaseDtoByCode = (cases: ICase[] | null | undefined, caseCode: string | undefined): ICase | undefined => {
    return cases?.find((caseDto: ICase) => caseDto.code === caseCode);
};
const findCasesByDisplayName = (displayValue: string, cases: ICase[]): ICase[] => {
    return cases.filter((caseDto: ICase) => `${caseDto.code} - ${caseDto.name}`.toLowerCase().includes(displayValue.toLowerCase()));
};

interface ICaseComboboxOptionProps {
    caseDto: ICase;
    optionToSelectedOption: (caseDto: ICase) => string;
    optionToInputValue: (caseDto: ICase) => string;
}
const CaseComboboxOption: FC<ICaseComboboxOptionProps> = (props) => {
    const { caseDto, optionToSelectedOption, optionToInputValue } = props;
    const hiddenCheckBoxCssClass = comboBoxCheckIconStyles().hidden;
    return (
        <Option
            key={optionToSelectedOption(caseDto)}
            className={hiddenCheckBoxCssClass}
            value={optionToSelectedOption(caseDto)}
            text={optionToInputValue(caseDto)}
            aria-label={optionToInputValue(caseDto)}
        >
            {optionToInputValue(caseDto)}
        </Option>
    );
};

export interface IEnterAmountsDialogCaseComboboxProps
    extends IEnterAmountsDialogComboboxPropsBase<ICase, IEnterAmountsDialogFilterCaseChangeAction> {}

export const CaseCombobox: FC<IEnterAmountsDialogCaseComboboxProps> = (props: IEnterAmountsDialogCaseComboboxProps) => {
    const { t } = useTranslation();
    const optionToInputValue: (option: ICase) => string = (option: ICase) => `${option.code} - ${option.name}`;
    const optionToSelectedOption: (option: ICase) => string = (option: ICase) => option.code!;
    const findOptionsByDisplayValue: (displayValue: string, options: ICase[]) => ICase[] = (displayValue: string, options: ICase[]) =>
        findCasesByDisplayName(displayValue, options);
    const findOptionByKey: (key: string | undefined, options: ICase[]) => ICase | undefined = (key: string | undefined, options: ICase[]) =>
        findCaseDtoByCode(options, key);
    const caseLabelId: string = useId('case');
    const { comboboxStyles } = useEnterAmountsDialogStyles();
    const optionRenderer: (caseDto: ICase) => JSX.Element = useCallback(
        (caseDto: ICase) => (
            <CaseComboboxOption
                key={optionToSelectedOption(caseDto)}
                caseDto={caseDto}
                optionToSelectedOption={optionToSelectedOption}
                optionToInputValue={optionToInputValue}
            />
        ),
        [optionToInputValue, optionToSelectedOption]
    );
    const { inputValue, selectedOptions, matchingOptions, searchText, onOptionSelect, onBlur, onChange, disabled, onKeyDown, comboboxRef } =
        useEnterAmountsDialogComboboxData<ICase, IEnterAmountsDialogFilterCaseChangeAction, IEnterAmountsDialogCaseComboboxProps>(
            props,
            {
                optionToInputValue,
                optionToSelectedOption,
                findOptionsByDisplayValue: findOptionsByDisplayValue,
                findOptionByKey,
                optionRenderer: optionRenderer,
            },
            EnterAmountsDialogFiltersActionType.CaseChanged
        );
    return (
        <div>
            <Label id={caseLabelId} weight='semibold'>
                {t('case')}
            </Label>
            <Combobox
                className={comboboxStyles}
                aria-labelledby={caseLabelId}
                value={inputValue}
                selectedOptions={selectedOptions}
                onOptionSelect={onOptionSelect}
                disabled={disabled}
                freeform
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                ref={comboboxRef}
                clearable={props.clearable}
            >
                {searchText ? (
                    <Option aria-disabled='true' key='freeform' text={searchText} disabled={true}>
                        {t('noOptionsFound', { searchText: searchText })}
                    </Option>
                ) : (
                    <OptionGroup>{matchingOptions}</OptionGroup>
                )}
            </Combobox>
        </div>
    );
};
