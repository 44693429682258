/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IsFeatureEnabledParams, ProblemDetails } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useIsFeatureEnabledHook = () => {
    const isFeatureEnabled = useCustomInstance<boolean>();

    return (params?: IsFeatureEnabledParams, signal?: AbortSignal) => {
        return isFeatureEnabled({ url: `/api/Features/IsFeatureEnabled`, method: 'get', params, signal });
    };
};

export const getIsFeatureEnabledQueryKey = (params?: IsFeatureEnabledParams) =>
    [`/api/Features/IsFeatureEnabled`, ...(params ? [params] : [])] as const;

export const useIsFeatureEnabledQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useIsFeatureEnabledHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: IsFeatureEnabledParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsFeatureEnabledHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsFeatureEnabledHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getIsFeatureEnabledQueryKey(params);

    const isFeatureEnabled = useIsFeatureEnabledHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIsFeatureEnabledHook>>>> = ({ signal }) =>
        isFeatureEnabled(params, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type IsFeatureEnabledQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsFeatureEnabledHook>>>>;
export type IsFeatureEnabledQueryError = ErrorType<ProblemDetails>;

export const useIsFeatureEnabled = <
    TData = Awaited<ReturnType<ReturnType<typeof useIsFeatureEnabledHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: IsFeatureEnabledParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsFeatureEnabledHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIsFeatureEnabledQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
