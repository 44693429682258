import { Label, MessageBarType, StackItem, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateFolderWithTaxReturns } from '../../../api/report/report';
import { useFeedbackMessages } from '../../../hooks/useFeedbackMessages';
import { validNameRuleRegExp } from '../../../utils/RegExpUtils';
import Modal, { IModalMessageBarProps } from '../../common/Modal';

export interface AddToNewFolderModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedReturns: number[];
    onSubmit: () => void;
    setMessageBarProps: (messageBarProps: IModalMessageBarProps | undefined) => void;
}

const AddToNewFolderModal: React.FC<AddToNewFolderModalProps> = ({ isOpen, onClose, selectedReturns, onSubmit, setMessageBarProps }) => {
    const [folderName, setFolderName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const { t } = useTranslation();
    const { mutateAsync: saveNewFolderAndReturns, isLoading } = useCreateFolderWithTaxReturns();
    const { displayFeedbackMessage } = useFeedbackMessages();

    const updateInputTextValue = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
        if (validNameRuleRegExp.test(value || '')) {
            setFolderName(value ?? '');
            setErrorMessage(undefined);
        }
    };

    const closeModal = () => {
        setFolderName('');
        setErrorMessage(undefined);
        onClose();
    };

    const sendErrorMessage = (message: string) => {
        setMessageBarProps({
            messageBarType: MessageBarType.error,
            message: message,
            onDismiss: () => setMessageBarProps(undefined),
        });
    };

    const getSuccessMessage = (folderName: string) => {
        return (
            <span>
                {t('calculationsAddedToFolder').toString()}
                {folderName}
            </span>
        );
    };

    const setInputErrorMessage = (folderName: string) => {
        setErrorMessage(`${t('folderExists').toString().replace('{{folderName}}', folderName)}`);
    };

    const setModalErrorMessage = () => {
        sendErrorMessage(t('calculationAddToFolderServerError').toString());
        closeModal();
    };

    const setGlobalErrorMessage = () => {
        displayFeedbackMessage({
            type: MessageBarType.error,
            message: `${t('calculationAddToFolderServerError').toString()}`,
        });

        closeModal();
        onSubmit();
    };

    const setSuccessfullMessage = (folderName: string) => {
        displayFeedbackMessage({
            type: MessageBarType.success,
            message: getSuccessMessage(folderName),
        });

        closeModal();
        onSubmit();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            createNewFolderAndAddItems();
        }
    };
    const createNewFolderAndAddItems = () => {
        saveNewFolderAndReturns({ data: { folderName, taxReturnItemKeys: selectedReturns } })
            .then((response: any) => {
                if (response?.errors?.length) {
                    if (response.errors.find((error: any) => error?.message?.includes('duplicate'))) {
                        setInputErrorMessage(folderName);
                    } else {
                        setModalErrorMessage();
                    }
                } else {
                    setSuccessfullMessage(response?.folderName);
                }
            })
            .catch(() => {
                setGlobalErrorMessage();
            });
    };

    const AddToFolderModalButtons = [
        { text: t('createAndAdd'), onClick: createNewFolderAndAddItems, primary: true, isProcessing: isLoading },
        { text: t('cancel'), onClick: closeModal, disabled: isLoading },
    ];

    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            headerContent={t('addNewFolder')}
            bodyContent={
                <StackItem tokens={{ padding: 5 }}>
                    <Label>{t('folderName').toString()}</Label>
                    <TextField
                        autoFocus
                        ariaLabel={t('enterFolderName').toString()}
                        placeholder={t('enterFolderName').toString()}
                        value={folderName}
                        style={{ minWidth: '400px' }}
                        onChange={updateInputTextValue}
                        disabled={isLoading}
                        errorMessage={errorMessage}
                        onKeyDown={(e) => handleKeyDown(e.nativeEvent)}
                    />
                </StackItem>
            }
            buttons={AddToFolderModalButtons}
            elementToFocusOnDismiss={document.getElementById('add-to-new-folder-button') || undefined}
        />
    );
};

export default AddToNewFolderModal;
