import { useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, FocusTrapCallout, mergeStyles } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { BaseSyntheticEvent, KeyboardEvent, PropsWithChildren } from 'react';
import { IAppTheme } from '../../theme/IAppTheme';
import { IconName } from '../../utils/Icons.constants';
import CustomIconButton, { IconPosition } from './IconButton';

export interface IIconButtonWithCalloutProps {
    buttonId?: string;
    label: string;
    ariaLabel?: string;
    iconPosition?: IconPosition;
    iconClassName?: string;
    iconName: IconName;
    onButtonClick?: () => void;
    isActive?: boolean;
    isDialogVisible?: boolean;
    toggleDialogVisible?: () => void;
    dialogClassName?: string;
    disabled?: boolean;
    onCalloutSubmit?: () => void;
}

const IconButtonWithCallout: React.FC<PropsWithChildren<IIconButtonWithCalloutProps>> = ({
    buttonId,
    label,
    ariaLabel,
    iconPosition,
    iconClassName,
    iconName,
    onButtonClick,
    isActive,
    children,
    dialogClassName,
    toggleDialogVisible: toggleIsDialogVisibleOverrride,
    isDialogVisible: isDialogVisibleOverride,
    disabled,
    onCalloutSubmit,
}) => {
    const [isDialogVisible, { toggle: toggleIsDialogVisible }] = useBoolean(false);
    const { customPalette, brandPalette } = useCorptaxTheme<IAppTheme>();

    const isVisible = isDialogVisibleOverride ?? isDialogVisible;

    const defaultDialogStyle = mergeStyles({
        background: brandPalette.white,
        border: '1px solid',
        borderColor: customPalette.neutralSecondaryAlt,
        boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
        borderRadius: '6px',
        flex: 'none',
        order: 12,
        flexGrow: 0,
        zIndex: 12,
        overflowX: 'hidden',
        maxWidth: '530px',
        maxHeight: '50vh',
        selectors: {
            '& > div': {
                overflow: 'hidden',
            },
        },
    });

    const toggleDialogVisibility = () => {
        if (toggleIsDialogVisibleOverrride) {
            toggleIsDialogVisibleOverrride();
        } else {
            toggleIsDialogVisible();
        }
    };

    const onButtonClickWrapper = () => {
        toggleDialogVisibility();
        if (onButtonClick) onButtonClick();
    };

    const callSubmitHandlerIfEnter = (event: BaseSyntheticEvent) => {
        const keyboardEvent = event as KeyboardEvent;

        if (keyboardEvent.key === 'Enter' && onCalloutSubmit) {
            event.preventDefault();
            onCalloutSubmit();
        }
    };

    return (
        <>
            <CustomIconButton
                id={buttonId}
                label={label}
                iconName={iconName}
                onButtonClick={onButtonClickWrapper}
                isActive={isActive || isVisible}
                ariaLabel={ariaLabel}
                disabled={disabled}
            />
            {isVisible && (
                <FocusTrapCallout
                    className={dialogClassName ?? defaultDialogStyle}
                    role='menu'
                    data-testid='icon-button-callout'
                    gapSpace={10}
                    target={`#${buttonId}`}
                    focusTrapProps={{
                        isClickableOutsideFocusTrap: true,
                        role: 'menu',
                    }}
                    isBeakVisible={false}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    onDismiss={toggleDialogVisibility}
                    onKeyDown={callSubmitHandlerIfEnter}
                >
                    {children}
                </FocusTrapCallout>
            )}
        </>
    );
};

export default IconButtonWithCallout;
