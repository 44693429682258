import { useContext } from 'react';
import { GridScrollContext, IGridScrollContextValues } from '../contexts/gridScrollContext';

export const useGridScroll = (): IGridScrollContextValues => {
    const context = useContext(GridScrollContext);

    if (!context) {
        throw new Error('useGridState must be used within a GridScrollContextProvider');
    }

    return context;
};
