import { useCorptaxTheme } from '@corptax/react-components-common';
import { ISearchBoxStyles, mergeStyles, SearchBox } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { KeyboardEvent, ReactEventHandler } from 'react';
import { IAppTheme } from '../../../theme/IAppTheme';

export interface IFilterSearchBarProps {
    searchBoxPlaceholderText: string;
    onSearchBoxChange: (ev: React.ChangeEvent<HTMLInputElement> | undefined, newValue: string | undefined) => void;
    onAbort: ReactEventHandler<HTMLInputElement>;
}

const FilterSearchBar: React.FC<IFilterSearchBarProps> = ({ searchBoxPlaceholderText, onSearchBoxChange, onAbort }) => {
    const [isSearchboxFocused, { setTrue: setIsSearchboxFocusedTrue, setFalse: setIsSearchboxFocusedFalse }] = useBoolean(false);
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();

    const searcherStyle = mergeStyles({
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '4px 0px 4px 0px ',
        width: '510px',
        borderRadius: '8px',
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 0,
        outline: 'none',
        color: palette.black,
        borderColor: customPalette.statusCoolLightGrey,
        ':hover': {
            borderColor: customPalette.neutralLightGrey,
        },
        ':focus': {
            borderColor: customPalette.blueLight,
        },
    });

    const defaultSearchBoxStyles: ISearchBoxStyles = {
        root: {
            color: customPalette.whiteTranslucent40,
            '&.ms-SearchBox.is-active:after': {
                // eslint-disable-next-line no-template-curly-in-string
                border: '1px solid ${(props) => props.customPalette.themePrimary} !important',
                borderRadius: '8px !important',
            },
        },
        icon: { color: palette.black },
        field: {
            '::placeholder': {
                color: customPalette.whiteTranslucent40,
            },
            '::-webkit-input-placeholder': {
                color: customPalette.whiteTranslucent40,
            },
            '::-moz-placeholder': {
                color: customPalette.whiteTranslucent40,
            },
        },
    };

    const searchIconProps = {
        iconName: 'Search',
        style: { color: customPalette.themeDarkAlt, fontWeight: 'bold' },
    };

    const focusOnFirstItemOnTab = (ev: KeyboardEvent<HTMLElement>) => {
        if (ev.key === 'Tab') {
            ev.preventDefault();
            ev.stopPropagation();
            document.getElementById('item-0')?.focus();
        }
    };

    return (
        <SearchBox
            onFocus={setIsSearchboxFocusedTrue}
            onBlur={setIsSearchboxFocusedFalse}
            onKeyDown={focusOnFirstItemOnTab}
            onChange={onSearchBoxChange}
            onAbort={onAbort}
            placeholder={isSearchboxFocused ? '' : searchBoxPlaceholderText}
            className={searcherStyle}
            styles={defaultSearchBoxStyles}
            iconProps={searchIconProps}
            showIcon={true}
            ariaLabel={searchBoxPlaceholderText}
        />
    );
};

export default FilterSearchBar;
