import { ComboboxProps, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { IAdjustment, ICalendarPeriod, ICase, IEntity, IJurisdiction } from '../model';
import {
    EnterAmountsComboboxAction,
    EnterAmountsDialogComboboxReducer,
    EnterAmountsDialogComboboxReducerAction,
    getEnterAmountsDialogComboboxReducer,
    IEnterAmountsDialogComboboxState,
} from '../reducers/enterAmountsDialogComboboxReducer';
import {
    EnterAmountsDialogFiltersActionType,
    EnterAmountsDialogFiltersFilterComboboxChangeActions,
    IEnterAmountsDialogComboboxPropsBase,
    IEnterAmountsDialogFilterFilterChangeAction,
} from '../reducers/enterAmountsDialogFiltersReducer';

export interface IEnterAmountsDialogComboboxHook<T> {
    inputValue: string;
    selectedOptions: string[];
    matchingOptions: JSX.Element[];
    searchText?: string;
    dispatch: React.Dispatch<EnterAmountsDialogComboboxReducerAction<T>>;
    onInput: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    onOptionSelect: (event: SelectionEvents, data: OptionOnSelectData) => void;
    onBlur: (ev: React.FocusEvent<HTMLInputElement>) => void;
    onChange: ComboboxProps['onChange'];
    disabled: boolean;
    onKeyDown: ComboboxProps['onKeyDown'];
    comboboxRef: React.RefObject<HTMLInputElement>;
}

export interface IEnterAmountsDialogComboboxHookConfig<T extends ICalendarPeriod | IEntity | ICase | IJurisdiction | IAdjustment> {
    optionToInputValue: (option: T) => string;
    optionToSelectedOption: (option: T) => string;
    findOptionsByDisplayValue: (displayValue: string, options: T[]) => T[];
    findOptionByKey: (key: string | undefined, options: T[]) => T | undefined;
    optionRenderer: (option: T) => JSX.Element;
}

export const useEnterAmountsDialogComboboxData: <
    TOption extends ICalendarPeriod | IEntity | ICase | IJurisdiction | IAdjustment,
    TAction extends IEnterAmountsDialogFilterFilterChangeAction<TOption | undefined> & EnterAmountsDialogFiltersFilterComboboxChangeActions,
    TProps extends IEnterAmountsDialogComboboxPropsBase<TOption, TAction>
>(
    props: TProps,
    config: IEnterAmountsDialogComboboxHookConfig<TOption>,
    actionType: EnterAmountsDialogFiltersActionType
) => IEnterAmountsDialogComboboxHook<TOption> = <
    TOption extends ICalendarPeriod | IEntity | ICase | IJurisdiction | IAdjustment,
    TAction extends IEnterAmountsDialogFilterFilterChangeAction<TOption | undefined> & EnterAmountsDialogFiltersFilterComboboxChangeActions
>(
    props: IEnterAmountsDialogComboboxPropsBase<TOption, TAction>,
    config: IEnterAmountsDialogComboboxHookConfig<TOption>,
    actionType: EnterAmountsDialogFiltersActionType
): IEnterAmountsDialogComboboxHook<TOption> => {
    const initialComboboxState: IEnterAmountsDialogComboboxState<TOption> = {
        inputValue: props.selectedOption ? config.optionToInputValue(props.selectedOption) : '',
        selectedOptions: props.selectedOption ? [config.optionToSelectedOption(props.selectedOption)] : [],
        matchingOptions: props.options || [],
        searchText: undefined,
    };

    const [state, dispatch] = useReducer<EnterAmountsDialogComboboxReducer<TOption>>(
        getEnterAmountsDialogComboboxReducer<TOption>({ ...config, clearable: props.clearable }),
        initialComboboxState
    );
    useEffect(() => {
        if (state !== initialComboboxState) {
            if (state.selectedOptions.length > 0) {
                const matchingOption: TOption | undefined = config.findOptionByKey(state.selectedOptions[0], props.options || []);
                props.dispatch &&
                    props.dispatch({
                        type: actionType,
                        newFilterValue: matchingOption,
                    } as TAction);
            } else {
                props.dispatch && props.dispatch({ type: actionType } as TAction);
            }
        }
        // I tried to spread state.selectedOptions, but in order for the combobox to not have a value, selectedOptions has to be empty.
        // If the number of items in the dependencies array changes (which can happen if the combobox is multi-select or if it's single-select and a value is not required),
        // you see a browser warning - The final argument passed to useEffect changed size between renders. The order and size of this array must remain constant.
        // I could not add null or undefined as the first item because then you'd see the  placeholder, but clearable comboboxes would
        // show the clear button, which is not correct.
        // One of Dam Abramov's suggestions for this situation is to use JSON.stringify - https://github.com/facebook/react/issues/14476#issuecomment-471199055
        // it seems appropriate for now as long as this is only used for single-select comboboxes
        // I found this link from this StackOverflow answer - https://stackoverflow.com/a/59468261
    }, [JSON.stringify(state.selectedOptions)]);
    const onOptionSelect = useCallback(
        (event: SelectionEvents, data: OptionOnSelectData) => {
            dispatch({ type: EnterAmountsComboboxAction.OptionSelected, allOptions: props.options || [], event, data });
        },
        [props.options, dispatch]
    );

    const onBlur: (ev: React.FocusEvent<HTMLInputElement>) => void = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            dispatch({
                type: EnterAmountsComboboxAction.InputBlur,
                allOptions: props.options || [],
                preSelectedOption: props.selectedOption,
                inputValue: event.target.value,
            });
        },
        [props.options, props.selectedOption, dispatch]
    );

    // fires when users type in the combobox
    // sets state for matching items based on what they've typed
    // if no matches are found, set the search text to what they've typed so it can be displayed back to them
    const onChange: ComboboxProps['onChange'] = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: EnterAmountsComboboxAction.OnChange, newInputValue: event.target.value, allOptions: props.options || [] });
        },
        [props.options, dispatch]
    );
    const comboboxRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const onKeyDown: ComboboxProps['onKeyDown'] = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                comboboxRef.current?.blur();
            }
        },
        [comboboxRef.current]
    );
    const childElements: JSX.Element[] = useMemo(
        () => state.matchingOptions.map((option: TOption) => config.optionRenderer(option)),
        [JSON.stringify(state.matchingOptions)]
    );
    const disabled = !props.options?.length || props.options.length <= 1;
    return {
        inputValue: state.inputValue,
        selectedOptions: state.selectedOptions,
        matchingOptions: childElements,
        searchText: state.searchText,
        dispatch,
        onOptionSelect,
        onBlur,
        onChange,
        disabled,
        onKeyDown,
        comboboxRef,
    } as IEnterAmountsDialogComboboxHook<TOption>;
};
