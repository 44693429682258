/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */

export type ReportStatus = (typeof ReportStatus)[keyof typeof ReportStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportStatus = {
    NotStarted: 'NotStarted',
    InPreparation: 'InPreparation',
    OnHold: 'OnHold',
    InReview: 'InReview',
    Complete: 'Complete',
} as const;
