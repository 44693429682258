/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { NavigationItem } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetNavigationItemsHook = () => {
    const getNavigationItems = useCustomInstance<NavigationItem[]>();

    return (signal?: AbortSignal) => {
        return getNavigationItems({ url: `/Navigation`, method: 'get', signal });
    };
};

export const getGetNavigationItemsQueryKey = () => [`/Navigation`] as const;

export const useGetNavigationItemsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetNavigationItemsQueryKey();

    const getNavigationItems = useGetNavigationItemsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>> = ({ signal }) =>
        getNavigationItems(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetNavigationItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>>;
export type GetNavigationItemsQueryError = ErrorType<unknown>;

export const useGetNavigationItems = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetNavigationItemsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
