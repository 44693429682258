import { SelectedWhitePaperCell } from '../../data-types/selectedWhitePaperCell';
import {
    ExtractionDetailsAccountSummary,
    ExtractionDetailsAdjustmentEntry,
    ExtractionDetailsAdjustmentInfo,
    ExtractionDetailsBookEntry,
    ExtractionDetailsBookInfo,
    ExtractionDetailsRequest,
    IWhitePaperColumn,
    IWhitePaperMappedRow,
    IWhitePaperReportCellChange,
    IWhitePaperReportCellChangesSummary,
    IWhitePaperReportChanges,
    Report,
} from '../../model';
import { IWhitePaperMappedRowDictionary } from '../../model/iWhitePaperMappedRowDictionary';
import { IWhitePaperRow } from '../../model/iWhitePaperRow';
import { IWhitePaperTracedCell } from '../../model/iWhitePaperTracedCell';
import { ArrayUtils } from '../../utils/ArrayUtils';
import { getAmountAsNumberOrDefault } from '../../utils/TableUtils';

export declare type AccountAmountDataObjectType = 'AccountSummary' | 'BookSummary' | 'BookEntry' | 'AdjustmentSummary' | 'AdjustmentEntry';

export declare type ExtractionDetailsAmount =
    | ExtractionDetailsBookInfo
    | ExtractionDetailsBookEntry
    | ExtractionDetailsAdjustmentInfo
    | ExtractionDetailsAdjustmentEntry;

export declare type ExtractionDetailsAmounts<T> = { entries?: T[] | null };

export enum TraceType {
    DefiningRows,
    ImpactedRows,
}

export interface IAccountAmountWhitePaperRow {
    get account(): string | null;
    get adjustmentCode(): string | null;
    get adjustmentEntries(): IAccountAmountWhitePaperRow[] | null;
    get adjustmentSummaries(): IAccountAmountWhitePaperRow[] | null;
    get adjustmentType(): string | null;
    get amount(): string | null;
    get bookEntries(): IAccountAmountWhitePaperRow[] | null;
    get bookSummary(): IAccountAmountWhitePaperRow | null;
    get case(): string | null;
    get dataObject(): any;
    get dataObjectType(): AccountAmountDataObjectType | null;
    get description(): string | null;
    get entity(): string | null;
    get jurisdiction(): string | null;
    get lastModifiedBy(): string | null;
    get parent(): IAccountAmountWhitePaperRow | null;
    get recalcNeeded(): boolean;
    get rowNumber(): string;
    get summaryEphemeralKey(): number;
    get year(): string | null;
    get isComplexAdjustment(): boolean;
}

export interface ITrackedExtractionDetailsAccountSummary extends ExtractionDetailsAccountSummary {
    get ephemeralKey(): number;
}

export interface ITrackedUpdateTime {
    updateTimestamp?: number;
}

export interface IWhitePaperRowTrace {
    amount?: string | null;
    dataType?: string | null;
    definition?: string | null;
    description?: string | null;
    rowNumber?: number;
    traces?: IWhitePaperRowTrace[];
    rowStartIndex?: number;
}

export interface IWhitePaperRowTraceDictionary {
    [key: string]: IWhitePaperRowTrace;
}

export interface IWhitePaperRowTraceState {
    currentBranchRowNumbers: number[];
    tracedRows: IWhitePaperRowTraceDictionary;
}

export class AccountAmountWhitePaperRow implements IAccountAmountWhitePaperRow {
    private readonly _children: any;
    private readonly _dataObject: any;
    private readonly _dataObjectType: AccountAmountDataObjectType | null;
    private readonly _parent: AccountAmountWhitePaperRow | null;
    private _recalcNeeded: boolean;

    constructor(
        dataObject: any,
        dataObjectType?: AccountAmountDataObjectType,
        children?: any,
        parent?: AccountAmountWhitePaperRow,
        recalcNeeded: boolean = false
    ) {
        this._children = children;
        this._dataObject = dataObject;
        this._dataObjectType = dataObjectType ?? null;
        this._parent = parent ?? null;
        this._recalcNeeded = recalcNeeded;
    }

    get account(): string | null {
        switch (this._dataObjectType) {
            case 'AccountSummary':
                return (this._dataObject as ITrackedExtractionDetailsAccountSummary).accountNumber ?? null;
            case 'BookSummary':
                return this._parent?.account ?? null;
            case 'BookEntry':
                return this._parent?._parent?.account ?? null;
            case 'AdjustmentSummary':
                return this._parent?.account ?? null;
            case 'AdjustmentEntry':
                return this._parent?._parent?.account ?? null;
            default:
                return null;
        }
    }

    get adjustmentCode(): string | null {
        switch (this._dataObjectType) {
            case 'AdjustmentSummary':
                return (this.dataObject as ExtractionDetailsAdjustmentInfo).adjustmentCode ?? null;

            default:
                return null;
        }
    }

    get adjustmentEntries(): IAccountAmountWhitePaperRow[] | null {
        switch (this._dataObjectType) {
            case 'AdjustmentSummary':
                return this._children['adjustmentEntries'];

            default:
                return null;
        }
    }

    get adjustmentSummaries(): IAccountAmountWhitePaperRow[] | null {
        switch (this._dataObjectType) {
            case 'AccountSummary':
                return this._children['adjustmentSummaries'];

            default:
                return null;
        }
    }

    get adjustmentType(): string | null {
        if (this._dataObjectType === 'AdjustmentSummary') {
            return (this.dataObject as ExtractionDetailsAdjustmentInfo).adjustmentTypeCode ?? '';
        }

        return null;
    }

    get amount(): string | null {
        switch (this._dataObjectType) {
            case 'AccountSummary':
                return (this._dataObject as ITrackedExtractionDetailsAccountSummary).reportAmount ?? null;

            case 'AdjustmentEntry':
                return (this._dataObject as ExtractionDetailsAdjustmentEntry).reportAmount ?? null;

            case 'AdjustmentSummary':
                return (this._dataObject as ExtractionDetailsAdjustmentInfo).reportAmount ?? null;

            case 'BookEntry':
                return (this._dataObject as ExtractionDetailsBookEntry).reportAmount ?? null;

            case 'BookSummary':
                return (this._dataObject as ExtractionDetailsBookInfo).reportAmount ?? null;

            default:
                return null;
        }
    }

    get bookEntries(): IAccountAmountWhitePaperRow[] | null {
        switch (this._dataObjectType) {
            case 'BookSummary':
                return this._children['bookEntries'];

            default:
                return null;
        }
    }

    get bookSummary(): IAccountAmountWhitePaperRow | null {
        switch (this._dataObjectType) {
            case 'AccountSummary':
                return this._children['bookSummary'];

            default:
                return null;
        }
    }

    get case(): string | null {
        switch (this._dataObjectType) {
            case 'AdjustmentEntry':
                return (this._dataObject as ExtractionDetailsAdjustmentEntry).case ?? null;

            case 'BookEntry':
                return (this._dataObject as ExtractionDetailsBookEntry).case ?? null;

            default:
                return null;
        }
    }

    get dataObject(): any {
        return this._dataObject;
    }

    get dataObjectType(): AccountAmountDataObjectType | null {
        return this._dataObjectType;
    }

    get description(): string | null {
        switch (this._dataObjectType) {
            case 'AccountSummary':
                return (this._dataObject as ITrackedExtractionDetailsAccountSummary).description ?? null;

            case 'AdjustmentSummary':
                return (this._dataObject as ExtractionDetailsAdjustmentInfo).description ?? null;

            case 'BookSummary':
                return this._parent?.description ?? null;

            default:
                return null;
        }
    }

    get entity(): string | null {
        switch (this._dataObjectType) {
            case 'AdjustmentEntry':
                return (this._dataObject as ExtractionDetailsAdjustmentEntry).corporation ?? null;

            case 'BookEntry':
                return (this._dataObject as ExtractionDetailsBookEntry).corporation ?? null;

            default:
                return null;
        }
    }

    get jurisdiction(): string | null {
        switch (this._dataObjectType) {
            case 'AdjustmentEntry':
                return (
                    (this._dataObject as ExtractionDetailsAdjustmentEntry).jurisdiction ??
                    (this._dataObject as ExtractionDetailsAdjustmentEntry).location ??
                    null
                );

            case 'BookEntry':
                return (
                    (this._dataObject as ExtractionDetailsBookEntry).jurisdiction ??
                    (this._dataObject as ExtractionDetailsBookEntry).location ??
                    null
                );

            default:
                return null;
        }
    }

    get lastModifiedBy(): string | null {
        switch (this._dataObjectType) {
            case 'AdjustmentEntry':
                return (this._dataObject as ExtractionDetailsAdjustmentEntry).lastModifiedBy ?? null;

            case 'AdjustmentSummary':
                if (this.adjustmentEntries && this.adjustmentEntries.length === 0) {
                    return this._dataObject.lastModifiedBy ?? null;
                }
                return null;

            default:
                return null;
        }
    }
    get isComplexAdjustment(): boolean {
        switch (this._dataObjectType) {
            case 'AdjustmentEntry':
                return (this._dataObject as ExtractionDetailsAdjustmentEntry).isComplexAdjustment ?? false;

            case 'AdjustmentSummary':
                if (this.adjustmentEntries && this.adjustmentEntries.length === 0) {
                    return this._dataObject.isComplexAdjustment ?? false;
                }
                return false;

            default:
                return false;
        }
    }

    get recalcNeeded(): boolean {
        return this._recalcNeeded;
    }

    get rowNumber(): string {
        if (this._dataObjectType === 'AccountSummary') {
            return (this._dataObject as ITrackedExtractionDetailsAccountSummary).rowNumber ?? '';
        }

        return this._parent?.rowNumber ?? '';
    }

    get summaryEphemeralKey(): number {
        if (this._dataObjectType === 'AccountSummary') {
            return (this._dataObject as ITrackedExtractionDetailsAccountSummary).ephemeralKey ?? -1;
        }

        return this._parent?.summaryEphemeralKey ?? -1;
    }

    get parent(): IAccountAmountWhitePaperRow | null {
        return this._parent;
    }

    get year(): string | null {
        switch (this._dataObjectType) {
            case 'AdjustmentEntry':
                return (this._dataObject as ExtractionDetailsAdjustmentEntry).year ?? null;

            case 'BookEntry':
                return (this._dataObject as ExtractionDetailsBookEntry).year ?? null;

            default:
                return null;
        }
    }
}

function addAdjustmentAmountToConsolidatedAmountChanges(
    adjustmentValueObject: (ExtractionDetailsAdjustmentInfo | ExtractionDetailsAdjustmentEntry) & ITrackedUpdateTime,
    adjustmentCode: string,
    accountCode: string,
    consolidatedAccountSummaries: ExtractionDetailsAccountSummary[]
) {
    const consolidatedAccountSummary: ExtractionDetailsAccountSummary = getOrCreateConsolidatedAccountSummary(
        accountCode,
        consolidatedAccountSummaries
    );
    const consolidatedAdjustmentCodeInfo: ExtractionDetailsAdjustmentInfo = getOrCreateConsolidatedAdjustmentCodeInfo(
        adjustmentCode,
        consolidatedAccountSummary
    );

    addAmountEntryToConsolidatedEntryInfo(adjustmentValueObject, consolidatedAdjustmentCodeInfo);
}

function addAmountEntryToConsolidatedEntryInfo<T extends ExtractionDetailsAmount>(
    amountItem: T & ITrackedUpdateTime,
    consolidatedAmounts: ExtractionDetailsAmounts<T>
) {
    if (!consolidatedAmounts.entries) {
        consolidatedAmounts.entries = [];
    }

    const consolidatedAmountEntries: (Partial<T> & ITrackedUpdateTime)[] = consolidatedAmounts.entries as (Partial<T> &
        ITrackedUpdateTime)[];
    let consolidatedEntry: (Partial<T> & ITrackedUpdateTime) | undefined = consolidatedAmountEntries.find(
        (currentConsolidatedEntry) =>
            currentConsolidatedEntry.corporation === amountItem.corporation &&
            currentConsolidatedEntry.case === amountItem.case &&
            currentConsolidatedEntry.jurisdiction === amountItem.jurisdiction &&
            currentConsolidatedEntry.location === amountItem.location &&
            currentConsolidatedEntry.year === amountItem.year
    );

    if (!consolidatedEntry) {
        consolidatedEntry = {
            case: amountItem.case,
            corporation: amountItem.corporation,
            jurisdiction: amountItem.jurisdiction,
            location: amountItem.location,
            updateTimestamp: 0,
            year: amountItem.year,
        } as Partial<T> & ITrackedUpdateTime;

        consolidatedAmountEntries.push(consolidatedEntry);
    }

    if (amountItem.updateTimestamp! >= consolidatedEntry.updateTimestamp!) {
        consolidatedEntry.updateTimestamp = amountItem.updateTimestamp;
        consolidatedEntry.reportAmount = amountItem.reportAmount;
    }
}

function addBookAmountToConsolidatedAmountChanges(
    bookValueObject: (ExtractionDetailsBookInfo | ExtractionDetailsBookEntry) & ITrackedUpdateTime,
    accountCode: string,
    consolidatedAccountSummaries: ExtractionDetailsAccountSummary[]
) {
    const consolidatedAccountSummary: ExtractionDetailsAccountSummary = getOrCreateConsolidatedAccountSummary(
        accountCode,
        consolidatedAccountSummaries
    );

    if (!consolidatedAccountSummary.bookInfo) {
        consolidatedAccountSummary.bookInfo = {};
    }

    addAmountEntryToConsolidatedEntryInfo(bookValueObject, consolidatedAccountSummary.bookInfo);
}

export function consolidateAmountChangesForSave(
    accountAmountSummaries: ITrackedExtractionDetailsAccountSummary[]
): ExtractionDetailsAccountSummary[] {
    const consolidatedAccountSummaries: ExtractionDetailsAccountSummary[] = [];

    for (const accountSummary of accountAmountSummaries) {
        const accountNumber: string = accountSummary.accountNumber!;
        const bookInfo: (ExtractionDetailsBookInfo & ITrackedUpdateTime) | undefined = accountSummary.bookInfo as
            | (ExtractionDetailsBookInfo & ITrackedUpdateTime)
            | undefined;

        if (bookInfo) {
            if (bookInfo.updateTimestamp !== undefined) {
                addBookAmountToConsolidatedAmountChanges(bookInfo, accountNumber, consolidatedAccountSummaries);
            } else if (bookInfo.entries) {
                for (const bookEntry of bookInfo.entries as (ExtractionDetailsBookEntry & ITrackedUpdateTime)[]) {
                    if (bookEntry.updateTimestamp !== undefined) {
                        addBookAmountToConsolidatedAmountChanges(bookEntry, accountNumber, consolidatedAccountSummaries);
                    }
                }
            }
        }

        if (accountSummary.adjustmentInfo) {
            for (const adjustmentCodeInfo of accountSummary.adjustmentInfo as (ExtractionDetailsAdjustmentInfo & ITrackedUpdateTime)[]) {
                const adjustmentCode: string = adjustmentCodeInfo.adjustmentCode!;

                if (adjustmentCodeInfo.updateTimestamp !== undefined) {
                    addAdjustmentAmountToConsolidatedAmountChanges(
                        adjustmentCodeInfo,
                        adjustmentCode,
                        accountNumber,
                        consolidatedAccountSummaries
                    );
                } else if (adjustmentCodeInfo.entries) {
                    for (const adjustmentEntry of adjustmentCodeInfo.entries as (ExtractionDetailsAdjustmentEntry & ITrackedUpdateTime)[]) {
                        if (adjustmentEntry.updateTimestamp !== undefined) {
                            addAdjustmentAmountToConsolidatedAmountChanges(
                                adjustmentEntry,
                                adjustmentCode,
                                accountNumber,
                                consolidatedAccountSummaries
                            );
                        }
                    }
                }
            }
        }
    }

    removeUpdateTimestampsFromConsolidatedAmountChanges(consolidatedAccountSummaries);
    return consolidatedAccountSummaries;
}

function getOrCreateConsolidatedAccountSummary(
    accountCode: string,
    consolidatedAccountSummaries: ExtractionDetailsAccountSummary[]
): ExtractionDetailsAccountSummary {
    let accountSummary: ExtractionDetailsAccountSummary | undefined = consolidatedAccountSummaries.find(
        (currentAccountSummary) => currentAccountSummary.accountNumber === accountCode
    );

    if (!accountSummary) {
        accountSummary = {
            accountNumber: accountCode,
        };

        consolidatedAccountSummaries.push(accountSummary);
    }

    return accountSummary;
}

function getOrCreateConsolidatedAdjustmentCodeInfo(
    adjustmentCode: string,
    consolidatedAccountSummary: ExtractionDetailsAccountSummary
): ExtractionDetailsAdjustmentInfo {
    if (!consolidatedAccountSummary.adjustmentInfo) {
        consolidatedAccountSummary.adjustmentInfo = [];
    }

    let adjustmentCodeInfo: ExtractionDetailsAdjustmentInfo | undefined = consolidatedAccountSummary.adjustmentInfo.find(
        (currentAdjustmentCodeInfo) => currentAdjustmentCodeInfo.adjustmentCode === adjustmentCode
    );

    if (!adjustmentCodeInfo) {
        adjustmentCodeInfo = {
            adjustmentCode: adjustmentCode,
        };

        consolidatedAccountSummary.adjustmentInfo.push(adjustmentCodeInfo);
    }

    return adjustmentCodeInfo;
}

export function createTrace(
    traceType: TraceType,
    rowNumber: number,
    selectedCell: SelectedWhitePaperCell,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperReportMap: IWhitePaperMappedRowDictionary,
    traceState: IWhitePaperRowTraceState
): IWhitePaperRowTrace | null {
    let currentRow: IWhitePaperMappedRow | null = whitePaperReportMap[rowNumber];

    if (currentRow && traceState.currentBranchRowNumbers.indexOf(rowNumber) === -1) {
        let trace: IWhitePaperRowTrace = traceState.tracedRows[rowNumber];

        if (!trace) {
            trace = mapRowToTraceDetail(currentRow, selectedCell);
            traceState.tracedRows[rowNumber] = trace;
            traceState.currentBranchRowNumbers.push(rowNumber);
            trace.traces = getTraces(traceType, currentRow, selectedCell, whitePaperReportColumn, whitePaperReportMap, traceState);
            traceState.currentBranchRowNumbers.pop();
        }

        return trace;
    }

    return null;
}

export function getAccountAmountDataSource(
    data: ITrackedExtractionDetailsAccountSummary[],
    reportRowNumber: number,
    reportColumnId: number,
    report: Report,
    changeTrackingData?: IWhitePaperReportChanges
): IAccountAmountWhitePaperRow[] {
    const rows: IAccountAmountWhitePaperRow[] = [];

    data?.forEach((accountSummary: ITrackedExtractionDetailsAccountSummary) => {
        rows.push(
            getWhitePaperRowForExtractionDetailsAccountSummary(accountSummary, reportRowNumber, reportColumnId, report, changeTrackingData)
        );
    });

    return rows;
}

export function getWhitePaperRowForExtractionDetailsAccountSummary(
    accountSummary: ITrackedExtractionDetailsAccountSummary,
    reportRowNumber: number,
    reportColumnId: number,
    report: Report,
    changeTrackingData?: IWhitePaperReportChanges
): AccountAmountWhitePaperRow {
    const accountSummaryRowChildren: any = {};

    const accountSummaryRow: AccountAmountWhitePaperRow = new AccountAmountWhitePaperRow(
        accountSummary,
        'AccountSummary',
        accountSummaryRowChildren
    );

    const bookInfo: ExtractionDetailsBookInfo | undefined = accountSummary.bookInfo;

    if (bookInfo) {
        const bookSummaryRowChildren: any = {};

        const bookSummaryRow: AccountAmountWhitePaperRow = new AccountAmountWhitePaperRow(
            bookInfo,
            'BookSummary',
            bookSummaryRowChildren,
            accountSummaryRow
        );

        const bookEntries: ExtractionDetailsBookEntry[] | null | undefined = bookInfo.entries;
        const bookEntryRows: IAccountAmountWhitePaperRow[] = [];

        if (bookEntries && bookEntries.length > 0) {
            for (const bookEntry of Object.values<ExtractionDetailsBookEntry>(bookEntries)) {
                const bookEntryRow: AccountAmountWhitePaperRow = new AccountAmountWhitePaperRow(
                    bookEntry,
                    'BookEntry',
                    null,
                    bookSummaryRow
                );

                bookEntryRows.push(bookEntryRow);
            }
        }

        bookSummaryRowChildren.bookEntries = bookEntryRows;
        accountSummaryRowChildren.bookSummary = bookSummaryRow;
    }

    const adjustmentInfoList: ExtractionDetailsAdjustmentInfo[] | null | undefined = accountSummary.adjustmentInfo;

    if (adjustmentInfoList && adjustmentInfoList.length > 0) {
        const adjustmentSummaryRows: IAccountAmountWhitePaperRow[] = [];
        for (const adjustmentInfo of Object.values<ExtractionDetailsAdjustmentInfo>(adjustmentInfoList)) {
            const adjustmentSummaryRowChildren: any = {};
            const adjustmentEntries: ExtractionDetailsAdjustmentEntry[] | null | undefined = adjustmentInfo.entries;
            const hasEntries: boolean = (adjustmentEntries?.length ?? 0) > 0;
            let recalcNeeded: boolean = false;

            if (!hasEntries) {
                recalcNeeded =
                    changeTrackingData?.rowChanges?.[reportRowNumber.toString()]?.[reportColumnId.toString()]?.find(
                        (changeSummary: IWhitePaperReportCellChangesSummary) =>
                            changeSummary.account === accountSummary.accountNumber &&
                            changeSummary.adjustment === adjustmentInfo.adjustmentCode &&
                            changeSummary.definingRowNumber === accountSummary.rowNumber &&
                            changeSummary.changes?.update?.find(
                                (change: IWhitePaperReportCellChange) =>
                                    change.context?.case === report.case &&
                                    change.context?.entity === report.entityCode &&
                                    change.context?.jurisdiction === report.jurisdiction &&
                                    change.context?.year === report.year
                            ) !== undefined
                    ) !== undefined;
            }

            const adjustmentSummaryRow: AccountAmountWhitePaperRow = new AccountAmountWhitePaperRow(
                adjustmentInfo,
                'AdjustmentSummary',
                adjustmentSummaryRowChildren,
                accountSummaryRow,
                recalcNeeded
            );

            const adjustmentEntryRows: IAccountAmountWhitePaperRow[] = [];

            if (hasEntries) {
                for (const adjustmentEntry of Object.values<ExtractionDetailsBookEntry>(adjustmentEntries!)) {
                    recalcNeeded =
                        changeTrackingData?.rowChanges?.[reportRowNumber.toString()]?.[reportColumnId.toString()]?.find(
                            (changeSummary: IWhitePaperReportCellChangesSummary) =>
                                changeSummary.account === accountSummary.accountNumber &&
                                changeSummary.adjustment === adjustmentInfo.adjustmentCode &&
                                changeSummary.definingRowNumber === accountSummary.rowNumber &&
                                changeSummary.changes?.update?.find(
                                    (change: IWhitePaperReportCellChange) =>
                                        change.context?.case === adjustmentEntry.case &&
                                        change.context?.entity === adjustmentEntry.corporation &&
                                        change.context?.jurisdiction === adjustmentEntry.jurisdiction &&
                                        change.context?.year?.toString() === adjustmentEntry.year
                                ) !== undefined
                        ) !== undefined;
                    const adjustmentEntryRow: AccountAmountWhitePaperRow = new AccountAmountWhitePaperRow(
                        adjustmentEntry,
                        'AdjustmentEntry',
                        null,
                        adjustmentSummaryRow,
                        recalcNeeded
                    );

                    adjustmentEntryRows.push(adjustmentEntryRow);
                }
            }

            adjustmentSummaryRowChildren.adjustmentEntries = adjustmentEntryRows;
            adjustmentSummaryRows.push(adjustmentSummaryRow);
        }

        if (adjustmentSummaryRows.length > 0) {
            accountSummaryRowChildren.adjustmentSummaries = adjustmentSummaryRows;
        }
    }

    return accountSummaryRow;
}

export function getDefiningRowsFromDataSource(
    selectedCell: SelectedWhitePaperCell,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperReportMap: IWhitePaperMappedRowDictionary
): IWhitePaperRowTrace[] {
    const traceState: IWhitePaperRowTraceState = {
        currentBranchRowNumbers: [],
        tracedRows: {},
    };

    let traces: IWhitePaperRowTrace[] | null = null;

    if (whitePaperReportMap && selectedCell?.displayRowNumber) {
        let currentRow: IWhitePaperMappedRow = whitePaperReportMap[selectedCell.displayRowNumber];

        traces = getTraces(TraceType.DefiningRows, currentRow, selectedCell, whitePaperReportColumn, whitePaperReportMap, traceState);
    }

    return traces ?? [];
}

function getDefiningRowTraces(
    currentRow: IWhitePaperMappedRow,
    selectedCell: SelectedWhitePaperCell,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperReportMap: IWhitePaperMappedRowDictionary,
    traceState: IWhitePaperRowTraceState
): IWhitePaperRowTrace[] {
    if (!currentRow.traces) {
        return [];
    }

    const traces: IWhitePaperRowTrace[] = [];

    for (const tracedCells of Object.values<IWhitePaperTracedCell[]>(currentRow.traces)) {
        for (const tracedCell of tracedCells) {
            if (tracedCell.rowNumber === undefined) {
                continue;
            }

            const trace = createTrace(
                TraceType.DefiningRows,
                tracedCell.rowNumber,
                selectedCell,
                whitePaperReportColumn,
                whitePaperReportMap,
                traceState
            );

            if (trace) {
                traces.push(trace);
            }
        }
    }

    return traces;
}

export function getImpactedRowsFromDataSource(
    selectedCell: SelectedWhitePaperCell,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperReportMap: IWhitePaperMappedRowDictionary
): IWhitePaperRowTrace[] {
    const traceState: IWhitePaperRowTraceState = {
        currentBranchRowNumbers: [],
        tracedRows: {},
    };

    let traces: IWhitePaperRowTrace[] | null = null;

    if (whitePaperReportMap && selectedCell?.displayRowNumber) {
        let currentRow: IWhitePaperMappedRow = whitePaperReportMap[selectedCell.displayRowNumber];

        traces = getTraces(TraceType.ImpactedRows, currentRow, selectedCell, whitePaperReportColumn, whitePaperReportMap, traceState);
    }

    return traces ?? [];
}

function getImpactedRowTraces(
    currentRow: IWhitePaperMappedRow,
    selectedCell: SelectedWhitePaperCell,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperReportMap: IWhitePaperMappedRowDictionary,
    traceState: IWhitePaperRowTraceState
): IWhitePaperRowTrace[] {
    if (currentRow.rowNumber === undefined) {
        return [];
    }

    const traces: IWhitePaperRowTrace[] = [];

    for (const mappedRow of Object.values<IWhitePaperMappedRow>(whitePaperReportMap)) {
        if (!mappedRow.traces) {
            continue;
        }

        for (const tracedCells of Object.values<IWhitePaperTracedCell[]>(mappedRow.traces)) {
            for (const tracedCell of tracedCells) {
                if (tracedCell.rowNumber !== currentRow.rowNumber) {
                    continue;
                }

                const trace = createTrace(
                    TraceType.ImpactedRows,
                    mappedRow.rowNumber!,
                    selectedCell,
                    whitePaperReportColumn,
                    whitePaperReportMap,
                    traceState
                );

                if (trace) {
                    traces.push(trace);
                }
            }
        }
    }

    return traces;
}

export function getSelectedRowFromDataSource(
    selectedCell: SelectedWhitePaperCell,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperReportMap: IWhitePaperMappedRowDictionary
): IWhitePaperRowTrace[] {
    const selectedRow: IWhitePaperRow = whitePaperReportMap[selectedCell.displayRowNumber!];
    const trace: IWhitePaperRowTrace = mapRowToTraceDetail(selectedRow, selectedCell);

    return [trace];
}

function getTraces(
    traceType: TraceType,
    currentRow: IWhitePaperMappedRow,
    selectedCell: SelectedWhitePaperCell,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperReportMap: IWhitePaperMappedRowDictionary,
    traceState: IWhitePaperRowTraceState
): IWhitePaperRowTrace[] {
    let traces: IWhitePaperRowTrace[] | null = null;

    switch (traceType) {
        case TraceType.DefiningRows: {
            traces = getDefiningRowTraces(currentRow, selectedCell, whitePaperReportColumn, whitePaperReportMap, traceState);
            break;
        }

        case TraceType.ImpactedRows: {
            traces = getImpactedRowTraces(currentRow, selectedCell, whitePaperReportColumn, whitePaperReportMap, traceState);
            break;
        }
    }

    return traces ?? [];
}

export function mapRowToTraceDetail(row: IWhitePaperMappedRow, selectedCell: SelectedWhitePaperCell): IWhitePaperRowTrace {
    let trace: IWhitePaperRowTrace = {};
    trace.dataType = row.dataType;
    trace.description = row.description;
    trace.definition = row.definition;
    trace.rowNumber = row.rowNumber;
    trace.rowStartIndex = row.rowStartIndex;

    if (selectedCell?.columnFieldName) {
        trace.amount = getAmountAsNumberOrDefault(row, selectedCell.columnFieldName);
    }

    return trace;
}

export function buildExtractionRequest(
    traces: IWhitePaperRowTrace[],
    selectedRow: IWhitePaperRow,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    returnKey: number
): ExtractionDetailsRequest[] {
    const requestMap = new Map<number, ExtractionDetailsRequest>();
    const tracedMap = new Map<number, boolean>();
    buildExtractionRequestInternal(traces, selectedRow, whitePaperReportColumn, returnKey, requestMap, tracedMap);
    const requests = Array.from(requestMap.values());
    return requests;
}

function buildExtractionRequestInternal(
    traces: IWhitePaperRowTrace[],
    selectedRow: IWhitePaperRow,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    returnKey: number,
    requestMap: Map<number, ExtractionDetailsRequest>,
    tracedMap: Map<number, boolean>
) {
    try {
        if (selectedRow.definition === 'INPUT') {
            const request: ExtractionDetailsRequest = {};
            request.rowStartIndex = selectedRow.rowStartIndex;
            request.rowStart = selectedRow.rowNumber;
            request.columnId = whitePaperReportColumn?.originalColumnId;
            request.columnIndex = whitePaperReportColumn?.columnIndex;
            request.rowCount = 1;
            request.columnCount = 1;
            request.returnKey = returnKey;
            requestMap.set(request.rowStart ?? 0, request);
        } else {
            traces.forEach((trace) => {
                if (!tracedMap.has(trace.rowNumber ?? 0)) {
                    tracedMap.set(trace.rowNumber ?? 0, true);

                    if (trace.definition === 'INPUT') {
                        const traceRequest: ExtractionDetailsRequest = {};
                        traceRequest.rowStartIndex = trace.rowStartIndex;
                        traceRequest.rowStart = trace.rowNumber;
                        traceRequest.columnId = whitePaperReportColumn?.originalColumnId;
                        traceRequest.columnIndex = whitePaperReportColumn?.columnIndex;
                        traceRequest.rowCount = 1;
                        traceRequest.columnCount = 1;
                        traceRequest.returnKey = returnKey;
                        requestMap.set(traceRequest.rowStart ?? 0, traceRequest);
                    }

                    if (trace.traces) {
                        buildExtractionRequestInternal(trace.traces, selectedRow, whitePaperReportColumn, returnKey, requestMap, tracedMap);
                    }
                }
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function getUniqueExtractionDetailRequests(requests: ExtractionDetailsRequest[]): ExtractionDetailsRequest[] {
    if (!requests?.length) {
        throw new Error('"requests" cannot be null or empty');
    }
    const requestMap = new Map<number, ExtractionDetailsRequest>();
    requests.forEach((element) => {
        requestMap.set(element.rowStart ?? 0, element);
    });
    const extractionDetailRequest = Array.from(requestMap.values());
    return extractionDetailRequest;
}

export function getAccountAmountSummaryByEphemeralKey(
    accountNumber: string,
    ephemeralKey: number,
    summaries: ITrackedExtractionDetailsAccountSummary[]
): ITrackedExtractionDetailsAccountSummary | undefined {
    const filterResult: ITrackedExtractionDetailsAccountSummary[] | null | undefined = summaries?.filter(
        (summary: ITrackedExtractionDetailsAccountSummary) => {
            const trackedSummary: ITrackedExtractionDetailsAccountSummary = summary as ITrackedExtractionDetailsAccountSummary;

            return trackedSummary.accountNumber === accountNumber && trackedSummary.ephemeralKey === ephemeralKey;
        }
    );
    return filterResult?.length > 0 ? filterResult[0] : undefined;
}

export function isReportAmountDataCellEditable(item: IAccountAmountWhitePaperRow): boolean {
    const adjustmentEntryCount: number = item?.adjustmentEntries?.length ?? 0;

    return (
        (item.dataObjectType === 'AdjustmentEntry' || (item.dataObjectType === 'AdjustmentSummary' && adjustmentEntryCount === 0)) &&
        !item.isComplexAdjustment
    );
}

export function removeUpdateTimestampsFromConsolidatedAmountChanges(consolidatedAccountSummaries: ExtractionDetailsAccountSummary[]) {
    for (const accountSummary of consolidatedAccountSummaries) {
        for (const bookEntry of ArrayUtils.ensureArray(accountSummary.bookInfo?.entries as ITrackedUpdateTime[])) {
            delete bookEntry.updateTimestamp;
        }

        for (const adjustmentCodeInfo of ArrayUtils.ensureArray(accountSummary.adjustmentInfo)) {
            for (const adjustmentEntry of ArrayUtils.ensureArray(adjustmentCodeInfo.entries as ITrackedUpdateTime[])) {
                delete adjustmentEntry.updateTimestamp;
            }
        }
    }
}
