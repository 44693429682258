import { ReactNode } from 'react';
import { ICardGridList, ICardGridListRowData, IColumnExtended, IGroupExtended } from './CardGridListInterfaces';

export type OnExpandCollapseStateChangeCallback = (state: CardGridListExpandCollapseState, grid: ICardGridList) => void;

export type CardGridListChildDataLoadedCallback = (
    childDataName: string,
    childRows: ICardGridListRowData[],
    parentRow: ICardGridListRowData
) => void;

export type CellDataType = 'number' | 'text';

export type RenderDataCellTextCallback = (
    item?: any,
    group?: IGroupExtended,
    column?: IColumnExtended,
    defaultRender?: () => ReactNode
) => ReactNode;

export type Direction = 'down' | 'left' | 'right' | 'up';
export type RowRenderType = 'datarow' | 'headerrow';

export enum CardGridListExpandCollapseState {
    AllCollapsed = 0,
    Mixed = 1,
    AllExpanded = 2,
}
