/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */

export type WhitePaperRowStyle = (typeof WhitePaperRowStyle)[keyof typeof WhitePaperRowStyle];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WhitePaperRowStyle = {
    Blue: 'Blue',
    Bold: 'Bold',
} as const;
