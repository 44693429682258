import { useMatch } from 'react-router';
import { IWhitePaperReport } from '../model/iWhitePaperReport';

// determines if the current route contains the cell comments subroute
// if it does, it creates an object with the comment data needed to show the comments panel
// example 1 (no commentId): /view/51/cell/FD0P_0/10500/comments
// example 2 (with commentId): /view/51/cell/FD0P_0/10500/comments/d158053e-72b9-4c3a-bb3e-dad621e65daf
export const useCellCommentsRouteDetails = (currentReport: IWhitePaperReport | undefined) => {
    const match = useMatch('/view/:id/cell/:columnId/:rowId/:subroute?/:cellCommentId?');

    if (currentReport && match?.params) {
        const { subroute, rowId, columnId } = match.params;

        if (subroute && rowId && columnId && subroute.toLocaleLowerCase() === 'comments') {
            const rowData = currentReport.rows?.find((r) => r.displayRowNumber === rowId);

            if (rowData) {
                return { isCommentsSubroute: true, commentData: { rowData, columnName: columnId } };
            }
        }
    }

    return { isCommentsSubroute: false, commentData: undefined };
};
