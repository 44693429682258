import { DirectionalHint, TooltipOverflowMode } from '@fluentui/react';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { ICustomPalette } from '../../theme/ICustomPalette';
import { getTooltipHostProps } from '../../utils/CustomStyles';
import {
    ICardGridListCellStyle,
    ICardGridListCellStyleAdvanced,
    ICardGridListColumn,
    IColumnExtended,
    IGroupExtended,
} from '../card-grid-list/CardGridListInterfaces';
import {
    adjustmentEntryRowClass,
    adjustmentSummaryClassWithLastModifiedBy,
    adjustmentSummaryRowClass,
    bookEntryRowClass,
    bookSummaryClassWithLastModifiedBy,
    bookSummaryRowClass,
    disabledClass,
    recalcNeededClass,
} from './AccountAmountTabPanel.constants';
import { IAccountAmountTabPanelProps } from './AccountAmountTabPanel.props';
import { IAccountAmountWhitePaperRow, isReportAmountDataCellEditable } from './ReportDrillDownUtils';

export function getAccountAmountGridListColumns(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>,
    renderLastModifiedColumn: (item?: IAccountAmountWhitePaperRow) => JSX.Element | null,
    props: IAccountAmountTabPanelProps,
    reportAmountColumnRowStyle: ICardGridListCellStyle,
    renderAdjustmentAssociationDialogButton: (item?: IAccountAmountWhitePaperRow) => JSX.Element | null,
    customPalette?: ICustomPalette
): ICardGridListColumn[] {
    return [
        getAccountColumn(onRenderDataCellText, t),
        getAdjustmentCodeColumn(onRenderDataCellText, t),
        getAdjustmentTypeColumn(onRenderDataCellText, t),
        getDescriptionColumn(onRenderDataCellText, t, customPalette),
        getEntityColumn(onRenderDataCellText, t),
        getCaseColumn(onRenderDataCellText, t),
        getJurisdictionColumn(onRenderDataCellText, t),
        getYearColumn(onRenderDataCellText, t),
        getLastModifiedByColumn(renderLastModifiedColumn, t),
        getAdjustmentAccountAssociationColumn(renderAdjustmentAssociationDialogButton, t, reportAmountColumnRowStyle, customPalette),
        getAmountColumn(props, t, reportAmountColumnRowStyle, customPalette),
    ];
}
function getAccountColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--account-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
            [`${adjustmentSummaryRowClass}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
            [`${adjustmentSummaryClassWithLastModifiedBy}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        fieldName: 'account',
        headerText: t('accountAdjustment'),
    };
}

function getAdjustmentCodeColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--adjustmentCode-column-width)',
        customRowStyles: {
            [`${adjustmentSummaryRowClass}`]: {
                display: 'block',
            } as ICardGridListCellStyleAdvanced,
            [`${adjustmentSummaryClassWithLastModifiedBy}`]: {
                display: 'block',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        fieldName: 'adjustmentCode',
        headerHidden: true,
        headerText: t('adjustmentCode'),
    };
}

function getAdjustmentTypeColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--type-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        fieldName: 'adjustmentType',
        headerText: t('type'),
    };
}

function getDescriptionColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>,
    customPalette?: ICustomPalette
): ICardGridListColumn {
    return {
        columnWidth: 'var(--description-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'none',
            } as ICardGridListCellStyleAdvanced,
            [`${adjustmentSummaryClassWithLastModifiedBy}`]: {
                flexBasis: 'var(--description-column-width-withAdjustment)',
            } as ICardGridListCellStyleAdvanced,
            [`${bookSummaryClassWithLastModifiedBy}`]: {
                flexBasis: 'var(--description-column-width-withAdjustment)',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        tooltipProps: {
            tooltipHostProps: getTooltipHostProps(DirectionalHint.leftCenter, TooltipOverflowMode.Parent, customPalette),
            getTooltipContent: (item) => item?.description,
        },
        fieldName: 'description',
        headerText: t('description'),
    };
}

function getEntityColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--entity-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'block',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'block',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        fieldName: 'entity',
        headerHidden: true,
        headerText: t('entity'),
    };
}

function getCaseColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--case-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        fieldName: 'case',
        headerHidden: true,
        headerText: t('case'),
    };
}

function getJurisdictionColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--jurisdiction-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        fieldName: 'jurisdiction',
        headerHidden: true,
        headerText: t('jurisdiction'),
    };
}

function getYearColumn(
    onRenderDataCellText: (
        t: TFunction,
        item?: any,
        group?: IGroupExtended,
        column?: IColumnExtended,
        defaultRender?: () => ReactNode
    ) => ReactNode,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--year-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: onRenderDataCellText.bind(null, t),
        },
        fieldName: 'year',
        headerHidden: true,
        headerText: t('year'),
    };
}

function getLastModifiedByColumn(
    renderLastModifiedColumn: (item?: IAccountAmountWhitePaperRow) => JSX.Element | null,
    t: TFunction<'translation', undefined, 'translation'>
): ICardGridListColumn {
    return {
        columnWidth: 'var(--lastmodifiedby-column-width)',
        customRowStyles: {
            [`${adjustmentEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            } as ICardGridListCellStyleAdvanced,
            [`${bookEntryRowClass}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            } as ICardGridListCellStyleAdvanced,
            [`${adjustmentSummaryClassWithLastModifiedBy}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            } as ICardGridListCellStyleAdvanced,
            [`${bookSummaryClassWithLastModifiedBy}`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            } as ICardGridListCellStyleAdvanced,
        },
        dataCellProps: {
            onRenderDataCellText: renderLastModifiedColumn,
        },
        headerText: t('lastModifiedBy'),
        fieldName: 'lastModifiedBy',
        headerHidden: true,
    } as ICardGridListColumn;
}
function getAdjustmentAccountAssociationColumn(
    renderAdjustmentAccountAssociationDialogButton: (item?: IAccountAmountWhitePaperRow) => JSX.Element | null,
    t: TFunction<'translation', undefined, 'translation'>,
    reportAmountColumnRowStyle: ICardGridListCellStyle,
    customPalette?: ICustomPalette
): ICardGridListColumn {
    return {
        columnWidth: 'var(--editvaluebutton-column-width)',
        disableColumnFlexSizing: true,
        fieldName: 'adjustmentAccountAssociation',
        dataCellProps: {
            onRenderDataCellText: renderAdjustmentAccountAssociationDialogButton,
        },
        headerText: '',
        headerHidden: false,
        rowStyle: reportAmountColumnRowStyle,
        customRowStyles: {
            [disabledClass]: {
                backgroundColor: customPalette?.disabledGrey,
            },
            [recalcNeededClass]: {
                backgroundColor: customPalette?.recalcNeededBackground,
            },
        },
    };
}

function getAmountColumn(
    props: IAccountAmountTabPanelProps,
    t: TFunction<'translation', undefined, 'translation'>,
    reportAmountColumnRowStyle: ICardGridListCellStyle,
    customPalette?: ICustomPalette
): ICardGridListColumn {
    return {
        columnWidth: 'var(--reportamount-column-width)',
        dataCellProps: {
            dataType: 'number',
            editable: (item: IAccountAmountWhitePaperRow) => isReportAmountDataCellEditable(item) && props.isAmountDataEntryModifyEnabled,
            onChange: props.onAmountCellValueChange,
        },
        disableColumnFlexSizing: true,
        fieldName: 'amount',
        groupHeaderStyle: {
            textAlign: 'right',
            fontWeight: '700',
        },
        headerText: t('reportAmount'),
        rowStyle: reportAmountColumnRowStyle,
        customRowStyles: {
            [adjustmentSummaryRowClass]: {
                fontWeight: '700',
            },
            [bookSummaryRowClass]: {
                fontWeight: '700',
            },
            [recalcNeededClass]: {
                backgroundColor: customPalette?.recalcNeededBackground,
            },
            [disabledClass]: {
                backgroundColor: customPalette?.disabledGrey,
            },
        },
    };
}
