import { useBoolean } from '@fluentui/react-hooks';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import { createContext, RefObject, useState } from 'react';
import { formatNumberForDisplay } from '../utils/NumberUtils';
import { convertStringToNumber } from '../utils/StringUtils';

export enum searchOnType {
    'Report',
    'ListOfReports',
    'Accounts',
}

export interface ISearchTableValues {
    searchValue: string;
    isSearchValueApplied: boolean;
    setIsSearchValueAppliedTrue: () => void;
    setIsSearchValueAppliedFalse: () => void;
    setSearchValue: (search: string) => void;
    clearSearch: (grid: RefObject<GridComponent>) => void;
    applySearch: (grid: RefObject<GridComponent>) => void;
}

export const SearchTableContext = createContext<ISearchTableValues | null>(null);

export function SearchTableContextProvider(props: { value?: ISearchTableValues; children?: React.ReactNode; searchOn: searchOnType }) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSearchValueApplied, { setTrue: setIsSearchValueAppliedTrue, setFalse: setIsSearchValueAppliedFalse }] = useBoolean(false);

    const updateSearchValueStatus = (searchValue: string) => {
        if (setIsSearchValueAppliedTrue && searchValue.length > 0) {
            setIsSearchValueAppliedTrue();
        }

        if (setIsSearchValueAppliedFalse && searchValue.length === 0) {
            setIsSearchValueAppliedFalse();
        }
    };

    const shouldSearchAsString = (numericValue: number) => {
        const includesThousandSeparators = searchValue?.includes(',');
        return isNaN(numericValue) || includesThousandSeparators || props.searchOn === searchOnType.ListOfReports;
    };

    const applySearch = (grid: RefObject<GridComponent>) => {
        if (grid && grid.current) {
            updateSearchValueStatus(searchValue ?? '');
            const numericValue = convertStringToNumber(searchValue);
            if (props.searchOn === searchOnType.Accounts) {
                const numberSearchValue = searchValue.replace(/,/g, '');
                if (!isNaN(parseFloat(numberSearchValue))) {
                    grid.current.search(numberSearchValue ?? '');
                    return;
                }
                grid.current.search(searchValue ?? '');
                return;
            }
            if (shouldSearchAsString(numericValue)) {
                grid.current.search(searchValue ?? '');
                return;
            }
            grid.current.search(formatNumberForDisplay(numericValue));
        }
    };

    const clearSearch = (grid: RefObject<GridComponent>) => {
        setSearchValue('');
        grid?.current?.search('');
        updateSearchValueStatus('');
    };

    return (
        <SearchTableContext.Provider
            value={
                props.value ?? {
                    searchValue,
                    isSearchValueApplied,
                    setIsSearchValueAppliedTrue,
                    setIsSearchValueAppliedFalse,
                    setSearchValue,
                    clearSearch,
                    applySearch,
                }
            }
        >
            {props.children}
        </SearchTableContext.Provider>
    );
}
