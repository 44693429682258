import { FocusZone, FocusZoneTabbableElements, IList, List, Stack } from '@fluentui/react';
import { RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingErrorSvg from '../../assets/Loading_error.svg';
import NoCommentSvg from '../../assets/No_comment.svg';
import { CommentsCurrentType } from '../../data-types/ICommentsUIState';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { Conversation } from '../../model/conversation';
import { CommentsActionType } from '../../reducers/commentsStateReducer';
import { ConversationCard } from '../comments/ConversationCard';
import { DeleteCommentConfirmationDialog } from '../comments/DeleteCommentConfirmationDialog';
import { OverlaySpinner } from '../common/OverlaySpinner';
import { AddConversationItem } from './AddConversationItem';
import { ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';

// todo: clean up the "any"s if we can - we want to avoid coupling to any Orval generated models or data
// so that we can pull this whole component set out and into common at some point
// todo: ditch dependence on Orval model
export interface IConversationListProps {
    newConversationTitle: string;
    items: Conversation[];
    isLoadingItems: boolean;
    isErrorItems: boolean;
    isFetchingItems: boolean;
    addConversationAsync: any;
    addReplyAsync: any;
    deleteConversationAsync: any;
    deleteReplyAsync: any;
    updateConversationAsync: any;
    updateReplyAsync: any;
    refetchItems: any;
}

export const ConversationList = (props: IConversationListProps) => {
    const { t } = useTranslation();
    const loadingText = t('loading').toString();
    const commentListAriaLabelText = t('commentListAriaLabel').toString();
    const pleaseUpdateCommentText = t('pleaseUpdateComment');

    const { state: uiState, dispatch: dispatchUIState } = useCommentsUI();
    // todo: lets get the error from reducer state instead and we can avoid having it here just to pass it to children
    const [userFriendlyErrorMessage, setUserFriendlyErrorMessage] = useState<string | null>(null);
    const [selectedCardRef, setSelectedCardRef] = useState<RefObject<HTMLDivElement> | undefined>(undefined);

    const commentToHighlightRef = useRef<HTMLDivElement>(null);

    const listRef = useRef<IList>(null);
    // stale reference issues - for now, capture uiState in a ref unless we have a better idea to get the real current value in the event handler
    // tried function definition, useCallback, useMemo, none of that solved the issue, so here we are
    // (this seems to be an issue so far only when the dispatch is sent from a direct grandchild async)
    // https://stackoverflow.com/questions/58524721/how-to-avoid-stale-state-in-react-functional-component
    const uiStateSnapshot = useRef(uiState);
    uiStateSnapshot.current = uiState;

    const handleCancelUpdate = () => {
        setUserFriendlyErrorMessage(null);
        dispatchUIState({ type: CommentsActionType.CancelOperation });
    };

    const handleCommentSelected = (id?: string | null, cardRef?: RefObject<HTMLDivElement>) => {
        if (uiStateSnapshot.current.hasPendingChanges) {
            dispatchUIState({ type: CommentsActionType.OperationFailed, errorMessage: pleaseUpdateCommentText });
            listRef?.current?.scrollToIndex(props.items.findIndex((x) => x.id === uiStateSnapshot.current.selectedCommentId));
        } else {
            setUserFriendlyErrorMessage(null);
            dispatchUIState({ type: CommentsActionType.SelectedComment, selectedId: id ?? null });
            setSelectedCardRef(cardRef);
        }
    };

    const handleAddReply = async (conversationId: string, req: any) => {
        try {
            await props.addReplyAsync({ conversationId: conversationId, data: req });
            return true;
        } catch (error: any) {
            // todo: can we ditch the forceUpdate here if we properly set error message in reducer
            listRef?.current?.forceUpdate();
            return false;
        }
    };

    const handleCancelDelete = () => {
        dispatchUIState({ type: CommentsActionType.CancelOperation });
    };

    const handleCommentDelete = async () => {
        const deleteRequest = uiState.conversationDeleteRequest;
        const deleteReplyRequest = uiState.replyDeleteRequest;

        if (deleteRequest || deleteReplyRequest) {
            dispatchUIState({ type: CommentsActionType.Submitting });
            try {
                if (deleteRequest) {
                    await props.deleteConversationAsync({
                        data: deleteRequest,
                    });
                    dispatchUIState({ type: CommentsActionType.DeleteConversationSuccess });
                } else if (deleteReplyRequest) {
                    await props.deleteReplyAsync({
                        data: deleteReplyRequest,
                    });
                    dispatchUIState({ type: CommentsActionType.DeleteReplySuccess });
                }
            } catch (error: any) {
                // todo: can we ditch the forceUpdate here if we properly set error message in reducer
                setUserFriendlyErrorMessage(t('unableToDeleteComment'));
                listRef?.current?.forceUpdate();
                dispatchUIState({ type: CommentsActionType.SubmitError });
            }
        }
    };

    const handleRefreshState = () => {
        dispatchUIState({ type: CommentsActionType.CancelOperation });
        setUserFriendlyErrorMessage(null);
        handleCommentSelected(null);
        props.refetchItems();
    };

    const handleConversationUpdated = async (value: ICommentRichTextEditorValue) => {
        //const updateRequest = uiState.conversationUpdateRequest; // todo: can we figure out why this is always null and avoid the ref nonsense?
        const updateRequest = uiStateSnapshot.current.conversationUpdateRequest;
        if (updateRequest && value.commentText) {
            dispatchUIState({ type: CommentsActionType.Submitting });
            try {
                await props.updateConversationAsync({
                    data: {
                        ...updateRequest,
                        comment: value.commentHtml,
                        commentPlainText: value.commentText,
                        userMentions: value.userMentions,
                    },
                });
                dispatchUIState({ type: CommentsActionType.EditConversationSuccess });
            } catch (error: any) {
                dispatchUIState({ type: CommentsActionType.SubmitError, errorMessage: t('anErrorOccurred') });
            }
        }
    };

    const handleReplyUpdated = async (value: ICommentRichTextEditorValue) => {
        const updateRequest = uiStateSnapshot.current.replyUpdateRequest;
        if (updateRequest && value.commentText) {
            dispatchUIState({ type: CommentsActionType.Submitting });
            try {
                await props.updateReplyAsync({
                    data: {
                        ...updateRequest,
                        comment: value.commentHtml,
                        commentPlainText: value.commentText,
                        userMentions: value.userMentions,
                    },
                });
                dispatchUIState({ type: CommentsActionType.EditReplySuccess });
            } catch (error: any) {
                dispatchUIState({ type: CommentsActionType.SubmitError, errorMessage: t('anErrorOccurred') });
            }
        }
    };

    const hasNoItems = !props.isLoadingItems && !props.isErrorItems && (props.items?.length ?? 0) === 0;

    const onRenderCell = (item: Conversation | undefined, index: number | undefined): JSX.Element => {
        if (!item) {
            return <div>no item</div>;
        }

        return (
            <ConversationCard
                ariaPosinset={index}
                conversation={item}
                title={item.title ?? ''}
                key={item.id}
                onConversationUpdated={handleConversationUpdated}
                onSelected={handleCommentSelected}
                errorMessage={userFriendlyErrorMessage ?? undefined}
                onRefresh={handleRefreshState}
                onAddReply={handleAddReply}
                onCancelUpdate={handleCancelUpdate}
                onReplyUpdated={handleReplyUpdated}
                commentToHighlightRef={commentToHighlightRef}
            />
        );
    };

    const handleAddComment = async (value: ICommentRichTextEditorValue) => {
        dispatchUIState({ type: CommentsActionType.Submitting });
        try {
            await props.addConversationAsync({
                comment: value.commentHtml,
                commentPlainText: value.commentText,
                title: props.newConversationTitle,
                userMentions: value.userMentions,
            });
            setUserFriendlyErrorMessage(null);
            dispatchUIState({ type: CommentsActionType.AddNewConversationSuccess });
        } catch (error: any) {
            if (error) {
                setUserFriendlyErrorMessage(t('anErrorOccurred'));
            }
            dispatchUIState({ type: CommentsActionType.SubmitError });
        }
    };

    const handleShouldEnterInnerZone = (ev: React.KeyboardEvent<HTMLElement>) => {
        let element = ev.target as HTMLElement;
        // contenteditable is syncfusion rich edit - hack to make some of the stuff work in focus zone, tho not perfect
        if (element.getAttribute('contenteditable') || (element.getAttribute('data-is-focuszone-keypress-skip') && ev.code === 'Tab')) {
            // return true means "go away focus zone, you're drunk"
            return true;
        } else {
            return false;
        }
    };

    const handleOnPagesUpdated = () => {
        if (selectedCardRef) {
            selectedCardRef.current?.focus();
        } else if (commentToHighlightRef.current) {
            //without the timeout, there are cases where the focus doesn't get set (no border around the comment)
            setTimeout(() => {
                commentToHighlightRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                commentToHighlightRef.current?.focus();
            }, 300);
        }
    };

    // we only show the main loading spinner when react query truly is loading - child cards will display status like overlays or processing buttons
    // and they need to re-render to do so
    const showLoadingSpinner = props.isLoadingItems || props.isFetchingItems;
    const loadingSpinnerLabel = loadingText;
    const showNoCommentsImg = !props.isLoadingItems && uiState.currentUiState !== CommentsCurrentType.AddingNewConversation && hasNoItems;
    return (
        <OverlaySpinner label={loadingSpinnerLabel} showOverlay={showLoadingSpinner}>
            <Stack>
                <AddConversationItem
                    newConversationTitle={props.newConversationTitle}
                    onAdd={handleAddComment}
                    onRefresh={handleRefreshState}
                    canAdd={!props.isErrorItems && !props.isLoadingItems}
                    errorMessage={userFriendlyErrorMessage ?? undefined}
                />

                {showNoCommentsImg && (
                    <Stack horizontalAlign='center'>
                        <img style={{ width: '25%' }} src={NoCommentSvg} alt='There are no comments yet.' />
                        There are no comments yet
                    </Stack>
                )}
                {props.isErrorItems && (
                    <Stack horizontalAlign='center'>
                        <img style={{ width: '25%' }} src={LoadingErrorSvg} alt='There was an error loading comments.' />
                        There was an error loading comments. Please retry.
                    </Stack>
                )}
                <FocusZone
                    disabled={uiState.currentUiState === CommentsCurrentType.AddingNewConversation}
                    handleTabKey={FocusZoneTabbableElements.all} //enable tabbing
                    shouldEnterInnerZone={handleShouldEnterInnerZone} //skipping focus for certain elements based on key press
                    preventFocusRestoration={true} //do not refocus after rerender
                >
                    {!props.isLoadingItems && (
                        <List
                            componentRef={listRef}
                            items={props.items}
                            onRenderCell={onRenderCell}
                            aria-label={commentListAriaLabelText}
                            onPagesUpdated={handleOnPagesUpdated} //refocus card after render
                            //if we have a highlighted comment, we disable virtualization to enable scrolling to the highlighted comment
                            onShouldVirtualize={() => !uiState.highlightedCommentId}
                        />
                    )}
                </FocusZone>
            </Stack>
            <DeleteCommentConfirmationDialog
                show={uiState.showDeleteDialog}
                onClose={handleCancelDelete}
                onDelete={handleCommentDelete}
                hasReplies={uiState.hasReplies}
            />
        </OverlaySpinner>
    );
};
