import { IContextualMenuItem, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterItem } from '../../../data-types/filterItem';
import { useContextualMenuWithSearchBox } from '../../../hooks/useContextualMenuWithSearchBox';
import { roleMenu } from '../../../utils/Filter.constant';
import IconButtonWithContextualMenu from '../../common/IconButtonWithContextualMenu';
import { getButtonStyles } from '../../filters/filter-button/FilterButton';

export interface IScrollToSectionDropdown {
    sections: IFilterItem[];
    label?: string;
    filterName: string;
    showSingleSelect?: boolean;
    isFiltered: boolean;
    isDisabled: boolean;
    onButtonClick?: (filterName: string) => void;
    onSectionChange: (filteredItems: IFilterItem[], filterName: string) => void;
}

const ScrollToSectionDropdown: React.FC<IScrollToSectionDropdown> = ({
    sections,
    label,
    filterName,
    isFiltered,
    isDisabled,
    onSectionChange,
}) => {
    const { t } = useTranslation();
    const { filterItemWrapperStyles } = getButtonStyles();
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[] | undefined>(undefined);
    const { contextualMenuObject } = useContextualMenuWithSearchBox({
        filterMenuItems: sections,
        filterName,
        iconProps: null,
        itemWrapperClassName: filterItemWrapperStyles,
        onMenuItemClick: onSectionChange,
        searchItemName: 'section',
        isFiltered,
        noResultsText: t('noSectionsFound'),
        viewAllText: t('viewAll'),
        searchPlaceholderText: t('search'),
        menuItems,
        setMenuItems,
        filterMenuAriaLabel: t('goToSection').toString(),
        menuListRole: roleMenu,
    });

    const buttonId: string = `${filterName}-button`;

    return (
        <Stack>
            <IconButtonWithContextualMenu
                menuProps={contextualMenuObject}
                buttonId={buttonId}
                label={t(label || filterName)}
                isDisabled={isDisabled}
            />
        </Stack>
    );
};

export default ScrollToSectionDropdown;
