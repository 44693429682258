import * as React from 'react';
import { useGetFunctionalAccess } from '../api/functional-access/functional-access';
import { FunctionalAccessDefinition } from '../model';

export interface IFunctionalAccessValues {
    functionalAccess?: FunctionalAccessDefinition;
    isFetchingAccess?: boolean;
}

const defaultFunctionalAccess: FunctionalAccessDefinition = {
    createFolderButtonEnabled: false,
    deleteFolderButtonEnabled: false,
    editFolderButtonEnabled: false,
    folderDropdownEnabled: false,
};

export const FunctionalAccessContext = React.createContext<IFunctionalAccessValues | null>(null);

export function FunctionalAccessContextProvider(props: { value?: IFunctionalAccessValues; children?: React.ReactNode }) {
    const { data: functionalAccessResponse, isFetching } = useGetFunctionalAccess();
    const functionalAccessFromApi = isFetching ? defaultFunctionalAccess : functionalAccessResponse;

    return (
        <FunctionalAccessContext.Provider
            value={props.value ?? { functionalAccess: functionalAccessFromApi, isFetchingAccess: isFetching }}
        >
            {props.children}
        </FunctionalAccessContext.Provider>
    );
}
