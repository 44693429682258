import { Stack } from '@fluentui/react';
import { ColumnDirective, ColumnsDirective, Freeze, Inject, Resize } from '@syncfusion/ej2-react-grids';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICalculationOptions, IWhitePaperColumn } from '../../../model';
import DefaultTooltipHost from '../../common/DefaultTooltipHost';
import { StyledTable } from '../StyledTable';

export interface ICalculationOptionsPanelProps {
    calculationOptions: ICalculationOptions[];
    columns?: IWhitePaperColumn[] | null;
}

export const CalculationOptionsTable = ({ calculationOptions, columns }: ICalculationOptionsPanelProps) => {
    const { t } = useTranslation();
    const hasCalculationOptions = calculationOptions?.length;

    const tableContainerStackStyles: React.CSSProperties = {
        height: '75vh',
    };

    const columnHeaderTemplate = (column: IWhitePaperColumn) => {
        return (
            <DefaultTooltipHost tooltipId={column.columnId?.toString()} tooltipContent={t(column.entityName || '')}>
                {column.entityName?.toString()}
            </DefaultTooltipHost>
        );
    };

    const CalculationOptionsTable = () => (
        <Stack.Item style={tableContainerStackStyles}>
            <StyledTable
                dataSource={calculationOptions}
                rowHeight={45}
                height={'100%'}
                key={'name'}
                data-testid='calculationOptionsTestId'
                aria-busy={!!hasCalculationOptions}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        field='name'
                        headerTemplate={() => <label aria-label={t('optionName').toString()}>{t('name').toString()}</label>}
                        autoFit
                        isPrimaryKey={true}
                        freeze='Left'
                    />
                    {columns?.map((column) =>
                        column.fieldName ? (
                            <ColumnDirective
                                autoFit
                                textAlign='Center'
                                key={column.fieldName}
                                field={column.fieldName}
                                headerTemplate={() => columnHeaderTemplate(column)}
                                template={(option: ICalculationOptions) =>
                                    column.fieldName && option?.value?.[column.fieldName]?.current
                                        ? option?.value?.[column.fieldName]?.current
                                        : null
                                }
                                maxWidth={columns.length > 1 ? '100%' : 'auto'}
                            />
                        ) : null
                    )}
                </ColumnsDirective>
                <Inject services={[Freeze, Resize]} />
            </StyledTable>
        </Stack.Item>
    );

    const emptyOptionsStack = () => <Stack aria-label={t('noOptionsCanBeDisplayed').toString()}>{t('noOptionsCanBeDisplayed')}</Stack>;

    return <Stack>{hasCalculationOptions ? <CalculationOptionsTable /> : emptyOptionsStack()}</Stack>;
};

export default CalculationOptionsTable;
