import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import {
    Column,
    GridActionEventArgs,
    GridComponent,
    GridModel,
    KeyboardEventArgs,
    QueryCellInfoEventArgs,
    ResizeArgs,
} from '@syncfusion/ej2-react-grids';
import { forwardRef, PropsWithChildren, useImperativeHandle, useRef } from 'react';
import { switchFocusToTooltipChild } from '../../utils/ReportUtils';

const DefaultGrid = forwardRef((props: PropsWithChildren<GridModel & DefaultHtmlAttributes>, ref: any) => {
    const defaultTableHeight = '100%';
    const gridRef = useRef<GridComponent>(null);
    const shouldKeepFocusedCellVisible = !props.enableColumnVirtualization;
    let focusUsingKeyboard = false;

    useImperativeHandle(ref, () => gridRef.current);

    const onResizeStop = (args: ResizeArgs) => {
        if (args.column) {
            args.column.autoFit = false;
        }

        if (props.resizeStop) props.resizeStop(args);
    };

    const onHandleCreated = () => {
        if (gridRef?.current) {
            gridRef.current.keyboardModule.keyConfigs['tab'] = '';
            gridRef.current.keyboardModule.keyConfigs['shiftTab'] = '';
        }

        if (props.created) props.created();
    };

    const onQueryCellInfo = (args: QueryCellInfoEventArgs) => {
        (args.cell as HTMLElement).onfocus = switchFocusToTooltipChild;

        if (props.queryCellInfo) props.queryCellInfo(args);
    };

    const setScrollToFocusedElement = (event: React.FocusEvent<any>) => {
        if (gridRef?.current && focusUsingKeyboard) {
            let frozenColumnsSize = 0;
            const cellMargin = 10;
            gridRef.current.getFrozenLeftColumns().forEach((column: Column) => {
                frozenColumnsSize += parseInt(column.width.toString());
            });
            const container = gridRef.current.getContent().firstElementChild;
            if (container && frozenColumnsSize) {
                container.scrollLeft = event.target.offsetLeft - frozenColumnsSize - cellMargin;
            }
            focusUsingKeyboard = false;
        }
    };
    const onFocus = (event: React.FocusEvent<any>) => {
        if (shouldKeepFocusedCellVisible) {
            setTimeout(() => {
                setScrollToFocusedElement(event);
            }, 10);
        }

        if (props.onFocus) props.onFocus(event);
    };

    const onHandleKeyPressed = (args: KeyboardEventArgs) => {
        if (shouldKeepFocusedCellVisible) {
            var gridcells = gridRef?.current?.element.querySelectorAll('.e-rowcell');
            const targetElement = args.target as HTMLElement;
            if (targetElement.classList.contains('e-headercell') && gridcells !== undefined) {
                gridcells[gridcells.length - 1].setAttribute('tabindex', '-1');
            }
            focusUsingKeyboard = true;
        }

        if (props.keyPressed) props.keyPressed(args);
    };

    const getTableHeight = (): string | number => {
        const searchResults = gridRef.current?.getRows()?.length ?? 0;

        if (searchResults === 0) {
            return props.height ?? defaultTableHeight;
        } else {
            return defaultTableHeight;
        }
    };

    const onDatabound = (args: GridActionEventArgs) => {
        if (gridRef.current) {
            const newHeight = getTableHeight();
            if (gridRef.current.height !== newHeight) {
                gridRef.current.height = getTableHeight();
            }
        }

        if (props.dataBound) props.dataBound(args);
    };

    return (
        <GridComponent
            statelessTemplates={['directiveTemplates']}
            {...props}
            ref={gridRef}
            height={getTableHeight()}
            resizeStop={onResizeStop}
            created={onHandleCreated}
            queryCellInfo={onQueryCellInfo}
            dataBound={onDatabound}
            onFocus={onFocus}
            keyPressed={onHandleKeyPressed}
        >
            {props.children}
        </GridComponent>
    );
});

export default DefaultGrid;
