import { DataManager } from '@syncfusion/ej2-data';
import { useMemo } from 'react';
import useVarianceThreshold from '../hooks/useVarianceThreshold';
import { IWhitePaperReport } from '../model';
import { getNonZeroRows, getOnlyExceedingRows } from './ReportUtils';
import { isUndefinedOrEmpty } from './StringUtils';

export const useDataManager = (
    currentReport: IWhitePaperReport | undefined,
    reportId: string,
    showOnlyExceedingRowsCookieValue: boolean = false
) => {
    const { varianceThresholdCookieValue } = useVarianceThreshold(reportId);

    return useMemo(() => {
        let rows = currentReport?.rows ?? [];

        if (showOnlyExceedingRowsCookieValue) {
            if (!isUndefinedOrEmpty(varianceThresholdCookieValue)) {
                rows = getOnlyExceedingRows(rows);
            } else {
                rows = getNonZeroRows(rows);
            }
        }

        return { dataManager: new DataManager(rows), contentLength: rows.length };
    }, [currentReport, showOnlyExceedingRowsCookieValue]);
};
