import { IAccountAmountRow } from '../../model';

export const accountAmountRowPropertyNames = {
    get accountBalanceType(): keyof IAccountAmountRow {
        return 'accountBalanceType';
    },
    get accountCode(): keyof IAccountAmountRow {
        return 'accountCode';
    },
    get accountDescription(): keyof IAccountAmountRow {
        return 'accountDescription';
    },
    get adjustedAmount(): keyof IAccountAmountRow {
        return 'adjustedAmount';
    },
    get adjustedTotal(): keyof IAccountAmountRow {
        return 'adjustedTotal';
    },
    get creditAmount(): keyof IAccountAmountRow {
        return 'creditAmount';
    },
    get creditTotal(): keyof IAccountAmountRow {
        return 'creditTotal';
    },
    get debitAmount(): keyof IAccountAmountRow {
        return 'debitAmount';
    },
    get debitTotal(): keyof IAccountAmountRow {
        return 'debitTotal';
    },
    get disabled(): keyof IAccountAmountRow {
        return 'disabled';
    },
    get combinedTotal(): keyof IAccountAmountRow {
        return 'combinedTotal';
    },
};
