import { useQueryClient } from '@tanstack/react-query';
import {
    useAddNewConversationToReportField,
    useAddNewReplyToConversation,
    useDeleteConversation,
    useDeleteReply,
    useGetConversationsForReportField,
} from '../api/collaboration/collaboration';

export const useCommentDataItemsForReportField = (reportId?: string, reportColumnName?: string, reportRowNumber?: string) => {
    const queryClient = useQueryClient();

    const {
        data: itemsField,
        isError: isErrorField,
        isLoading: isLoadingField,
        isFetching: isFetchingField,
        queryKey: commentQueryKeyField,
        refetch: refetchField,
        //orval created these as string types so we cannot pass undefined
    } = useGetConversationsForReportField(reportId ?? '0', reportColumnName ?? '', reportRowNumber ?? '0');

    const mutationHandler = {
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(commentQueryKeyField);
            },
        },
    };

    const { mutateAsync: mutateAsyncField } = useAddNewConversationToReportField(mutationHandler);

    const { mutateAsync: deleteConversationAsync } = useDeleteConversation(mutationHandler);

    const { mutateAsync: addReplyAsync } = useAddNewReplyToConversation(mutationHandler);

    const { mutateAsync: deleteReplyAsync } = useDeleteReply(mutationHandler);

    return {
        isLoadingItems: isLoadingField,
        isFetchingItems: isFetchingField,
        isErrorItems: isErrorField,
        items: itemsField?.conversations ?? [],
        errors: itemsField?.problemDetails?.title,
        mutateAsync: mutateAsyncField,
        deleteConversationAsync,
        addReplyAsync,
        deleteReplyAsync,
        refetchItems: refetchField,
        queryKey: commentQueryKeyField,
    };
};
