import { GridComponent, IIndex } from '@syncfusion/ej2-react-grids';
import { TFunction } from 'i18next';

import { ITaxReturnTab } from '../contexts/userSettingsContext';
import { IFilterItem } from '../data-types/filterItem';
import { IExecutionStatusStorage } from '../data-types/IExecutionStatusStorage';
import { IRowInfo } from '../data-types/IRowInfo';
import {
    CalculationExecutionStatus,
    CalculationExecutionStatusCode,
    IWhitePaperColumn,
    IWhitePaperComparedValue,
    IWhitePaperReport,
    IWhitePaperRow,
    Report,
    ReportListRequest,
    WhitePaperReportExportRequest,
    WhitePaperReportVersion,
} from '../model';
import { getColumnNames, SUBTOTAL_COLUMN_NAME } from './ColumnUtils';
import { getDateStringAsLocaleString } from './DateUtils';
import { formatNumberForDisplay, parseNumericValue } from './NumberUtils';
import { isUndefinedOrEmpty } from './StringUtils';
import {
    currentValueFieldNameSuffix,
    dynamicColumnsFieldNameBeginning,
    squareBracketsClosing,
    squareBracketsOpening,
    varianceValueFieldNameSuffix,
} from './Table.constants';
import { getCellValueForColumnInWhitePaperRow } from './TableUtils';

const MULTIPLE_REPORTS_EXPORT_LIMIT = 20;
export const CLICKABLE_COLUMN_START_INDEX = 2;

export const updateSubtotalByEntitiesValue = (rows: IWhitePaperRow[], columns: string[], treeGridRef: any) => {
    if (!columns.some((columnName) => columnName.includes(SUBTOTAL_COLUMN_NAME))) {
        return;
    }

    rows.forEach((row) => {
        if (row.values) {
            const value: any = row.values;
            let subtotalValue = 0;
            let subtotalColumnName = SUBTOTAL_COLUMN_NAME;
            columns.forEach((column) => {
                if (shouldAddColumnValueToSubtotal(value, column)) {
                    const numericValue = parseNumericValue(value[column]?.current);
                    subtotalValue += numericValue;
                } else if (column.includes(SUBTOTAL_COLUMN_NAME)) {
                    subtotalColumnName = column;
                }
            });

            value[subtotalColumnName].current = formatNumberForDisplay(subtotalValue);
        }
    });
    treeGridRef?.current?.clearSelection();
    treeGridRef?.current?.refresh();
};

export const shouldAddColumnValueToSubtotal = (value: any, columnName: string): boolean => {
    return value && columnName && !columnName.includes(SUBTOTAL_COLUMN_NAME) && value[columnName]?.current;
};

export const getVisibleEntityColumnNames = (filteredItems: IFilterItem[], columns: IWhitePaperColumn[]): string[] => {
    const filteredEntityNames = getColumnNames(filteredItems);
    return columns
        .filter((column) => column.entityName && filteredEntityNames.includes(column.entityName))
        .map((column) => column.fieldName ?? '');
};

export const getDynamicFieldName = (fieldName: string | undefined | null, sufixAttributeName: string): string => {
    if (!fieldName) {
        return '';
    }
    if (!sufixAttributeName && fieldName) {
        return fieldName;
    }
    return dynamicColumnsFieldNameBeginning + '.' + fieldName + sufixAttributeName;
};

export const getDynamicFieldNameParts = (fieldName: string) => {
    let cleanedName = fieldName.replace(dynamicColumnsFieldNameBeginning, '');

    if (cleanedName.startsWith('.')) {
        cleanedName = cleanedName.replace('.', '');
    }

    const parts = cleanedName.replace(squareBracketsOpening, '').replace(squareBracketsClosing, '').split('.');

    const isComplexValue = parts.length === 2;
    const entityCode = parts[0];
    const valueType = isComplexValue ? parts[1] : '';

    return { entityCode, valueType, isComplexValue };
};

export const isNegativeValue = (value: string | undefined): boolean => value?.startsWith('(') ?? false;

export const getCellValueByFieldName = (item: IWhitePaperRow, fieldName: string): string => {
    const { entityCode, valueType, isComplexValue } = getDynamicFieldNameParts(fieldName);
    if (isComplexValue) {
        return getNumericValue(item, entityCode, valueType);
    } else {
        const propertyKey = fieldName as keyof IWhitePaperRow;
        return item[propertyKey]?.toString() ?? '';
    }
};

const getNumericValue = (item: IWhitePaperRow, entityCode: string, valueType: string): string => {
    if (item.values) {
        const valuesForEntity = item.values[entityCode];
        const fieldNameAsKey = valueType as keyof IWhitePaperComparedValue;

        return valuesForEntity[fieldNameAsKey] as string;
    }

    return '';
};

export const getSelectedReportRequestsToExport = (
    selectedRecords: Report[] | undefined,
    translateFunction: TFunction<'translation', undefined, 'translation'>,
    compareVersionId?: string
): WhitePaperReportExportRequest[] => {
    if (selectedRecords && selectedRecords.length > 0 && selectedRecords.length <= MULTIPLE_REPORTS_EXPORT_LIMIT) {
        if (selectedRecords.every((report) => report.executed)) {
            return selectedRecords.map((report) => {
                return {
                    id: report.id ?? 0,
                    period: report.period ?? '',
                    entityCode: report.entityCode ?? '',
                    isCompare: compareVersionId !== undefined,
                    versionId: compareVersionId,
                };
            });
        } else {
            throw translateFunction('notExecutedReportSelectedForExportError');
        }
    } else if (selectedRecords && selectedRecords.length > MULTIPLE_REPORTS_EXPORT_LIMIT) {
        throw translateFunction('multipleReportsSelectedForExportError');
    } else {
        throw translateFunction('noReportsSelectedForExportError');
    }
};

export const getExportFileNameBasedOnSelectedReports = (
    selectedRecords: Report[] | undefined,
    fileExtension: string,
    translateFunction: TFunction<'translation', undefined, 'translation'>,
    isTaxReturnList?: boolean
) => {
    if (!isTaxReturnList && selectedRecords) {
        if (selectedRecords.length === 1) {
            return `${selectedRecords[0].name}${fileExtension}`;
        } else {
            const currentDate = new Date().toLocaleDateString(undefined, { dateStyle: 'short' });
            return `${translateFunction('reportExport')} - ${currentDate}${fileExtension}`;
        }
    }

    return `${translateFunction('taxReturns')}${fileExtension}`;
};

export const getOnlyExceedingRows = (rows: IWhitePaperRow[]): IWhitePaperRow[] => {
    return rows.filter((row) => {
        const values = row.values ?? [];
        for (const [, value] of Object.entries<IWhitePaperComparedValue>(values)) {
            if (value.exceedsVariance) return true;
        }

        return false;
    });
};

export const getNonZeroRows = (rows: IWhitePaperRow[]): IWhitePaperRow[] => {
    return rows.filter((row) => {
        const values = row.values ?? [];
        for (const [, value] of Object.entries<IWhitePaperComparedValue>(values)) {
            if (!isUndefinedOrEmpty(value.variance) && !isZeroValue(value.variance ?? '')) return true;
        }

        return false;
    });
};

const isZeroValue = (value: string) => {
    return parseNumericValue(value.replace('%', '')) === 0;
};

export const updateEmptyRowTextInGrid = (grid: GridComponent, emptyRowText: string): void => {
    const emptyRowElement = grid.getContent().querySelector('.e-emptyrow')?.firstElementChild;

    if (emptyRowElement) {
        emptyRowElement.innerHTML = emptyRowText;
    }
};

export const parseRowValuesToNumber = (row: IWhitePaperRow) => {
    for (const entity in row.values) {
        const currentEntityValue = row.values[entity];
        const valueAsNumber = parseNumericValue(currentEntityValue.current ?? '');

        if (currentEntityValue.current) {
            currentEntityValue.current = valueAsNumber.toString();
        }
    }
};

export const formatEditableRowValues = (row: IWhitePaperRow) => {
    for (const entity in row.values) {
        const currentEntityValue = row.values[entity];
        const valueAsNumber = parseNumericValue(currentEntityValue.current ?? '');

        if (currentEntityValue.current) {
            const formattedNumber = formatNumberForDisplay(valueAsNumber);
            currentEntityValue.current = formattedNumber;
        }
    }
};

export const markModifiedValues: (row: IWhitePaperRow, previousData: IWhitePaperRow) => boolean = (
    row: IWhitePaperRow,
    previousData: IWhitePaperRow
) => {
    let isRowModified = false;
    if (row && row.values && previousData && previousData.values) {
        for (const entity in row.values) {
            const currentEntityValue: IWhitePaperComparedValue = row.values[entity];
            const previousEntityvalue: IWhitePaperComparedValue = previousData.values[entity];
            const currentValueAsNumber: number = parseNumericValue(currentEntityValue.current ?? '');
            const previousValueAsNumber: number = parseNumericValue(previousEntityvalue.current ?? '');

            currentEntityValue.isOverridden = currentValueAsNumber !== previousValueAsNumber;
            isRowModified = isRowModified || currentEntityValue.isOverridden;
        }
    }
    return isRowModified;
};

export const shouldReportBeOnTopOfRecentlyOpened = (report: Report | undefined, recentlyOpened: ITaxReturnTab[] | undefined): boolean => {
    const reportId = Number(report?.id);
    const result: boolean =
        reportId > 0 && recentlyOpened !== undefined && recentlyOpened.length > 0 && recentlyOpened[0].returnKey !== reportId;
    return result;
};

export const getReportPropertyValueByFieldName = (report: Report, fieldName: string): string => {
    const reportPropertyKey = fieldName as keyof Report;

    return report[reportPropertyKey]?.toString() ?? '';
};

export const getColumnFieldNameByIndex = (index: number, currentReport?: IWhitePaperReport): string => {
    if (!currentReport?.columns) {
        return '';
    }

    if (index < 0 || currentReport.columns.length <= index) {
        return '';
    }

    return currentReport.columns[index].fieldName ?? '';
};

export const scrollToRowAndSetFocusCell = (
    setIsVarianceNavigating: (isNavigating: boolean) => void,
    setCurrentVarianceIndex: (step: number) => void,
    scrollFunction: (gridRef: GridComponent, index: IIndex) => void,
    cellIndex: IIndex,
    step: number,
    grid?: GridComponent
) => {
    if (grid) {
        setIsVarianceNavigating(true);
        scrollFunction(grid, cellIndex);
        setCurrentVarianceIndex(step);
    }
};

const isOriginalColumnIdGreaterThanZero = (column: IWhitePaperColumn) => (!column?.originalColumnId ? false : column?.originalColumnId > 0);

export const isDrillDownContextMenuAllowed = (
    cellIndex: IRowInfo<IWhitePaperRow>,
    isMultiColumnReport: Boolean,
    currentReport: IWhitePaperReport | undefined,
    isDrillDownAllowed: (cellIndex?: IIndex) => boolean
) => {
    if ((cellIndex?.rowData as any)?.['definition'] && isDrillDownAllowed?.(cellIndex) && currentReport?.columns?.length) {
        const currentColumnName = cellIndex.column?.headerText?.replace(' Current', '')?.toLocaleLowerCase();
        const column: IWhitePaperColumn | undefined = currentReport.columns.find(
            (col) => col.entityName?.toLocaleLowerCase() === currentColumnName
        );

        return column && (!isMultiColumnReport || isOriginalColumnIdGreaterThanZero(column));
    }
    return false;
};

export const getReportVersionLabelText = (version: WhitePaperReportVersion): string => {
    return `${getDateStringAsLocaleString(version.dateTime, true)} - ${version.executedBy}`;
};

export function isEditAllowedForCell(isMultiColumns: boolean, cellIndex: IIndex): boolean {
    let canEdit: boolean = false;
    if (isMultiColumns) {
        canEdit =
            (cellIndex.cellIndex ?? 0) - 1 === CLICKABLE_COLUMN_START_INDEX ||
            (cellIndex.cellIndex ?? 0) - 2 > CLICKABLE_COLUMN_START_INDEX;
    } else {
        canEdit = isMenuEnabledForTableColumn(cellIndex.cellIndex ?? 0);
    }
    return canEdit;
}

export const isMenuEnabledForTableColumn = (cellIndex: number): boolean => {
    return cellIndex >= CLICKABLE_COLUMN_START_INDEX;
};

export function getReturnRequest(
    columnFilters: Record<string, IFilterItem[]>,
    folderKey: number | undefined,
    entityFilterKey: string,
    jurisdictionFilterKey: string,
    periodFilterKey: string,
    caseFilterKey: string
): ReportListRequest {
    const filter = {
        selectedEntities: columnFilters[entityFilterKey]?.map((x) => x.key) ?? [],
        selectedJurisdictions: columnFilters[jurisdictionFilterKey]?.map((x) => x.key) ?? [],
        selectedPeriods: columnFilters[periodFilterKey]?.map((x) => x.key) ?? [],
        selectedCases: columnFilters[caseFilterKey]?.map((x) => x.key) ?? [],
    };

    if (folderKey) {
        return {
            ...filter,
            folderKey: folderKey,
        };
    }

    return filter;
}

export const shouldFetch = (currentReportInfo: Report | undefined, currentReturnStatus: CalculationExecutionStatus) =>
    currentReportInfo?.executed || currentReturnStatus.statusCode === CalculationExecutionStatusCode.Completed;

export const shouldExecuteRerun = (
    currentReportInfo: Report,
    isReportExecuting: boolean,
    isLoadingRecalculation: boolean,
    currentReturnStatus: IExecutionStatusStorage
): boolean => {
    return (
        !currentReportInfo.executed &&
        (!isReportExecuting || !isLoadingRecalculation) &&
        currentReturnStatus.statusCode !== CalculationExecutionStatusCode.Completed
    );
};

export const getRecentlyOpenedTabsWithStatus = (
    calculationExecutionStatus: CalculationExecutionStatus[],
    recentlyOpenedTabs: ITaxReturnTab[] | undefined
): ITaxReturnTab[] => {
    if (recentlyOpenedTabs) {
        return recentlyOpenedTabs.map((item) => {
            let statusCode: CalculationExecutionStatusCode = CalculationExecutionStatusCode.Unknown;
            const index = calculationExecutionStatus.findIndex((i) => i.taxReturnItemKey === item.returnKey);
            if (index >= 0) {
                statusCode = calculationExecutionStatus[index].statusCode ?? CalculationExecutionStatusCode.Unknown;
            }

            return {
                ...item,
                statusCode,
            };
        });
    }

    return [];
};

export const switchFocusToTooltipChild = (event: FocusEvent) => {
    const tooltipChild = (event.target as HTMLElement).querySelector('.ms-TooltipHost') as HTMLElement;

    if (tooltipChild) {
        const tooltipContent = tooltipChild.firstElementChild as HTMLElement;

        if (tooltipChild.offsetWidth < tooltipContent.offsetWidth) {
            tooltipContent.focus();
        }
    }
};

export const isMultiColumnReport = (report: IWhitePaperReport | undefined): boolean => {
    if (report?.columns) {
        return report.columns.length > 1;
    }
    return false;
};

const getColumnIdFromFieldName = (fieldName: string): string => {
    if (!fieldName.includes('.')) {
        return fieldName;
    }
    const { entityCode } = getDynamicFieldNameParts(fieldName);
    return entityCode;
};
const isVarianceColumn = (columnFieldName: string): boolean => {
    return columnFieldName?.endsWith(varianceValueFieldNameSuffix) ?? false;
};
const isCurrentValueColumn = (columnFieldName: string): boolean => {
    return columnFieldName?.endsWith(currentValueFieldNameSuffix) ?? false;
};

export const getCellHighlightingClasses: (data: IWhitePaperRow, field: string) => { add: string[]; remove: string[] } = (
    data: IWhitePaperRow,
    field: string
) => {
    const addClasses: string[] = [];
    const removeClasses: string[] = [];
    const cellWasChangedByDrilldown = getCellValueForColumnInWhitePaperRow<boolean>(
        data,
        getColumnIdFromFieldName(field ?? ''),
        (rowCellValue: IWhitePaperComparedValue) => rowCellValue!.isChangedByDrilldown!
    );
    if (cellWasChangedByDrilldown && isCurrentValueColumn(field)) {
        addClasses.push('recalc-needed');
    } else {
        removeClasses.push('recalc-needed');
    }

    const cellWasOverridden = getCellValueForColumnInWhitePaperRow<boolean>(
        data,
        getColumnIdFromFieldName(field ?? ''),
        (rowCellValue: IWhitePaperComparedValue) => rowCellValue!.isOverridden!
    );
    if (cellWasOverridden && isCurrentValueColumn(field)) {
        addClasses.push('highlighted-cell');
    } else {
        removeClasses.push('highlighted-cell');
    }
    const exceedsVariance = getCellValueForColumnInWhitePaperRow<boolean>(
        data,
        getColumnIdFromFieldName(field ?? ''),
        (rowCellValue: IWhitePaperComparedValue) => rowCellValue!.exceedsVariance!
    );
    if (exceedsVariance && isVarianceColumn(field)) {
        addClasses.push('white-paper-report-highlight');
    } else {
        removeClasses.push('white-paper-report-highlight');
    }
    return { add: addClasses, remove: removeClasses };
};

export const convertBlobToBase64 = (blob: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            try {
                if (reader.result) {
                    const base64String = reader.result as string;
                    return resolve(base64String.split(',')[1]);
                } else {
                    return reject(`Error converting blob to base64: ' + ${reader.error?.message}`);
                }
            } catch (error) {
                return reject('Malformed base64 string');
            }
        };

        reader.onerror = () => reject(`Error converting blob to base64`);
        reader.readAsDataURL(blob);
    });
};
