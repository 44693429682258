import { Combobox, Label, Option, OptionGroup, useId } from '@fluentui/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnterAmountsDialogComboboxData } from '../../hooks/useEnterAmountsDialogComboboxData';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { IJurisdiction } from '../../model';
import {
    EnterAmountsDialogFiltersActionType,
    IEnterAmountsDialogComboboxPropsBase,
    IEnterAmountsDialogFilterJurisdictionChangeAction,
} from '../../reducers/enterAmountsDialogFiltersReducer';
import { comboBoxCheckIconStyles } from './EnterAmountsDialogFilters';

interface IJurisdictionComboboxOptionProps {
    jurisdiction: IJurisdiction;
    optionToSelectedOption: (jurisdiction: IJurisdiction) => string;
    optionToInputValue: (jurisdiction: IJurisdiction) => string;
}
const JurisdictionComboboxOption: FC<IJurisdictionComboboxOptionProps> = (props) => {
    const { jurisdiction, optionToSelectedOption, optionToInputValue } = props;
    const hiddenCheckBoxCssClass = comboBoxCheckIconStyles().hidden;
    return (
        <Option
            key={optionToSelectedOption(jurisdiction)}
            className={hiddenCheckBoxCssClass}
            value={optionToSelectedOption(jurisdiction)}
            text={optionToInputValue(jurisdiction)}
            aria-label={optionToInputValue(jurisdiction)}
        >
            {optionToInputValue(jurisdiction)}
        </Option>
    );
};

export interface IEnterAmountsDialogJurisdictionComboboxProps
    extends IEnterAmountsDialogComboboxPropsBase<IJurisdiction, IEnterAmountsDialogFilterJurisdictionChangeAction> {}

export const JurisdictionCombobox: FC<IEnterAmountsDialogJurisdictionComboboxProps> = (
    props: IEnterAmountsDialogJurisdictionComboboxProps
) => {
    const jurisdictionLabelId: string = useId('jurisdiction');
    const { comboboxStyles } = useEnterAmountsDialogStyles();
    const { t } = useTranslation();
    const optionToSelectedOption: (option: IJurisdiction) => string = (option: IJurisdiction) => option.code!;
    const optionToInputValue: (option: IJurisdiction) => string = (option: IJurisdiction) => `${option.code} - ${option.name}`;
    const findOptionsByDisplayValue: (displayValue: string, options: IJurisdiction[]) => IJurisdiction[] = (
        displayValue: string,
        options: IJurisdiction[]
    ) =>
        options.filter((jurisdiction: IJurisdiction) =>
            `${jurisdiction.code} - ${jurisdiction.name}`.toLowerCase().includes(displayValue.toLowerCase())
        );
    const findOptionByKey: (key: string | undefined, options: IJurisdiction[]) => IJurisdiction | undefined = (
        key: string | undefined,
        options: IJurisdiction[]
    ) => options.find((jurisdiction: IJurisdiction) => jurisdiction.code === key);
    const optionRenderer: (jurisdiction: IJurisdiction) => JSX.Element = (jurisdiction: IJurisdiction) => (
        <JurisdictionComboboxOption
            key={optionToSelectedOption(jurisdiction)}
            jurisdiction={jurisdiction}
            optionToSelectedOption={optionToSelectedOption}
            optionToInputValue={optionToInputValue}
        />
    );
    const { inputValue, selectedOptions, matchingOptions, searchText, onOptionSelect, onBlur, onChange, disabled, onKeyDown, comboboxRef } =
        useEnterAmountsDialogComboboxData<
            IJurisdiction,
            IEnterAmountsDialogFilterJurisdictionChangeAction,
            IEnterAmountsDialogJurisdictionComboboxProps
        >(
            props,
            {
                optionToInputValue,
                optionToSelectedOption,
                findOptionsByDisplayValue,
                findOptionByKey,
                optionRenderer: optionRenderer,
            },
            EnterAmountsDialogFiltersActionType.JurisdictionChanged
        );
    return (
        <div>
            <Label id={jurisdictionLabelId} weight='semibold'>
                {t('jurisdiction')}
            </Label>
            <Combobox
                className={comboboxStyles}
                aria-labelledby={jurisdictionLabelId}
                value={inputValue}
                selectedOptions={selectedOptions}
                onOptionSelect={onOptionSelect}
                disabled={disabled}
                freeform
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                ref={comboboxRef}
                clearable={props.clearable}
            >
                {searchText ? (
                    <Option aria-disabled='true' key='freeform' text={searchText} disabled={true}>
                        {t('noOptionsFound', { searchText: searchText })}
                    </Option>
                ) : (
                    <OptionGroup>{matchingOptions}</OptionGroup>
                )}
            </Combobox>
        </div>
    );
};
