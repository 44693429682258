import { SanitizeHtmlHelper } from '@syncfusion/ej2-base';
import styled from 'styled-components';

export interface ICommentRichTextViewerProps {
    commentHtml?: string | null;
}

// mimic the style in the editor that we get from SF (at least for now) with bonus italic flair
const Wrapper = styled.div`
    .e-mention-chip {
        font-style: italic;
        background: #f3f2f1;
        border-radius: 2px;
        border: none;
        color: #003b5c;
        cursor: default;
    }
`;

export const CommentRichTextViewer = (props: ICommentRichTextViewerProps) => {
    if (props.commentHtml) {
        let sanitizedHtml = SanitizeHtmlHelper.sanitize(props.commentHtml);
        return <Wrapper dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    }

    return null;
};
