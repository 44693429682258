import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

const applicationInsightsReactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | undefined;

const initializeAppInsights = (connectionString: string | undefined, environmentName: string, buildNumber: string) => {
    if (connectionString && !appInsights) {
        appInsights = new ApplicationInsights({
            config: {
                connectionString,
                correlationHeaderExcludedDomains: ['cloud.corptax.com'],
                extensions: [applicationInsightsReactPlugin],
                enableAutoRouteTracking: true,
                disableAjaxTracking: false,
                autoTrackPageVisitTime: true,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                disableFlushOnBeforeUnload: true,
            },
        });
        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
            env.tags = env.tags || [];
            env.tags['ai.cloud.role'] = 'testTag';
            env.data = env.data || {};
            env.data['environmentName'] = environmentName;
            env.data['buildVersion'] = buildNumber;
        });
    }
};

export { applicationInsightsReactPlugin, initializeAppInsights, appInsights };
