import { IAccountAmountWhitePaperRow } from '../components/sidebar/ReportDrillDownUtils';
import { AllowUndefined, AllowUndefinedAndNull } from '../data-types/AllowUndefinedAndNull';
import {
    IReportColumnContext,
    IWhitePaperReportCellChange,
    IWhitePaperReportCellChanges,
    IWhitePaperReportCellChangesSummary,
    IWhitePaperReportChanges,
    IWhitePaperReportColumnChanges,
    IWhitePaperReportRowChanges,
    Report,
} from '../model';

interface IAccountAmountWhitePaperRowCellChange
    extends Pick<IAccountAmountWhitePaperRow, 'account' | 'adjustmentCode' | 'case' | 'entity' | 'rowNumber' | 'jurisdiction' | 'year'> {}

export function addDrillDownAmountCellToChangeTrackingData(
    report: Report,
    reportRowNumber: number,
    reportColumnId: number,
    accountAmountWhitePaperRow: IAccountAmountWhitePaperRow,
    changesTrackingData: IWhitePaperReportChanges
): IWhitePaperReportChanges {
    const updatedChangesTrackingData = Object.assign({}, changesTrackingData ?? {});
    const isAdjustmentEntry = accountAmountWhitePaperRow.dataObjectType === 'AdjustmentEntry';
    const cellChangeInfo: IAccountAmountWhitePaperRowCellChange = {
        account: accountAmountWhitePaperRow.account,
        adjustmentCode: (isAdjustmentEntry
            ? accountAmountWhitePaperRow.parent!.adjustmentCode
            : accountAmountWhitePaperRow.adjustmentCode)!,
        case: (isAdjustmentEntry ? accountAmountWhitePaperRow.case : report.case)!,
        entity: (isAdjustmentEntry ? accountAmountWhitePaperRow.entity : report.entityCode)!,
        jurisdiction: (isAdjustmentEntry ? accountAmountWhitePaperRow.jurisdiction : report.jurisdiction)!,
        rowNumber: accountAmountWhitePaperRow.rowNumber,
        year: (isAdjustmentEntry ? accountAmountWhitePaperRow.year : report.year!.toString())!,
    };

    updateTrackingData(reportRowNumber, reportColumnId, cellChangeInfo, updatedChangesTrackingData);
    return updatedChangesTrackingData;
}

export function addEnterAmountsUpdatedAccountsToTrackingData(
    reportRowNumber: number,
    reportColumnId: number,
    definingRowNumber: number,
    context: IReportColumnContext,
    adjustment: string,
    accounts: string[],
    changesTrackingData: IWhitePaperReportChanges
): IWhitePaperReportChanges {
    const updatedChangesTrackingData = Object.assign({}, changesTrackingData ?? {});

    for (const account of accounts) {
        const cellChangeInfo: IAccountAmountWhitePaperRowCellChange = {
            account: account,
            adjustmentCode: adjustment,
            case: context.case!,
            entity: context.entity!,
            jurisdiction: context.jurisdiction!,
            rowNumber: definingRowNumber.toString(),
            year: context.year!.toString(),
        };

        updateTrackingData(reportRowNumber, reportColumnId, cellChangeInfo, updatedChangesTrackingData);
    }

    return updatedChangesTrackingData;
}

export function addReportCellToChangeTrackingData(
    reportRowNumber: number,
    reportColumnId: number,
    changesTrackingData: IWhitePaperReportChanges
): IWhitePaperReportChanges {
    const updatedChangesTrackingData = Object.assign({}, changesTrackingData ?? {});

    updateTrackingData(reportRowNumber, reportColumnId, undefined, updatedChangesTrackingData);
    return updatedChangesTrackingData;
}

export function findDrillDownAmountCellChanges(
    reportRowNumber: number,
    reportColumnId: number,
    definingRowNumber: number,
    changesTrackingData: IWhitePaperReportChanges
): IWhitePaperReportCellChangesSummary[] {
    const definingRowNumberAsString = definingRowNumber.toString();
    const rowChanges: AllowUndefined<IWhitePaperReportRowChanges> = changesTrackingData.rowChanges;
    const columnChanges: AllowUndefined<IWhitePaperReportColumnChanges> = rowChanges?.[reportRowNumber];
    const cellChangesSummaries: AllowUndefined<IWhitePaperReportCellChangesSummary[]> = columnChanges?.[reportColumnId];
    const applicableCellChangesSummaries: AllowUndefined<IWhitePaperReportCellChangesSummary[]> = cellChangesSummaries?.filter(
        (cellChangesSummary: IWhitePaperReportCellChangesSummary) => cellChangesSummary.definingRowNumber === definingRowNumberAsString
    );

    return applicableCellChangesSummaries ?? [];
}

export function mergeChangeTrackingData(
    changeTrackingData1: IWhitePaperReportChanges,
    changeTrackingData2: IWhitePaperReportChanges
): IWhitePaperReportChanges {
    const newTrackingData: IWhitePaperReportChanges = {};

    for (const curentChangesTrackingData of [changeTrackingData1, changeTrackingData2]) {
        if (!curentChangesTrackingData.rowChanges) {
            continue;
        }

        for (const rowNumberAsString in curentChangesTrackingData.rowChanges) {
            const rowColumnChanges: IWhitePaperReportColumnChanges = curentChangesTrackingData.rowChanges[rowNumberAsString];
            const rowNumber: number = Number.parseInt(rowNumberAsString);

            for (const columnIdAsString in rowColumnChanges) {
                const columnChanges: AllowUndefined<IWhitePaperReportCellChangesSummary[]> = rowColumnChanges[columnIdAsString];
                const columnId: number = Number.parseInt(columnIdAsString);

                if ((columnChanges?.length ?? 0) === 0) {
                    updateTrackingData(rowNumber, columnId, undefined, newTrackingData);
                } else {
                    for (const amountChange of columnChanges) {
                        for (const updateChange of amountChange.changes!.update!) {
                            const context: IReportColumnContext = updateChange.context!;
                            const accountAmountRowInfo: IAccountAmountWhitePaperRowCellChange = {
                                account: amountChange.account!,
                                adjustmentCode: amountChange.adjustment!,
                                case: context.case!,
                                entity: context.entity!,
                                rowNumber: amountChange.definingRowNumber!,
                                jurisdiction: context.jurisdiction!,
                                year: context.year!.toString(),
                            };

                            updateTrackingData(rowNumber, columnId, accountAmountRowInfo, newTrackingData);
                        }
                    }
                }
            }
        }
    }

    return newTrackingData;
}

function updateTrackingData(
    reportRowNumber: number,
    reportColumnId: number,
    accountAmountRowInfo: AllowUndefined<IAccountAmountWhitePaperRowCellChange>,
    changesTrackingData: IWhitePaperReportChanges
) {
    let rowChanges: AllowUndefined<IWhitePaperReportRowChanges> = changesTrackingData.rowChanges;

    if (!rowChanges) {
        rowChanges = {};
        changesTrackingData.rowChanges = rowChanges;
    }

    let rowColumnChanges: AllowUndefined<IWhitePaperReportColumnChanges> = rowChanges[reportRowNumber];

    if (!rowColumnChanges) {
        rowColumnChanges = {};
        rowChanges[reportRowNumber] = rowColumnChanges;
    }

    let columnChanges: AllowUndefined<IWhitePaperReportCellChangesSummary[]> = rowColumnChanges[reportColumnId];

    if (!columnChanges) {
        columnChanges = [];
        rowColumnChanges[reportColumnId] = columnChanges;
    }

    if (!accountAmountRowInfo) {
        return changesTrackingData;
    }

    let amountChanges: AllowUndefined<IWhitePaperReportCellChangesSummary> = columnChanges.find(
        (cellChangeSummary) =>
            cellChangeSummary.account === accountAmountRowInfo.account &&
            cellChangeSummary.adjustment === accountAmountRowInfo.adjustmentCode &&
            cellChangeSummary.definingRowNumber === accountAmountRowInfo.rowNumber
    );

    if (!amountChanges) {
        amountChanges = {
            account: accountAmountRowInfo.account,
            adjustment: accountAmountRowInfo.adjustmentCode,
            definingRowNumber: accountAmountRowInfo.rowNumber,
        };
        columnChanges.push(amountChanges);
    }

    let cellChanges: AllowUndefined<IWhitePaperReportCellChanges> = amountChanges.changes;

    if (!cellChanges) {
        cellChanges = {};
        amountChanges.changes = cellChanges;
    }

    let updateChanges: AllowUndefinedAndNull<IWhitePaperReportCellChange[]> = cellChanges.update;

    if (!updateChanges) {
        updateChanges = [];
        cellChanges.update = updateChanges;
    }

    let updateChange: AllowUndefined<IWhitePaperReportCellChange> = updateChanges.find(
        (cellChange) =>
            cellChange.context!.case === accountAmountRowInfo.case &&
            cellChange.context!.entity === accountAmountRowInfo.entity &&
            cellChange.context!.jurisdiction === accountAmountRowInfo.jurisdiction &&
            cellChange.context!.year!.toString() === accountAmountRowInfo.year
    );

    if (!updateChange) {
        updateChange = {
            context: {
                case: accountAmountRowInfo.case,
                entity: accountAmountRowInfo.entity,
                jurisdiction: accountAmountRowInfo.jurisdiction,
                year: Number.parseInt(accountAmountRowInfo.year!),
            },
        };

        updateChanges.push(updateChange);
    }
}
