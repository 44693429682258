import { useCorptaxTheme } from '@corptax/react-components-common';
import { DefaultButton, DirectionalHint, ITooltipHostProps, mergeStyleSets, Stack, TooltipHost } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAppTheme } from '../../theme/IAppTheme';
import { getTooltipHostProps } from '../../utils/CustomStyles';

export interface IPdfFormNavigationProps {
    isFirstForm: boolean;
    isLastForm: boolean;
    onPreviousFormClick: () => void;
    onNextFormClick: () => void;
}

export const PdfFormNavigation: React.FC<IPdfFormNavigationProps> = ({ isFirstForm, isLastForm, onPreviousFormClick, onNextFormClick }) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const { buttonStyles, wrapperStyles } = mergeStyleSets({
        buttonStyles: {
            fontSize: '0.75rem',
            height: '1.5rem',
            color: customPalette.grey222222,
        },
        wrapperStyles: {
            padding: '0 0.5rem',
        },
    });

    const tooltipHostPreviousFormProps: Partial<ITooltipHostProps> = getTooltipHostProps(
        DirectionalHint.topCenter,
        undefined,
        customPalette
    );
    tooltipHostPreviousFormProps.content = t('previousForm').toString();
    tooltipHostPreviousFormProps.id = 'previousForm';

    const tooltipHostNextFormProps: Partial<ITooltipHostProps> = getTooltipHostProps(DirectionalHint.topCenter, undefined, customPalette);
    tooltipHostNextFormProps.content = t('nextForm').toString();
    tooltipHostNextFormProps.id = 'nextForm';

    return (
        <Stack className={wrapperStyles} horizontal verticalAlign='center' tokens={{ childrenGap: 5 }}>
            <TooltipHost {...tooltipHostPreviousFormProps}>
                <DefaultButton
                    text={t('previousForm').toString()}
                    className={buttonStyles}
                    ariaLabel={t('previousForm').toString()}
                    disabled={isFirstForm}
                    allowDisabledFocus
                    onClick={onPreviousFormClick}
                ></DefaultButton>
            </TooltipHost>
            <TooltipHost {...tooltipHostNextFormProps}>
                <DefaultButton
                    text={t('nextForm').toString()}
                    className={buttonStyles}
                    ariaLabel={t('nextForm').toString()}
                    disabled={isLastForm}
                    allowDisabledFocus
                    onClick={onNextFormClick}
                ></DefaultButton>
            </TooltipHost>
        </Stack>
    );
};
