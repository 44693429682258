export function mentionParser(html: string): Array<string> {
    const parser = new DOMParser();

    const document = parser.parseFromString(html, 'text/html');
    const mentionNodes = document.body.querySelectorAll('.e-mention-chip > span');
    if (mentionNodes) {
        let userArray: Array<string> = [];
        let mentions = Array.from(mentionNodes);
        mentions.forEach((mention) => {
            const userId = mention.getAttribute('data-id');
            if (userId) {
                userArray.push(userId);
            }
        });

        return userArray;
    }

    return [];
}
