import { IGroup } from '@fluentui/react';
import { KeyboardEvent as ReactKeyboardEvent, MouseEvent as ReactMouseEvent } from 'react';
import {
    arrowLeftKey,
    arrowRightKey,
    automationKeyAttribute,
    enterKey,
    expandCollapseClass,
    gridCellRole,
    rowRole,
} from './CardGridListConstants';
import { getGridGroupsExpandCollapseState, loadChildRows } from './CardGridListData';
import {
    ICardGridList,
    ICardGridListEventHandling,
    ICardGridListNotify,
    ICardGridListProps,
    ICardGridListRowData,
    IGroupExtended,
} from './CardGridListInterfaces';
import { CardGridListExpandCollapseState, Direction } from './CardGridListTypes';
import { getGridCellElements, getParentRowElement } from './CardGridListUtilities';

export function handleEventsUsing(
    props: ICardGridListProps,
    groupedListId: string,
    gridGroups: IGroupExtended[],
    dataSource: ICardGridListRowData[]
): ICardGridListEventHandling {
    return new CardGridListEventHandling(props, groupedListId, gridGroups, dataSource);
}

class CardGridListEventHandling implements ICardGridListEventHandling {
    private readonly _props: ICardGridListProps;
    private readonly _groupedListId: string;
    private readonly _gridGroups: IGroupExtended[];

    private _dataSource: ICardGridListRowData[];
    private _grid: ICardGridList | null;
    private _notify: ICardGridListNotify | null;

    constructor(props: ICardGridListProps, groupedListId: string, gridGroups: IGroupExtended[], dataSource: ICardGridListRowData[]) {
        this._props = props;
        this._groupedListId = groupedListId;
        this._gridGroups = gridGroups;
        this._dataSource = dataSource;
        this.collapseAll = this.handleCollapseAll;
        this.collapsibleRowDoubleClick = this.handleCollapsibleRowDoubleClick.bind(this);
        this.expandAll = this.handleExpandAll;
        this.expanderClick = this.handleExpanderClick.bind(this);
        this.groupedListKeyUp = this.handleGroupedListKeyUp.bind(this);
        this.groupedListKeyDown = this.handleGroupedListKeyDown.bind(this);
        this.rowExpandCollapseToggled = this.handleRowExpandCollapseToggled.bind(this);
        this._grid = null;

        const existingNotifyCallbacks = this._props.onExpandCollapseStateChange;

        if (existingNotifyCallbacks) {
            this._notify = {
                onExpandCollapseStateChange: existingNotifyCallbacks,
            } as ICardGridListNotify;
        } else {
            this._notify = null;
        }
    }

    public get expandCollapseState(): CardGridListExpandCollapseState {
        return getGridGroupsExpandCollapseState(this._gridGroups);
    }

    public set grid(value: ICardGridList) {
        this._grid = value;
    }

    public get notify(): ICardGridListNotify | null {
        return this._notify;
    }

    public set notify(value: ICardGridListNotify | null) {
        this._notify = value;
    }

    public readonly collapseAll: () => void;
    public readonly collapsibleRowDoubleClick: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
    public readonly expandAll: () => void;
    public readonly expanderClick: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
    public readonly groupedListKeyDown: (groupedListId: string, event: ReactKeyboardEvent<HTMLElement>) => void;
    public readonly groupedListKeyUp: (groupedListId: string, event: ReactKeyboardEvent<HTMLElement>, group?: IGroup) => void;
    public readonly rowExpandCollapseToggled: (group: IGroup) => void;

    private handleCollapseAll() {
        const groupedListRootElement: HTMLElement = document.getElementById(this._groupedListId) as HTMLElement;

        this.collapseAllGroupsAndDescedants(this._gridGroups, groupedListRootElement);
        this.onExpandCollapseStateChange(CardGridListExpandCollapseState.AllCollapsed);
    }

    private collapseAllGroupsAndDescedants(groups: IGroupExtended[], groupedListRootElement: HTMLElement) {
        for (const group of Object.values<IGroupExtended>(groups)) {
            if (group.hasChildren && group.childrenLoaded) {
                this.collapseAllGroupsAndDescedants(group.children as IGroupExtended[], groupedListRootElement);
            }

            if (!group.isCollapsed && !group.noExpandCollapse) {
                const gridRowElement: HTMLElement | null = groupedListRootElement.querySelector(`.${group.groupClassName}`);
                const expandCollapseButton: HTMLButtonElement | null = gridRowElement?.getElementsByTagName('button').item(0) ?? null;

                expandCollapseButton?.click();
            }
        }
    }

    private handleCollapsibleRowDoubleClick(event: ReactMouseEvent<HTMLDivElement, MouseEvent>): void {
        event.currentTarget.getElementsByTagName('button')[0].click();
        this.onExpandCollapseStateChange(this.expandCollapseState);
    }

    private handleExpandAll() {
        const groupedListRootElement: HTMLElement = document.getElementById(this._groupedListId) as HTMLElement;

        this.expandAllGroupsAndDescedants(this._gridGroups, groupedListRootElement);
        this.onExpandCollapseStateChange(CardGridListExpandCollapseState.AllExpanded);
    }

    private expandAllGroupsAndDescedants(groups: IGroupExtended[], groupedListRootElement: HTMLElement) {
        for (const group of Object.values<IGroupExtended>(groups)) {
            if (group.isCollapsed && !group.noExpandCollapse) {
                const gridRowElement: HTMLElement | null = groupedListRootElement.querySelector(`.${group.groupClassName}`);
                const expandCollapseButton: HTMLButtonElement | null = gridRowElement?.getElementsByTagName('button').item(0) ?? null;

                expandCollapseButton?.click();
            }

            if (group.hasChildren && group.childrenLoaded) {
                this.expandAllGroupsAndDescedants(group.children as IGroupExtended[], groupedListRootElement);
            }
        }
    }

    private handleExpanderClick(event: ReactMouseEvent<HTMLDivElement, MouseEvent>): void {
        event.currentTarget.parentElement?.parentElement?.parentElement?.parentElement?.getElementsByTagName('button')[0].click();
        this.onExpandCollapseStateChange(this.expandCollapseState);
    }

    private handleGroupedListKeyDown(groupedListId: string, event: ReactKeyboardEvent<HTMLElement>): void {
        if (event.key === arrowLeftKey || event.key === arrowRightKey) {
            event.preventDefault();
        }
    }

    private handleGroupedListKeyUp(groupedListId: string, event: ReactKeyboardEvent<HTMLElement>, group?: IGroup): void {
        const target: HTMLElement = event.target as HTMLElement;
        const stopDefaultAndPropagate = () => {
            event.preventDefault();
        };

        switch (event.key) {
            case arrowLeftKey:
                this.maybeMoveGridFocus(target, 'left', stopDefaultAndPropagate);
                break;

            case arrowRightKey:
                this.maybeMoveGridFocus(target, 'right', stopDefaultAndPropagate);
                break;

            case enterKey:
                this.maybeToggleRowExpandCollapse(groupedListId, target, stopDefaultAndPropagate, group);
                break;
        }
    }

    private handleRowExpandCollapseToggled(group: IGroup): void {
        const extendedGroup = group as IGroupExtended;

        // flip state to perform logic with group representing the post-toggled state
        extendedGroup.isCollapsed = !extendedGroup.isCollapsed;

        if (!extendedGroup.isCollapsed) {
            this._dataSource = loadChildRows(
                extendedGroup,
                this._dataSource,
                this._props.childDataPropertyNames!,
                this._props.onChildDataLoaded
            );
        }

        const groupedListRootElement: HTMLElement = document.getElementById(this._groupedListId) as HTMLElement;
        const gridRowElement: HTMLElement = groupedListRootElement.querySelector(`.${extendedGroup.groupClassName}`) as HTMLElement;
        const gridCellElements: HTMLElement[] = getGridCellElements(gridRowElement);

        gridCellElements[0].setAttribute('aria-expanded', group.isCollapsed ? 'false' : 'true');

        // revert state to pre-toggled state so fluent ui takes approrpiate action
        extendedGroup.isCollapsed = !extendedGroup.isCollapsed;
    }

    private onExpandCollapseStateChange(state: CardGridListExpandCollapseState) {
        if (this._notify?.onExpandCollapseStateChange) {
            this._notify.onExpandCollapseStateChange(state, this._grid!);
        }
    }

    private maybeMoveGridFocus(elementOfGrid: HTMLElement, direction: Direction, notifyIsFocusableElement: () => void): void {
        const role: string | null = elementOfGrid?.role;

        if (role === gridCellRole || role === rowRole) {
            notifyIsFocusableElement();

            if (role === gridCellRole) {
                const rowElement: HTMLElement | null = getParentRowElement(elementOfGrid);
                const dataAutomationKey: string | null = elementOfGrid.getAttribute(automationKeyAttribute);
                let index: number;

                if (rowElement && dataAutomationKey) {
                    const gridCellElements: HTMLElement[] = getGridCellElements(rowElement);
                    let focusElement: HTMLElement | null = null;

                    for (index = 0; index < gridCellElements.length; index++) {
                        if (
                            gridCellElements[index].getAttribute(automationKeyAttribute) === dataAutomationKey ||
                            gridCellElements[index].parentElement?.getAttribute(automationKeyAttribute) === dataAutomationKey
                        ) {
                            if (direction === 'left' && index > 0) {
                                focusElement = gridCellElements[index - 1];
                            } else if (direction === 'right' && index < gridCellElements.length - 1) {
                                focusElement = gridCellElements[index + 1];
                            }

                            break;
                        }
                    }

                    if (focusElement) {
                        elementOfGrid.tabIndex = -1;
                        focusElement.focus();
                        focusElement.tabIndex = 0;
                    }
                }
            }
        } else if (!!elementOfGrid) {
            this.maybeMoveGridFocus(elementOfGrid.parentElement as HTMLElement, direction, notifyIsFocusableElement);
        }
    }

    private maybeToggleRowExpandCollapse(
        groupedListId: string,
        elementOfGrid: HTMLElement,
        notifyWillToggle: () => void,
        group?: IGroup
    ): void {
        const role: string | null = elementOfGrid.role;

        if (role === gridCellRole || role === rowRole) {
            let expandCollapseIcon: HTMLDivElement | null = elementOfGrid.querySelector(`.${expandCollapseClass}`) as HTMLDivElement | null;

            if (expandCollapseIcon && group) {
                notifyWillToggle();
                expandCollapseIcon.click();

                setTimeout(() => {
                    const extendedGroup = group as IGroupExtended;
                    elementOfGrid = document
                        .getElementById(groupedListId)!
                        .querySelector(`.${extendedGroup!.groupClassName}`) as HTMLElement;

                    if (role === gridCellRole) {
                        elementOfGrid = getGridCellElements(elementOfGrid)[0];
                    }

                    // The following is a hack to restore the grid focused item outline after toggling expand/collapse
                    // as it does not necessarily restore in all cases by simply calling the element focus method.
                    expandCollapseIcon = elementOfGrid.querySelector(`.${expandCollapseClass}`) as HTMLDivElement;

                    const display: string = expandCollapseIcon.style.display;

                    expandCollapseIcon.style.display = 'none';
                    elementOfGrid.contentEditable = 'true';
                    elementOfGrid.focus();
                    elementOfGrid.contentEditable = 'false';
                    expandCollapseIcon.style.display = display;
                });
            }
        }
    }
}
