import { Panel, PanelType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { CommentsActionType } from '../../reducers/commentsStateReducer';
import { ConversationListReport } from '../comments/ConversationListReport';
import { ConversationListReportField } from '../comments/ConversationListReportField';
import { CommentsPanelConfirmCloseDialog } from './CommentsPanelConfirmCloseDialog';

// todo? is there something better to name this type?
export enum CommentsPanelLocationType {
    'Report',
    'ReportField',
}

export interface ICommentsPanelProps {
    locationType: CommentsPanelLocationType;
    reportId?: string;
    reportRowNumber?: number;
    reportColumnName?: string;
    reportName?: string;
    reportRowDescription?: string;
    isOpen: boolean;
    onClose?: () => void;
}

export const CommentsPanel = (props: ICommentsPanelProps) => {
    // the Panel will (may?) have a header eventually with filters and possible other behaviors
    // a Panel renders whether it's open or not, so if data calls live here, they run regardless, so we move those calls to the child component(s)

    const { t } = useTranslation();
    const headerText = t('comments').toString();
    const closeText = t('close').toString();
    const { state: uiState, dispatch: dispatchUIState } = useCommentsUI();
    const [isConfirmDialogVisible, { setTrue: showConfirmDialog, setFalse: hideConfirmDialog }] = useBoolean(false);

    useEffect(() => {
        if (!uiState.hasPendingChanges && !uiState.isSubmitting) {
            hideConfirmDialog();
        }
    }, [hideConfirmDialog, uiState.hasPendingChanges, uiState.isSubmitting]);

    if (!props.isOpen) {
        return null;
    }

    const handleDismiss = (hasConfirmedDialog: boolean = false) => {
        if (!hasConfirmedDialog && (uiState.hasPendingChanges || uiState.isSubmitting)) {
            showConfirmDialog();
            return;
        }
        // this needs to clean state even if pending changes, otherwise if you had one in progress it stays weird when you re-open the panel
        dispatchUIState({ type: CommentsActionType.CancelOperation });
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleConfirmDialogDiscard = () => {
        hideConfirmDialog();
        handleDismiss(true);
    };

    const handleConfirmDialogCancel = () => {
        hideConfirmDialog();
    };

    return (
        <>
            {/* onOuterClick will make the panel try to onDismiss, which is a problem when components like
        syncfusion render their DOM outside the component
        see https://github.com/microsoft/fluentui/issues/6476 */}
            <Panel
                onOuterClick={() => {}}
                isLightDismiss
                layerProps={{ eventBubblingEnabled: true }}
                headerText={headerText}
                isOpen={props.isOpen}
                onDismiss={(ev) => {
                    handleDismiss();
                }}
                closeButtonAriaLabel={closeText}
                type={PanelType.medium}
                isFooterAtBottom={true}
            >
                {props.locationType === CommentsPanelLocationType.Report && (
                    <ConversationListReport reportId={props.reportId} reportName={props.reportName} />
                )}
                {props.locationType === CommentsPanelLocationType.ReportField && (
                    <ConversationListReportField
                        reportId={props.reportId}
                        reportName={props.reportName}
                        reportRowNumber={props.reportRowNumber}
                        reportColumnName={props.reportColumnName}
                        reportRowDescription={props.reportRowDescription}
                    />
                )}
                <CommentsPanelConfirmCloseDialog
                    isOpen={isConfirmDialogVisible}
                    onDiscard={handleConfirmDialogDiscard}
                    onCancel={handleConfirmDialogCancel}
                />
            </Panel>
        </>
    );
};
