import { useQueryClient } from '@tanstack/react-query';
import {
    useAddNewConversationToReport,
    useAddNewReplyToConversation,
    useDeleteConversation,
    useDeleteReply,
    useGetConversationsForReport,
} from '../api/collaboration/collaboration';

export const useCommentDataItemsForReport = (reportId?: string) => {
    const queryClient = useQueryClient();

    const {
        data: items,
        isError,
        isLoading,
        isFetching,
        queryKey: commentQueryKey,
        refetch,
    } = useGetConversationsForReport(reportId ?? '0');

    const mutationHandler = {
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(commentQueryKey);
            },
        },
    };

    const { mutateAsync } = useAddNewConversationToReport(mutationHandler);

    const { mutateAsync: deleteConversationAsync } = useDeleteConversation(mutationHandler);

    const { mutateAsync: addReplyAsync } = useAddNewReplyToConversation(mutationHandler);

    const { mutateAsync: deleteReplyAsync } = useDeleteReply(mutationHandler);

    return {
        isLoadingItems: isLoading,
        isFetchingItems: isFetching,
        isErrorItems: isError,
        items: items?.conversations ?? [],
        errors: items?.problemDetails?.title,
        mutateAsync: mutateAsync,
        deleteConversationAsync,
        addReplyAsync,
        deleteReplyAsync,
        refetchItems: refetch,
        queryKey: commentQueryKey,
    };
};
