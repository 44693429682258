import { useContext } from 'react';
import { ICalculationExecutionStatusContextValues, TaxReturnCalculationContext } from '../contexts/taxReturnCalculationContext';

export const useTaxReturnCalculationState = (): ICalculationExecutionStatusContextValues => {
    const context = useContext(TaxReturnCalculationContext);

    if (!context) {
        throw new Error('useTaxReturnCalculationState must be used within a TaxReturnCalculationProvider');
    }

    return context;
};
