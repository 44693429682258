import { useCorptaxTheme } from '@corptax/react-components-common';
import { Link, mergeStyleSets } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IAppTheme } from '../../theme/IAppTheme';

export interface ILineContainerProps {
    handleLinkAction: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>) => void;
    linkTitle: string;
    showDivider?: boolean;
}

const LineContainerLink: React.FC<ILineContainerProps> = ({ handleLinkAction, linkTitle, showDivider }) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const getLineContainerLinkStyles = () =>
        mergeStyleSets({
            lineContainerStyle: {
                display: 'flex',
                alignItems: 'center',
                width: '530px',
                paddingLeft: '10px,',
            },
            lineStyle: {
                flexGrow: 1,
                height: '1px',
                background: customPalette.neutralTertiaryAlt,
                border: 'none',
                marginLeft: '14px',
            },
            linkStyle: {
                marginLeft: '16px',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '15px',
                leadingTrim: 'both',
                textEdge: 'cap',
                color: customPalette.blueDark,
                marginRight: '19px',
                selectors: {
                    '&:hover': {
                        'text-decoration': 'none',
                        color: customPalette.themePrimary,
                    },
                },
            },
        });

    const { lineContainerStyle, lineStyle, linkStyle } = getLineContainerLinkStyles();

    return (
        <div className={lineContainerStyle}>
            {showDivider ? <hr className={lineStyle} /> : ''}
            <Link text={t(linkTitle)} onClick={handleLinkAction} className={linkStyle}>
                {t(linkTitle)}
            </Link>
        </div>
    );
};

export default LineContainerLink;
