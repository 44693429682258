import { useCorptaxTheme } from '@corptax/react-components-common';
import {
    BaseButton,
    Button,
    concatStyleSets,
    DefaultButton,
    IButtonStyles,
    IconButton,
    IContextualMenuProps,
    IIconProps,
    mergeStyles,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import React, { CSSProperties, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { IAppTheme } from '../../theme/IAppTheme';
import { IconName } from '../../utils/Icons.constants';

export declare type IconPosition = 'before' | 'after';

export interface IIconButtonProps
    extends React.HTMLAttributes<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement> {
    iconName?: IconName;
    id?: string;
    url?: string;
    label?: string;
    ariaLabel?: string;
    buttonStyle?: string;
    textClassName?: string;
    iconProps?: IIconProps;
    menuProps?: IContextualMenuProps;
    onButtonClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>;
    isActive?: boolean;
    toggle?: boolean;
    checked?: boolean;
    styles?: IButtonStyles;
    tabIndex?: number;
    ariaExpanded?: boolean;
    role?: string;
    disabled?: boolean;
    showCancelButton?: boolean;
    cancelButtonAriaLabel?: string;
    handleCancelButtonClick?: MouseEventHandler<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement
    >;
    cancelButtonStyle?: CSSProperties;
    isProcessing?: boolean;
    processingLabel?: string;
    splitButton?: boolean;
    splitButtonAriaLabel?: string;
    dataTestid?: string;
}

const CustomIconButton: React.FC<IIconButtonProps> = (props: IIconButtonProps) => {
    const {
        id,
        url,
        label,
        ariaLabel,
        iconName,
        iconProps,
        menuProps,
        onButtonClick,
        toggle,
        checked,
        styles,
        tabIndex,
        ariaExpanded,
        role,
        disabled,
        showCancelButton,
        cancelButtonAriaLabel,
        handleCancelButtonClick,
        cancelButtonStyle,
        isProcessing,
        dataTestid,
    } = props;
    const { t } = useTranslation();
    const { brandPalette, customPalette } = useCorptaxTheme<IAppTheme>();

    const defaultCancelButtonClassName = mergeStyles({
        fontSize: '15px',
        borderRadius: '0 6px 6px 0',
        border: '1px solid',
        color: customPalette.blueDark,
        borderColor: customPalette.neutralSecondaryAlt,
        backgroundColor: brandPalette.white,
        boxShadow: '0px 5px 4px -4px #00000005, 0px 1px 2px 0px #0000000F',
        '&:hover': {
            color: disabled ? customPalette.cementGrey : customPalette.themePrimary,
            borderColor: disabled ? customPalette.disabledGreyBorder : customPalette.themePrimary,
        },
    });

    const handleReportButtonClick: MouseEventHandler<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement
    > = (e) => {
        if (onButtonClick) onButtonClick(e);
    };

    const processingLabel = props.processingLabel ?? t('working').toString();

    return (
        <Stack horizontal horizontalAlign='center'>
            <DefaultButton
                iconProps={{ ...iconProps, iconName: iconName }}
                id={id}
                href={url}
                target='_blank'
                onClick={handleReportButtonClick}
                ariaLabel={ariaLabel ?? label}
                menuProps={menuProps}
                text={isProcessing ? processingLabel : label}
                toggle={toggle}
                checked={checked}
                tabIndex={tabIndex}
                aria-expanded={ariaExpanded}
                styles={concatStyleSets(
                    {
                        root: {
                            padding: '8px',
                        },
                        splitButtonContainer: {
                            selectors: {
                                '& > span > button': {
                                    borderColor: disabled ? customPalette.disabledGreyBorder : customPalette.neutralSecondaryAlt,
                                    marginRight: '1px',
                                    borderRight: `1px solid ${
                                        disabled ? customPalette.disabledGreyBorder : customPalette.neutralSecondaryAlt
                                    } !important`,
                                },
                                '& > span > button:hover': {
                                    borderColor: disabled ? customPalette.disabledGreyBorder : customPalette.themePrimary,
                                    borderLeft: '1px solid',
                                    borderRight: '1px solid !important',
                                },
                                '& > span > button:nth-child(2)': {
                                    borderRadius: '0 6px 6px 0',
                                    boxShadow: '0px 5px 4px -4px #00000005, 0px 1px 2px 0px #0000000F',
                                    color: 'inherit',
                                },
                                '& > span > span': {
                                    display: 'none',
                                },
                            },
                        },
                    },
                    styles
                )}
                role={role}
                disabled={disabled || isProcessing}
                allowDisabledFocus={true}
                split={props.splitButton}
                splitButtonAriaLabel={props.splitButtonAriaLabel}
                data-testid={dataTestid}
            >
                {isProcessing ? (
                    <Spinner
                        size={SpinnerSize.xSmall}
                        ariaLabel={processingLabel}
                        styles={{
                            root: {
                                paddingRight: '5px',
                                opacity: 0.5,
                            },
                        }}
                    />
                ) : null}
                {props.children}
            </DefaultButton>
            {showCancelButton && (
                <IconButton
                    id={id ? 'cancelBtn' + id : undefined}
                    data-testid='icon-button-cancel'
                    className={defaultCancelButtonClassName}
                    style={cancelButtonStyle}
                    iconProps={{ iconName: 'Cancel' }}
                    aria-label={cancelButtonAriaLabel || 'Cancel'}
                    onClick={handleCancelButtonClick}
                ></IconButton>
            )}
        </Stack>
    );
};

export default CustomIconButton;
