import { useContext } from 'react';
import { UserSettingsContext } from './userSettingsContext';

export function useUserSettings() {
    const userSettingsContext = useContext(UserSettingsContext);
    if (!userSettingsContext) {
        throw new Error('useUserSettings must be wrapped in a UserSettingsProvider');
    }
    return userSettingsContext;
}
