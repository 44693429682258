import { useBoolean } from '@fluentui/react-hooks';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import React, { createContext } from 'react';

export interface IGridStateContextValues {
    grid: React.RefObject<GridComponent>;
    isFilterApplied: boolean;
    setIsFilterAppliedTrue: () => void;
    setIsFilterAppliedFalse: () => void;
}

export const GridStateContext = createContext<IGridStateContextValues | null>(null);

export function GridStateContextProvider(props: {
    value?: IGridStateContextValues;
    children?: React.ReactNode;
    grid: React.RefObject<GridComponent>;
}) {
    const [isFilterApplied, { setTrue: setIsFilterAppliedTrue, setFalse: setIsFilterAppliedFalse }] = useBoolean(false);

    return (
        <GridStateContext.Provider
            value={props.value ?? { grid: props.grid, isFilterApplied, setIsFilterAppliedTrue, setIsFilterAppliedFalse }}
        >
            {props.children}
        </GridStateContext.Provider>
    );
}
