import { useCorptaxTheme } from '@corptax/react-components-common';
import { ITextFieldStyles, mergeStyles, TextField } from '@fluentui/react';
import React, { PropsWithChildren } from 'react';
import { IAppTheme } from '../../theme/IAppTheme';

interface IMaskTextFieldProps {
    id?: string;
    testid?: string;
    defaultValue?: string;
    className?: string;
    type?: string;
    prefix?: string;
    onChange?: (event: any, value: any) => void;
    value?: string;
    ariaLabel?: string;
    disableCustomStyle?: boolean;
    ariaDisabled?: boolean;
}
export const formatNumberWithCommas = (inputValue: string) => {
    const numberValue = inputValue.replace(/,/g, '');
    if (!isNaN(Number(numberValue)) && numberValue) {
        return Number(numberValue).toLocaleString();
    }
    return inputValue;
};

const CustomMaskedTextField: React.FC<PropsWithChildren<IMaskTextFieldProps>> = ({
    testid,
    id,
    defaultValue,
    className,
    type,
    prefix,
    onChange,
    value,
    ariaLabel,
    disableCustomStyle,
    ariaDisabled = false,
}) => {
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();

    const defaultTextFieldStyle = mergeStyles({
        width: '221px',
        height: '48px',
        padding: '8px 4px',
        borderRadius: '6px',
        boxShadow: '0px 5px 4px -4px #00000005, 0px 1px 2px 0px #0000000F',
        border: palette.white,
        selectors: {
            '@media (forced-colors: active)': {
                border: '1px solid WindowText',
                boxShadow: 'none',
                backgroundColor: 'Window',
                color: 'WindowText',
            },
        },
    });

    const defaultTextFieldPrefixStyle = mergeStyles({
        color: customPalette.neutralSecondary,
        backgroundColor: 'unset',
    });

    const getCustomInputStyles = (): Partial<ITextFieldStyles> => {
        return {
            fieldGroup: [defaultTextFieldStyle],
            prefix: [defaultTextFieldPrefixStyle],
        };
    };

    const handleInputOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, inputValue?: string) => {
        if (inputValue === undefined) {
            if (onChange) onChange(event, '');
            return;
        }

        const numberParts = inputValue.split('.');

        if (numberParts.length > 2 || (numberParts.length === 2 && numberParts[1].length > 1)) {
            return;
        }

        const formattedValue = formatNumberWithCommas(numberParts[0]) + (numberParts.length === 2 ? '.' + numberParts[1] : '');

        if (onChange) onChange(event, formattedValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Delete', 'ArrowLeft', 'ArrowRight'];
        if (!validKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <TextField
            data-testid={testid}
            id={id}
            type={type}
            prefix={prefix}
            onChange={handleInputOnChange}
            onKeyDown={handleKeyDown}
            styles={disableCustomStyle ? undefined : getCustomInputStyles}
            defaultValue={defaultValue}
            className={className}
            value={value}
            ariaLabel={ariaLabel}
            aria-disabled={ariaDisabled}
        />
    );
};

export default CustomMaskedTextField;
