import { Combobox, Label, Option, OptionGroup, Tooltip, useId } from '@fluentui/react-components';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnterAmountsDialogComboboxData } from '../../hooks/useEnterAmountsDialogComboboxData';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { IAdjustment } from '../../model';
import {
    EnterAmountsDialogFiltersActionType,
    IEnterAmountsDialogComboboxPropsBase,
    IEnterAmountsDialogFilterAdjustmentChangeAction,
} from '../../reducers/enterAmountsDialogFiltersReducer';
import { comboBoxCheckIconStyles } from './EnterAmountsDialogFilters';
const findAdjustmentDtoByCode = (
    adjustments: IAdjustment[] | null | undefined,
    adjustmentCode: string | undefined
): IAdjustment | undefined => {
    return adjustments?.find((adjustmentDto: IAdjustment) => adjustmentDto.adjustmentCode === adjustmentCode);
};
const OptionGroupLabel: FC = () => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '24px',
                overflow: 'hidden',
            }}
        >
            <div>{t('adjustmentCodeAbbreviated')}</div>
            <div>{t('adjustmentNameAbbreviated')}</div>
            <div>{t('adjustmentTypeAbbreviated')}</div>
        </div>
    );
};

interface IAdjustmentComboboxOptionProps {
    adjustment: IAdjustment;
    optionToSelectedOption: (adjustment: IAdjustment) => string;
    optionToInputValue: (adjustment: IAdjustment) => string;
}

const AdjustmentComboboxOption: FC<IAdjustmentComboboxOptionProps> = (props) => {
    const hiddenCheckBoxCssClass = comboBoxCheckIconStyles().hidden;
    const { adjustment, optionToSelectedOption, optionToInputValue } = props;
    return (
        <Option
            key={optionToSelectedOption(adjustment)}
            text={optionToInputValue(adjustment)}
            className={hiddenCheckBoxCssClass}
            value={optionToSelectedOption(adjustment)}
            aria-label={optionToInputValue(adjustment)}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '24px',
                    overflow: 'hidden',
                }}
            >
                <Tooltip content={adjustment!.adjustmentCode!} relationship='inaccessible' appearance='inverted' withArrow={true}>
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: '0 0 60px',
                        }}
                    >
                        {adjustment!.adjustmentCode}
                    </div>
                </Tooltip>
                <Tooltip content={adjustment!.adjustmentName!} relationship='inaccessible' appearance='inverted' withArrow={true}>
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: '3 0 0',
                        }}
                    >
                        {adjustment!.adjustmentName}
                    </div>
                </Tooltip>
                <Tooltip content={adjustment!.adjustmentTypeCode!} relationship='inaccessible' appearance='inverted' withArrow={true}>
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: '0 0 60px',
                        }}
                    >
                        {adjustment!.adjustmentTypeCode}
                    </div>
                </Tooltip>
            </div>
        </Option>
    );
};

export interface IEnterAmountsDialogAdjustmentComboboxProps
    extends IEnterAmountsDialogComboboxPropsBase<IAdjustment, IEnterAmountsDialogFilterAdjustmentChangeAction> {}

export const AdjustmentCombobox: FC<IEnterAmountsDialogAdjustmentComboboxProps> = (props: IEnterAmountsDialogAdjustmentComboboxProps) => {
    const adjustmentLabelId: string = useId('adjustment');
    const { comboboxStyles } = useEnterAmountsDialogStyles();
    const { t } = useTranslation();
    const optionToSelectedOption: (option: IAdjustment) => string = (option: IAdjustment) => option.adjustmentCode!;
    const optionToInputValue: (option: IAdjustment) => string = (option: IAdjustment) =>
        `${option.adjustmentCode} - ${option.adjustmentName} - ${option.adjustmentTypeCode}`;
    const findOptionsByDisplayValue: (displayValue: string, options: IAdjustment[]) => IAdjustment[] = (
        displayValue: string,
        options: IAdjustment[]
    ) =>
        options.filter((adjustmentDto: IAdjustment) =>
            `${adjustmentDto.adjustmentCode} - ${adjustmentDto.adjustmentName} - ${adjustmentDto.adjustmentTypeCode}`
                .toLowerCase()
                .includes(displayValue.toLowerCase())
        );
    const findOptionByKey: (key: string | undefined, options: IAdjustment[]) => IAdjustment | undefined = (
        key: string | undefined,
        options: IAdjustment[]
    ) => findAdjustmentDtoByCode(options, key);
    const optionRenderer: (adjustment: IAdjustment) => JSX.Element = useCallback(
        (adjustment: IAdjustment) => (
            <AdjustmentComboboxOption
                key={optionToSelectedOption(adjustment)}
                adjustment={adjustment}
                optionToSelectedOption={optionToSelectedOption}
                optionToInputValue={optionToInputValue}
            />
        ),
        [optionToSelectedOption, optionToInputValue]
    );
    const { inputValue, selectedOptions, matchingOptions, searchText, onOptionSelect, onBlur, onChange, disabled, onKeyDown, comboboxRef } =
        useEnterAmountsDialogComboboxData<
            IAdjustment,
            IEnterAmountsDialogFilterAdjustmentChangeAction,
            IEnterAmountsDialogAdjustmentComboboxProps
        >(
            props,
            {
                optionToInputValue,
                optionToSelectedOption,
                findOptionsByDisplayValue,
                findOptionByKey,
                optionRenderer: optionRenderer,
            },
            EnterAmountsDialogFiltersActionType.AdjustmentChanged
        );
    const optionGroupLabel: JSX.Element = useMemo(() => <OptionGroupLabel />, []);
    return (
        <div>
            <Label id={adjustmentLabelId} weight='semibold'>
                {t('adjustment')}
            </Label>
            <Combobox
                aria-labelledby={adjustmentLabelId}
                placeholder={t('selectAdjustmentCode').toString()}
                className={comboboxStyles}
                onOptionSelect={onOptionSelect}
                selectedOptions={selectedOptions}
                value={inputValue}
                disabled={disabled}
                freeform
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                clearable={props.clearable}
                ref={comboboxRef}
            >
                {searchText ? (
                    <Option aria-disabled='true' key='freeform' text={searchText} disabled={true}>
                        {t('noOptionsFound', { searchText: searchText })}
                    </Option>
                ) : (
                    <OptionGroup key='optionGroup' label={optionGroupLabel}>
                        {matchingOptions}
                    </OptionGroup>
                )}
            </Combobox>
        </div>
    );
};
