import { useCorptaxTheme } from '@corptax/react-components-common';
import { mergeStyles, Separator } from '@fluentui/react';
import { IAppTheme } from '../../theme/IAppTheme';

export interface IDefaultSeparatorProps {
    height?: string;
}

const DefaultSeparator: React.FC<IDefaultSeparatorProps> = ({ height }) => {
    const { brandPalette } = useCorptaxTheme<IAppTheme>();
    const separatorStyles = mergeStyles({
        height: height ?? '32px',
        '::after': {
            backgroundColor: brandPalette.lightGrayColor.hex,
        },
    });

    return <Separator className={separatorStyles} vertical />;
};

export default DefaultSeparator;
