import { MenuEventArgs } from '@syncfusion/ej2-react-navigations';
import { useTranslation } from 'react-i18next';
import { ICurrentCellEventArgs } from '../data-types/ICurrentCellEventArgs';
import { IContextMenuItemOptions } from './IContextMenuItemOptions';
import { ContextMenuItemModelExtended, ContextMenuVisibilityAccessor } from './useCommonIndividualReportTableContextMenu';

export const useEditCellContextMenuItem = <T>(
    onMenuItemClick?: (args?: ICurrentCellEventArgs<T>) => void,
    getVisibilityState?: ContextMenuVisibilityAccessor
): IContextMenuItemOptions<T> => {
    const { t } = useTranslation();
    const menuItemId = 'editCell';

    const contextMenuItem: ContextMenuItemModelExtended = {
        text: t('editCell').toString(),
        target: '.e-content',
        id: menuItemId,
        iconCss: 'icon--fluent icon--fluent--edit-20-regular',
        visibilityAccessor: getVisibilityState,
    };

    const handleContextMenuItemClick = (args: MenuEventArgs, currentCellArgs?: ICurrentCellEventArgs<T> | null | undefined) => {
        if (args.item.id === menuItemId && onMenuItemClick) {
            onMenuItemClick(currentCellArgs!);
            return true;
        }
        return false;
    };

    return { contextMenuItem, handleContextMenuItemClick };
};
