import { useQueryClient } from '@tanstack/react-query';
import { useUpdateConversation, useUpdateReply } from '../../api/collaboration/collaboration';
import { useCommentDataItemsForReport } from '../../hooks/useCommentDataItemsForReport';
import { useCommentTitleGeneration } from '../../hooks/useCommentTitleGeneration';
import { AddNewConversationRequest } from '../../model';
import { ConversationList } from './ConversationList';

export interface IConversationListReportProps {
    reportId?: string;
    reportName?: string;
}

export const ConversationListReport = (props: IConversationListReportProps) => {
    const queryClient = useQueryClient();
    const { newConversationTitle } = useCommentTitleGeneration(props.reportId);
    const reportId = props.reportId?.toString() ?? '';

    const {
        isLoadingItems,
        isFetchingItems,
        isErrorItems,
        items,
        mutateAsync,
        deleteConversationAsync,
        addReplyAsync,
        deleteReplyAsync,
        refetchItems,
        queryKey,
    } = useCommentDataItemsForReport(reportId);

    const { mutateAsync: updateConversationAsync } = useUpdateConversation({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
            },
        },
    });

    const { mutateAsync: updateReplyAsync } = useUpdateReply({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
            },
        },
    });

    const addConversationAsync = async (data: AddNewConversationRequest) => {
        data.reportName = props.reportName;
        //orval created these as string types so we cannot pass undefined
        await mutateAsync({ reportId: reportId, data: data });
    };

    return (
        <ConversationList
            newConversationTitle={newConversationTitle}
            items={items}
            isLoadingItems={isLoadingItems}
            isErrorItems={isErrorItems}
            isFetchingItems={isFetchingItems}
            addConversationAsync={addConversationAsync}
            addReplyAsync={addReplyAsync}
            deleteConversationAsync={deleteConversationAsync}
            deleteReplyAsync={deleteReplyAsync}
            updateConversationAsync={updateConversationAsync}
            updateReplyAsync={updateReplyAsync}
            refetchItems={refetchItems}
        />
    );
};
