import { useCorptaxTheme } from '@corptax/react-components-common';
import { IRawStyle, mergeStyles, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';
import { useGridState } from '../../hooks/useGridState';
import { useSearchTable } from '../../hooks/useSearchTable';
import { IAppTheme } from '../../theme/IAppTheme';

export interface IGridSearchBarProps {
    searchBoxPlaceholderText: string;
    styleOverride?: IRawStyle;
    stackStyle?: CSSProperties;
    buttonStyleOverride?: CSSProperties;
}

const GridSearchBar: React.FC<IGridSearchBarProps> = ({ searchBoxPlaceholderText, styleOverride, stackStyle, buttonStyleOverride }) => {
    const { t } = useTranslation();
    const { applySearch, clearSearch, setSearchValue, searchValue } = useSearchTable();
    const [isSearchboxFocused, { setTrue: setIsSearchboxFocusedTrue, setFalse: setIsSearchboxFocusedFalse }] = useBoolean(false);
    const { grid } = useGridState();
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const searchBarStyles = mergeStyles({
        boxSizing: 'border-box',
        width: '250px',
        borderRadius: '4px',
        marginRight: '5px',
        outline: 'none',
        borderColor: customPalette.statusCoolLightGrey,
        ':hover': {
            borderColor: customPalette.neutralLightGrey,
        },
        ':focus': {
            borderColor: customPalette.blueLight,
        },
        '::after': {
            borderRadius: '8px',
        },
        ...styleOverride,
    });

    const searchIconProps = {
        iconName: 'Search',
        style: { color: customPalette.themeDarkAlt, fontWeight: 'bold' },
    };

    const handleSearch = () => {
        applySearch(grid);
    };

    return (
        <Stack horizontal style={stackStyle}>
            <SearchBox
                value={searchValue}
                className={searchBarStyles}
                placeholder={isSearchboxFocused ? '' : searchBoxPlaceholderText}
                iconProps={searchIconProps}
                showIcon={true}
                ariaLabel={searchBoxPlaceholderText}
                onChange={(_: any, newValue?: string) => setSearchValue(newValue ?? '')}
                onFocus={setIsSearchboxFocusedTrue}
                onBlur={setIsSearchboxFocusedFalse}
                onClear={() => clearSearch(grid)}
                onSearch={handleSearch}
            />
            <PrimaryButton
                text={t('search').toString()}
                onClick={handleSearch}
                role='button'
                id='gridSearchButton'
                style={buttonStyleOverride}
            ></PrimaryButton>
        </Stack>
    );
};

export default GridSearchBar;
