import { IContextualMenuProps } from '@fluentui/react';
import CustomIconButton from './IconButton';

export interface IIconButtonWithContextualMenuProps {
    buttonId: string;
    label: string;
    ariaLabel?: string;
    menuProps: IContextualMenuProps;
    isActive?: boolean;
    isDisabled?: boolean;
}
const IconButtonWithContextualMenu: React.FC<IIconButtonWithContextualMenuProps> = ({
    buttonId,
    label,
    ariaLabel,
    menuProps,
    isActive,
    isDisabled,
}) => {
    return (
        <CustomIconButton
            id={buttonId}
            label={label}
            ariaLabel={ariaLabel}
            menuProps={menuProps}
            isActive={isActive}
            disabled={isDisabled}
        />
    );
};

export default IconButtonWithContextualMenu;
