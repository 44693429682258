import { useCorptaxTheme } from '@corptax/react-components-common';
import { FocusZone, FocusZoneTabbableElements, IconButton, Label, mergeStyles, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useGridState } from '../../../hooks/useGridState';
import { useSearchTable } from '../../../hooks/useSearchTable';
import { useVarianceNavigation } from '../../../hooks/useVarianceNavigation';
import { ReportVariancePoint } from '../../../model';
import { IAppTheme } from '../../../theme/IAppTheme';

interface IVarianceGoToNavigationProps {
    variancePoints?: ReportVariancePoint[];
    isDisabled?: boolean;
    showOnlyExceedingRows: boolean;
}

const VarianceGoToNavigation: React.FC<IVarianceGoToNavigationProps> = (props: IVarianceGoToNavigationProps) => {
    const { variancePoints, isDisabled } = props;
    const { t } = useTranslation();
    const { brandPalette, customPalette } = useCorptaxTheme<IAppTheme>();
    const { isSearchValueApplied } = useSearchTable();
    const { grid } = useGridState();

    const { isVarianceNavigating, currentVarianceIndex, scrollToVariance } = useVarianceNavigation();

    const labelClassName = mergeStyles({
        height: '32px',
        width: 'auto',
        padding: '5px 10px',
        alignContent: 'center',
    });

    const GoToNavigationStyles = mergeStyles({
        fontSize: '14px',
        border: '1px solid',
        borderColor: isDisabled ? customPalette.disabledGreyBorder : brandPalette.hyperlinkBlue,
        padding: '4px 0px',
        borderRadius: '4px',
        gap: '20px',
        backgroundColor: isDisabled ? customPalette.disabledGrey : brandPalette.white,
        height: '32px',
        '& > label': {
            fontSize: '14px',
            color: isDisabled ? customPalette.cementGrey : customPalette.blueDark,
        },
        '& > div > button': {
            width: '20px',
            height: '20px',
            backgroundColor: isDisabled ? customPalette.disabledGrey : brandPalette.white,
        },
        '& > div > button > span > i': {
            fontSize: '12px',
            color: isDisabled ? customPalette.cementGrey : customPalette.blueDark,
        },
    });

    const hasVariances = () => variancePoints && variancePoints.length > 0;

    const handlePrevious = () => {
        scrollToVariance(variancePoints || [], true, props.showOnlyExceedingRows ?? false, grid?.current);
    };
    const handleNext = () => {
        scrollToVariance(variancePoints || [], false, props.showOnlyExceedingRows ?? false, grid?.current);
    };

    const shouldRenderResultsAsXofY = () =>
        variancePoints?.length && currentVarianceIndex !== undefined && currentVarianceIndex > 0 && !isSearchValueApplied;

    const getVarianceResultCountLabel = () => {
        if (shouldRenderResultsAsXofY()) {
            return `${currentVarianceIndex} of ${variancePoints?.length}`;
        }
        if (hasVariances()) {
            return `${variancePoints?.length} ${t('results')}`;
        }
        return t('noResults').toString();
    };

    return (
        <FocusZone as='div' handleTabKey={FocusZoneTabbableElements.all}>
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center' className={GoToNavigationStyles}>
                <Label data-is-focusable aria-label={`${getVarianceResultCountLabel()} ${t('ofVariance')}`} className={labelClassName}>
                    {getVarianceResultCountLabel()}
                </Label>
                {hasVariances() && (
                    <Stack horizontal horizontalAlign='end'>
                        <IconButton
                            data-is-focusable
                            disabled={isDisabled || isVarianceNavigating}
                            onClick={handleNext}
                            iconProps={{ iconName: 'ChevronUp' }}
                            aria-label={t('previousVariance').toString()}
                        />
                        <IconButton
                            data-is-focusable
                            disabled={isDisabled || isVarianceNavigating}
                            onClick={handlePrevious}
                            iconProps={{ iconName: 'ChevronDown' }}
                            aria-label={t('nextVariance').toString()}
                        />
                    </Stack>
                )}
            </Stack>
        </FocusZone>
    );
};

export default VarianceGoToNavigation;
