import { useCorptaxTheme } from '@corptax/react-components-common';
import { mergeStyles, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IAppTheme } from '../../theme/IAppTheme';
import FilterGroup from '../common/FilterGroup';

export interface IFilterBarProps {
    filterGroupItems: React.ReactNode[];
    isFiltered: boolean;
    clearAllFilters: () => void;
    compareButton?: JSX.Element;
}
const ReportToolbar: React.FC<IFilterBarProps> = (props: IFilterBarProps) => {
    const { isFiltered, clearAllFilters } = props;
    const { t } = useTranslation();

    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();

    const card = mergeStyles({
        alignItems: 'center',
        width: 'auto',
        maxWidth: '100%',
        height: 'auto',
        boxSizing: 'border-box',
        backgroundColor: palette.white,
        padding: '12px 16px',
        borderRadius: '10px 10px 0 0',
        border: `1px solid ${customPalette.greyD1D3D4}`,
    });

    const buttonGap = mergeStyles({
        '& > div': {
            gap: '5px',
        },
    });

    const buttonsContainer = mergeStyles({
        width: '100%',
    });

    return (
        <Stack className={card}>
            <Stack horizontal wrap className={buttonsContainer}>
                <FilterGroup isFiltered={isFiltered} clearAllFiltersLabel={t('filterBarClearAllFilters')} clearAllFilters={clearAllFilters}>
                    <Stack horizontal wrap className={buttonGap}>
                        {props.filterGroupItems}
                        {props.compareButton}
                    </Stack>
                </FilterGroup>
            </Stack>
        </Stack>
    );
};

export default ReportToolbar;
