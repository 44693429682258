import { Combobox, Label, Option, useId } from '@fluentui/react-components';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnterAmountsDialogComboboxData } from '../../hooks/useEnterAmountsDialogComboboxData';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { ICalendarPeriod } from '../../model';
import {
    EnterAmountsDialogFiltersActionType,
    IEnterAmountsDialogComboboxPropsBase,
    IEnterAmountsDialogFilterCalendarPeriodChangeAction,
} from '../../reducers/enterAmountsDialogFiltersReducer';
import { comboBoxCheckIconStyles, findCalendarPeriodDtoByCode } from './EnterAmountsDialogFilters';

const findCalendarPeriodsByDisplayValue: (displayValue: string, calendarPeriods: ICalendarPeriod[]) => ICalendarPeriod[] = (
    displayValue: string,
    calendarPeriods: ICalendarPeriod[]
) => {
    return (
        calendarPeriods?.filter((calendarPeriodDto: ICalendarPeriod) => {
            return `${calendarPeriodDto.periodYear} - ${calendarPeriodDto.description}`.toLowerCase().includes(displayValue.toLowerCase());
        }) || []
    );
};

interface ICalendarPeriodComboboxOptionProps {
    calendarPeriod: ICalendarPeriod;
    optionToSelectedOption: (calendarPeriod: ICalendarPeriod) => string;
    optionToInputValue: (calendarPeriod: ICalendarPeriod) => string;
}

const CalendarPeriodComboboxOption: FC<ICalendarPeriodComboboxOptionProps> = (props) => {
    const hiddenCheckBoxCssClass = comboBoxCheckIconStyles().hidden;
    const { calendarPeriod, optionToSelectedOption, optionToInputValue } = props;
    return (
        <Option
            key={optionToSelectedOption(calendarPeriod)}
            text={optionToInputValue(calendarPeriod)}
            className={hiddenCheckBoxCssClass}
            value={optionToSelectedOption(calendarPeriod)}
            aria-label={optionToInputValue(calendarPeriod)}
        >
            {optionToInputValue(calendarPeriod)}
        </Option>
    );
};

export interface IEnterAmountsDialogCalendarPeriodComboboxProps
    extends IEnterAmountsDialogComboboxPropsBase<ICalendarPeriod, IEnterAmountsDialogFilterCalendarPeriodChangeAction> {}

export const CalendarPeriodCombobox: FC<IEnterAmountsDialogCalendarPeriodComboboxProps> = (
    props: IEnterAmountsDialogCalendarPeriodComboboxProps
) => {
    const { t } = useTranslation();
    const optionToInputValue = (option: ICalendarPeriod) => `${option.periodYear} - ${option.description}`;
    const optionToSelectedOption = (option: ICalendarPeriod) => option.periodYear!.toString();
    const findOptionsByDisplayValue = (displayValue: string, options: ICalendarPeriod[]) =>
        findCalendarPeriodsByDisplayValue(displayValue, options);
    const findOptionByKey = (key: string | undefined, options: ICalendarPeriod[]) => findCalendarPeriodDtoByCode(options, key);
    const periodLabelId: string = useId('period');
    const { comboboxStyles } = useEnterAmountsDialogStyles();
    const optionRenderer: (calendarPeriod: ICalendarPeriod) => JSX.Element = useCallback(
        (calendarPeriod: ICalendarPeriod) => (
            <CalendarPeriodComboboxOption
                key={optionToSelectedOption(calendarPeriod)}
                calendarPeriod={calendarPeriod}
                optionToInputValue={optionToInputValue}
                optionToSelectedOption={optionToSelectedOption}
            />
        ),
        [optionToInputValue, optionToSelectedOption]
    );
    const { inputValue, selectedOptions, matchingOptions, searchText, onOptionSelect, onBlur, onChange, disabled, onKeyDown, comboboxRef } =
        useEnterAmountsDialogComboboxData<
            ICalendarPeriod,
            IEnterAmountsDialogFilterCalendarPeriodChangeAction,
            IEnterAmountsDialogCalendarPeriodComboboxProps
        >(
            props,
            {
                optionToInputValue,
                optionToSelectedOption,
                findOptionsByDisplayValue: findOptionsByDisplayValue,
                findOptionByKey,
                optionRenderer: optionRenderer,
            },
            EnterAmountsDialogFiltersActionType.CalendarPeriodChanged
        );

    return (
        <div>
            <Label id={periodLabelId} weight='semibold'>
                {t('period')}
            </Label>
            <Combobox
                className={comboboxStyles}
                aria-labelledby={periodLabelId}
                selectedOptions={selectedOptions}
                value={inputValue}
                onOptionSelect={onOptionSelect}
                disabled={disabled}
                freeform
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                ref={comboboxRef}
                clearable={props.clearable}
            >
                {searchText ? (
                    <Option aria-disabled='true' key='freeform' text={searchText} disabled={true}>
                        {t('noOptionsFound', { searchText: searchText })}
                    </Option>
                ) : (
                    matchingOptions
                )}
            </Combobox>
        </div>
    );
};
