import { useBoolean } from '@fluentui/react-hooks';
import { useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useState } from 'react';
import { applicationInsightsReactPlugin } from '../ApplicationInsightsService';

interface IRenderEventTableInformation {
    rowCount: number;
    columnCount: number;
    visibleColumnCount: number;
    reportId?: number;
    isMultiColumn?: boolean;
}

interface IRenderEventInformation {
    componentName: string;
    durationInMilliseconds: number;
    tableInformation?: IRenderEventTableInformation;
}

// When using this useGetHook, we need to make sure it is at the very start of the component
// This is to ensure that the start time of the render is captured correctly
export const usePerformanceMetricsForTable = (tableName: string) => {
    const [renderStartTimestamp, setRenderStartTimestamp] = useState<number | undefined>(undefined);
    const [isInitialLoad, { setFalse: initialLoadHappened }] = useBoolean(true);
    const [isScrolling, { setTrue: startScrollEvent, setFalse: scrollEventFinished }] = useBoolean(false);

    const currentEvent: IRenderEventInformation = {
        componentName: tableName,
        durationInMilliseconds: 0,
    };

    const trackInitialRender = useTrackEvent<IRenderEventInformation>(
        applicationInsightsReactPlugin,
        'React component initial render',
        currentEvent
    );
    const trackCartUpdate = useTrackEvent<IRenderEventInformation>(
        applicationInsightsReactPlugin,
        'React component load after scroll',
        currentEvent
    );

    const resetStartTime = () => {
        setRenderStartTimestamp(Date.now());
    };

    if (isInitialLoad && !renderStartTimestamp) {
        resetStartTime();
        initialLoadHappened();
    }

    const trackRenderFinished = (tableInformation: IRenderEventTableInformation) => {
        if (renderStartTimestamp) {
            const renderEndTimestamp = Date.now();
            const durationInMilliseconds = renderEndTimestamp - renderStartTimestamp;

            if (!isScrolling) {
                trackInitialRender(() => ({
                    ...currentEvent,
                    durationInMilliseconds,
                    tableInformation,
                }));
            } else {
                trackCartUpdate(() => ({
                    ...currentEvent,
                    durationInMilliseconds,
                    tableInformation,
                }));
                scrollEventFinished();
            }

            setRenderStartTimestamp(undefined);
        }
    };

    const startTrackingScrollEvent = () => {
        setRenderStartTimestamp(Date.now());
        startScrollEvent();
    };

    return { trackRenderFinished, startTrackingScrollEvent, resetStartTime };
};
