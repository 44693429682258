import { Callout, getId, ICalloutProps, IIconProps, Link } from '@fluentui/react';
import { Attributes, FC, HTMLAttributes, KeyboardEventHandler, useState } from 'react';
import { IconName } from '../../utils/Icons.constants';
import Icon from './Icon';

export interface ICalloutIconProps extends Attributes, HTMLAttributes<HTMLElement> {
    calloutProps?: Partial<ICalloutProps>;
    iconProps?: IIconProps;
    icon: IconName;
    buttonAriaLabel?: string;
    onDismiss?: (event: React.KeyboardEvent<HTMLElement> | Event | React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
}
const CalloutIcon: FC<ICalloutIconProps> = (props: ICalloutIconProps) => {
    const [isCalloutVisible, setCalloutVisibility] = useState<boolean>(false);
    const iconId: string = getId();
    const onKeyDown: KeyboardEventHandler<HTMLElement> = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.code === 'Space' || event.code === 'Enter') {
            onDismiss(event);
        }
    };

    const onDismiss = (event: React.KeyboardEvent<HTMLElement> | Event | React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
        setCalloutVisibility(false);
        if (props.onDismiss) props.onDismiss(event);
    };
    return (
        <>
            <Link
                styles={{ root: { display: 'flex', justifyItems: 'center', alignItems: 'center' } }}
                onClick={(event: React.KeyboardEvent<HTMLElement> | Event | React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
                    setCalloutVisibility(true);
                }}
                tabIndex={-1}
                aria-label={props.buttonAriaLabel}
                aria-pressed={isCalloutVisible}
            >
                <Icon {...props.iconProps} iconName={props.icon} id={iconId} />
            </Link>
            {isCalloutVisible && (
                <Callout
                    key={props.key}
                    {...props.calloutProps}
                    onDismiss={onDismiss}
                    target={`#${iconId}`}
                    setInitialFocus
                    onKeyDown={onKeyDown}
                    role='dialog'
                >
                    {props.children}
                </Callout>
            )}
        </>
    );
};

export default CalloutIcon;
