import { MentionComponent } from '@syncfusion/ej2-react-dropdowns';
import { useGetAllUserRecords } from '../../../api/users/users';
import { UserRecord } from '../../../model';

export interface ICommentMentionComponentProps {
    targetIdentifier: string;
}

export const CommentMentionComponent = (props: ICommentMentionComponentProps) => {
    // for now, just directly call this hook.
    // passing the list as props results in a LOT of prop drilling, but a context seemed like overkill.
    // if we move to common, likely would want to get it from a context or similar
    const { data } = useGetAllUserRecords();

    // we can't bind to an interface type because reasons
    // https://github.com/microsoft/TypeScript/issues/15300
    let usersList = data?.map((item) => {
        return {
            fullName: item.fullName ?? '',
            userName: item.userName ?? '',
            userId: item.userId ?? '',
        };
    });

    // this is the "dropdown" selector type part of the component
    // it's super basic for now
    function itemTemplate(userRecord: UserRecord) {
        return (
            <table cellSpacing={5}>
                <tbody>
                    <tr>
                        <td>
                            <span>
                                <b>{userRecord.fullName}</b>
                            </span>
                        </td>
                        <td>
                            <span>
                                <i>{userRecord.userName}</i>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    // this is what ends up in the RTE textarea that SyncFusion uses - it gets wrapped in another span
    // of <span contenteditable="false" class="e-mention-chip">
    function displayTemplate(userRecord: UserRecord) {
        return (
            <>
                <span data-id={userRecord.userId}>{userRecord.fullName}</span>
            </>
        );
    }

    return (
        <>
            <MentionComponent
                target={props.targetIdentifier}
                suggestionCount={10}
                showMentionChar={false}
                allowSpaces={true}
                dataSource={usersList}
                fields={{ text: 'fullName' }}
                popupWidth='250px'
                popupHeight='200px'
                itemTemplate={itemTemplate}
                displayTemplate={displayTemplate}
            ></MentionComponent>
        </>
    );
};
