/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { GetNotificationsResponse, GetSignalRConnectionInformationResponse, UpdateNotificationRequest } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetNotificationsHook = () => {
    const getNotifications = useCustomInstance<GetNotificationsResponse>();

    return (signal?: AbortSignal) => {
        return getNotifications({ url: `/Notifications/GetNotifications`, method: 'get', signal });
    };
};

export const getGetNotificationsQueryKey = () => [`/Notifications/GetNotifications`] as const;

export const useGetNotificationsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetNotificationsQueryKey();

    const getNotifications = useGetNotificationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>> = ({ signal }) =>
        getNotifications(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetNotificationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>>;
export type GetNotificationsQueryError = ErrorType<void>;

export const useGetNotifications = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetNotificationsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetSignalRConnectionInformationHook = () => {
    const getSignalRConnectionInformation = useCustomInstance<GetSignalRConnectionInformationResponse>();

    return (signal?: AbortSignal) => {
        return getSignalRConnectionInformation({ url: `/Notifications/GetSignalRConnectionInformation`, method: 'get', signal });
    };
};

export const getGetSignalRConnectionInformationQueryKey = () => [`/Notifications/GetSignalRConnectionInformation`] as const;

export const useGetSignalRConnectionInformationQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetSignalRConnectionInformationHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSignalRConnectionInformationHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSignalRConnectionInformationHook>>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetSignalRConnectionInformationQueryKey();

    const getSignalRConnectionInformation = useGetSignalRConnectionInformationHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSignalRConnectionInformationHook>>>> = ({ signal }) =>
        getSignalRConnectionInformation(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetSignalRConnectionInformationQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetSignalRConnectionInformationHook>>>
>;
export type GetSignalRConnectionInformationQueryError = ErrorType<void>;

export const useGetSignalRConnectionInformation = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetSignalRConnectionInformationHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSignalRConnectionInformationHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetSignalRConnectionInformationQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useUpdateNotificationHook = () => {
    const updateNotification = useCustomInstance<void>();

    return (updateNotificationRequest: UpdateNotificationRequest) => {
        return updateNotification({
            url: `/Notifications/UpdateNotification`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: updateNotificationRequest,
        });
    };
};

export const useUpdateNotificationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateNotificationHook>>>,
        TError,
        { data: UpdateNotificationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateNotificationHook>>>,
    TError,
    { data: UpdateNotificationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const updateNotification = useUpdateNotificationHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateNotificationHook>>>,
        { data: UpdateNotificationRequest }
    > = (props) => {
        const { data } = props ?? {};

        return updateNotification(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateNotificationHook>>>>;
export type UpdateNotificationMutationBody = UpdateNotificationRequest;
export type UpdateNotificationMutationError = ErrorType<unknown>;

export const useUpdateNotification = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateNotificationHook>>>,
        TError,
        { data: UpdateNotificationRequest },
        TContext
    >;
}) => {
    const mutationOptions = useUpdateNotificationMutationOptions(options);

    return useMutation(mutationOptions);
};
