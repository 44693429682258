import { useMatch } from 'react-router';

// determines if the current route contains the comments subroute
// example 1 (no commentId): /view/51/comments
// example 2 (with commentId): /view/51/comments/d158053e-72b9-4c3a-bb3e-dad621e65daf
export const useIsCommentsSubroute = () => {
    const match = useMatch('/view/:id/:subroute?/:commentId?');
    const subroute = match?.params?.subroute;
    return !!(subroute && subroute.toLocaleLowerCase() === 'comments');
};
