import { IContextualMenuItem, mergeStyleSets } from '@fluentui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterItem } from '../../../data-types/filterItem';
import { useContextualMenuWithSearchBox } from '../../../hooks/useContextualMenuWithSearchBox';
import { roleMenuItem } from '../../../utils/Filter.constant';
import { getFilterMenuAriaLabel, getUpdatedSelectedFilters, reorderCheckedItems } from '../../../utils/FilterUtils';
import IconButtonWithContextualMenu from '../../common/IconButtonWithContextualMenu';

export interface IFilterButtonProps {
    label?: string;
    items: IFilterItem[];
    filterName: string;
    checkedItems: IFilterItem[];
    searchValue: string;
    noElementsFoundtext: string;
    setSearchValue: (value: string) => void;
    setCheckedItems: (items: IFilterItem[]) => void;
    onButtonClick?: (filterName: string) => void;
    disabled?: boolean;
}

export const getButtonStyles = () =>
    mergeStyleSets({
        filterItemWrapperStyles: {
            paddingTop: 5,
            paddingBottom: 5,
        },
    });

const FilterButton: React.FC<IFilterButtonProps> = ({
    label,
    filterName,
    items,
    checkedItems,
    searchValue,
    noElementsFoundtext,
    disabled,
    setSearchValue,
    setCheckedItems,
    onButtonClick,
}) => {
    const { t } = useTranslation();
    const { filterItemWrapperStyles } = getButtonStyles();
    const isItemSelected = checkedItems.length > 0;
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[] | undefined>(undefined);
    const handleContextualMenuButtonClick = (filteredItems: IFilterItem[], filterName: string) => {
        const newSelectedItems: IFilterItem[] = getUpdatedSelectedFilters(filteredItems, checkedItems);
        setCheckedItems(newSelectedItems);
        if (onButtonClick) {
            onButtonClick(filterName);
        }
    };

    const orderedItems = useMemo(() => {
        return reorderCheckedItems(checkedItems, items, filterName);
    }, [checkedItems, items, filterName]);

    const { contextualMenuObject } = useContextualMenuWithSearchBox({
        filterMenuItems: orderedItems,
        filterName: filterName,
        iconProps: null,
        itemWrapperClassName: filterItemWrapperStyles,
        onMenuItemClick: handleContextualMenuButtonClick,
        searchItemName: 'item',
        isFiltered: isItemSelected,
        noResultsText: noElementsFoundtext,
        viewAllText: t('clearAll'),
        searchPlaceholderText: t('search'),
        menuItems,
        setMenuItems,
        filterMenuAriaLabel: getFilterMenuAriaLabel(label || filterName, t),
        menuListRole: roleMenuItem,
        selectedFilterItems: checkedItems,
        isMultiSelect: true,
        searchValue,
        setSearchValue,
    });

    const buttonId = `${filterName}-button`;
    return (
        <IconButtonWithContextualMenu
            ariaLabel={t(label || filterName) ?? ''}
            menuProps={contextualMenuObject}
            buttonId={buttonId}
            label={t(label || filterName)}
            isActive={isItemSelected}
            isDisabled={disabled}
        />
    );
};

export default FilterButton;
