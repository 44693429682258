import { corptaxCustomLightTheme } from '@corptax/react-components-common';
import { registerIcons } from '@fluentui/react';
import { Theme } from '@fluentui/react-components';
import * as FluentUiIcons from '@fluentui/react-icons';
import Icon from '../components/common/Icon';
import { IconName } from '../utils/Icons.constants';
import { IAppThemeSettings } from './IAppThemeSettings';
import { ICustomPalette } from './ICustomPalette';
const registeredReactIcons: { [key: string]: JSX.Element } = {};
Object.keys(FluentUiIcons).forEach((icon: string) => {
    const iconName = icon as IconName;
    registeredReactIcons[iconName] = <Icon iconName={iconName} />;
});
const iconsToRegister: { [key: string]: JSX.Element } = {
    FDL2MoreFilled24: <Icon iconName='MoreHorizontal24Filled' />,
    FDL2DeleteRegular24: <Icon iconName='Delete24Regular' />,
    FDL2EditRegular24: <Icon iconName='Edit24Regular' />,
    ...registeredReactIcons,
};
registerIcons({
    icons: iconsToRegister,
});
// TEMPORARY - this change was made in react-components-common,
// but there's also a syncfusion upgrade in the same package version,
// and we're not ready to take that upgrade
corptaxCustomLightTheme.colorNeutralForegroundDisabled = '#B4B6B7';
const customPalette: ICustomPalette = {
    statusLightOrange: '#FFCBA6',
    statusMidOrange: '#F5C3A7',
    statusDarkOrange: '#EBBBA0',
    statusLightGreen: '#DFF6DD',
    statusMidGreen: '#D4EBD2',
    statusDarkGreen: '#CBE0C9',
    statusLightBlue: '#E5F0FF',
    statusMidBlue: '#DCE6F5',
    statusDarkBlue: '#D3DCEB',
    statusLightCyan: '#CCF0EE',
    statusMidCyan: '#99E0DD',
    statusDarkCyan: '#69D6D0',
    statusCoolLightGrey: '#EDEBE9',
    statusCoolMidGrey: '#F5F5F5',
    statusCoolDarkGrey: '#EAEBEB',
    themePrimary: '#003b5c', //why do we need to duplicate theme colors here?
    themeSecondary: '#2b88d8',
    themeTertiary: '#71afe5',
    themeLighter: '#f5f8ff',
    themeLighterAlt: '#eff6fc',
    themeLight: '#D9EAFD',
    themeDark: '#313131',
    themeDarker: '#05183E',
    themeDarkAlt: '#747474',
    neutralPrimary: '#333',
    neutralPrimaryAlt: '#4b4b4b',
    neutralSecondary: '#2C2E30',
    neutralSecondaryAlt: '#E7E8E9',
    neutralTertiaryAlt: '#545855',
    neutralTertiary: '#c2c2c2',
    neutralLighter: '#F9F9FC',
    neutralLighterAlt: '#EFF7FF',
    neutralQuaternaryAlt: '#dadada',
    neutralLightGrey: '#d2d2d2',
    neutralGrey: '#616161',
    neutralDark: '#585756',
    neutralDarker: '#2C2C2E',
    lightGreen: '#D1FAE5',
    darkGreen: '#107C10',
    whiteTranslucent40: 'rgba(255,255,255,.4)',
    blueLight: '#0036fa',
    blueMid: '#174DC2',
    blueDark: '#323130', //this is gray not blue...
    greenDark: '#605E5C', //this is gray not green....
    orangeLight: '#FFD9C2',
    orangeLighter: '#FEE7CC',
    red: '#D00024',
    yellowDark: '#F3C319',
    redLighter: '#FEE2E2',
    disabledGrey: '#F5F5F5',
    disabledGreyBorder: '#C7C7C7',
    cementGrey: '#97999B',
    blue075A92: '#075A92',
    greyD1D3D4: '#D1D3D4',
    greyD3D2D1: '#D3D2D1',
    grey616567: '#616567',
    blueDFF1FD: '#DFF1FD',
    grey222222: '#222222',
    greyC3C5C6: '#C3C5C6',
    lightBlue: '#A8DEFD',
    lineGrey: '#DFDFDFDF',
    blueCerulean: '#0F6CBD',
    lightCeruleanBlue: '#B4D6FA',
    warningBackground: 'linear-gradient(0deg, rgba(255, 231, 168, 0.75) 0%, rgba(255, 231, 168, 0.75) 100%, #FFF)',
    errorBackground: '#F5879133',
    blueC7DCE5: '#C7DCE5',
    blueGreyNeutral: '#8DB9CA',
    blueGreyNeutralTint2: '#EDF5F7',
    recalcNeededBackground: '#FFF8E8',
};

/*For now, this simply serves as a way to temporarily invert certain design tokens from the light theme on the Panel component. 
The base Fluent theme does not provide a background color for a Panel/Drawer header, but we provide one for Drilldown and
Calculation Options

While we could use the palette directly, using the design tokens makes more sense semantically*/
export const cometDarkTheme: Theme = {
    ...corptaxCustomLightTheme,
    colorNeutralForeground1: '#FFFFFF',
    colorNeutralForeground1Hover: '#FFFFFF',
    colorNeutralStroke1Hover: '#7C7F81',
    colorNeutralStroke1Pressed: '#6F7274',
    colorNeutralStrokeDisabled: '#B4B6B7',
    colorNeutralBackground2Hover: '#F5F5F5',
    colorNeutralBackground2Pressed: '#E2E3E4',
};

const baseButtonStyles = {
    styles: {
        root: {
            borderRadius: corptaxCustomLightTheme.borderRadiusMedium,
            display: 'flex',
            padding: `5px ${corptaxCustomLightTheme.spacingHorizontalM}`,
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 'unset',
        },
        flexContainer: {
            gap: '4px',
        },
        rootDisabled: {
            backgroundColor: corptaxCustomLightTheme.colorNeutralBackgroundDisabled,
            borderColor: corptaxCustomLightTheme.colorNeutralStrokeDisabled,
            color: corptaxCustomLightTheme.colorNeutralForegroundDisabled,
        },
        rootFocused: {
            outline: '-webkit-focus-ring-color auto 1px',
            ':after': {
                display: 'none !important',
            },
        },
        label: {
            margin: 0,
            fontWeight: corptaxCustomLightTheme.fontWeightSemibold,
        },
        labelDisabled: {
            color: corptaxCustomLightTheme.colorNeutralForegroundDisabled,
        },
        menuIcon: {
            margin: 0,
        },
    },
};

const baseIconStyles = {
    selectors: {
        '@media (forced-colors: active)': {
            color: `none`,
            border: '1px solid WindowText',
            fontSize: '22px',
        },
    },
};

export const themeSettings: Partial<IAppThemeSettings> = {
    customPalette: customPalette,
    /* In a perfect world, we could use Fluent UI "theme tokens" in this section 
       to customize how components are rendered app-wide without using custom components
       or styling each component where it's being used. 
       
       Theme tokens are strongly-typed references to CSS variables whose values are
       injected into the root element of a <FluentProvider /> component. This works
       perfectly well until you try to use tokens in a component rendered inside a
       Fluent 8 <LayerHost />. LayerHosts are rendered outside the root element of
       the <FluentProvider />, so the token values are out of scope. V8 components
       that are rendered in a LayerHost include all context menus, <Panel />, and
       <Tooltip />, among others. 
       
       I tried wrapping those particular components in a separate <FluentProvider />,
       but that did not work. This problem does not occur if you are using Fluent v9
       components.

       So until we fix that, we should use the Theme palette variables directly in
       order to achieve a consistent style across the app.
    */
    components: {
        Icon: {
            styles: {
                root: {
                    margin: 0,
                    justifyContent: 'center',
                    ...baseIconStyles,
                },
            },
        },
        IconButton: {
            styles: {
                icon: {
                    margin: 0,
                    justifyContent: 'center',
                    ...baseIconStyles,
                },
                rootFocused: {
                    ...baseButtonStyles.styles.rootFocused,
                },
            },
        },
        DetailsList: {
            styles: {
                headerWrapper: {
                    selectors: {
                        '.ms-DetailsHeader': {
                            backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1,
                            padding: '0',
                            margin: '0',
                            border: '1px solid',
                            borderColor: corptaxCustomLightTheme.colorNeutralBackground5,
                        },
                        '.ms-DetailsHeader-cellTitle:hover': {
                            backgroundColor: corptaxCustomLightTheme.colorNeutralBackground5,
                            color: '#003B5C',
                        },
                        '.ms-DetailsHeader-cellName': {
                            fontSize: '14px',
                            fontWeight: '600',
                        },
                        '.ms-DetailsHeader-cell': {
                            height: '40px',
                            width: '330px',
                            left: '21px',
                            top: '0px',
                            borderRadius: '0px',
                        },
                    },
                },
            },
        },
        DetailsRow: {
            styles: {
                cell: {
                    paddingLeft: '33px',
                },
            },
        },
        PrimaryButton: {
            styles: {
                root: {
                    ...baseButtonStyles.styles.root,
                    backgroundColor: corptaxCustomLightTheme.colorBrandBackground,
                    /*
                        even though we don't actually want a border to be visible on 
                        primary buttons, if we don't include the style, then the browser 
                        will probably apply a border on hover, which will make the
                        button appear to subtly change dimensions
                    */
                    border: `${corptaxCustomLightTheme.strokeWidthThin} solid ${corptaxCustomLightTheme.colorTransparentStroke}`,
                    ':focus-visible': {
                        ...baseButtonStyles.styles.rootFocused,
                    },
                },
                rootHovered: {
                    backgroundColor: corptaxCustomLightTheme.colorBrandBackgroundHover,
                    color: corptaxCustomLightTheme.colorNeutralStrokeOnBrand,
                },
                rootDisabled: {
                    ...baseButtonStyles.styles.rootDisabled,
                },
                rootPressed: {
                    backgroundColor: corptaxCustomLightTheme.colorBrandBackgroundPressed,
                },
                label: {
                    ...baseButtonStyles.styles.label,
                    color: corptaxCustomLightTheme.colorNeutralForegroundOnBrand,
                },
                labelHovered: {
                    color: corptaxCustomLightTheme.colorNeutralStrokeOnBrand,
                },
                labelDisabled: {
                    ...baseButtonStyles.styles.labelDisabled,
                },
                flexContainer: {
                    ...baseButtonStyles.styles.flexContainer,
                },
            },
        },
        DefaultButton: {
            styles: {
                root: {
                    ...baseButtonStyles.styles.root,
                    backgroundColor: corptaxCustomLightTheme.colorTransparentBackground,
                    border: `${corptaxCustomLightTheme.strokeWidthThin} solid ${corptaxCustomLightTheme.colorNeutralStroke1}`,
                    ':focus-visible': {
                        ...baseButtonStyles.styles.rootFocused,
                    },
                },
                rootHovered: {
                    backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1Hover,
                    color: corptaxCustomLightTheme.colorBrandStroke1,
                    borderColor: corptaxCustomLightTheme.colorBrandStroke1,
                },
                rootDisabled: {
                    ...baseButtonStyles.styles.rootDisabled,
                },
                labelHovered: {
                    color: corptaxCustomLightTheme.colorBrandStroke1,
                },
                icon: {
                    display: 'flex',
                    alignItems: 'center',
                    ...baseIconStyles,
                },
                label: {
                    ...baseButtonStyles.styles.label,
                    color: corptaxCustomLightTheme.colorNeutralForeground1,
                },
                labelDisabled: {
                    ...baseButtonStyles.styles.labelDisabled,
                },
                flexContainer: {
                    ...baseButtonStyles.styles.flexContainer,
                },
                menuIcon: {
                    ...baseButtonStyles.styles.menuIcon,
                    ...baseIconStyles,
                },
            },
        },
        Combobox: {
            styles: {
                root: {
                    width: 200,
                    height: 32,
                    borderRadius: 4,
                    placeHolder: {
                        color: corptaxCustomLightTheme.colorNeutralForeground1,
                    },
                },
                rootDisabled: {
                    placeHolder: {
                        color: corptaxCustomLightTheme.colorNeutralForeground1,
                    },
                },
                label: {
                    fontStyle: 'Web/Body 1 Strong',
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 20,
                    textAlign: 'left',
                    fontFamily: 'Segoe UI',
                    color: corptaxCustomLightTheme.colorNeutralForeground1,
                },
                labelDisabled: {
                    color: corptaxCustomLightTheme.colorNeutralForeground1,
                },
            },
        },
    },
};
