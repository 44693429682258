import { useState } from 'react';
import { useCookies } from 'react-cookie';
import {
    DISPLAY_VARIANCE_THRESHOLD_COOKIE_NAME,
    SHOW_ONLY_EXCEEDING_ROWS_COOKIE_NAME,
    VARIANCE_THRESHOLD_COOKIE_NAME,
    VARIANCE_THRESHOLD_DISPLAY_FORMAT_COOKIE_NAME,
    VARIANCE_THRESHOLD_TYPE_COOKIE_NAME,
} from '../utils/BrowserStorageKeys.constants';
import { isUndefinedOrEmpty } from '../utils/StringUtils';
import { VARIANCE_DISPLAY_FORMAT, VARIANCE_TYPE } from '../utils/Variance.constants';

const useVarianceThreshold = (reportId: string = '') => {
    const reportVarianceThresholdCookieName = `${reportId}_${VARIANCE_THRESHOLD_COOKIE_NAME}`;
    const reportDisplayVarianceThresholdCookieName = `${reportId}_${DISPLAY_VARIANCE_THRESHOLD_COOKIE_NAME}`;
    const reportVarianceThresholdTypeCookieName = `${reportId}_${VARIANCE_THRESHOLD_TYPE_COOKIE_NAME}`;
    const reportVarianceThresholdDisplayFormatCookieName = `${reportId}_${VARIANCE_THRESHOLD_DISPLAY_FORMAT_COOKIE_NAME}`;
    const reportShowOnlyExceedingRowsCookieName = `${reportId}_${SHOW_ONLY_EXCEEDING_ROWS_COOKIE_NAME}`;

    const [cookies, setCookie, removeCookie] = useCookies([
        reportVarianceThresholdCookieName,
        reportDisplayVarianceThresholdCookieName,
        reportVarianceThresholdTypeCookieName,
        reportVarianceThresholdDisplayFormatCookieName,
        reportShowOnlyExceedingRowsCookieName,
    ]);
    const varianceThresholdCookieValue = cookies[reportVarianceThresholdCookieName];
    const displayVarianceThresholdCookieValue = cookies[reportDisplayVarianceThresholdCookieName];
    const varianceThresholdTypeCookieValue = cookies[reportVarianceThresholdTypeCookieName] ?? VARIANCE_TYPE.AMOUNT;
    const varianceThresholdDisplayFormatCookieValue =
        cookies[reportVarianceThresholdDisplayFormatCookieName] ?? VARIANCE_DISPLAY_FORMAT.AMOUNT;
    const showOnlyExceedingRowsCookieValue: boolean = cookies[reportShowOnlyExceedingRowsCookieName] ?? false;

    const [varianceThreshold, setVarianceThreshold] = useState<string | undefined>(varianceThresholdCookieValue);
    const [displayVarianceThreshold, setDisplayVarianceThreshold] = useState<string | undefined>(displayVarianceThresholdCookieValue);

    const [varianceDisplayFormat, setVarianceDisplayFormat] = useState<string | undefined>(varianceThresholdDisplayFormatCookieValue);
    const [varianceType, setVarianceType] = useState<string | undefined>(varianceThresholdTypeCookieValue);
    const [showOnlyExceedingRows, setShowOnlyExceedingRows] = useState<boolean>(showOnlyExceedingRowsCookieValue);

    const updateAllThresholdCookies = () => {
        updateVarianceThresholdCookie(varianceThreshold);
        updateDisplayVarianceThresholdCookie(displayVarianceThreshold);
        updateVarianceThresholdTypeCookie(varianceType);
        updateVarianceThresholdDisplayFormatCookie(varianceDisplayFormat);
        updateShowOnlyExceedingRowsCookie(showOnlyExceedingRows);
    };

    const updateVarianceThresholdCookie = (newValue: any) => {
        if (isUndefinedOrEmpty(newValue)) {
            removeCookie(reportVarianceThresholdCookieName, { path: '/' });
        } else {
            setCookie(reportVarianceThresholdCookieName, newValue);
        }
    };

    const updateDisplayVarianceThresholdCookie = (newValue: any) => {
        if (isUndefinedOrEmpty(newValue)) {
            removeCookie(reportDisplayVarianceThresholdCookieName, { path: '/' });
        } else {
            setCookie(reportDisplayVarianceThresholdCookieName, newValue);
        }
    };

    const updateVarianceThresholdTypeCookie = (newValue: any) => {
        setCookie(reportVarianceThresholdTypeCookieName, newValue ?? VARIANCE_TYPE.AMOUNT);
    };

    const updateVarianceThresholdDisplayFormatCookie = (newValue: any) => {
        setCookie(reportVarianceThresholdDisplayFormatCookieName, newValue ?? VARIANCE_DISPLAY_FORMAT.AMOUNT);
    };

    const updateShowOnlyExceedingRowsCookie = (newValue: boolean) => {
        setCookie(reportShowOnlyExceedingRowsCookieName, newValue?.toString());
    };

    return {
        varianceThresholdCookieValue,
        varianceThreshold,
        setVarianceThreshold,
        displayVarianceThresholdCookieValue,
        displayVarianceThreshold,
        setDisplayVarianceThreshold,
        varianceThresholdDisplayFormatCookieValue,
        varianceDisplayFormat,
        setVarianceDisplayFormat,
        varianceThresholdTypeCookieValue,
        varianceType,
        setVarianceType,
        showOnlyExceedingRowsCookieValue,
        showOnlyExceedingRows,
        setShowOnlyExceedingRows,
        updateVarianceThresholdCookie,
        updateVarianceThresholdTypeCookie,
        updateVarianceThresholdDisplayFormatCookie,
        updateAllThresholdCookies,
    };
};

export default useVarianceThreshold;
