import { IIndex } from '@syncfusion/ej2-react-grids';
import { useState } from 'react';
import { focusOnButtonContainedByCell } from '../utils/TableUtils';

export const usePersistFocusInTableAfterDatabound = () => {
    const [selectedCellIndex, setSelectedCellIndex] = useState<IIndex | undefined>(undefined);

    const handleDatabound = () => {
        if (selectedCellIndex) {
            focusOnButtonContainedByCell(selectedCellIndex);
            setSelectedCellIndex(undefined);
        }
    };

    return { selectedCellIndex, setSelectedCellIndex, handleDatabound };
};
