import { columnHeaderRole, elementNodeType, expandCollapseClass, msGroupHeaderTitleClass, rowRole } from './CardGridListConstants';
import { getGridCellElements } from './CardGridListUtilities';

export function doEffects(id: string): any {
    const root: HTMLElement | null = document.getElementById(id);

    if (root) {
        const mutationObserver: MutationObserver = new MutationObserver(handleMutation);

        makeListHeaderNotFocusable(root);

        mutationObserver.observe(root, {
            childList: true,
            subtree: true,
        });

        return () => {
            mutationObserver.disconnect();
        };
    }
}

function configureRowFocus(rowElement: HTMLElement, gridCellElements: HTMLElement[]) {
    let rowFocusable: boolean = false;

    for (const gridCellElement of Object.values<HTMLElement>(gridCellElements)) {
        if (gridCellElement.classList.contains(msGroupHeaderTitleClass)) {
            rowFocusable = true;
            break;
        }

        gridCellElement.setAttribute('data-is-focusable', 'true');
        gridCellElement.tabIndex = -1;
    }

    if (!rowFocusable) {
        rowElement.removeAttribute('data-is-focusable');
        rowElement.removeAttribute('tabindex');
    }
}

function handleMutation(mutations: MutationRecord[], observer: MutationObserver) {
    for (const mutationRecord of Object.values<MutationRecord>(mutations)) {
        processMutationRecord(mutationRecord);
    }
}

function makeListHeaderNotFocusable(componentRoot: HTMLElement) {
    const columnHeaderElements: NodeListOf<HTMLDivElement> = componentRoot.querySelectorAll(
        `div[role="${columnHeaderRole}"]`
    ) as NodeListOf<HTMLDivElement>;

    for (const columnHeaderElement of Object.values<HTMLDivElement>(columnHeaderElements)) {
        columnHeaderElement.setAttribute('data-is-focusable', 'false');
    }
}

function modifyRowAriaExpandedAttribute(rowElement: HTMLElement, gridCellElements: HTMLElement[]) {
    const canExpandCollapse: boolean = rowElement.querySelector(`.${expandCollapseClass}`) !== null;

    if (canExpandCollapse) {
        gridCellElements[0].setAttribute('aria-expanded', rowElement.getAttribute('aria-expanded') as string);
    } else {
        rowElement.removeAttribute('aria-expanded');
    }
}

function processAddedNode(node: Node) {
    // Only care about HTML elements
    if (node.nodeType !== elementNodeType) {
        return;
    }

    const addedElement: HTMLElement = node as HTMLElement;
    const rowElements: NodeListOf<HTMLDivElement> = addedElement.querySelectorAll(`div[role="${rowRole}"]`) as NodeListOf<HTMLDivElement>;

    for (const rowElement of Object.values<HTMLDivElement>(rowElements)) {
        processAddedRow(rowElement);
    }
}

function processAddedRow(rowElement: HTMLElement) {
    const gridCellElements: HTMLElement[] = getGridCellElements(rowElement);

    removeUnneededAriaHiddenElementsFromRow(rowElement);
    modifyRowAriaExpandedAttribute(rowElement, gridCellElements);
    configureRowFocus(rowElement, gridCellElements);
}

function processMutationRecord(mutationRecord: MutationRecord) {
    // Only care about HTML elements
    if (mutationRecord.target.nodeType !== elementNodeType) {
        return;
    }

    // Assuming the mutation type is 'childlist' since mutation observer was filtered for only that type
    for (const node of Object.values<Node>(mutationRecord.addedNodes)) {
        processAddedNode(node);
    }
}

function removeUnneededAriaHiddenElementsFromRow(rowElement: HTMLElement) {
    const iElements: HTMLCollectionOf<HTMLElement> = rowElement.getElementsByTagName('i') as HTMLCollectionOf<HTMLElement>;

    for (let index: number = 0; index < iElements.length; index++) {
        const iElement: HTMLElement = iElements[index];

        iElement.parentElement?.removeChild(iElement);
    }
}
