import { GridComponent } from '@syncfusion/ej2-react-grids';
import { RefObject } from 'react';
import { createFilterItem, IFilterItem } from '../data-types/filterItem';
import {
    IWhitePaperColumn,
    IWhitePaperRow,
    Report,
    ReportListEntityFilterOption,
    ReportListRequest,
    ReturnFolderDto,
    Statement,
} from '../model';

export const updateFilterItems = (
    items: Report[],
    filterItem: string,
    setFilterItem: (items: IFilterItem[]) => void,
    createFilterItem: (itemValue: string) => IFilterItem
) => {
    let filterItemKey = filterItem as keyof Report;
    const uniqueItems = Array.from(new Set(items.map((item) => item[filterItemKey] ?? '')));
    setFilterItem(uniqueItems.map((item) => createFilterItem(item.toString())));
};

export const isRowIncludedInStatement = (row: IWhitePaperRow, statementBeginRow: number, statementEndRow: number) => {
    return row.rowNumber !== undefined && row.rowNumber >= statementBeginRow && row.rowNumber <= statementEndRow;
};

export const findFirstVisibleRow = (rows: IWhitePaperRow[], statement: Statement): IWhitePaperRow | undefined => {
    if (statement.beginRow === undefined || statement.endRow === undefined) {
        return undefined;
    }

    let leftLimit = 0;
    let rightLimit = rows.length - 1;
    let middle = Math.floor((leftLimit + rightLimit) / 2);
    let firstVisibleRow: IWhitePaperRow | undefined = undefined;

    while (leftLimit <= rightLimit && (firstVisibleRow === undefined || firstVisibleRow.rowNumber !== statement.beginRow)) {
        const currentRow = rows[middle];

        if (isRowIncludedInStatement(currentRow, statement.beginRow, statement.endRow)) {
            firstVisibleRow = rows[middle];
            rightLimit = middle - 1;
        } else {
            if (currentRow.rowNumber && currentRow.rowNumber < statement.beginRow) {
                leftLimit = middle + 1;
            } else if (currentRow.rowNumber && currentRow.rowNumber > statement.endRow) {
                rightLimit = middle - 1;
            }
        }

        middle = Math.floor((leftLimit + rightLimit) / 2);
    }

    return firstVisibleRow;
};

export const getSectionsFilterItem = (rows: IWhitePaperRow[], statements: Statement[]): IFilterItem[] => {
    const result: IFilterItem[] = [];

    statements?.forEach((statement) => {
        const firstVisibleRow = findFirstVisibleRow(rows, statement);

        if (firstVisibleRow) {
            result.push({
                key: `${firstVisibleRow.rowNumber}`,
                label: `${statement.description}`,
                searchBy: [`${statement.description}`],
            });
        }
    });

    return result;
};

export const getEntitiesFilterItem = (items: ReportListEntityFilterOption[]) => {
    const entities: IFilterItem[] = [];

    items.forEach((item) => {
        if (!entities.some((e) => e.key === item.entityCode)) {
            entities.push({
                key: `${item.entityCode}`,
                label: `${item.entityCode} - ${item.entityName}`,
                searchBy: [item.entityCode ?? '', item.entityName ?? ''],
            });
        }
    });

    return entities;
};

export const getEntitiesFilterForColumns = (columns: IWhitePaperColumn[]) => {
    const entities: IFilterItem[] = [];

    columns.forEach((col) => {
        if (!entities.some((e) => e.key === col.fieldName)) {
            entities.push({
                key: `${col.fieldName}`,
                label: `${col.entityCode} - ${col.entityName}`,
                searchBy: [col.entityCode ?? '', col.entityName ?? ''],
            });
        }
    });

    return entities;
};

export const getFilterOptions = (availableFilterOptions: string[] | null | undefined): IFilterItem[] => {
    return availableFilterOptions?.map((option) => createFilterItem(option)) ?? [];
};

export const performFolderFiltering = (filters: IFilterItem[], folderItems: ReturnFolderDto[] | undefined, result: Report[]): Report[] => {
    let selectedFolders = new Set(filters.map((filter) => filter.key));
    let reportsForFolderItems = new Set(
        folderItems
            ?.filter((item) => {
                return selectedFolders.has(item.folderName ?? '');
            })
            ?.flatMap((item) => item.returnKeys)
    );
    result = result.filter((report) => {
        return reportsForFolderItems.has(report.id);
    });
    return result;
};

export const reorderCheckedItems = (checkedItems: IFilterItem[], allItems: IFilterItem[], filterName: string) => {
    const checkedSet = new Set(checkedItems.map((item) => item.key));
    const customSort = (a: IFilterItem, b: IFilterItem) => {
        if (filterName === 'case') {
            return parseInt(a.label, 10) - parseInt(b.label, 10);
        }

        return a.label.localeCompare(b.label);
    };

    const checkedSortedItems = [...checkedItems].sort(customSort);
    const uncheckedSortedItems = allItems.filter((item) => !checkedSet.has(item.key)).sort(customSort);

    return [...checkedSortedItems, ...uncheckedSortedItems];
};

export const getUpdatedSelectedFilters = (
    selectedItem: IFilterItem[],
    currentlySelectedItems: IFilterItem[],
    isSingleSelect: boolean = false
) => {
    let newSelectedItems: IFilterItem[] = [];

    if (selectedItem.length > 0) {
        if (isSingleSelect && currentlySelectedItems.some((x) => x.key === selectedItem[0].key)) {
            newSelectedItems = [];
        } else {
            newSelectedItems = [...selectedItem];
        }
    }

    return newSelectedItems;
};

export const getSelectedTaxReturnsAndFilters = (
    gridComponentRef: RefObject<GridComponent>,
    getTaxReturnsRequestFunc: { (): ReportListRequest; (): ReportListRequest }
) => {
    if (gridComponentRef && gridComponentRef.current && gridComponentRef.current.selectionModule) {
        const selectedTaxReturns = gridComponentRef.current.selectionModule.getSelectedRecords();
        const filters = getTaxReturnsRequestFunc();
        return { selectedTaxReturns, filters };
    }
    return {};
};

export const getFilterMenuAriaLabel = (labelName: string, t: (value: string) => string) => {
    return `${t(labelName)} ${t('filter')}`;
};
