import { StackItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { CommentRichTextEditor, ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';
import { ErrorDisplay } from './ErrorDisplay';

export interface IEditCommentProps {
    originalCommentHtml?: string | null;
    onCancel: () => void;
    onSave: (value: ICommentRichTextEditorValue) => void;
    onChange: (hasChanges: boolean) => void;
    errorMessage?: string;
}

export const EditComment = (props: IEditCommentProps) => {
    const { t } = useTranslation();
    const saveButtonText = t('save').toString();
    const cancelButtonText = t('cancel').toString();
    const commentInputAriaLabelText = t('commentInputAriaLabel').toString();
    const commentInputPlaceholderText = t('commentInputPlaceholder').toString();
    const savingButtonText = t('savingCommentButtonText').toString();

    const handleSaveClicked = (value: ICommentRichTextEditorValue) => {
        props.onSave(value);
    };

    const handleChange = (value?: ICommentRichTextEditorValue) => {
        const isDirtyAndValid = (value && value.commentText.trim() !== '' && value?.commentHtml !== props.originalCommentHtml) ?? false;
        props.onChange(isDirtyAndValid);
    };

    return (
        <StackItem>
            <StackItem tokens={{ padding: 5 }}>
                <CommentRichTextEditor
                    commentHtml={props.originalCommentHtml ?? undefined}
                    commentInputAriaLabel={commentInputAriaLabelText}
                    commentInputPlaceholder={commentInputPlaceholderText}
                    saveButtonAriaLabel={saveButtonText}
                    saveButtonText={saveButtonText}
                    onSaveButtonClick={handleSaveClicked}
                    cancelButtonText={cancelButtonText}
                    cancelButtonAriaLabel={cancelButtonText}
                    onCancelButtonClick={props.onCancel}
                    processingText={savingButtonText}
                    onChange={handleChange}
                />
            </StackItem>
            <StackItem>
                <ErrorDisplay errorMessage={props.errorMessage} />
            </StackItem>
        </StackItem>
    );
};
