export const getInitialsOfUserName = (name: string): string => {
    if (!name) {
        return '';
    }
    const portions = name.split(' ').map((val) => val[0]);

    return portions
        .slice(0, 2)
        .reduce((a, b) => a + b, '')
        .toUpperCase();
};
