import { DefaultButton, IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from '../../common/SpinnerButton';

export interface IFilterActionBarProps {
    primaryHandler: (event: React.MouseEvent<HTMLElement>) => void;
    primaryButtonLabel: string;
    primaryButtonId?: string;
    primaryButtonDisabled?: boolean;
    primaryButtonProcessing?: boolean;
    primaryButtonProcessingLabel?: string;
    secondaryHandler: (event: React.MouseEvent<HTMLElement>) => void;
    secondaryButtonLabel: string;
    secondaryButtonDisabled?: boolean;
    wrapperStyles?: string;
}

const FilterActionBar: React.FC<IFilterActionBarProps> = (props: IFilterActionBarProps) => {
    const { t } = useTranslation();

    const defaultGapStackTokens: IStackTokens = {
        childrenGap: '10px',
    };

    const filterActionContainerStyle =
        props.wrapperStyles ??
        mergeStyles({
            padding: '16px 20px',
        });

    return (
        <Stack horizontalAlign='end' role='menuitem' horizontal tokens={defaultGapStackTokens} className={filterActionContainerStyle}>
            <SpinnerButton
                ariaLabel={t(props.primaryButtonLabel)}
                text={t(props.primaryButtonLabel)}
                onClick={props.primaryHandler}
                disabled={props.primaryButtonDisabled ?? false}
                isProcessing={props.primaryButtonProcessing ?? false}
                processingText={props.primaryButtonProcessingLabel ? t(props.primaryButtonProcessingLabel) : t('processing')}
            ></SpinnerButton>
            <DefaultButton
                ariaLabel={t(props.secondaryButtonLabel).toString()}
                onClick={props.secondaryHandler}
                disabled={props.secondaryButtonDisabled}
            >
                {t(props.secondaryButtonLabel)}
            </DefaultButton>
        </Stack>
    );
};

export default FilterActionBar;
