import { DateTimeDisplay } from '@corptax/react-components-common';
import { IContextualMenuItem, mergeStyles, Stack, StackItem, Text } from '@fluentui/react';
import { Avatar } from '@fluentui/react-components';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommentsUI } from '../../hooks/useCommentsUI';
import { Conversation, DeleteConversationRequest, UpdateConversationRequest } from '../../model';
import { CommentsActionType } from '../../reducers/commentsStateReducer';
import { CommentContextualMenuButton } from './CommentContextualMenuButton';
import { EditComment } from './EditComment';
import { ICommentRichTextEditorValue } from './editor/CommentRichTextEditor';
import { ViewComment } from './ViewComment';

// todo: ditch dependence on Orval model
export interface IConversationItemProps {
    conversation: Conversation;
    commentToHighlightRef?: RefObject<HTMLDivElement>;
    onMenuOpened: () => void;
    onConversationUpdated: (value: ICommentRichTextEditorValue) => void;
}

export const ConversationItem = (props: IConversationItemProps) => {
    const { state: uiState, dispatch: dispatchUIState } = useCommentsUI();
    const { t } = useTranslation();

    const showDelete = props.conversation.allowDelete;
    const showEdit = props.conversation.allowModification;
    const updateRequest = uiState.conversationUpdateRequest;
    const isHighlighted = uiState.highlightedCommentId && uiState.highlightedCommentId === props.conversation.id?.toLowerCase();
    const isEdit = updateRequest !== null && uiState.selectedCommentId === props.conversation.id;
    const isConversationSelected = props.conversation.id === uiState.selectedCommentId;
    const operationErrorMessage =
        props.conversation?.id === uiState.selectedCommentId && uiState.operationFailedErrorMessage
            ? uiState.operationFailedErrorMessage
            : undefined;
    const submitErrorMessage =
        props.conversation?.id === uiState.selectedCommentId && uiState.submitFailedErrorMessage
            ? uiState.submitFailedErrorMessage
            : undefined;
    const errorMessage = (submitErrorMessage || operationErrorMessage) ?? undefined;
    const iconClass: string = mergeStyles({
        '& svg': {
            verticalAlign: '-0.125em',
            height: '16px',
            width: '16px',
        },
    });

    const handleDeleteCommentClick = () => {
        var parent = props.conversation.parent;
        if (parent && parent.locationId && parent.parentLocationId && parent.id && props.conversation.id) {
            var deleteRequest: DeleteConversationRequest = {
                locationId: parent.locationId,
                parentLocationId: parent.parentLocationId,
                collaborationId: parent.id,
                conversationId: props.conversation.id,
            };
            dispatchUIState({
                type: CommentsActionType.DeleteConversation,
                conversationDeleteRequest: deleteRequest,
                hasReplies: (props.conversation.replies?.length ?? 0) > 0,
            });
        }
    };

    const handleUpdateCommentClick = () => {
        var parent = props.conversation.parent;
        if (parent && parent.locationId && parent.parentLocationId && parent.id && props.conversation.id) {
            var updateRequest: UpdateConversationRequest = {
                locationId: parent.locationId,
                parentLocationId: parent.parentLocationId,
                collaborationId: parent.id,
                conversationId: props.conversation.id,
            };
            dispatchUIState({ type: CommentsActionType.EditConversation, conversationUpdateRequest: updateRequest });
        }
    };

    const handleCancelUpdate = () => {
        dispatchUIState({ type: CommentsActionType.CancelOperation });
    };

    const handleCommentUpdate = async (value: ICommentRichTextEditorValue) => {
        props.onConversationUpdated(value);
    };

    const handleCommentValueChange = (hasValidChangesCommentValue: boolean) => {
        if (hasValidChangesCommentValue) {
            dispatchUIState({ type: CommentsActionType.MadeChanges });
        } else {
            dispatchUIState({ type: CommentsActionType.UndoMadeChanges });
        }
    };

    const menuItems = new Array<IContextualMenuItem>();
    if (showDelete) {
        const deleteText = t('deleteComment').toString();
        menuItems.push({
            key: 'deleteComment',
            name: deleteText,
            ariaLabel: deleteText,
            onClick: handleDeleteCommentClick,
            iconProps: {
                iconName: 'FDL2DeleteRegular24',
                className: iconClass,
            },
        });
    }

    if (showEdit) {
        const editText = t('editComment').toString();
        menuItems.push({
            key: 'editComment',
            name: editText,
            ariaLabel: editText,
            onClick: handleUpdateCommentClick,
            iconProps: {
                iconName: 'FDL2EditRegular24',
                className: iconClass,
            },
        });
    }

    const showMenu = menuItems && menuItems.length > 0;

    return (
        <div
            ref={isHighlighted ? props.commentToHighlightRef : undefined}
            data-is-focusable={isConversationSelected}
            tabIndex={isConversationSelected ? 0 : -1}
            data-is-focuszone-keypress-skip={isConversationSelected}
        >
            <Stack horizontal style={{ padding: '10px' }} tokens={{ childrenGap: 10 }}>
                <StackItem>
                    <span>
                        <Avatar
                            name={props.conversation.authorName ?? 'Unknown'}
                            color='colorful'
                            idForColor={props.conversation.authorName ?? 'Unknown'}
                            size={24}
                        />
                    </span>
                </StackItem>
                <StackItem grow>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal horizontalAlign='space-between'>
                            <StackItem grow>
                                <div>
                                    <Text>{props.conversation.authorName}</Text>
                                </div>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <DateTimeDisplay timestamp={props.conversation.timestamp!} format='LocaleDateTime' />
                                    {props.conversation.modified && <Text>Edited</Text>}
                                </Stack>
                            </StackItem>
                            <CommentContextualMenuButton
                                showMenuButton={showMenu}
                                menuItems={menuItems}
                                onMenuOpen={props.onMenuOpened}
                                onMenuDismissed={() => {}}
                                isInTabOrder={isConversationSelected}
                            />
                        </Stack>
                        {isEdit ? (
                            <EditComment
                                originalCommentHtml={props.conversation.comment}
                                onCancel={handleCancelUpdate}
                                onSave={handleCommentUpdate}
                                errorMessage={errorMessage}
                                onChange={handleCommentValueChange}
                            />
                        ) : (
                            <ViewComment comment={props.conversation.comment} />
                        )}
                    </Stack>
                </StackItem>
            </Stack>
        </div>
    );
};
