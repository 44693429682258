import * as FluentUiIcons from '@fluentui/react-icons';
import { CSSProperties } from 'react';
import { IconName } from '../../utils/Icons.constants';

export interface IIconProps {
    iconName: IconName;
    id?: string;
    className?: string;
    style?: CSSProperties;
    role?: string;
}

const Icon: React.FC<IIconProps> = ({ iconName, id, className, style, role }) => {
    const propertyMatchingIconName: any = FluentUiIcons[iconName];
    const isValidIcon = propertyMatchingIconName && propertyMatchingIconName.render && propertyMatchingIconName.displayName === iconName;
    const IconElement = isValidIcon ? (propertyMatchingIconName as FluentUiIcons.FluentIcon) : null;

    return IconElement ? <IconElement id={id} className={className} style={style} role={role ?? 'img'} data-image-name={iconName} /> : null;
};

export default Icon;
