import { Icon, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CalculateButtonProps {
    onClick: () => void;
    disabled: boolean;
}

const CalculateButton: React.FC<CalculateButtonProps> = ({ onClick, disabled }) => {
    const { t } = useTranslation();

    return (
        <PrimaryButton onClick={onClick} disabled={disabled} key='rerunCalculationButton'>
            <Icon iconName='Calculator20Regular' style={{ marginTop: '5px' }} />
            {t('rerunCalculation').toString()}
        </PrimaryButton>
    );
};

export default CalculateButton;
