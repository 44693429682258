// We manually added this API client to support on the account related logic
// Do not delete
// Orval does not update this file
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';
import type { Claims } from '../model/claims';

export const useGetClaimsHook = () => {
    const getClaims = useCustomInstance<Claims[]>();

    return (signal?: AbortSignal) => {
        return getClaims({ url: `/bff/user`, method: 'get', signal });
    };
};

export const getGetClaimsQueryKey = () => [`/bff/user`] as const;

export const useGetClaimsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetClaimsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClaimsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClaimsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetClaimsQueryKey();

    const getClaims = useGetClaimsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClaimsHook>>>> = ({ signal }) => getClaims(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAllReportsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClaimsHook>>>>;
export type GetAllReportsQueryError = ErrorType<unknown>;

export const useGetClaims = <TData = Awaited<ReturnType<ReturnType<typeof useGetClaimsHook>>>, TError = ErrorType<unknown>>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClaimsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetClaimsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};
