import { useBoolean } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';
import { useGridState } from '../../../hooks/useGridState';
import { useVarianceNavigation } from '../../../hooks/useVarianceNavigation';
import useVarianceThreshold from '../../../hooks/useVarianceThreshold';
import { scrollToTop } from '../../../utils/TableUtils';
import IconButtonWithCallout from '../../common/IconButtonWithCallout';
import VarianceThresholdPanel from './VarianceThresholdPanel';

interface IVarianceThresholdButtonProps {
    disabled?: boolean;
    reportId: number;
}

const VarianceThresholdButton: React.FC<IVarianceThresholdButtonProps> = (props: IVarianceThresholdButtonProps) => {
    const { t } = useTranslation();
    const [isDialogVisible, { toggle: toggleIsDialogVisible }] = useBoolean(false);
    const {
        varianceThreshold,
        setVarianceThreshold,
        displayVarianceThreshold,
        setDisplayVarianceThreshold,
        varianceDisplayFormat,
        setVarianceDisplayFormat,
        varianceType,
        setVarianceType,
        updateAllThresholdCookies,
        showOnlyExceedingRows,
        setShowOnlyExceedingRows,
    } = useVarianceThreshold(props.reportId.toString());
    const { grid } = useGridState();
    const { setCurrentVarianceIndex } = useVarianceNavigation();

    const saveChangesAndCloseDialog = () => {
        updateAllThresholdCookies();
        setCurrentVarianceIndex(0);
        toggleIsDialogVisible();
        scrollToTop(grid.current);
    };

    const compareButtonId = `setVarianceThresholdContainer-button`;

    return (
        <IconButtonWithCallout
            buttonId={compareButtonId}
            iconName='WindowSettings20Regular'
            iconPosition='before'
            label={t('setVarianceThreshold').toString()}
            isDialogVisible={isDialogVisible}
            toggleDialogVisible={toggleIsDialogVisible}
            onCalloutSubmit={saveChangesAndCloseDialog}
            disabled={props.disabled}
        >
            <VarianceThresholdPanel
                varianceThreshold={varianceThreshold}
                displayVarianceThreshold={displayVarianceThreshold}
                varianceType={varianceType}
                varianceDisplayFormat={varianceDisplayFormat}
                shouldShowOnlyExceedingRows={showOnlyExceedingRows}
                setVarianceThreshold={setVarianceThreshold}
                setDisplayVarianceThreshold={setDisplayVarianceThreshold}
                setVarianceType={setVarianceType}
                setVarianceDisplayFormat={setVarianceDisplayFormat}
                saveChangesAndCloseDialog={saveChangesAndCloseDialog}
                toggleIsDialogVisible={toggleIsDialogVisible}
                setShouldShowOnlyExceedingRows={setShowOnlyExceedingRows}
            />
        </IconButtonWithCallout>
    );
};

export default VarianceThresholdButton;
