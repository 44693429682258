import { IExecutionStatusStorage } from '../data-types/IExecutionStatusStorage';
import { CalculationExecutionStatusCode } from '../model';

export const shouldInvalidateDependentQueries = (
    taxReturnItemKey: number,
    taxReturnCalculation: IExecutionStatusStorage,
    previousStateList: IExecutionStatusStorage[]
) => {
    const previousValue = previousStateList.find((item) => item.taxReturnItemKey === taxReturnItemKey);

    return (
        (taxReturnCalculation.statusCode === CalculationExecutionStatusCode.Completed ||
            taxReturnCalculation.statusCode === CalculationExecutionStatusCode.Canceled) &&
        previousValue?.statusCode === CalculationExecutionStatusCode.Executing
    );
};
