import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export const usePreventNavigation = (shouldPreventNavigation: boolean) => {
    const browserNativePreventNavigation = (event: any) => {
        if (shouldPreventNavigation) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('beforeunload', browserNativePreventNavigation);

        return () => {
            window.removeEventListener('beforeunload', browserNativePreventNavigation);
        };
    }, [shouldPreventNavigation]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => shouldPreventNavigation && currentLocation.pathname !== nextLocation.pathname
    );

    return { blocker };
};
