export interface IFilterItem {
    key: string;
    label: string;
    searchBy: string[];
}

export const createFilterItem = (itemValue: string, itemKey?: string): IFilterItem => {
    return {
        key: itemKey ?? itemValue,
        label: itemValue,
        searchBy: [itemValue],
    };
};
