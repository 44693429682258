import { useCorptaxTheme } from '@corptax/react-components-common';
import { mergeStyles, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import React from 'react';
import { IAppTheme } from '../../theme/IAppTheme';
import { setConditionalTimeout } from '../../utils/timerUtils';

export type SpinnerLabelPositionType = 'top' | 'right' | 'bottom' | 'left';

export interface ILoadingSpinnerProps {
    id?: string;
    label?: string;
    containerStyle?: string;
    children?: React.ReactNode;
    labelPosition?: SpinnerLabelPositionType;
    renderDelay?: number;
}

const headerHeight = 300;

const defaultLoadingSpinnerStyle = mergeStyles({
    height: `calc(100vh - ${headerHeight}px)`,
    width: 'calc(100vw - 315px)',
});

const CustomLoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
    id,
    label,
    containerStyle,
    children,
    labelPosition = 'left',
    renderDelay = 250,
}) => {
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const [showSpinner, { setTrue: setShowSpinner }] = useBoolean(renderDelay === 0);

    if (!showSpinner && renderDelay > 0) {
        setConditionalTimeout(
            () => {
                setShowSpinner();
            },
            true,
            renderDelay
        );
    }
    const spinnerStyle = mergeStyles({
        selectors: {
            '[class*="circle"]': {
                borderWidth: '2px 2px 2px',
                borderColor: `${customPalette.blueCerulean} ${customPalette.lightCeruleanBlue} ${customPalette.lightCeruleanBlue}`,
            },
        },
    });

    return (
        <Stack id={id} enableScopedSelectors verticalAlign='center' verticalFill className={containerStyle ?? defaultLoadingSpinnerStyle}>
            <Stack.Item align='center' className={mergeStyles({ display: 'flex', flexDirection: 'column', gap: '20px' })}>
                {showSpinner ? (
                    <>
                        <Spinner
                            ariaLive='polite'
                            ariaLabel={label}
                            label={label}
                            labelPosition={labelPosition}
                            className={spinnerStyle}
                            size={SpinnerSize.large}
                        />
                        {children}
                    </>
                ) : null}
            </Stack.Item>
        </Stack>
    );
};

export default CustomLoadingSpinner;
