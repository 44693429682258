import { useCorptaxTheme } from '@corptax/react-components-common';
import { IRawStyle, mergeStyles } from '@fluentui/react';
import { FC } from 'react';
import { SelectedWhitePaperCell } from '../../data-types/selectedWhitePaperCell';
import { IAppTheme } from '../../theme/IAppTheme';

export interface IAccountAmountSectionProps {
    data?: SelectedWhitePaperCell;
}

const AccountAmountSection: FC<IAccountAmountSectionProps> = ({ data }) => {
    const headerText = data?.description;
    const amount = data?.valueEx;
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const baseTextStyles: IRawStyle = {
        color: `var(--type-colors-primary, ${customPalette.blueDark})`,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
    };
    const rootClass: string = mergeStyles({
        display: 'flex',
        displayName: 'accountAmountSection',
        backgroundColor: customPalette.lightBlue,
        borderRadius: '4px',
        alignItems: 'center',
        boxShadow: '0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10), 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13)',
        padding: '4px 16px',
        justifyContent: 'space-between',
    });
    const descriptionClass: string = mergeStyles(baseTextStyles, { color: customPalette.grey222222 });
    const amountClass: string = mergeStyles(baseTextStyles, { fontWeight: 700 });

    return (
        <div id='accountAmountSection' className={rootClass}>
            <div className={descriptionClass}>
                <span>{headerText}</span>
            </div>
            <div className={amountClass}>
                <span>{amount}</span>
            </div>
        </div>
    );
};

export default AccountAmountSection;
