import { IContextualMenuItem } from '@fluentui/react';
import { getFilteredItemsOrEmptyValue } from '../utils/ContextualMenuUtils';

export const useFilterItemContextualMenuSearch = (
    contextualMenuItems: IContextualMenuItem[],
    noResultsText: string,
    setMenuItems: (newMenuItems: IContextualMenuItem[]) => void,
    setSearchValue?: (searchValue: string) => void
): ((newValue: string | undefined) => void) => {
    return (newValue: string | undefined) => {
        let filteredMenuItems: IContextualMenuItem[] = getFilteredItemsOrEmptyValue(contextualMenuItems, noResultsText, newValue);

        setMenuItems(filteredMenuItems);
        if (setSearchValue) {
            setSearchValue(newValue ?? '');
        }
    };
};
