import { useContext } from 'react';
import { CommentsUIStateContext } from '../contexts/commentsUIStateContext';

export function useCommentsUI() {
    const context = useContext(CommentsUIStateContext);
    if (!context) {
        throw new Error('useCommentsUI must be wrapped in a CommentsUIStateContext');
    }
    return context;
}
