import { Spinner, SpinnerSize } from '@fluentui/react';
import styled from 'styled-components';

export interface IOverlaySpinnerProps {
    showOverlay: boolean;
    label: string;
    children: React.ReactNode;
}

const StyledOverlayDivContainer = styled.div`
    position: relative;
`;

const StyledOverlayDiv = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: ${(props) => props.theme.brandPalette.overlayLight};
`;

const StyledOverlayDivContent = styled.div`
    position: absolute;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    top: min(50%, 50vh);
    left: 0;
    right: 0;
    text-align: center;
    color: #555;
`;

export const OverlaySpinner = (props: IOverlaySpinnerProps) => {
    if (!props.showOverlay) {
        return <>{props.children}</>;
    }
    return (
        <StyledOverlayDivContainer>
            <StyledOverlayDiv>
                <StyledOverlayDivContent>
                    <Spinner
                        ariaLive='polite'
                        ariaLabel={props.label}
                        label={props.label}
                        labelPosition='bottom'
                        size={SpinnerSize.large}
                    />
                </StyledOverlayDivContent>
            </StyledOverlayDiv>
            {props.children}
        </StyledOverlayDivContainer>
    );
};
