export class ArrayUtils extends Array {
    private constructor() {
        super();
    }

    public static ensureArray<T>(value: T | T[] | null | undefined): T[] {
        if (value) {
            return Array.isArray(value) ? value : [value];
        }

        return [];
    }

    public static isNonEmptyArray(value: any, interpretNonArrayAsSingleElementArray: boolean = false) {
        if (ArrayUtils.isArray(value)) {
            return value.length > 0;
        } else {
            return value !== null && value !== undefined && interpretNonArrayAsSingleElementArray;
        }
    }
}
