import { useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, ITooltipHostProps, mergeStyleSets, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import React, { PropsWithChildren } from 'react';
import { IAppTheme } from '../../theme/IAppTheme';
import { getTooltipHostProps } from '../../utils/CustomStyles';

export interface DefaultTooltipHostProps {
    tooltipContent: string | null | undefined;
    tooltipId: string | null | undefined;
    alwaysDisplay?: boolean;
}

const DefaultTooltipHost: React.FC<PropsWithChildren<DefaultTooltipHostProps>> = ({
    children,
    tooltipContent,
    tooltipId,
    alwaysDisplay,
}) => {
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const getReportsTableStyles = () =>
        mergeStyleSets({
            truncateText: {
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '1.5em',
            },
        });
    const { truncateText } = getReportsTableStyles();

    const tooltipHostProps: ITooltipHostProps = { ...getTooltipHostProps(DirectionalHint.rightCenter, undefined, customPalette) };
    tooltipHostProps.content = tooltipContent ?? '';
    tooltipHostProps.id = `${tooltipId ?? ''} tooltip`;
    tooltipHostProps.overflowMode = !alwaysDisplay ? TooltipOverflowMode.Self : undefined;

    return (
        <TooltipHost {...tooltipHostProps} hostClassName={truncateText}>
            {children}
        </TooltipHost>
    );
};

export default DefaultTooltipHost;
