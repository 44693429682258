import React from 'react';
import { VarianceNavigationContext } from '../contexts/varianceNavigationContext';

export function useVarianceNavigation() {
    const context = React.useContext(VarianceNavigationContext);
    if (!context) {
        throw new Error('useVarianceNavigation must be wrapped in a VarianceNavigationContextProvider');
    }
    return context;
}
