import { CommentsCurrentType, ICommentsUIState } from '../data-types/ICommentsUIState';
import { DeleteConversationRequest, DeleteReplyRequest, UpdateConversationRequest } from '../model';
import { UpdateReplyRequest } from '../model/updateReplyRequest';

export enum CommentsActionType {
    AddNewConversation,
    AddNewConversationSuccess,
    EditConversation,
    EditConversationSuccess,
    AddNewReply,
    AddNewReplySuccess,
    EditReply,
    EditReplySuccess,
    DeleteConversation,
    DeleteConversationSuccess,
    DeleteReply,
    DeleteReplySuccess,
    CancelOperation,
    MadeChanges,
    UndoMadeChanges,
    SelectedComment,
    OperationFailed,
    Submitting,
    SubmitError,
}

export type CommentStateAllowedAction =
    | { type: CommentsActionType.AddNewConversation }
    | { type: CommentsActionType.AddNewConversationSuccess }
    | {
          type: CommentsActionType.EditConversation;
          conversationUpdateRequest: UpdateConversationRequest;
      }
    | { type: CommentsActionType.EditConversationSuccess }
    | { type: CommentsActionType.AddNewReply; selectedConversationId: string | null }
    | { type: CommentsActionType.AddNewReplySuccess }
    | { type: CommentsActionType.EditReply; replyUpdateRequest: UpdateReplyRequest }
    | { type: CommentsActionType.EditReplySuccess }
    | { type: CommentsActionType.DeleteConversation; conversationDeleteRequest: DeleteConversationRequest; hasReplies: boolean }
    | { type: CommentsActionType.DeleteConversationSuccess }
    | { type: CommentsActionType.DeleteReply; replyDeleteRequest: DeleteReplyRequest }
    | { type: CommentsActionType.DeleteReplySuccess }
    | { type: CommentsActionType.CancelOperation }
    | { type: CommentsActionType.MadeChanges }
    | { type: CommentsActionType.UndoMadeChanges }
    | { type: CommentsActionType.SelectedComment; selectedId: string | null }
    | { type: CommentsActionType.OperationFailed; errorMessage: string | null }
    | { type: CommentsActionType.Submitting }
    | { type: CommentsActionType.SubmitError; errorMessage?: string | null };

export const initialUIState: ICommentsUIState = {
    currentUiState: CommentsCurrentType.ViewingComments,
    selectedCommentId: null,
    selectedReplyId: null,
    openCommentId: null,
    highlightedCommentId: null,
    showDeleteDialog: false,
    isSubmitting: false,
    hasPendingChanges: false,
    conversationUpdateRequest: null,
    conversationDeleteRequest: null,
    replyUpdateRequest: null,
    replyDeleteRequest: null,
    hasReplies: false,
    operationFailedErrorMessage: null,
    submitFailedErrorMessage: null,
};

export function commentsStateReducer(state: ICommentsUIState, action: CommentStateAllowedAction): ICommentsUIState {
    let newState = { ...state };

    switch (action.type) {
        case CommentsActionType.MadeChanges: {
            newState.hasPendingChanges = true;
            break;
        }
        case CommentsActionType.UndoMadeChanges: {
            newState.hasPendingChanges = false;
            break;
        }
        case CommentsActionType.Submitting: {
            newState.isSubmitting = true;
            newState.showDeleteDialog = false;
            break;
        }
        case CommentsActionType.SubmitError: {
            newState.isSubmitting = false;
            newState.submitFailedErrorMessage = action.errorMessage ?? null;
            break;
        }
        case CommentsActionType.CancelOperation: {
            newState.currentUiState = CommentsCurrentType.ViewingComments;
            newState.hasPendingChanges = false;
            newState.showDeleteDialog = false;
            newState.conversationUpdateRequest = null;
            newState.conversationDeleteRequest = null;
            newState.replyUpdateRequest = null;
            newState.replyDeleteRequest = null;
            newState.operationFailedErrorMessage = null;
            newState.submitFailedErrorMessage = null;
            newState.openCommentId = null;
            newState.highlightedCommentId = null;
            break;
        }
        case CommentsActionType.SelectedComment: {
            if (!state.hasPendingChanges) {
                newState.selectedCommentId = action.selectedId;
                newState.hasPendingChanges = false;
                newState.showDeleteDialog = false;
                newState.conversationUpdateRequest = null;
                newState.conversationDeleteRequest = null;
                newState.replyDeleteRequest = null;
                newState.operationFailedErrorMessage = null;
                newState.submitFailedErrorMessage = null;
            }
            break;
        }
        case CommentsActionType.AddNewConversation: {
            newState.currentUiState = CommentsCurrentType.AddingNewConversation;
            newState.selectedCommentId = null;
            newState.hasPendingChanges = true;
            break;
        }
        case CommentsActionType.AddNewConversationSuccess: {
            newState.currentUiState = CommentsCurrentType.ViewingComments;
            newState.hasPendingChanges = false;
            newState.isSubmitting = false;
            newState.operationFailedErrorMessage = null;
            newState.submitFailedErrorMessage = null;
            break;
        }
        case CommentsActionType.AddNewReply: {
            newState.currentUiState = CommentsCurrentType.AddingNewReply;
            newState.conversationUpdateRequest = null;
            newState.replyUpdateRequest = null;
            newState.openCommentId = action.selectedConversationId;
            break;
        }
        case CommentsActionType.AddNewReplySuccess: {
            newState.currentUiState = CommentsCurrentType.ViewingComments;
            newState.hasPendingChanges = false;
            newState.isSubmitting = false;
            newState.operationFailedErrorMessage = null;
            newState.submitFailedErrorMessage = null;
            newState.openCommentId = null;
            break;
        }
        case CommentsActionType.EditConversation: {
            newState.currentUiState = CommentsCurrentType.EditingConversation;
            newState.conversationUpdateRequest = action.conversationUpdateRequest;
            newState.selectedCommentId = action.conversationUpdateRequest.conversationId;
            newState.openCommentId = null;
            break;
        }
        case CommentsActionType.EditConversationSuccess: {
            newState.currentUiState = CommentsCurrentType.ViewingComments;
            newState.conversationUpdateRequest = null;
            newState.hasPendingChanges = false;
            newState.isSubmitting = false;
            newState.operationFailedErrorMessage = null;
            newState.submitFailedErrorMessage = null;
            break;
        }
        case CommentsActionType.DeleteConversation: {
            newState.currentUiState = CommentsCurrentType.DeletingConversation;
            newState.showDeleteDialog = true;
            newState.conversationDeleteRequest = action.conversationDeleteRequest;
            newState.hasReplies = action.hasReplies;
            break;
        }
        case CommentsActionType.DeleteConversationSuccess: {
            newState.currentUiState = CommentsCurrentType.ViewingComments;
            newState.selectedCommentId = null;
            newState.conversationDeleteRequest = null;
            newState.isSubmitting = false;
            newState.operationFailedErrorMessage = null;
            newState.submitFailedErrorMessage = null;
            newState.showDeleteDialog = false;
            break;
        }
        case CommentsActionType.DeleteReply: {
            newState.currentUiState = CommentsCurrentType.DeletingReply;
            newState.showDeleteDialog = true;
            newState.replyDeleteRequest = action.replyDeleteRequest;
            break;
        }
        case CommentsActionType.DeleteReplySuccess: {
            newState.currentUiState = CommentsCurrentType.ViewingComments;
            newState.selectedCommentId = null;
            newState.replyDeleteRequest = null;
            newState.isSubmitting = false;
            newState.operationFailedErrorMessage = null;
            newState.submitFailedErrorMessage = null;
            newState.showDeleteDialog = false;
            break;
        }
        case CommentsActionType.EditReply: {
            newState.currentUiState = CommentsCurrentType.EditingReply;
            newState.replyUpdateRequest = action.replyUpdateRequest;
            newState.selectedCommentId = action.replyUpdateRequest.conversationId;
            newState.selectedReplyId = action.replyUpdateRequest.replyId;
            newState.openCommentId = null;
            break;
        }
        case CommentsActionType.EditReplySuccess: {
            newState.currentUiState = CommentsCurrentType.ViewingComments;
            newState.replyUpdateRequest = null;
            newState.hasPendingChanges = false;
            newState.isSubmitting = false;
            newState.operationFailedErrorMessage = null;
            newState.submitFailedErrorMessage = null;
            newState.openCommentId = null;
            break;
        }
        case CommentsActionType.OperationFailed: {
            newState.operationFailedErrorMessage = action.errorMessage;
            break;
        }
    }
    return newState;
}
