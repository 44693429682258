import { FC } from 'react';
import { useIsFeatureEnabled } from '../../api/features/features';
import AnalyticsReport from './AnalyticsReport';

const Analytics: FC = () => {
    const { data: isAnalyticsPreviewEnabled, isLoading: isFeatureFlagLoading } = useIsFeatureEnabled({
        featureFlagName: 'AnalyticsPreviewEnabled',
    });

    if (isFeatureFlagLoading) {
        return null;
    }

    if (!isAnalyticsPreviewEnabled) {
        window.location.replace(window.location.href.substring(0, window.location.href.indexOf('/analytics')));
    }

    return <AnalyticsReport />;
};

export default Analytics;
