import { useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, getId, TooltipOverflowMode } from '@fluentui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IUnitTestProps } from '../../data-types/IUnitTestProps';
import { SelectedWhitePaperCell } from '../../data-types/selectedWhitePaperCell';
import { IWhitePaperColumn, IWhitePaperMappedRowDictionary, Report } from '../../model';
import { IAppTheme } from '../../theme/IAppTheme';
import { getTooltipHostProps } from '../../utils/CustomStyles';
import { mergeStylesOnDemand } from '../../utils/StyleUtils';
import CardGridList from '../card-grid-list/CardGridList';
import { groupKeyPrefix } from '../card-grid-list/CardGridListConstants';
import {
    ICardGridCellTooltip,
    ICardGridList,
    ICardGridListCellStyle,
    ICardGridListColumn,
    ICardGridListGroup,
} from '../card-grid-list/CardGridListInterfaces';
import { OnExpandCollapseStateChangeCallback } from '../card-grid-list/CardGridListTypes';
import LoadingSpinner from '../common/LoadingSpinner';
import {
    getDefiningRowsFromDataSource,
    getImpactedRowsFromDataSource,
    getSelectedRowFromDataSource,
    IWhitePaperRowTrace,
} from './ReportDrillDownUtils';

export interface ITraceCalculationTabPanelProps extends IUnitTestProps {
    cardGridListRef?: React.RefObject<ICardGridList>;
    data?: SelectedWhitePaperCell[];
    onExpandCollapseStateChange?: OnExpandCollapseStateChangeCallback;
    report: Report;
    whitePaperCellDataRowIndex: number;
    whitePaperReportMap: IWhitePaperMappedRowDictionary;
    whitePaperReportColumn: IWhitePaperColumn | undefined;
    isFetchingOrSavingData: boolean;
}

interface IRowTraceInfo {
    displayRowNumber: string | null;
    traces: IWhitePaperRowTrace[];
}

function getSelectedCellData(
    whitePaperCellDataRowIndex: number,
    currentData: IRowTraceInfo,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    retrieveData: (
        selectedCell: SelectedWhitePaperCell,
        whitePaperReportColumn: IWhitePaperColumn | undefined,
        whitePaperReportMap: IWhitePaperMappedRowDictionary
    ) => IWhitePaperRowTrace[],
    whitePaperCellData?: SelectedWhitePaperCell[],
    whitePaperReportMap?: IWhitePaperMappedRowDictionary
): IWhitePaperRowTrace[] {
    let traces: IWhitePaperRowTrace[] = [];

    if (whitePaperCellData && whitePaperReportMap) {
        const selectedCell: SelectedWhitePaperCell = whitePaperCellData[whitePaperCellDataRowIndex];

        if (selectedCell?.displayRowNumber === currentData.displayRowNumber) {
            traces = currentData.traces;
        } else if (selectedCell?.displayRowNumber) {
            traces = retrieveData(selectedCell, whitePaperReportColumn, whitePaperReportMap);
        }
    }

    return traces;
}

function getSelectedRowDataSource(
    whitePaperCellDataRowIndex: number,
    currentData: IRowTraceInfo,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperCellData?: SelectedWhitePaperCell[],
    whitePaperReportMap?: IWhitePaperMappedRowDictionary
): IWhitePaperRowTrace[] {
    return getSelectedCellData(
        whitePaperCellDataRowIndex,
        currentData,
        whitePaperReportColumn,
        getSelectedRowFromDataSource,
        whitePaperCellData,
        whitePaperReportMap
    );
}

function getDefiningRowsDataSource(
    whitePaperCellDataRowIndex: number,
    currentData: IRowTraceInfo,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperCellData?: SelectedWhitePaperCell[],
    whitePaperReportMap?: IWhitePaperMappedRowDictionary
): IWhitePaperRowTrace[] {
    return getSelectedCellData(
        whitePaperCellDataRowIndex,
        currentData,
        whitePaperReportColumn,
        getDefiningRowsFromDataSource,
        whitePaperCellData,
        whitePaperReportMap
    );
}

function getRowsImpactedDataSource(
    whitePaperCellDataRowIndex: number,
    currentData: IRowTraceInfo,
    whitePaperReportColumn: IWhitePaperColumn | undefined,
    whitePaperCellData?: SelectedWhitePaperCell[],
    whitePaperReportMap?: IWhitePaperMappedRowDictionary
): IWhitePaperRowTrace[] {
    return getSelectedCellData(
        whitePaperCellDataRowIndex,
        currentData,
        whitePaperReportColumn,
        getImpactedRowsFromDataSource,
        whitePaperCellData,
        whitePaperReportMap
    );
}

const getGridVariablesClassName = mergeStylesOnDemand({
    displayName: 'grid-variables',
    '--amount-column-font-size': '11px',
    '--amount-column-font-style': 'normal',
    '--amount-column-font-weight': '550',
    '--amount-column-width': '100px',
    '--definition-column-width': 'calc(var(--dynamic-width) * .35)',
    '--description-column-width': 'calc(var(--dynamic-width) * .44)',
    '--dynamic-width': 'calc(100% - var(--amount-column-width))',
    '--rownumber-column-width': 'calc(var(--dynamic-width) * .21)',
});

const TraceCalculationTabPanel: FC<ITraceCalculationTabPanelProps> = (props: ITraceCalculationTabPanelProps) => {
    const gridDisplayHeight: string = 'calc(100vh - 268px)';
    const { t } = useTranslation();
    let selectedRowInfo: IRowTraceInfo = { displayRowNumber: null, traces: [] };
    let definingRowsInfo: IRowTraceInfo = { displayRowNumber: null, traces: [] };
    let impactedRowsInfo: IRowTraceInfo = { displayRowNumber: null, traces: [] };
    const getRowNumberTooltipContent = (traceCalculationObject: IWhitePaperRowTrace) => traceCalculationObject?.rowNumber?.toString();
    const getDescriptionTooltipContent = (traceCalculationObject: IWhitePaperRowTrace) => traceCalculationObject?.description;
    const getDefinitionTooltipContent = (traceCalculationObject: IWhitePaperRowTrace) => traceCalculationObject?.definition;
    const getAmountTooltipContent = (traceCalculationObject: IWhitePaperRowTrace) => traceCalculationObject?.amount;
    const { customPalette, palette } = useCorptaxTheme<IAppTheme>();

    const rowHeaderStyle: ICardGridListCellStyle = {
        backgroundColor: customPalette.neutralLighter,
        color: customPalette.neutralDark,
        fontSize: '9px',
        fontStyle: 'normal',
        fontWeight: '550',
    };

    const groupHeaderStyle: ICardGridListCellStyle = {
        color: customPalette.neutralDark,
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: '550',
    };

    const columns: ICardGridListColumn[] = [
        {
            columnWidth: 'var(--rownumber-column-width)',
            fieldName: 'rowNumber',
            headerText: t('row'),
            tooltipProps: {
                tooltipHostProps: getTooltipHostProps(DirectionalHint.rightCenter, TooltipOverflowMode.Parent, customPalette),
                getTooltipContent: getRowNumberTooltipContent,
            } as ICardGridCellTooltip,
            rowStyle: {
                fontWeight: '700',
            },
        },
        {
            columnWidth: 'var(--description-column-width)',
            fieldName: 'description',
            headerText: t('description'),
            tooltipProps: {
                tooltipHostProps: getTooltipHostProps(DirectionalHint.leftCenter, TooltipOverflowMode.Parent, customPalette),
                getTooltipContent: getDescriptionTooltipContent,
            } as ICardGridCellTooltip,
        },
        {
            columnWidth: 'var(--definition-column-width)',
            fieldName: 'definition',
            headerText: t('definition'),
            tooltipProps: {
                tooltipHostProps: getTooltipHostProps(DirectionalHint.leftCenter, TooltipOverflowMode.Parent, customPalette),
                getTooltipContent: getDefinitionTooltipContent,
            } as ICardGridCellTooltip,
        },
        {
            columnWidth: 'var(--amount-column-width)',
            disableColumnFlexSizing: true,
            fieldName: 'amount',
            headerText: t('amount'),
            tooltipProps: {
                tooltipHostProps: getTooltipHostProps(DirectionalHint.leftCenter, TooltipOverflowMode.Parent, customPalette),
                getTooltipContent: getAmountTooltipContent,
            } as ICardGridCellTooltip,
            rowStyle: {
                backgroundColor: palette.white,
                fontSize: '11px',
                textAlign: 'right',
            },
        },
    ];

    const groups: ICardGridListGroup[] = [
        {
            dataSource: {
                traces: getSelectedRowDataSource(
                    props.whitePaperCellDataRowIndex,
                    selectedRowInfo,
                    props.whitePaperReportColumn,
                    props.data,
                    props.whitePaperReportMap
                ),
            },
            headerStyle: {
                backgroundColor: customPalette.statusDarkGreen,
                color: palette.white,
            },
            headerText: t('total'),
            initiallyExpanded: true,
            noExpandCollapseDefault: true,
            noChildNesting: true,
            rowStyle: {
                backgroundColor: customPalette.statusLightGreen,
            },
        },
        {
            dataSource: {
                traces: getDefiningRowsDataSource(
                    props.whitePaperCellDataRowIndex,
                    definingRowsInfo,
                    props.whitePaperReportColumn,
                    props.data,
                    props.whitePaperReportMap
                ),
            },
            headerStyle: {
                backgroundColor: customPalette.themeLight,
            },
            headerText: t('definingRows'),
        },
        {
            dataSource: {
                traces: getRowsImpactedDataSource(
                    props.whitePaperCellDataRowIndex,
                    impactedRowsInfo,
                    props.whitePaperReportColumn,
                    props.data,
                    props.whitePaperReportMap
                ),
            },
            headerStyle: {
                backgroundColor: customPalette.orangeLighter,
            },
            headerText: t('rowsImpacted'),
        },
    ];

    const getSpinnerClassName: () => string = mergeStylesOnDemand({
        displayName: 'spinner',
        boxSizing: 'content-box',
        height: gridDisplayHeight,
        margin: '10px 0 0',
        padding: '0 0 5px',
    });

    return (
        <>
            <CardGridList
                childDataPropertyNames='traces'
                className={getGridVariablesClassName()}
                columns={columns}
                groups={groups}
                groupHeaderStyle={groupHeaderStyle}
                height='inherit'
                id='traceCalculationGrid'
                isUnitTestExecution={props.isUnitTestExecution}
                ref={props.cardGridListRef}
                rowStyle={rowHeaderStyle}
                groupKeyGenerator={(item: ICardGridListGroup) => getId(groupKeyPrefix)}
                onExpandCollapseStateChange={props.onExpandCollapseStateChange}
            />
            {props.isFetchingOrSavingData && (
                <LoadingSpinner
                    id='loadingSpinnerTraceCalculation'
                    containerStyle={getSpinnerClassName()}
                    label={t('loadingPleaseWait').toString()}
                />
            )}
        </>
    );
};

export default TraceCalculationTabPanel;
