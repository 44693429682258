import styled from 'styled-components';
import { HtmlViewer, IHtmlViewerProps } from './HtmlViewer';

// mimic the style in the editor that we get from SF (at least for now) with bonus italic flair
const Wrapper = styled(HtmlViewer)`
    .e-mention-chip {
        font-style: italic;
        background: #f3f2f1;
        border-radius: 2px;
        border: none;
        color: #003b5c;
        cursor: default;
    }
`;

export const StyledHtmlViewer = (props: IHtmlViewerProps) => {
    if (props.htmlString) {
        return <Wrapper {...props} />;
    }

    return null;
};
