import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import debounce from 'lodash/debounce';
import { getBffUrl } from './EnvironmentUtils';
import { LOGIN_URL } from './Urls.constant';

const REFRESH_TOKEN_DEBOUNCE = 2000;

const redirectToLogin = () => {
    window.location.replace(LOGIN_URL);
};

const refreshTokenDebounce = debounce(() => {
    const instance = Axios.create({
        baseURL: getBffUrl(window.location.host),
    });

    instance({
        url: `Users/RefreshToken`,
        method: 'get',
        headers: {
            'x-csrf': '1',
        },
        withCredentials: true,
    })
        .then((response) => {
            if (response.data) redirectToLogin();
        })
        .catch(() => {
            redirectToLogin();
        });
}, REFRESH_TOKEN_DEBOUNCE);

export const useCustomInstance = <T>(): ((config: AxiosRequestConfig) => Promise<T>) => {
    const AXIOS_INSTANCE = Axios.create({
        baseURL: getBffUrl(window.location.host),
    });

    AXIOS_INSTANCE.interceptors.request.use((config) => {
        if (refreshTokenDebounce) refreshTokenDebounce.cancel();
        refreshTokenDebounce();

        return config;
    });

    return (config: AxiosRequestConfig) => {
        const source = Axios.CancelToken.source();
        const promise = AXIOS_INSTANCE({
            ...config,
            headers: {
                'x-csrf': '1',
                Accept: getAcceptHeaderValue(config),
            },
            withCredentials: true,
            cancelToken: source.token,
            paramsSerializer: {
                indexes: true,
            },
        })
            .then(({ data }) => data)
            .catch((error) => {
                if (error.response?.status === 403) {
                    const redirectUrl = error.response.headers['x-corptax-redirect-uri'];
                    if (redirectUrl) {
                        //feature flag is turned off
                        window.location.href = redirectUrl;
                        return Promise.resolve(null);
                    }
                }
                return Promise.reject(error);
            });

        // @ts-ignore
        promise.cancel = () => {
            source.cancel('Query was cancelled');
        };

        return promise;
    };
};

const getAcceptHeaderValue = (config: AxiosRequestConfig): string => {
    let acceptHeaderValue = 'application/json, text/plain';

    if (config.responseType === 'blob') {
        acceptHeaderValue += ', application/octet-stream';
    }

    return acceptHeaderValue;
};

export default useCustomInstance;

export type ErrorType<Error> = AxiosError<Error>;
