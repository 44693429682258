import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

export const initI18n = async () => {
    await i18n
        .use(HttpApi)
        //.use(LanguageDetector) // Not translating to the browser language for now
        .use(initReactI18next)
        .init({
            lng: 'en',
            fallbackLng: 'en',
            debug: false,

            interpolation: {
                escapeValue: false,
            },

            react: {
                useSuspense: false,
            },

            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
                requestOptions: {
                    cache: 'no-store',
                },
            },

            detection: {
                order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
                caches: ['localStorage', 'cookie'],
            },
        });
};
