import { Dialog, DialogFooter, DialogType, IDialogContentProps, IModalProps } from '@fluentui/react';
import { getComponent, NumberFormatOptions } from '@syncfusion/ej2-base';
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    ColumnDirective,
    ColumnsDirective,
    Edit,
    EditSettingsModel,
    GridComponent,
    IEditCell,
    Inject,
} from '@syncfusion/ej2-react-grids';
import { NumericTextBox, NumericTextBoxComponent, NumericTextBoxModel } from '@syncfusion/ej2-react-inputs';
import { Dispatch, FC, RefObject, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAdjustmentAccountAssociations, useSaveAmounts } from '../../api/report/report';
import { GridStateContextProvider } from '../../contexts/gridStateContext';
import { searchOnType, SearchTableContextProvider } from '../../contexts/searchTableContext';
import { useAdjustmentAccountAndAmountAssociationDialogStyles } from '../../hooks/useAdjustmentAccountAndAmountAssociationDialogStyles';
import { useDebitCreditColumnEditingGridComponent } from '../../hooks/useDebitCreditColumnEditingGridComponent';
import {
    AdjustmentAccountAndAmountAssociationResponse,
    AdjustmentAccountAndAmountAssociationsResponse,
    ExtractionDetailsAccountSummary,
    IWhitePaperReportChanges,
    ProblemDetails,
    SaveAmountsRequest,
    SaveAmountsResponse,
} from '../../model';
import { AdjustmentAccountAndAmountAssociationRequest } from '../../model/adjustmentAccountAndAmountAssociationRequest';
import {
    AdjustmentAccountAmountAction,
    AdjustmentAccountAmountDialogReducerActionParams,
} from '../../reducers/adjustmentAccountAmountReducer';
import { addReportCellToChangeTrackingData } from '../../utils/ChangeTrackingDataUtils';
import { deFormatNumber, parseNumericValue } from '../../utils/NumberUtils';
import { mergeStylesOnDemand } from '../../utils/StyleUtils';
import { ErrorType } from '../../utils/UseCustomInstance';
import GridSearchBar from '../common/GridSearchBar';
import LoadingSpinner from '../common/LoadingSpinner';
import { accountAmountRowPropertyNames } from '../enter-amounts-dialog/AccountAmountRowPropertyNames';
import FilterActionBar, { IFilterActionBarProps } from '../filters/filter-actions/FilterActionBar';
import ReportColumnContext from './ReportColumnContext';

export interface IAdjustmentAccountAndAmountAssociationDialogProps {
    show: boolean;
    adjustmentCode: string;
    case: string;
    jurisdiction: string;
    entity: string;
    period: string;
    location: string;
    rowNumber?: number;
    columnId?: number;
    changeTrackingData?: IWhitePaperReportChanges;
    taxReturnKey: number;
    isLoading: boolean;
    saveButtonIsDisabled: boolean;
    updateState: Dispatch<AdjustmentAccountAmountDialogReducerActionParams>;
    updateChangeTrackingData?: (changeTrackingData: IWhitePaperReportChanges) => void;
}

export interface IAccountAmountRow extends AdjustmentAccountAndAmountAssociationResponse {
    debitTotal?: number;
    creditTotal?: number;
}

export function getUpdatedAccountAmountRow(
    originalRow: IAccountAmountRow,
    batchChanges: { changedRecords: IAccountAmountRow[] }
): IAccountAmountRow | undefined {
    const updatedRow: IAccountAmountRow | undefined = batchChanges.changedRecords.find(
        (changedRecord) => changedRecord.accountCode === originalRow.accountCode
    );
    return updatedRow;
}

const AdjustmentAccountAndAmountAssociationDialog: FC<IAdjustmentAccountAndAmountAssociationDialogProps> = (
    props: IAdjustmentAccountAndAmountAssociationDialogProps
) => {
    const { t } = useTranslation();
    const gridRef: RefObject<GridComponent> = useRef<GridComponent>(null);
    const { onCellSave, onGridCreated, onCellEdit, onCellSaved, onRowDataBound } = useDebitCreditColumnEditingGridComponent(
        gridRef,
        props.updateState,
        props.saveButtonIsDisabled
    );
    const {
        modalStyles,
        dialogContentStyles,
        dialogFooterStyles,
        gridComponentCSSClass,
        searchBarStyleOverrides,
        totalRowStyle,
        spinnerClassName,
    } = useAdjustmentAccountAndAmountAssociationDialogStyles();
    const isLoading: boolean = props.isLoading;
    const { mutate: getAdjustmentAccountAndAmountAssociations } = useGetAdjustmentAccountAssociations();
    const { mutate: saveAmounts } = useSaveAmounts();
    const closeLabel = useMemo(() => t('close'), [t]);
    const cancelLabel = useMemo(() => t('cancel'), [t]);
    const confirmLabel = useMemo(() => t('save'), [t]);
    const dialogHeading = useMemo(() => `${t('quickAddHeader')} ${props.adjustmentCode}`, [props.adjustmentCode, t]);

    const primaryHandler = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            function retrieveNormalizedRowValue(row: IAccountAmountRow): string {
                let amount: string = '0';

                if (row.accountBalanceType === 'Debit') {
                    if (row.debitTotal) {
                        amount = row.debitTotal.toString();
                    } else if (row.creditTotal) {
                        amount = (row.creditTotal * -1).toString();
                    }
                } else if (row.accountBalanceType === 'Credit') {
                    if (row.creditTotal) {
                        amount = row.creditTotal.toString();
                    } else if (row.debitTotal) {
                        amount = (row.debitTotal * -1).toString();
                    }
                } else {
                    throw new Error(`Invalid account balance type: ${row.accountBalanceType}`);
                }

                return amount;
            }

            function convertIAccountAmountRowToExtractionDetailsAccountSummary(row: IAccountAmountRow): ExtractionDetailsAccountSummary {
                let amount: string = retrieveNormalizedRowValue(row);

                return {
                    accountNumber: row.accountCode,
                    adjustmentInfo: [
                        {
                            adjustmentCode: props.adjustmentCode,
                            case: props.case,
                            corporation: props.entity,
                            jurisdiction: props.jurisdiction,
                            location: props.location,
                            reportAmount: amount,
                            year: props.period,
                        },
                    ],
                    reportAmount: amount,
                };
            }

            function createSaveAmountsRequestIfRowsChanged() {
                const updatedRows: IAccountAmountRow[] = findUpdatedRows();

                if (updatedRows.length === 0) {
                    // Nothing to save.
                    return null;
                }

                const accountSummaryUpdates = updatedRows.map(convertIAccountAmountRowToExtractionDetailsAccountSummary);
                const saveAmountsRequest: SaveAmountsRequest = {
                    accountSummaryUpdates: accountSummaryUpdates,
                    // TODO: Evntually we will need to update change tracking via call to addDrilldownAmountCellToChangeTrackingData if entering from pencil icon of account/amount row of drilldown.
                    changesTrackingData: addReportCellToChangeTrackingData(props.rowNumber!, props.columnId!, props.changeTrackingData!),
                    taxReturnKey: props.taxReturnKey,
                };

                return saveAmountsRequest;
            }

            function findUpdatedRows(): IAccountAmountRow[] {
                gridRef!.current!.editModule.batchSave();
                gridRef!.current!.refresh();

                /*gridRef.current.getBatchChanges() returns an object with the following structure:
            {
                "addedRecords": [],
                "deletedRecords": [],
                "changedRecords": [
                    {
                        "accountCode": "2200090",
                        "accountDescription": "ACCUM DEPR - OTHER ASSETS",
                        "creditAmount": "53126",
                        "debitAmount": null,
                        "accountBalanceType": "Credit",
                        "creditTotal": 12
                    }
                ]
            }*/
                const batchChanges: { changedRecords: IAccountAmountRow[] } = gridRef.current!.getBatchChanges() as {
                    changedRecords: IAccountAmountRow[];
                };
                const rowElements = (gridRef!.current!.getContentTable() as HTMLTableElement).rows;

                // https://www.syncfusion.com/forums/167379/getting-access-to-changed-rows-in-a-grid-in-batch-edit-mode?reply=SEg2UC
                const updatedRows: IAccountAmountRow[] = [];

                for (const rowElement of rowElements) {
                    const updatedRow: IAccountAmountRow | undefined = getUpdatedAccountAmountRow(
                        gridRef.current!.getRowInfo(rowElement)?.rowData as IAccountAmountRow,
                        batchChanges
                    );

                    if (updatedRow) {
                        updatedRows.push(updatedRow);
                    }
                }

                return updatedRows;
            }

            const saveAmountsRequest: SaveAmountsRequest | null = createSaveAmountsRequestIfRowsChanged();

            if (saveAmountsRequest) {
                saveAmounts(
                    {
                        data: saveAmountsRequest,
                    },
                    {
                        onSuccess: (
                            data: SaveAmountsResponse | undefined,
                            variables: { data: SaveAmountsRequest },
                            context: unknown | undefined
                        ) => {
                            props.updateState({
                                type: AdjustmentAccountAmountAction.ModalClose,
                                changeTrackingData: variables.data.changesTrackingData,
                                updateChangeTrackingData: props.updateChangeTrackingData,
                            });
                        },
                    }
                );
            }
        },
        [props.updateState, props.updateChangeTrackingData, gridRef, gridRef.current, saveAmounts]
    );

    const secondaryHandler = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            props.updateState({ type: AdjustmentAccountAmountAction.ModalClose });
        },
        [props.updateState]
    );

    const filterActionBarProps: IFilterActionBarProps = useMemo(() => {
        return {
            primaryButtonLabel: confirmLabel,
            secondaryButtonLabel: cancelLabel,
            primaryButtonDisabled: props.saveButtonIsDisabled,
            primaryHandler: primaryHandler,
            secondaryHandler: secondaryHandler,
        };
    }, [props.saveButtonIsDisabled, primaryHandler, secondaryHandler, confirmLabel, cancelLabel, saveAmounts]);

    const [accountAmountRows, setAccountAmountRows] = useState<IAccountAmountRow[] | undefined>();

    const request: AdjustmentAccountAndAmountAssociationRequest = {
        caseCode: props.case,
        location: props.location,
        entityCode: props.entity,
        period: props.period,
        adjustmentCode: props.adjustmentCode,
        jurisdiction: props.jurisdiction,
    };

    useLayoutEffect(
        () =>
            getAdjustmentAccountAndAmountAssociations(
                {
                    data: request,
                },
                {
                    onSettled: (
                        data: AdjustmentAccountAndAmountAssociationsResponse | undefined,
                        error: ErrorType<ProblemDetails> | null,
                        variables: { data: AdjustmentAccountAndAmountAssociationRequest },
                        context: unknown | undefined
                    ) => {
                        props.updateState({ type: AdjustmentAccountAmountAction.AssociationsFetchSuccess });
                    },
                    onSuccess: (
                        data: AdjustmentAccountAndAmountAssociationsResponse | undefined,
                        variables: { data: AdjustmentAccountAndAmountAssociationRequest },
                        context: unknown | undefined
                    ) => {
                        const accountAmountRows: IAccountAmountRow[] | undefined = data?.accounts?.map(
                            (account: AdjustmentAccountAndAmountAssociationResponse) => {
                                return {
                                    ...account,
                                    [accountAmountRowPropertyNames.debitTotal]: account.debitAmount
                                        ? parseNumericValue(account.debitAmount)
                                        : undefined,
                                    [accountAmountRowPropertyNames.creditTotal]: account.creditAmount
                                        ? parseNumericValue(account.creditAmount)
                                        : undefined,
                                } as IAccountAmountRow;
                            }
                        );
                        setAccountAmountRows(accountAmountRows);
                    },
                }
            ),
        []
    );

    const editSettings: EditSettingsModel = {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
        mode: 'Batch',
    };

    const numericEditParams: IEditCell = {
        params: {
            format: 'N',
            decimals: 0,
            showSpinButton: false,
            htmlAttributes: { maxlength: '15' },
            pasteHandler(e: ClipboardEvent) {
                // _this.setElementValue doesnt work when object is declared as NumericTextBox because functions are marked as private.
                //This code was given to us by Syncfusion in order to intercept paste values and change them before completing the paste event.
                const textBoxComponent = getComponent<NumericTextBoxComponent>(e.target! as HTMLElement, NumericTextBox) as any;
                const defaultValue = '';
                if (!textBoxComponent.enabled || textBoxComponent.readonly) {
                    return;
                }
                setTimeout(function () {
                    const number = deFormatNumber(textBoxComponent.element.value).trim();
                    textBoxComponent.setElementValue(number);
                    if (!textBoxComponent.numericRegex().test(textBoxComponent.element.value)) {
                        textBoxComponent.setElementValue(defaultValue);
                    }
                });
            },
        } as NumericTextBoxModel,
    };

    const numberFormatOptions = {
        format: 'N',
        useGrouping: true,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    } as NumberFormatOptions;

    const dialogContentProps: IDialogContentProps = useMemo(() => {
        return {
            closeButtonAriaLabel: closeLabel,
            showCloseButton: true,
            styles: dialogContentStyles,
            title: dialogHeading,
            type: DialogType.normal,
        };
    }, [closeLabel, dialogContentStyles, dialogHeading]);

    const modalProps: IModalProps = useMemo(() => {
        return {
            isBlocking: true,
            styles: modalStyles,
        };
    }, [modalStyles, props.updateState]);

    const totalRowTemplate = useCallback(
        (props: any) => {
            const className = mergeStylesOnDemand(totalRowStyle)();
            return <span className={className}>{t('total')}</span>;
        },
        [mergeStylesOnDemand, t, totalRowStyle]
    );

    const dialogFooter = useMemo(() => {
        return (
            <DialogFooter styles={dialogFooterStyles}>
                <FilterActionBar {...filterActionBarProps} />
            </DialogFooter>
        );
    }, [dialogFooterStyles, filterActionBarProps]);

    const reportColumnContext = useMemo(() => {
        return <ReportColumnContext case={props.case} entity={props.entity} jurisdiction={props.jurisdiction} year={props.period} />;
    }, [props.case, props.entity, props.jurisdiction, props.period]);

    return (
        <Dialog
            dialogContentProps={dialogContentProps}
            hidden={!props.show}
            modalProps={modalProps}
            onDismiss={() => props.updateState({ type: AdjustmentAccountAmountAction.ModalClose })}
        >
            {reportColumnContext}
            {!props.isLoading && (
                <GridStateContextProvider grid={gridRef}>
                    <SearchTableContextProvider searchOn={searchOnType.Accounts}>
                        <GridSearchBar
                            searchBoxPlaceholderText={t('search')}
                            styleOverride={searchBarStyleOverrides}
                            stackStyle={{ width: '100%', paddingBottom: '5px' }}
                            buttonStyleOverride={{ height: '32px' }}
                        />
                        <GridComponent
                            className={gridComponentCSSClass}
                            dataSource={accountAmountRows}
                            gridLines='Both'
                            editSettings={editSettings}
                            cellEdit={onCellEdit}
                            ref={gridRef}
                            cellSave={onCellSave}
                            cellSaved={onCellSaved}
                            rowDataBound={onRowDataBound}
                            created={onGridCreated}
                        >
                            <ColumnsDirective>
                                <ColumnDirective
                                    field={accountAmountRowPropertyNames.accountCode}
                                    headerText={t('accountCode').toString()}
                                    textAlign='Left'
                                    type='string'
                                    width='102'
                                    allowEditing={false}
                                    // if one of the columns is not designated as the primary key, aggregation will not work.
                                    // without this, the sum will be calculated as the amount in the first cell multiplied
                                    // by the number of rows in the table
                                    isPrimaryKey={true}
                                />
                                <ColumnDirective
                                    field={accountAmountRowPropertyNames.accountDescription}
                                    headerText={t('accountDescription').toString()}
                                    textAlign='Left'
                                    type='string'
                                    width='206'
                                    allowEditing={false}
                                />
                                <ColumnDirective
                                    field={accountAmountRowPropertyNames.accountBalanceType}
                                    headerText={t('type').toString()}
                                    textAlign='Center'
                                    type='string'
                                    width='61'
                                    allowEditing={false}
                                />
                                <ColumnDirective
                                    field={accountAmountRowPropertyNames.debitTotal}
                                    headerText={t('debit').toString()}
                                    textAlign='Right'
                                    width='80'
                                    format={numberFormatOptions}
                                    edit={numericEditParams}
                                    editType='numericedit'
                                />
                                <ColumnDirective
                                    field={accountAmountRowPropertyNames.creditTotal}
                                    headerText={t('credit').toString()}
                                    textAlign='Right'
                                    width='80'
                                    format={numberFormatOptions}
                                    edit={numericEditParams}
                                    editType='numericedit'
                                />
                            </ColumnsDirective>
                            <Inject services={[Edit, Aggregate]} />
                            <AggregatesDirective>
                                <AggregateDirective>
                                    <AggregateColumnsDirective>
                                        <AggregateColumnDirective
                                            footerTemplate={totalRowTemplate}
                                            // the aggregation type doesn't matter. you just have to supply something so the row will render
                                            type='Min'
                                            // by picking the accountCode field, we make sure the template will render in the correct position within the grid
                                            field={accountAmountRowPropertyNames.accountCode}
                                        />
                                    </AggregateColumnsDirective>
                                </AggregateDirective>
                                <AggregateDirective>
                                    <AggregateColumnsDirective>
                                        <AggregateColumnDirective
                                            type='Sum'
                                            field={accountAmountRowPropertyNames.debitTotal}
                                            format={numberFormatOptions}
                                        />
                                        <AggregateColumnDirective
                                            type='Sum'
                                            field={accountAmountRowPropertyNames.creditTotal}
                                            format={numberFormatOptions}
                                        />
                                    </AggregateColumnsDirective>
                                </AggregateDirective>
                            </AggregatesDirective>
                        </GridComponent>
                    </SearchTableContextProvider>
                </GridStateContextProvider>
            )}
            {isLoading && (
                <LoadingSpinner
                    id='loadingSpinnerEnterAmountsGrid'
                    containerStyle={spinnerClassName}
                    label={t('loadingPleaseWait').toString()}
                />
            )}
            {dialogFooter}
        </Dialog>
    );
};

export default AdjustmentAccountAndAmountAssociationDialog;
